import { Tooltip } from '@rezio/components/tooltip/tooltip'
import { palette } from '@rezio/res/theme'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'

import { HoverableFileInfoPropsType } from './types'

export const TipsInfoText = ({
  context,
  style,
  containerStyle,
  numberOfLines = 1
}: HoverableFileInfoPropsType) => {
  const { t } = useTranslation()
  return (
    <View style={[{ flex: 1, zIndex: 1 }, containerStyle]}>
      {_.isEmpty(context) ? (
        <Text style={{ color: palette.gray, lineHeight: 20, minHeight: 20 }}>
          {t('COMMON.NOT_YET_FILLED')}
        </Text>
      ) : (
        <Tooltip popover={context}>
          <Text
            style={[{ fontSize: 14, lineHeight: 20 }, style]}
            numberOfLines={numberOfLines}
            ellipsizeMode='tail'
          >
            {context}
          </Text>
        </Tooltip>
      )}
    </View>
  )
}
