import {
  MaterialIcons,
  PageWidthModal,
  BorderPanel,
  PageFooter,
  StaticImage,
  FilterableSelector,
  Separator
} from '@rezio/components'
import Dropdown from '@rezio/components/modalDropdown'
import { usePermission, ROLE_TITLE } from '@rezio/core/auth'
import { getConfig } from '@rezio/core/config'
import { useStores, useLayout, usePageWidth, useRouter } from '@rezio/core/hooks'
import { template, manipulator, palette } from '@rezio/res/theme'
import { formatPickerItem, getFuzzySearchItems } from '@rezio/utils/format'
import { sleep } from '@rezio/utils/sleep'
import { ImageCroppingSizeType, CustomItemsButtonType } from '@rezio/utils/types'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, {
  Component,
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
  Fragment
} from 'react'
import { ChromePicker } from 'react-color'
import { Text, View, Image, Platform, StyleSheet, ScrollView, TouchableOpacity } from 'react-native'

import { PageStepControl } from './formComponent'
import { HoverableOpacity } from './hoverableOpacity'
import {
  ThemedTextInput,
  ThemedButton as ThemedButtonOrigin,
  ThemedPicker,
  ThemedTagSelector
} from './shareComponents'

export { default as Dropdown } from './modalDropdown'

export {
  ThemedTextInput,
  ThemedRadioButton,
  ThemedTextTag,
  ThemedPicker,
  ThemedTagSelector
} from './shareComponents'

export const textInputStyles = StyleSheet.create({
  default: {
    fontSize: 14,
    flex: 1,
    height: Platform.OS === 'android' ? 'auto' : '100%',
    color: palette.black,
    backgroundColor: palette.white
  },
  container: {
    height: 40,
    paddingHorizontal: 10,
    borderRadius: 5,
    borderWidth: 1
  }
})

export function ThemedExtendableTextInput(props) {
  const pageWidth = usePageWidth()
  const [isOpen, setOpen] = useState(_.get(props, 'value.isHyperLink', false))
  const [mainTextInput, setMainTextInput] = useState(_.get(props, 'value.label', ''))
  const [secondTextInput, setSecondTextInput] = useState(_.get(props, 'value.hyperLink', ''))
  const mainTextInputWidth = useMemo(() => {
    return pageWidth > 680 ? Math.max((pageWidth - 200) / 3, 300) : pageWidth - 100
  }, [pageWidth, Math.max])

  useEffect(() => {
    const changeHandler = props.onValueChange || props.onChange
    changeHandler?.({
      label: mainTextInput,
      isHyperLink: isOpen,
      hyperLink: isOpen ? secondTextInput : ''
    })
  }, [mainTextInput, secondTextInput, isOpen, props.onValueChange, props.onChange])

  return (
    <>
      <ThemedTextInput
        style={{ width: mainTextInputWidth }}
        value={mainTextInput}
        onChangeText={setMainTextInput}
        testId='labelTextInput'
      />
      <View style={{ paddingVertical: 10 }}>
        <View style={manipulator.container('row', 'space-between', 'center')}>
          <ThemedButton
            style={{ backgroundColor: 'transparent', padding: 0 }}
            onPress={() => setOpen((prev) => !prev)}
            accessibilityRole='checkbox'
          >
            <MaterialIcons
              name={isOpen ? 'check-box' : 'check-box-outline-blank'}
              color={isOpen ? palette.primary : palette.border}
              size={20}
              accessibilityState={{ checked: isOpen }}
            />
            <Text style={{ marginLeft: 5, marginRight: 10 }}>{props.label}</Text>
          </ThemedButton>
          {isOpen && (
            <ThemedTextInput
              style={{ width: mainTextInputWidth - 100 }}
              value={secondTextInput}
              onChangeText={setSecondTextInput}
              testId='hyperLinkTextInput'
            />
          )}
        </View>
      </View>
    </>
  )
}

export function ThemedColorSelector(props) {
  const [isVisible, setIsVisible] = useState(false)
  const [selectedColor, setSelectedColor] = useState(props.value || palette.primary)
  const { labelStyle, placeholderStyle } = props

  const changeHandler = props.onValueChange ?? props.onChange

  const handleChangeText = useCallback(
    (color) => {
      changeHandler(color)
      setSelectedColor(color)
    },
    [changeHandler]
  )

  const handleChangeChromePicker = useCallback(
    (color) => {
      changeHandler(color.hex)
      setSelectedColor(color.hex)
    },
    [changeHandler]
  )

  return (
    <>
      {props.label && (
        <Text
          style={[{ fontSize: 16, color: palette.black, marginBottom: 10 }, labelStyle]}
          accessibilityRole='text'
        >
          {props.label}
        </Text>
      )}
      {props.placeholder && (
        <Text
          style={[{ fontSize: 16, color: palette.gray, marginBottom: 10 }, placeholderStyle]}
          accessibilityRole='text'
        >
          {props.placeholder}
        </Text>
      )}
      <View style={[template.rowContainer, { position: 'relative' }]}>
        <ThemedButton
          onPress={() => setIsVisible((prevState) => !prevState)}
          style={{ backgroundColor: 'transparent', width: 40, marginRight: 10, padding: 0 }}
          accessibilityRole='spinbutton'
        >
          <View
            style={{
              height: 40,
              width: 40,
              backgroundColor: selectedColor,
              borderRadius: 5,
              boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.2)'
            }}
          />
        </ThemedButton>
        <ThemedTextInput value={selectedColor} onChangeText={handleChangeText} />
      </View>
      {isVisible && (
        <View style={{ marginTop: 10 }} accessibilityRole='toolbar'>
          <ChromePicker color={selectedColor} onChange={handleChangeChromePicker} />
        </View>
      )}
    </>
  )
}

export const ThemedLandmarkSelector = inject(
  'theme',
  't'
)(
  class ThemedLandmarkSelector extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isVisible: false,
        initItems: null
      }
    }

    renderTagItem = ({ each, index, isSelected = false }) => {
      const { items, data, value: propsValue } = this.props
      const value = _.get(each, 'value') || each
      const label =
        _.get(each, 'label') ||
        _.get(
          _.find(items, (i) => i.uuid === value),
          'label'
        ) ||
        _.get(
          _.find(data, (i) => i.uuid === value),
          'label'
        ) ||
        _.get(items, propsValue) ||
        ''
      return (
        <ThemedButton
          key={index}
          style={[
            {
              minWidth: 100,
              marginRight: 10,
              height: 40,
              borderWidth: 1,
              borderRadius: 5,
              backgroundColor: 'transparent',
              paddingLeft: 5,
              paddingRight: 5
            },
            isSelected
              ? { marginBottom: 5, borderColor: palette.primary }
              : { marginBottom: 10, borderColor: palette.gray }
          ]}
          onPress={() => this.handleDelete(value)}
        >
          {isSelected && (
            <MaterialIcons
              name='clear'
              size={14}
              color={palette.primary}
              style={{ marginRight: 3 }}
            />
          )}
          {isSelected ? (
            <Text style={{ color: palette.primary }}>{label}</Text>
          ) : (
            <Text style={{ fontSize: 14, fontWeight: '500' }}>
              <MaterialIcons
                name='add'
                size={14}
                color={palette.black}
                style={{ marginRight: 5 }}
              />
              {label}
            </Text>
          )}
        </ThemedButton>
      )
    }

    handleCancel = () => {
      const { initItems } = this.state
      const changeHandler = this.props.onValueChange || this.props.onChange
      changeHandler?.(initItems)
    }

    handleDelete = (value) => {
      const { value: propsValue, multiple } = this.props
      const changeHandler = this.props.onValueChange || this.props.onChange
      if (multiple) {
        const itemsObj = _.remove(propsValue, (n) => n !== value)
        changeHandler?.(itemsObj)
      } else {
        changeHandler?.('')
      }
    }

    render() {
      const { isVisible } = this.state
      const { t, label, description, value: propsValue, disabled, items, multiple } = this.props
      const selectedValue =
        _.isString(propsValue) && !_.isEmpty(propsValue)
          ? {
              uuid: propsValue,
              label: _.get(items, propsValue)
            }
          : propsValue

      return disabled ? (
        <ThemedTextInput disabled value={items[propsValue]} layout='block' />
      ) : (
        <>
          <View
            style={[
              manipulator.container('row', 'flex-start', 'center'),
              manipulator.border('all', '', 5),
              { flexWrap: 'wrap', paddingTop: 5, paddingLeft: 5, paddingRight: 5 }
            ]}
          >
            {!_.isEmpty(propsValue) &&
              (multiple
                ? propsValue.map((each, index) =>
                    this.renderTagItem({ each, index, isSelected: true })
                  )
                : this.renderTagItem({ each: propsValue, isSelected: true }))}
            <ThemedButton
              style={{
                marginBottom: 7,
                height: 40,
                width: 40,
                borderWidth: 1,
                borderRadius: 5,
                borderColor: palette.primary,
                backgroundColor: 'transparent',
                paddingLeft: 5,
                paddingRight: 5
              }}
              onPress={() => this.setState({ isVisible: true, initItems: propsValue })}
            >
              <MaterialIcons name='add' size={28} color={palette.primary} />
            </ThemedButton>
          </View>
          <PageWidthModal
            isVisible={isVisible}
            onBackdropPress={() => this.setState({ isVisible: !this.state.isVisible })}
          >
            <View style={{ backgroundColor: palette.white, justifyContent: 'center', margin: 0 }}>
              <BorderPanel label={label} containerStyle={{ width: '100%' }}>
                {_.isEmpty(description) ? null : description}
                <FilterableSelector {...this.props} showFilterTags={false} value={selectedValue} />
              </BorderPanel>
              <PageFooter>
                <View style={manipulator.container('row', 'space-between', 'center')}>
                  <ThemedButton
                    onPress={() => this.setState({ isVisible: false }, this.handleCancel)}
                    style={[
                      template.defaultPanel,
                      { width: 150, backgroundColor: palette.negative }
                    ]}
                  >
                    <Text style={{ color: palette.black }}>{t('FORM.BUTTON_CANCEL')}</Text>
                  </ThemedButton>
                  <ThemedButton
                    onPress={() => this.setState({ isVisible: false })}
                    style={[
                      template.defaultPanel,
                      { width: 150, backgroundColor: palette.positive }
                    ]}
                  >
                    <Text style={{ color: palette.white }}>{t('FORM.BUTTON_CONFIRM')}</Text>
                  </ThemedButton>
                </View>
              </PageFooter>
            </View>
          </PageWidthModal>
        </>
      )
    }
  }
)

export const ThemedCheckBoxes = inject('theme')(
  class ThemedCheckBoxes extends Component {
    constructor(props) {
      super(props)
      this.state = {
        checkedItems: props.value || []
      }
    }

    componentDidUpdate(prevProps) {
      if (!_.isEqual(prevProps.value, this.props.value)) {
        this.setState({ checkedItems: this.props.value })
      }
    }

    handlePress = (value) => {
      const changeHandler = this.props.onValueChange || this.props.onChange
      const { checkedItems: prevCheckedItems } = this.state
      const itemsObj =
        prevCheckedItems.indexOf(value) === -1
          ? _.concat(prevCheckedItems, value)
          : _.remove(prevCheckedItems, (n) => n !== value)

      changeHandler?.(itemsObj)
      this.setState({ checkedItems: itemsObj })
    }

    render() {
      const { checkedItems } = this.state
      const {
        disabled = false,
        items,
        value: propsValue,
        itemStyle = {},
        containerStyle = {},
        labelStyle = {},
        isColumn,
        showSeparator,
        hint
      } = this.props
      return (
        <View className='flex-col'>
          {hint ? <Text className='mb-3'>{hint}</Text> : null}
          <View
            style={[
              manipulator.container(
                isColumn ? 'column' : 'row',
                'flex-start',
                isColumn ? 'flex-start' : 'center'
              ),
              { flexWrap: 'wrap' },
              containerStyle
            ]}
            accessibilityRole='combobox'
          >
            {items.map((each, index) => {
              const { label, value, disabled: itemDisabled } = each
              const isChecked = checkedItems.indexOf(value) !== -1
              const isDisabeled = itemDisabled || disabled
              return (
                <Fragment key={index}>
                  <ThemedButton
                    disabled={isDisabeled}
                    style={[{ backgroundColor: 'transparent', padding: 0 }, itemStyle]}
                    onPress={() => this.handlePress(value)}
                    accessibilityRole='checkbox'
                    accessibilityState={{ disabled: isDisabeled, checked: isChecked }}
                  >
                    {isDisabeled ? (
                      <MaterialIcons
                        name='indeterminate-check-box'
                        size={20}
                        style={{ color: palette.negative }}
                      />
                    ) : isChecked && !_.isEmpty(propsValue) ? (
                      <MaterialIcons name='check-box' size={20} style={{ color: palette.active }} />
                    ) : (
                      <MaterialIcons
                        name='check-box-outline-blank'
                        size={20}
                        style={{ color: palette.negative }}
                      />
                    )}
                    <Text
                      accessibilityRole='text'
                      style={[{ marginLeft: 4, marginRight: 30 }, labelStyle]}
                    >
                      {label}
                    </Text>
                  </ThemedButton>
                  {showSeparator && index !== items.length - 1 && (
                    <Separator forceLight style={{ width: '100%' }} />
                  )}
                </Fragment>
              )
            })}
          </View>
        </View>
      )
    }
  }
)

export const ThemedCheckBox = inject('theme')(
  class ThemedCheckBox extends Component {
    handlePress = () => {
      const changeHandler = this.props.onValueChange || this.props.onChange
      changeHandler?.(!this.props.value)
    }

    render() {
      const { disabled, value: propsValue, labelStyle = {}, disabledColor } = this.props
      return (
        <ThemedButton
          disabled={disabled}
          style={[{ backgroundColor: 'transparent' }, this.props.style]}
          onPress={this.handlePress}
          accessibilityRole='checkbox'
          accessibilityState={{ disabled: !!disabled, checked: !!propsValue }}
        >
          {propsValue ? (
            <MaterialIcons
              name='check-box'
              color={disabled ? disabledColor || palette.active : palette.active}
              size={20}
            />
          ) : (
            <MaterialIcons
              name='check-box-outline-blank'
              color={
                disabled ? disabledColor || this.props.theme.textColor : this.props.theme.textColor
              }
              size={20}
            />
          )}
          <Text accessibilityRole='text' style={[{ marginLeft: 4 }, labelStyle]}>
            {this.props.label}
          </Text>
        </ThemedButton>
      )
    }
  }
)

export const ThemedSwitch = inject('theme')(
  class ThemedSwitch extends Component {
    handlePress = () => {
      const changeHandler = this.props.onValueChange || this.props.onChange
      changeHandler?.(!this.props.value)
    }

    render() {
      const { disabled, value: propsValue } = this.props
      const switchStyle = propsValue ? { right: 3 } : { left: 3 }

      return (
        <ThemedButton
          disabled={disabled}
          style={{
            position: 'relative',
            width: 44,
            height: 27,
            borderRadius: 23,
            backgroundColor: propsValue ? palette.primary : palette.lightGray
          }}
          onPress={this.handlePress}
          accessibilityRole='switch'
          accessibilityState={{ disabled: !!disabled, checked: !!propsValue }}
        >
          <View
            style={[
              {
                position: 'absolute',
                backgroundColor: palette.white,
                borderRadius: 10,
                height: 21,
                width: 21
              },
              !propsValue && manipulator.border('all', 'light', 13, 1),
              switchStyle
            ]}
          />
        </ThemedButton>
      )
    }
  }
)

export const ThemedLabelSelector = inject(
  'theme',
  'store'
)(
  class ThemedLabelSelector extends Component {
    constructor(props) {
      super(props)
      this.state = {
        selectedValue: props.defaultValue || props.value
      }
    }

    handlePress = (value, key) => {
      const changeHandler = this.props.onValueChange || this.props.onChange
      changeHandler?.(value, key)
      this.setState({ selectedValue: value })
    }

    render() {
      const { selectedValue } = this.state
      const { disabled = false, items, value: propsValue, labelStyle, style } = this.props
      const currentValue = propsValue || selectedValue
      const isMobile = this.props.store.viewStore.layout !== 'desktop'
      const selectorWidth = _.size(items) > 1 ? 270 : 150
      return (
        <View style={[manipulator.container('row'), { width: selectorWidth }, style]}>
          {items.map((item, key) => {
            const { value, label, icon } = item
            const isActive = currentValue === value
            const color = isActive ? palette.white : disabled ? palette.gray : palette.primary

            return (
              <ThemedButton
                disabled={disabled}
                key={key}
                textStyle={{ color: palette.white, fontSize: 14 }}
                containerStyle={{ overflow: 'hidden', flex: 1, flexDirection: 'row' }}
                onPress={() => this.handlePress(value, key)}
                style={[
                  {
                    borderBottomLeftRadius: +key === 0 ? 6 : 0,
                    borderBottomRightRadius: +key + 1 === items.length ? 6 : 0,
                    borderTopLeftRadius: +key === 0 ? 6 : 0,
                    borderTopRightRadius: +key + 1 === items.length ? 6 : 0,
                    height: isMobile ? 'auto' : 40,
                    flex: 1,
                    borderRadius: 0,
                    borderColor: disabled ? palette.gray : palette.primary,
                    borderWidth: 1,
                    borderLeftWidth: key === 0 ? 1 : 0
                  },
                  manipulator.panel(
                    5,
                    10,
                    5,
                    10,
                    isActive ? (disabled ? palette.gray : palette.primary) : palette.white
                  ),
                  labelStyle
                ]}
                accessibilityState={{ disabled: !!disabled, checked: isActive }}
              >
                <View style={manipulator.container('row', 'center', 'center')}>
                  {icon && <MaterialIcons color={color} name={icon} size={16} />}
                  {_.isString(label) ? (
                    <Text style={{ fontSize: 14, marginLeft: 5, color }}>{label}</Text>
                  ) : (
                    label
                  )}
                </View>
              </ThemedButton>
            )
          })}
        </View>
      )
    }
  }
)

export const ThemedTimeSelector = forwardRef(function ThemedTimeSelector(props, ref) {
  const { t } = useStores()
  const [open, setOpen] = useState(false)
  const changeHandler = props.onValueChange || props.onChange
  const { hasInitValue = true } = props
  const generateTimeObj = (value) => {
    const initHour = moment(value, 'HH:mm')
    return _.isEmpty(props.value) && hasInitValue === false
      ? { hour: '', min: '', hourSystem: '' }
      : {
          hour: _.isEmpty(props.value) ? '12' : initHour.format('hh'),
          min: _.isEmpty(props.value) ? '00' : initHour.format('mm'),
          hourSystem: _.isEmpty(props.value)
            ? moment('00:00', 'HH:mm').format('A')
            : initHour.format('A')
        }
  }

  const [time, setTime] = useState(generateTimeObj(props.value))
  const style = props.disabled ? { borderColor: palette.disable } : { borderColor: palette.gray }
  const openStyle = open
    ? { borderColor: palette.gray, borderRadius: 5, borderWidth: 1, paddingBottom: 2 }
    : {}

  useEffect(() => {
    hasInitValue && confirm()
  }, [])

  useEffect(() => {
    setTime(generateTimeObj(props.value))
  }, [props.value])

  useImperativeHandle(
    ref,
    () => ({
      toggle: handleToggle,
      close: () => setOpen(false),
      open: () => setOpen(true)
    }),
    []
  )

  const confirm = useCallback(() => {
    if (!_.isNull(time.hour) && !_.isNull(time.min) && !_.isNull(time.hourSystem)) {
      const outputTime = moment(`${time.hour}:${time.min}${time.hourSystem}`, 'hh:mma').format(
        'HH:mm'
      )
      changeHandler?.(outputTime)
      setOpen(false)
    }
  }, [time])

  const handleSetTime = useCallback((obj) => {
    const nullTime = moment(obj.hour + ':' + obj.min, 'hh:mma')
    if (obj?.hourSystem) {
      if (obj.hourSystem === 'AM' && obj.hour === '12') {
        obj.hourSystem = nullTime.subtract(12, 'hours').format('A')
      } else if (obj.hourSystem === 'PM' && obj.hour !== '12') {
        obj.hourSystem = nullTime.add(12, 'hours').format('A')
      } else {
        obj.hourSystem = nullTime.format('A')
      }
    }
    setTime(obj)
  }, [])

  const handleToggle = useCallback(() => {
    setOpen((prevState) => !prevState)
  }, [])

  const removeAll = useCallback(async () => {
    setOpen(false)
    changeHandler?.('')
    await sleep(50)
    setTime({ hour: null, min: null, hourSystem: null })
  })

  const isConfirmable = !_.isEmpty(time.hour) && !_.isEmpty(time.min) & !_.isEmpty(time.hourSystem)
  return (
    <View style={[openStyle, props?.selectorStyle]} accessibilityState={{ expanded: !!open }}>
      <ThemedButton
        onPress={handleToggle}
        disabled={props.disabled}
        style={[
          manipulator.container('row', 'space-between', 'center'),
          {
            borderTopWidth: open ? 0 : 1,
            borderRightWidth: open ? 0 : 1,
            borderLeftWidth: open ? 0 : 1,
            borderBottomWidth: 1,
            borderRadius: open ? 0 : 5,
            backgroundColor: props.disabled ? palette.disable : 'transparent',
            minWidth: 150,
            paddingVertical: 8,
            paddingRight: 4,
            paddingLeft: 10
          },
          style
        ]}
        accessibilityRole='spinbutton'
      >
        <Text
          style={{ color: props.disabled ? palette.gray : palette.black }}
          accessibilityRole='text'
        >
          {_.isEmpty(time.hour) ? '-- : ' : `${time.hour} : `}
          {_.isEmpty(time.min) ? '-- ' : `${time.min} `}
          {_.isEmpty(time.hourSystem) ? '--' : `${time.hourSystem}`}
        </Text>
        <TouchableOpacity disabled={props.disabled} onPress={removeAll} accessibilityRole='button'>
          <MaterialIcons
            name='close'
            size={18}
            color={props.disabled ? 'transparent' : palette.gray}
          />
        </TouchableOpacity>
      </ThemedButton>
      {open && (
        <>
          <View style={manipulator.container('row', 'space-between', 'center')}>
            <ScrollView
              style={{
                height: 80,
                borderRightWidth: 1,
                borderRightColor: palette.gray,
                paddingVertical: 3
              }}
              accessibilityRole='menubar'
              nestedScrollEnabled
            >
              {_.range(12).map((each, index) => {
                const hour = +each === 0 ? '12' : +each < 10 ? `0${each}` : each.toString()
                return (
                  <TouchableOpacity
                    key={`hour-${index}`}
                    onPress={() => handleSetTime({ ...time, hour })}
                    accessibilityRole='menuitem'
                  >
                    <Text style={{ textAlign: 'center', lineHeight: 20 }}>{hour}</Text>
                  </TouchableOpacity>
                )
              })}
            </ScrollView>
            <ScrollView
              style={{
                height: 80,
                borderRightWidth: 1,
                borderRightColor: palette.gray,
                paddingVertical: 3
              }}
              accessibilityRole='menubar'
              nestedScrollEnabled
            >
              {_.range(60).map((each, index) => {
                const min = +each < 10 ? `0${each}` : each.toString()
                return (
                  <TouchableOpacity
                    key={`min-${index}`}
                    onPress={() => handleSetTime({ ...time, min })}
                    accessibilityRole='menuitem'
                  >
                    {props.interval ? (
                      min % props.interval === 0 ? (
                        <Text style={{ textAlign: 'center', lineHeight: 20 }}>{min}</Text>
                      ) : null
                    ) : (
                      <Text style={{ textAlign: 'center', lineHeight: 20 }}>{min}</Text>
                    )}
                  </TouchableOpacity>
                )
              })}
            </ScrollView>
            <ScrollView
              style={{ height: 80, paddingVertical: 3 }}
              accessibilityRole='menubar'
              nestedScrollEnabled
            >
              {['AM', 'PM'].map((each, index) => {
                return (
                  <TouchableOpacity
                    key={`${each}-${index}`}
                    onPress={() => handleSetTime({ ...time, hourSystem: each })}
                    accessibilityRole='menuitem'
                  >
                    <Text style={{ textAlign: 'center', lineHeight: 20 }}>{each}</Text>
                  </TouchableOpacity>
                )
              })}
            </ScrollView>
          </View>
          <TouchableOpacity
            onPress={isConfirmable ? confirm : ''}
            style={{
              paddingVertical: 6,
              margin: 5,
              backgroundColor: isConfirmable ? palette.primary : palette.lightGray
            }}
            accessibilityRole='button'
            accessibilityState={{ disabled: !isConfirmable }}
          >
            <Text style={{ color: palette.white, textAlign: 'center' }}>{t('FORM.BUTTON_OK')}</Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  )
})

export const ThemedMapSnapshot = observer((props) => {
  const { payload, containerStyle = {} } = props
  const { t } = useStores()
  const [error, setError] = useState(null)
  const [image, setImage] = useState(null)
  const [init, setInit] = useState(false)
  const [clickable, setClickable] = useState(true)

  const handleGoogleSnapshot = async (type) => {
    if (
      !_.isUndefined(_.get(payload, 'address')) ||
      (!_.isUndefined(_.get(payload, 'lat')) && !_.isUndefined(_.get(payload, 'lng')))
    ) {
      const addr = _.isNil(_.get(payload, 'lat'))
        ? _.get(payload, 'address')
        : `${_.get(payload, 'lat')},${_.get(payload, 'lng')}`
      setError(null)
      setClickable(false)
      setImage(`${getConfig().API_BASE_URL}media/mapsnapshot?addr=${addr}`)
      setTimeout(() => {
        setClickable(true)
      }, 60000)
    } else {
      type !== 'firstTime' && setError(t('LOCATION.GET_GOOGLE_MAP_SNAPSHOT_HINT'))
      setImage(null)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setInit(true)
    }, 700)
  }, [])

  useEffect(() => {
    if (
      _.isEmpty(_.get(props, 'payload.address')) &&
      _.isEmpty(_.get(props, 'payload.lat')) &&
      _.isEmpty(_.get(props, 'payload.lng'))
    ) {
      setError(null)
      setImage(null)
      setClickable(true)
    }
  }, [_.get(props, 'payload.address'), _.get(props, 'payload.lat'), _.get(props, 'payload.lng')])

  useEffect(() => {
    if (init) {
      handleGoogleSnapshot('firstTime')
    }
  }, [init])

  return (
    <View
      style={[
        {
          position: 'relative',
          height: 310,
          width: 375,
          marginBottom: 10,
          backgroundColor: palette.panelBackground,
          justifyContent: 'center',
          alignItems: 'center'
        },
        containerStyle
      ]}
    >
      <ThemedButton
        disabled={!clickable}
        style={[
          {
            position: 'absolute',
            right: -185,
            top: _.isEmpty(image) ? (_.isEmpty(error) ? -180 : -144) : -80,
            height: 'auto'
          },
          clickable ? { backgroundColor: palette.primary } : {}
        ]}
        onPress={handleGoogleSnapshot}
        accessibilityState={{ disabled: !clickable }}
      >
        {t('LOCATION.GET_GOOGLE_MAP_SNAPSHOT')}
      </ThemedButton>
      {!_.isEmpty(error) && <Text style={{ color: palette.warning }}>{error}</Text>}
      {_.isEmpty(image) ? (
        <StaticImage
          style={[template.center, { width: 27, height: 40 }]}
          source={require('../assets/mapsnapshot-logo.png')}
          accessibilityLabel='Initial-map-icon'
        />
      ) : (
        <Image
          style={{ width: 375, height: 250 }}
          source={{ uri: _.replace(image, 'size', ImageCroppingSizeType.AdminImageUpload1x) }}
          accessibilityRole='image'
        />
      )}
    </View>
  )
})

export const ThemedButton = inject('store')(ThemedButtonOrigin)

// const Dropdown = forwardRef(function Dropdown (props, ref) {
//   const { disabled, containerStyle, hideOnBlur = true, fullWidth = true } = props
//   const [ opening, setOpening ] = useState(true)

//   const handleToggle = useCallback(() => {
//     setOpening(opening => !opening)
//   }, [])

//   const handleBlur = useCallback(() => {
//     hideOnBlur && setOpening(false)
//   }, [hideOnBlur])

//   useImperativeHandle(ref, () => ({
//     toggle: handleToggle,
//     close: () => setOpening(false),
//     open: () => setOpening(true)
//   }), [])

//   const result = <View style={props.style}>
//     <HoverableOpacity onBlur={handleBlur} style={[props.labelStyle, { justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }, props.layoutStyle]} disabled={disabled} onPress={handleToggle}>
//       {props.label}
//     </HoverableOpacity>
//     <Wrapper>
//       <View style={[template.shadow, {
//         width: fullWidth ? '100%' : undefined,
//         backgroundColor: palette.white,
//         top: '100%',
//         minWidth: 16 * 6,
//         position: 'absolute',
//         borderRadius: 5,
//         zIndex: 1000
//       }, { display: opening ? undefined : 'none' }, containerStyle]}>
//         <ScrollView style={[{
//           maxHeight: 300,
//           position: 'relative',
//           boxSizing: 'border-box',
//           zIndex: 2000
//         }, props.menuStyle]}>
//           {props.children}
//         </ScrollView>
//       </View>
//     </Wrapper>

//   </View>
//   return Platform.OS === 'web' ? <div>{result}</div> : result
// })

export function ThemedDropdown(props) {
  const { isMobile } = useLayout()
  const { t } = useStores()
  const {
    items = [],
    searchable = false,
    label,
    menuStyle = {},
    showActivedItem = false,
    numberOfLines = undefined,
    ...restProps
  } = props
  const [selected, setSelected] = useState()
  const [searchText, setSearchText] = useState('')
  const index = React.useMemo(() => {
    const index = items.findIndex((item) => item.value === props.value)
    return index < 0 ? selected || 0 : index
  }, [selected, items, props.value])
  const dropdownRef = useRef()

  useEffect(() => {
    dropdownRef.current?.close()
  }, [selected])

  const handleChangeText = useCallback((text) => setSearchText(text), [])
  return (
    <Dropdown
      ref={dropdownRef}
      label={
        _.isUndefined(props.label) && items.length === 0 ? (
          <View />
        ) : (
          label || (
            <>
              {typeof items[index].label === 'string' ? (
                <Text numberOfLines={numberOfLines} style={props.textStyle}>
                  {items[index].label}
                </Text>
              ) : typeof items[index].label === 'function' ? (
                items[index].label({ inner: false })
              ) : (
                items[index].label
              )}
              <MaterialIcons
                style={{ paddingLeft: 6 }}
                name='arrow-drop-down'
                color={props.arrowColor || palette.black}
                size={16}
              />
            </>
          )
        )
      }
      menuStyle={[
        { maxHeight: 300, paddingVertical: 4, margin: isMobile || !searchable ? 10 : 20 },
        menuStyle
      ]}
      {...restProps}
    >
      <>
        {searchable && _.size(items) > 3 && (
          <ThemedTextInput
            placeholder={t('COMMON.SEARCH_PLACEHOLDER')}
            iconName='search'
            containerStyle={{ marginBottom: 10 }}
            onChangeText={handleChangeText}
          />
        )}
        {_.map(searchable ? getFuzzySearchItems(items, searchText) : items, (item, key) => {
          const { disableRule = false } = item
          const isFirst = key === 0
          const isLast = key + 1 === items.length
          return (
            (showActivedItem || +key !== +index || searchable) && (
              <HoverableOpacity
                onPress={() => {
                  !disableRule && setSelected(key)
                  !disableRule && typeof props.onChange === 'function' && props.onChange(item.value)
                }}
                key={key}
                style={{
                  borderBottomWidth: isLast ? 0 : 1,
                  borderBottomColor: palette.border,
                  paddingTop: isFirst ? 0 : 10,
                  paddingBottom: isLast ? 0 : 10
                }}
              >
                <View style={[{ flexDirection: 'row' }, props?.textContainerStyle]}>
                  {typeof item.label === 'string' ? (
                    <Text style={[props.textStyle, { color: palette.black }]}>{item.label}</Text>
                  ) : typeof item.label === 'function' ? (
                    item.label({ inner: true })
                  ) : (
                    item.label
                  )}
                </View>
              </HoverableOpacity>
            )
          )
        })}
      </>
    </Dropdown>
  )
}

export function ThemedActivityIndicator(props) {
  const source =
    Platform.OS === 'android'
      ? require('../assets/animation_static.png')
      : require('../assets/animation.gif')
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <StaticImage
        source={source}
        style={[template.center, { width: 110, height: 42 }]}
        accessibilityRole='image'
      />
    </View>
  )
}

export function ThemedOptionGroupInput({
  label,
  options,
  disabled = false,
  value,
  onChange,
  skipOnValueChange,
  ...props
}) {
  const [optionValue, setOptionValue] = useState(value?.option)
  const [inputValue, setInputValue] = useState(value?.input || '')

  useEffect(() => {
    onChange?.({ input: inputValue || '', option: optionValue })
  }, [optionValue, inputValue])

  return (
    <View style={[manipulator.container('row', 'flex-start', 'center')]}>
      <ThemedPicker
        containerStyle={{ flex: 1 }}
        disabled={disabled}
        value={optionValue}
        items={options}
        onValueChange={setOptionValue}
        skipOnValueChange={skipOnValueChange}
      />
      <ThemedTextInput
        style={{ flex: 1, marginLeft: 10 }}
        disabled={disabled}
        value={inputValue}
        onChangeText={setInputValue}
      />
    </View>
  )
}

export const ThemedRegionLandmarkSelector = (props) => {
  const { data } = useStores()
  const {
    value,
    onChange,
    regionItems = [],
    countryItems = [],
    regionConfig = {},
    countryConfig = {},
    landmarkConfig = {},
    skipOnValueChange
  } = props
  const [formValue, setFromValue] = useState({
    region: value?.region || '',
    country: value?.country || '',
    landmark: value?.landmark || []
  })
  const [landmarkItems, setLandmarkItems] = useState([])
  const [landmarkRefreshing, setLandmarkRefrfeshing] = useState(false)
  const [landmarkFilter, setLandmarkFilter] = useState({
    text: '',
    filteredItems: undefined,
    categoryList: []
  })

  const handleChange = useCallback(
    (inputValue, changeKey) => {
      const currentValue = {
        ...(formValue || {}),
        [changeKey]: inputValue
      }

      if (changeKey === 'region') {
        // 如果變動的是 region 選單
        currentValue.country = ''
        currentValue.landmark = []
      }

      if (changeKey === 'country') {
        // 如果變動的是 country 選單
        currentValue.landmark = []
        inputValue && getLandmarkItems(inputValue)
      }

      setFromValue(currentValue)
      onChange?.(currentValue)
    },
    [value]
  )

  const getLandmarkItems = useCallback(
    async (countryValue) => {
      setLandmarkRefrfeshing(true)
      const result = await data.fetchLandmarkOptions(
        { country: countryValue, page: 0 },
        { shouldUpdateData: false }
      )
      const landmarkCategory = _.find(countryItems, ({ uuid }) => uuid === countryValue)
      setLandmarkItems(formatPickerItem(_.get(result, 'data.landmark.list', []), 'title'))
      setLandmarkFilter({
        text: '',
        filteredItems: undefined,
        categoryList: [{ label: landmarkCategory?.title, uuid: landmarkCategory?.uuid }]
      })
      setLandmarkRefrfeshing(false)
    },
    [countryItems]
  )

  const handleLandmarkFilterChange = useCallback(
    async (text) => {
      const { title: categoryTitle, uuid: categoryUuid } =
        _.find(countryItems, ({ uuid }) => uuid === formValue?.country) || {}
      if (text) {
        const result = await data.fetchLandmarkOptions(
          { country: formValue.country, label: text, page: 0 },
          { shouldUpdateData: false }
        )
        setLandmarkFilter({
          text,
          filteredItems: formatPickerItem(result?.data?.landmark?.list || [], 'title'),
          categoryList: [{ label: categoryTitle, uuid: categoryUuid }]
        })
      } else {
        setLandmarkFilter({
          text,
          filteredItems: undefined,
          categoryList: [{ label: categoryTitle, uuid: categoryUuid }]
        })
      }
    },
    [formValue?.country]
  )

  useEffect(() => {
    if (formValue?.country) {
      getLandmarkItems(formValue?.country)
    }
  }, [])

  return (
    <View style={[manipulator.container('row', 'flex-start', 'center'), { flexWrap: 'wrap' }]}>
      <ThemedPicker
        containerStyle={{ marginRight: 10 }}
        value={value.region}
        items={regionItems}
        onValueChange={(value) => handleChange(value, 'region')}
        skipOnValueChange={skipOnValueChange}
        {...regionConfig}
      />
      <ThemedPicker
        containerStyle={{ marginRight: 10 }}
        value={value.country}
        items={_.filter(countryItems, ({ regionUuid }) => regionUuid === formValue?.region)}
        onValueChange={(value) => handleChange(value, 'country')}
        skipOnValueChange={skipOnValueChange}
        {...countryConfig}
      />
      <ThemedTagSelector
        containerStyle={{ flex: 1 }}
        disabled={!formValue?.country || landmarkRefreshing}
        value={value.landmark}
        groupKey='countryUuid'
        limit={1}
        items={landmarkItems}
        category={landmarkFilter?.categoryList}
        filteredItems={landmarkFilter?.filteredItems}
        onValueChange={(value) => handleChange(value, 'landmark')}
        onChangeFilters={handleLandmarkFilterChange}
        showCategoryFilter={false}
        {...landmarkConfig}
      />
    </View>
  )
}

export const ThemedWeekdayTimeRangePanel = (props) => {
  const { t } = useStores()
  const { value: propsValue, showTimeRangeLast = false, firstDayOfWeek = 0, onChange } = props
  const initWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] // 需對齊 moment.weekdaysMin() 英文排序
  const sortedWeekdays = useMemo(() => {
    return {
      min: _.concat(
        _.slice(moment.weekdaysMin(), firstDayOfWeek),
        _.slice(moment.weekdaysMin(), 0, firstDayOfWeek)
      ), // 根據語言顯示用
      long: _.concat(
        _.slice(moment.weekdays(), firstDayOfWeek),
        _.slice(moment.weekdays(), 0, firstDayOfWeek)
      ), // 根據語言顯示用
      init: _.concat(_.slice(initWeek, firstDayOfWeek), _.slice(initWeek, 0, firstDayOfWeek)) // 對齊 API 的 key
    }
  }, [firstDayOfWeek])
  const initTimeRange = {
    from: '00:00',
    to: '23:59',
    ...(showTimeRangeLast ? { last: '23:59' } : {})
  }
  const [isAllDay, setIsAllDay] = useState(propsValue?.isAllDay || false)
  const [weekdaysTimeRange, setWeekdaysTimeRange] = useState(
    propsValue?.weekdays ||
      _.reduce(
        sortedWeekdays.init,
        (result, weekdayName) => {
          return { ...result, [weekdayName]: initTimeRange }
        },
        {}
      )
  )

  useEffect(() => {
    onChange?.({
      isAllDay,
      weekdays: weekdaysTimeRange
    })
  }, [isAllDay, weekdaysTimeRange])

  const handleTimeRangeChange = useCallback(
    (value, weekdayName, timeRangeType) => {
      if (value && weekdayName && timeRangeType) {
        setWeekdaysTimeRange(
          _.reduce(
            weekdaysTimeRange,
            (result, timeRange, weekdaysKey) => {
              return {
                ...result,
                [weekdaysKey]:
                  weekdaysKey === weekdayName ? { ...timeRange, [timeRangeType]: value } : timeRange
              }
            },
            {}
          )
        )
      }
    },
    [weekdaysTimeRange]
  )

  const copyFirstToAllTimeSelector = useCallback(() => {
    const { from, to, last } = _.reduce(
      sortedWeekdays.init,
      (result, weekdayName) =>
        _.isEmpty(result) && weekdaysTimeRange[weekdayName]
          ? weekdaysTimeRange[weekdayName]
          : result,
      {}
    )
    setWeekdaysTimeRange(
      _.reduce(
        weekdaysTimeRange,
        (result, eachTimeRange, weekdayName) => ({
          ...result,
          [weekdayName]: { from, to, ...(showTimeRangeLast ? { last } : {}) }
        }),
        {}
      )
    )
  }, [weekdaysTimeRange])

  const handleIsAllDayChange = useCallback(
    (value) => {
      setIsAllDay(value)
      setWeekdaysTimeRange(
        _.reduce(
          weekdaysTimeRange,
          (result, { last }, weekdayName) => ({
            ...result,
            [weekdayName]: {
              from: initTimeRange.from,
              to: initTimeRange.to,
              ...(showTimeRangeLast ? { last } : {})
            }
          }),
          {}
        )
      )
    },
    [weekdaysTimeRange]
  )

  return (
    <View>
      <View style={[manipulator.container('row', 'flex-start', 'center')]}>
        <View style={[manipulator.container('row', 'flex-start', 'center')]}>
          {_.map(sortedWeekdays.init, (weekdayValue, index) => {
            const isActive = _.keys(weekdaysTimeRange).includes(weekdayValue)
            return (
              <ThemedButton
                key={`simpleWeek-${weekdayValue}`}
                onPress={() => {
                  setWeekdaysTimeRange(
                    isActive
                      ? _.omit(weekdaysTimeRange, weekdayValue)
                      : { ...weekdaysTimeRange, [weekdayValue]: initTimeRange }
                  )
                }}
                style={{
                  marginRight: 10,
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  backgroundColor: palette.white,
                  borderWidth: '1px',
                  borderColor: isActive ? palette.primary : palette.negative
                }}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: isActive ? palette.primary : palette.negative
                  }}
                >
                  {sortedWeekdays.min[index]}
                </Text>
              </ThemedButton>
            )
          })}
        </View>
        <ThemedCheckBox
          value={propsValue?.isAllDay}
          label={t('LOCATION.LABEL_ISALLDAY')}
          onValueChange={handleIsAllDayChange}
        />
      </View>
      <View style={[manipulator.container('row', 'flex-start', 'flex-start'), { paddingTop: 20 }]}>
        <View>
          {_.map(sortedWeekdays.init, (weekdayValue, index) => {
            return _.keys(weekdaysTimeRange).includes(weekdayValue) ? (
              <View
                key={`weekdayTimeRange-${weekdayValue}-${JSON.stringify(
                  _.get(propsValue, `weekdays.${weekdayValue}`)
                )}`}
                style={[
                  manipulator.container('row', 'flex-start', 'center'),
                  { paddingBottom: 10, minHeight: 48 }
                ]}
              >
                <Text style={{ minWidth: 60, maxWidth: 60 }}>{sortedWeekdays.long[index]}</Text>
                {isAllDay ? (
                  <Text>{t('PRODUCT.ALL_DAY_LABEL')}</Text>
                ) : (
                  <View style={[manipulator.container('row', 'flex-start', 'center')]}>
                    <ThemedTimeSelector
                      value={
                        _.get(propsValue, `weekdays.${weekdayValue}.from`) || initTimeRange.from
                      }
                      onChange={(value) => handleTimeRangeChange(value, weekdayValue, 'from')}
                    />
                    <Text style={{ marginHorizontal: 5 }}>～</Text>
                    <ThemedTimeSelector
                      value={_.get(propsValue, `weekdays.${weekdayValue}.to`) || initTimeRange.to}
                      onChange={(value) => handleTimeRangeChange(value, weekdayValue, 'to')}
                    />
                  </View>
                )}
                {showTimeRangeLast && (
                  <View style={[manipulator.container('row', 'flex-start', 'center')]}>
                    <Text style={{ marginLeft: isAllDay ? 0 : 5, marginRight: 10 }}>
                      {t('LOCATION.LABEL_WEEKDAYS_LAST')}
                    </Text>
                    <ThemedTimeSelector
                      value={
                        _.get(propsValue, `weekdays.${weekdayValue}.last`) || initTimeRange.last
                      }
                      onChange={(value) => handleTimeRangeChange(value, weekdayValue, 'last')}
                    />
                  </View>
                )}
              </View>
            ) : null
          })}
        </View>
        {!_.isEmpty(weekdaysTimeRange) && (
          <ThemedButton
            style={{
              width: 'auto',
              height: 38,
              backgroundColor: 'transparent',
              paddingHorizontal: 5,
              paddingVertical: 0,
              marginLeft: 20
            }}
            onPress={() => copyFirstToAllTimeSelector()}
          >
            <MaterialIcons name='content-copy' color={palette.primary} size={24} />
            <Text style={{ marginLeft: 5, color: palette.primary }}>
              {t('PUBLISH.SESSION_TITLE_COPY_WEEK')}
            </Text>
          </ThemedButton>
        )}
      </View>
    </View>
  )
}

export function ConditionalWrapper({ wrapper, children }) {
  return wrapper(children)
}

export function LockedWrapper(props) {
  const { t } = useStores()
  const router = useRouter()
  const { role } = usePermission()
  const {
    isLocked = true,
    hideLockIcon = false,
    containerStyle = {},
    wrapperStyle = {},
    color,
    children,
    iconStyle = {},
    labelStyle = {}
  } = props
  const [modalConfig, setModalConfig] = useState()
  const defaultTextColor = isLocked ? palette.disableLight : palette.text
  const hasSubscriptionPermission = [ROLE_TITLE.STORE_OWNER, ROLE_TITLE.REZIO_EXPERT].includes(role)

  const content =
    typeof children === 'string' ? (
      <Text style={[template.text, { color: color || defaultTextColor }, labelStyle]}>
        {children}
      </Text>
    ) : (
      children
    )

  const handleToUpgrade = useCallback(() => {
    setModalConfig()
    router.push({ pathname: '/myplan/subscription' })
  }, [])

  if (isLocked === false) {
    return (
      <View style={[manipulator.container('row', 'flex-start', 'center'), wrapperStyle]}>
        {content}
      </View>
    )
  }

  return (
    <View style={containerStyle}>
      {isLocked === false ? (
        <View style={[manipulator.container('row', 'flex-start', 'center'), wrapperStyle]}>
          {content}
        </View>
      ) : (
        <HoverableOpacity
          onPress={() => setModalConfig(true)}
          style={[manipulator.container('row', 'flex-start', 'center'), wrapperStyle]}
        >
          {content}
          {!hideLockIcon && (
            <MaterialIcons
              name='lock'
              size={14}
              color={color || palette.disableLight}
              style={[{ marginLeft: 10 }, iconStyle]}
            />
          )}
        </HoverableOpacity>
      )}
      <PageWidthModal isVisible={modalConfig}>
        <View style={{ borderWidth: 1, borderColor: palette.border, borderRadius: 5, padding: 20 }}>
          <Text
            style={{
              fontSize: 20,
              lineHeight: 40,
              color: palette.primary,
              paddingBottom: 8,
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {t('PERMISSION.TITLE_IS_INVAILD')}
          </Text>
          <Text style={{ fontSize: 14, textAlign: 'center' }}>
            {t('PERMISSION.TIPS_IS_INVAILD', {
              context: hasSubscriptionPermission ? 'OWNER' : undefined
            })}
          </Text>
        </View>
        <PageStepControl
          buttons={
            hasSubscriptionPermission
              ? [
                  {
                    type: 'negative',
                    text: t('FORM.BUTTON_CANCEL'),
                    onPress: () => setModalConfig()
                  },
                  {
                    type: 'positive',
                    text: t('SUBSCRIPTION.HEADER_UPDATE_TIER'),
                    onPress: handleToUpgrade
                  }
                ]
              : [
                  {
                    type: 'positive',
                    text: t('FORM.BUTTON_CONFIRM'),
                    onPress: () => setModalConfig()
                  }
                ]
          }
        />
      </PageWidthModal>
    </View>
  )
}

function DefaultPermissionDenidedView(props) {
  const { t } = useStores()
  const router = useRouter()
  const { role } = usePermission()
  const hasSubscriptionPermission = [ROLE_TITLE.STORE_OWNER, ROLE_TITLE.REZIO_EXPERT].includes(role)

  const handleToUpgrade = useCallback(() => {
    props?.hideModal()
    router.push({ pathname: '/myplan/subscription' })
  }, [])
  return (
    <>
      <View style={{ borderWidth: 1, borderColor: palette.border, borderRadius: 5, padding: 20 }}>
        <Text
          style={{
            fontSize: 20,
            lineHeight: 40,
            color: palette.primary,
            paddingBottom: 8,
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          {t('PERMISSION.TITLE_IS_INVAILD')}
        </Text>
        <Text style={{ fontSize: 14, textAlign: 'center' }}>
          {t('PERMISSION.TIPS_IS_INVAILD', {
            context: hasSubscriptionPermission ? 'OWNER' : undefined
          })}
        </Text>
      </View>
      <PageStepControl
        buttons={
          hasSubscriptionPermission
            ? [
                { type: 'negative', text: t('FORM.BUTTON_CANCEL'), onPress: props?.hideModal },
                {
                  type: 'positive',
                  text: t('SUBSCRIPTION.HEADER_UPDATE_TIER'),
                  onPress: handleToUpgrade
                }
              ]
            : [{ type: 'positive', text: t('FORM.BUTTON_CONFIRM'), onPress: props?.hideModal }]
        }
      />
    </>
  )
}

export function LockedButton(props) {
  const { isLocked, children, DeniedView, ...restProps } = props
  const [visible, setVisible] = useState(false)
  const hideModal = useCallback(() => setVisible(false), [])
  if (isLocked === false) {
    return <ThemedButton {...restProps}>{children}</ThemedButton>
  }
  const content =
    typeof children === 'string' ? (
      <Text
        style={[
          manipulator.text(palette.white),
          props.disabled && { color: 'black' },
          props.textStyle
        ]}
        accessibilityRole='text'
      >
        {children}
      </Text>
    ) : (
      children
    )
  const PermissionView = DeniedView ?? DefaultPermissionDenidedView
  return (
    <View>
      <ThemedButton
        {...restProps}
        style={[{ backgroundColor: palette.disableLight }, props.style]}
        onPress={() => setVisible(true)}
      >
        <Text>{content}</Text>
        <MaterialIcons
          name='lock'
          size={14}
          color={palette.white}
          style={[{ marginLeft: 10 }, props.iconStyle]}
        />
      </ThemedButton>
      <PageWidthModal isVisible={visible}>
        <PermissionView hideModal={hideModal} />
      </PageWidthModal>
    </View>
  )
}

export const ThemedCustomItems = (props) => {
  const {
    items = [],
    value = [],
    type = 'include' | 'exclude',
    containerStyle,
    isSwapItem = false,
    showSortedIndex = false,
    actionButtons = [CustomItemsButtonType.Delete],
    actionButtonsStyle = {},
    onSwapItems,
    onRemove
  } = props
  const customItems = _.isEmpty(items) ? value : items
  const itemCount = customItems.length
  let style = {}

  const itemStyle = (index) => {
    switch (true) {
      case itemCount === 1 && !isSwapItem:
        style = {
          padding: 0
        }
        break
      case itemCount > 1 && index === 0:
        style = [manipulator.border('bottom', 'light'), { paddingBottom: 10 }]
        break
      case itemCount > 1 && index !== itemCount - 1:
        style = [manipulator.border('bottom', 'light'), { paddingTop: 10, paddingBottom: 10 }]
        break
      case itemCount > 1 && index === itemCount - 1:
        style = {
          paddingTop: 10
        }
        break
      default:
        style = {
          paddingBottom: 10
        }
        break
    }

    return style
  }

  const swapItem = useCallback(
    (from, to) => {
      if (customItems.length === 1) return customItems
      customItems.splice(to, 1, customItems.splice(from, 1, customItems[to])[0])
      onSwapItems(customItems)
    },
    [customItems]
  )

  const ItemWrapper = ({ index, item, containerStyle, onRemove }) => {
    const isFirstItem = index === 0
    const isLastItem = index === itemCount - 1

    return (
      <View style={[template.rowContainer, itemStyle(index), containerStyle]}>
        {showSortedIndex ? (
          <Text
            style={{
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto',
              width: 40,
              textAlign: 'center'
            }}
          >
            {+index + 1}
          </Text>
        ) : null}
        {_.isString(item.label) ? (
          <Text
            style={[
              props.onAdd ? { width: '100%' } : { flex: 10 },
              showSortedIndex ? { marginLeft: 15, flex: 2 } : {}
            ]}
          >
            {item.label}
          </Text>
        ) : (
          item.label
        )}
        <View style={[manipulator.container('row', 'flex-end'), actionButtonsStyle]}>
          {actionButtons.map((name, buttonIndex) => {
            const isUpWardButton = name === CustomItemsButtonType.Upward
            const isDownWardButton = name === CustomItemsButtonType.Downward
            const color =
              (isUpWardButton && isFirstItem) || (isDownWardButton && isLastItem)
                ? palette.lightGray
                : palette.gray

            return (
              <ThemedButton
                key={buttonIndex}
                style={[
                  { backgroundColor: 'transparent', marginRight: 5, height: 'auto', padding: 0 }
                ]}
                disabled={(isUpWardButton && isFirstItem) || (isDownWardButton && isLastItem)}
                onPress={
                  isUpWardButton
                    ? () => swapItem(index, index - 1)
                    : isDownWardButton
                    ? () => swapItem(index, index + 1)
                    : onRemove
                }
              >
                <MaterialIcons name={name} color={color} size={18} />
              </ThemedButton>
            )
          })}
        </View>
      </View>
    )
  }

  return (
    !_.isEmpty(customItems) &&
    customItems.map((item, index) => (
      <ItemWrapper
        key={index}
        item={item}
        index={index}
        containerStyle={containerStyle}
        onRemove={() => onRemove(item.label, type, customItems, index)}
      />
    ))
  )
}
