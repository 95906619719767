import { ChannelType } from '@rezio/components/channel/constants'
import Core from '@rezio/core'
import {
  TIERS,
  TIER_PLATFORM,
  SUBSCRIPTION_PLAN_PERIOD,
  SubscriptionPaymentType,
  TIER_SERIES
} from '@rezio/core/permission'
import { store } from '@rezio/core/stores'
import { PricingPolicyType, OrderStatus, PayStatus } from '@rezio/utils/types'
import { identityUuids } from '@rezio/core/stores/index'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import qs from 'query-string'
import React from 'react'
import { Text, View } from 'react-native'
import parse from 'url-parse'
import { instance as i18n } from '@rezio/core/i18n'

// 僅接受英文或 - 和 空白字元，首尾限英文
export const passportNameRegex = /^([a-zA-Z]|[a-zA-Z][a-zA-Z\s-]*[a-zA-Z])$/

export const formatErrorMessage = (errors, keyMapping = {}) => {
  return i18n.t('FORM.ERRORS_VALIDATE_FAILED_MESSAGE', {
    errors: errors
      .map(({ _key, ...error }) =>
        _.map(error, ({ _message }, errorType) => {
          const column = _.get(keyMapping, _key)
          return i18n.t('FORM.ERRORS_VALIDATE_FAILED_FIELD', {
            column: column ? i18n.t(column) : _key,
            error: i18n.t(..._message)
          })
        })
      )
      .join('\n')
  })
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const getObjectList = (targetObj) => {
  return Object.keys(targetObj).reduce((result, key) => {
    let next = {}
    const isValueProperty = parseInt(key, 10) >= 0
    if (!isValueProperty) {
      next = _.set({}, key.toString().toUpperCase(), targetObj[key])
    }
    return {
      ...result,
      ...next
    }
  }, {})
}

export const formatUTMUrl = (defaultUrl, utmConfig) => {
  const url = parse(defaultUrl, qs.parse)
  url.set('query', { ...url.query, ...utmConfig })
  return url.toString()
}

export const formatQueryFilters = (queryFilters) => {
  const filters = _.map(_.split(queryFilters, ','), (filter) => {
    const path = _.get(_.split(filter, ':'), '0')
    const value = _.get(_.split(filter, ':'), '1')
    const type = path === 'text' ? path : 'equal'
    return { type, path, value }
  })
  return filters
}

export const formatQueryFiltersToObject = (queryFilters) => {
  return (
    queryFilters?.split(',')?.reduce((acc, filter) => {
      const filterSplitArr = filter.split(':')
      if (filterSplitArr.length === 2) {
        return Object.assign(acc, { [filterSplitArr?.[0]]: filterSplitArr?.[1] })
      }
      return acc
    }, {}) ?? null
  )
}

export function formatName(firstName = '', lastName = '', gender = '', personalTitle) {
  const formatedFirstName = _.isEmpty(firstName) ? '' : firstName
  const formatedLastName = _.isEmpty(lastName) ? '' : lastName
  const name = (
    formatedLastName.match('^[a-zA-Z]+')
      ? `${formatedFirstName} ${formatedLastName}`
      : `${formatedLastName}${formatedFirstName}`
  ).trim()

  if (
    !(
      gender &&
      ['MALE', 'M', 'ORDER.GENDER_MALE', 'FEMALE', 'F', 'ORDER.GENDER_FEMALE'].includes(
        gender.toUpperCase()
      )
    ) &&
    !personalTitle
  ) {
    return name
  }

  if (personalTitle) {
    return i18n.t(`COMMON.NAME_PERSONAL_TITLE_${personalTitle.toUpperCase()}`, { name })
  } else if (['MALE', 'M', 'ORDER.GENDER_MALE'].includes(gender.toUpperCase())) {
    return i18n.t('COMMON.NAME_MALE', { name })
  } else if (['FEMALE', 'F', 'ORDER.GENDER_FEMALE'].includes(gender.toUpperCase())) {
    return i18n.t('COMMON.NAME_FEMALE', { name })
  } else {
    return name
  }
}

export function formatTitleName(account) {
  return `${account?.title ? `${_.get(account, 'title')} - ` : ''}${formatName(
    _.get(account, 'firstName', ''),
    _.get(account, 'lastName', '')
  )}`
}

export const formatDatePickerItem = (dates, unit) => {
  return dates.map((each) => ({
    label: each + ' ' + (each > 1 ? i18n.t(`COMMON.${unit}S`) : i18n.t(`COMMON.${unit}`)),
    value: each,
    key: each
  }))
}

export const formatCurrencyPickerItem = (currencyItems) => {
  return _.sortBy(
    _.map(currencyItems, ({ title, code, uuid }) => {
      return {
        label: `${code}  ${title}`,
        value: uuid,
        key: uuid
      }
    }),
    ['label']
  )
}

export const formatPickerItem = (items = [], labelPath = 'title', valuePath = 'uuid') => {
  const isPhoneFormat = valuePath === 'code'
  return items.map((item) => {
    return {
      ...item,
      label: isPhoneFormat
        ? `${item.countrycode} - ${item.title} +${item.code}`
        : `${_.get(item, labelPath, _.get(item, 'name', '') || _.get(item, 'label', ''))}`,
      value: _.get(item, valuePath, _.get(item, 'uuid', '')) || item.label,
      description: _.get(item, 'description'),
      key: _.get(item, 'uuid') || item.label
    }
  })
}

export const formatPayType = (payTypeMap) => {
  return Object.keys(payTypeMap).map((item) => ({ title: item, value: payTypeMap[item] }))
}

export const pricePattern = (currencyUuid, rule) => {
  const core = _.get(Core, 'instance')
  const pattern = _.get(core.getOptionContent('currency', currencyUuid, { pattern: '' }), 'pattern')
  const decimal = _.size(pattern.split('.')[1])
  return decimal > 0 || rule ? /^\d+(\.\d{0,2})?$/ : /^\d+$/
}

export const formatCurrency = (currencyUuid, value, formatedType = true) => {
  const currency = _.get(Core, 'instance.options.currency')
  const pattern = _.get(_.find(currency, { uuid: currencyUuid }), 'pattern')
  const formatedValue = numeral(value).format(pattern)
  const numeralValue = _.toNumber(
    _.isString(formatedValue) ? formatedValue.replace(/,/g, '') : formatedValue
  )

  return formatedType ? formatedValue : numeralValue
}

export const formatSymbol = (currencyUuid, target) => {
  const core = Core.getInstance()
  const currency = _.get(core, 'options.currency')

  if (_.isUndefined(currency)) {
    core.fetchOptions(['currency'])
  }
  return _.isEmpty(target)
    ? `${_.get(_.find(currency, { uuid: currencyUuid }), 'code')} ${_.get(
        _.find(currency, { uuid: currencyUuid }),
        'title'
      )}`
    : _.get(_.find(currency, { uuid: currencyUuid }), target) ||
        _.get(_.find(currency, { uuid: currencyUuid }), 'code')
}

export const exchangeRateObj = (
  currentCurrencyUuid,
  nextCurrencyUuid,
  currentPrice,
  format = true
) => {
  const core = Core.getInstance()
  const current = _.find(_.get(core, 'options.currency'), ['uuid', currentCurrencyUuid])
  const next = _.find(_.get(core, 'options.currency'), ['uuid', nextCurrencyUuid])
  const isSameCurrency = currentCurrencyUuid === nextCurrencyUuid
  const directExchangeRate = isSameCurrency
    ? undefined
    : _.get(current, `exchangeRateFor${next?.code}`, undefined)
  const targetPrice = directExchangeRate
    ? currentPrice * directExchangeRate
    : (currentPrice * current?.exchangeRateForUSD) / next?.exchangeRateForUSD

  const nextTargetPrice = next?.kkdayPrecision === 0 ? Math.round(targetPrice) : targetPrice

  const exchangeRateText = directExchangeRate
    ? `1 ${current?.code || current?.symbol} = ${directExchangeRate} ${next?.code || next?.symbol}`
    : `1 ${current?.code || current?.symbol} = ${current?.exchangeRateForUSD} USD, 1 USD = ${
        1 / next?.exchangeRateForUSD
      } ${next?.code || next?.symbol}`

  return {
    exchangeRateText,
    includedNextExchangeRate: !_.isEmpty(directExchangeRate),
    price: formatCurrency(nextCurrencyUuid, nextTargetPrice, format),
    isSameCurrency: currentCurrencyUuid === nextCurrencyUuid
  }
}

export const formatSymbolCurrency = (currencyUuid, value) => {
  const core = Core.getInstance()
  const symbolPricePattern = _.get(core, 'pricePattern', '{{symbol}}{{price}}')
  const currency = _.get(core, 'options.currency')

  if (_.isUndefined(currency)) {
    core.fetchOptions(['currency'])
    return `${value}`
  }

  const symbol =
    _.get(_.find(currency, { uuid: currencyUuid }), 'symbol') ||
    _.get(_.find(currency, { uuid: currencyUuid }), 'code') ||
    ''
  const pattern = _.get(_.find(currency, { uuid: currencyUuid }), 'pattern') || ''
  const price = numeral(value).format(pattern)
  return symbolPricePattern.replace('{{symbol}}', symbol).replace('{{price}}', price)
}

export const formatHourMin = (time) => {
  if (time === 0) {
    return '*'
  }
  let timeString = ''
  const hours = _.floor(time / 60)
  const mins = time % 60
  if (hours > 0) {
    timeString += `${hours} ${hours > 1 ? i18n.t('COMMON.HOURS') : i18n.t('COMMON.HOUR')} `
  }
  if (mins > 0) {
    timeString += `${mins} ${i18n.t('COMMON.MINS')}`
  }
  return timeString
}

export const formatTime = (time) => {
  const timePattern = _.get(Core, 'instance.timePattern')
  return moment(time).format(timePattern)
}

export const datePattern = () => {
  return _.get(Core, 'instance.datePattern')
}

export const formatDate = (date) => {
  const datePattern = _.get(Core, 'instance.datePattern')
  return moment(date).format(datePattern)
}

export const formatDateTime = (datetime) => {
  return `${formatDate(datetime)} ${formatTime(datetime)}`
}

export const formatTimezone = (timezoneTs) => {
  const hour = Math.floor(timezoneTs / 3600)

  let timezone = ''
  if (timezoneTs % 3600 > 0) {
    timezone = `${hour}:${(timezoneTs % 3600) / 60}`
  } else {
    timezone = `${hour}:00`
  }
  return (timezoneTs > 0 ? '+' : '') + timezone
}

export function printObject(obj) {
  return _.map(obj, (value, key) => (
    <View key={key}>
      <Text style={{ fontWeight: 'bold', fontSize: 16, paddingBottom: 5, paddingTop: 5 }}>
        {key}
      </Text>
      <View style={{ paddingLeft: 20 }}>
        {_.isNull(value) ? (
          <Text>NULL</Text>
        ) : typeof value === 'object' ? (
          printObject(value)
        ) : (
          <Text>{_.isUndefined(value) ? 'undefined' : value}</Text>
        )}
      </View>
    </View>
  ))
}

export function getNotEmpty(...args) {
  const value = _.get(...args)
  return _.isEmpty(value) ? undefined : value
}

export function getAsArray(source, path) {
  const value = _.get(source, path, [])
  return _.isArray(value) ? value : []
}

export const isUuid = (value) =>
  Boolean(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.exec(value))

export const formatPriceTier = (tier) => {
  if (!_.isString(tier)) {
    return {}
  }

  const tierPlatform = _.find(TIER_PLATFORM, (platform) => tier.includes(platform)) || '' // 可再考慮要不要保留
  const isBindDevicePlan = store?.core?.isBindDeviceTier(tier)

  const [tierWithoutPeriod, orgPeriod, periodYearValue] = tier.split(/[-_]/)
  // 如果去除平台之後為空就把平台塞回去 EX: KKDAY
  const authTier = tierWithoutPeriod.replace(tierPlatform, '')
    ? isBindDevicePlan
      ? tierWithoutPeriod.replace(/\+.*$/, '')
      : tierWithoutPeriod
    : tierPlatform
  const planPriceIsEqualToZeroOrNull = [
    TIERS.NONE,
    TIERS.FREE,
    TIERS.LITE,
    TIERS.SILVER,
    TIERS.ENTERPRISE
  ]
  const period = periodYearValue
    ? `${orgPeriod}-${periodYearValue}`
    : orgPeriod ||
      (planPriceIsEqualToZeroOrNull.includes(authTier)
        ? SUBSCRIPTION_PLAN_PERIOD.NONE
        : SUBSCRIPTION_PLAN_PERIOD.PERIOD)
  const tierSeries = (() => {
    switch (true) {
      case [TIERS.ENTERPRISE].includes(authTier):
        return TIER_SERIES.VIP
      case [TIER_PLATFORM.KKDAY].includes(tierPlatform):
        return TIER_SERIES.KKday
      case [TIERS.SILVER, TIERS.GOLD, TIERS.PLATINUM].includes(authTier):
      case [TIERS.BASIC, TIERS.PREMIUM].includes(authTier):
        return TIER_SERIES.JpVersion
      case [TIERS.GROW_YEAR, TIERS.RISE, TIERS.SUPER].includes(authTier):
      default:
        return TIER_SERIES.Default
    }
  })()

  return { priceTier: tier, period, tierPlatform, authTier, tierSeries }
}

export function getFuzzySearchItems(items = [], searchText = '') {
  const formatedUpperText = (text) => {
    return _.map(text.split(''), (each) => each.toUpperCase())
  }
  const initFuzzySearch = _.reverse(
    _.sortBy(
      _.reduce(
        items,
        (result, item, index) => {
          const targetText =
            (typeof item?.title === 'string' ? item?.title : '') ||
            (typeof item?.label === 'string' ? item?.label : '')
          const comparedSameText = _.intersection(
            formatedUpperText(targetText),
            formatedUpperText(searchText)
          )
          const targetStore = {
            ...item,
            fuzzySort: _.isEmpty(searchText) ? index : comparedSameText.length
          }
          return _.concat(
            result,
            _.isEmpty(searchText) || comparedSameText.length > 0 ? [targetStore] : []
          )
        },
        []
      ),
      ['fuzzySort']
    )
  )

  const formatedSearchReg = _.map(searchText, (text) =>
    ['.', '+', '?', '*', '(', ')', '[', ']', '\\'].includes(text) ? `\\${text}` : text
  )
    .join('')
    .toUpperCase()

  return _.map(
    _.sortBy(
      _.reduce(
        initFuzzySearch,
        (result, item, index) => {
          const targetText =
            (typeof item?.title === 'string' ? item?.title : '') ||
            (typeof item?.label === 'string' ? item?.label : '')
          const matchedText = targetText.toUpperCase().match(formatedSearchReg)
          const targetStore = {
            ...item,
            rank: _.isEmpty(searchText) ? index : matchedText?.index
          }
          return _.concat(
            result,
            _.isEmpty(searchText) || !_.isEmpty(matchedText) ? [targetStore] : []
          )
        },
        []
      ),
      'rank'
    ),
    ({ fuzzySort, rank, ...rawItems }) => {
      return rawItems
    }
  )
}

function calculatePlanPrice(planName, isJPPlan, planPrice) {
  const isAnnualPlan = planName.includes('YEAR')

  const perMonthPrice = isAnnualPlan
    ? Math.floor((isJPPlan ? Math.floor(planPrice / 0.9) : planPrice) / 12)
    : planPrice ?? 0

  const discountPrice = isJPPlan ? Math.round(perMonthPrice * 0.9) : perMonthPrice

  return { perMonthPrice, discountPrice }
}

export function getSpecificPlan(plans, planTier, isJPPlan) {
  const nextPlan = Object.assign(
    {},
    Object.values(plans).find((plan) => plan.tier === planTier)
  )
  const { perMonthPrice, discountPrice } = calculatePlanPrice(
    nextPlan.planName,
    isJPPlan,
    nextPlan.planPrice
  )

  nextPlan.title = nextPlan.tier.replace(/\+.*$/, '')
  nextPlan.perMonthPrice = perMonthPrice
  nextPlan.discountPrice = discountPrice
  nextPlan.period = formatPlanPeriod(nextPlan.planName, nextPlan.planPrice === 0)
  nextPlan.isJPPlan = isJPPlan

  return nextPlan
}

export function getSubscriptablePlan(
  planGroup,
  subscriptedTier,
  subscriptedTierPlanPrice,
  isTrial,
  paymentType
) {
  /*
    * @param {Array} planGroup
    * @param {TIERS} subscriptedTier
    * @param {number} subscriptedTierPlanPrice
    * @param {boolean} isTrial
    * @param {SubscriptionPaymentType} paymentType

    * 試用期內，可訂閱所有方案
    * 過試用期後，僅可訂閱比目前訂閱方案還要高價的方案
    * 若商店的訂閱付款方式為「合併手續費（WITHFEE）」則不提供供應商從 Admin 更換訂閱方案
  */

  return paymentType === SubscriptionPaymentType.WITHFEE
    ? []
    : isTrial
    ? planGroup
    : planGroup.filter(({ planPrice, tier, ...rest }) => {
        if (planPrice === subscriptedTierPlanPrice && tier !== subscriptedTier) {
          return { planPrice, tier, ...rest }
        }
        return planPrice > subscriptedTierPlanPrice
      })
}

export function formatPlanPeriod(planName, isFreePlan) {
  const yearPeriod = planName.match(/YEAR.+|YEAR/g)

  return yearPeriod || isFreePlan
    ? SUBSCRIPTION_PLAN_PERIOD.YEAR
    : planName === TIERS.NONE
    ? SUBSCRIPTION_PLAN_PERIOD.NONE
    : SUBSCRIPTION_PLAN_PERIOD.PERIOD
}

export function formatAscendingPlanOrder(plans, filteredTiers = [], isJPPlan = false) {
  if (_.isEmpty(plans)) {
    return 'plans argument is required.'
  }

  if (filteredTiers.find((tier) => !Object.values(TIERS).includes(tier))) {
    return 'filtered tiers types is TIERS.'
  }

  const groupByPlanName =
    _.groupBy(
      Object.values(plans),
      (plan) => plan.planName.match(/^[^-]+-([^-]+|$)/)?.[1] ?? plan.planName
    ) ?? {}
  const orderData = []

  // 加上 perMonthPrice, discountPrice, period 欄位
  for (const group in groupByPlanName) {
    groupByPlanName[group]
      .sort((a, b) => a.planPrice - b.planPrice)
      .forEach((plan) => {
        const { planName, planPrice } = plan
        const { perMonthPrice, discountPrice } = calculatePlanPrice(planName, isJPPlan, planPrice)

        plan.perMonthPrice = perMonthPrice
        plan.discountPrice = discountPrice
        plan.period = formatPlanPeriod(planName, planPrice === 0)
        plan.isJPPlan = isJPPlan

        orderData.push({ name: group, perMonthPrice })
      })
  }

  // 依照各方案的「月繳」金額低 -> 高排
  // 當方案費用相同時，維持元素在元陣列中的相對位置排列
  const planOrder = [
    ...new Set(
      orderData
        .sort((a, b) => a.perMonthPrice - b.perMonthPrice)
        .map((sortedPlan) => sortedPlan.name)
    )
  ]

  return Object.entries(groupByPlanName)
    .sort(([group1], [group2]) => planOrder.indexOf(group1) - planOrder.indexOf(group2))
    .map(([key, value]) => ({ group: key, items: value }))
    .filter(({ group }) => ![...filteredTiers].includes(group))
}

export const formatSourceFrom = (sourceFrom = '') => {
  const isBelongsToChannelType = Object.values(ChannelType).includes(sourceFrom?.toUpperCase())
  const sourceUsage = isBelongsToChannelType
    ? i18n.t(`COMMON.SOURCE_${sourceFrom?.toUpperCase()}`)
    : sourceFrom
  return sourceUsage
}

export const parserUuid = (value = '') => {
  const regexUuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/g
  return value.match(regexUuid)
}

export const checkPasswordPattern = (password) => {
  const passwordRegex = /^(?=.*\d)(?=.*[A-Za-z]).{8,}$/ // 密碼至少 8 碼，須同時包含英文數字
  return passwordRegex.test(password)
}

export const formatSourceWording = (order) => {
  if (order?.platform === 'CUSTOM' && order?.customSourceLabel) {
    return order.customSourceLabel
  }

  // 不同頁面 order 的資料不一致
  return formatSourceFrom(order?.affiliate || order?.platform || order?.saleFrom || '-')
}

// freshdesk 有設定的語系
export const formatFreshdeskLocale = (lan) => {
  let locale = 'en'
  switch (lan) {
    case 'zh-TW':
      locale = 'zh-TW'
      break
    case 'zh-CN':
      locale = 'zh-CN'
      break
    case 'ko-KR':
      locale = 'ko'
      break
    case 'ja-JP':
      locale = 'ja-JP'
      break
    case 'en-US':
    default:
      locale = 'en'
  }
  return locale
}

export const formatRedeemResult = (redeemData, redeemFailUuids) => {
  const redeemSuccessVoucherUuids = []
  const redeemFailVoucherUuids = []
  redeemData?.forEach(function (uuid) {
    if (redeemFailUuids.includes(uuid)) {
      redeemFailVoucherUuids.push(uuid)
    } else {
      redeemSuccessVoucherUuids.push(uuid)
    }
  })
  return { redeemSuccessVoucherUuids, redeemFailVoucherUuids }
}

export const formatVoucherTitle = (order, voucher) => {
  const core = _.get(Core, 'instance')

  return voucher.ticketUuid
    ? voucher.title
    : voucher.pricePolicyType === PricingPolicyType.ByItem
    ? _.find(order, ({ itemUuid }) => itemUuid === voucher.itemUuid)?.label
    : core?.getOptionContent('identity', voucher.itemUuid, { title: '' })?.title
}

// 之後 format 希望改成 .tsx, 再補齊對應的 types
const kb = 1024
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const dm = decimals < 0 ? 0 : decimals

  const exponent = Math.floor(Math.log(bytes) / Math.log(kb))

  return parseFloat((bytes / Math.pow(kb, exponent)).toFixed(dm)) + ' ' + sizes[exponent]
}

export const toBytes = (size, type) => {
  const exponent = sizes.indexOf(type.toUpperCase())

  if (sizes.includes(type.toUpperCase())) {
    return size * Math.pow(kb, exponent)
  }

  return 'invalid type: type must be GB/KB/MB etc.'
}

export const payStatusMapping = {
  0: PayStatus.Unpaid,
  1: PayStatus.PaidPartial,
  2: PayStatus.PaidAll,
  3: PayStatus.RefundPartial,
  4: PayStatus.RefundAll,
  5: PayStatus.Refund,
  6: PayStatus.NoPaymentNeeded,
  7: PayStatus.ChannelCheckout,
  8: PayStatus.DistributorCheckout,
  9: PayStatus.PayOnSite
}

export function formatPayStatus(status) {
  if (typeof status === 'string') {
    return [+getKeyByValue(payStatusMapping, status), status]
  }

  return [status, payStatusMapping[status]]
}

export function formatStatus(orderStatus) {
  switch (orderStatus) {
    case -2:
    case OrderStatus.Invalid:
      return [-2, OrderStatus.Invalid]
    case 0:
    case OrderStatus.Canceled:
      return [0, OrderStatus.Canceled]
    case 1:
    case OrderStatus.OnHold:
      return [1, OrderStatus.OnHold]
    case 2:
    case OrderStatus.New:
      return [2, OrderStatus.New]
    case 3:
    case OrderStatus.Pending:
      return [3, OrderStatus.Pending]
    case 4:
    case OrderStatus.Confirmed:
      return [4, OrderStatus.Confirmed]
    case 5:
    case OrderStatus.CheckIn:
      return [5, OrderStatus.CheckIn]
    case 6:
    case OrderStatus.NoShow:
      return [6, OrderStatus.NoShow]
    case 7:
    case OrderStatus.CancelProcessing:
      return [7, OrderStatus.CancelProcessing]
    case 8:
    case OrderStatus.ToBeCancel:
      return [8, OrderStatus.ToBeCancel]
    case 9:
    case OrderStatus.Departed:
      return [9, OrderStatus.Departed]
    case 10:
    case OrderStatus.RedeemByCH:
      return [10, OrderStatus.RedeemByCH]
    case 11:
    case OrderStatus.SpecialCancelByCh:
      return [11, OrderStatus.SpecialCancelByCh]
    case 12:
    case OrderStatus.CloseByCh:
      return [12, OrderStatus.CloseByCh]
  }
  return [-1, '']
}

export function formatUtcOffset(diff = 0 || '') {
  if (!diff && diff !== 0) {
    return 'The diff should be a number or string.'
  }

  if (_.isString(diff)) {
    const [timezone, , halfTimezone = 0] = diff.split(/(\.|:)/g)
    const formattedTimezone = halfTimezone ? timezone : +timezone / 100
    const formattedHalfTimezone = +halfTimezone % 30 === 0 ? 0.5 : 0.8
    const utcOffset = +`${
      +timezone === 0
        ? 0
        : +timezone > 12 || +timezone < 0 || +halfTimezone > 0
        ? _.isInteger(formattedTimezone) && +halfTimezone === 0
          ? formattedTimezone
          : Math.floor(formattedTimezone) + formattedHalfTimezone
        : timezone
    }`

    return utcOffset
  }

  if (Math.abs(diff) <= 12) return diff

  if (Math.abs(diff / 60) > 12) {
    return diff / 3600
  }

  if (diff % 60 === 0) {
    return diff / 60
  } else {
    const hour = Math.floor(diff / 60)
    const minute = (diff % 60) % 30 === 0 ? 0.5 : 0.8
    return hour + minute
  }
}

export function formatTimezoneString(timezone = 0 || '') {
  const formattedTimezone = _.isString(timezone) ? formatUtcOffset(timezone) : timezone
  if (!timezone && timezone !== 0) {
    return 'The timezone should be a number or string.'
  }

  const plusOrMinus = timezone >= 0 ? '+' : '-'
  const prefix = timezone < 10 ? '0' : ''
  const nextTimezone = _.isInteger(formattedTimezone)
    ? formattedTimezone
    : Math.floor(formattedTimezone)
  const minute = _.isInteger(formattedTimezone)
    ? '00'
    : _.isInteger(formattedTimezone - 0.5)
    ? '30'
    : '45'
  return `${plusOrMinus}${prefix}${nextTimezone}${minute}`
}

export function formatPricePolicyUnit(pricePolicyItem) {
  const core = _.get(Core, 'instance')
  const {
    content: { isGroup, quantityWeight, identityUuid, unit }
  } = pricePolicyItem
  const isPersonUnit =
    [identityUuids.group_fixed, identityUuids.group_person].includes(identityUuid) ||
    (!isGroup && quantityWeight === 1)

  return isPersonUnit
    ? i18n.t('ORDER.TAB_DETAIL_LABEL_UNIT_PERSON')
    : _.mapValues(_.keyBy(core.options?.pricePolicyUnit, 'uuid'), 'label')[unit] ??
        i18n.t('ORDER.TAB_DETAIL_LABEL_UNIT_GROUP')
}
