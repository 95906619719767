import AsyncStorage from '@react-native-async-storage/async-storage'
import { getConfig } from '@rezio/core/config'
import { Canceler } from 'axios'
import DataLoader from 'dataloader'
import { i18n, TFunction } from 'i18next'
import _ from 'lodash'
import {
  action,
  observable,
  computed,
  autorun,
  toJS,
  isObservableProp,
  keys,
  decorate,
  when,
  IReactionDisposer
} from 'mobx'
import moment from 'moment'
import { Platform, Keyboard } from 'react-native'

import API, { APIError, APIErrorCode, RuntimeError } from './api'
import auth from './auth'
import { getDeviceLang, instance } from './i18n'

import 'moment/locale/zh-tw'
import 'moment/locale/zh-cn'
import 'moment/locale/vi'
import 'moment/locale/ja'
import 'moment/locale/ko'
import 'moment/locale/th'

const momentLocaleMapping = {
  'en-us': 'en',
  'ja-jp': 'ja',
  'ko-kr': 'ko',
  'th-TH': 'th',
  'vi-vn': 'vi'
}

declare global {
  interface Window {
    coreStore: Core
  }
}

interface RuntimeErrorConfig {
  title?: string
  iconName?: string
  iconColor?: string
  callback?: () => void
}

type IdentityOption = {
  uuid: string
  title: string
  createdAt: string
  isGroup: boolean
  updatedAt: string
  sort: number
  isConstraint: boolean
  quantityWeight: number
  ageSort: number
  allowSpec: boolean
}
type CountryOption = {
  uuid: string
  title: string
  code: number
  createdAt: string
  regionUuid: string
  countrycode: string
  isActive: boolean
  updatedAt: string
  defaultTimezoneUuid: string
  defaultCurrencyUuid: string
  defaultLanguageUuid: string
  meta: string
}
type LanguageOption = {
  title: string
  uuid: string
  code: string
  createdAt: string
  isActive: boolean
  updatedAt: string
  pricePattern: string // "{{symbol}} {{price}}"
  thousand: string
  decimal: string
  datePattern: string
}
type PricePolicyUnitOption = {
  uuid: string
  label: string
  createdAt: string
  updatedAt: string
  sort: number
  isActive: boolean
}
type TimezoneOption = {
  uuid: string
  title: string
  timeDiff: string
  createdAt: string
  updatedAt: string
  meta: any
  phpTimezone: string
}

type Options = {
  identity: IdentityOption[]
  country: CountryOption[]
  language: LanguageOption[]
  languageAll: LanguageOption[]
  pricyPolicyUnit: PricePolicyUnitOption[]
  timezone: TimezoneOption[]
  [key: string]: Option[]
}

export interface Option {
  uuid: string
  [props: string]: any
}

class Core {
  api: API
  i18n: i18n
  disposes: IReactionDisposer[] = []
  optionLoader: DataLoader<string, any>
  constructor() {
    window.coreStore = this

    this.api = new API(this)

    this.i18n = instance
    this.t = this.i18n.t.bind(this.i18n)

    this.optionLoader = new DataLoader(
      async (keys: string[]) => {
        const results = await Promise.all(
          keys.map(async (option) => {
            const result = await this.api.get('option', {
              params: { keys: option, lang: this.lang }
            })[0]
            return _.get(result, `data.${option}.list`, null)
          })
        )
        return results
      },
      {
        cacheKeyFn: (key) => `${key}-${this.lang}`
      }
    )
  }

  static get instance() {
    return core
  }

  static getInstance = () => {
    return core
  }

  initialize = (partialData: Partial<Core>) => {
    this.dispose()

    this.disposes.push(
      autorun(() => {
        this.api.setAccessToken(this.token)

        if (this.error?.session && this.error.session !== this.token) {
          this.setError(undefined)
        }

        this.changeStoreListeners.forEach((func) => func(this.token))

        if (!this.token) {
          auth.logout()
        }
      })
    )

    this.disposes.push(
      autorun(() => {
        if (Platform.OS === 'web') {
          const cookie = require('js-cookie')
          cookie.set('next_i18next', this.lang, { expires: 365 })
        }

        const targetLang = this.lang.toLowerCase()

        moment.locale(_.get(momentLocaleMapping, targetLang, targetLang))
        this.i18n
          .changeLanguage(this.lang)
          .then(() => {
            this.api.setLang(this.i18n.t('LANGUAGE_UUID'))
            this.setPattern()
          })
          .catch(console.error)
      })
    )

    this.disposes.push(
      autorun(() => {
        if (this.error instanceof APIError && this.error.code === APIErrorCode.Deny) {
          if (
            _.some(this.error.data, (errData) => {
              const needLogOutErrors = ['accountIsNotBelongToStore', 'notLogin']
              return (
                needLogOutErrors.includes(errData.type) || needLogOutErrors.includes(errData.error)
              )
            })
          ) {
            this.logout().catch(console.error)
          }
        }
      })
    )

    if (partialData.token && auth.validate(partialData.token).isLogin) {
      this.token = partialData.token
      this.account = auth.getAccount(this.token)
    }

    if (!_.isEmpty(partialData)) {
      const pickingKeys = _(keys(this))
        .map((key) => {
          return isObservableProp(this, key as string) ? key : null
        })
        .compact()
        .without(...['token', 'account', 'error', 'updateChecked'])
        .value()
      Object.assign(this, _.pick(partialData, pickingKeys))
    }

    this.disposes.push(
      autorun(() => {
        AsyncStorage.setItem('core', JSON.stringify(this.getSnapshot())).catch(console.error)
      })
    )
  }

  static async getInstanceWithoutCreate(): Promise<Core> {
    return Core.instance
  }

  token: string = null

  hasStore = false

  lang = getDeviceLang()

  datePattern = ''

  timePattern = ''

  pricePattern = ''

  options:
    | {
        [key in keyof Options]: Options[key]
      }
    | Record<string, never> = {}

  userAgent = ''

  roles = []

  account: Partial<ReturnType<typeof auth.getAccount>> = {}

  error = undefined

  updateChecked = false

  loginSession: Canceler

  setError = (error) => {
    if (Platform.OS !== 'web') {
      Keyboard.dismiss()
    }
    this.error = error
  }

  setRuntimeError = async (message, type?, data?, config: RuntimeErrorConfig = {}) => {
    const { title, callback, ...iconConfig } = config
    this.setError(new RuntimeError(message, type, data, title, iconConfig, callback))
    await when(() => !this.error)
  }

  getOptionContent = (type, uuid, defaultValue) => {
    if (_.isEmpty(this.options[type])) {
      this.fetchOptions([type]).catch(console.error)
      return defaultValue
    }
    return this.options[type].find((option) => option.uuid === uuid) || defaultValue
  }

  fetchOptions = async (options) => {
    const relatedCountryArr = ['countryLandmark', 'country', 'countryAll']
    const relatedCountryOptions = _.filter(options, (each) => relatedCountryArr.includes(each))
    if (options.includes('currency') && !options.includes('currencyCharge')) {
      options.push('currencyCharge')
    }
    if (relatedCountryOptions.length > 0 && relatedCountryOptions.length < 3) {
      _.forEach(relatedCountryArr, (each) => (options.includes(each) ? null : options.push(each)))
    }
    try {
      const results = await this.optionLoader.loadMany(options)
      options.map((key, index) => {
        this.options[key] = _.get(results, index, []).map(({ title, name, ...rest }) =>
          title ? { title, name, ...rest } : { title: name, name, ...rest }
        )
      })
      if (options.includes('language') && this.pricePattern === '') {
        this.setPattern()
      }
    } catch (e) {
      // throw e
    }
  }

  getRoleContent = (uuid, defaultValue) => {
    if (_.isEmpty(this.roles)) {
      return defaultValue
    }
    return this.roles.find((role) => role.uuid === uuid) || defaultValue
  }

  fetchRoles = async () => {
    try {
      const result = await this.api.get('store/permissionGroup')[0]
      this.roles = _.get(result, 'data', [])
    } catch (e) {
      // throw e
    }
  }

  setPattern = () => {
    const languageUuid = this.i18n.t('LANGUAGE_UUID')
    this.datePattern = _.get(_.find(this.options.language, { uuid: languageUuid }), 'datePattern')
    this.timePattern = _.get(_.find(this.options.language, { uuid: languageUuid }), 'timePattern')
    this.pricePattern = _.get(_.find(this.options.language, { uuid: languageUuid }), 'pricePattern')
  }

  setLang = (nextLang) => {
    this.lang = nextLang
  }

  get decodedToken() {
    if (!this.token) return null
    return auth.decodeToken(this.token)
  }

  refreshToken = async () => {
    const result = await this.api.post('account/login', {
      storeUuid: _.get(auth.decodeToken(this.token), 'storeUuid'),
      regenerate: true
    })[0]

    const { token } = result.data

    if (auth.login(token)) {
      this.token = token
      this.account = auth.getAccount(token)
    } else {
      throw new Error('token invalid')
    }
  }

  setStore = async (storeUuid) => {
    try {
      const [request, cancel] = this.api.post('account/login', {
        storeUuid
      })

      this.loginSession = cancel
      const result = await request
      const { token } = result.data

      if (auth.login(token)) {
        this.token = token
        this.account = auth.getAccount(token)
      } else {
        throw new Error('token invalid')
      }
    } catch (e) {
      if (this.api.isCancel(e)) {
        // do nothing
      } else {
        throw e
      }
    } finally {
      delete this.loginSession
    }
  }

  get isLogin() {
    return auth.validate(this.token).isLogin
  }

  get permission() {
    return auth.validate(this.token, this.options?.country)
  }

  login = async ({ account, password, storeUuid = '' }) => {
    if (this.loginSession) {
      return
    }

    try {
      const [request, cancel] = this.api.post(
        'account/login',
        {
          acc: account,
          pwd: password,
          storeUuid
        },
        { skipAuth: true }
      )
      this.loginSession = cancel
      const result = await request
      const { token, hasStore } = result.data
      if (auth.login(token)) {
        this.hasStore = hasStore
        this.token = token
        this.account = auth.getAccount(token)
        if (typeof window !== 'undefined') {
          try {
            await fetch(`${getConfig().SCM_PROXY}/api/rezio/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
              body: JSON.stringify({
                token,
                email: this.account.email,
                password
              })
            })
              .then(async (res) => await res.json())
              .then(async ({ token }) => await AsyncStorage.setItem('scm_token', token))
          } catch (e) {}
        }
      } else {
        throw new Error('token invalid')
      }
    } catch (e) {
      if (this.api.isCancel(e)) {
        // do nothing
      } else {
        throw e
      }
    } finally {
      delete this.loginSession
    }
  }

  loginCancel = (reason) => {
    if (this.loginSession) {
      this.loginSession(reason)
    }
  }

  logout = async () => {
    this.token = null
    await AsyncStorage.removeItem('scm_token')
  }

  forgetEmailSend = async (input) => {
    const [request] = this.api.post('/account/forgetPwd', {
      email: input.email
    })
    const result = await request
    return result
  }

  signUp = async (signUpObj) => {
    const [request] = this.api.post('/account/signUp', signUpObj)
    const result = await request
    return result
  }

  checkResetPasswordToken = async (input) => {
    const { triggerType, token } = input
    const [request] = this.api.get(
      `/account/${triggerType === 'init' ? 'firstLogin' : 'newPwd'}/${token}`
    )
    const result = await request
    return result
  }

  postSetPwd = async (input) => {
    const { triggerType, ...rest } = input
    const [request] = this.api.post(
      `/account/${triggerType === 'init' ? 'initPwd' : 'setPwd'}`,
      rest
    )
    const result = await request
    return result
  }

  setUpdateChecked = (updateChecked) => {
    this.updateChecked = updateChecked
  }

  getSnapshot = () => {
    const pickingKeys = _(this)
      .map((value, key) => {
        return isObservableProp(this, key) ? key : null
      })
      .compact()
      .value()
    return _.pick(toJS(this), pickingKeys)
  }

  t: TFunction

  dispose = () => {
    this.disposes.map((d) => d())
  }

  changeStoreListeners: ((token?: string) => void)[] = []

  /**
   * 新增換店處理
   * @param listener (token?: string) => void 沒有token時代表登出
   * @returns dispose function
   * @example
   * ```ts
   *   const [currentToken, setCurrentToken] = useState(core.token)
   *   useEffect(() => {
   *     const dispose = core.addChangeStoreListeners((token) => {
   *       if (token) {
   *         console.log('change to new Store')
   *       } else {
   *         console.log('logout!')
   *       }
   *       setCurrentToken(token)
   *     })
   *     return () => {
   *       dispose() // 元件unmount時取消監聽，避免觸發出問題
   *     }
   *   }, [])
   * ```
   */
  addChangeStoreListeners = (listener: (token?: string) => void): (() => void) => {
    this.changeStoreListeners.push(listener)
    return () => {
      this.changeStoreListeners = this.changeStoreListeners.filter((i) => i !== listener)
    }
  }
}

decorate(Core, {
  token: observable,
  hasStore: observable,
  lang: observable,
  datePattern: observable,
  timePattern: observable,
  pricePattern: observable,
  options: observable,
  userAgent: observable,
  error: observable,
  roles: observable,
  account: observable,
  updateChecked: observable,
  setUpdateChecked: action,
  setError: action,
  fetchOptions: action,
  fetchRoles: action,
  setLang: action,
  login: action,
  logout: action,
  refreshToken: action,
  initialize: action,
  forgetEmailSend: action,
  checkResetPasswordToken: action,
  postSetPwd: action,
  isLogin: computed,
  permission: computed
})

const core = new Core()

export default Core

export function useCore(): Core {
  return core
}
