import {
  ThemedButton,
  ThemedDropdown,
  ThemedPicker,
  HoverableOpacity,
  LockedWrapper
} from '@rezio/components'
import { useFlags } from '@rezio/core/flags'
import { useStores, useAnimation, useRouter, useLayout, useRezioCheats } from '@rezio/core/hooks'
import {
  useSitemap,
  type SiteMap,
  type SiteMapCategory,
  type SiteMapPage
} from '@rezio/core/routing/sitemap'
import { useConversation } from '@rezio/core/stores/conversation'
import { palette, template, manipulator } from '@rezio/res/theme'
import { NavigatorStatus } from '@rezio/utils/types'
import _ from 'lodash'
import { autorun } from 'mobx'
import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
  createContext,
  useMemo,
  useContext,
  ReactElement
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Text,
  View,
  StyleSheet,
  Animated,
  ScrollView,
  FlatList,
  Platform,
  ViewStyle
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ToastController, ToastRef } from '@rezio/components/shareComponents/Toast'
import { Icon } from './rezioIcon'

const NavigatorContext = createContext<{
  siteMap?: SiteMap
  counts: Record<string, number>
}>({
  siteMap: null,
  counts: {}
})

const FavoriteIndex = -1

const TabsPickerStyle = {
  backgroundColor: palette.primary,
  color: palette.white,
  borderRadius: 10,
  borderColor: palette.primary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0
}

export function Navigator({ mode, ...restProps }) {
  const { store } = useStores()
  const siteMap = useSitemap()
  const conversation = useConversation()
  const [counts, setCounts] = useState<Record<string, number>>({})
  const { t } = useTranslation()

  useEffect(() => {
    store.viewStore.setPageHeader(siteMap.currentPage.label && t(siteMap.currentPage.label))
  }, [siteMap.currentPage])

  useEffect(() => {
    const dispatcher = autorun(() => {
      setCounts({
        'NAVIGATOR.PAGE_ABNORMAL_ORDER': store.orderStore.abnormalOrderPage?.totalCount || 0,
        'NAVIGATOR.PAGE_CONVERSATION': conversation?.hasUnread ? 1 : 0
      })
    })
    return dispatcher
  }, [conversation, store])

  const context = useMemo(() => ({ counts, siteMap }), [counts, siteMap])

  return (
    <NavigatorContext.Provider value={context}>
      {mode === 'tabs' ? <TabBar /> : <Sidebar {...restProps} />}
    </NavigatorContext.Provider>
  )
}

function TabBar() {
  const { t } = useTranslation()
  const router = useRouter()
  const insets = useSafeAreaInsets()
  const { siteMap } = useContext(NavigatorContext)
  const currentPath = siteMap.currentPage.path

  if (siteMap.status !== NavigatorStatus.Active) {
    return null
  }

  return (
    <View
      style={[
        {
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'white'
        },
        template.shadow
      ]}
    >
      <View
        style={{
          width: '100%',
          height: 64 + insets.bottom,
          justifyContent: 'space-around',
          flexDirection: 'row',
          paddingBottom: insets.bottom
        }}
      >
        {siteMap.categories
          .filter((category) => category.pages.some((page) => page.mobile === 'tab'))
          .map((category) => {
            const tabPage = category.pages.find((page) => page.mobile === 'tab')
            const isActiveTab =
              _.filter(category.pages, (page) => page.path === currentPath).length > 0
            const disabled = category.status !== NavigatorStatus.Active
            const color = disabled
              ? palette.disableLight
              : isActiveTab
              ? palette.primary
              : palette.icon
            return (
              <View key={category.label} style={{ flex: 1 }}>
                <HoverableOpacity
                  disabled={disabled}
                  onPress={() => router.push(tabPage.path)}
                  style={{
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Icon name={category.icon} size={32} color={color} />
                  <Text style={{ color }}>{t(category.label)}</Text>
                </HoverableOpacity>
              </View>
            )
          })}
      </View>
    </View>
  )
}

function SidebarPageItem(props: {
  page: SiteMapPage
  index: number
  onClickPath
  favorite?: boolean
}) {
  const router = useRouter()
  const { t } = useTranslation()
  const { path, label, status } = props.page
  const { siteMap, counts } = useContext(NavigatorContext)
  const currentPath = siteMap.currentPage.path
  const { isMobile } = useLayout()
  if (status === NavigatorStatus.Hidden) {
    return null
  }

  return status === NavigatorStatus.Locked ? (
    <LockedWrapper wrapperStyle={{ marginTop: 10 }}>{t(label)}</LockedWrapper>
  ) : (
    <HoverableOpacity
      disabled={status === NavigatorStatus.Disabled}
      onPress={() => (props.onClickPath ? props.onClickPath(path) : router.push(path))}
      style={{ marginTop: 10, position: 'relative', justifyContent: 'center' }}
    >
      {!counts[label] && props.favorite ? (
        <Icon
          style={{ position: 'absolute', left: isMobile ? -28 : -36, transform: [{ scaleY: -1 }] }}
          color={status !== NavigatorStatus.Disabled ? palette.icon : palette.disableLight}
          name='subdirectory-arrow-right'
          size={15}
        />
      ) : null}
      <Text
        style={[
          template.text,
          styles.pathText,
          currentPath === path
            ? { color: palette.primary }
            : { color: status !== NavigatorStatus.Disabled ? palette.icon : palette.disableLight }
        ]}
      >
        {t(label)}
      </Text>
      {counts[label] > 0 && (
        <View
          style={{
            position: 'absolute',
            left: -35,
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: palette.warning
          }}
        />
      )}
    </HoverableOpacity>
  )
}

function SidebarCategory(props: {
  category: SiteMapCategory
  index: number
  onClickPath
  sidebarOpened: boolean
  opening: boolean
  openingProgress: Animated.Value
  expanding: boolean
  onToggle
  favorite?: boolean
}) {
  const {
    sidebarOpened,
    category,
    expanding,
    onToggle,
    index,
    favorite,
    opening,
    openingProgress
  } = props
  const { counts } = useContext(NavigatorContext)
  const { isMobile } = useLayout()
  const { icon, label, pages = [], status: categoryStatus } = category
  const { t } = useTranslation()
  const toggle = useCallback(() => {
    onToggle?.(index)
  }, [index, onToggle])
  const color = categoryStatus === NavigatorStatus.Active ? palette.icon : palette.disableLight

  const progress = useAnimation({
    duration: 200,
    toValue: opening && expanding ? 1 : 0,
    useNativeDriver: false
  })

  return (
    <Animated.View
      style={[
        isMobile ? styles.mobileGroup : styles.group,
        { marginLeft: openingProgress.interpolate({ inputRange: [0, 1], outputRange: [5, 0] }) }
      ]}
    >
      <HoverableOpacity
        disabled={categoryStatus === NavigatorStatus.Disabled}
        onPress={toggle}
        style={styles.category}
      >
        <Animated.View
          style={{
            transform: [
              {
                scale: openingProgress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0.9]
                })
              }
            ]
          }}
        >
          <Icon name={icon} size={30} color={color} />
        </Animated.View>
        {sidebarOpened ? (
          <>
            <Text
              style={[
                isMobile ? styles.mobileCategoryText : styles.categoryText,
                { fontWeight: '500', color, flex: 1 }
              ]}
            >
              {t(label)}
            </Text>
            <Animated.View
              style={{
                transform: [
                  {
                    rotate: progress.interpolate({
                      inputRange: [0, 1],
                      outputRange: ['0deg', '90deg']
                    })
                  }
                ]
              }}
            >
              <Icon color={palette.disableLight} name='chevron-right' size={24} />
            </Animated.View>
          </>
        ) : null}
        {!(expanding && sidebarOpened) && _.some(pages, ({ label }) => counts[label] > 0) ? (
          <View
            style={{
              position: 'absolute',
              left: 20,
              top: 0,
              width: 12,
              height: 12,
              borderRadius: 6,
              backgroundColor: palette.warning
            }}
          />
        ) : null}
      </HoverableOpacity>
      <Animated.View
        style={[
          isMobile ? styles.mobilePath : styles.path,
          {
            height: progress.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 30 * pages.length]
            }),
            opacity: progress
          }
        ]}
      >
        {sidebarOpened && expanding && (
          <>
            {pages.map((page, pageIndex) => {
              return (
                <SidebarPageItem
                  page={page}
                  key={pageIndex}
                  index={pageIndex}
                  onClickPath={props.onClickPath}
                  favorite={favorite}
                />
              )
            })}
          </>
        )}
      </Animated.View>
    </Animated.View>
  )
}

function Sidebar(props: { style?: any; onClickPath?: (url: string) => void }) {
  const { t, store } = useStores()
  const { isMobile = false } = useLayout()
  const { style } = props
  const { siteMap } = useContext(NavigatorContext)
  const elementRef = useRef<ScrollView>()
  const [opening, setOpening] = useState(isMobile || store.viewStore.menuOpening)
  const [sidebarOpened, setDisplaySubMenu] = useState(isMobile || opening)
  const router = useRouter()
  const toggle = useCallback(
    function toggle() {
      store.viewStore.toggleMenu()
    },
    [store]
  )

  const favorite: SiteMapCategory = useMemo(() => {
    const favPages = _.compact(
      siteMap.favorites.map((favoriteKey) =>
        siteMap.pages.find((page) => page.label === `NAVIGATOR.PAGE_${favoriteKey}`)
      )
    )
    if (favPages.length === 0) {
      return null
    }
    return {
      icon: 'star',
      label: 'NAVIGATOR.CATEGORY_FAVORITE',
      pages: favPages,
      status: NavigatorStatus.Active
    }
  }, [siteMap])

  useEffect(() => {
    if (!isMobile) {
      const disposer = autorun(() => {
        setOpening(store.viewStore.menuOpening)
      })
      return () => {
        disposer()
      }
    }
  }, [])

  const openingProgress = useAnimation({
    duration: 200,
    toValue: opening ? 1 : 0,
    useNativeDriver: false
  })

  const width = openingProgress.interpolate({
    inputRange: [0, 1],
    outputRange: [110, 260]
  })

  const currentPath = siteMap.currentPage.path

  useEffect(() => {
    if (!isMobile) {
      const timeoutId = setTimeout(
        () => {
          setDisplaySubMenu(opening)

          // TODO: 自動scroll到對應位置，改版後暫不動
          // if (opening) {
          //   const activePageIdx = _.findIndex(siteMap.pages, page => page.path === currentPath) || 0
          //   const activeIconIdx = _.findIndex(siteMap.categories, each => each.icon === _.get(siteMap.categories.filter((block, index) => block.pages.filter(page => page.path === currentPath).length === 1), '0.icon')) || 0
          //   const y = 32 * activeIconIdx + (activeIconIdx > 0 ? 30 : 0) + 37 * activePageIdx
          //   elementRef.current?.scrollTo && elementRef.current.scrollTo({ y })
          // }
        },
        opening ? 100 : 50
      )
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [opening, currentPath])

  const [expanding, setExpanding] = useState<number>(
    siteMap.categories.findIndex((category) =>
      category.pages.some((page) => page.path === currentPath)
    )
  )

  useEffect(() => {
    setExpanding(
      siteMap.categories.findIndex((category) =>
        category.pages.some((page) => page.path === currentPath)
      )
    )
  }, [siteMap.categories.length])

  const toggleSubmenu = useCallback(
    (index) => {
      if (!opening) {
        toggle()
        setExpanding(index)
      } else {
        setExpanding((current) => (index === current ? null : index))
      }
    },
    [toggle, opening]
  )

  const handleClickFavorite = useCallback((path: string) => {
    const targetCategoryIndex = siteMap.categories.findIndex((category) =>
      category.pages.some((page) => page.path === path)
    )
    setExpanding(targetCategoryIndex)
    if (props.onClickPath) {
      props.onClickPath(path)
    } else {
      router.push(path)
    }
  }, [])

  // beta features
  const { validCheatCode, triggerCheats, resetCheats } = useRezioCheats()
  const betaToastRef = useRef<ToastRef>()
  const { setFlag } = useFlags()
  useEffect(() => {
    if (validCheatCode) {
      resetCheats()
      betaToastRef.current.showToast()
      setFlag('beta_flags', true)
      setTimeout(() => router.push('/internal'), 1500)
    }
  }, [validCheatCode])

  return (
    <View
      style={[isMobile ? styles.mobileBase : styles.base, isMobile ? {} : template.shadow, style]}
    >
      <Animated.View style={[{ flex: 1 }, { width: isMobile ? '100%' : width }]}>
        {!isMobile && (
          <View style={styles.header}>
            <HoverableOpacity style={template.rowContainer} onPress={toggle}>
              <Icon color={palette.primary} name='menu' size={44} />
              {sidebarOpened && (
                <Text style={[styles.headerText, template.bold]}>{t('NAVIGATOR.HEADER')}</Text>
              )}
            </HoverableOpacity>
          </View>
        )}
        <ScrollView ref={elementRef} style={isMobile ? {} : styles.main}>
          {favorite && (
            <>
              <SidebarCategory
                expanding={expanding === FavoriteIndex}
                index={FavoriteIndex}
                onToggle={toggleSubmenu}
                category={favorite}
                onClickPath={handleClickFavorite}
                opening={opening}
                openingProgress={openingProgress}
                sidebarOpened={sidebarOpened}
                favorite
              />
              <View
                style={{
                  marginBottom: 16,
                  marginRight: 15,
                  borderColor: palette.border,
                  borderBottomWidth: 1
                }}
              />
            </>
          )}
          {siteMap.categories.map((category, index) => {
            return (
              <SidebarCategory
                key={index}
                expanding={expanding === index}
                index={index}
                onToggle={toggleSubmenu}
                category={category}
                onClickPath={props.onClickPath}
                opening={opening}
                openingProgress={openingProgress}
                sidebarOpened={sidebarOpened}
              />
            )
          })}
          {sidebarOpened && !isMobile && (
            <View style={{ paddingRight: 25, paddingVertical: 15 }}>
              <Text
                style={{
                  color: palette.darkGray,
                  fontSize: 12,
                  textAlign: 'center',
                  marginBottom: 8
                }}
              >
                {t('COMMON.BROWSER_SUGGESTION')}
                {Platform.OS === 'web' &&
                  !!window.__WEB_REVISION_ID__ &&
                  `\n\n(${window.__WEB_REVISION_ID__})`}
              </Text>
              <Text
                onPress={triggerCheats}
                style={{ color: palette.darkGray, fontSize: 12, textAlign: 'center' }}
              >
                {t('COMMON.COPYRIGHT', { year: new Date().getFullYear() })}
              </Text>
              <ToastController text='Enabled Beta Flags!' ref={betaToastRef} />
            </View>
          )}
        </ScrollView>
      </Animated.View>
    </View>
  )
}

const DropDownTabs = (props) => {
  const { t } = useStores()
  const { tabs: propsTabs } = props
  const currentTab = props.currentTab || props.tab || propsTabs[0].key
  const tabs = propsTabs.map((eachTab, key) => {
    return { label: t(`${eachTab.label}`), value: key, key }
  })
  return (
    <ThemedDropdown
      value={currentTab}
      arrowColor={palette.white}
      textStyle={{ color: palette.white, fontWeight: 'bold', fontSize: 16 }}
      layoutStyle={manipulator.container('row', 'space-between', 'center')}
      style={{
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: palette.primary,
        paddingVertical: 16,
        paddingHorizontal: 10
      }}
      items={tabs}
    />
  )
}

const Tabs = forwardRef(function Tabs(
  props: {
    tabs: { key: string; label: string; required?: boolean }[]
    tab?: string
    currentTab?: string
    status?: object
    onPressTab?: (key: string) => void
    menuStyle?: ViewStyle
    restSteps: string[]
    tabStyle?: ViewStyle
    isPickerTabs?: boolean
    renderFixedHeader?: () => ReactElement
  },
  ref
) {
  const { t } = useStores()
  const router = useRouter()
  const {
    tabs,
    status = {},
    onPressTab,
    menuStyle = {},
    tabStyle = {},
    isPickerTabs = false,
    tab = 'detail',
    renderFixedHeader
  } = props
  const [tabWidth] = useState(220)
  const [filterType, setFilterType] = useState(tab)
  const prevTabRef = useRef<string>(tab)
  const { isMobile } = useLayout()
  const flatlistRef = useRef<FlatList>()
  const currentTab = props.currentTab || props.tab || tabs[0].key
  const currentIndex = tabs.findIndex((item) => currentTab === item.key)
  const back = useCallback(() => {
    const nextIndex = Math.max(currentIndex - 1, 0)
    onPressTab(tabs[nextIndex].key)
  }, [onPressTab, tabs, currentIndex])

  const next = useCallback(() => {
    const nextIndex = Math.min(currentIndex + 1, tabs.length - 1)
    onPressTab(tabs[nextIndex].key)
  }, [onPressTab, tabs, currentIndex])

  useEffect(() => {
    const nextIndex = Math.max(currentIndex, 0)
    flatlistRef.current?.scrollToIndex?.({
      index: nextIndex,
      viewOffset: -10 * (nextIndex - 1)
    })
  }, [currentIndex])

  useEffect(() => {
    if (isMobile && isPickerTabs && Platform.OS !== 'ios' && prevTabRef.current !== filterType) {
      onPressTab?.(filterType)
      prevTabRef.current = filterType
    }
  }, [filterType, isPickerTabs, isMobile])

  useEffect(() => {
    setFilterType(tab)
  }, [tab])

  useImperativeHandle(ref, () => ({
    scrollToTab(key) {
      const index = tabs.findIndex((item) => key === item.key)
      flatlistRef.current?.scrollToIndex?.({ index, viewOffset: -10 * (index - 1) })
    },
    next,
    back
  }))

  const renderItem = useCallback(
    ({ item }) => {
      const { key, label, required } = item
      const isActive = currentTab === key
      return isMobile ? (
        <View
          style={[
            template.rowContainer,
            {
              transform: [{ translateY: 2 }],
              paddingHorizontal: 20,
              paddingVertical: 10,
              width: '100%',
              borderRadius: 10,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: isActive ? palette.primary : palette.disable,
              justifyContent: 'flex-start'
            }
          ]}
        >
          <Text
            style={[
              template.text,
              { fontSize: 18, marginRight: 6 },
              isActive && { color: palette.white }
            ]}
          >
            {t(label)}
          </Text>
          <Icon name='arrow-drop-down' size={20} color={palette.white} />
        </View>
      ) : status[key] === 'locked' ? (
        <LockedWrapper
          wrapperStyle={[
            template.defaultPanel,
            {
              borderRadius: 10,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              marginRight: 10,
              backgroundColor: isActive ? palette.primary : palette.disable,
              justifyContent: 'center',
              width: tabWidth,
              height: 60,
              paddingBottom: 0
            }
          ]}
          labelStyle={[template.text, { fontSize: 18 }]}
          color={palette.icon}
        >
          {t(label)}
        </LockedWrapper>
      ) : status[key] === 'hidden' ? null : (
        <ThemedButton
          style={[
            template.defaultPanel,
            {
              borderRadius: 10,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              marginRight: 10,
              backgroundColor: isActive ? palette.primary : palette.disable,
              justifyContent: 'space-around',
              width: tabWidth,
              height: 60,
              paddingBottom: 0
            },
            tabStyle
          ]}
          onPress={() => {
            onPressTab?.(key)
          }}
          containerStyle={{ width: '100%' }}
        >
          <View style={{ marginLeft: 10, marginRight: 10 }}>
            {required && (
              <Icon size={12} name='star' color={isActive ? palette.white : palette.black} />
            )}
          </View>
          <Text style={[template.text, { fontSize: 18 }, isActive && { color: palette.white }]}>
            {t(label)}
          </Text>
          <View style={{ marginLeft: 10, marginRight: 10 }}>
            {status[key] && (
              <Icon
                size={24}
                name='check-circle'
                color={isActive ? palette.white : palette.positive}
              />
            )}
          </View>
        </ThemedButton>
      )
    },
    [onPressTab, currentTab, status, isMobile]
  )

  const keyExtractor = useCallback((item) => {
    return item.key
  }, [])

  const getItemLayout = useCallback(
    (data, index) => {
      return {
        length: tabWidth,
        offset: tabWidth * index + 10,
        index
      }
    },
    [tabWidth]
  )

  const restRequiredStep = _.size(props.restSteps)

  return isMobile ? (
    isPickerTabs ? (
      <ThemedPicker
        value={filterType}
        styleIOS={TabsPickerStyle}
        styleAndroid={{ ...TabsPickerStyle, paddingLeft: 10 }}
        iconStyle={{ right: 5 }}
        isWhiteIcon
        allowDonePress
        items={tabs.map((tab) => ({ label: t(tab.label), value: tab.key }))}
        onValueChange={setFilterType}
        onChange={() => {
          onPressTab(filterType)
        }}
      />
    ) : (
      <ThemedDropdown
        layoutStyle={{ width: '100%' }}
        menuStyle={menuStyle}
        onChange={onPressTab}
        label={renderItem({ item: tabs[currentIndex] })}
        items={tabs.map((tab) => ({
          label:
            status[tab.key] === 'locked'
              ? () => <LockedWrapper>{t(tab.label)}</LockedWrapper>
              : t(tab.label),
          value: tab.key
        }))}
      />
    )
  ) : (
    <>
      {_.get(router, 'route.pathname', '').includes('product') && (
        <View style={[{ marginBottom: 20 }, manipulator.container('row', 'flex-end', 'center')]}>
          <Text style={{ color: restRequiredStep === 0 ? palette.positive : palette.warning }}>
            <Icon
              style={{ marginHorizontal: 5 }}
              size={12}
              name='star'
              color={restRequiredStep === 0 ? palette.positive : palette.warning}
            />
            {t('PRODUCT.REST_REQUIRED_HINT')}{' '}
          </Text>
          <Text
            style={{
              fontWeight: '700',
              color: restRequiredStep === 0 ? palette.positive : palette.warning
            }}
          >
            {+restRequiredStep === 0
              ? t('PRODUCT.FINISH_ALL_STEPS')
              : t('PRODUCT.REST_REQUIRED_STEP', { restRequiredStep })}
          </Text>
        </View>
      )}
      <View
        style={[
          template.rowContainer,
          { marginBottom: 30, borderBottomWidth: 8, borderBottomColor: palette.primary }
        ]}
      >
        {renderFixedHeader?.()}
        <HoverableOpacity onPress={back} style={{ width: 40, alignItems: 'center' }}>
          <Icon size={24} name='chevron-left' color={palette.border} />
        </HoverableOpacity>
        <FlatList
          ref={flatlistRef}
          initialScrollIndex={currentIndex}
          getItemLayout={getItemLayout}
          horizontal
          keyExtractor={keyExtractor}
          data={tabs}
          extraData={status}
          renderItem={renderItem}
        />
        <HoverableOpacity onPress={next} style={{ width: 40, alignItems: 'center' }}>
          <Icon size={24} name='chevron-right' color={palette.border} />
        </HoverableOpacity>
      </View>
    </>
  )
})

const styles = StyleSheet.create({
  base: {
    marginLeft: 15,
    marginRight: 15,
    backgroundColor: palette.menuBackground
  },
  mobileBase: {
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: palette.white
  },
  header: {
    height: 100,
    justifyContent: 'center',
    paddingLeft: 33
  },
  headerText: {
    paddingLeft: 15,
    fontSize: 22,
    color: palette.primary
  },
  main: { paddingLeft: 25 },
  group: { paddingLeft: 10, paddingBottom: 20 },
  mobileGroup: { paddingLeft: 10, paddingBottom: 16 },
  category: {
    flexDirection: 'row',
    height: 32,
    paddingRight: 10,
    alignItems: 'center',
    position: 'relative'
  },
  pathText: {
    fontSize: 14,
    color: palette.icon
  },
  path: { paddingLeft: 45 },
  mobilePath: { paddingLeft: 38 },
  categoryText: {
    fontSize: 16,
    paddingLeft: 15,
    color: palette.icon
  },
  mobileCategoryText: {
    fontSize: 16,
    paddingLeft: 8,
    color: palette.icon
  }
})

export { Tabs, DropDownTabs }
