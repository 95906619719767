// eslint-disable-next-line @typescript-eslint/no-unused-vars

import Core from '@rezio/core/core'
import { DeviceType } from '@rezio/utils/types'
import { model, Model, modelAction, modelFlow, prop, _async, _await } from 'mobx-keystone'

interface IAccount {
  uuid: string
  firstName: string
  lastName: string
  email: string
  permissionGroupUuid: string
}

interface IDevice {
  uuid: string
  deviceName: string
  deviceId: string
  deviceType: DeviceType
  model: string
  accountName: string
}

interface RedeemPermissionItem {
  uuid: string
  name: string
  account?: IAccount[]
  device?: IDevice[]
  enableDelete?: boolean
}

interface CreateRedeemPermissionPayload {
  name: string
  account: string[]
  device: string[]
}

@model('admin/RedeemPermission')
class RedeemPermission extends Model({
  loading: prop<boolean>(false),
  list: prop<RedeemPermissionItem[]>(() => [])
}) {
  core = Core.getInstance()

  @modelAction
  resetRedeemPermission() {
    this.list = []
  }

  @modelAction
  addPermission(permission) {
    if (this.list.find((each) => each.uuid === permission.uuid)) {
      this.list = [...this.list.filter((each) => each.uuid !== permission.uuid), permission]
    } else {
      this.list.push(permission)
    }
  }

  @modelFlow
  fetchRedeemPermissionList = _async(function* (this: RedeemPermission) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.get('/store/redeemPermission')
      const { data } = yield* _await(request)
      data.map((eachPermission) => this.addPermission(eachPermission))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  getRedeemPermission = _async(function* (this: RedeemPermission, uuid: string) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.get(`/store/redeemPermission/${uuid}`)
      const { data } = yield* _await(request)
      this.addPermission({
        uuid,
        ...data
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  createRedeemPermission = _async(function* (
    this: RedeemPermission,
    payload: CreateRedeemPermissionPayload,
    callback: () => void
  ) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.post('/store/redeemPermission', payload)
      const { message } = yield* _await(request)
      message === 'success' && callback()
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  updateRedeemPermission = _async(function* (
    this: RedeemPermission,
    uuid: string,
    payload: CreateRedeemPermissionPayload
  ) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.put(`/store/redeemPermission/${uuid}`, payload)
      yield* _await(request)
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  deleteRedeemPermission = _async(function* (this: RedeemPermission, uuid: string) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.delete(`/store/redeemPermission/${uuid}`)
      yield* _await(request)
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  getRedeemPermissionByCondition = _async(function* (
    this: RedeemPermission,
    uuid: string,
    conditionType: 'account' | 'device'
  ) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.get(
        `store/redeemPermission/${conditionType}/${uuid}`
      )
      const { data } = yield* _await(request)
      data.map((eachPermission: RedeemPermissionItem) => this.addPermission(eachPermission))
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  })
}

export default RedeemPermission
