import { useStores } from '@rezio/core/hooks'
import DiscountReport from '@rezio/core/stores/discountReport'
import RedeemPermission from '@rezio/core/stores/redeemPermission'
import { useSetAtom } from 'jotai'
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  model,
  Model,
  prop,
  registerRootStore,
  unregisterRootStore,
  detach
} from 'mobx-keystone'
import { observer } from 'mobx-react'
import React, { createContext, useState, useEffect, useContext, PropsWithChildren } from 'react'

import { storeUuidAtom } from '../storeUuidAtom'
import CouponCode from './couponCode'
import Dashboard from './dashboard'
import OrderSource from './orderSource'
import StockCalendar from './stockCalendar'

declare global {
  interface Window {
    keystone: Root
  }
}

@model('admin/Root')
class Root extends Model({
  OrderSource: prop<OrderSource>(() => new OrderSource({})),
  StockCalendar: prop<StockCalendar>(() => new StockCalendar({})),
  Dashboard: prop<Dashboard>(() => new Dashboard({})),
  CouponCode: prop<CouponCode>(() => new CouponCode({})),
  DiscountReport: prop<DiscountReport>(() => new DiscountReport({})),
  RedeemPermission: prop<RedeemPermission>(() => new RedeemPermission({}))
}) {}

export const KeystoneContext = createContext<Root>(null)

export function useKeystoneStores() {
  return useContext(KeystoneContext)
}

export const KeystoneProvider = observer((props: PropsWithChildren) => {
  const { store } = useStores()
  const storeUuid = store?.core?.session?.profile?.storeUuid

  const [keystoneStore, setKeystoneStore] = useState<Root>(null)
  const setStoreUuid = useSetAtom(storeUuidAtom)

  useEffect(() => {
    if (keystoneStore) {
      unregisterRootStore(keystoneStore)
      detach(keystoneStore)
    }

    const newStore = new Root({})
    window.keystone = newStore
    registerRootStore(newStore)
    setKeystoneStore(newStore)

    setStoreUuid(storeUuid)
  }, [storeUuid])

  return <KeystoneContext.Provider value={keystoneStore}>{props.children}</KeystoneContext.Provider>
})
