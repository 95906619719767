import qs from 'query-string'
import { pages } from './pages'
import type { Destination } from './types'

export function getPageNameFromPath(path: string) {
  return pages.find((page) => page.path === path)?.name
}

export function getPathFromPageName(pageName: string) {
  return pages.find((page) => page.name === pageName)?.path
}

export function parseDestination(desination: Destination, current: string) {
  let pathname
  let query
  if (typeof desination === 'string') {
    pathname = desination.split('?')[0]
    query = qs.parse(desination.split('?')[1])
  } else {
    pathname = desination?.pathname || current
    query = desination?.query
  }
  pathname = getPathFromPageName(pathname) || pathname
  pathname = pathname.replace(/^\/@rezioio\/rezio-admin/, '/')
  return {
    pathname,
    search: `?${qs.stringify(query)}`
  }
}

export { useRouter } from '@rezio/core/hooks'
