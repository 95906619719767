import Core from '@rezio/core/core'
import { type Method } from 'axios'
import { tail } from 'lodash'
import { head, prop } from 'ramda'

export const apiInstance = () => Core.getInstance().api
export const apiWrapper = async (promise) =>
  await (promise[0].then(prop('data')) as Promise<unknown>)

export const apiWithExtractor = async <T>(
  url: string,
  method: Method = 'get',
  params?: Record<string, unknown>,
  body?: unknown
) =>
  await ((await apiWrapper(
    apiInstance().request({ url, params, method, data: body })
  )) as Promise<T>)

apiWithExtractor.get = async <T>(...args) =>
  await apiWithExtractor<T>(head(args), 'get', ...tail(args))
apiWithExtractor.post = async <T>(...args) =>
  await apiWithExtractor<T>(head(args), 'post', ...tail(args))
apiWithExtractor.put = async <T>(...args) =>
  await apiWithExtractor<T>(head(args), 'put', ...tail(args))
