import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { tv, type VariantProps } from 'tailwind-variants'
import {
  Pressable,
  View,
  type PressableProps,
  Text,
  type ViewProps,
  GestureResponderEvent
} from 'react-native'
import clsx from 'clsx'
import { MaterialIcons } from '@expo/vector-icons'
import { PageFooter } from '@rezio/components/layout'
import { DSIcon } from './icon'

const buttonVariants = tv({
  slots: {
    base: 'inline-flex select-none flex-row items-center justify-center rounded-1 border-1 transition-all focus-visible:outline-none disabled:pointer-events-none',
    text: 'whitespace-nowrap text-[14px] font-medium leading-[20px] transition-colors disabled:pointer-events-none'
  },
  variants: {
    intent: {
      primary: {
        base: 'min-w-[110px] border-primary bg-primary',
        text: 'text-white'
      },
      destructive: {
        base: 'min-w-[110px] border-red bg-red',
        text: 'text-white'
      },
      cancel: {
        base: 'min-w-[110px] border-gray-600',
        text: 'text-black'
      },
      normal: {
        base: 'min-w-[110px] border-primary',
        text: 'text-primary'
      },
      ghost: {
        base: 'border-transparent',
        text: 'text-primary'
      },
      link: {
        base: 'border-0',
        text: 'text-primary underline-offset-4'
      }
    },
    state: {
      default: '',
      loading: {
        base: 'pointer-events-none bg-gray-300',
        text: 'text-gray-600'
      },
      disabled: {
        base: 'pointer-events-none bg-gray-300',
        text: 'text-gray-600'
      }
    },
    size: {
      default: 'h-9 px-4 py-2',
      small: 'h-8 px-3',
      icon: 'h-10 w-10'
    }
  },
  compoundVariants: [
    // hovers
    // 如果寫在不限制在default這個state, disabled時也會有hover效果
    {
      intent: 'primary',
      state: 'default',
      className: {
        base: 'hover:bg-primary/90'
      }
    },
    {
      intent: 'destructive',
      state: 'default',
      className: {
        base: 'hover:bg-red/80'
      }
    },
    {
      intent: 'cancel',
      state: 'default',
      className: {
        base: 'hover:opacity-50'
      }
    },
    {
      intent: 'normal',
      state: 'default',
      className: {
        base: 'hover:opacity-50'
      }
    },
    {
      intent: 'ghost',
      state: 'default',
      className: {
        base: 'hover:opacity-50'
      }
    },
    {
      intent: 'link',
      state: 'default',
      className: {
        base: 'hover:bg-black/10',
        text: 'hover:underline'
      }
    },
    // disabled (or loading)
    {
      intent: ['primary', 'destructive', 'cancel', 'normal'],
      state: ['loading', 'disabled'],
      className: {
        base: 'border-gray-300 bg-gray-300 hover:bg-gray-300',
        text: 'text-gray-600 hover:text-gray-600'
      }
    },
    {
      intent: ['ghost', 'link'],
      state: ['loading', 'disabled'],
      className: {
        text: 'text-gray-600'
      }
    },
    {
      intent: 'ghost',
      state: ['loading', 'disabled'],
      className: {
        base: 'hover:bg-transparent'
      }
    }
  ],
  defaultVariants: {
    intent: 'normal',
    state: 'default',
    size: 'default'
  }
})

interface ButtonContentProps {
  label: string
  icon: keyof typeof MaterialIcons.glyphMap
}

type ButtonProps = Omit<PressableProps, 'children' | 'style'> &
  Omit<VariantProps<typeof buttonVariants>, 'state'> &
  (
    | (Pick<ButtonContentProps, 'label'> & Partial<Pick<ButtonContentProps, 'icon'>>)
    | (Pick<ButtonContentProps, 'icon'> & Partial<Pick<ButtonContentProps, 'label'>>)
  ) & {
    style?: ViewProps['style']
    loadingText?: string
  }
interface ButtonIconProps extends ViewProps, VariantProps<typeof iconVariant> {
  icon: keyof typeof MaterialIcons.glyphMap
  textClassName?: string
}

const iconVariant = tv({
  base: '',
  variants: {
    pureIcon: {
      true: 'px-3',
      false: 'px-0'
    }
  },
  defaultVariants: {
    pureIcon: false
  }
})

const DSButtonIcon = ({ icon, className, pureIcon, textClassName }: ButtonIconProps) => {
  return (
    <View className={clsx(iconVariant({ pureIcon }), className)}>
      <DSIcon textClassName={textClassName} name={icon} />
    </View>
  )
}
DSButtonIcon.displayName = 'DSButtonIcon'

const DSButton = forwardRef<View, ButtonProps>(
  ({ className, intent, size, label, icon, onPress, disabled, style, ...props }, ref) => {
    const [buttonState, setButtonState] =
      useState<VariantProps<typeof buttonVariants>['state']>('default')
    const state = disabled ? 'disabled' : buttonState
    const { base, text } = buttonVariants({
      intent,
      size,
      state
    })
    const pressableRef = useRef<View>()
    const textClassName = clsx(text())
    useImperativeHandle(ref, () => pressableRef.current, [])

    const handlePress = useCallback(
      async (e: GestureResponderEvent) => {
        if (buttonState === 'loading') return

        const lastState = buttonState
        pressableRef.current?.blur()
        const pressing = onPress?.(e) as unknown
        if (pressing instanceof Promise) {
          setButtonState('loading')
          await pressing
          setButtonState(lastState)
        }
      },
      [onPress, buttonState]
    )

    return (
      <Pressable
        className={clsx(base(), className)}
        style={[{ gap: 4 }, style]}
        ref={pressableRef}
        onPress={handlePress}
        disabled={state === 'loading' || state === 'disabled'}
        {...props}
      >
        {icon ? <DSButtonIcon pureIcon={!label} textClassName={textClassName} icon={icon} /> : null}
        {label ? <Text className={textClassName}>{label}</Text> : null}
      </Pressable>
    )
  }
)
DSButton.displayName = 'DSButton'

const buttonGroupVariants = tv({
  base: 'flex',
  variants: {
    variant: {
      default: 'flex-col justify-around md:flex-row',
      flow: 'relative flex-col md:flex-1 md:flex-row-reverse',
      dialog: ''
    },
    direction: {
      row: '',
      column: ''
    },
    reversed: {
      true: '',
      false: ''
    }
  },
  compoundVariants: [
    {
      direction: 'row',
      reversed: true,
      className: 'flex-row-reverse md:flex-row-reverse'
    },
    {
      direction: 'row',
      reversed: false,
      className: 'flex-row md:flex-row'
    },
    {
      direction: 'column',
      reversed: true,
      className: 'flex-col-reverse md:flex-col-reverse'
    },
    {
      direction: 'column',
      reversed: false,
      className: 'flex-col md:flex-col'
    }
  ],
  defaultVariants: {
    variant: 'default',
    reversed: false
  }
})

interface ButtonGroupProps extends ViewProps, VariantProps<typeof buttonGroupVariants> {
  buttons: ButtonProps[]
}

const intentSort = {
  primary: 2,
  normal: 1,
  ghost: 1,
  link: 1,
  destructive: -1,
  cancel: -2
} as const

const DSButtonGroup = forwardRef<View, ButtonGroupProps>(
  ({ className, variant, direction, buttons, reversed, style, ...props }, ref) => {
    const renderedButtons = useMemo(() => {
      return (
        <>
          {buttons
            .filter((button) => !!button)
            .sort((a, b) => intentSort[b.intent ?? 'normal'] - intentSort[a.intent ?? 'normal'])
            .reduce<(ButtonProps | 'space')[]>((result, button, index, buttons) => {
              const sorts = [
                intentSort[buttons[index - 1]?.intent ?? 'normal'],
                intentSort[button.intent ?? 'normal']
              ]
              if (sorts.some((sort) => sort > 0) && sorts.some((sort) => sort < 0)) {
                result.push('space')
              }
              result.push(button)
              return result
            }, [])
            .map((button, index) => {
              if (button === 'space') {
                return <View key={index} className='md:flex-1' />
              } else {
                return <DSButton key={index} {...button} />
              }
            })}
        </>
      )
    }, [buttons])

    if (variant === 'flow') {
      return (
        <PageFooter>
          <View
            ref={ref}
            className={clsx(buttonGroupVariants({ variant, direction }), className)}
            style={{ gap: 8 }}
            {...props}
          >
            {renderedButtons}
          </View>
        </PageFooter>
      )
    } else {
      return (
        <View
          ref={ref}
          className={clsx(buttonGroupVariants({ variant, direction }), className)}
          style={{ gap: 8 }}
          {...props}
        >
          {renderedButtons}
        </View>
      )
    }
  }
)
DSButtonGroup.displayName = 'DSButtonGroup'

export { DSButton, DSButtonGroup }
