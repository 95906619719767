import { MaterialIcons } from '@rezio/components'
import { HoverableOpacity } from '@rezio/components/hoverableOpacity'
import { palette } from '@rezio/res/theme'
import React, { useState, useCallback, ReactNode } from 'react'
import { View, Text, TextStyle, StyleProp, StyleSheet } from 'react-native'

type TargetType = 'icon' | 'text'
interface HoverableTooltipProps {
  targetType: TargetType
  iconName?: string
  iconSize?: number
  iconStyle?: StyleProp<TextStyle>
  textNode?: ReactNode
  tipContent: string
  asChildren?: true
  children?: ReactNode
}

const tipContainerStyles = StyleSheet.create({
  baseStyles: {
    position: 'absolute',
    backgroundColor: palette.gray,
    zIndex: 2,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 3,
    borderWidth: 0
  },
  iconTipContainer: {
    width: 255,
    minHeight: 50,
    left: -60,
    top: 30,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 7,
    paddingRight: 3,
    borderRadius: 3
  },
  textTipContainer: {
    left: 0,
    top: 20
  }
})

export const HoverableTooltip = (props: HoverableTooltipProps) => {
  const { targetType, iconName, iconSize, iconStyle, textNode, tipContent, asChildren, children } =
    props
  const isIconTarget = targetType === 'icon'
  const [showTips, setShowTips] = useState(false)
  const handleMouseEnter = useCallback(() => setShowTips(true), [])
  const handleMouseLeave = useCallback(() => setShowTips(false), [])

  return (
    <View>
      <HoverableOpacity
        style={{ cursor: isIconTarget ? 'pointer' : 'default' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isIconTarget ? (
          <MaterialIcons
            name={iconName}
            size={iconSize}
            color={showTips ? palette.lightNotice : palette.notice}
            style={iconStyle}
          />
        ) : asChildren ? (
          children
        ) : (
          textNode
        )}
      </HoverableOpacity>
      {showTips && (
        <View
          style={[
            tipContainerStyles.baseStyles,
            isIconTarget ? tipContainerStyles.iconTipContainer : {}
          ]}
          tw='-top-[30px]'
        >
          <Text style={{ color: palette.white, fontSize: 12 }} numberOfLines={isIconTarget ? 0 : 1}>
            {tipContent}
          </Text>
        </View>
      )}
    </View>
  )
}
