import axios, { AxiosError, AxiosRequestConfig, Canceler } from 'axios'
import _ from 'lodash'
import md5 from 'md5'
import { when } from 'mobx'
import { nanoid } from 'nanoid/non-secure'

import { getConfig, getServiceHost } from './config'
import type Core from './core'

declare module 'axios' {
  interface AxiosRequestConfig {
    raw?: boolean
    skipErrorHandle?: boolean
    skipAuth?: boolean
    key?: string
  }
}

// Nimda Brain API 回應相關
export interface APIResponse<T = any> {
  data?: T
  errorCode: APIErrorCode
  message: string
}

export interface PagedList<T = any> {
  totalCount: number
  itemPerPage: number
  currentPage: number
  list: T[]
}

export enum APIErrorCode {
  Unknown = -1,
  /** 成功 */
  Success = 0,
  /** 失敗 */
  Fail = 1,
  /** 無效 */
  Invalid = 2,
  /** 權限不足 */
  Deny = 3,
  /** 要跳出額外做其他確認 */
  Confirm = 4,
  /** 客製化訊息使用 */
  Frontend = 5,
  NotFound = 404,
  Exception = 999
}

enum APICustomErrorCode {
  RequestUnknown = 'REQUEST_UNKNOWN',
  RequestTimeout = 'REQUEST_TIMEOUT',
  RequestNoResponse = 'REQUEST_NO_RESPONSE',
  RequestWrongStatus = 'REQUEST_WRONG_STATUS'
}

function isAPIResponse(content): content is APIResponse {
  return Object.values(APIErrorCode).includes(content.errorCode) && content.message != null
}

interface ErrorContent {
  type: string
  desc: string
  error?: string
}

function isErrorContent(content): content is ErrorContent {
  return content?.type && content?.desc
}

export class APIError<T = any> extends Error {
  name = 'APIError'
  code: APIErrorCode
  data: ErrorContent[] | any[]
  rawData: T
  payload?: T
  message: string
  description: string
  session?: string

  constructor(message: string, code: number, data: T, session?: string) {
    super(message)
    if (_.isArray(data)) {
      this.data = data
    } else if (_.isString(data) && !data.includes('-')) {
      this.data = [{ type: data, desc: data }]
    } else if (_.isString(data) && data.includes('-')) {
      const [type, detail] = data.split('-')
      this.data = [_.set({ type }, 'detail.title', detail)]
    } else if (!data) {
      this.data = []
    } else {
      this.data = [data]
    }
    this.name = 'APIError'
    this.code = code
    this.rawData = data
    this.payload = code === APIErrorCode.Confirm ? data : undefined
    this.description = isErrorContent(data) ? data.error : ''
    this.session = session
  }
}

export const isAPIError = (error): error is APIError => error.name === 'APIError'

// WD40API回應/錯誤
export interface WDFCResponse<T> {
  code: WDFCResultCode | string
  codeMessage: string
  data?: T
}

enum WDFCResultCode {
  Success = 'S0000',
  WrongParameter = 'V0001'
}

export class WDFCError extends Error {
  data: WDFCResponse<any>
  config: AxiosRequestConfig
  constructor(data: WDFCResponse<any>, config: AxiosRequestConfig) {
    super(data.codeMessage)
    this.name = 'WDFCError'
    this.config = config
    this.data = data
  }
}

interface IconConfig {
  iconName?: string
  iconColor?: string
}

export class RuntimeError<T = any> extends Error {
  name = 'RuntimeError'
  type?: string
  data?: T
  title?: string
  description?: string
  iconConfig?: IconConfig
  callback?: () => void

  constructor(
    message: string,
    type?: string,
    data?: T,
    title?: string,
    iconConfig?: IconConfig,
    callback?: () => void
  ) {
    super(message)
    this.name = 'RuntimeError'
    this.type = type
    this.data = data
    this.title = title
    this.iconConfig = iconConfig
    this.description = message
    this.callback = callback
  }
}

class API {
  constructor(core: Core) {
    this.core = core
  }

  core: Core

  createInstance = () => {
    const instance = axios.create()

    instance.interceptors.request.use(async (config) => {
      config.baseURL = getConfig().API_BASE_URL
      if (config.skipAuth) {
        delete config.headers.common.Authorization
      }
      return config
    })

    instance.interceptors.response.use(
      (res) => {
        const session = this.instance.defaults.headers.common.Authorization as string
        this.connections.delete(res.config.key)

        if (isAPIResponse(res.data)) {
          if (res.data.errorCode !== APIErrorCode.Success) {
            const { errorCode, message, data } = res.data
            const error = new APIError(message, errorCode, data, session?.split(' ')[1])
            if (
              !res.config.skipErrorHandle &&
              !(error.session && error.session !== this.core.token)
            ) {
              this.handleAPIError(error)
            }
            throw error
          }

          if (res.config.raw) {
            return res
          }
          res.data = {
            ...res.data,
            response: res
          }
        }

        return res
      },
      (error) => {
        if (!error.config?.skipErrorHandle) {
          this.handleError(error)
        }
        throw error
      }
    )
    return instance
  }

  handleAPIError = (error: APIError) => {
    if (error.code === APIErrorCode.Deny) {
      if (!_.isEmpty(this.core.token)) {
        this.core.setError(error)
      }
      return {
        errorCode: error.code,
        message: error.message,
        data: error.rawData
      }
    } else if (![APIErrorCode.Confirm, APIErrorCode.Frontend].includes(error.code)) {
      this.core.setError(error)
    }
  }

  handleError = (error: AxiosError) => {
    if (!this.connections.has(error?.config?.key)) {
      throw error
    }
    this.connections.delete(error.config.key)

    if (this.isCancel(error)) {
      console.log('connection canceled', { ...error, config: error.config })
    } else {
      if (error.code === 'ECONNABORTED') {
        error.code = APICustomErrorCode.RequestTimeout
      } else if (!error.response) {
        error.code = APICustomErrorCode.RequestNoResponse
      } else if (error.response) {
        // server response error ,ex: REQUEST_WRONG_STATUS: 305
        error.code = `${APICustomErrorCode.RequestWrongStatus}: ${error.response.status}`
      } else {
        error.code = APICustomErrorCode.RequestUnknown
      }
    }
    this.core.setError(error)
  }

  createWdfcInstance = () => {
    const instance = axios.create()
    instance.interceptors.request.use(async (req: AxiosRequestConfig) => {
      await when(() => !!this.core.decodedToken?.storeUuid)
      const storeUuid = this.core.decodedToken?.storeUuid
      req.baseURL = `https://${getServiceHost('wdfc')}`
      req.headers['X-Auth-StoreUuid'] = storeUuid
      req.headers['X-Auth-Key'] = md5(`${storeUuid}1a675fd29e20ca88a2884eba4e095cfeface`)
      req.headers['X-Gateway'] = 'face'
      req.headers['X-Lang'] = this.core.i18n.t('LANGUAGE_UUID')
      return req
    })
    instance.interceptors.response.use(async (res) => {
      if (
        res.data.code !== 'S0000' &&
        !res.config.skipErrorHandle &&
        res.data.codeMessage !== 'channelStoreSettingNotExists'
      ) {
        const error = new WDFCError(res.data, res.config)
        this.core.setError(error)
        throw error
      }
      return res
    })
    return instance
  }

  connections = new Map()
  instance = this.createInstance()
  wdfcInstance = this.createWdfcInstance()

  setAccessToken = (token?: string) => {
    if (
      this.instance.defaults.headers.common.Authorization &&
      `bearer ${token}` !== this.instance.defaults.headers.common.Authorization
    ) {
      const canceled = []
      this.connections.forEach((cancel, key) => {
        canceled.push(key)
        cancel('change token')
      })
      this.connections.clear()
      console.log('canceled requests by change token:', canceled)
    }

    if (token) {
      this.instance.defaults.headers.common.Authorization = `bearer ${token}`
    } else {
      delete this.instance.defaults.headers.common.Authorization
    }
  }

  setLang = (lang?: string) => {
    if (lang) {
      this.instance.defaults.headers.common['X-Lang'] = lang
    } else {
      delete this.instance.defaults.headers.common['X-Lang']
    }
  }

  request = <T = any>(config: AxiosRequestConfig): [Promise<T>, Canceler] => {
    const source = axios.CancelToken.source()
    const { cancel, token } = source
    return [
      new Promise(async (resolve, reject) => {
        try {
          const id = nanoid()
          const key = `${id}-${config.url}`
          config.key = key
          this.connections.set(key, cancel)
          const res = await this.instance.request<T>({
            ...config,
            cancelToken: token
          })
          if (res) {
            resolve(res)
          }
        } catch (err) {
          reject(err)
        }
      }).then((res: { data: any }) => res.data),
      cancel
    ]
  }

  get = <T = any>(url: string, config?: AxiosRequestConfig) =>
    this.request<T>(Object.assign({ url, method: 'get' }, config))
  head = <T = any>(url: string, config?: AxiosRequestConfig) =>
    this.request<T>(Object.assign({ url, method: 'head' }, config))
  delete = <T = any>(url: string, config?: AxiosRequestConfig) =>
    this.request<T>(Object.assign({ url, method: 'delete', data: config?.data }, config))
  options = <T = any>(url: string, config?: AxiosRequestConfig) =>
    this.request<T>(Object.assign({ url, method: 'options' }, config))
  post = <T = any, Body = any>(url: string, data?: Body, config?: AxiosRequestConfig) =>
    this.request<T>(Object.assign({ url, method: 'post', data }, config))
  put = <T = any, Body = any>(url: string, data?: Body, config?: AxiosRequestConfig) =>
    this.request<T>(Object.assign({ url, method: 'put', data }, config))
  patch = <T = any, Body = any>(url: string, data?: Body, config?: AxiosRequestConfig) =>
    this.request<T>(Object.assign({ url, method: 'patch', data }, config))

  isCancel = axios.isCancel
}

export default API
