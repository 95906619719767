import { MaterialIcons } from '@rezio/components'
import { useLayout } from '@rezio/core/hooks'
import React, { useContext } from 'react'
import { Platform, Text, View } from 'react-native'
import { useTranslation, Trans } from 'react-i18next'
import { GracePeriodStatus } from '@rezio/core/response.types'
import { Link } from '@rezio/unimodules/router'
import { usePermission } from '@rezio/core/auth'
import { AppConfigContext } from '@rezio/core/config'
import { ROLE_TITLE } from '@rezio/core/permission'

const WarningHeader = () => {
  const { basic } = useContext(AppConfigContext)
  const { t } = useTranslation()
  const { role } = usePermission()
  const { isMobile } = useLayout()

  const PaidFailStatus = [GracePeriodStatus.OnlineFail, GracePeriodStatus.OfflineFail]

  if (PaidFailStatus.includes(basic?.gracePeriodStatus)) {
    const i18nKey =
      basic?.gracePeriodStatus === GracePeriodStatus.OnlineFail
        ? 'SUBSCRIPTION.WARNING_ONLINE_PAID_FAIL'
        : 'SUBSCRIPTION.WARNING_OFFLINE_PAID_FAIL'
    const subscriptionPeriodEndDate = basic?.periodEndDate ?? ''
    const isOwner = role === ROLE_TITLE.STORE_OWNER
    const linkText = isOwner && !isMobile ? t('SUBSCRIPTION.GO_TO_MYPLAN_SUBSCRIPTION') : ''

    return (
      <View className='bg-primary p-3 sm:p-0'>
        <View className='flex flex-row items-center justify-center bg-red px-5 py-3'>
          <MaterialIcons name='warning' className='mr-4 text-white' size={20} />
          <Text className='text-white'>
            <Trans
              i18nKey={i18nKey}
              values={{ endDate: subscriptionPeriodEndDate, linkText }}
              components={{
                CustomLink: (
                  <Link
                    style={{ color: 'white', textDecorationLine: 'underline' }}
                    to='/myplan/subscription'
                    component={Platform.OS === 'web' ? undefined : Text}
                  />
                )
              }}
            />
          </Text>
        </View>
      </View>
    )
  }

  return null
}

export default WarningHeader
