import _ from 'lodash'
import React from 'react'
import { Text, View } from 'react-native'
import { manipulator, palette, template } from '@rezio/res/theme'
import { MaterialIcons, ThemedButton } from '@rezio/components'
import type { StyleProp, ViewStyle } from 'react-native'
import type { TFunction } from 'i18next'

interface ItemWrapperProps {
  t: TFunction
  isAddable?: boolean
  isSortable?: boolean
  editable?: boolean
  isClearable?: boolean
  onRemove?: () => void
  onUp?: () => void
  onDown?: () => void
  onReset?: () => void
  onAdd?: () => void
  containerStyle?: StyleProp<ViewStyle>
  itemStyle?: StyleProp<ViewStyle>
  itemCount?: number
  id?: number
  buttonStyle?: StyleProp<ViewStyle>
  addingContainerStyle?: StyleProp<ViewStyle>
  children: React.ReactNode
}

const ItemWrapper = (props: ItemWrapperProps) => {
  const { t, isAddable = true, isSortable = true, editable = true, isClearable = false } = props
  const buttons = [
    { iconName: 'delete', onPress: props.onRemove },
    ...(isSortable
      ? [
          { iconName: 'arrow-upward', onPress: props.onUp },
          { iconName: 'arrow-downward', onPress: props.onDown }
        ]
      : []),
    ...(isClearable ? [{ iconName: 'refresh', onPress: props.onReset }] : [])
  ]
  const addContainer = [manipulator.border('all', 'light', 5), { padding: 20 }]
  const itemButtons = props.buttonStyle || (isSortable ? { width: 150 } : { width: 50 })
  return (
    <View style={props.onAdd ? addContainer : props.containerStyle}>
      <View
        style={[
          template.rowContainer,
          { flex: 18 },
          props.onAdd || +_.get(props, 'id') + 1 === +_.get(props, 'itemCount')
            ? null
            : [manipulator.border('bottom', 'light'), { paddingBottom: 10 }],
          _.get(props, 'id') ? { paddingTop: 10 } : {},
          props.itemStyle
        ]}
      >
        <View style={props.onAdd ? [{ width: '100%' }, props.addingContainerStyle] : { flex: 10 }}>
          {props.children}
        </View>
        {editable ? (
          <View style={[manipulator.container('row', 'flex-start'), itemButtons]}>
            {buttons.map((eachBtn, index) => {
              const { iconName, onPress } = eachBtn
              const isResetBtn = iconName === 'refresh'
              return props.onAdd ? null : (
                <ThemedButton
                  key={`${iconName}-${index}`}
                  disabled={!onPress}
                  style={[
                    {
                      backgroundColor: 'transparent',
                      marginRight: isResetBtn ? 0 : 14,
                      height: 'auto',
                      paddingVertical: 0
                    },
                    isResetBtn ? { padding: 0 } : { width: 18 }
                  ]}
                  onPress={onPress}
                >
                  <MaterialIcons
                    name={iconName}
                    color={
                      isResetBtn ? palette.primary : onPress ? palette.gray : palette.lightGray
                    }
                    size={18}
                  />
                  {isResetBtn && (
                    <Text style={{ color: palette.primary, paddingLeft: 4 }}>
                      {t('COMMON.BUTTON_RESET')}
                    </Text>
                  )}
                </ThemedButton>
              )
            })}
          </View>
        ) : null}
      </View>
      {props.onAdd && (
        <View style={{ marginTop: 20 }}>
          <ThemedButton
            disabled={!isAddable}
            style={{
              backgroundColor: isAddable ? palette.lightBlue : palette.gray,
              minWidth: 56,
              height: 40
            }}
            onPress={props.onAdd}
          >
            <Text style={{ color: palette.white, fontSize: 16 }}>{t('COMMON.BUTTON_ADD')}</Text>
          </ThemedButton>
        </View>
      )}
    </View>
  )
}

export default ItemWrapper
