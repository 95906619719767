import type { EdgeInsets } from 'react-native-safe-area-context'
import {
  HoverableOpacity,
  LockedWrapper,
  MaterialIcons,
  Navigator,
  StaticImage,
  ThemedButton,
  ThemedDropdown
} from '@rezio/components'
import { Modal } from '@rezio/components/modal'
import { DropdownBaseView } from '@rezio/components/modalDropdown'
import { PLAN_FEATURES, TIERS, usePermission } from '@rezio/core/auth'
import { useLayout, useRouter, useStores } from '@rezio/core/hooks'
import { freshdeskI18nLabels, supportLanguages } from '@rezio/core/i18n'
import { openURL } from '@rezio/core/linking'
import { NotificationCenter } from '@rezio/core/notification'
import { manipulator, palette, template } from '@rezio/res/theme'
import { formatFreshdeskLocale, formatName } from '@rezio/utils/format'
import _ from 'lodash'
import { Observer, observer } from 'mobx-react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Dimensions, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { interval, merge, of } from 'rxjs'
import { AppConfigContext } from '../../core/config'
import { AnnouncementPanel, getIsAllChecked, setAllToChecked } from '../announcement'
import SelectStoreModal from './SelectStoreModal'

const headerLogoImage = require('../../assets/header-logo.png')
const headerStyles = StyleSheet.create({
  container: {
    backgroundColor: palette.primary,
    height: 60,
    padding: 15,
    borderBottomWidth: 0,
    flexDirection: 'row'
  },
  selectorText: {
    color: palette.white,
    fontSize: 16
  }
})

type TSessionStore = {
  id: number // 商店編號
  accountUuid: string
  authTier: TIERS
  isRobot: boolean
  permissionGroupUuid: string
  priceTier: string
  storeLogo: string
  storeTitle: string
  storeUuid: string
  tierPlatform: string
  tourtakoSubDomain: string
  uuid: string
  verifyStatus: number
}

interface HeaderProps {
  hideNav?: boolean
  showAppFreshdesk?: () => void // mobile, APP
}
const Header: React.FC<HeaderProps> = observer(function Header(props) {
  const { t } = useTranslation()
  const { core, store } = useStores()
  const { isMobile } = useLayout()
  const router = useRouter()
  const [openMenu, onOpenMenu] = useState(false)
  const [showStoreMenuModal, setShowStoreMenuModal] = useState(false)
  const { session } = store.core
  const insets: EdgeInsets = useSafeAreaInsets()
  const { hasPlanFeature } = usePermission()
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const [isAllChecked, setIsAllChecked] = useState(true)
  const { basic: storeBasic } = useContext(AppConfigContext)
  useEffect(() => {
    const subscription = merge(interval(5 * 60 * 1000), of(1)).subscribe(() =>
      store.core
        .loadAnnouncement()
        .then(() => getIsAllChecked(store.core.announcements))
        .then((allGreen: boolean) => {
          setIsAllChecked(allGreen)
          setShowAnnouncement(!allGreen)
        })
        .catch(console.error)
    )
    return () => subscription.unsubscribe()
  }, [])

  const onGoToMySite = useCallback(() => {
    const mySiteUrl = storeBasic?.mySiteUrl
    if (mySiteUrl) {
      return openURL(mySiteUrl, true)
    }
    return () => null
  }, [session?.store?.storeUuid, storeBasic?.mySiteUrl])

  const handlePressAccount = useCallback(
    function handlePressAccount() {
      router.push({ pathname: '/account/profile' })
    },
    [router]
  )

  const handleChangeStore = useCallback(
    async (storeUuid: string) => {
      if (storeUuid === _.get(session, 'store.storeUuid')) {
        return
      }
      await store.core.switchStore(storeUuid, { pathname: '/' })
    },
    [session, router]
  )

  const handleLogout = useCallback(() => {
    core.logout()
    router.push('/')
  }, [])

  const handleChangeUser = useCallback(async function handleChangeUser(type: string) {
    switch (type) {
      case 'name':
        handlePressAccount()
        break
      case 'logout':
        handleLogout()
        break
      case 'favorite':
        router.push('/favorite')
        break
    }
  }, [])

  const handleChangeLanguage = useCallback(
    (input: string) => {
      core.setLang(input)
      core.setPattern()
      // reload freshdesk while change language
      if (Platform.OS === 'web') {
        const locale = formatFreshdeskLocale(input)
        window.FreshworksWidget('close')
        window.fwSettings = {
          widget_id: 67000003397,
          locale
        }
        document.getElementById('freshworks-script')?.remove()
        const script = document.createElement('script')
        script.src = 'https://widget.freshworks.com/widgets/67000003397.js'
        script.type = 'text/javascript'
        script.async = true
        script.id = 'freshworks-script'
        script.onload = () => {
          // 必須等 launcher 顯示後才能隱藏
          setTimeout(() => {
            window.FreshworksWidget('hide', 'launcher')
            window.FreshworksWidget('setLabels', freshdeskI18nLabels)
          }, 1000)
        }
        document.body.appendChild(script)
      }
    },
    [core]
  )

  const handleClickPath = useCallback(
    (path: string) => {
      onOpenMenu(false)
      setTimeout(() => router.push(path), 100)
    },
    [onOpenMenu]
  )

  const languages = React.useMemo(() => {
    return supportLanguages.map((lang) => {
      return { label: t('LANGUAGE_SELF_LABEL', { lng: lang }), value: lang }
    })
  }, [supportLanguages])

  const handleGoHome = useCallback(() => {
    router.push({ pathname: '/' })
  }, [router])

  const toggleDrawer = useCallback(() => onOpenMenu((prevState) => !prevState), [])
  const stores = _.get(session, 'stores', []) as TSessionStore[]

  const renderStoreDropdownLabel = useCallback(
    (item: TSessionStore | undefined, { inner }: { inner: boolean /* 是否為 dropdown item */ }) => {
      if (_.isNil(item)) return null
      const isJPPlan = [TIERS.ENTRY, TIERS.STANDARD, TIERS.ADVANCE, TIERS.PREMIUM].includes(
        item.authTier
      )

      return (
        <View
          style={[
            { flexDirection: inner ? 'column-reverse' : 'row' },
            isMobile && !inner
              ? { flexGrow: 0, flexShrink: 1, flexBasis: 'auto', alignItems: 'center' }
              : { flex: 1 }
          ]}
        >
          <View
            style={
              !isMobile && inner
                ? [manipulator.container('row', 'space-between', 'center'), { marginTop: 4 }]
                : undefined
            }
          >
            {!isMobile && inner && (
              <Text
                style={{
                  backgroundColor: palette.panelBackground,
                  color: palette.gray,
                  borderRadius: 3,
                  paddingVertical: 2,
                  paddingHorizontal: 4,
                  fontSize: 12
                }}
              >
                {item.id}
              </Text>
            )}
            <Text
              style={{
                fontSize: isMobile ? 12 : 14,
                color: inner ? palette.gray : palette.white,
                backgroundColor: inner ? 'transparent' : '#0977f5',
                marginRight: inner ? 0 : 4,
                paddingVertical: inner ? 0 : 2,
                paddingHorizontal: inner ? 0 : 5,
                borderRadius: inner ? 0 : 5
              }}
            >
              {isJPPlan
                ? t('SUBSCRIPTION.PLAN_KATAKANA_NAME', { context: item.authTier })
                : t('SUBSCRIPTION.PLAN_NAME', { context: item.authTier })}
            </Text>
          </View>
          <View style={{ flexGrow: 0, flexShrink: 1, flexBasis: 'auto' }}>
            <Text
              numberOfLines={1}
              ellipsizeMode='tail'
              style={{
                fontSize: isMobile ? 12 : 16,
                color: inner ? palette.black : palette.white
              }}
            >
              {item.storeTitle}
            </Text>
          </View>
        </View>
      )
    },
    []
  )

  const closeStoreMenuModal = useCallback(() => setShowStoreMenuModal(false), [])

  const closeAnnouncement = useCallback(() => setShowAnnouncement(false), [setShowAnnouncement])

  const setAllAnnouncementToChecked = useCallback(() => {
    setAllToChecked(store.core.announcements)
    setIsAllChecked(true)
  }, [store.core.announcements])

  const dropdownItems = _.map(stores, (item) => ({
    label: (props: { inner: boolean }): React.ReactNode | null => {
      return renderStoreDropdownLabel(item, props)
    },
    title: item.id + item.storeTitle,
    value: item.storeUuid
  }))

  const { firstName = '', lastName = '' } = _.get(session, 'account', {})
  const userItems = [
    {
      label: (
        <View style={template.rowContainer}>
          <MaterialIcons name='person' color={palette.white} size={22} style={{ marginRight: 8 }} />
          <Text style={{ fontSize: 16, color: palette.white }}>
            {formatName(firstName, lastName)}
          </Text>
        </View>
      ),
      value: 'default'
    },
    ...(core?.permission?.priceTier !== TIERS.NONE
      ? [{ label: t('NAVIGATOR.PAGE_SETTING_ACCOUNT_FOR_ALL_SHOP'), value: 'name' }]
      : []),
    {
      label: (
        <View style={template.rowContainer}>
          <Text style={{ fontSize: 14, color: palette.black }}>{t('NAVIGATOR.PAGE_FAVORITE')}</Text>
        </View>
      ),
      value: 'favorite'
    },
    {
      label: (
        <View style={template.rowContainer}>
          <MaterialIcons
            style={{ paddingRight: 8, color: palette.black }}
            name='exit-to-app'
            color={palette.white}
            size={16}
          />
          <Text style={{ fontSize: 14, color: palette.black }}>{t('LOGIN.LOGOUT_BUTTON')}</Text>
        </View>
      ),
      value: 'logout'
    }
  ]

  const openFreshDesk = () => {
    if (Platform.OS === 'web') {
      typeof window.FreshworksWidget === 'function' && window.FreshworksWidget('open')
      return
    }
    props?.showAppFreshdesk()
  }

  const mobileMenuWidth = Math.min(Dimensions.get('window').width - 55, 320)

  return isMobile ? (
    <>
      <View
        style={[
          headerStyles.container,
          manipulator.container('row', 'space-between', 'center'),
          { padding: 5, marginBottom: -1 }
        ]}
      >
        <HoverableOpacity onPress={handleGoHome}>
          <StaticImage
            style={{ width: 80, height: 30, marginLeft: 12, marginBottom: 8 }}
            source={headerLogoImage}
          />
        </HoverableOpacity>
        <View
          style={[manipulator.container('row', 'flex-end', 'center'), { flex: 1, marginLeft: 5 }]}
        >
          <View>
            <TouchableOpacity onPress={openFreshDesk}>
              <MaterialIcons size={16} color='white' name='help' />
            </TouchableOpacity>
          </View>
          <View
            style={[
              !store.core.announcements?.length ? { opacity: 0.5 } : {},
              manipulator.margin()
            ]}
          >
            <TouchableOpacity
              onPress={() => store.core.announcements?.length && setShowAnnouncement(true)}
            >
              <MaterialIcons size={16} color='white' name='feedback' />
            </TouchableOpacity>
          </View>
          <NotificationCenter />
          <ThemedButton style={{ backgroundColor: 'transparent' }} onPress={toggleDrawer}>
            <MaterialIcons name='dehaze' color={palette.white} size={24} />
          </ThemedButton>
        </View>
        <AnnouncementPanel
          isVisible={showAnnouncement}
          showCountdown={!isAllChecked}
          announcements={store.core.announcements}
          closeModal={closeAnnouncement}
          setAllToChecked={setAllAnnouncementToChecked}
        />
      </View>
      <Modal
        hideModalContentWhileAnimating
        backdropTransitionInTiming={0}
        backdropTransitionOutTiming={0}
        onRequestClose
        isVisible={openMenu}
        animationIn='slideInRight'
        animationOut='slideOutRight'
        onBackdropPress={toggleDrawer}
        style={[
          StyleSheet.absoluteFill,
          { margin: 0, alignItems: 'flex-end', paddingTop: insets.top }
        ]}
      >
        <View
          style={{
            width: mobileMenuWidth,
            flex: 1,
            padding: 0,
            maxHeight: '100%',
            backgroundColor: 'white'
          }}
        >
          <View
            style={{ backgroundColor: palette.primary, height: insets.top, marginTop: -insets.top }}
          />
          <DropdownBaseView style={{ flex: 1, justifyContent: 'space-between' }}>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  backgroundColor: palette.primary,
                  paddingHorizontal: 10,
                  alignItems: 'baseline',
                  marginBottom: 22,
                  justifyContent: 'flex-end'
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 20
                  }}
                >
                  <MaterialIcons
                    name='language'
                    color={palette.white}
                    size={22}
                    style={{ paddingRight: 8 }}
                  />
                  <ThemedDropdown
                    value={core.lang}
                    onChange={handleChangeLanguage}
                    arrowColor={palette.white}
                    textStyle={headerStyles.selectorText}
                    items={languages}
                    layoutStyle={{ justifyContent: 'flex-end', width: 'auto' }}
                    style={{ minWidth: 65, maxWidth: 100 }}
                  />
                </View>
                <HoverableOpacity
                  disabled
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    padding: 0,
                    marginBottom: 12,
                    marginTop: 12
                  }}
                >
                  <MaterialIcons
                    name='account-circle'
                    color={palette.white}
                    size={22}
                    style={{ paddingRight: 8, marginTop: 2 }}
                  />
                  <Text style={{ fontSize: 16, color: palette.white, textAlign: 'left', flex: 1 }}>
                    {formatName(firstName, lastName)}
                  </Text>
                </HoverableOpacity>
                <ThemedButton
                  onPress={() => setShowStoreMenuModal(_.size(stores) > 1)}
                  style={{
                    backgroundColor: 'transparant',
                    paddingHorizontal: 0,
                    paddingRight: 8,
                    justifyContent: 'flex-start'
                  }}
                  titleStyles={{ color: palette.white }}
                  containerStyle={{ width: '100%' }}
                >
                  {_.size(stores) > 1 ? (
                    <>
                      {renderStoreDropdownLabel(
                        stores.find(
                          ({ storeUuid }) => storeUuid === _.get(session, 'store.storeUuid')
                        ),
                        { inner: false }
                      )}
                      <MaterialIcons name='arrow-drop-down' color={palette.white} size={20} />
                    </>
                  ) : _.size(stores) === 1 ? (
                    renderStoreDropdownLabel(stores[0], { inner: false })
                  ) : null}
                </ThemedButton>
              </View>
              {!props.hideNav && <Navigator style={{ flex: 1 }} onClickPath={handleClickPath} />}
            </View>
            <View
              style={[
                manipulator.container('row', 'center'),
                { width: '100%', flexWrap: 'wrap', paddingHorizontal: 10 }
              ]}
            >
              <Observer>
                {() => {
                  return storeBasic?.mySiteUrl ? (
                    hasPlanFeature(PLAN_FEATURES.REZIO_BASIC) ? (
                      <ThemedButton
                        onPress={onGoToMySite}
                        style={{ height: 40, backgroundColor: 'transparent' }}
                        containerStyle={{
                          borderColor: palette.primary,
                          borderWidth: 1,
                          borderRadius: 5,
                          marginVertical: 10,
                          width: '100%'
                        }}
                      >
                        <MaterialIcons
                          style={{ paddingRight: 8 }}
                          name='phonelink'
                          color={palette.primary}
                          size={20}
                        />
                        <Text style={{ color: palette.primary }}>
                          {t('MYSITE.GO_TO_MYSITE_BUTTON')}
                        </Text>
                      </ThemedButton>
                    ) : (
                      <View
                        style={[
                          manipulator.border('all', 'light', 5),
                          { height: 40, padding: 8, marginHorizontal: 10, width: '100%' }
                        ]}
                      >
                        <LockedWrapper wrapperStyle={{ justifyContent: 'center' }}>
                          <MaterialIcons
                            style={{ paddingRight: 8 }}
                            name='phonelink'
                            color={palette.disableLight}
                            size={20}
                          />
                          <Text style={{ color: palette.disableLight }}>
                            {t('MYSITE.GO_TO_MYSITE_BUTTON')}
                          </Text>
                        </LockedWrapper>
                      </View>
                    )
                  ) : (
                    <View />
                  )
                }}
              </Observer>
              <ThemedButton
                onPress={handleLogout}
                style={{ height: 40, backgroundColor: 'transparent' }}
                containerStyle={{
                  borderColor: palette.primary,
                  borderWidth: 1,
                  borderRadius: 5,
                  marginVertical: 10,
                  width: '100%'
                }}
              >
                <MaterialIcons
                  style={{ paddingRight: 8 }}
                  name='exit-to-app'
                  color={palette.primary}
                  size={20}
                />
                <Text style={{ color: palette.primary }}>{t('LOGIN.LOGOUT_BUTTON')}</Text>
              </ThemedButton>
              {Platform.OS === 'web' && !!window.__WEB_REVISION_ID__ && (
                <Text
                  style={{
                    color: palette.darkGray,
                    fontSize: 12,
                    textAlign: 'center',
                    marginBottom: 8
                  }}
                >
                  {window.__WEB_REVISION_ID__}
                </Text>
              )}
            </View>
          </DropdownBaseView>
        </View>
        <SelectStoreModal
          isVisible={showStoreMenuModal}
          stores={stores}
          onClose={closeStoreMenuModal}
          onChangeStore={handleChangeStore}
        />
      </Modal>
    </>
  ) : (
    <View style={[headerStyles.container, template.shadow]}>
      <View style={manipulator.container('row', 'center', 'center')}>
        <HoverableOpacity onPress={() => router.push({ pathname: '/' })}>
          <StaticImage
            style={{ width: 80, height: 30, marginBottom: 8 }}
            source={headerLogoImage}
          />
        </HoverableOpacity>
        <Text
          style={{
            paddingLeft: 10,
            color: palette.white,
            fontSize: 18,
            fontWeight: '400',
            marginRight: 20
          }}
        >
          {t('COMMON.BRANDING_QUOTE')}
        </Text>
        {_.size(stores) > 1 ? (
          <ThemedDropdown
            searchable
            arrowColor={palette.white}
            style={{ paddingRight: 20 }}
            textStyle={{ fontSize: 16 }}
            onChange={handleChangeStore}
            value={_.get(session, 'store.storeUuid')}
            items={dropdownItems}
          />
        ) : (
          <View style={{ paddingRight: 20 }}>
            {renderStoreDropdownLabel(stores[0], { inner: false })}
          </View>
        )}
        {hasPlanFeature(PLAN_FEATURES.REZIO_BASIC) ? (
          <ThemedButton
            onPress={onGoToMySite}
            style={{ minWidth: 100, backgroundColor: 'transparent' }}
            layout='default'
          >
            <MaterialIcons name='phonelink' color={palette.white} size={18} />
            <Text style={{ fontSize: 16, color: palette.white, marginLeft: 10 }}>
              {t('MYSITE.GO_TO_MYSITE_BUTTON')}
            </Text>
          </ThemedButton>
        ) : (
          <View style={[{ height: 40, padding: 8, opacity: 0.5 }]}>
            <LockedWrapper wrapperStyle={{ justifyContent: 'center' }} color={palette.white}>
              <MaterialIcons name='phonelink' color={palette.white} size={18} />
              <Text style={{ fontSize: 16, color: palette.white, marginLeft: 10 }}>
                {t('MYSITE.GO_TO_MYSITE_BUTTON')}
              </Text>
            </LockedWrapper>
          </View>
        )}
      </View>
      <View
        style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <View>
          <TouchableOpacity onPress={openFreshDesk}>
            <MaterialIcons size={16} color='white' name='help' />
          </TouchableOpacity>
        </View>
        <View
          style={[!store.core.announcements?.length ? { opacity: 0.5 } : {}, manipulator.margin()]}
        >
          <TouchableOpacity
            onPress={() => store.core.announcements?.length && setShowAnnouncement(true)}
          >
            <MaterialIcons size={16} color='white' name='feedback' />
          </TouchableOpacity>
        </View>
        <NotificationCenter />
        <ThemedDropdown
          portalTarget='header-dropdowns'
          value={core.lang}
          onChange={handleChangeLanguage}
          arrowColor={palette.white}
          style={{ marginRight: 20, minWidth: 65, maxWidth: 200 }}
          textStyle={headerStyles.selectorText}
          items={languages}
        />
        <ThemedDropdown
          arrowColor={palette.white}
          textStyle={{ fontSize: 14 }}
          textContainerStyle={{ flexDirection: 'column' }}
          style={{ minWidth: 140, maxWidth: 200 }}
          onChange={handleChangeUser}
          value={userItems[0].value}
          items={userItems}
        />
      </View>
      <AnnouncementPanel
        isVisible={showAnnouncement}
        showCountdown={!isAllChecked}
        announcements={store.core.announcements}
        closeModal={closeAnnouncement}
        setAllToChecked={setAllAnnouncementToChecked}
      />
    </View>
  )
})

export default Header
