// import FlatAmex from 'payment-icons/min/flat/amex.svg'
// import FlatJCB from 'payment-icons/min/flat/jcb.svg'
// import FlatMasterCard from 'payment-icons/min/flat/mastercard.svg'
// import FlatVisa from 'payment-icons/min/flat/visa.svg'
// import MonoAmex from 'payment-icons/min/mono/amex.svg'
// import MonoJCB from 'payment-icons/min/mono/jcb.svg'
// import MonoMasterCard from 'payment-icons/min/mono/mastercard.svg'
// import MonoVisa from 'payment-icons/min/mono/visa.svg'
import { View } from 'react-native'
const FlatAmex = () => <View />
const FlatJCB = () => <View />
const FlatMasterCard = () => <View />
const FlatVisa = () => <View />
const MonoAmex = () => <View />
const MonoJCB = () => <View />
const MonoMasterCard = () => <View />
const MonoVisa = () => <View />

const Svgs = {
  FlatAmex,
  FlatJCB,
  FlatMasterCard,
  FlatVisa,
  MonoAmex,
  MonoJCB,
  MonoMasterCard,
  MonoVisa
}

export { Svgs }
