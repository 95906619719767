import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import 'intl-pluralrules' // polyfill

import { getConfig } from '@rezio/core/config'
import resources from '@rezio/res/translations'
import * as Localization from 'expo-localization'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import _ from 'lodash'

export const supportLanguages = _(resources)
  .keys()
  .filter(
    (lang) =>
      getConfig().API_BASE_URL === 'https://prototype-nimda.sit.rezio.io/' ||
      (!_.isEmpty(resources[lang].translation.LANGUAGE_UUID) && !!lang)
  )
  .compact()
  .value()

export function negotiateLang(lang: string) {
  lang = lang ?? Localization.locale
  if (lang.startsWith('zh')) {
    if (
      lang.startsWith('zh-Hant') ||
      lang.toUpperCase().includes('TW') ||
      lang.toUpperCase().includes('HK')
    ) {
      lang = 'zh-TW'
    } else {
      lang = 'zh-CN'
    }
  } else {
    lang =
      supportLanguages.find((supportLanguage) =>
        supportLanguage.startsWith(lang.substring(0, 2))
      ) || 'en-US'
  }

  return lang
}

export function getDeviceLang() {
  return negotiateLang(Localization.locale)
}

export const instance = i18next.createInstance({
  initImmediate: false,
  debug: false,
  keySeparator: '..',
  resources,
  fallbackLng: 'en-US',
  interpolation: {
    skipOnVariables: false,
    escapeValue: false
  }
})

instance.use(intervalPlural).use(initReactI18next).init().catch(console.error)

export const freshdeskI18nLabels = {
  'zh-TW': {
    banner: 'rezio 支援中心',
    launcher: '支援',
    contact_form: {
      title: '聯絡我們',
      submit: '送出',
      confirmation: '感謝您寶貴的回饋！'
    }
  },
  'zh-CN': {
    banner: 'rezio 支持中心',
    launcher: '支持',
    contact_form: {
      title: '联络我们',
      submit: '送出',
      confirmation: '感谢您宝贵的回馈！'
    }
  },
  'ja-JP': {
    banner: 'Rezioユーザーガイド',
    launcher: 'ユーザーガイド',
    contact_form: {
      title: 'お問い合わせ',
      submit: '送信',
      confirmation:
        'お問い合わせありがとうございます。担当者よりご連絡を差し上げますので、今しばらくお待ちください。'
    }
  },
  ko: {
    banner: 'rezio 고개센터',
    launcher: '문의',
    contact_form: {
      title: '연락하기',
      submit: '보냄',
      confirmation: '피드백에 감사드립니다.'
    }
  }
}
