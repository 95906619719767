import { DSButton } from '@rezio/components/ds/button'
import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'

export interface ErrorBoundaryProps {
  children: React.ReactNode
  onResetError?: () => void
}

interface ErrorBoundaryStates {
  hasError: boolean
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const ThemedErrorBoundary: React.FC<ErrorBoundaryProps> = (props) => {
  const { t } = useTranslation()
  const { onResetError } = props

  return (
    <View className='flex flex-col items-center py-4'>
      <Text className='mb-6 text-3xl font-bold text-primary-600'>
        {t('COMMON.GENERAL_ERROR_TITLE')}
      </Text>
      <Text className='mb-5 text-sm font-normal'>{t('COMMON.GENERAL_ERROR_DESCRIPTION')}</Text>
      <DSButton
        className='w-fit'
        intent='normal'
        onPress={onResetError}
        label={t('COMMON.BUTTON_RELOAD')}
      />
    </View>
  )
}

ThemedErrorBoundary.displayName = 'ThemedErrorBoundary'

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryStates> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  resetError = () => {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ThemedErrorBoundary
          {...this.props}
          onResetError={async () => {
            await delay(500)
            this.resetError()
            this.props.onResetError?.()
          }}
        />
      )
    }

    return this.props.children
  }
}

export { ThemedErrorBoundary }

export default ErrorBoundary
