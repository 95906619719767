import { StaticImage } from '@rezio/components'
import { template, manipulator, palette } from '@rezio/res/theme'
import _ from 'lodash'
import { inject } from 'mobx-react'
import React, { useState } from 'react'
import type { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Text, View, Platform, StyleSheet } from 'react-native'

import { ThemedButton } from '../themedComponents'
import { BaseThemedListElement } from './types'

interface Props {
  isColumn?: boolean
  isBorderItem?: boolean
  label?: string
  disabled?: boolean
  items: Item[]
  theme?: Record<string, string>
  value: string
  onChange: (value: string, key: string) => null
  layout?: 'default' | 'desktop'
  containerStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<ViewStyle>
  itemButtonStyle?: StyleProp<ViewStyle>
  itemButtonContainerStyle?: StyleProp<ViewStyle>
  itemContainerStyle?: StyleProp<ViewStyle>
  titleStyles?: StyleProp<TextStyle>
  descStyles?: StyleProp<TextStyle>
  labelContainerStyle?: StyleProp<TextStyle>
  className?: string
}

interface Item extends BaseThemedListElement {
  description?: string
  disabled?: boolean
  additionalComponent?: JSX.Element
  additionalStyle?: StyleProp<ViewStyle>
  key?: string
  imageSource?: string
  ItemComponent?: React.ComponentType<unknown>
  imageStyle?: StyleProp<ViewStyle>
}

const styles = StyleSheet.create({
  radioBtn: {
    height: 16,
    width: 16,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.white,
    marginRight: 10
  }
})

export const ThemedRadioButton = inject(
  'theme',
  'store'
)(function ThemedRadioButton(props: Props) {
  const [selectedValue, setSelectedValue] = useState(props.value)

  const handlePress = (value, key) => {
    props.onChange(value, key)
    setSelectedValue(value)
  }

  // @ts-expect-error
  const isMobile = props.store.viewStore.layout !== 'desktop'
  const {
    isColumn = false,
    isBorderItem = false,
    label = '',
    disabled = false,
    items,
    theme,
    value: propsValue,
    layout = 'default',
    containerStyle = {},
    labelStyle = {},
    itemButtonStyle = {},
    itemButtonContainerStyle = {},
    itemContainerStyle = {},
    titleStyles,
    descStyles,
    labelContainerStyle,
    className = null
  } = props
  const currentValue = propsValue ?? selectedValue
  return (
    <View
      className={`${className} print-exclusion`}
      style={[
        isColumn ? template.columnContainer : template.rowContainer,
        { flexWrap: 'wrap' },
        containerStyle
      ]}
      accessibilityRole='radiogroup'
    >
      {!_.isEmpty(label) && (
        <Text style={[{ marginBottom: 10, fontWeight: '700', fontSize: 14 }, labelStyle]}>
          {label}
        </Text>
      )}
      {items.map((item, index) => {
        const {
          value,
          label = '',
          description = '',
          disabled: itemDisabled,
          additionalComponent,
          additionalStyle = {},
          key: itemKey,
          imageSource,
          ItemComponent = View,
          imageStyle
        } = item
        const isDisabeled = itemDisabled || disabled

        return (
          <ItemComponent
            key={itemKey || index}
            style={[
              isBorderItem
                ? {
                    borderWidth: 1,
                    borderColor: currentValue === value ? palette.primary : palette.border,
                    borderRadius: 10,
                    padding: 16,
                    marginBottom: 8
                  }
                : {},
              itemContainerStyle
            ]}
          >
            <ThemedButton
              disabled={isDisabeled}
              textStyle={{ color: theme.black }}
              style={[
                manipulator.panel(0, 0, 0, 0),
                isMobile
                  ? { marginBottom: _.isEmpty(additionalComponent) ? 16 : 0 }
                  : { height: 40 },
                manipulator.container('row', 'flex-start', 'center'),
                itemButtonStyle
              ]}
              onPress={() => handlePress(value, index)}
              accessibilityRole='radio'
              containerStyle={_.isEmpty(additionalComponent) ? itemButtonContainerStyle : ''}
            >
              <RadioCircle checked={currentValue === value} disabled={isDisabeled} />
              {imageSource && <StaticImage source={imageSource} style={imageStyle} />}
              <View
                style={[
                  manipulator.container('row', 'flex-start', 'center'),
                  Platform.OS === 'web' ? { flex: 1 } : {},
                  labelContainerStyle
                ]}
              >
                {typeof label === 'string' ? (
                  label && (
                    <Text
                      style={[
                        {
                          fontSize: 14,
                          width: 130,
                          marginRight: _.isEmpty(description) ? 30 : 10,
                          fontWeight:
                            _.isEmpty(description) || layout === 'default'
                              ? theme.fontweightNormal
                              : theme.fontweightBold
                        } as any,
                        titleStyles
                      ]}
                    >
                      {label}
                    </Text>
                  )
                ) : (
                  <View style={[{ marginRight: _.isEmpty(description) ? 30 : 10 }, titleStyles]}>
                    {label}
                  </View>
                )}
                {!_.isEmpty(description) && (
                  <Text style={[{ fontSize: 14 }, descStyles]}>{description}</Text>
                )}
              </View>
            </ThemedButton>
            {!_.isEmpty(additionalComponent) && (
              <View style={[{ paddingVertical: 10 }, additionalStyle]}>{additionalComponent}</View>
            )}
          </ItemComponent>
        )
      })}
    </View>
  )
})

export const RadioCircle = ({ checked, disabled = false, style = {} }) => (
  <View
    style={[
      styles.radioBtn,
      disabled ? { backgroundColor: palette.disable } : {},
      checked
        ? { borderWidth: 5, borderColor: disabled ? palette.disableLight : palette.active }
        : {},
      style
    ]}
    accessibilityState={{ disabled, checked }}
  />
)
