import { HoverableOpacity, MaterialIcons, PageWidthModal, PageStepControl } from '@rezio/components'
import { usePermission, ROLE_TITLE, PLAN_FEATURES } from '@rezio/core/auth'
import { useRouter } from '@rezio/core/hooks'
import { palette, manipulator } from '@rezio/res/theme'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    position: 'absolute',
    transform: [{ translateY: 40 }],
    top: 0,
    right: -14,
    zIndex: 10
  },
  square: {
    padding: 15,
    width: 240,
    height: 'auto',
    backgroundColor: palette.white,
    borderRadius: 10,
    shadowColor: palette.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 5
  },
  whiteTriangle: {
    position: 'absolute',
    right: 10,
    top: '-12%',
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 15,
    borderRightWidth: 15,
    borderBottomWidth: 15,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: palette.white
  },
  shadowTriangle: {
    position: 'absolute',
    right: 10,
    top: '-15%',
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 15,
    borderRightWidth: 15,
    borderBottomWidth: 15,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: palette.panelBackground
  }
})

export function DropdownMenu({ dropdownContent = [], setDropdownMenuIndex, isEnabledTtd }) {
  const router = useRouter()
  const { t } = useTranslation()
  const { role, planFeatures } = usePermission('basic')
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const hasSubscriptionPermission = [ROLE_TITLE.STORE_OWNER, ROLE_TITLE.REZIO_EXPERT].includes(role)
  const hasGoogleTtdPermission = planFeatures.includes(PLAN_FEATURES.CHANNEL_TTD)
  const handleToUpgrade = useCallback(() => {
    setIsVisibleModal((prevState) => !prevState)
    router.push({ pathname: '/myplan/subscription' })
  }, [])

  const handleToMysiteChannelPage = useCallback(() => {
    setIsVisibleModal((prevState) => !prevState)
    router.push({ pathname: '/channel/mysite' })
  }, [])

  const handlePageStepControlButton = useCallback(() => {
    // 尚未啟用Google TTD && 目前方案有權限使用Google TTD
    if (!isEnabledTtd && hasGoogleTtdPermission) {
      return [
        {
          type: 'negative',
          text: t('FORM.BUTTON_CANCEL'),
          onPress: () => {
            setIsVisibleModal((prevState) => !prevState)
            setDropdownMenuIndex(undefined)
          }
        },
        {
          type: 'positive',
          text: t('CHANNEL.BUTTON_SETTING_NOW'),
          onPress: () => {
            handleToMysiteChannelPage()
          }
        }
      ]
    }
    // 有權限訂閱方案 && 目前方案有權限使用Google TTD
    if (hasSubscriptionPermission || hasGoogleTtdPermission) {
      return [
        {
          type: 'negative',
          text: t('FORM.BUTTON_CANCEL'),
          onPress: () => {
            setIsVisibleModal((prevState) => !prevState)
            setDropdownMenuIndex(undefined)
          }
        },
        { type: 'positive', text: t('SUBSCRIPTION.HEADER_UPDATE_TIER'), onPress: handleToUpgrade }
      ]
    }
    return [
      {
        type: 'positive',
        text: t('FORM.BUTTON_CONFIRM'),
        onPress: () => {
          setIsVisibleModal((prevState) => !prevState)
          setDropdownMenuIndex(undefined)
        }
      }
    ]
  }, [hasSubscriptionPermission, isEnabledTtd])

  return (
    <>
      <View style={styles.container}>
        <View style={styles.square}>
          <Text style={[{ fontSize: 14, fontWeight: 'bold' }]}>{t('COMMON.OPERATIONAL')}</Text>
          <Text style={{ display: 'flex', flexDirection: 'column' }}>
            {dropdownContent.map((item, index) => {
              return (
                <HoverableOpacity key={index} style={{ display: 'flex' }}>
                  <View
                    style={[
                      manipulator.container('row', 'flex-start', 'center'),
                      { paddingLeft: 20, paddingTop: 10, display: 'flex' }
                    ]}
                  >
                    {item.disabled && (
                      <MaterialIcons
                        name='lock'
                        size={14}
                        color={palette.disableLight}
                        style={[{ paddingRight: 5 }]}
                      />
                    )}
                    <Text
                      style={{ fontSize: 14 }}
                      onPress={() => {
                        if (item.disabled) {
                          setIsVisibleModal((prevState) => !prevState)
                          return
                        }
                        if (!item.disabled) {
                          router.push({ pathname: item.pathName, query: item.query })
                        }
                      }}
                    >
                      {item.text}
                    </Text>
                  </View>
                </HoverableOpacity>
              )
            })}
          </Text>
        </View>
        <View style={styles.shadowTriangle} />
        <View style={styles.whiteTriangle} />
      </View>
      <PageWidthModal isVisible={isVisibleModal}>
        <View style={{ borderWidth: 1, borderColor: palette.border, borderRadius: 5, padding: 20 }}>
          {(isEnabledTtd || !hasGoogleTtdPermission) && (
            <>
              <Text
                style={{
                  fontSize: 20,
                  lineHeight: 40,
                  color: palette.primary,
                  paddingBottom: 8,
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {t('PERMISSION.TITLE_IS_INVAILD')}
              </Text>
              <Text style={{ fontSize: 14, textAlign: 'center' }}>
                {t('PERMISSION.TIPS_IS_INVAILD', {
                  context: hasSubscriptionPermission ? 'OWNER' : undefined
                })}
              </Text>
            </>
          )}
          {!isEnabledTtd && hasGoogleTtdPermission && (
            <>
              <Text
                style={{
                  fontSize: 20,
                  lineHeight: 40,
                  color: palette.primary,
                  paddingBottom: 8,
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {t('PERMISSION.TIPS_IS_DISABLED')}
              </Text>
              <Text style={{ fontSize: 14, textAlign: 'center' }}>
                {t('PERMISSION.CHANNEL_ENABLE_TTD')}
              </Text>
            </>
          )}
        </View>
        <PageStepControl buttons={handlePageStepControlButton()} />
      </PageWidthModal>
    </>
  )
}
