import {
  Dropdown,
  FormComponent,
  HoverableOpacity,
  MaterialIcons,
  PageStepControl,
  PageWidthModal,
  ThemedButton,
  ThemedTextInput
} from '@rezio/components/index'
import { Page, PageFooter } from '@rezio/components/layout'
import { DropdownBaseView } from '@rezio/components/modalDropdown'
import { useLayout, usePageWidth, useRouter, useStores } from '@rezio/core/hooks'
import { useKeystoneStores } from '@rezio/core/stores/rootStore'
import { manipulator, palette, template } from '@rezio/res/theme'
import { formatSymbol } from '@rezio/utils/format'
import { concatStrings } from '@rezio/utils/fp'
import _ from 'lodash'
import memoize from 'memoize-one'
import { Observer, inject, observer } from 'mobx-react'
import { always, complement, converge, prop, propEq } from 'ramda'
import React, {
  Component,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { ActivityIndicator, FlatList, Text, View } from 'react-native'
import { NoticeArea } from '@rezio/components/notice'

// const debug = require('debug')('tako:filterable_table')
const dateRangeTypes = [
  'dateRange',
  'bookingDateRange',
  'departureDateRange',
  'redeemDateRange',
  'createDateRange',
  'cancelApplicationDateRange',
  'cancelDateRange'
]

export const Separator = ({ highlighted, style, forceLight }) => {
  const { isMobile } = useLayout()
  const colorStyle = isMobile && !forceLight ? '' : 'light'
  return (
    <View
      style={[manipulator.border('bottom', colorStyle), highlighted && { marginLeft: 0 }, style]}
    />
  )
}

const EmptyMessage = inject('t')(
  class EmptyMessage extends Component {
    render() {
      const { t } = this.props
      return (
        <View>
          <Text style={{ fontWeight: '500', marginBottom: 10, textAlign: 'center' }}>
            {t('FORM.EMPTY_SELECT')}
          </Text>
          <Text style={{ textAlign: 'center' }}>{`* ${t('FORM.EMPTY_SELECT_HINT')}`}</Text>
        </View>
      )
    }
  }
)

const FilterableTable = forwardRef((props, ref) => {
  const { t } = useStores()
  const { isMobile } = useLayout()
  const router = useRouter()
  const {
    // core props
    onGotoPage,
    onChangeFilters,
    disableFiltering,
    FilterComponent = GridFilter,
    filterProps,

    // add component --- will be deprecated!
    onAdd,
    AddItemComponent,
    onAddItemComplete,
    hasSelector,
    buttonStyle,
    buttonText = t('COMMON.BUTTON_ADD'),
    isNonSessionProduct = false,

    // legacy filter
    filterFormCol,
    filters: filterConfig,
    keepFilterIcon,
    showFilterTags = false,
    showMoreFilterCount = null,
    isShowFilterPopupContent = true,
    onFilterRuleComponent,
    AdditionalComponentStyle,
    AdditionalComponent,
    updateFilterOn,
    hiddenFiltersRule,
    isDirect = true,
    isStrict = true,

    // rest props
    ...restProps
  } = props
  const localRef = useRef()
  const filterRef = ref || localRef

  const validHiddenFilters = _.compact(
    _.remove(_.split(router.query?.filters, ','), (item) => !_.includes(hiddenFiltersRule, item))
  )
  const [directChange, setDirectChange] = useState(isDirect || !_.isEmpty(validHiddenFilters))
  const [expandable, setExpandable] = useState(
    showMoreFilterCount > 0 && _.isEmpty(router.query?.filters)
  )
  const [filters, setFilters] = useState(
    FilterComponent === GridFilter && !_.isEmpty(router.query?.filters) ? null : []
  )

  useEffect(() => {
    if ((router.query?.filters || '').indexOf('direct') > -1) {
      setDirectChange(true)
      !directChange && setExpandable(false)
    }
  }, [router.query?.filters])

  useEffect(() => {
    if (_.isEmpty(validHiddenFilters) && !isDirect) {
      setDirectChange(false)
      setExpandable(false)
    }
  }, [validHiddenFilters, isDirect])

  const handlePageChange = useCallback(
    (nextPage) => {
      typeof onGotoPage === 'function' && onGotoPage(nextPage, filters, _.get(router, 'query.sort'))
    },
    [onGotoPage, filters]
  )

  const handleChangeFilter = useCallback((filters) => {
    setFilters(filters)
  }, [])

  useEffect(() => {
    const isFiltersNoError = _.isEmpty(filterRef?.current?.errors)
    let isValidRequiredRule = true
    if (_.isArray(filterProps?.requiredRule)) {
      isValidRequiredRule = filterProps.requiredRule.every((filedName) =>
        filters?.find((filter) => filter?.path === filedName)
      )
    }
    filters !== null &&
      isValidRequiredRule &&
      isFiltersNoError &&
      onChangeFilters?.(filters, _.get(router, 'query.sort'))
  }, [filters, JSON.stringify(filterProps?.requiredRule)])

  // add component will be deprcated, use Page's right items instead! ======>
  const [adding, setAdding] = useState(false)
  const [addFormKey, setAddFormKey] = useState(`${Date.now()}-${Math.floor(Math.random() * 100)}`)
  const showAddForm = useCallback(() => {
    setAdding(true)
  }, [])
  const hideAddForm = useCallback(() => {
    setAdding(false)
  }, [])
  const handlePressAdd = useCallback(() => {
    typeof onAdd === 'function' && onAdd()
    AddItemComponent && showAddForm()
  })

  const handleExpandable = useCallback(
    (value) => {
      if (!isDirect) {
        setExpandable(value)
      }
    },
    [isDirect]
  )

  const handleDirectChange = useCallback(
    (value) => {
      if (!isDirect) {
        setDirectChange(value)
      }
    },
    [isDirect]
  )

  const handleAddFormOnComplete = useCallback(
    (value) => {
      onAddItemComplete && onAddItemComplete()
      hideAddForm()
      setAddFormKey(`${Date.now()}-${Math.floor(Math.random() * 100)}`)
    },
    [onAddItemComplete]
  )
  // <======= end of add component

  const table = disableFiltering ? (
    <PagedFlatList
      onPageChange={handlePageChange}
      AdditionalComponent={AdditionalComponent}
      AdditionalComponentStyle={AdditionalComponentStyle}
      {...restProps}
    />
  ) : (
    <FilterablePagedFlatList
      filters={filters}
      AdditionalComponent={AdditionalComponent}
      AdditionalComponentStyle={AdditionalComponentStyle}
      {...restProps}
    />
  )

  return (
    <View>
      <View style={[manipulator.container('row', 'space-between')]}>
        <FilterComponent
          onChangeFilters={handleChangeFilter}
          ref={filterRef}
          expandable={expandable}
          onExpandable={handleExpandable}
          directChange={directChange}
          onDirectChange={handleDirectChange}
          source='formComponent'
          // legacy support
          filterFormCol={filterFormCol}
          filters={filterConfig}
          keepFilterIcon={keepFilterIcon}
          showFilterTags={showFilterTags}
          showMoreFilterCount={showMoreFilterCount}
          isShowFilterPopupContent={isShowFilterPopupContent}
          onFilterRuleComponent={onFilterRuleComponent}
          AdditionalComponentStyle={AdditionalComponentStyle}
          AdditionalComponent={AdditionalComponent}
          updateFilterOn={updateFilterOn}
          hiddenFiltersRule={hiddenFiltersRule}
          isStrict={isStrict}
          {...filterProps}
        />
        {(onAdd || AddItemComponent) && (
          <>
            <ThemedButton
              onPress={handlePressAdd}
              style={[
                { margin: 0, padding: 0, minWidth: 80, backgroundColor: 'transparent' },
                { position: 'absolute', top: isMobile ? -120 : hasSelector ? 0 : -80, right: 0 },
                buttonStyle
              ]}
              layout='default'
            >
              {typeof buttonText === 'string' ? (
                <Text>
                  <MaterialIcons name='add' color={palette.primary} size={14} />
                  <Text
                    style={{ fontSize: 14, color: palette.primary, lineHeight: 40, paddingLeft: 4 }}
                  >
                    {buttonText}
                  </Text>
                </Text>
              ) : (
                buttonText
              )}
            </ThemedButton>
            {AddItemComponent && (
              <PageWidthModal
                isVisible={adding}
                onBackdropPress={hideAddForm}
                style={{ padding: 0 }}
              >
                <Page title={t('COMMON.BUTTON_ADD')}>
                  <AddItemComponent
                    key={addFormKey}
                    onCancel={hideAddForm}
                    onComplete={handleAddFormOnComplete}
                    isNonSessionProduct={isNonSessionProduct}
                  />
                </Page>
              </PageWidthModal>
            )}
          </>
        )}
      </View>
      {props.noticeKey ? <NoticeArea className='mt-5' translationKey={props.noticeKey} /> : null}
      {directChange && table}
    </View>
  )
})

const DefaultFilter = forwardRef(function DefaultFilter(props, ref) {
  const pageWidth = usePageWidth()
  const { t, store } = useStores()
  const { isMobile } = useLayout()

  return (
    <LegacyFilter
      ref={ref}
      pageWidth={pageWidth}
      t={t}
      store={store}
      isMobile={isMobile}
      {...props}
    />
  )
})

function FilterablePagedFlatList(props) {
  const { data, pageRows = 5, filters, ...restProps } = props
  const [currentPage, setCurrentPage] = useState(1)
  const filteredData = useMemo(() => {
    return _.filter(data, (item) => {
      return _.every(filters, ({ path, type, value }) => {
        const target = _.get(item, path)
        switch (type) {
          case 'text':
            return typeof target === 'string' && target.includes(value)
          case 'equal':
            return _.isEqual(target, value)
          case 'in':
            return _.isArray(value) && _.includes(value, target)
        }
      })
    })
  }, [data, filters])

  const pagination = useMemo(() => {
    const start = pageRows * (currentPage - 1)
    return {
      data: _.slice(filteredData, start, start + pageRows),
      currentPage,
      totalPage: Math.min(Math.ceil(filteredData.length / pageRows), 1)
    }
  }, [filteredData, currentPage])

  const handlePageChange = useCallback(
    (nextPage) => {
      setCurrentPage(nextPage)
    },
    [setCurrentPage]
  )
  return <PagedFlatList {...restProps} onPageChange={handlePageChange} {...pagination} />
}

function PagedFlatList(props) {
  const {
    ListHeaderComponent,
    ListHeaderComponentStyle,
    showListHeaderDivider = true,
    onPressItem = () => {},
    selectedConfig,
    renderItem,
    sortable = false,
    selectable = false,
    selectedTips,
    onPageChange,
    data,
    numColumns = 1,
    currentPage,
    totalPage,
    showTotalCount = true,
    totalCount,
    emptyMessage,
    emptyMessageViewStyle,
    sequencedConfig,
    AdditionalComponent,
    AdditionalComponentStyle,
    mobileRowStyle = {},
    renderRowStyle,
    showPagination = true,
    ListWrapperComponent,
    isCellRender = false,
    SelectableComponent = ThemedButton,
    isMultiSelect = true,
    customSelectBtnName,
    ...restProps
  } = props
  const { t } = useStores()
  const router = useRouter()
  const { isMobile } = useLayout()
  const dropdownRef = useRef()
  const [selectedArray, onSelectedArray] = useState([])
  const [sortableList, setSortableList] = useState([])
  const [isOpenChosenMode, onOpenChosenMode] = useState(false)
  const [isOpenSortableMode, onOpenSortableMode] = useState(false)

  const handleSelectedItem = useCallback(
    (value, label) => {
      const nextSelectedArray = _.map(selectedArray, (item) => item.value).includes(value)
        ? _.filter(selectedArray, (n) => n.value !== value)
        : _.concat(selectedArray, [{ value, label }])
      return onSelectedArray(nextSelectedArray)
    },
    [selectedArray]
  )

  const onSelectRuleValidator = useCallback(
    (target) => {
      return (
        _.filter(_.get(selectedConfig, 'onSelectRule', []), (eachRule) => {
          const disabledkey = _.keys(eachRule)[0]
          const disableValue = _.get(eachRule, disabledkey)
          return _.get(target, disabledkey) === disableValue
        }).length > 0
      )
    },
    [_.get(selectedConfig, 'onSelectRule', [])]
  )

  const handleSelectedAll = useCallback(() => {
    const targetData = _.filter(
      data,
      (eachItem) =>
        onSelectRuleValidator(eachItem) &&
        _.filter(selectedArray, (item) => item.value === eachItem.uuid).length === 0
    )
    const targetItems = _.map(targetData, (eachItem) => {
      return {
        value: eachItem.uuid,
        label:
          _.get(eachItem, 'selectedLabel') ||
          _.get(eachItem, 'title') ||
          _.get(eachItem, 'label') ||
          ''
      }
    })
    const nextSelectedArray = _.concat(targetItems, selectedArray)
    return onSelectedArray(nextSelectedArray)
  }, [selectedArray, data])

  const handleChosenModeOpen = useCallback(
    async (state) => {
      props.onOpenSelection && (await props.onOpenSelection(state))
      onOpenChosenMode((prevState) => !prevState)
    },
    [isOpenChosenMode, props.onOpenSelection]
  )

  const handleSortableModeOpen = useCallback(
    async (state) => {
      props.onOpenSortable && (await props.onOpenSortable(state))
      onOpenSortableMode((prevState) => !prevState)
    },
    [isOpenSortableMode, props.onOpenSortable]
  )

  const handleCancelChosenMode = useCallback(() => {
    handleChosenModeOpen(false)
    onSelectedArray([])
  }, [selectedArray, onSelectedArray, isOpenChosenMode, props.onOpenSelection])

  const handleSubmitSelectedItems = useCallback(
    (onSelectAction) => {
      handleChosenModeOpen(false)
      onSelectAction(selectedArray)
      onSelectedArray([])
    },
    [selectedArray, onSelectedArray, isOpenChosenMode, props.onOpenSelection]
  )

  const handleCancelSortableMode = useCallback(async () => {
    props.onOpenSortable && (await props.onOpenSortable(false))
    onOpenSortableMode(false)
    setSortableList([])
  }, [isOpenSortableMode, onOpenSortableMode, sortableList, setSortableList, props.onOpenSortable])

  const handleSubmitSortableItems = useCallback(async () => {
    typeof props.sortableItemHandler === 'function' &&
      (await props.sortableItemHandler(sortableList))
    props.onOpenSortable && (await props.onOpenSortable(false))
    onOpenSortableMode(false)
    setSortableList([])
  }, [
    isOpenSortableMode,
    onOpenSortableMode,
    sortableList,
    setSortableList,
    props.sortableItemHandler,
    props.onOpenSortable
  ])

  const handleSortIndexChange = useCallback(
    (targetSortIndex, targetUuid) => {
      const isExisting = !_.isEmpty(
        _.find(sortableList, (eachItem) => eachItem.uuid === targetUuid)
      )
      const nextSortableList = isExisting
        ? _.map(sortableList, (sortableItem) => {
            const { uuid, sortIndex: originSortIndex } = sortableItem
            const isTargetItem = sortableItem.uuid === targetUuid
            return {
              uuid,
              sortIndex: isTargetItem ? targetSortIndex : originSortIndex
            }
          })
        : _.concat(sortableList, [{ uuid: targetUuid, sortIndex: targetSortIndex }])
      return setSortableList(nextSortableList)
    },
    [sortableList, setSortableList]
  )

  const changePage = useCallback(
    (delta) => {
      const nextPage = Math.min(Math.max(currentPage + delta, 1), totalPage)
      if (!_.isEqual(currentPage, nextPage) && typeof onPageChange === 'function') {
        onPageChange(nextPage)
      }
    },
    [currentPage, totalPage, onPageChange]
  )

  const nextPage = useCallback(() => changePage(1), [changePage])
  const prevPage = useCallback(() => changePage(-1), [changePage])

  const handleQueryChange = useCallback(
    (sort) => {
      router.replace({ pathname: router.pathname, query: { ...router.query, sort } })
    },
    [router]
  )

  const renderRow = useCallback(
    function renderRow({ item, index, seperators }) {
      const itemUuid = _.get(item, 'uuid') || _.get(item, 'productUuid')
      const itemLabel =
        _.get(item, 'selectedLabel') || _.get(item, 'title') || _.get(item, 'label') || ''
      const sortIndex = _.get(
        _.find(sortableList, (eachItem) => eachItem.uuid === itemUuid),
        'sortIndex',
        _.get(item, 'channelSort')
      )
      const style = isMobile
        ? [{ flex: 1, paddingHorizontal: 7, paddingVertical: 14 }, mobileRowStyle]
        : [manipulator.panel(10, 15, 10, 15), { flex: 1 }, renderRowStyle]
      const selected = _.map(selectedArray, (item) => item.value).includes(itemUuid)
      const selectDisabled =
        !onSelectRuleValidator(item) || (!selected && !isMultiSelect && selectedArray?.length > 0)

      return (
        <>
          <View
            key={itemUuid}
            style={[
              { display: 'flex', flex: 1, zIndex: -1 },
              manipulator.container('row', 'flex-start', 'center')
            ]}
          >
            {isOpenChosenMode && (
              <ThemedButton
                disabled={selectDisabled}
                onPress={() => handleSelectedItem(itemUuid, itemLabel)}
                style={{ backgroundColor: 'transparent' }}
                containerStyle={{ width: 60 }}
              >
                {selectDisabled ? (
                  <View style={{ width: 18, height: 18, backgroundColor: palette.negative }} />
                ) : (
                  <MaterialIcons
                    name={selected ? 'check-box' : 'check-box-outline-blank'}
                    color={selected ? palette.primary : palette.negative}
                    size={24}
                  />
                )}
              </ThemedButton>
            )}
            {sortable && (
              <View style={{ width: 60, justifyContent: 'center', alignItems: 'center' }}>
                {isOpenSortableMode ? (
                  <ThemedTextInput
                    keyboardType='phone-pad'
                    onChangeText={(targetSortIndex) =>
                      handleSortIndexChange(_.toString(targetSortIndex), itemUuid)
                    }
                    style={{ width: 50 }}
                    inputStyle={{ textAlign: 'center' }}
                    value={sortIndex}
                  />
                ) : (
                  <Text>{sortIndex}</Text>
                )}
              </View>
            )}
            {/* // TODO: temporally remove disabled={!onPressItem || (isOpenChosenMode && !onSelectRuleValidator(item))} */}
            <HoverableOpacity
              style={style}
              onPress={() =>
                isOpenChosenMode ? handleSelectedItem(item.uuid, itemLabel) : onPressItem(item)
              }
              disabled={isOpenChosenMode && selectDisabled}
            >
              {renderItem({ item, index, seperators })}
            </HoverableOpacity>
          </View>
          {isCellRender && (
            <View
              style={[
                manipulator.border('bottom', isMobile ? '' : 'light'),
                { zIndex: index === 0 ? '-1' : 'auto' }
              ]}
            />
          )}
        </>
      )
    },
    [
      sortableList,
      isCellRender,
      _.size(sortableList),
      setSortableList,
      isOpenSortableMode,
      onOpenSortableMode,
      _.toString(currentPage),
      sortable,
      renderItem,
      selectedArray,
      onPressItem,
      _.get(selectedConfig, 'onSelectRule', []),
      handleSelectedItem,
      isOpenChosenMode,
      isMultiSelect
    ]
  )

  const renderHeader = useCallback(() => {
    const isRenderFirstRow =
      (!isMobile && !_.isEmpty(AdditionalComponent)) || selectable || sortable
    const isNilListHeader = _.isNil(ListHeaderComponent)
    return (
      <>
        <View
          style={[
            manipulator.container('row', isRenderFirstRow ? 'space-between' : 'flex-end', 'center'),
            {
              marginTop: isNilListHeader || isMobile ? 0 : 15,
              marginBottom: isNilListHeader ? 0 : 10
            }
          ]}
        >
          {isRenderFirstRow && (
            <View
              style={[
                manipulator.container('row', 'flex-start', 'center'),
                AdditionalComponentStyle
              ]}
            >
              {!isMobile && !_.isEmpty(AdditionalComponent) && (
                <View style={{ marginRight: 10 }}>{AdditionalComponent}</View>
              )}
              {selectable && (
                <SelectableComponent
                  style={{ backgroundColor: 'transparent' }}
                  onPress={() => (isOpenChosenMode ? '' : handleChosenModeOpen(true))}
                >
                  <MaterialIcons
                    name='settings'
                    color={isOpenChosenMode ? palette.black : palette.primary}
                    size={16}
                  />
                  <Text
                    style={{
                      color: isOpenChosenMode ? palette.black : palette.primary,
                      marginLeft: 4
                    }}
                  >
                    {isOpenChosenMode
                      ? t('MEDIA.IMAGE_SELECTED_COUNT', { count: selectedArray.length })
                      : customSelectBtnName || t('MEDIA.CHOOSE_IMAGE_TITLE')}
                  </Text>
                </SelectableComponent>
              )}
              {isOpenChosenMode && selectedTips}
              {sortable && (
                <ThemedButton
                  style={{ backgroundColor: 'transparent' }}
                  onPress={() => (isOpenSortableMode ? '' : handleSortableModeOpen(true))}
                >
                  <MaterialIcons
                    name='settings'
                    color={isOpenSortableMode ? palette.black : palette.primary}
                    size={16}
                  />
                  <Text
                    style={{
                      color: isOpenSortableMode ? palette.black : palette.primary,
                      marginLeft: 4
                    }}
                  >
                    {t('FORM.BUTTON_EDIT')}
                  </Text>
                </ThemedButton>
              )}
            </View>
          )}
          <View
            style={[
              manipulator.container('row', isMobile ? 'space-between' : 'flex-end', 'center'),
              isMobile && { width: '100%' }
            ]}
          >
            {_.size(sequencedConfig) > 0 && (
              <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', zIndex: 999 }}>
                <Dropdown
                  ref={dropdownRef}
                  style={{ flex: 1 }}
                  containerStyle={{ minWidth: 100, right: 0 }}
                  menuStyle={{ maxHeight: 400 }}
                  label={
                    <View
                      style={{
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                        alignItems: 'center',
                        paddingRight: isMobile ? 0 : 10
                      }}
                    >
                      <MaterialIcons name='swap-vert' size={20} color={palette.primary} />
                      <Text style={{ color: palette.primary }}>{`${t('FORM.BUTTON_SORT')} (${
                        _.filter(
                          sequencedConfig,
                          (eachType) =>
                            _.get(router, 'query.sort', _.get(sequencedConfig, '0.value')) ===
                            eachType.value
                        )[0].label
                      })`}</Text>
                    </View>
                  }
                >
                  {sequencedConfig.map((each, index) => {
                    return (
                      <ThemedButton
                        key={each.value}
                        onPress={() => {
                          handleQueryChange(each.value)
                          dropdownRef.current.close()
                        }}
                        style={{ backgroundColor: 'transparent', justifyContent: 'flex-start' }}
                      >
                        <Text
                          style={{
                            color:
                              _.get(router, 'query.sort', _.get(sequencedConfig, '0.value')) ===
                              each.value
                                ? palette.primary
                                : palette.black
                          }}
                        >
                          {each.label}
                        </Text>
                      </ThemedButton>
                    )
                  })}
                </Dropdown>
              </View>
            )}
            {showTotalCount && !_.isNil(totalCount) && (
              <Text>{t('FORM.TEXT_TOTAL', { total: totalCount })}</Text>
            )}
          </View>
        </View>
        {showListHeaderDivider ? (
          isNilListHeader ? (
            <View style={[manipulator.border('bottom', isMobile ? '' : 'light')]} />
          ) : (
            <View
              style={[{ display: 'flex' }, manipulator.container('row', 'flex-start', 'flex-end')]}
            >
              {isOpenChosenMode && (
                <View style={{ height: 40, backgroundColor: palette.menuBackground, width: 60 }} />
              )}
              {sortable && (
                <View
                  style={{
                    height: 40,
                    backgroundColor: palette.menuBackground,
                    width: 60,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Text>{t('FORM.BUTTON_SORT')}</Text>
                </View>
              )}
              <View
                style={[
                  isMobile
                    ? { flex: 1, paddingHorizontal: 7, paddingVertical: 10 }
                    : manipulator.panel(10, 15, 10, 15),
                  { flex: 1, backgroundColor: palette.panelBackground },
                  ListHeaderComponentStyle
                ]}
              >
                <ListHeaderComponent />
              </View>
            </View>
          )
        ) : null}
        {isOpenChosenMode && isMultiSelect && (
          <View style={{ paddingLeft: 10 }}>
            <ThemedButton
              style={{ backgroundColor: 'transparent', justifyContent: 'flex-start' }}
              containerStyle={{ height: 40 }}
              onPress={handleSelectedAll}
            >
              <Text style={{ color: palette.primary }}>{t('COMMON.SELECT_ALL')}</Text>
            </ThemedButton>
            {isOpenChosenMode && <View style={{ height: 40, flex: 1 }} />}
          </View>
        )}
      </>
    )
  }, [
    router.query,
    sortable,
    selectedArray,
    ListHeaderComponent,
    isMobile,
    isOpenChosenMode,
    isOpenSortableMode,
    totalCount,
    AdditionalComponent,
    AdditionalComponentStyle,
    isMultiSelect
  ])

  const renderFlatListContent = useMemo(() => {
    return (
      <FlatList
        numColumns={numColumns}
        data={data}
        {...(isCellRender ? { CellRendererComponent: renderRow } : { renderItem: renderRow })}
        removeClippedSubviews={false}
        ListHeaderComponent={renderHeader}
        ListEmptyComponent={() => {
          return restProps.refreshing ? (
            <View style={{ paddingVertical: 40 }}>
              <ActivityIndicator />
            </View>
          ) : (
            <View
              style={[
                { justifyContent: 'center', alignItems: 'center', padding: 20 },
                emptyMessageViewStyle
              ]}
            >
              {emptyMessage || <Text>{t('COMMON.NONE')}</Text>}
            </View>
          )
        }}
        {...restProps}
      />
    )
  }, [numColumns, data, renderRow, renderHeader, restProps, emptyMessage])

  return (
    <View>
      {ListWrapperComponent ? (
        <ListWrapperComponent>{renderFlatListContent}</ListWrapperComponent>
      ) : (
        renderFlatListContent
      )}
      {showPagination && (
        <View style={manipulator.container('row', `${isMobile ? 'center' : 'flex-end'}`, 'center')}>
          <ThemedButton onPress={prevPage} style={{ backgroundColor: 'transparent' }}>
            <MaterialIcons name='chevron-left' size={20} />
          </ThemedButton>
          <Text style={{ fontSize: isMobile ? 16 : 14 }}>
            {t('COMMON.PAGE', { currentPage, totalPage })}
          </Text>
          <ThemedButton onPress={nextPage} style={{ backgroundColor: 'transparent' }}>
            <MaterialIcons name='chevron-right' size={20} />
          </ThemedButton>
        </View>
      )}
      {isOpenChosenMode && (
        <PageFooter style={manipulator.container('row', 'space-between', 'center')}>
          <ThemedButton
            style={{ minWidth: 120, backgroundColor: palette.negative }}
            onPress={handleCancelChosenMode}
          >
            <Text>{t('FORM.BUTTON_CANCEL')}</Text>
          </ThemedButton>
          <View style={manipulator.container('row', 'flex-end', 'center')}>
            {_.map(selectedConfig.buttons, (item, index) => {
              const isLast = index + 1 === selectedConfig.buttons.length
              return (
                <ThemedButton
                  key={index}
                  containerStyle={{ marginRight: isLast ? 0 : 15 }}
                  style={{ minWidth: 120, backgroundColor: item.bg }}
                  onPress={() => handleSubmitSelectedItems(item.onSelectAction)}
                >
                  <Text style={{ color: palette.white }}>{item.txt}</Text>
                </ThemedButton>
              )
            })}
          </View>
        </PageFooter>
      )}
      {isOpenSortableMode && (
        <PageFooter style={manipulator.container('row', 'space-between', 'center')}>
          <ThemedButton
            style={{ minWidth: 120, backgroundColor: palette.negative }}
            onPress={handleCancelSortableMode}
          >
            <Text>{t('FORM.BUTTON_CANCEL')}</Text>
          </ThemedButton>
          <ThemedButton
            style={{ minWidth: 120, backgroundColor: palette.positive }}
            onPress={handleSubmitSortableItems}
          >
            <Text style={{ color: palette.white }}>{t('FORM.BUTTON_CONFIRM')}</Text>
          </ThemedButton>
        </PageFooter>
      )}
    </View>
  )
}

PagedFlatList.defaultProps = {
  keyExtractor: (item, index) => index.toString(),
  ItemSeparatorComponent: Separator
}

function FilterWrapper(props) {
  const { isMobile } = useLayout()
  const { t } = useStores()
  const { isShowFilterIcon, filterWrapper, onOpen, isPopup } = props

  return (
    <View
      style={
        isShowFilterIcon && !isMobile ? manipulator.container('row', 'space-between', 'center') : {}
      }
    >
      <View>{filterWrapper(props, isPopup)}</View>
      {isShowFilterIcon && (
        <View
          style={
            isMobile
              ? { marginBottom: 17 }
              : { alignSelf: 'flex-end', marginLeft: 15, marginBottom: 26 }
          }
        >
          <ThemedButton
            style={[{ height: isMobile ? 32 : 38 }, isMobile ? { maxWidth: 85 } : { width: 85 }]}
            onPress={onOpen}
          >
            <MaterialIcons name='tune' size={18} color={palette.white} />
            <Text style={{ color: palette.white, marginLeft: 5 }}>{t('COMMON.FILTER_TITLE')}</Text>
          </ThemedButton>
        </View>
      )}
    </View>
  )
}

function GridFilterRow(props) {
  const { isMobile } = useLayout()
  return (
    <View
      style={[
        {
          paddingTop: isMobile ? 5 : 0,
          paddingBottom: isMobile ? 5 : 10,
          flexDirection: 'row',
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexWrap: 'wrap'
        },
        props.gridContainerStyle,
        props.gridContainerStyleIndivisual && props.gridContainerStyleIndivisual[props.index]
      ]}
    >
      {props.grid
        ? props.grid.map((flex, index) => {
            return (
              <View
                key={index}
                style={{
                  flexDirection: 'row',
                  marginRight: props.grid.length - 1 === index ? 0 : 10,
                  flex
                }}
              >
                {_.get(props.children, index)}
              </View>
            )
          })
        : props.children}
      {props.postRow}
    </View>
  )
}

function generateFilterType(targetType) {
  return [
    'text',
    'productSelector',
    'ticketSelector',
    'dateRangePicker',
    'searchSelector',
    'resourceSelector',
    'deviceSelector',
    'deviceOperationSelector',
    'couponSelector'
  ].includes(targetType)
    ? targetType === 'text'
      ? 'text'
      : 'in'
    : 'equal'
}

function arrayTypeFilters(filters) {
  const defaultArrayType = [
    ...dateRangeTypes,
    ...['productUuid', 'resourceUuid', 'ticketUuid', 'deviceUuids', 'deviceIds', 'couponUuids']
  ]
  const customArrayType = _.map(
    _.filter(
      filters,
      (filter) =>
        (filter.type === 'searchSelector' && _.get(filter, 'single', false) === false) ||
        (filter.type === 'resourceSelector' && filter.isMultiSelect === true)
    ),
    (item) => item.path
  )
  return _.concat(defaultArrayType, customArrayType)
}

export function decodeFilterArray(value, targetFilters) {
  const filters = _.compact(
    _.map(value, (content, key) =>
      _.isUndefined(content) ||
      _.isNil(content) ||
      (typeof content === 'object' && Object.keys(content).length === 0)
        ? undefined
        : arrayTypeFilters(targetFilters).includes(key)
        ? `${key}:${content.join('|')}`
        : `${key}:${content}`
    )
  )
  return filters.join(',')
}

function encodeFilterArray(value, targetFilters) {
  const filters = _(value)
    .map((value, path) => {
      if (_.isEmpty(value)) {
        return null
      }
      const filter = targetFilters.find((f) => f.path === path)
      return filter
        ? {
            type: generateFilterType(filter.type),
            path,
            value
          }
        : null
    })
    .compact()
    .value()
  return filters
}

function generateRowItemContent(rowItem) {
  const {
    path,
    formState,
    type,
    desc,
    iconName,
    keeponpage,
    location,
    column,
    single = false,
    afterCreate,
    isStatic = true,
    ...rest
  } = rowItem
  const options = Object.assign({}, rest.options, {
    display: { containerStyle: { marginBottom: 0, flex: 1 }, ..._.get(rest.options, 'display', {}) }
  })
  const result = {
    options,
    key: path,
    formState,
    isStatic
  }

  switch (type) {
    case 'text':
      return {
        ...result,
        options: {
          updateOn: 'blur',
          ...result.options
        },
        type: 'TextInput',
        afterCreate,
        meta: {
          style: { maxWidth: undefined },
          placeholder: desc,
          iconName,
          ...rest
        }
      }
    case 'label':
      return {
        ...result,
        type: 'Text',
        afterCreate,
        meta: {
          ...rest.meta,
          label: rest.meta.txt
        }
      }
    case 'valueLabel':
      return {
        ...result,
        type: 'ValueText',
        afterCreate,
        meta: {
          ...rest.meta
        }
      }
    case 'labelSelector':
      return {
        ...result,
        type: 'LabelSelector',
        formState: _.isEmpty(formState)
          ? _.get(rowItem, 'defaultValue', rowItem.items[0].value)
          : formState,
        meta: {
          style: { flex: 1, width: undefined },
          ...rest
        }
      }
    case 'productSelector':
      return {
        ...result,
        type: 'ProductSelector',
        afterCreate,
        meta: {
          ...rest
        }
      }
    case 'couponSelector':
      return {
        ...result,
        type: 'CouponSelector',
        afterCreate,
        meta: {
          ...rest
        }
      }
    case 'deviceSelector':
      return {
        ...result,
        type: 'DeviceSelector',
        afterCreate,
        meta: {
          ...rest
        }
      }
    case 'deviceOperationSelector':
      return {
        ...result,
        type: 'DeviceOperationSelector',
        afterCreate,
        meta: {
          ...rest
        }
      }
    case 'checkbox':
      return {
        ...result,
        type: 'Checkbox',
        meta: {
          label: desc,
          ...rest
        }
      }
    case 'picker':
      return {
        ...result,
        type: 'Picker',
        afterCreate,
        meta: {
          placeholder: {
            label: desc,
            value: undefined
          },
          ...rest
        }
      }
    case 'datePicker':
      return {
        ...result,
        type: 'DatePicker',
        formState: _.isEmpty(formState) ? undefined : formState,
        meta: {
          ...rest.meta
        }
      }
    case 'dateRangePicker':
      return {
        ...result,
        type: 'DateRangePicker',
        afterCreate,
        meta: {
          ...rest.meta
        }
      }
    case 'searchSelector':
      return {
        ...result,
        type: 'SearchSelector',
        isStatic,
        meta: {
          single,
          ...rest
        }
      }
    case 'timeSelector':
      return {
        ...result,
        type: 'TimeSelector',
        meta: {
          hasInitValue: false,
          ...rest
        }
      }
    case 'resourceSelector':
      return {
        ...result,
        type: 'ResourceSelector',
        meta: {
          ...rest
        }
      }
    case 'ticketSelector':
      return {
        ...result,
        type: 'TicketSelector',
        afterCreate,
        meta: {
          ...rest
        }
      }
    default:
      return null
  }
}

function generateNextRemoveFilters(stateFilters, propsFilters, targetFilter, targetValue) {
  let newFilters
  const filteredArrayType = _.filter(
    arrayTypeFilters(propsFilters),
    (propsFilter) => !dateRangeTypes.includes(propsFilter)
  )
  const isInFilteredArrayType = filteredArrayType.includes(targetFilter.path)

  if (isInFilteredArrayType) {
    const targetData = _.filter(stateFilters, (each) => each.path === targetFilter.path)
    const otherFilters = _.filter(stateFilters, (each) => each.path !== targetFilter.path)
    const { value, ...rest } = _.get(targetData, '0')
    newFilters = _.concat(
      otherFilters,
      value.length > 1 ? [{ value: _.remove(value, (n) => n !== targetValue), ...rest }] : []
    )
  } else {
    newFilters = _.filter(stateFilters, (each) => each.path !== targetFilter.path)
  }

  // remove related salesOptionUuid filters
  if (targetFilter.path === 'productUuid') {
    newFilters = _.filter(newFilters, (each) => each.path !== 'salesOptionUuid')
  }

  return newFilters
}

function useFilterState(filters) {
  const initFilterContent = {}
  Array.isArray(filters) &&
    filters.filter(prop('forceInit')).forEach((filter) => {
      Object.assign(initFilterContent, { [filter.path]: filter.defaultValue })
    })
  const router = useRouter()
  const hasFilters = !_.isEmpty(_.get(router, 'query.filters'))
  hasFilters &&
    _.forEach(_.get(router, 'query.filters').split(','), (each) => {
      const item = each.split(':')
      const value = arrayTypeFilters(filters).includes(item[0]) ? item[1].split('|') : item[1]
      Object.assign(initFilterContent, _.set({}, item[0], value))
    })
  const initFilters = hasFilters ? encodeFilterArray(initFilterContent, filters) : []
  const state = Object.assign(
    {},
    { filterContent: initFilterContent, filters: initFilters },
    hasFilters ? { currentPage: 1 } : {}
  )
  const handleFilterChange = useCallback(
    (filters) => {
      router.replace({ pathname: router.pathname, query: { ...router.query, filters } })
    },
    [router]
  )

  return [state, handleFilterChange]
}

const TagComponent = ({ id, handleOnPress, textContent, disabled }) => (
  <HoverableOpacity
    style={{ maxWidth: '100%' }}
    key={id}
    onPress={handleOnPress}
    disabled={disabled}
  >
    <View
      style={[
        {
          marginVertical: 3,
          marginRight: 5,
          paddingHorizontal: 10,
          paddingVertical: 5,
          borderRadius: 20,
          backgroundColor: palette.panelBackground
        },
        manipulator.container('row', 'center', 'center')
      ]}
    >
      {!disabled && <MaterialIcons name='close' size={12} color={palette.icon} />}
      <Text style={{ color: palette.icon, fontSize: 12, marginLeft: 4 }}>{textContent}</Text>
    </View>
  </HoverableOpacity>
)

export const FilterTagComponent = observer(function FilterTagComponent(props) {
  const { t, store } = useStores()
  const { CouponCode } = useKeystoneStores()
  const { stateFilters, propsFilters, removeFilterHandler } = props
  const activityIndicatorElement = useMemo(
    () => (
      <View
        style={[
          {
            marginVertical: 3,
            marginRight: 5,
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 20,
            backgroundColor: palette.panelBackground
          },
          manipulator.container('row', 'center', 'center')
        ]}
      >
        <ActivityIndicator />
      </View>
    ),
    []
  )
  return _.map(
    stateFilters.filter(complement(propEq('path', 'fromSearchButton'))),
    (filter, index) => {
      let content
      const filterConfig = _.find(propsFilters, (rawFilter) => rawFilter.path === filter.path)
      if (
        filter.type === 'in' &&
        [
          'productSelector',
          'resourceSelector',
          'ticketSelector',
          'deviceSelector',
          'deviceOperationSelector',
          'couponSelector'
        ].includes(filterConfig.type)
      ) {
        content = _.map(filter.value, (p) => {
          return (
            <Observer key={`${filter.path}-${p}`}>
              {() => {
                let item
                let textContent
                switch (filter.path) {
                  case 'ticketUuid':
                    item = store.ticketStore.tickets.get(p)
                    textContent = item?.label || item?.title || item?.accountName
                    break
                  case 'resourceUuids':
                    item = store.resourceStore.resources.get(p)
                    textContent = item?.label || item?.title || item?.accountName
                    break
                  case 'deviceUuids':
                    item = store.deviceStore.devices.get(p)
                    textContent = item?.label || item?.title || item?.accountName
                    break
                  case 'deviceIds':
                    item = [...store.deviceStore.devices.values()].find(
                      (item) => item.deviceId === p
                    )
                    textContent = item?.label || item?.title || item?.accountName
                    break
                  case 'couponUuids':
                    item = [...CouponCode.couponList.values()].find((item) => item.uuid === p)
                    textContent = `[${item?.code}] ${item?.label}`
                    break
                  case 'productUuid':
                  default:
                    item = store.productStore.products.get(p)
                    textContent = `[${item?.productCode}] ${item?.title}`
                    break
                }

                return item ? (
                  <TagComponent
                    id={item.uuid}
                    handleOnPress={() =>
                      removeFilterHandler(
                        filter,
                        filter.path !== 'deviceIds' ? item.uuid : item.deviceId
                      )
                    }
                    textContent={textContent}
                  />
                ) : (
                  activityIndicatorElement
                )
              }}
            </Observer>
          )
        })
      } else if (
        filter.type === 'in' &&
        _.get(filterConfig, 'single', false) === false &&
        !dateRangeTypes.includes(filter.path)
      ) {
        content = _.map(filter.value, (eachValue) => {
          let label = ''
          _.forEach(filterConfig.items, (item) => {
            const selectedItem =
              item.uuid === eachValue ||
              _.toString(item.value) === eachValue ||
              item.label === eachValue
            const selectedSubitem = _.find(item.group, ['value', eachValue])
            if (selectedItem || selectedSubitem) {
              label = selectedSubitem?.label || item.label
            }
          })
          return (
            <Observer key={`${filter.path}-${eachValue}`}>
              {() => {
                return filter.path === 'salesOptionUuid' && !filterConfig.items.length ? (
                  activityIndicatorElement
                ) : (
                  <TagComponent
                    id={`${filter.path}-${eachValue}-${label}`}
                    handleOnPress={() => removeFilterHandler(filter, eachValue)}
                    textContent={`${
                      filter.path === 'currencyUuid' ? formatSymbol(eachValue, 'title') : label
                    }`}
                  />
                )
              }}
            </Observer>
          )
        })
      } else {
        let txt
        switch (filter.path) {
          case 'from':
          case 'to':
            txt = t(`ORDER.FILTER_DATE_${filter.path.toUpperCase()}_TAG`, { date: filter.value })
            break
          case dateRangeTypes[0]:
          case dateRangeTypes[1]:
          case dateRangeTypes[2]:
          case dateRangeTypes[3]:
          case dateRangeTypes[4]:
          case dateRangeTypes[5]:
          case dateRangeTypes[6]:
            txt = t(`COMMON.FORM_${filter.path.toUpperCase()}`, {
              startDate: _.get(filter, 'value.0'),
              endDate: _.get(filter, 'value.1')
            })
            break
          default:
            txt = _.isEmpty(_.get(filterConfig, 'items'))
              ? _.get(
                  _.filter(stateFilters, (item) => item.path === _.get(filterConfig, 'path')),
                  '0.value'
                )
              : _.get(
                  _.get(filterConfig, 'items').filter((i) => i.value === filter.value),
                  '0.label'
                )
            break
        }
        content = (
          <TagComponent
            key={`${filter.path}-${index}`}
            id={`${filter.path}-${index}`}
            handleOnPress={() => removeFilterHandler(filter)}
            textContent={txt}
            disabled={props.propsFilters.find(propEq('path', filter.path)).noTabCancel}
          />
        )
      }
      return content
    }
  )
})

export const GridFilter = forwardRef((props, ref) => {
  const {
    AdditionalComponent,
    AdditionalComponentStyle,
    source,
    showFilterTags,
    showMoreFilterCount,
    hiddenFiltersRule,
    filterFormContainerStyle,
    filterHeaderComponent,
    filterFooterComponent,
    gridContainerStyle,
    gridContainerStyleIndivisual,
    expandable,
    showMoreContainerStyle,
    directChange,
    isStrict,
    onDirectChange,
    onExpandable,
    customReminder,
    alwaysShowSearchButton,
    requiredRule = []
  } = props
  const pageWidth = usePageWidth()
  const { isMobile } = useLayout()
  const { t } = useStores()
  const router = useRouter()
  const gridColumns = props.gridColumns || (isMobile ? 1 : 3)

  const [state, handleFilterChange] = useFilterState(props.filters)
  const [modalOpening, setModalOpening] = useState(false)
  const [localFilters, setLocalFilters] = useState(
    decodeFilterArray(state.filterContent, props.filters)
  )
  const [errors, setErrors] = useState({})
  const formattedStateFilters = _.map(state.filters, ({ path, value }) => `${path}:${value}`)
  const hiddenFilters = _.isEmpty(hiddenFiltersRule)
    ? false
    : _.isEmpty(_.remove(formattedStateFilters, (filter) => !_.includes(hiddenFiltersRule, filter)))
  const formattedLocalFilters = _.filter(
    _.split(localFilters, ','),
    (filter) => !_.isEmpty(_.split(filter, ':')?.[1])
  )
  const validLocalFilters = _.compact(
    _.remove(formattedLocalFilters, (item) => !_.includes(hiddenFiltersRule, item))
  )

  const isValidLocalFiltersRequired = useMemo(() => {
    const localFiltersKeyArray = localFilters.split(',').map((ele) => ele.split(':')[0])
    if (!_.isArray(requiredRule)) return true
    return requiredRule.filter((filter) => !localFiltersKeyArray.includes(filter)).length === 0
  }, [requiredRule, localFilters])

  const pageFormRef = useRef()
  const modalFormRef = useRef()

  useEffect(() => {
    pageFormRef.current?.form.statusChanges.subscribe(() => {
      setErrors({
        ...(pageFormRef.current?.errors ?? {}),
        ...(modalFormRef.current?.errors ?? {})
      })
    })
    modalFormRef.current?.form.statusChanges.subscribe(() => {
      setErrors({
        ...(pageFormRef.current?.errors ?? {}),
        ...(modalFormRef.current?.errors ?? {})
      })
    })
    return () => {
      pageFormRef.current?.form.statusChanges.unsubscribe()
      modalFormRef.current?.form.statusChanges.unsubscribe()
    }
  }, [])

  const onCloseDirectSearch = useCallback(() => {
    onDirectChange?.(false)
    onExpandable?.(true)
  }, [])

  const handleSearch = useCallback(
    ({ fromSearchButton } = {}) => {
      if (props.checkblockSearch?.()) return
      handleFilterChange(localFilters + (fromSearchButton ? ',fromSearchButton:true' : ''))
      onDirectChange?.(true)
      onExpandable?.(false)
    },
    [localFilters]
  )

  const updateFilter = useCallback(
    (value) => {
      const errors = {
        ..._.get(pageFormRef, 'current.errors', {}),
        ..._.get(modalFormRef, 'current.errors', {})
      }
      _.forEach(errors, (err) => _.set(value, err._key, undefined))
      const val = { ..._.omit(state.filterContent, 'fromSearchButton'), ...value }
      const nextFilters = decodeFilterArray(val, props.filters)
      const filtersWithoutHiddenRule = _.compact(
        _.remove(_.split(nextFilters, ','), (item) => !_.includes(hiddenFiltersRule, item))
      )
      const isOnlyTextInput =
        filtersWithoutHiddenRule.length === 1 && _.includes(filtersWithoutHiddenRule?.[0], 'text')
      isOnlyTextInput && handleSearch()
      alwaysShowSearchButton && setLocalFilters(nextFilters)
      return directChange || isOnlyTextInput
        ? handleFilterChange(nextFilters)
        : setLocalFilters(nextFilters)
    },
    [directChange, props.filters, hiddenFiltersRule, state.filterContent, handleSearch]
  )

  const resetFilters = useCallback(() => {
    pageFormRef.current && pageFormRef.current.reset()
    modalFormRef.current && modalFormRef.current.reset()
    onCloseDirectSearch()
    setLocalFilters(
      // TODO: extract to dedicated function and add test
      props.filters
        .filter(prop('forceInit'))
        .map(converge(concatStrings, [prop('path'), always(':'), prop('defaultValue')]))
        .join('#')
        .replace(',', '|') // ! temp dirty fix for array to string
        .replace('#', ',')
    )
    props?.onReset?.()
    return handleFilterChange('')
  }, [])

  const removeFilter = useCallback(
    (targetFilter, targetValue) => {
      const newFilters = generateNextRemoveFilters(
        state.filters,
        props.filters,
        targetFilter,
        targetValue
      )
      const newfilterContent = _.reduce(
        newFilters,
        (obj, item) => {
          _.set(obj, item.path, item.value)
          return obj
        },
        {}
      )
      pageFormRef.current &&
        pageFormRef.current.form.setValue(
          _.mapValues(pageFormRef.current.form.value, (value, key) => newfilterContent[key] || null)
        )
      modalFormRef.current &&
        modalFormRef.current.form.setValue(
          _.mapValues(
            modalFormRef.current.form.value,
            (value, key) => newfilterContent[key] || null
          )
        )
      newFilters.length === 0 && onCloseDirectSearch()
      return handleFilterChange(decodeFilterArray(newfilterContent, props.filters))
    },
    [props, state.filters, pageFormRef.current, modalFormRef.current]
  )

  const handleExpandable = useCallback(() => onExpandable(!expandable), [expandable])

  useEffect(() => {
    props.onChangeFilters?.(state.filters)
  }, [JSON.stringify(state.filters), _.get(router, 'query.sort')])

  useImperativeHandle(
    ref,
    () => {
      return {
        props,
        resetFilters,
        state,
        errors
      }
    },
    [resetFilters, state, errors]
  )

  const filterForm = useMemo(() => {
    const filters = _.groupBy(
      _.map(props.filters, (filter) => {
        const location = ['page', 'modal'].includes(filter.keepon)
          ? filter.keepon
          : isMobile
          ? 'modal'
          : 'page'
        return { ...filter, location }
      }),
      'location'
    )

    function divideFilters(result, currentFilter) {
      currentFilter.column = currentFilter.column || 1
      if (
        !_.last(result) ||
        _.last(result).reduce((sum, filter) => sum + filter.column, 0) + currentFilter.column >
          gridColumns
      ) {
        result.push([currentFilter])
      } else {
        _.last(result).push(currentFilter)
      }

      return result
    }

    function buildRow(row, index, list) {
      return {
        type: 'Wrapper',
        key: index,
        wrapper: GridFilterRow,
        group: false,
        meta: {
          grid: row.map((item) => item.column),
          index,
          gridColumns,
          gridContainerStyle,
          gridContainerStyleIndivisual
        },
        form: _.compact(_.map(row, (rowItem) => generateRowItemContent(rowItem)))
      }
    }

    const page = _.map(_.reduce(filters.page, divideFilters, []), buildRow)
    const modal = _.map(_.reduce(filters.modal, divideFilters, []), buildRow)

    return {
      modal,
      page
    }
  }, [JSON.stringify(props.filters), gridColumns])

  const pageFilterForm =
    filterForm.page.length > 0 ? (
      <View
        style={[
          { width: isMobile ? '100%' : pageWidth > 1200 ? '70%' : '100%' },
          filterFormContainerStyle
        ]}
      >
        <FormComponent
          ref={pageFormRef}
          value={state.filterContent}
          key={`${isMobile}-${expandable}`}
          formConfig={_.dropRight(
            filterForm.page,
            showMoreFilterCount > 0 && directChange && !expandable
              ? filterForm.page.length - showMoreFilterCount
              : 0
          )}
          onChange={updateFilter}
          isStrict={isStrict}
        />
      </View>
    ) : (
      <View />
    )

  return (
    <View style={{ width: '100%' }}>
      {directChange ? (
        <>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              width: '100%',
              flexWrap: 'wrap'
            }}
          >
            {!_.isEmpty(filterHeaderComponent) && filterHeaderComponent}
            {pageFilterForm}
            {!_.isEmpty(filterFooterComponent) && filterFooterComponent}
          </View>
          <View
            style={[
              manipulator.container('row', 'space-between', 'center'),
              { marginBottom: source !== 'formComponent' || isMobile ? 20 : 0 },
              showMoreContainerStyle
            ]}
          >
            <View>
              {filterForm.modal.length > 0 && (
                <ThemedButton
                  style={{ height: isMobile ? 32 : 38 }}
                  onPress={() => setModalOpening(true)}
                >
                  <MaterialIcons name='tune' size={18} color={palette.white} />
                  <Text style={{ color: palette.white, marginLeft: 5 }}>
                    {t('COMMON.FILTER_TITLE')}
                  </Text>
                </ThemedButton>
              )}
              {showMoreFilterCount > 0 && (
                <ThemedButton style={{ backgroundColor: 'transparent' }} onPress={handleExpandable}>
                  <MaterialIcons
                    name={`expand-${expandable ? 'less' : 'more'}`}
                    size={18}
                    color={palette.primary}
                  />
                  <Text style={{ color: palette.primary, paddingLeft: 2 }}>
                    {t('ORDER.BUTTON_FILTER_MORE')}
                  </Text>
                </ThemedButton>
              )}
            </View>
          </View>
          {!hiddenFilters &&
            ((isMobile && showFilterTags !== false) || (!isMobile && showFilterTags)) &&
            state.filters.length > 0 && (
              <View
                style={[
                  { flexWrap: 'wrap', paddingBottom: 15 },
                  manipulator.container('row', 'flex-start', 'center')
                ]}
              >
                <ThemedButton
                  style={{
                    backgroundColor: 'transparent',
                    marginVertical: 0,
                    padding: 0,
                    marginLeft: 0,
                    marginRight: 10
                  }}
                  onPress={resetFilters}
                >
                  <MaterialIcons name='clear' size={14} color={palette.brand} />
                  <Text style={{ color: palette.brand, marginLeft: 2 }}>
                    {t('FORM.REMOVE_ALL_FILTERS')}
                  </Text>
                </ThemedButton>
                <FilterTagComponent
                  stateFilters={state.filters}
                  propsFilters={props.filters}
                  removeFilterHandler={removeFilter}
                />
              </View>
            )}
          {(source !== 'formComponent' || isMobile) && !_.isEmpty(AdditionalComponent) && (
            <View style={AdditionalComponentStyle}>{AdditionalComponent}</View>
          )}
          {alwaysShowSearchButton && (
            <View style={manipulator.container('row', 'center', 'center')}>
              <ThemedButton
                style={{ width: 100 }}
                disabled={_.isEmpty(validLocalFilters) || !isValidLocalFiltersRequired}
                onPress={() => handleSearch({ fromSearchButton: true })}
              >
                {t('COMMON.SEARCH_PLACEHOLDER')}
              </ThemedButton>
            </View>
          )}
        </>
      ) : (
        <>
          {pageFilterForm}
          <View style={[isMobile ? { marginTop: 10 } : { alignItems: 'center' }]}>
            {!isMobile && (
              <Text style={{ paddingTop: 20, paddingBottom: 15 }}>
                {customReminder || t('REPORT.FILTER_REMINDER')}
              </Text>
            )}
            <View style={[manipulator.container('row', 'center')]}>
              {props.showResetButton && localFilters.length > 0 && (
                <ThemedButton
                  style={{
                    backgroundColor: 'transparent',
                    borderColor: palette.primary,
                    borderWidth: 1
                  }}
                  containerStyle={{ flex: 1, width: isMobile ? '100%' : 100, marginRight: 10 }}
                  onPress={resetFilters}
                >
                  <Text style={{ color: palette.primary }}>{t('COMMON.RESET_FILTER')}</Text>
                </ThemedButton>
              )}
              <ThemedButton
                containerStyle={{ flex: 1, width: isMobile ? '100%' : 100 }}
                disabled={_.isEmpty(validLocalFilters) || !isValidLocalFiltersRequired}
                onPress={() => handleSearch({ fromSearchButton: true })}
              >
                {t('COMMON.SEARCH_PLACEHOLDER')}
              </ThemedButton>
            </View>
          </View>
        </>
      )}
      {filterForm.modal.length > 0 && (
        <PageWidthModal
          isVisible={modalOpening}
          style={props?.pageWidthModalStyle}
          pageWidthContainerStyle={props?.pageWidthContainerStyle}
        >
          <View
            style={[
              template.rowContainer,
              { marginBottom: 10, borderBottomWidth: 1, borderBottomColor: palette.border }
            ]}
          >
            <ThemedButton
              style={{ backgroundColor: 'transparent', width: 40 }}
              onPress={() => setModalOpening(false)}
            >
              <MaterialIcons name='close' size={20} color={palette.primary} />
            </ThemedButton>
            <Text style={{ color: palette.primary, fontWeight: 'bold', fontSize: 18 }}>
              {t('COMMON.FILTER_TITLE')}
            </Text>
          </View>
          <DropdownBaseView style={{ flex: 1 }}>
            <FormComponent
              ref={modalFormRef}
              value={state.filterContent}
              key={`modal-${isMobile ? 'mobile' : 'pc'}`}
              formConfig={filterForm.modal}
              onChange={updateFilter}
            />
          </DropdownBaseView>
          <PageStepControl
            buttons={[
              {
                type: 'negative',
                text: t('ORDER.FILTER_CLEAR_BUTTON'),
                onPress: resetFilters
              },
              {
                type: 'positive',
                text: t('REPORT.BUTTON_APPLY'),
                disabled: !_.isEmpty({
                  ..._.get(pageFormRef, 'current.errors', {}),
                  ..._.get(modalFormRef, 'current.errors', {})
                }),
                onPress: () => setModalOpening(false)
              }
            ]}
          />
        </PageWidthModal>
      )}
    </View>
  )
})

export class LegacyFilter extends Component {
  static defaultProps = {
    filters: [],
    updateFilterOn: 'change'
  }

  state = {
    openPopup: false,
    filters: [],
    filterContent: {}
  }

  filterFormRef = React.createRef()
  filters = React.createRef()

  handleResetFilters = () => {
    return this.setState(
      {
        filters: [],
        filterContent: {},
        currentPage: 1
      },
      () =>
        this.props.onChangeFilters && this.props.onChangeFilters([]) && this.filters.current.reset()
    )
  }

  filterWrapper = (filters, isPopup) => {
    const { isMobile } = this.props
    const children = isPopup
      ? filters.children.filter((each) =>
          isMobile
            ? _.isUndefined(_.get(each, 'props.children.props.meta.keeponpage')) &&
              _.get(each, 'props.children.key') !== 'search-button'
            : _.get(each, 'props.children.props.meta.keeponpopup') === 'true'
        )
      : filters.children.filter((each) =>
          isMobile
            ? _.get(each, 'props.children.props.meta.keeponpage') === 'true'
            : _.isUndefined(_.get(each, 'props.children.props.meta.keeponpopup'))
        )
    return children.length > 0 ? (
      <View
        style={{
          paddingTop: isMobile ? 5 : 0,
          paddingBottom: isMobile ? 16 : 10,
          paddingRight: 5,
          flexDirection: isMobile ? 'column' : 'row',
          width: isMobile ? 'auto' : '50%',
          alignItems: isMobile ? 'flex-start' : 'center'
        }}
      >
        {children}
      </View>
    ) : (
      <View />
    )
  }

  getFilterConfig = memoize(
    (
      rawFilters = [],
      updateFilterOn,
      isMobile,
      isPopup = false,
      pcCol = { page: 3, modal: 3 },
      keepFilterIcon
    ) => {
      const children = isPopup
        ? rawFilters.filter((each) =>
            isMobile
              ? _.isUndefined(_.get(each, 'keeponpage'))
              : _.get(each, 'keeponpopup') === 'true'
          )
        : rawFilters.filter((each) =>
            isMobile
              ? _.get(each, 'keeponpage') === 'true'
              : _.isUndefined(_.get(each, 'keeponpopup'))
          )
      return _.chunk(children, isMobile ? 1 : isPopup ? pcCol.modal : pcCol.page).map(
        (chunk, index, array) => {
          const isLastRow = array.length - 1 === index
          return {
            type: 'Wrapper',
            key: index,
            wrapper: FilterWrapper,
            meta: {
              isShowFilterIcon: (isMobile || keepFilterIcon) && isLastRow && !isPopup,
              filterWrapper: this.filterWrapper,
              isPopup,
              onOpen: () => this.setState({ openPopup: true })
            },
            group: false,
            form: _.compact([...chunk.map((rowItem) => generateRowItemContent(rowItem))])
          }
        }
      )
    }
  )

  handleUpdateFilter = (filterContent) => {
    const { errors } = this.filterFormRef.current?.form

    filterContent = {
      ...this.state.filterContent,
      ...filterContent
    }

    _.forEach(errors, (err) => _.set(filterContent, err._key, undefined))

    const filters = _(filterContent)
      .map((value, path) => {
        if (_.isEmpty(value)) {
          return null
        }
        const filter = this.props.filters.find((f) => f.path === path)
        return {
          type: generateFilterType(filter.type),
          path,
          value
        }
      })
      .compact()
      .value()

    this.setState({
      filterContent,
      filters,
      currentPage: 1
    })

    this.props.onChangeFilters && this.props.onChangeFilters(filters)
  }

  removeFilter = (targetFilter, targetValue) => {
    const newFilters = generateNextRemoveFilters(
      this.state.filters,
      this.props.filters,
      targetFilter,
      targetValue
    )
    return this.setState(
      {
        filters: newFilters,
        filterContent: _.reduce(
          newFilters,
          (obj, item) => {
            _.set(obj, item.path, item.value)
            return obj
          },
          {}
        ),
        currentPage: 1
      },
      () => this.props.onChangeFilters && this.props.onChangeFilters(newFilters)
    )
  }

  render() {
    const {
      t,
      filters,
      isMobile,
      pageWidth,
      keepFilterIcon = false,
      AdditionalComponentStyle = {},
      AdditionalComponent,
      showFilterTags,
      isShowFilterPopupContent,
      onFilterRuleComponent,
      updateFilterOn,
      filterFormCol,
      hiddenFiltersRule = []
    } = this.props
    const showPopup = true
    const { openPopup } = this.state
    const formattedStateFilters = _.map(this.state.filters, ({ path, value }) => `${path}:${value}`)
    const hiddenFilters = _.isEmpty(hiddenFiltersRule)
      ? false
      : _.isEmpty(
          _.remove(formattedStateFilters, (filter) => !_.includes(hiddenFiltersRule, filter))
        )
    return (
      <View style={{ width: '100%' }}>
        <View style={[{ paddingBottom: 4 }, isMobile || !keepFilterIcon ? {} : { width: '100%' }]}>
          <FormComponent
            ref={this.filterFormRef}
            value={this.state.filterContent}
            key={`${this.state.filters}-${isMobile ? 'mobile' : 'pc'}`}
            formConfig={this.getFilterConfig(
              filters,
              updateFilterOn,
              isMobile,
              !showPopup,
              filterFormCol,
              keepFilterIcon
            )}
            onChange={this.handleUpdateFilter}
          />
          {isMobile && !_.isEmpty(AdditionalComponent) && (
            <View style={AdditionalComponentStyle}>{AdditionalComponent}</View>
          )}
          {(isMobile || showFilterTags) && this.state.filters.length > 0 && !hiddenFilters && (
            <View
              style={[
                { paddingBottom: 10, flexWrap: 'wrap', width: pageWidth + 20 },
                manipulator.container('row', 'flex-start', 'center')
              ]}
            >
              {!isMobile && (
                <ThemedButton
                  style={{
                    backgroundColor: 'transparent',
                    marginVertical: 0,
                    padding: 0,
                    marginLeft: 0,
                    marginRight: 10
                  }}
                  onPress={this.handleResetFilters}
                >
                  <MaterialIcons name='clear' size={14} color={palette.brand} />
                  <Text style={{ color: palette.brand, marginLeft: 2 }}>
                    {t('FORM.REMOVE_ALL_FILTERS')}
                  </Text>
                </ThemedButton>
              )}
              <FilterTagComponent
                stateFilters={this.state.filters}
                propsFilters={filters}
                removeFilterHandler={this.removeFilter}
              />
            </View>
          )}
        </View>
        <PageWidthModal
          isVisible={openPopup}
          containerStyle={isMobile ? { justifyContent: 'flex-end' } : {}}
          pageWidthContainerStyle={[
            {
              height: isShowFilterPopupContent ? (isMobile ? '100%' : 600) : 'auto',
              backgroundColor: palette.white
            },
            isMobile ? { width: '100%', maxHeight: '100%' } : { maxHeight: '90%' }
          ]}
          style={{ padding: 15 }}
        >
          {isShowFilterPopupContent ? (
            <>
              <View
                style={[
                  template.rowContainer,
                  { marginBottom: 10, borderBottomWidth: 1, borderBottomColor: palette.border }
                ]}
              >
                <ThemedButton
                  style={{ backgroundColor: 'transparent', width: 40 }}
                  onPress={() => this.setState({ openPopup: false })}
                >
                  <MaterialIcons name='close' size={20} color={palette.primary} />
                </ThemedButton>
                <Text style={{ color: palette.primary, fontWeight: 'bold', fontSize: 18 }}>
                  {t('COMMON.FILTER_TITLE')}
                </Text>
              </View>
              <FormComponent
                ref={this.filters}
                value={this.state.filterContent}
                key={`${this.state.filters}-${isMobile ? 'mobile' : 'pc'}`}
                formConfig={this.getFilterConfig(
                  filters,
                  updateFilterOn,
                  isMobile,
                  showPopup,
                  filterFormCol
                )}
                onChange={this.handleUpdateFilter}
              />
              <View
                style={[
                  template.rowContainer,
                  { display: 'flex', width: '100%', position: 'absolute', bottom: 0 }
                ]}
              >
                <ThemedButton
                  onPress={this.handleResetFilters}
                  containerStyle={{ flex: 1, marginRight: 10 }}
                  style={{ backgroundColor: palette.negative }}
                >
                  <Text style={{ color: palette.black }}>{t('ORDER.FILTER_CLEAR_BUTTON')}</Text>
                </ThemedButton>
                <ThemedButton
                  onPress={() =>
                    this.setState(
                      { openPopup: false },
                      () =>
                        this.props.onChangeFilters &&
                        this.props.onChangeFilters(this.state.filters, 'search')
                    )
                  }
                  containerStyle={{ flex: 1 }}
                >
                  <Text style={{ color: palette.white }}>{t('REPORT.BUTTON_APPLY')}</Text>
                </ThemedButton>
              </View>
            </>
          ) : (
            <>
              <View style={manipulator.panel(20, 20, 20, 20)}>
                <Text
                  style={[
                    template.bold,
                    { color: palette.primary, fontSize: 24, paddingBottom: 10 }
                  ]}
                >
                  {t('COMMON.TITLE_TIPS')}
                </Text>
                {onFilterRuleComponent}
              </View>
              <ThemedButton onPress={() => this.setState({ openPopup: false })}>
                {this.props.t('FORM.BUTTON_OK')}
              </ThemedButton>
            </>
          )}
        </PageWidthModal>
      </View>
    )
  }
}

const FilterableSelector = forwardRef((props, ref) => {
  const { t } = useStores()
  return <OrginalFilterableSelector t={t} ref={ref} pageWidth={usePageWidth()} {...props} />
})

class OrginalFilterableSelector extends Component {
  static defaultProps = {
    multiple: false,
    keyExtractor: (i) => _.get(i, 'uuid') || _.get(i, 'key'),
    renderSelected: (key) => <Text>{key}</Text>
  }

  state = {
    editing: false,
    editingValue: null,
    editFormKey: `${Date.now()}-${Math.floor(Math.random() * 100)}`
  }

  table = React.createRef()

  handlePressItem = (item) => {
    const { keyExtractor, multiple, onChange } = this.props
    const key = keyExtractor(item)
    let selected
    if (multiple) {
      const currentValue = this.value
      const index = _.findIndex(currentValue, (s) => _.isEqual(s.uuid, key))
      selected = Object.assign([], currentValue)
      if (index >= 0) {
        selected.splice(index, 1)
      } else {
        selected.push({ uuid: item.uuid, label: item.label })
      }
    } else {
      selected = [{ uuid: item.uuid, label: item.label }]
    }

    onChange && onChange(multiple ? selected : selected[0])
  }

  remove = (key) => {
    const { multiple, onChange } = this.props
    const currentValue = this.value
    const index = _.findIndex(currentValue, (s) => _.isEqual(s.uuid, key))
    const selected = Object.assign([], currentValue)
    selected.splice(index, 1)

    onChange && onChange(multiple ? selected : selected[0])
  }

  handleRemoveAll = () => {
    const { multiple, onChange } = this.props
    const selected = []

    onChange && onChange(multiple ? selected : selected[0])
  }

  renderHeader = () => {
    const { ListHeaderComponent } = this.props
    return (
      <View style={[template.rowContainer, { backgroundColor: palette.panelBackground }]}>
        <View style={{ width: 70 }} />
        <View style={{ flex: 1 }}>
          <ListHeaderComponent />
        </View>
      </View>
    )
  }

  renderItem = ({ item, ...restProps }) => {
    const { multiple, EditItemComponent, t, isEditable } = this.props
    const checked = !!this.value.find(({ uuid }) => _.isEqual(uuid, this.props.keyExtractor(item)))
    return (
      <View style={[template.rowContainer, { flex: 1 }]}>
        <View style={{ width: 70, alignItems: 'center' }}>
          {multiple ? (
            checked ? (
              <MaterialIcons name='check-box' size={16} style={{ color: palette.active }} />
            ) : (
              <MaterialIcons
                name='check-box-outline-blank'
                size={16}
                style={{ color: palette.negative }}
              />
            )
          ) : checked ? (
            <MaterialIcons
              name='radio-button-checked'
              size={16}
              style={{ color: palette.active }}
            />
          ) : (
            <MaterialIcons
              name='radio-button-unchecked'
              size={16}
              style={{ color: palette.negative }}
            />
          )}
        </View>
        <View style={{ flex: 1 }}>{this.props.renderItem({ item, ...restProps })}</View>
        {(_.isUndefined(isEditable) || isEditable(item)) && EditItemComponent && (
          <ThemedButton
            style={{ backgroundColor: 'transparent', padding: 0, width: 50 }}
            onPress={() => this.setState({ editingUuid: _.get(item, 'uuid'), editing: true })}
          >
            <MaterialIcons name='edit' color={palette.primary} size={16} />
            <Text style={{ fontSize: 14, color: palette.primary, marginLeft: 4 }}>
              {t('FORM.BUTTON_EDIT')}
            </Text>
          </ThemedButton>
        )}
      </View>
    )
  }

  handleEditFormOnComplete = (value) => {
    const { onEditItemComplete } = this.props
    onEditItemComplete && onEditItemComplete()
    return this.setState({
      editing: !!value?.skipCloseModal,
      editFormKey: `${Date.now()}-${Math.floor(Math.random() * 100)}`
    })
  }

  renderRemoveAll = () => {
    const { t } = this.props
    return (
      <View style={manipulator.container('row', 'flex-end', 'center')}>
        <ThemedButton
          onPress={this.handleRemoveAll}
          style={{ minWidth: 120, marginBottom: 10, height: 40, backgroundColor: palette.warning }}
        >
          <MaterialIcons name='clear' size={14} color={palette.white} style={{ marginRight: 3 }} />
          <Text style={{ color: palette.white }}>{t('COMMON.REMOVE_ALL')}</Text>
        </ThemedButton>
      </View>
    )
  }

  renderSelected = (item, index) => {
    const numColumns = this.props.numColumns || 1
    const columnStyle =
      numColumns > 1
        ? (index + 1) % numColumns !== 0 && index !== this.value.length - 1
          ? { marginRight: 10 }
          : ''
        : ''
    const marginStyle = index + 1 > this.value.length - numColumns ? '' : { marginBottom: 10 }
    return (
      <ThemedButton
        key={item.uuid}
        onPress={() => this.remove(item.uuid)}
        style={[
          manipulator.container('row', 'flex-start', 'center'),
          marginStyle,
          columnStyle,
          {
            backgroundColor: 'transparent',
            borderWidth: 1,
            borderColor: palette.primary,
            borderRadius: 5
          }
        ]}
      >
        <MaterialIcons name='clear' size={24} color={palette.primary} style={{ marginRight: 5 }} />
        <Text>{this.props.renderSelected(item)}</Text>
      </ThemedButton>
    )
  }

  get value() {
    return this.props.multiple ? this.props.value || [] : this.props.value ? [this.props.value] : []
  }

  resetFilters = () => {
    const currentResetFunc =
      this.table.current?.handleResetFilters ?? this.table.current?.resetFilters
    currentResetFunc?.()
  }

  render() {
    const {
      EditItemComponent,
      editingReminder,
      t,
      containerStyle,
      numColumns = 1,
      hideSelector = false,
      onPressItem,
      multiple,
      renderItem,
      ListHeaderComponent,
      ...restProps
    } = this.props
    const value = this.value
    return (
      <View>
        {!hideSelector && (
          <>
            {numColumns > 1 ? (
              <View
                style={[
                  manipulator.panel(20, 20, 20, 20),
                  manipulator.border('all', 'light', 5),
                  { marginBottom: 30 }
                ]}
              >
                {value.length > 0 && this.renderRemoveAll()}
                {value.length === 0 ? (
                  <EmptyMessage />
                ) : (
                  <FlatList
                    numColumns={numColumns}
                    data={value}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={({ item, index, ...restProps }) => (
                      <View style={{ flex: 1 }}>{this.renderSelected(item, index)}</View>
                    )}
                    ListHeaderComponent={() => <View />}
                  />
                )}
              </View>
            ) : (
              <View
                style={[
                  manipulator.panel(20, 20, 20, 20),
                  manipulator.border('all', 'light', 5),
                  { marginBottom: 30 }
                ]}
              >
                {value.length > 0 && this.renderRemoveAll()}
                {value.length === 0 ? (
                  <EmptyMessage />
                ) : (
                  value.map((item, index) => this.renderSelected(item, index))
                )}
              </View>
            )}
          </>
        )}
        <View
          style={[
            manipulator.panel(20, 20, 20, 20),
            manipulator.border('all', 'light', 5),
            containerStyle
          ]}
        >
          <FilterableTable
            hasSelector
            ref={this.table}
            extraData={value}
            numColumns={numColumns}
            ListHeaderComponent={ListHeaderComponent ? this.renderHeader : null}
            renderItem={this.renderItem}
            onPressItem={this.handlePressItem}
            {...restProps}
          />
        </View>
        {this.state.editing && EditItemComponent && (
          <PageWidthModal
            isVisible
            onBackdropPress={() => this.setState({ editing: false })}
            style={{ padding: 0 }}
          >
            <Page title={t('FORM.BUTTON_EDIT')} reminder={editingReminder}>
              <EditItemComponent
                editing
                itemUuid={this.state.editingUuid}
                key={this.state.editFormKey}
                onDelete={() => this.setState({ editing: false }, this.handleEditFormOnComplete)}
                onCancel={() => this.setState({ editing: false })}
                onComplete={this.handleEditFormOnComplete}
              />
            </Page>
          </PageWidthModal>
        )}
      </View>
    )
  }
}

export { DefaultFilter, FilterableSelector, FilterableTable }
