import 'fastestsmallesttextencoderdecoder'
import { ApolloProvider } from '@apollo/client'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Frame, close as closeFrame } from '@rezio/components/frame'
import { getClient } from '@rezio/core/apollo'
import { GlobalErrorDialog } from '@rezio/core/errorHandler'
import { useRouter } from '@rezio/core/hooks'
import { freshdeskI18nLabels, negotiateLang } from '@rezio/core/i18n'
import {
  NotificationProvider,
  UserNotification,
  useNotificationHandler
} from '@rezio/core/notification'
import { ConversationProvider } from '@rezio/core/stores/conversation'
import { KeystoneProvider } from '@rezio/core/stores/rootStore'
import {
  ErrorBoundary as SentryErrorBoundary,
  TouchEventBoundary as SentryTouchEventBoundary,
  Tracing
} from '@rezio/unimodules/sentry'
import { pageViewTrigger } from '@rezio/unimodules/tracking'
import { formatFreshdeskLocale } from '@rezio/utils/format'
import { FallbackRender } from '@sentry/react'
import * as Notifications from 'expo-notifications'
import * as Updates from 'expo-updates'
import { Observer, Provider } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AppState, Dimensions, Platform, Text, Vibration, View } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { PortalProvider, WhitePortal } from 'react-native-portal'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import * as Sentry from 'sentry-expo'
import { useAtom } from 'jotai'
import { useFonts } from 'expo-font'

import 'expo-dev-client'
import { DialogModal, PageStepControl, StaticImage, View100vh } from './components'
import { DropdownBaseView } from './components/modalDropdown'
import { AppConfigProvider } from './core/AppConfigProvider'
import { getEnv } from './core/config'
import Core from './core/core'
import DataStore from './core/data'
import { useDeeplink } from './core/linking'
import { StoreContext, initializeStore } from './core/stores'
import './css'
import { getTheme, manipulator, palette, template } from './res/theme'
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration'
import { ModalHost } from './unimodules/modal'
import { BackButton, UniRouter, useHistory } from './unimodules/router'
import Routes from './components/routes'
import { dimensionAtom } from './core/layout'

declare global {
  interface Window {
    fwSettings: any
    FreshworksWidget: any
    ReactNativeWebView: any
  }
}

declare let FreshworksWidget: any

serviceWorkerRegistration.register()

// NOTE: PROD 用 sentry SASS 版本
Sentry.init({
  dsn:
    getEnv() === 'prod'
      ? 'https://48bff455d40641d9b658b68262da33d4@o499500.ingest.sentry.io/5578061'
      : 'https://3a546efe72ff430db59e9a38d5836ab5@sentry.rezio.io/4',
  environment: `${Platform.OS}_${getEnv()}`,
  autoSessionTracking: true,
  debug:
    ['sit', 'uat'].includes(getEnv()) ||
    (window as Window & typeof globalThis & { __SENTRY_DEBUG__: boolean }).__SENTRY_DEBUG__ != null,
  ignoreErrors: [
    'Native is disabled',
    'jwt expired',
    '3000ms timeout exceeded', // expo讀取字體的FontObserver 不影響使用
    'ChunkLoadError' // 上版當後的chunk載入 重整頁面會正常 或是需要push/long pulling讓頁面知道有新版 提醒用戶重整
  ],
  integrations: [new Tracing()],
  tracesSampleRate: 1.0
})

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false
  })
})

const ErrorScreen = ({ resetError }: { resetError?: () => void }) => {
  useEffect(() => {
    if (Platform.OS !== 'web') {
      resetError?.()
    }
  }, [])
  return (
    <View100vh style={{ flex: 1, marginVertical: 0, marginHorizontal: 'auto' }}>
      <View style={[manipulator.container('row', 'center', 'center'), { flex: 1 }]}>
        <View style={{ alignItems: 'center' }}>
          <Text
            style={{ fontSize: 28, fontWeight: 'bold', color: palette.brand, marginBottom: 40 }}
          >
            Something went wrong, please contact rezio.
          </Text>
          <StaticImage
            style={[template.center, { width: 365, height: 360 }]}
            source={require('./assets/500.png')}
          />
        </View>
      </View>
    </View100vh>
  )
}

const SentryBoundary = (props) => {
  const fallbackMessage: FallbackRender = useCallback((errorData) => {
    if (errorData.error.name === 'ChunkLoadError' && Platform.OS === 'web') {
      window.location.reload()
    }
    return <ErrorScreen resetError={errorData.resetError} />
  }, [])

  return (
    <SentryErrorBoundary fallback={fallbackMessage}>
      <SentryTouchEventBoundary {...props} />
    </SentryErrorBoundary>
  )
}

const handleUpdater =
  (t = (key) => key) =>
  ({ type, manifest, message }) => {
    switch (type) {
      case Updates.UpdateEventType.NO_UPDATE_AVAILABLE:
        try {
          Core.getInstance().setUpdateChecked(true)
        } catch (e) {}
        return
      case Updates.UpdateEventType.ERROR:
        Alert.alert(t('COMMON.APP_UPDATE_FAILED'), t('COMMON.APP_UPDATE_FAILED_DESCRIPTION'))
        return
    }

    Alert.alert(t('COMMON.APP_UPDATE_SUCEESS_TITLE'), t('COMMON.APP_UPDATE_SUCEESS_DESCRIPTION'), [
      { text: t('FORM.BUTTON_CONFIRM'), onPress: async () => await Updates.reloadAsync() }
    ])
  }

function NotificationHandler() {
  const handleNotification = useNotificationHandler()
  useEffect(() => {
    const receiverListener = Notifications.addNotificationReceivedListener((notification) => {
      Vibration.vibrate()
    })
    const responseListener = Notifications.addNotificationResponseReceivedListener(
      (notificationResponse) => {
        handleNotification(
          notificationResponse.notification.request.content.data as unknown as UserNotification
        ).catch(console.error)
      }
    )
    return () => {
      Notifications.removeNotificationSubscription(receiverListener)
      Notifications.removeNotificationSubscription(responseListener)
    }
  }, [handleNotification])
  return <View style={{ display: 'none' }} />
}

const WebWorkerUpdateNotify = ({ hasUpdate }) => {
  const [dismiss, setDismiss] = useState(false)

  const reload = useCallback(() => {
    if (Platform.OS === 'web') {
      location.reload()
    }
  }, [])

  const { t } = useTranslation()

  if (Platform.OS !== 'web') {
    return null
  }

  return (
    <DialogModal isVisible={hasUpdate && !dismiss} containerStyle={{ padding: 0 }}>
      <View style={{ padding: 20 }}>
        <Text style={{ fontSize: 18, color: palette.primary, fontWeight: 'bold' }}>
          {t('COMMON.WEB_UPDATE_SUCEESS_TITLE')}
        </Text>
        <Text style={{ marginVertical: 4 }}>{t('COMMON.WEB_UPDATE_SUCEESS_DESCRIPTION')}</Text>
      </View>
      <PageStepControl
        buttons={[
          {
            type: 'negative',
            text: t('COMMON.WEB_UPDATE_BUTTON_DISMISS'),
            onPress: () => setDismiss(true)
          },
          {
            type: 'positive',
            text: t('FORM.BUTTON_CONFIRM'),
            onPress: reload
          }
        ]}
      />
    </DialogModal>
  )
}

function App() {
  const [ready, setReady] = useState(false)
  const [hasUpdate, setHasUpdate] = useState(false)
  const [isMaintaining, setIsMaintaining] = useState(false)
  const storesRef = useRef(null)
  const router = useRouter()
  const history = useHistory()
  const appState = useRef(AppState.currentState)
  const lastCheckUpdateTime = useRef(0)
  const [fontsLoaded] = useFonts({
    IcoMoon: require('@rezio/assets/icomoon/icomoon.ttf')
  })

  // 維護時間
  useEffect(() => {
    const maintenanceRange = {
      sit: ['2024-09-16T14:00:00+0800', '2024-09-16T14:10:00+0800'],
      sit01: ['2024-09-16T14:00:00+0800', '2024-09-16T14:10:00+0800'],
      uat: ['2024-09-16T14:20:00+0800', '2024-09-16T14:30:00+0800'],
      sta: ['2024-09-16T14:40:00+0800', '2024-09-16T14:50:00+0800'],
      prod: ['2024-09-18T02:00:00+0800', '2024-09-18T06:00:00+0800']
    }
    const env = getEnv()
    const maintenanceStartTime = new Date(maintenanceRange[env][0])
    const maintenanceEndTime = new Date(maintenanceRange[env][1])
    let timer: NodeJS.Timeout
    const calculateIsMaintaining = () => {
      if (moment().isSameOrAfter(maintenanceEndTime)) {
        setIsMaintaining(false)
        clearInterval(timer)
        return
      }
      const isMaintaining = moment().isBetween(maintenanceStartTime, maintenanceEndTime)
      isMaintaining && closeFrame()
      setIsMaintaining(isMaintaining)
    }
    calculateIsMaintaining()

    if (
      moment().isBetween(moment(maintenanceStartTime).subtract(2, 'h'), moment(maintenanceEndTime))
    ) {
      timer = setInterval(calculateIsMaintaining, 60 * 1000)
    }

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    ;(async function () {
      let lastState
      try {
        lastState = JSON.parse(await AsyncStorage.getItem('core')) || {}
      } catch (error) {
        lastState = {}
      }
      const core = Core.getInstance()
      core.initialize({
        ...lastState,
        ...(router?.query?.lang ? { lang: negotiateLang(router.query.lang as string) } : {})
      })
      const data = DataStore.getInstance({}, core)
      const store = initializeStore({ core, history })
      const theme = getTheme()
      storesRef.current = { core, store, data, t: core.t, theme }
      setReady(true)
    })().catch(console.error)
  }, [])

  useEffect(() => {
    pageViewTrigger(router.pageName ?? router.pathname)?.catch(console.error)
  }, [router.pathname])

  const checkUpdate = useCallback(async () => {
    if (!Updates.updateId) {
      return
    }
    // 5分鐘內不重複檢查
    const now = Date.now()
    if (now - lastCheckUpdateTime.current > 1000 * 60 * 5) {
      lastCheckUpdateTime.current = now

      const result = await Updates.fetchUpdateAsync()
      if (result.isNew) {
        const t = storesRef.current.t
        Alert.alert(
          t('COMMON.APP_UPDATE_SUCEESS_TITLE'),
          t('COMMON.APP_UPDATE_SUCEESS_DESCRIPTION'),
          [{ text: t('FORM.BUTTON_CONFIRM'), onPress: async () => await Updates.reloadAsync() }]
        )
      }
    }
  }, [])

  const handleChangeAppState = useCallback((nextAppState) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      checkUpdate().catch(console.error)
    }
    appState.current = nextAppState
  }, [])

  useEffect(() => {
    if (!ready) {
      return () => {}
    }

    if (Platform.OS === 'web') {
      navigator?.serviceWorker?.getRegistration().then((registration) => {
        if (registration?.waiting?.state === 'installed') {
          registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })
          location.reload()
        } else {
          registration?.addEventListener('updatefound', (...args) => {
            const installingWorker = registration.installing
            installingWorker.addEventListener('statechange', function () {
              registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })
              setHasUpdate(true)
            })
          })
        }
      })

      // first time freshdesk widget embed code
      window.fwSettings = {
        widget_id: 67000003397,
        locale: formatFreshdeskLocale(storesRef?.current?.core?.lang)
      }
      const bodyScript = document.createElement('script')
      bodyScript.innerHTML =
        '!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()'
      document.body.appendChild(bodyScript)

      const script = document.createElement('script')
      script.src = 'https://widget.freshworks.com/widgets/67000003397.js'
      script.type = 'text/javascript'
      script.async = true
      script.onload = () => {
        FreshworksWidget('hide', 'launcher')
        FreshworksWidget('setLabels', freshdeskI18nLabels)
      }
      document.body.appendChild(script)
      return () => {
        document.body.removeChild(script)
        document.body.removeChild(bodyScript)
      }
    } else {
      const t = storesRef.current.t

      const subscription = Updates.addListener(handleUpdater(t))
      const appEventSubscription = AppState.addEventListener('change', handleChangeAppState)

      return () => {
        subscription.remove()
        appEventSubscription.remove()
      }
    }
  }, [ready])

  useDeeplink()

  const [, setDimension] = useAtom(dimensionAtom)
  useEffect(() => {
    const emitter = Dimensions.addEventListener('change', (change) => {
      setDimension(change.window)
    })
    return () => {
      emitter.remove()
    }
  }, [])

  return (
    ready &&
    fontsLoaded && (
      <SentryBoundary>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <View100vh>
            <PortalProvider>
              <SafeAreaProvider>
                <ApolloProvider client={getClient()}>
                  <StoreContext.Provider value={storesRef.current}>
                    <Provider {...storesRef.current}>
                      <AppConfigProvider>
                        <NotificationProvider>
                          <ConversationProvider>
                            <KeystoneProvider>
                              <DropdownBaseView style={{ flex: 1, width: '100%' }}>
                                <ModalHost>
                                  <GlobalErrorDialog>
                                    <Observer>
                                      {() => (
                                        <>
                                          <Routes
                                            key={storesRef.current.core.lang}
                                            style={{ height: '100%' }}
                                            isMaintaining={isMaintaining}
                                          />
                                          <Frame>
                                            <Routes
                                              key={storesRef.current.core.lang}
                                              style={{ height: '100%' }}
                                              pageOnly
                                            />
                                          </Frame>
                                        </>
                                      )}
                                    </Observer>
                                    <NotificationHandler />
                                    <WebWorkerUpdateNotify hasUpdate={hasUpdate} />
                                  </GlobalErrorDialog>
                                </ModalHost>
                                <WhitePortal name='toast' />
                              </DropdownBaseView>
                            </KeystoneProvider>
                          </ConversationProvider>
                        </NotificationProvider>
                      </AppConfigProvider>
                    </Provider>
                  </StoreContext.Provider>
                </ApolloProvider>
              </SafeAreaProvider>
            </PortalProvider>
          </View100vh>
        </GestureHandlerRootView>
      </SentryBoundary>
    )
  )
}

function AppWithRouter(props) {
  return (
    <UniRouter>
      <BackButton>
        <App {...props} />
      </BackButton>
    </UniRouter>
  )
}

export default AppWithRouter
