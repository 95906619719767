import { PaymentMethod, PaymentPlatform, PaymentPlatformStatus } from '@rezio/utils/types'

import {
  Gateway,
  IPaymentContext,
  PaymentMethodList,
  PaymentMethodDisabledBasis
} from './multiGatewayModal.type'

export const paymentMethodOrderList: string[] = [
  PaymentMethod.CashCoupon,
  PaymentMethod.CreditCard,
  PaymentMethod.FromChannel,
  PaymentMethod.CouponFromChannel,
  PaymentMethod.PointsFromChannel,
  PaymentMethod.Credit,
  PaymentMethod.Google,
  PaymentMethod.SAMSUNG,
  PaymentMethod.ATM,
  PaymentMethod.CVS,
  PaymentMethod.BANK,
  PaymentMethod.Mobile,
  PaymentMethod.PayNow,
  PaymentMethod.Alipay,
  PaymentMethod.AlipayHK,
  PaymentMethod.WeChat,
  PaymentMethod.Grab,
  PaymentMethod.Fpx,
  PaymentMethod.PayOnSite,
  PaymentMethod.PayMe,
  PaymentMethod.TouchNGo,
  PaymentMethod.Boost,
  PaymentMethod.Prompt,
  PaymentMethod.TrueMoney,
  PaymentMethod.LinePay,
  PaymentMethod['123SERVICE'],
  PaymentMethod.BankTransfer,
  PaymentMethod.Cash,
  PaymentMethod.Monthly
]

export const gatewayOrderList: string[] = [
  PaymentPlatform.Neweb,
  PaymentPlatform.RezioPayOrico,
  PaymentPlatform.StripeDirectPay,
  PaymentPlatform.Toss,
  PaymentPlatform['2C2PTH'],
  PaymentPlatform['2C2PSG'],
  PaymentPlatform['2C2PSGUSD'],
  PaymentPlatform['2C2PMY'],
  PaymentPlatform['2C2PHK']
]

export const getPaymentMethodDisableBasis = (
  paymentContext: IPaymentContext
): PaymentMethodDisabledBasis[] => {
  return paymentContext.paymentPlatforms.reduce((result, platform) => {
    return [
      ...result,
      ...platform.payWayList.map((payWayItem) => ({
        gateway: platform.gateway,
        payWay: payWayItem.payWay,
        isDeny: payWayItem.isDeny,
        isReadOnly: payWayItem.isReadonly,
        status: platform.status
      }))
    ]
  }, [])
}

export const sortByPaymentMethodOrderList = (paymentMethod: PaymentMethodList[]) => {
  return paymentMethod.sort((a, b) => {
    const indexOfA = paymentMethodOrderList.indexOf(a.paymentMethod)
    const indexOfB = paymentMethodOrderList.indexOf(b.paymentMethod)

    if (indexOfA === -1) {
      return 1
    }

    if (indexOfB === -1) {
      return -1
    }

    return indexOfA - indexOfB
  })
}

export const getSortedByGatewayOrderList = (
  sortedList: PaymentMethodList[],
  disabledBasis: PaymentMethodDisabledBasis[]
) => {
  return sortedList.map((method) => {
    const currentPaymentMethod = disabledBasis.filter(
      (item) => item.payWay === method.paymentMethod
    )
    method.gateway = method.gateway
      .map((item: Gateway) => {
        const currentGateway = currentPaymentMethod.find((rule) => rule.gateway === item.name)
        item.isDeny = currentGateway?.isDeny
        item.isReadOnly = currentGateway?.isReadOnly
        item.status = currentGateway?.status
        return item
      })
      .sort((a, b) => gatewayOrderList.indexOf(a.name) - gatewayOrderList.indexOf(b.name))
    return method
  })
}

export const formatPaymentMethod = (
  paymentMethod: PaymentMethodList[],
  paymentContext: IPaymentContext
) => {
  const disableBasis = getPaymentMethodDisableBasis(paymentContext)
  const sortedList = sortByPaymentMethodOrderList(paymentMethod)

  return getSortedByGatewayOrderList(sortedList, disableBasis)
    .filter(
      (method) =>
        method.gateway.length >= 1 &&
        !method.gateway.every((item) => item.isDeny || item.isReadOnly)
    )
    .map((method) => ({
      ...method,
      gateway: method.gateway.filter((item) =>
        [PaymentPlatformStatus.InProgress, PaymentPlatformStatus.Ready].includes(item.status)
      )
    }))
}
