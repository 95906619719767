import React, { useCallback, useRef, useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import Animated, { useSharedValue, useAnimatedStyle } from 'react-native-reanimated'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'

import type {
  ViewProps,
  LayoutChangeEvent,
  NativeSyntheticEvent,
  NativeScrollEvent
} from 'react-native'

type CustomScrollbarProps = {
  children: React.ReactNode
  childrenHeight: number
  scrollbarCustomStyle?: ViewProps['style']
}

const CustomScrollbar = (props: CustomScrollbarProps) => {
  const { childrenHeight, scrollbarCustomStyle } = props
  const translationY = useSharedValue(0)
  const scrollViewRef = useRef(null)
  const [containerHeight, setContainerHeight] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [scrollbarAbsoluteY, setScrollbarAbsoluteY] = useState(0)

  const scrollbarHeight =
    childrenHeight > containerHeight
      ? Math.round(containerHeight * (containerHeight / childrenHeight))
      : 0

  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (isDragging) return
      const translateY = event.nativeEvent.contentOffset.y * (containerHeight / childrenHeight)
      translationY.value = translateY
    },
    [containerHeight, childrenHeight, isDragging]
  )

  const animatedStyles = useAnimatedStyle(() => ({
    transform: [
      {
        translateY: translationY.value
      }
    ]
  }))

  const pan = Gesture.Pan()
    .onBegin((event) => {
      setScrollbarAbsoluteY(event.absoluteY)
      setIsDragging(true)
    })
    .onChange((event) => {
      if (Platform.OS !== 'web') return
      const minimumScrollY = 0
      const maximumScrollY = containerHeight - scrollbarHeight
      const translationDiff = event.absoluteY - scrollbarAbsoluteY + translationY.value
      setScrollbarAbsoluteY(event.absoluteY)
      const scrollViewPosition = translationDiff * (childrenHeight / containerHeight)

      if (translationDiff > maximumScrollY) {
        scrollViewRef.current.scrollTo({ y: childrenHeight - containerHeight, animated: false })
        translationY.value = maximumScrollY
        return
      }

      if (translationDiff < minimumScrollY) {
        scrollViewRef.current.scrollTo({ y: 0, animated: false })
        translationY.value = minimumScrollY
        return
      }

      scrollViewRef.current.scrollTo({ y: scrollViewPosition, animated: false })
      translationY.value = translationDiff
    })
    .onFinalize(() => {
      setIsDragging(false)
    })

  return (
    <View style={styles.container}>
      <Animated.ScrollView
        ref={scrollViewRef}
        onScroll={handleScroll}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        style={styles.scrollView}
        onLayout={(event: LayoutChangeEvent) => {
          setContainerHeight(event.nativeEvent.layout.height)
        }}
      >
        {props.children}
      </Animated.ScrollView>
      <GestureDetector gesture={pan}>
        <Animated.View
          style={[
            styles.scrollbar,
            animatedStyles,
            scrollbarCustomStyle,
            { height: scrollbarHeight }
          ]}
        />
      </GestureDetector>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  scrollView: {
    flex: 1
  },
  scrollbar: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 4
  }
})

export default CustomScrollbar
