import { palette } from '@rezio/res/theme'
import { noop } from 'lodash'
import React, { useState } from 'react'
import { View, type StyleProp, type ViewStyle, Text } from 'react-native'

import { TagListBuilder } from './TagListBuilder'
import { ThemedTagList } from './ThemedTagList'

interface Props {
  value: string[]
  style?: StyleProp<ViewStyle>
  onChange: (value: string[]) => void
}

export const TagList = ({ value, style, onChange }: Props) => {
  const [errorMsg, setErrorMsg] = useState('')
  return (
    <View style={style}>
      {errorMsg && <Text style={{ marginBottom: 5, color: palette.warning }}>{errorMsg}</Text>}
      <TagListBuilder tagItems={value} onAddTag={onChange} onError={setErrorMsg} />
      <ThemedTagList
        hideAddButton
        hideBorder
        items={value}
        onCancelItem={onChange}
        onPressAdd={noop}
      />
    </View>
  )
}
