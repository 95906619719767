import {
  DeviceCustomPayWay,
  DeviceSettingTab,
  DeviceCustomPayWayKey
} from '@rezio/components/device/status'
import { themeDefaultSetting } from '@rezio/components/mysite/setting'
import { gatewayOrderList } from '@rezio/components/store/multiGateway/utils'
import { APIErrorCode } from '@rezio/core/api'
import { ProductStatus, ProductCategories } from '@rezio/utils/types'
import _ from 'lodash'
import fp from 'lodash/fp'
import { action, observable, toJS, isObservableProp, keys, decorate } from 'mobx'
import { range, path } from 'ramda'

import { parserUuid } from '../utils/format'
import { PERMISSION_GROUP_UUID } from './auth'
import Core from './core'

const debug = require('debug')('tako:data_store')

class DataStore {
  static instance = null

  static getInstance = function (initialData, core) {
    if (this.instance === null) {
      this.instance = new DataStore(initialData, core)
    }
    return this.instance
  }

  constructor(initialData, core) {
    if (!core) {
      core = Core.getInstance()
    }
    this.api = core.api
    if (!_.isEmpty(initialData)) {
      const pickingKeys = _(keys(this))
        .map((key) => {
          return isObservableProp(this, key) ? key : null
        })
        .compact()
        .value()
      Object.assign(this, _.pick(initialData, pickingKeys))
    }
  }

  location = {}

  sessionData = {}

  productOption = []

  sessionSettingData = {}

  resourceOption = {}

  productLabelList = {}

  products = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  loadingProduct = false

  loadProducts = async (filters) => {
    if (this.loadingProduct) {
      return
    }
    const { page = 1, param } = filters
    this.loadingProduct = true
    const [request] = this.api.get(`product/${page}`, { params: param })
    const result = await request
    this.products = result.data
    this.loadingProduct = false
  }

  putVoucherData = async ({ productId, value }) => {
    const { voucherType } = value.type
    // let { voucherUnit, writeOff } = value.setting
    // voucherUnit = (undefined === voucherUnit) ? 0 : voucherUnit
    // writeOff = (undefined === writeOff) ? false : writeOff
    const unitType = 1
    const writeOff = true
    const requestBody = fp.omitBy(fp.isUndefined)({
      voucherType,
      unitType,
      writeOff
    })

    const [request] = this.api.put(`/product/${productId}/voucher`, requestBody)
    const result = await request
    return result
  }

  fetchProductOption = async () => {
    try {
      const [request] = this.api.get('/product/option')
      const result = await request
      this.productOption = result.data
    } catch (e) {
      debug(e)
    }
  }

  fetchProductLabelList = async (productId, type) => {
    const [request] = this.api.get(`/product/${productId}/labelList`, { params: { type } })
    const result = await request
    this.productLabelList[type] = result.data
  }

  fetchSessionData = async (productUuid, sessionId) => {
    try {
      const [request] = this.api.get(`/publish/session/${sessionId}?productUuid=${productUuid}`)
      const result = await request
      this.sessionData = result.data
      return Promise.all([
        this.fetchProduct(_.get(result.data, 'productUuid')),
        this.fetchFormatSalesOption(_.get(result.data, 'productUuid')),
        this.fetchSessionSettingData(_.get(result.data, 'sessionSettingUuid'))
      ])
    } catch (e) {
      debug(e)
    }
  }

  fetchSessionSettingData = async (sessionSettingUuid) => {
    try {
      const [request] = this.api.get(`/publish/sessionSetting/${sessionSettingUuid}`)
      const result = await request
      this.sessionSettingData = result.data
    } catch (e) {
      debug(e)
    }
  }

  putSessionSetting = async (requestBody, sessionSettingUuid) => {
    const [request] = this.api.put(`/publish/sessionSetting/${sessionSettingUuid}`, requestBody)
    const result = await request
    return result
  }

  postSessionSetting = async (requestBody) => {
    const [request] = this.api.post('/publish/sessionSetting', requestBody)
    const result = await request
    return result
  }

  deleteSessionSetting = async (productUuid, sessionUuid) => {
    const [request] = this.api.delete(
      `/publish/session/${sessionUuid}?target=all&productUuid=${productUuid}`
    )
    const result = await request
    return result
  }

  putPriceDetail = async ({ productId, value }) => {
    try {
      // const { voucherType } = value.type
      // let { voucherUnit: unitType, writeOff } = value.setting
      // unitType = (undefined === unitType) ? 0 : unitType
      // writeOff = (undefined === writeOff) ? false : writeOff
      // const requestBody = fp.omitBy(fp.isUndefined)({
      //   voucherType,
      //   unitType,
      //   writeOff
      // })
      const [request] = this.api.put(`/product/${productId}/priceDetail`, value)
      const result = await request
      return result
    } catch (e) {
      debug(e)
    }
  }

  putLocationData = async ({ productId, value }) => {
    // try {
    const requestBody = fp.omitBy(fp.isUndefined)(value)
    const [request] = this.api.put(`/product/${productId}/location`, requestBody)
    const result = await request
    return result && Object.assign(this.products[productId], { location: value })
    // } catch (e) {
    //   debug(e)
    // }
  }

  putGuideData = async ({ productId, value }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(value)
    const [request] = this.api.put(`/product/${productId}/guide`, requestBody)
    const result = await request
    return result
  }

  putSalesOptionData = async ({ productId, value }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(value)
    const [request] = this.api.put(`/product/${productId}/salesOption`, requestBody)
    const result = await request
    return result
  }

  putPublishData = async ({ productId, value }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(value)
    const [request] = this.api.put(`/product/${productId}/publish`, requestBody)
    const result = await request
    return result
  }

  putProductIntroData = async ({ productId, value }) => {
    const { brief, description, warnings, feature, purchaseSummary, userRestrictions } = value
    const requestBody = fp.omitBy(fp.isUndefined)({
      brief,
      feature,
      purchaseSummary,
      description,
      warnings,
      userRestrictions
    })
    const [request] = this.api.put(`/product/${productId}/intro`, requestBody)
    const result = await request
    return result
  }

  putProductItineraryData = async (uuid, input) => {
    const [request] = this.api.put(`/product/${uuid}/itinerary`, input)
    const result = await request
    return (
      result &&
      Object.assign(this.products[uuid], { itinerary: input.itinerary, duration: input.duration })
    )
  }

  fetchCancelPolicy = async (uuid) => {
    const [request] = this.api.get('cancelPolicy')
    const result = await request
    this.cancelPolicy = result.data
  }

  fetchProduct = async (uuid) => {
    const [request] = this.api.get(`/product/${uuid}`)
    const result = await request
    this.products[uuid] = result.data
    return result.data
  }

  deleteProduct = async (target) => {
    const productUuids = [].concat(target)
    const payload = {
      productUuids
    }
    await this.api.delete('product', { data: payload })
    this.hideProductOnClient(productUuids)
    return payload
  }

  archiveProduct = async (target) => {
    const productUuids = [].concat(target)
    const payload = {
      productUuids,
      productStatus: ProductStatus.Archived
    }
    await this.api.put('product/status', payload)
    this.hideProductOnClient(productUuids)
    return payload
  }

  unarchiveProduct = async (target) => {
    const productUuids = [].concat(target)
    const payload = {
      productUuids,
      productStatus: ProductStatus.Discontinued
    }
    await this.api.put('product/status', payload)
    this.hideProductOnClient(productUuids)
    return payload
  }

  duplicateProduct = async (target) => {
    try {
      const uuid = target[0]
      const [request] = this.api.post(`product/${uuid}/copy`)
      const result = await request
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  hideProductOnClient = (uuids) => {
    uuids.forEach((uuid) => delete this.products.list[uuid])
  }

  unlockProduct = async (target) => {
    const [request] = await this.api.put(`/product/${target}/processedStatus`, { status: false })
    const result = await request
    result && (await this.fetchProduct(target))
    return target
  }

  fetchProductMedia = async (uuid) => {
    const result = await this.api.get(`/product/${uuid}/media`)[0]
    const { cover, image, video } = result.data
    Object.assign(this.products[uuid], { cover, image, video })
    return { cover, image, video }
  }

  mysite = {}

  fetchMysite = async () => {
    const [request] = this.api.get('/store/mysite')
    const result = await request
    this.mysite = result.data
  }

  updateMysite = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.put('/store/mysite', requestBody)
    const result = await request
    this.mysite = requestBody
    return result
  }

  themeDesign = {}

  fetchThemeDesign = async () => {
    const [request] = this.api.get('/store/themeDesign')
    const result = await request
    this.themeDesign = result.data
  }

  putThemeDesign = async (requestBody) => {
    const [request] = this.api.put('/store/themeDesign', requestBody)
    const result = await request
    this.themeDesign = requestBody
    return result
  }

  putMysiteThemeDesign = async (params = {}) => {
    const { mysite, themeDesign } = params
    const isMysiteUpdate = !_.isEmpty(mysite)
    const isThemeDesignUpdate = !_.isEmpty(themeDesign)

    if (!isMysiteUpdate && !isThemeDesignUpdate) {
      return false
    }

    ;(await isMysiteUpdate) && _.isEmpty(this.mysite) && this.fetchMysite()
    ;(await isThemeDesignUpdate) && _.isEmpty(this.themeDesign) && this.fetchThemeDesign()
    const mysiteBody = { ...this.mysite, ...mysite }
    const themeDesignBody =
      isThemeDesignUpdate &&
      _.mapValues(
        themeDefaultSetting,
        (value, key) => themeDesign?.[key] ?? this.themeDesign?.[key] ?? value
      )
    const [themeDesignResult, mysiteResult] = await Promise.all([
      isThemeDesignUpdate && this.putThemeDesign(themeDesignBody),
      isMysiteUpdate &&
        this.updateMysite({ registerSubDomain: this.mysite?.registerSubDomain, ...mysiteBody })
    ])
    return { themeDesign: themeDesignResult, mysite: mysiteResult }
  }

  putVoucherSetting = async (requestBody) => {
    const [request] = this.api.put('/store/voucherSetting', requestBody)
    const result = await request
    return result
  }

  checkSubDomain = async (subDomain) => {
    const [request] = this.api.get(`/store/checkSubDomain/${subDomain}`)
    const result = await request
    return result
  }

  feeIncludeOptions = []

  feeExcludeOptions = []

  filterFeeIncludeOptions = []

  filterFeeExcludeOptions = []

  landmarkLabels = {}

  landmarkOptions = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  sessionSettingHistory = []
  orderCancelOptions = []

  fetchLandmarkLabel = async (uuid) => {
    const [request] = this.api.get(`tag/landmarkLabel?uuid=${uuid}`)
    const result = await request
    this.landmarkLabels = result.data
  }

  fetchLandmarkOptions = async (params = {}, config = {}) => {
    const { label: text, country, page = 1 } = params
    const { shouldUpdateData = true } = config
    const [request] = this.api.get(
      `/option?keys=landmark${_.isUndefined(text) ? '' : `&text=${text}`}${
        _.isUndefined(country) ? '' : `&country=${country}`
      }&p=${page}`
    )
    const result = await request
    if (shouldUpdateData) {
      this.landmarkOptions = _.get(result.data, 'landmark') || { list: [] }
    }
    return result
  }

  fetchFeeOptions = async (text, type) => {
    const [request] = this.api.get(`/option?keys=feeOption&text=${_.isUndefined(text) ? '' : text}`)
    const result = await request

    if (!_.isUndefined(text)) {
      this.filterFeeIncludeOptions =
        type === 'include' ? result.data.feeOption.list : this.filterFeeIncludeOptions
      this.filterFeeExcludeOptions =
        type === 'exclude' ? result.data.feeOption.list : this.filterFeeExcludeOptions
    } else {
      this.feeIncludeOptions =
        type === 'include' || _.isUndefined(type)
          ? result.data.feeOption.list
          : this.feeIncludeOptions
      this.feeExcludeOptions =
        type === 'exclude' || _.isUndefined(type)
          ? result.data.feeOption.list
          : this.feeExcludeOptions
      this.filterFeeIncludeOptions = this.feeIncludeOptions
      this.filterFeeExcludeOptions = this.feeExcludeOptions
    }

    return result
  }

  invitationEmail = ''

  checkInvitation = async (iid) => {
    const [request] = this.api.get(`/account/invite/${iid}`)
    const result = await request
    this.invitationEmail = _.get(result, 'response.data.data.email', null)
  }

  acceptPayType = []

  fetchAcceptPayType = async () => {
    const [request] = this.api.get('/store/acceptPayType')
    const result = await request
    this.acceptPayType = result.data.acceptPayType
  }

  salesOptions = {}

  clearSalesOption = () => {
    this.salesOptions = {}
    return this.salesOptions
  }

  clearSessionData = () => {
    this.sessionData = {}
    return this.sessionData
  }

  clearSessionSettingData = () => {
    this.sessionSettingData = {}
    return this.sessionSettingData
  }

  fetchSalesOption = async (uuid) => {
    try {
      const [request] = this.api.get(`/product/${uuid}/salesOption`)
      const result = await request
      this.salesOptions[uuid] = result.data
    } catch (e) {
      debug(e)
    }
  }

  fetchFormatSalesOption = async (uuid) => {
    try {
      const [request] = this.api.get(`/product/${uuid}/salesOption?format=1`)
      const result = await request
      this.salesOptions[uuid] = result.data
    } catch (e) {
      debug(e)
    }
  }

  fetchSessionSettingHistory = async (uuid) => {
    try {
      const [request] = this.api.get(`/product/${uuid}/sessionSetting`)
      const result = await request
      this.sessionSettingHistory = result.data
      return result && Object.assign(this.products[uuid], { sessionSettingHistory: result.data })
    } catch (e) {
      debug(e)
    }
  }

  putPricePolicyNdSession = async ({ salesOptionUuid, ...rest }) => {
    try {
      const requestBody = fp.omitBy(fp.isUndefined)(rest)
      const [request] = this.api.put(
        `/publish/pricePolicyNdSession/${salesOptionUuid}`,
        requestBody
      )
      const result = await request
      return result
    } catch (error) {
      if (error.code === APIErrorCode.Frontend) {
        throw error
      }
    }
  }

  postNewProduct = async (newProduct) => {
    const requestBody = fp.omitBy(fp.isUndefined)(newProduct)
    const [request] = this.api.post('product', requestBody)
    const result = await request
    return result.data.uuid
  }

  putProductBasic = async (product) => {
    const requestBody = fp.omitBy(fp.isUndefined)(product)
    const [request] = this.api.put(`product/${product.uuid}/basic`, requestBody)
    const result = await request
    return result
  }

  putProductDeposit = async (input) => {
    const { uuid, depositPolicyUuid } = input
    const product = {
      depositPolicyUuid
    }
    const requestBody = fp.omitBy(fp.isUndefined)(product)
    const [request] = this.api.put(`product/${uuid}/depositPolicy`, requestBody)
    const result = await request
    return result
  }

  putProductFeeNdTax = async (input) => {
    const { uuid, feeNdTaxUuid } = input
    const product = {
      feeNdTaxUuid
    }
    const requestBody = fp.omitBy(fp.isUndefined)(product)
    const [request] = this.api.put(`product/${uuid}/feeNdTax`, requestBody)
    const result = await request
    return result
  }

  putProductBookingInfo = async (input) => {
    const { uuid, bookingInfoUuid } = input
    const product = {
      bookingInfoUuid
    }
    const requestBody = fp.omitBy(fp.isUndefined)(product)
    const [request] = this.api.put(`product/${uuid}/bookingInfo`, requestBody)
    const result = await request
    return result
  }

  putProductCancelPolicy = async (input) => {
    const { uuid, cancelPolicyUuid } = input
    const product = {
      cancelPolicyUuid
    }
    const requestBody = fp.omitBy(fp.isUndefined)(product)
    const [request] = this.api.put(`product/${uuid}/cancelPolicy`, requestBody)
    const result = await request
    return result
  }

  putProductPickup = async ({ uuid, pickupSetting }) => {
    const requestBody = fp.omitBy(fp.isUndefined)({ pickupSetting })
    const [request] = this.api.put(`product/${uuid}/pickup`, requestBody)
    const result = await request
    return result
  }

  getSnapshot = () => {
    const pickingKeys = _(this)
      .map((value, key) => {
        return isObservableProp(this, key) ? key : null
      })
      .compact()
      .value()
    return _.pick(toJS(this), pickingKeys)
  }

  bookingInfos = {
    list: [],
    fields: []
  }

  bookingInfo = {}

  customedBookingInfo = {}

  loadBookingField = async () => {
    const [request] = this.api.get('bookingInfo/field')
    const result = await request
    this.bookingInfos.fields = result.data
  }

  loadBookingInfoList = async ({ page = 1, label = '', productUuid }) => {
    const [request] = this.api.get(`bookingInfo/${page}`, { params: { label, productUuid } })
    const result = await request
    this.bookingInfos = { ...this.bookingInfos, ...result.data }
  }

  loadBookingInfo = async (uuid) => {
    if (_.isEmpty(uuid)) {
      return
    }

    const localCache = this.bookingInfos.list.find((e) => e.uuid === uuid)
    if (localCache) {
      this.bookingInfo = localCache
    }

    const [request] = this.api.get(`bookingInfo/${uuid}`)
    const result = await request
    this.bookingInfo = result.data
  }

  insertBookingInfo = async (bookingInfo) => {
    const requestBody = fp.omitBy(fp.isUndefined)(bookingInfo)
    const [request] = this.api.post('bookingInfo', requestBody)
    const result = await request
    bookingInfo.uuid = result.data.uuid
    this.bookingInfos.list.unshift(bookingInfo)
    return bookingInfo.uuid
  }

  updateBookingInfo = async ({ uuid, bookingInfo }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(bookingInfo)
    const [request] = this.api.put(`bookingInfo/${uuid}`, requestBody)
    await request
    bookingInfo.uuid = uuid

    for (const x in this.bookingInfos.list) {
      if (uuid === this.bookingInfos.list[x].uuid) {
        this.bookingInfos.list[x] = bookingInfo
      }
    }
  }

  deleteBookingInfo = async (uuid) => {
    const [request] = this.api.delete(`bookingInfo/${uuid}`)
    await request
    this.bookingInfos.list = this.bookingInfos.list.filter((e) => e.uuid !== uuid)
  }

  insertCustomedBookingInfo = async (params) => {
    const [request] = this.api.post('/bookingInfo/customField', params)
    const result = await request
    const defaultConfig = _.omit(_.last(_.get(this.bookingInfos, 'fields.custom')), [
      'uuid',
      'label',
      'type',
      'bindingProductCnt'
    ])
    this.bookingInfos.fields.custom = [
      { ...defaultConfig, uuid: _.get(result, 'data.bookingInfoFieldUuid'), ...params },
      ...this.bookingInfos.fields.custom
    ]
  }

  loadCustomedBookingInfoList = async (filters = {}) => {
    const { label = '', page = 1 } = filters
    const [request] = this.api.get(`/bookingInfo/customField/${page}?label=${label}&num=999`)
    const result = await request

    const customedField = _.keyBy(_.get(this.bookingInfos, 'fields.custom'), 'uuid')
    const defaultConfig = _.omit(_.last(_.get(this.bookingInfos, 'fields.custom')), [
      'uuid',
      'label',
      'type',
      'bindingProductCnt'
    ])
    this.bookingInfos.fields = {
      ...this.bookingInfos.fields,
      custom: _.map(result.data.list, (eachCustom) => {
        return {
          ...defaultConfig,
          ..._.get(customedField, eachCustom.uuid, {}),
          ..._.omit(eachCustom, ['fieldType', 'config']),
          type: eachCustom.fieldType,
          ...(eachCustom.config ? { fieldConfig: eachCustom.config } : {})
        }
      })
    }
  }

  loadCustomedBookingInfo = async (uuid) => {
    const [request] = this.api.get(`/bookingInfo/customField/${uuid}`)
    const result = await request
    this.customedBookingInfo = result.data
  }

  updateCustomedBookingInfo = async (uuid, params) => {
    const [request] = this.api.put(`/bookingInfo/customField/${uuid}`, params)
    await request
    const { type, label, config } = params
    this.bookingInfos.fields.custom = _.map(this.bookingInfos.fields.custom, (item) =>
      item.uuid === uuid
        ? {
            ...item,
            type,
            fieldConfig: config,
            label
          }
        : item
    )
  }

  deleteCustomedBookingInfo = async (uuid) => {
    const [request] = this.api.delete(`/bookingInfo/customField/${uuid}`)
    await request
    this.bookingInfos.fields = {
      ...this.bookingInfos.fields,
      custom: _.filter(_.get(this.bookingInfos.fields, 'custom', []), (item) => item !== uuid)
    }
  }

  payTypeMap = {}

  loadPayTypeMap = async (filter) => {
    const [request] = this.api.get('/order/payTypeMap')
    const result = await request
    this.payTypeMap = result.data
  }

  orderCalendar = {}
  latestOrderCalendarFrom = null

  loadOrderCalendar = async ({ page = 1, forceRefresh = false, ...params }) => {
    const [request] = this.api.get(`order/calendar/${page}`, params)
    const result = await request
    const getOrdersExcludeNST = (orders) =>
      _.filter(orders, (order) => order.productCategoryUuid !== ProductCategories.NonSessionProduct)

    this.orderCalendar = {
      ...this.orderCalendar,
      [`${params.params.from}_${params.params.to}`]: {
        ...result.data,
        list: [
          ...(!forceRefresh
            ? this.orderCalendar[`${params.params.from}_${params.params.to}`]?.list ?? []
            : []),
          ...getOrdersExcludeNST(result.data.list)
        ]
      }
    }
    return this.orderCalendar
  }

  loadTotalOrderCalendar = async (payload) => {
    const { page, forceRefresh, ...params } = payload
    const result = await this.loadOrderCalendar(payload)
    const requestPeriod = `${params.params.from}_${params.params.to}`
    const targetData = result[requestPeriod]
    let list = targetData.list

    if (targetData.totalCount > targetData.itemPerPage * page) {
      const restOrdersRequests = range(
        2,
        Math.ceil(targetData.totalCount / targetData.itemPerPage) + 1
      ).map((eachPage) => this.loadOrderCalendar({ ...params, page: eachPage }))
      const restOrders = await Promise.all(restOrdersRequests)
      list = restOrders.flatMap(path([requestPeriod, 'list']))
    }
    return list
  }

  clearOrderCalendar = () => {
    this.orderCalendar = {}
    this.latestOrderCalendarFrom = null
    return this.orderCalendar
  }

  redeemAccountList = []

  loadRedeemAccountList = async () => {
    const [request] = this.api.get('/account/redeemAccountList')
    const result = await request
    this.redeemAccountList = result.data?.list
  }

  store = {
    basic: {},
    regional: {},
    payment: {},
    receipt: {},
    dayoff: {},
    notificationMailType: {}
  }

  voucherTemplateUrl = ''

  loadVoucherTemplateUrl = async () => {
    const [request] = this.api.get('store/voucherEditor')
    const result = await request
    this.voucherTemplateUrl = result.data.url
  }

  loadStoreBasic = async (filter) => {
    const [request] = this.api.get('store/basic')
    const result = await request
    this.store.basic = result.data
  }

  setStoreBasic = (basic) => {
    this.store.basic = basic
  }

  loadStoreRegional = async (filter) => {
    if (this.store.regionalLoading) {
      return
    }
    this.store.regionalLoading = true
    const [request] = this.api.get('store/regional')
    const result = await request
    this.store.regional = result.data
    this.store.regionalLoading = false
  }

  loadStorePayment = async (onlinePaymentEnabledPayType, filter) => {
    const [request] = this.api.get('store/paymentData')
    const result = await request

    result.data.paymentRule.sort(
      (a, b) => gatewayOrderList.indexOf(a.gateway) - gatewayOrderList.indexOf(b.gateway)
    )
    result.data.acceptPayType = onlinePaymentEnabledPayType | result.data.acceptPayType
    this.store.payment = result.data
  }

  loadStoreReceipt = async () => {
    const [request] = this.api.get('store/receiptSetting')
    const result = await request
    this.store.receipt = result.data
  }

  loadStoreDayoff = async (filter) => {
    const [request] = this.api.get('store/dayoff')
    const result = await request
    this.store.dayoff = result.data
  }

  loadStoreLegal = async (filter) => {
    const [request] = this.api.get('store/legal')
    const result = await request
    this.store.legal = result.data
  }

  loadStoreNotificationMailType = async (filter) => {
    const [request] = this.api.get('store/notificationMailType')
    const result = await request
    this.store.notificationMailType = result.data
  }

  updateStoreBasic = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.put('store/basic', requestBody)
    const result = await request
    return result
  }

  updateStoreRegional = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.put('store/regional', requestBody)
    const result = await request
    return result
  }

  updateStorePayment = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.put('store/paymentData', requestBody)
    const result = await request
    return result
  }

  updateStoreReceipt = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.put('store/receiptSetting', requestBody)
    const result = await request
    return result
  }

  updateStoreNotificationMailType = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.put('store/notificationMailType', requestBody)
    const result = await request
    return result
  }

  createMerchant = async (gateway) => {
    const [request] = this.api.get(`store/createMerchant/${gateway}`)
    const result = await request
    return result
  }

  revokeMerchant = async (params) => {
    const [request] = this.api.post('store/deauthMerchant', params)
    const result = await request
    return result
  }

  sendEmailToRazio = async (type, message) => {
    let path
    switch (type) {
      case 'receipt':
        path = 'receiptContactRezioBD'
        break
      case 'payment':
      default:
        path = 'paymentContactRezioBD'
        break
    }
    const [request] = this.api.get(`/mail/${path}?message=${message}`)
    const result = await request
    return result
  }

  sendPlanChangeNoticeEmailToRezio = async (params) => {
    const { message = '', newPlanTier, languageUuid } = params
    const [request] = this.api.get(
      `/mail/planChangeNotice?newPlanTier=${newPlanTier}&languageUuid=${languageUuid}&message=${message}`
    )
    const result = await request
    return result
  }

  updateStoreLegal = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.put('store/legal', requestBody)
    const result = await request
    return result
  }

  // About Tag
  tagTypeList = {}

  loadTagList = async (text = '', refreshTagTypeList = true) => {
    const [request] = this.api.get(_.isEmpty(text) ? 'tag' : `tag?text=${text}`)
    const result = await request
    if (refreshTagTypeList) {
      this.tagTypeList = result.data
    }
    return result.data
  }

  putTagData = async (data = {}) => {
    const { productId, ...requestBody } = data
    const [request] = this.api.put(`product/${productId}/tag`, requestBody)
    const result = await request
    return result
  }

  updateCustomTag = async (input) => {
    const [request] = this.api.post('tag', input)
    const result = await request
    return result
  }

  redeemRecords = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  redeemLog = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20,
    totalPage: 0
  }

  loadRedeemRecords = async ({ page = 1, ...rest }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(rest)
    const [request] = this.api.get(`report/redeemRecord/preview/${page}`, { params: requestBody })
    const result = await request
    this.redeemRecords = result.data
  }

  clearRedeemLog = () => {
    this.redeemLog = {
      list: [],
      currentPage: 1,
      totalCount: 0,
      itemPerPage: 20,
      totalPage: 0
    }
    return this.redeemLog
  }

  loadRedeemLog = async ({ page = 1, sort = 'redeemDESC', ...rest }) => {
    const requestBody = fp.omitBy(fp.isUndefined)({ sort, ...rest })
    const [request] = this.api.get(`voucher/redeemLog/${page}`, { params: requestBody })
    const result = await request
    this.redeemLog = result.data
  }

  realNameReport = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  loadRealNameReport = async ({ page = 1, ...rest }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(rest)
    const [request] = this.api.get(`report/realName/preview/${page}`, { params: requestBody })
    const result = await request
    this.realNameReport = result.data
  }

  ticketReport = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  loadTicketReport = async ({ page = 1, ...rest }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(rest)
    const [request] = this.api.get(`report/ticket/preview/${page}`, { params: requestBody })
    const result = await request
    this.ticketReport = result.data
  }

  // About Pickup
  pickups = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  pickup = {}

  loadPickup = async (uuid) => {
    if (_.isEmpty(uuid)) {
      return
    }

    const localCache = this.pickups.list.find((e) => e.uuid === uuid)
    if (localCache) {
      this.pickup = localCache
    }

    const [request] = this.api.get(`pickup/${uuid}`)
    const result = await request
    this.pickup = result.data
  }

  loadPickups = async ({ page = 1, label, type, timeBeforeSession, productUuid }) => {
    const [request] = this.api.get(`pickup/${page}`, {
      params: { label, type, bs: timeBeforeSession, productUuid }
    })
    const result = await request
    this.pickups = result.data
  }

  insertPickup = async (pickup) => {
    const requestBody = fp.omitBy(fp.isUndefined)(pickup)
    const [request] = this.api.post('pickup', requestBody)
    const result = await request
    return result
  }

  updatePickup = async (pickup) => {
    const requestBody = fp.omitBy(fp.isUndefined)(pickup)
    const [request] = this.api.put(`pickup/${pickup.uuid}`, requestBody)
    const result = await request
    return result
  }

  deletePickup = async (uuid) => {
    const [request] = this.api.delete(`pickup/${uuid}`)
    await request
    this.pickups.list = this.pickups.list.filter((e) => e.uuid !== uuid)
  }

  // About Price Policy
  pricePolicies = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  pricePolicy = {}

  loadPricePolicy = async (uuid) => {
    if (_.isEmpty(uuid)) {
      return
    }

    const localCache = this.pricePolicies.list.find((e) => e.uuid === uuid)
    if (localCache) {
      this.pricePolicy = localCache
    }

    const [request] = this.api.get(`pricePolicy/${uuid}`)
    const result = await request
    this.pricePolicy = result.data
  }

  loadPricePolicies = async ({ page = 1, label, currency, type, productUuid, usageType }) => {
    const requestBody = fp.omitBy(fp.isUndefined)({ label, currency, type, productUuid, usageType })
    const [request] = this.api.get(`pricePolicy/${page}`, { params: requestBody })
    const result = await request
    this.pricePolicies = result.data
  }

  insertPricePolicy = async (pricePolicy) => {
    try {
      const requestBody = fp.omitBy(fp.isUndefined)(pricePolicy)
      const [request] = this.api.post('pricePolicy', requestBody)
      const result = await request
      return result.data
    } catch (error) {
      if (error.code === APIErrorCode.Frontend) {
        throw error
      }
    }
  }

  updatePricePolicy = async (pricePolicy) => {
    try {
      const requestBody = fp.omitBy(fp.isUndefined)(pricePolicy)
      const [request] = this.api.put(`pricePolicy/${pricePolicy.uuid}`, requestBody)
      await request
    } catch (error) {
      if (error.code === APIErrorCode.Frontend) {
        throw error
      }
    }
  }

  deletePricePolicy = async (uuid) => {
    const [request] = this.api.delete(`pricePolicy/${uuid}`)
    await request
    this.pricePolicies.list = this.pricePolicies.list.filter((e) => e.uuid !== uuid)
  }

  // About Fee and Tax
  feeNdTaxes = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  feeNdTax = {}

  loadFeeNdTax = async (uuid) => {
    if (_.isEmpty(uuid)) {
      return
    }

    const localCache = this.feeNdTaxes.list.find((e) => e.uuid === uuid)
    if (localCache) {
      this.feeNdTax = localCache
    }

    const [request] = this.api.get(`feeNdTax/${uuid}`)
    const result = await request
    this.feeNdTax = result.data
  }

  loadFeeNdTaxes = async ({ page = 1, label, type, currency, productUuid }) => {
    const [request] = this.api.get(`feeNdTax/${page}`, {
      params: { label, type, currency, productUuid }
    })
    const result = await request
    this.feeNdTaxes = result.data
  }

  insertFeeNdTax = async (feeNdTax) => {
    try {
      const requestBody = fp.omitBy(fp.isUndefined)(feeNdTax)
      const [request] = this.api.post('feeNdTax', requestBody)
      await request
    } catch (e) {
      debug(e)
    }
  }

  updateFeeNdTax = async (feeNdTax) => {
    const requestBody = fp.omitBy(fp.isUndefined)(feeNdTax)
    const [request] = this.api.put(`feeNdTax/${feeNdTax.uuid}`, requestBody)
    const result = await request
    this.feeNdTax = result.data
  }

  deleteFeeNdTax = async (uuid) => {
    const [request] = this.api.delete(`feeNdTax/${uuid}`)
    await request
    this.feeNdTaxes.list = this.feeNdTaxes.list.filter((e) => e.uuid !== uuid)
  }

  depositPolicies = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  depositPolicy = {}

  loadDepositPolicy = async (uuid) => {
    if (_.isEmpty(uuid)) {
      return
    }

    const localCache = this.depositPolicies.list.find((e) => e.uuid === uuid)
    if (localCache) {
      this.depositPolicy = localCache
    }

    const [request] = this.api.get(`depositPolicy/${uuid}`)
    const result = await request
    this.depositPolicy = result.data
  }

  loadDepositPolicies = async ({ page = 1, label, currency, productUuid }) => {
    const [request] = this.api.get(`depositPolicy/${page}`, {
      params: { label, currency, productUuid }
    })
    const result = await request
    this.depositPolicies = result.data
  }

  insertDepositPolicy = async (depositPolicy) => {
    const requestBody = fp.omitBy(fp.isUndefined)(depositPolicy)
    const [request] = this.api.post('depositPolicy', requestBody)
    const result = await request
    this.depositPolicies.list = [
      {
        uuid: result.data.uuid,
        label: requestBody.label,
        currencyUuid: requestBody.currencyUuid,
        policyType: parseInt(requestBody.policyType),
        handlingCharge: parseInt(requestBody.handlingCharge),
        allowFullAmountPay: +requestBody.allowFullAmountPay,
        allowMinAlarm: +requestBody.allowMinAlarm,
        dueDays: parseInt(requestBody.dueDays),
        bindingProductCnt: 0
      },
      ...this.depositPolicies.list
    ]
  }

  updateDepositPolicy = async (depositPolicy) => {
    const requestBody = fp.omitBy(fp.isUndefined)(depositPolicy)
    const [request] = this.api.put(`depositPolicy/${depositPolicy.uuid}`, requestBody)
    await request
  }

  deleteDepositPolicy = async (uuid) => {
    const [request] = this.api.delete(`depositPolicy/${uuid}`)
    await request
    this.depositPolicies.list = this.depositPolicies.list.filter((e) => e.uuid !== uuid)
  }

  vendorList = {
    list: [],
    totalCount: 0,
    itemPerPage: 20,
    currentPage: 1,
    filter: {
      text: ''
    }
  }

  vendor = {}

  loadVendorList = async ({ page = 1, num, text, productUuid }) => {
    const [request] = this.api.get(`/vendor/${page}`, { params: { num, text, productUuid } })
    const result = await request
    this.vendorList = result.data
  }

  loadVendor = async (uuid) => {
    const [request] = this.api.get(`/vendor/${uuid}`)
    const result = await request
    this.vendor = result.data
  }

  postVendor = async (vendor) => {
    const requestBody = fp.omitBy(fp.isUndefined)(vendor)
    const [request] = this.api.post('/vendor', requestBody)
    await request
  }

  updateVendor = async ({ uuid, value }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(value)
    const [request] = this.api.put(`/vendor/${uuid}`, requestBody)
    await request
  }

  deleteVendor = async (uuid) => {
    const [request] = this.api.delete(`/vendor/${uuid}`)
    await request
    this.vendorList.list = this.vendorList.list.filter((e) => e.uuid !== uuid)
  }

  // voucherTemplateList
  voucherTemplateList = []

  loadVoucherTemplateList = async () => {
    const [request] = this.api.get('store/voucherTemplateList/1', { params: { num: 99999 } })
    const result = await request
    this.voucherTemplateList = result.data.list
  }

  // translations
  translations = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  translationData = {}

  loadTranslations = async ({ page = 1, num, text, type }) => {
    const [request] = this.api.get(`translation/${page}`, { params: { num, text, type } })
    const result = await request
    this.translations = result.data
  }

  loadTranslationData = async ({ translationType, productId }) => {
    const [request] = this.api.get(
      `/translation/${translationType}${translationType === 'product' ? `/${productId}` : ''}`
    )
    const result = await request
    this.translationData = result.data
  }

  // Cancel Policy

  cancelPolicies = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  cancelPolicy = {}

  loadCancelPolicy = async (uuid) => {
    if (_.isEmpty(uuid)) {
      return
    }

    const localCache = this.cancelPolicies.list.find((e) => e.uuid === uuid)
    if (localCache) {
      this.cancelPolicy = localCache
    }

    const [request] = this.api.get(`cancelPolicy/${uuid}`)
    const result = await request
    this.cancelPolicy = result.data
  }

  loadCancelPolicies = async ({ page = 1, label, currency, productUuid, usageType }) => {
    const [request] = this.api.get(`cancelPolicy/${page}`, {
      params: { label, currency, productUuid, usageType }
    })
    const result = await request
    this.cancelPolicies = result.data
  }

  insertCancelPolicy = async (cancelPolicy) => {
    const requestBody = fp.omitBy(fp.isUndefined)(cancelPolicy)
    const [request] = this.api.post('cancelPolicy', requestBody)
    await request
  }

  updateCancelPolicy = async (cancelPolicy) => {
    const requestBody = fp.omitBy(fp.isUndefined)(cancelPolicy)
    const [request] = this.api.put(`cancelPolicy/${cancelPolicy.uuid}`, requestBody)
    await request
  }

  deleteCancelPolicy = async (uuid) => {
    const [request] = this.api.delete(`cancelPolicy/${uuid}`)
    await request
    this.cancelPolicies.list = this.cancelPolicies.list.filter((e) => e.uuid !== uuid)
  }
  // Cancel Policy

  account = {
    profile: {},
    accountDetail: {},
    permissionGroup: [],
    assignablePermissionGroup: []
  }

  loadAccountProfile = async (loadDashboard) => {
    const [profileResult, dashboardResult] = await Promise.all([
      this.api.get('account/profile')[0],
      loadDashboard
        ? this.api.get('store/dashboard/v2?option=1')[0].catch(console.error)
        : undefined
    ])
    this.account.profile.account = {
      ...profileResult.data.account,
      dashboardCards: dashboardResult?.data?.map((card) => card.type) ?? []
    }
  }

  putAccountProfile = async (profile, dashboardCards) => {
    await Promise.all([
      this.api.put('account/profile', fp.omitBy(fp.isUndefined)(profile))[0],
      ...(dashboardCards
        ? [this.api.put('account/dashboard', { cardKeys: dashboardCards })[0]]
        : [])
    ])
  }

  loadAccountPermissionGroup = async () => {
    const [request] = this.api.get('store/permissionGroup')
    const result = await request
    this.account.permissionGroup = result.data
    this.account.assignablePermissionGroup = result.data.filter(
      (permission) => permission.uuid !== PERMISSION_GROUP_UUID.REZIO_EXPERT
    )
  }

  putAccountChangePwd = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.post('account/changePwd', requestBody)
    const result = await request
    return result
  }

  postAccountInvite = async (input) => {
    const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.post('/account/invite', requestBody)
    const result = await request
    return result
  }

  loadAccountDetail = async (uuid) => {
    const [request] = this.api.get(`account/detail/${uuid}`)
    const result = await request
    this.account.accountDetail = result.data
  }

  putAccountDetail = async (input) => {
    const data = {
      note: input.note,
      title: input.title,
      permissionGroupUuid: input.permissionGroupUuid
    }

    const requestBody = fp.omitBy(fp.isUndefined)(data)
    const [request] = this.api.put(`/account/detail/${input.uuid}`, requestBody)
    const result = await request
    return result
  }

  deleteUnbind = async (input) => {
    // const requestBody = fp.omitBy(fp.isUndefined)(input)
    const [request] = this.api.delete(`account/unbind/${input.accountUuid}`)
    const result = await request
    return result
  }

  bindingProductList = []

  loadBindingProducts = async (productUuids, bindingMap) => {
    const [request] = this.api.post('product/list', _.set({}, 'productUuids', productUuids))
    const result = await request

    this.bindingProductList = result?.data.list.map((product) => {
      const bindingSalesOption = product.salesOptions.filter(
        (salesOption) =>
          bindingMap?.[product.productUuid]?.includes(salesOption.salesOptionUuid) ?? salesOption
      )

      return {
        ...product,
        salesOptions: bindingSalesOption
      }
    })
  }

  pricingSettings = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  loadPricingSettings = async (input) => {
    const { page = 1, param = {} } = input
    const requestBody = fp.omitBy(fp.isUndefined)(param)
    const queryString = Object.keys(requestBody)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(requestBody[k]))
      .join('&')
    const [request] = this.api.get(`/store/pricePolicy/${page}?${queryString}`)
    const result = await request
    this.pricingSettings = result.data
  }

  putPricingSettings = async ({ productId, value }) => {
    const requestBody = fp.omitBy(fp.isUndefined)(value)
    const [request] = this.api.put(`publish/${productId}/pricePolicy`, requestBody)
    const result = await request
    return result
  }

  loadSalesOptionSessionSetting = async (productUuid, salesOptionUuid) => {
    const [request] = this.api.get(
      `/product/${productUuid}/salesOption/${salesOptionUuid}/sessionSetting`
    )
    const result = await request
    return result.data
  }

  // About Account List
  accountLists = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  accountList = {}

  loadaccountList = async (uuid) => {
    if (_.isEmpty(uuid)) {
      return
    }

    const localCache = this.accountLists.list.find((e) => e.uuid === uuid)
    if (localCache) {
      this.accountList = localCache
    }

    const [request] = this.api.get(`account/${uuid}`)
    const result = await request
    this.accountList = result.data
  }

  loadAccountLists = async ({ page = 1, ...rest }) => {
    const [request] = this.api.get(`account/${page}`, { params: rest })
    const result = await request
    this.accountLists = result.data
  }

  // API token
  apiToken = ''

  actionHistory = []

  fetchApiToken = async () => {
    const [request] = this.api.get('/store/apiToken')
    const result = await request
    this.apiToken = result.data.apiToken
  }

  fetchActionHistory = async () => {
    const [request] = this.api.get('/store/actionHistory')
    const result = await request
    this.actionHistory = result.data
  }

  resetApiToken = async () => {
    const [request] = this.api.put('/store/apiToken')
    const result = await request
    result && this.fetchActionHistory()
    this.apiToken = result.data.apiToken
  }

  travelerReportList = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  loadTravelerReportList = async ({ page = 1, ...params }) => {
    const [request] = this.api.get(`/report/traveler/preview/${page}`, { params })
    const result = await request
    this.travelerReportList = result.data
  }

  salesReportList = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  loadSalesReportList = async ({ page = 1, ...params }) => {
    const [request] = this.api.get(`/report/sales/preview/${page}`, { params })
    const result = await request
    this.salesReportList = result.data
  }

  accountStatementFilterOption = {
    period: [],
    rate: []
  }

  accountStatementList = {
    list: [],
    currentPage: 1,
    totalCount: 0,
    itemPerPage: 20
  }

  loadAccountStatementFilterOption = async () => {
    const [request] = this.api.get('/report/accountStatement/option')
    const result = await request
    this.accountStatementFilterOption = result.data
  }

  loadAccountStatementList = async ({ page = 1, ...params }) => {
    const [request] = this.api.get(`/report/accountStatement/preview/${page}`, { params })
    const result = await request
    this.accountStatementList = result.data
  }

  orderTagList = []

  orderTag = {}

  loadOrderTagList = async () => {
    const [request] = this.api.get('/orderTag')
    const result = await request
    this.orderTagList = _.sortBy(result.data, ['sort', 'createdAt'])
  }

  fetchOrderTag = async (orderTagUuid) => {
    const [request] = this.api.get(`/orderTag/${orderTagUuid}`)
    const result = await request
    this.orderTag = result.data
  }

  postOrderTag = async (params = {}) => {
    const [request] = this.api.post('/orderTag', params)
    const result = await request
    return result
  }

  updateOrderTag = async ({ uuid: orderTagUuid, ...params }) => {
    const [request] = this.api.put(`/orderTag/${orderTagUuid}`, params)
    const result = await request
    if (result.errorCode === APIErrorCode.Success) {
      this.orderTagList = _.map(this.orderTagList, (eachOrderTag) =>
        eachOrderTag.uuid === orderTagUuid ? { ...eachOrderTag, ...params } : eachOrderTag
      )
    }
    return result
  }

  devicePayWay = {}

  loadDevicePayWay = async () => {
    const [request] = this.api.get('/device/payways')
    const result = await request
    this.devicePayWay = { ...result.data, custom: [...result.data.custom, DeviceCustomPayWay] }
  }

  postDeviceCustomPayWay = async (customPayWay) => {
    const [request] = this.api.post('/device/payways', { customPayways: customPayWay })
    const result = await request
    if (result.errorCode === APIErrorCode.Success) {
      const newCustomPayWay = Object.keys(result.data).map((key) => {
        return {
          uuid: key,
          label: result.data[key]
        }
      })

      this.devicePayWay = {
        ...this.devicePayWay,
        custom: [...this.devicePayWay.custom, ...newCustomPayWay]
      }
    }
  }

  deviceSetting = {}

  loadDeviceSetting = async () => {
    const [request] = this.api.get('/device/deviceConfig')
    const result = await request
    const cloneData = _.cloneDeep(result.data)

    Object.keys(result.data).map((key) => {
      const deviceSetting = result.data[key]
      if (deviceSetting.devicePayWays) {
        // 非系統付款方式的 "現金" 選項由前端自行添加
        if (key.toLowerCase() === DeviceSettingTab.Pos && deviceSetting.devicePayWays) {
          deviceSetting.devicePayWays.push(DeviceCustomPayWay.label)
        }

        deviceSetting.devicePayWay = deviceSetting.devicePayWays.map((payWay) => {
          if (payWay.includes(DeviceCustomPayWayKey)) {
            return parserUuid(payWay)[0]
          }

          if (payWay === DeviceCustomPayWay.label) {
            return '1'
          }

          return payWay
        })
      }

      const deviceSettingKeys = Object.keys(deviceSetting)
      if (!_.isEmpty(deviceSettingKeys)) {
        cloneData[key] = deviceSettingKeys.reduce((accumulator, key) => {
          if (key !== 'devicePayWays') {
            accumulator[key] = deviceSetting[key]
          }
          return accumulator
        }, {})
      }

      return key
    })

    this.deviceSetting = cloneData
  }

  updateDeviceSetting = async ({ type, tagUuids, devicePayWay, languageUuids, ...params }) => {
    const [request] = this.api.put('/device/deviceConfig', {
      type,
      tagUuids,
      devicePayWays: devicePayWay.map((payWay) =>
        payWay === DeviceCustomPayWay.uuid ? DeviceCustomPayWay.label : payWay
      ),
      languageUuids,
      ...params
    })
    const result = await request
    result && this.loadDeviceSetting()
    return result
  }

  updatePrintTemplateSetting = async ({ headerType, title, logo, footer }) => {
    const [request] = this.api.put('/device/voucherSetting', {
      headerType,
      ...(logo && { logo }),
      ...(title && { title }),
      footer
    })
    const result = await request
    result && (await this.loadDeviceSetting())
    return result
  }

  updateInvoiceSetting = async ({ status }) => {
    const [request] = this.api.put('/device/invoice/switch', { status })
    const result = await request
    result && (await this.loadDeviceSetting())
    return result
  }
}

decorate(DataStore, {
  products: observable,
  location: observable,
  sessionData: observable,
  themeDesign: observable,
  translations: observable,
  translationData: observable,
  productOption: observable,
  productLabelList: observable,
  sessionSettingData: observable,
  acceptPayType: observable,
  salesOptions: observable,
  bookingInfos: observable,
  bookingInfo: observable,
  customedBookingInfo: observable,
  payTypeMap: observable,
  store: observable,
  tagTypeList: observable,
  voucherTemplateList: observable,
  realNameReport: observable,
  ticketReport: observable,
  redeemRecords: observable,
  pickups: observable,
  pickup: observable,
  pricePolicies: observable,
  pricePolicy: observable,
  feeNdTaxes: observable,
  feeNdTax: observable,
  depositPolicies: observable,
  depositPolicy: observable,
  cancelPolicies: observable,
  cancelPolicy: observable,
  vendorList: observable,
  vendor: observable,
  account: observable,
  sessionSettingHistory: observable,
  bindingProductList: observable,
  pricingSettings: observable,
  accountLists: observable,
  accountList: observable,
  actionHistory: observable,
  apiToken: observable,
  mysite: observable,
  invitationEmail: observable,
  feeIncludeOptions: observable,
  feeExcludeOptions: observable,
  filterFeeIncludeOptions: observable,
  filterFeeExcludeOptions: observable,
  landmarkLabels: observable,
  landmarkOptions: observable,
  orderCancelOptions: observable,
  travelerReportList: observable,
  salesReportList: observable,
  voucherTemplateUrl: observable,
  accountStatementFilterOption: observable,
  accountStatementList: observable,
  orderTagList: observable,
  orderTag: observable,
  setProducts: action('set_products'),
  loadProducts: action,
  putVoucherData: action,
  fetchProductOption: action,
  putPriceDetail: action,
  putLocationData: action,
  putGuideData: action,
  putSalesOptionData: action,
  putPublishData: action,
  putProductIntroData: action,
  putProductItineraryData: action,
  fetchCancelPolicy: action,
  fetchProduct: action,
  deleteProduct: action,
  archiveProduct: action,
  unarchiveProduct: action,
  duplicateProduct: action,
  unlockProduct: action,
  fetchProductMedia: action,
  fetchThemeDesign: action,
  putThemeDesign: action,
  checkSubDomain: action,
  fetchProductLabelList: action,
  fetchSessionData: action,
  fetchSessionSettingData: action,
  putSessionSetting: action,
  postSessionSetting: action,
  deleteSessionSetting: action,
  fetchAcceptPayType: action,
  clearSalesOption: action,
  clearSessionData: action,
  clearSessionSettingData: action,
  fetchSalesOption: action,
  fetchFormatSalesOption: action,
  fetchSessionSettingHistory: action,
  postNewProduct: action,
  putProductBasic: action,
  putProductDeposit: action,
  putProductFeeNdTax: action,
  putProductBookingInfo: action,
  putProductCancelPolicy: action,
  putProductPickup: action,
  getSnapshot: action,
  loadBookingField: action,
  loadBookingInfoList: action,
  loadBookingInfo: action,
  insertBookingInfo: action,
  updateBookingInfo: action,
  deleteBookingInfo: action,
  insertCustomedBookingInfo: action,
  loadCustomedBookingInfoList: action,
  loadCustomedBookingInfo: action,
  updateCustomedBookingInfo: action,
  deleteCustomedBookingInfo: action,
  loadPayTypeMap: action,
  loadRedeemAccountList: action,
  loadStoreBasic: action,
  setStoreBasic: action,
  loadStoreRegional: action,
  loadStorePayment: action,
  loadStoreReceipt: action,
  loadStoreDayoff: action,
  loadStoreLegal: action,
  loadStoreNotificationMailType: action,
  updateStoreBasic: action,
  updateStoreRegional: action,
  updateStorePayment: action,
  updateStoreReceipt: action,
  updateStoreNotificationMailType: action,
  createMerchant: action,
  revokeMerchant: action,
  sendEmailToRazio: action,
  sendPlanChangeNoticeEmailToRezio: action,
  updateStoreLegal: action,
  loadTagList: action,
  putTagData: action,
  updateCustomTag: action,
  loadRealNameReport: action,
  loadTicketReport: action,
  loadRedeemRecords: action,
  clearRedeemLog: action,
  loadRedeemLog: action,
  loadPickup: action,
  loadPickups: action,
  insertPickup: action,
  updatePickup: action,
  deletePickup: action,
  loadPricePolicy: action,
  loadPricePolicies: action,
  insertPricePolicy: action,
  updatePricePolicy: action,
  deletePricePolicy: action,
  loadFeeNdTax: action,
  loadFeeNdTaxes: action,
  insertFeeNdTax: action,
  updateFeeNdTax: action,
  deleteFeeNdTax: action,
  loadDepositPolicy: action,
  loadDepositPolicies: action,
  insertDepositPolicy: action,
  updateDepositPolicy: action,
  deleteDepositPolicy: action,
  loadCancelPolicy: action,
  loadCancelPolicies: action,
  insertCancelPolicy: action,
  updateCancelPolicy: action,
  deleteCancelPolicy: action,
  loadVendorList: action,
  loadVendor: action,
  postVendor: action,
  updateVendor: action,
  deleteVendor: action,
  loadAccountProfile: action,
  putAccountProfile: action,
  loadAccountPermissionGroup: action,
  putAccountChangePwd: action,
  postAccountInvite: action,
  loadAccountDetail: action,
  putAccountDetail: action,
  deleteUnbind: action,
  loadBindingProducts: action,
  loadPricingSettings: action,
  putPricingSettings: action,
  loadSalesOptionSessionSetting: action,
  loadaccountList: action,
  loadAccountLists: action,
  fetchActionHistory: action,
  fetchApiToken: action,
  resetApiToken: action,
  fetchFeeOptions: action,
  fetchLandmarkOptions: action,
  fetchLandmarkLabel: action,
  fetchMysite: action,
  updateMysite: action,
  loadTranslations: action,
  loadTranslationData: action,
  checkInvitation: action,
  loadTravelerReportList: action,
  loadSalesReportList: action,
  loadVoucherTemplateList: action,
  loadVoucherTemplateUrl: action,
  loadAccountStatementFilterOption: action,
  loadAccountStatementList: action,
  loadOrderTagList: action,
  fetchOrderTag: action,
  postOrderTag: action,
  updateOrderTag: action,
  loadDevicePayWay: action,
  loadDeviceSetting: action,
  postDeviceCustomPayWay: action,
  updateDeviceSetting: action,
  updateInvoiceSetting: action
})
export default DataStore
