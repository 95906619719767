import { MaterialIcons } from '@expo/vector-icons'
import { useLayout } from '@rezio/core/hooks'
import { palette, manipulator } from '@rezio/res/theme'
import { sleep } from '@rezio/utils/sleep'
import React, { useImperativeHandle, useState, forwardRef, useCallback, useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { BlackPortal } from 'react-native-portal'
import { animated, useSpring } from 'react-spring/native'

import { FrameContext } from '../../frame'

export type Status = 'success' | 'warning'
export interface ToastRef {
  showToast?: (updatedText?: string, updatedTimeout?: number, status?: Status) => Promise<void>
}
interface ToastProps {
  text?: string
  timeout?: number
}

/**
 * @description this is a wrapper if {@link BlackPortal},have to use with {@link WhitePortal}
 * @example
 * ```ts
 * ! the name has to be matched
 * <WhitePortal name="myToast" />   // actual render output place
 * <ToastController text="smaple test" > // the controller it self
 * ```
 * @storybook toast.stories.tsx
 */

export const ToastController = forwardRef<ToastRef, ToastProps>(({ text, timeout = 1500 }, ref) => {
  const { isMobile } = useLayout()
  const { isInFrame } = useContext(FrameContext)
  const [currentText, setCurrentText] = useState(text)
  const [timeoutHide, setTimeoutHide] = useState(false)
  const [status, setStatus] = useState<Status>('success')
  const [toastWidth, setToastWidth] = useState(0)
  const { top } = useSpring({ top: timeoutHide ? (isMobile ? 60 : 30) : -130 })

  useImperativeHandle(
    ref,
    () => ({
      async showToast(updatedText, updatedTimeout, status = 'success') {
        updatedText && setCurrentText(updatedText)
        setStatus(status)
        setTimeoutHide(true)
        await sleep(updatedTimeout ?? timeout)
        setTimeoutHide(false)
      }
    }),
    [setTimeoutHide]
  )

  const handleOnLayout = useCallback(
    ({ nativeEvent }) => {
      const {
        layout: { width }
      } = nativeEvent
      width !== toastWidth && setToastWidth(width)
    },
    [toastWidth]
  )

  return (
    // @ts-expect-error // * related to react 18 FC type no longer contains implicit children
    <BlackPortal name={isInFrame ? 'frameToast' : 'toast'}>
      <AnimatedView
        onLayout={handleOnLayout}
        style={[
          {
            top,
            maxWidth: isMobile ? 320 : 'auto',
            transform: [{ translateX: -toastWidth / 2 }]
          },
          staticStyle.toastBackground,
          staticStyle[status],
          manipulator.container('row', 'center', 'center')
        ]}
      >
        <MaterialIcons
          name={status === 'success' ? 'check-circle' : 'warning'}
          color='palette.positive'
          size={24}
          style={[staticStyle.icon, staticStyle[status], { flexShrink: 0 }]}
        />
        <Text style={[staticStyle.text, staticStyle[status], { flexShrink: 1, maxWidth: '95%' }]}>
          {currentText}
        </Text>
      </AnimatedView>
    </BlackPortal>
  )
})

const AnimatedView = animated(View)

const staticStyle = StyleSheet.create({
  toastBackground: {
    backgroundColor: palette.positiveBg,
    borderRadius: 10,
    position: 'absolute',
    left: '50%',
    paddingHorizontal: 12,
    paddingVertical: 14,
    zIndex: 10
  },
  icon: {
    marginRight: 5
  },
  text: {
    fontSize: 18,
    fontWeight: '400'
  },
  success: {
    color: palette.positive,
    backgroundColor: palette.positiveBg
  },
  warning: {
    color: palette.warning,
    backgroundColor: palette.warningBg
  }
})
