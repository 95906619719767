import { MaterialIcons } from '@rezio/components'
import { getEnv } from '@rezio/core/config'
import { useStores } from '@rezio/core/hooks'
import { palette } from '@rezio/res/theme'
import Modal from '@rezio/unimodules/modal'
import { clsx } from 'clsx'
import _ from 'lodash'
import moment from 'moment'
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View, ScrollView, TextInput, Platform } from 'react-native'
import type { LayoutChangeEvent } from 'react-native'

import {
  LabelProps,
  DatePickerProps,
  DateHeaderProps,
  DateBodyProps,
  Mode,
  YearMonthSelectorProps,
  DateFooterProps
} from './datePicker.type'
import { generateCalendar, generateNewYearMonth } from './utils'

const Label: React.FC<LabelProps> = ({
  disabled,
  isMultiple,
  allowClear,
  defaultValue,
  onClearAll,
  onOpen,
  webId = 'date',
  onChangeInput
}) => {
  const editable = getEnv() !== 'prod' && Platform.OS === 'web'

  const labelClassName = clsx(
    'group',
    'p-4',
    'flex-row',
    'items-center',
    'justify-between',
    'rounded-1',
    'border-1',
    'border-gray-600',
    'overflow-hidden',
    disabled ? 'bg-gray-200' : 'bg-white'
  )

  const inputClassName = clsx('outline-0', defaultValue.length > 0 ? 'text-black' : 'text-gray-400')
  const placeholder =
    defaultValue.length > 0
      ? isMultiple
        ? defaultValue.join(', ')
        : defaultValue[0]
      : moment().format('YYYY-MM-DD')
  const clearAllBtnClassName = clsx(
    'absolute',
    'top-4',
    'right-4',
    'bg-white',
    'opacity-100',
    'md:opacity-0',
    'group-hover:md:opacity-100',
    (!allowClear || defaultValue.length === 0) && 'hidden'
  )

  return (
    <TouchableOpacity className={labelClassName} onPress={onOpen} disabled={disabled}>
      <View className='flex-row items-center'>
        <MaterialIcons className='mr-2' name='event' size={16} color={palette.icon} />
        <TextInput
          selectionColor='transparent'
          nativeID={webId}
          className={inputClassName}
          editable={editable}
          value={placeholder}
          onPressOut={onOpen}
          caretHidden
          showSoftInputOnFocus={false}
          onChangeText={onChangeInput}
        />
      </View>
      <View className='h-5 w-5' />
      <TouchableOpacity
        className={clearAllBtnClassName}
        onPress={onClearAll}
        disabled={!allowClear}
      >
        <MaterialIcons name='cancel' size={16} color={palette.icon} />
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const DateHeader: React.FC<DateHeaderProps> = ({
  onSetNewMonth,
  setMode,
  nowMonthIndex,
  nowYear
}) => {
  return (
    <View className='flex-row items-center justify-between'>
      <TouchableOpacity
        className='flex h-7 w-7 items-center justify-center'
        onPress={() => {
          onSetNewMonth(-1)
        }}
      >
        <MaterialIcons name='keyboard-arrow-left' size={24} color={palette.black} />
      </TouchableOpacity>
      <View>
        <TouchableOpacity
          className='h-6 flex-row items-center justify-center px-3'
          onPress={() => {
            setMode('year')
          }}
        >
          <Text className='font-bold text-primary'>{moment.months()[nowMonthIndex]}</Text>
          <View className='mx-4 h-6'>
            <Text className='border-transparent/0 font-bold leading-6 text-primary'>{nowYear}</Text>
          </View>
          <MaterialIcons name='arrow-drop-down' size={16} color={palette.brand} />
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        className='flex h-7 w-7 items-center justify-center'
        onPress={() => {
          onSetNewMonth(1)
        }}
      >
        <MaterialIcons name='keyboard-arrow-right' size={24} color={palette.black} />
      </TouchableOpacity>
    </View>
  )
}

const DateBody: React.FC<DateBodyProps> = ({
  dateDisabledRule,
  nowYear,
  nowMonthIndex,
  dates,
  onSelectDate
}) => {
  const { data, core } = useStores()
  const firstDayOfWeek: number = data?.store?.regional?.firstDayOfWeek || 0
  const calendarArray: string[][] = useMemo(() => {
    return generateCalendar(nowYear, nowMonthIndex, firstDayOfWeek)
  }, [nowYear, nowMonthIndex, firstDayOfWeek])

  const calendar = useMemo(() => {
    const now = moment().format('YYYY-MM-DD')
    return calendarArray.map((weeks, idx) => (
      <View key={`week${idx}`} className='mt-7 flex-row items-center justify-between'>
        {weeks.map((date) => {
          const isOutOfRange = moment(date).toDate().getMonth() !== nowMonthIndex
          const isDisabled = dateDisabledRule?.(date, dates) ?? false
          const isToday = date === now
          const isSelected = dates.includes(date)

          const buttonClassName = clsx(
            'w-6',
            'h-6',
            isToday && 'rounded-full bg-gray-400',
            isSelected && 'rounded-full bg-primary-600'
          )

          let conditionalTextCN = ''
          switch (true) {
            case isSelected:
              conditionalTextCN = 'text-white'
              break
            case isToday:
              conditionalTextCN = 'text-white'
              break
            case isDisabled:
              conditionalTextCN = 'text-gray-400'
              break
            case isOutOfRange:
              conditionalTextCN = 'text-gray-600'
              break
          }

          const textClassName = clsx('text-center', 'leading-6', conditionalTextCN)
          return (
            <TouchableOpacity
              key={date}
              className={buttonClassName}
              disabled={isDisabled}
              onPress={() => {
                onSelectDate(date)
              }}
            >
              <Text className={textClassName}>{moment(date).toDate().getDate()}</Text>
            </TouchableOpacity>
          )
        })}
      </View>
    ))
  }, [calendarArray, dateDisabledRule, dates, nowMonthIndex, onSelectDate])

  const weekdays = useMemo(() => {
    const weekdays = core.lang === 'zh-TW' ? moment.weekdaysMin() : moment.weekdaysShort()
    return weekdays.map((val, index) => {
      const nowIndex = (firstDayOfWeek + index) % 7
      const isWeekend = nowIndex === 6 || nowIndex === 0
      const textClassName = clsx(
        'text-tip',
        '!font-bold',
        'text-center',
        isWeekend && 'text-primary'
      )
      return (
        <View key={nowIndex}>
          <Text className={textClassName}>{weekdays[nowIndex]}</Text>
        </View>
      )
    })
  }, [firstDayOfWeek, core?.lang])

  return (
    <View className='mt-6'>
      <View className='flex-row items-center justify-between'>{weekdays}</View>
      {calendar}
    </View>
  )
}

const YearMonthSelector: React.FC<YearMonthSelectorProps> = ({
  yearRange,
  nowYear,
  nowMonthIndex,
  onSelectYear,
  onSelectMonth,
  onSetMode
}) => {
  const { t } = useTranslation()
  const [mode, setMode] = useState<'month' | 'year'>('year')
  const [tempYear, setTempYear] = useState<number>(nowYear)
  const scrollViewRef = useRef(null)

  const yearList = _.range(yearRange[0], yearRange[1] + 1)

  const handleSelectMonth = useCallback(
    (monthIndex: number) => {
      onSelectMonth(monthIndex)
      onSelectYear(tempYear)
      onSetMode('date')
    },
    [onSelectMonth, onSelectYear, onSetMode, tempYear]
  )

  const handleSelectYear = useCallback((year: number) => {
    setTempYear(year)
    setMode('month')
  }, [])

  const months = useMemo(() => {
    return moment.months().map((monthStr, index) => {
      const isNow = index === nowMonthIndex
      const monthClassName = clsx(
        'w-10',
        'h-9',
        'flex',
        'justify-center',
        'items-center',
        isNow && 'rounded-full bg-primary'
      )
      const textClassName = clsx('text-h4', isNow && 'text-white')
      return (
        <View key={monthStr} className='mt-6 flex basis-1/3 items-center justify-center'>
          <TouchableOpacity className={monthClassName} onPress={() => handleSelectMonth(index)}>
            <Text className={textClassName}>{monthStr}</Text>
          </TouchableOpacity>
        </View>
      )
    })
  }, [nowMonthIndex, handleSelectMonth])

  const years = useMemo(() => {
    const scrollToCurrentYear = (e: LayoutChangeEvent, isNow: boolean) => {
      if (!isNow) return
      const targetY = e.nativeEvent.layout.y
      scrollViewRef.current.scrollTo({ y: targetY, animated: false })
    }

    return yearList.map((year: number) => {
      const isNow = year === tempYear
      const monthClassName = clsx(
        'w-10',
        'h-9',
        'flex',
        'justify-center',
        'items-center',
        isNow && 'rounded-full bg-primary'
      )
      const textClassName = clsx('text-h4', isNow && 'text-white')
      return (
        <View
          onLayout={(e) => scrollToCurrentYear(e, isNow)}
          key={year}
          className='mt-6 flex basis-1/3 items-center justify-center'
        >
          <TouchableOpacity className={monthClassName} onPress={() => handleSelectYear(year)}>
            <Text className={textClassName}>{year}</Text>
          </TouchableOpacity>
        </View>
      )
    })
  }, [tempYear, yearList])

  return (
    <View>
      <View className='flex shrink-0 grow-0 flex-row items-center justify-center'>
        <TouchableOpacity className='mr-6' onPress={() => setMode('year')}>
          <Text
            className={`border-b-2 pb-3 font-medium ${
              mode === 'year' ? 'border-primary text-primary' : 'border-transparent text-gray-600'
            }`}
          >
            {t('COMMON.YEARS', '年份')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setMode('month')}>
          <Text
            className={`border-b-2 pb-3 font-medium ${
              mode === 'month' ? 'border-primary text-primary' : 'border-transparent text-gray-600'
            }`}
          >
            {t('COMMON.MONTHS', '月份')}
          </Text>
        </TouchableOpacity>
      </View>
      <ScrollView ref={scrollViewRef} className='h-[280px] overflow-y-auto'>
        <View className='flex-row flex-wrap'>{mode === 'month' ? months : years}</View>
      </ScrollView>
    </View>
  )
}

const DateFooter: React.FC<DateFooterProps> = ({
  isMultiple,
  dates,
  setDates,
  allowClear,
  onConfirm,
  isRangeStart = false,
  isRangeEnd = false
}) => {
  const { t } = useTranslation()

  const handleClear = useCallback(
    (date: string) => {
      const dateSet = new Set(dates)
      dateSet.delete(date)
      setDates([...dateSet])
    },
    [dates, setDates]
  )

  const selected = useMemo(() => {
    const badgeClassName = clsx(
      'flex',
      'flex-row',
      'items-center',
      'px-3',
      'py-2',
      'bg-primary',
      'rounded-full'
    )
    const compareTs = (a: number, b: number) => {
      return a - b
    }
    const sortedDates: number[] = dates.map((date) => +moment(date).format('x')).sort(compareTs)
    const badges = sortedDates.map((ts) => {
      const date = moment(ts).format('YYYY-MM-DD')
      return (
        <TouchableOpacity
          key={date}
          className={badgeClassName}
          disabled={!allowClear}
          onPress={() => {
            handleClear(date)
          }}
        >
          {allowClear && (
            <MaterialIcons className='mr-2' name='close' size={16} color={palette.white} />
          )}
          <Text className='text-white text-body'>{`${isRangeEnd ? '~ ' : ''}${moment(date).format(
            'YYYY/MM/DD'
          )}${isRangeStart ? ' ~' : ''}`}</Text>
        </TouchableOpacity>
      )
    })
    return dates.length > 0 ? (
      <View>{badges}</View>
    ) : (
      <View className='rounded-full bg-lightRed px-3 py-2'>
        <Text className='text-red text-body'>{t('COMMON.NOT_SELECTED')}</Text>
      </View>
    )
  }, [dates, setDates, allowClear, isRangeStart, isRangeEnd])

  const wrapperClassName = clsx(
    'py-4',
    'px-5',
    'bg-gray-200',
    'flex',
    'items-center',
    isMultiple ? 'flex-col' : 'flex-row justify-between'
  )
  const isDisabled = !allowClear && dates.length === 0
  const buttonClassName = clsx(
    'py-3',
    'rounded-1',
    isDisabled ? 'bg-gray-400' : 'bg-primary-400 hover:bg-primary-200',
    isMultiple ? 'flex-1' : 'w-10'
  )

  return (
    <View className={wrapperClassName}>
      {selected}
      <TouchableOpacity className={buttonClassName} disabled={isDisabled} onPress={onConfirm}>
        <Text className='text-center text-white text-button-s'>{t('FORM.BUTTON_CONFIRM')}</Text>
      </TouchableOpacity>
    </View>
  )
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { t } = useTranslation()
  const {
    isMultiple = false,
    isBirthday = false,
    allowClear = true,
    value,
    dateDisabledRule,
    disabled,
    onChange,
    forceOpen = false,
    isRangeStart = false,
    isRangeEnd = false,
    yearRange = [2019, new Date().getFullYear() + 3], // 預設 2019 ~ 當年 + 3年
    webId = 'date',
    onClose
  } = props
  const defaultDates: string[] = useMemo(() => {
    return _.compact([].concat(value)).map((date) => moment(date).format('YYYY-MM-DD'))
  }, [value])
  const now = new Date()
  isBirthday && now.setFullYear(now.getFullYear() - 30) // 生日起始日預設為 30 年前
  const date = useMemo(() => {
    return defaultDates[0] ? moment(defaultDates[0]).toDate() : new Date()
  }, [defaultDates])

  const [visible, setVisible] = useState<boolean>(false)
  const [nowYear, setNowYear] = useState<number>(date.getFullYear())
  const [nowMonthIndex, setNowMonthIndex] = useState<number>(date.getMonth())
  const [dates, setDates] = useState<string[]>(defaultDates)
  const [mode, setMode] = useState<Mode>('date')

  const handleOpen = useCallback(() => {
    setNowYear(date.getFullYear())
    setNowMonthIndex(date.getMonth())
    setDates(defaultDates)
    setMode('date')
    setVisible(true)
  }, [date, defaultDates])

  useEffect(() => {
    setDates(defaultDates)
  }, [defaultDates])

  useEffect(() => {
    forceOpen && handleOpen()
  }, [forceOpen, handleOpen])

  const handleClose = useCallback(() => {
    setVisible(false)
    setNowYear(date.getFullYear())
    setNowMonthIndex(date.getMonth())
    setDates(defaultDates)
    setMode('date')
    onClose?.()
  }, [date, defaultDates, onClose])

  const handleSelectToday = useCallback(() => {
    const dateSet = new Set(dates)
    const now = new Date()
    if (!isMultiple) dateSet.clear()
    dateSet.add(moment(now).format('YYYY-MM-DD'))
    setNowYear(now.getFullYear())
    setNowMonthIndex(now.getMonth())
    setDates([...dateSet])
    setMode('date')
  }, [dates, isMultiple])

  const handleSetNewMonth = useCallback(
    (diff: 1 | -1) => {
      const [newYear, newMonthIndex] = generateNewYearMonth(nowYear, nowMonthIndex, diff)
      setNowYear(newYear)
      setNowMonthIndex(newMonthIndex)
    },
    [nowYear, nowMonthIndex]
  )

  const handleSelectDate = useCallback(
    (target: string) => {
      const dateSet = new Set(dates)

      if (dateSet.has(target)) {
        dateSet.delete(target)
      } else {
        !isMultiple && dateSet.clear()
        dateSet.add(target)
      }

      setDates([...dateSet])
      if (moment(target).toDate().getMonth() > nowMonthIndex) handleSetNewMonth(1)
      if (moment(target).toDate().getMonth() < nowMonthIndex) handleSetNewMonth(-1)
    },
    [dates, isMultiple, nowMonthIndex, handleSetNewMonth]
  )

  const handleConfirm = useCallback(() => {
    const value = _.isEmpty(dates) ? null : isMultiple ? dates : dates[0]
    onChange(value)
    setVisible(false)
  }, [dates, onChange, isMultiple])

  const handleClearAll = useCallback(() => {
    setDates([])
    onChange(null)
  }, [onChange])

  const isDisabledToday = useMemo(() => {
    return dateDisabledRule?.(moment().format('YYYY-MM-DD'), dates) ?? false
  }, [dateDisabledRule, dates])

  const handleChangeInput = useCallback(
    (value: string) => {
      getEnv() !== 'prod' && onChange(value)
    },
    [onChange]
  )

  return (
    <View className='flex-1'>
      <Label
        disabled={disabled}
        isMultiple={isMultiple}
        allowClear={allowClear}
        defaultValue={defaultDates}
        onOpen={handleOpen}
        onClearAll={handleClearAll}
        webId={webId}
        onChangeInput={handleChangeInput}
      />
      <Modal isVisible={visible} onBackdropPress={handleClose}>
        <View className='absolute left-1/2 top-1/2 translate-x-[-163.5px] translate-y-[-200px]'>
          <View className='w-[327px] overflow-hidden rounded-3 bg-white shadow'>
            <View className='flex-row items-center justify-between bg-primary p-5'>
              <TouchableOpacity className='shrink-0 flex-row items-center' onPress={handleClose}>
                <MaterialIcons name='close' size={16} color={palette.white} />
                <Text className='pl-2 text-white text-button-s'>{t('FORM.BUTTON_CLOSE')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                className='shrink-0 flex-row items-center'
                disabled={isDisabledToday}
                onPress={handleSelectToday}
              >
                <MaterialIcons name='event' size={16} color={palette.white} />
                <Text className='pl-2 text-white text-button-s'>
                  {t('CALENDAR.DATE_SELECTOR_TODAY')}
                </Text>
              </TouchableOpacity>
            </View>
            <View className='p-6'>
              {mode === 'date' ? (
                <>
                  <DateHeader
                    onSetNewMonth={handleSetNewMonth}
                    nowYear={nowYear}
                    nowMonthIndex={nowMonthIndex}
                    setMode={setMode}
                  />
                  <DateBody
                    dateDisabledRule={dateDisabledRule}
                    nowYear={nowYear}
                    nowMonthIndex={nowMonthIndex}
                    dates={dates}
                    onSelectDate={handleSelectDate}
                  />
                </>
              ) : (
                <YearMonthSelector
                  yearRange={yearRange}
                  nowYear={nowYear}
                  nowMonthIndex={nowMonthIndex}
                  onSelectYear={setNowYear}
                  onSelectMonth={setNowMonthIndex}
                  onSetMode={setMode}
                />
              )}
            </View>
            {mode === 'date' && (
              <DateFooter
                isMultiple={isMultiple}
                allowClear={allowClear}
                dates={dates}
                setDates={setDates}
                onConfirm={handleConfirm}
                isRangeStart={isRangeStart}
                isRangeEnd={isRangeEnd}
              />
            )}
          </View>
        </View>
      </Modal>
    </View>
  )
}
