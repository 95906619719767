import { i18n } from 'i18next'
import { map, modify } from 'ramda'
import markdownit from 'markdown-it'
import { parseFromString } from 'dom-parser'

export const convertCamelCaseToUnderscoreWithUppercase = (camelCaseString) =>
  camelCaseString
    .split(/(?=[A-Z])/)
    .join('_')
    .toUpperCase()

// 立榮假期，高鐵假期，環島之星
export const excludeUuids = [
  '76f1b60b-2553-483e-a056-fb2741c91824',
  '77d54e8c-c38e-41f6-a91b-b13739878b07',
  '2eafe628-a8a3-41f0-ac6f-8d12db18887b'
] as const

/**
 * Label Translator
 *
 * @param {Array<{label: string}>} source
 * @param {i18n['t']} t
 * @returns {Array<{label: string}>} source
 * @example translateLabel([{ label: 'myKey' }], toUpper) // [{ label: 'MYKEY' }]
 */
export const translateLabel = (
  source: { label: string; errorMessage: string }[],
  t: i18n['t'],
  translateKey: 'label' | 'errorMessage' = 'label'
) => map(modify(translateKey, t), source)

export const wrapStr = (head: string, tail?: string) => (input: string) =>
  input && head + input + (tail || head)

export const excludeMarkdownSyntax = (markdown: string) => {
  if (typeof markdown !== 'string') return markdown
  const md = markdownit()
  const htmlString = md.render(markdown)

  const dom = parseFromString(`<div id='root'>${htmlString}</div>`)
  const plainText = dom.getElementById('root').textContent.trim()
  return plainText
}
