import { Text, View } from 'react-native'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { palette } from '@rezio/res/theme'
import { excludeMarkdownSyntax } from '@rezio/utils/string'

interface MarkdownRestrictionProps {
  value: string
  isColumnDisplay: boolean
  restriction: {
    min?: number
    max: number
  }
}

const MarkdownRestriction = ({ value, isColumnDisplay, restriction }: MarkdownRestrictionProps) => {
  const { t } = useTranslation()

  const plainText = useMemo(() => excludeMarkdownSyntax(value), [value])
  const textCount = plainText ? [...plainText].length : 0
  const mdCount = value?.length ?? 0

  return (
    <View className='flex flex-row justify-between'>
      <Text
        style={{
          paddingTop: isColumnDisplay ? 0 : 4,
          paddingBottom: isColumnDisplay ? 15 : 0,
          color: palette.gray,
          fontSize: 14
        }}
      >
        {t('COMMON.INPUT_TEXT_LIMIT', {
          range: `${restriction?.min > 0 ? `${restriction?.min} ～ ` : ''}${restriction?.max}`
        })}
      </Text>
      {restriction?.max && (
        <Text className={mdCount > restriction.max ? 'text-red' : 'text-gray-600'}>
          {t('MARKDOWN.RESTRICTION_COUNT', {
            textCount,
            mdCount
          })}
        </Text>
      )}
    </View>
  )
}

export default MarkdownRestriction
