import { registerRootComponent } from 'expo'
import '@expo/metro-runtime'
import { NativeWindStyleSheet } from 'nativewind'

import App from './App'

NativeWindStyleSheet.setOutput({
  web: 'css',
  default: 'native'
})

registerRootComponent(App)
