import _ from 'lodash'
import { observer, inject } from 'mobx-react'
import React, { Component, useState, useCallback, useEffect } from 'react'
import {
  FlatList,
  Text,
  View,
  TextInput,
  TouchableWithoutFeedback,
  TouchableOpacity,
  UIManager
} from 'react-native'

import { MaterialIcons } from '@expo/vector-icons'
import { useStores, useLayout } from '../../core/hooks'
import { palette } from '../../res/theme'
import Dropdown from '../modalDropdown'

const styles = {
  footerWrapper: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  footerWrapperNC: {
    width: 320,
    flexDirection: 'column'
  },
  subSection: {
    backgroundColor: palette.white,
    borderWidth: 1,
    borderColor: palette.gray,
    borderRadius: 5,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  greyButton: {
    height: 40,
    borderRadius: 5,
    elevation: 0,
    backgroundColor: palette.backgroundColor
  },
  indicator: {
    fontSize: 24,
    color: palette.text
  },
  selectedItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingTop: 3,
    paddingRight: 3,
    paddingBottom: 3,
    margin: 3,
    borderRadius: 20,
    borderWidth: 2
  },
  button: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonText: {
    color: palette.white,
    fontSize: 14
  },
  selectorView: {
    flexDirection: 'column',
    elevation: 2
  },
  inputGroup: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: palette.white,
    flex: 1,
    borderColor: palette.text,
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    height: 40
  },
  dropdownView: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    flex: 1
  }
}

// set UIManager LayoutAnimationEnabledExperimental
if (UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true)
}

const defaultSearchIcon = <MaterialIcons name='search' size={20} color={palette.primary} />

export const MultiSelect = inject('t')(
  class MultiSelect extends Component {
    static defaultProps = {
      single: false,
      updateOnSelect: false,
      selectedItems: [],
      uniqueKey: '_id',
      tagBorderColor: palette.primary,
      tagTextColor: palette.primary,
      tagRemoveIconColor: palette.warning,
      selectedItemFontFamily: '',
      selectedItemTextColor: palette.primary,
      searchIcon: defaultSearchIcon,
      itemFontFamily: '',
      itemTextColor: palette.text,
      selectedItemIconColor: palette.primary,
      searchInputPlaceholderText: 'Search',
      searchInputStyle: {},
      textColor: palette.text,
      selectText: 'Select',
      hideSubmitButton: false,
      submitButtonColor: '#CCC',
      submitButtonText: 'Submit',
      hideTags: false,
      hideDropdown: false,
      onChangeInput: () => {},
      displayKey: 'name',
      canAddItems: false,
      onAddItem: () => {},
      onClearSelector: () => {},
      onToggleList: () => {},
      removeSelected: false,
      dropdownMenuWrapper: undefined
    }

    constructor(props) {
      super(props)
      this.state = {
        showSelector: false,
        searchTerm: ''
      }
      this.selectorRef = React.createRef()
    }

    getSelectedItemsExt = (optionalSelctedItems) => (
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {this._displaySelectedItems(optionalSelctedItems)}
      </View>
    )

    handleInputChange = (value) => {
      const { onChangeInput } = this.props
      if (onChangeInput) {
        onChangeInput(value)
      }
      this.setState({ searchTerm: value })
    }

    _getSelectLabel = () => {
      const { selectText, single, selectedItems, titleDisplayKey, displayKey, t } = this.props
      if (!selectedItems || selectedItems.length === 0) {
        return selectText
      }
      if (single) {
        const item = selectedItems[0]
        const foundItem = this._findItem(item)
        return _.get(foundItem, `${titleDisplayKey || displayKey}`) || selectText
      }
      return `${t('MEDIA.IMAGE_SELECTED_COUNT', { count: selectedItems.length })}`
    }

    _findItem = (itemKey) => {
      const { items, rawItems, uniqueKey } = this.props
      const allItems = rawItems || items
      return (
        _.find(
          allItems,
          (singleItem) => _.toString(singleItem[uniqueKey]) === _.toString(itemKey)
        ) || {}
      )
    }

    _displaySelectedItems = (optionalSelctedItems) => {
      const {
        tagRemoveIconColor,
        tagBorderColor,
        uniqueKey,
        tagTextColor,
        selectedItems,
        displayKey
      } = this.props
      const actualSelectedItems = optionalSelctedItems || selectedItems
      return actualSelectedItems.map((singleSelectedItem) => {
        const item = this._findItem(singleSelectedItem)
        if (!item[displayKey]) return null
        return (
          <View
            style={[
              styles.selectedItem,
              {
                width: item[displayKey].length * 8 + 60,
                justifyContent: 'center',
                height: 40,
                borderColor: tagBorderColor
              }
            ]}
            key={item[uniqueKey]}
          >
            <Text style={{ flex: 1, color: tagTextColor, fontSize: 15 }} numberOfLines={1}>
              {item[displayKey]}
            </Text>
            <TouchableOpacity
              onPress={() => {
                this._removeItem(item)
              }}
            >
              <MaterialIcons
                name='close'
                style={{ color: tagRemoveIconColor, fontSize: 22, marginLeft: 10 }}
              />
            </TouchableOpacity>
          </View>
        )
      })
    }

    _removeItem = (item) => {
      const { uniqueKey, selectedItems, onSelectedItemsChange } = this.props
      const newItems = _.reject(selectedItems, (singleItem) => item[uniqueKey] === singleItem)
      // broadcast new selected items state to parent component
      onSelectedItemsChange(newItems)
    }

    _removeAllItems = () => {
      const { onSelectedItemsChange } = this.props
      // broadcast new selected items state to parent component
      onSelectedItemsChange([])
    }

    _clearSelector = () => {
      this.setState({
        showSelector: false
      })
    }

    handleLeftArrowButtonPress = () => {
      const { onClearSelector } = this.props
      this._clearSelector()
      if (onClearSelector) {
        onClearSelector()
      }
    }

    handleToggleSelector = () => {
      const { onToggleList } = this.props

      this.setState({
        showSelector: !this.state.showSelector
      })
      if (!this.state.showSelector) {
        this.selectorRef.current?.onPress?.()
      }
      if (onToggleList) {
        onToggleList()
      }
    }

    _clearSearchTerm = () => {
      this.setState({
        searchTerm: ''
      })
    }

    handleSubmitSelection = () => {
      this.handleToggleSelector()
      // reset searchTerm
      this._clearSearchTerm()
    }

    _itemSelected = (item) => {
      let isSelectedAllSubitems = false
      const { uniqueKey, selectedItems } = this.props
      if (item.group) {
        const subitems = _.map(item.group, (subitem) => subitem.value)
        isSelectedAllSubitems = _.intersection(subitems, selectedItems).length === item.group.length
      }
      return isSelectedAllSubitems || selectedItems.indexOf(item[uniqueKey]) !== -1
    }

    handleToggleItem = (item) => {
      const { single, uniqueKey, selectedItems, onSelectedItemsChange, updateOnSelect } = this.props
      const itemValue = item.group
        ? _.map(item.group, (subitem) => subitem[uniqueKey])
        : [item[uniqueKey]]

      if (single) {
        this.handleSubmitSelection()
        onSelectedItemsChange(itemValue)
      } else {
        const status = this._itemSelected(item)
        let newItems = []
        if (status) {
          newItems = _.difference(selectedItems, itemValue)
        } else {
          newItems = _.concat(selectedItems, itemValue)
        }
        // broadcast new selected items state to parent component
        onSelectedItemsChange(newItems, updateOnSelect)
      }
    }

    _itemStyle = (item) => {
      const { selectedItemTextColor, itemTextColor } = this.props
      const isSelected = this._itemSelected(item)
      const color = isSelected ? { color: selectedItemTextColor } : { color: itemTextColor }
      return {
        ...color
      }
    }

    _getRowContent = (item, type = 'item') => {
      const { selectedItemIconColor, displayKey, styleRowList } = this.props
      const isSubitem = type === 'subitem'

      return (
        <TouchableOpacity
          key={item[displayKey]}
          disabled={item.disabled}
          onPress={() => this.handleToggleItem(item)}
          style={[
            styleRowList && styleRowList,
            { paddingLeft: isSubitem ? 30 : 20, paddingRight: 20 }
          ]}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={[
                { flex: 1, paddingVertical: 10 },
                this._itemStyle(item),
                item.disabled ? { color: 'grey' } : {}
              ]}
            >
              {isSubitem && <MaterialIcons name='remove' style={{ marginRight: 6 }} />}
              {item[displayKey]}
            </Text>
            {this._itemSelected(item) ? (
              <MaterialIcons name='check' style={{ fontSize: 20, color: selectedItemIconColor }} />
            ) : null}
          </View>
        </TouchableOpacity>
      )
    }

    _getRow = (item) => {
      return (
        <>
          {this._getRowContent(item)}
          {_.map(item.group, (subitem) => {
            const subitemObj = { ...subitem, disabled: item.disabled }
            return this._getRowContent(subitemObj, 'subitem')
          })}
        </>
      )
    }

    _getRowNew = (item) => (
      <TouchableOpacity
        disabled={item.disabled}
        onPress={() => this._addItem(item)}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={[
                { flex: 1, paddingTop: 5, paddingBottom: 5 },
                this._itemStyle(item),
                item.disabled ? { color: 'grey' } : {}
              ]}
            >
              Add {item.label} (tap or press return)
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    )

    _filterItems = (searchTerm) => {
      const filteredItems = []
      const { items, unChosenItems, displayKey, filterMethod } = this.props
      const itemUsage = unChosenItems ?? items
      const searchTermValidator = (target) => {
        if (filterMethod === 'full') {
          return target[displayKey].toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0
        } else {
          const parts = searchTerm
            .trim()
            .toLowerCase()
            .split(/[ \-:]+/)
          return _.some(parts, (part) => _.get(target, displayKey).toLowerCase().indexOf(part) > -1)
        }
      }

      _.forEach(itemUsage, (item) => {
        const filteredSubitems = _.filter(item.group, (subitem) => searchTermValidator(subitem))
        if (searchTermValidator(item) || !_.isEmpty(filteredSubitems)) {
          const newFilteredItem = Object.assign(
            {},
            item,
            _.isEmpty(filteredSubitems) ? {} : { group: filteredSubitems }
          )
          filteredItems.push(newFilteredItem)
        }
      })
      return filteredItems
    }

    _renderItems = () => {
      const {
        canAddItems,
        items,
        unChosenItems,
        uniqueKey,
        selectedItems,
        flatListProps,
        styleListContainer,
        removeSelected,
        emptyMsg
      } = this.props
      const { searchTerm } = this.state
      let component = null
      // If searchTerm matches an item in the list, we should not add a new
      // element to the list.
      let searchTermMatch
      let itemList
      let addItemRow
      const itemUsage = unChosenItems ?? items
      let renderItems = searchTerm ? this._filterItems(searchTerm) : itemUsage
      // Filtering already selected items
      if (removeSelected) {
        renderItems = renderItems.filter((item) => !selectedItems.includes(itemUsage[uniqueKey]))
      }
      if (renderItems.length) {
        itemList = (
          <FlatList
            data={renderItems}
            extraData={selectedItems}
            keyExtractor={(item) => item[uniqueKey]}
            listKey={(item) => item[uniqueKey]}
            renderItem={(rowData) => this._getRow(rowData.item)}
            {...flatListProps}
          />
        )
        searchTermMatch = renderItems.filter((item) => item.name === searchTerm).length
      } else if (!canAddItems) {
        itemList = (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ flex: 1, paddingVertical: 10, textAlign: 'center' }}>
              {emptyMsg || 'No item to display.'}
            </Text>
          </View>
        )
      }

      if (canAddItems && !searchTermMatch && searchTerm.length) {
        addItemRow = this._getRowNew({ name: searchTerm })
      }
      component = (
        <View style={styleListContainer && styleListContainer}>
          {itemList}
          {addItemRow}
        </View>
      )
      return component
    }

    handleOnBlur = () => {
      const { single, onSelectedItemsChange, selectedItems } = this.props
      this._clearSearchTerm()
      return this.setState({ showSelector: false }, () =>
        onSelectedItemsChange(selectedItems, !single)
      )
    }

    handleTextInputSubmitEditing = () => {
      const { onSelectedItemsChange } = this.props
      const filteredItem = this._filterItems(this.state.searchTerm)

      if (filteredItem?.length === 1) {
        onSelectedItemsChange([filteredItem[0].value])
        this._clearSearchTerm()
        this.selectorRef.current?.close?.()
      }
    }

    render() {
      const {
        selectedItems,
        single,
        searchInputPlaceholderText,
        searchInputStyle,
        styleDropdownOffset,
        styleDropdownMenuWrapper,
        styleDropdownMenu,
        styleDropdownMenuSubsection,
        hideSubmitButton,
        hideDropdown,
        hideTags,
        menuStyle,
        textInputProps,
        styleMainWrapper,
        styleInputGroup,
        styleSelectorContainer,
        searchIcon,
        disabled
      } = this.props
      const { searchTerm, showSelector } = this.state

      return (
        <View
          style={[{ flexDirection: 'column', flex: 1 } && styleMainWrapper && styleMainWrapper]}
        >
          {disabled ? (
            <View style={{ borderRadius: 5, backgroundColor: palette.disable }}>
              <Text
                numberOfLines={1}
                ellipsizeMode='clip'
                style={[
                  { flex: 1, paddingHorizontal: 12, paddingVertical: 9, maxWidth: '90%' },
                  !selectedItems || selectedItems.length === 0
                    ? { color: palette.gray }
                    : { color: palette.text }
                ]}
              >
                {this._getSelectLabel()}
              </Text>
            </View>
          ) : (
            <Dropdown
              ref={this.selectorRef}
              onBlur={this.handleOnBlur}
              dropdownOffset={{
                top: _.get(styleDropdownOffset, 'top', this.props.isMobile ? 85 : 40),
                left: _.get(styleDropdownOffset, 'left', 0)
              }}
              menuStyle={menuStyle}
              style={{ borderRadius: 5 }}
              label={
                showSelector ? (
                  <View style={[styles.inputGroup, styleInputGroup]}>
                    {searchIcon}
                    <TextInput
                      autoFocus
                      onChangeText={this.handleInputChange}
                      onSubmitEditing={this.handleTextInputSubmitEditing}
                      placeholder={searchInputPlaceholderText}
                      underlineColorAndroid='transparent'
                      style={[
                        { paddingHorizontal: 7, flex: 1, height: 26, maxWidth: '95%' },
                        this.props.isMobile ? { elevation: 0 } : { outlineColor: 'transparent' },
                        searchInputStyle
                      ]}
                      value={searchTerm}
                      accessibilityRole='search'
                      {...textInputProps}
                    />
                    {hideSubmitButton && (
                      <TouchableOpacity onPress={this.handleSubmitSelection}>
                        <MaterialIcons
                          name='arrow-drop-down'
                          style={[styles.indicator, { paddingLeft: 15, paddingRight: 15 }]}
                        />
                      </TouchableOpacity>
                    )}
                    {!hideDropdown && (
                      <MaterialIcons
                        name='subdirectory-arrow-left'
                        size={20}
                        onPress={this.handleLeftArrowButtonPress}
                        color={palette.negative}
                        style={{ marginLeft: -5 }}
                      />
                    )}
                  </View>
                ) : (
                  <View style={[{ flex: 1 }, styleDropdownMenuWrapper]}>
                    <View style={[styles.dropdownView, styleDropdownMenu]}>
                      <View style={[styles.subSection, styleDropdownMenuSubsection]}>
                        <TouchableWithoutFeedback
                          onPress={this.handleToggleSelector}
                          accessibilityRole='spinbutton'
                        >
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              alignItems: 'center',
                              overflow: 'hidden',
                              position: 'relative'
                            }}
                          >
                            <Text
                              numberOfLines={1}
                              ellipsizeMode='clip'
                              style={[
                                {
                                  flex: 1,
                                  paddingHorizontal: 12,
                                  paddingVertical: 9,
                                  maxWidth: '90%'
                                },
                                !selectedItems ||
                                selectedItems.length === 0 ||
                                (selectedItems.length === 1 && _.get(selectedItems, '0') === '')
                                  ? { color: palette.gray }
                                  : { color: palette.text }
                              ]}
                            >
                              {this._getSelectLabel()}
                            </Text>
                            <MaterialIcons
                              name={hideSubmitButton ? 'menu-right' : 'arrow-drop-down'}
                              style={[{ position: 'absolute', right: 4, top: 8 }, styles.indicator]}
                            />
                          </View>
                        </TouchableWithoutFeedback>
                      </View>
                    </View>
                    {!single && !hideTags && selectedItems.length ? (
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        {this._displaySelectedItems()}
                      </View>
                    ) : null}
                  </View>
                )
              }
            >
              {showSelector && (
                <View
                  style={[styles.selectorView, styleSelectorContainer]}
                  accessibilityRole='menu'
                >
                  {this._renderItems()}
                </View>
              )}
            </Dropdown>
          )}
        </View>
      )
    }
  }
)

const SearchSelector = observer(function SearchSelector(props) {
  const { t } = useStores()
  const {
    single = true,
    onChange,
    items: displayItems,
    unChosenItems,
    rawItems,
    value,
    disabled,
    style,
    ...rest
  } = props
  const [selectItem, setSelectItem] = useState([])
  const { isMobile } = useLayout()
  const ref = React.createRef()

  useEffect(() => {
    const targetValue = _.isArray(value) ? value : _.isEmpty(_.toString(value)) ? [] : [value]
    setSelectItem(targetValue)
  }, [value, single])

  const onSelectedItemsChange = useCallback(
    (selectItems, changed = false) => {
      setSelectItem(selectItems)
      const isEmptySelectItems = _.isEmpty(selectItems) && _.isNil(selectItems)
      const selectedValue = isEmptySelectItems ? '' : single ? _.get(selectItems, '0') : selectItems
      const executable = (single && !isEmptySelectItems) || changed
      return executable && onChange && onChange(selectedValue)
    },
    [single, onChange]
  )

  const allItems = rawItems || displayItems
  const outOfItemsRange =
    single &&
    !_.isEmpty(value) &&
    !_.isNil(value) &&
    _.isEmpty(_.find(allItems, (singleItem) => singleItem.value === value))

  return (
    <View style={[{ flex: 1 }, style]}>
      <MultiSelect
        isMobile={isMobile}
        hideTags
        single={single}
        items={displayItems}
        rawItems={rawItems}
        unChosenItems={unChosenItems}
        value={value}
        uniqueKey='value'
        displayKey='label'
        ref={ref}
        onSelectedItemsChange={onSelectedItemsChange}
        selectedItems={selectItem}
        selectText={
          outOfItemsRange
            ? `${value} ${t('FORM.PICKER_DEFAULT_INVALID_VALUE_PLACEHOLDER')}`
            : _.get(props, 'placeholder.label', t('FORM.PICKER_DEFAULT_PLACEHOLDER'))
        }
        searchInputPlaceholderText={t('FORM.TEXTINPUT_FILTER_PLACEHOLDER')}
        emptyMsg={t('COMMON.NONE')}
        hideDropdown
        disabled={disabled}
        {...rest}
      />
    </View>
  )
})

export default SearchSelector
