import { MaterialIcons, createIconSetFromIcoMoon } from '@expo/vector-icons'
import { IconProps } from '@expo/vector-icons/build/createIconSet'
import { PropsOf } from '@rezio/utils/types'

export const RezioIcon = createIconSetFromIcoMoon(
  require('@rezio/assets/icomoon/selection.json'),
  'IcoMoon',
  'icomoon.ttf'
)

type MaterialIconProps = PropsOf<typeof MaterialIcons>
type BaseRezioIconName = 'kkday_1' | 'kkday_2'
type RezioIconName = `rezio_${BaseRezioIconName}`

// rezio_開頭是從icomoon讀取的自定義的icon，其他則是MaterialIcons的icon
export type IconName = RezioIconName | MaterialIconProps[`name`]

// typeguard of RezioIconName
const isRezioIconName = (name: IconName): name is RezioIconName => name.startsWith('rezio_')

export const Icon = ({ name, ...restProps }: IconProps<IconName>) => {
  if (isRezioIconName(name)) {
    return <RezioIcon name={name.replace('rezio_', '')} {...restProps} />
  } else {
    return <MaterialIcons name={name} {...restProps} />
  }
}
