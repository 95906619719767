/* eslint-disable @typescript-eslint/indent */
import _ from 'lodash'
import React, { useState, useEffect, forwardRef } from 'react'
import type { StyleProp, TextStyle, ViewStyle, TextInputProps } from 'react-native'
import { View, Platform, TextInput, StyleSheet } from 'react-native'

import { useTranslation } from 'react-i18next'
import { MaterialIcons } from '..'
import { getFontFamilyByLanguage, manipulator, palette } from '../../res/theme'

interface Props
  extends Omit<
    TextInputProps,
    'editable' | 'selectTextOnFocus' | 'placeholderTextColor' | 'onContentSizeChange'
  > {
  iconName?: keyof typeof MaterialIcons.glyphMap
  iconStyle?: StyleProp<TextStyle>
  showCancelIcon?: boolean
  cancelIconStyle?: StyleProp<TextStyle>
  disabled?: boolean
  readonly?: boolean
  layout?: 'default' | 'block'
  containerStyle?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<TextStyle>
  customAutoCapitalize?: boolean
  autoGrow?: boolean
}

export const ThemedTextInput = forwardRef<TextInput, Props>(function ThemedTextInput(props, ref) {
  const {
    iconName,
    showCancelIcon,
    iconStyle,
    cancelIconStyle,
    disabled = false,
    readonly = false,
    layout,
    placeholder,
    style,
    containerStyle,
    multiline,
    inputStyle,
    value,
    customAutoCapitalize,
    autoGrow,
    ...rest
  } = props
  const { i18n } = useTranslation()
  const currentValue = _.isNil(value)
    ? undefined
    : customAutoCapitalize
    ? `${value}`.toUpperCase()
    : `${value}`
  const [height, setHeight] = useState(null)

  useEffect(() => {
    if (currentValue === '') {
      setHeight(null)
    }
  }, [currentValue, setHeight])
  return (
    <View
      style={[
        textInputStyles.container,
        manipulator.container('row', 'flex-start', 'center'),
        {
          maxWidth: layout !== 'default' || _.get(style, 'width') ? undefined : 210,
          paddingVertical: multiline ? 10 : 8.5,
          borderColor: disabled || readonly ? palette.disable : palette.gray,
          backgroundColor: disabled || readonly ? palette.disable : palette.white
        },
        Platform.OS === 'android' ? { alignItems: 'flex-start', paddingTop: 5 } : {},
        containerStyle,
        style
      ]}
      accessibilityValue={{ text: currentValue }}
    >
      {iconName ? (
        <MaterialIcons
          name={iconName}
          color={palette.border}
          size={24}
          style={[{ marginTop: 5 }, iconStyle]}
        />
      ) : null}
      <TextInput
        editable={!disabled}
        selectTextOnFocus={!disabled}
        placeholder={placeholder}
        ref={ref}
        placeholderTextColor={palette.border}
        multiline={multiline}
        onContentSizeChange={({ nativeEvent }) => {
          setHeight(nativeEvent.contentSize.height)
        }}
        style={[
          textInputStyles.default,
          Platform.OS === 'web'
            ? ({ width: 'calc(100% - 16px)', outlineStyle: 'none' } as any)
            : {},
          { backgroundColor: disabled || readonly ? palette.disable : palette.white },
          autoGrow && { height },
          Platform.select({
            web: { fontFamily: getFontFamilyByLanguage(i18n.language) }
          }),
          inputStyle
        ]}
        value={currentValue}
        {...rest}
      />
      {showCancelIcon ? (
        <MaterialIcons
          name='cancel'
          color={palette.border}
          size={16}
          style={cancelIconStyle}
          onPress={() => rest?.onChangeText('')}
        />
      ) : null}
    </View>
  )
})

const textInputStyles = StyleSheet.create({
  default: {
    fontSize: 14,
    flex: 1,
    height: Platform.OS === 'android' ? 'auto' : '100%',
    color: palette.black,
    backgroundColor: palette.white
  },
  container: {
    height: 40,
    paddingHorizontal: 10,
    borderRadius: 5,
    borderWidth: 1
  }
})
