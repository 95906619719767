import { HoverableOpacity, MaterialIcons, Navigator } from '@rezio/components'
import { DropdownBaseView } from '@rezio/components/modalDropdown'
import { getEnv } from '@rezio/core/config'
import { useLayout, useRouter, useStores } from '@rezio/core/hooks'
import { StoreContext } from '@rezio/core/stores'
import { manipulator, palette, template } from '@rezio/res/theme'
import { formatFreshdeskLocale } from '@rezio/utils/format'
import _ from 'lodash'
import { Observer, Provider, inject, observer } from 'mobx-react'
import React, {
  Component,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import {
  ActivityIndicator,
  Image,
  KeyboardAvoidingView,
  ScrollView as NativeScrollView,
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  View
} from 'react-native'
import { ScrollView as GestureScrollView } from 'react-native-gesture-handler'
import { WhitePortal } from 'react-native-portal'
import { SafeAreaContext, useSafeAreaInsets, SafeAreaView } from 'react-native-safe-area-context'
import { WebView } from 'react-native-webview'
import { useAtom } from 'jotai'
import { useFooter, pageDimensionAtom, FooterContext } from '@rezio/core/layout'
import ErrorBoundary from '@rezio/components/shareComponents/ErrorBoundary'
import { ScopeProvider } from 'bunshi/react'
import { userScope, storeScope } from '@rezio/core/scopes'
import { FrameContext } from './frame'
import Header from './header'
import WarningHeader from './warningHeader'

const ScrollView = Platform.OS === 'web' ? NativeScrollView : GestureScrollView

const styles = StyleSheet.create({
  pageBase: {
    backgroundColor: palette.white,
    flex: 1,
    padding: 30,
    minHeight: '100%'
  },
  pageHeader: {
    paddingBottom: 20,
    marginBottom: 20,
    borderColor: palette.border,
    borderBottomWidth: 1
  },
  pageHeaderInFrame: {
    paddingBottom: 16,
    marginBottom: 16,
    borderColor: palette.border,
    borderBottomWidth: 1
  },
  pageHeaderText: {
    fontSize: 22,
    color: palette.primary
  },
  pageFooter: {
    backgroundColor: palette.white,
    padding: 30,
    shadowColor: 'rgb(0,0,0)',
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 2
  }
})

export function PageFooter(props) {
  const { render, children, style, inline } = props
  const { isMobile } = useLayout()

  const footers = useContext(FooterContext)
  const [footerKey] = React.useState(footers ? footers.count + 1 : 0)
  const content = (
    <View
      style={[
        {
          paddingVertical: 16,
          paddingHorizontal: isMobile ? 10 : 30,
          backgroundColor: palette.white,
          shadowColor: palette.black,
          shadowOffset: { width: 0, height: -6 },
          shadowOpacity: 0.2,
          shadowRadius: 30,
          elevation: 6
        },
        style
      ]}
    >
      {children || render()}
    </View>
  )

  useEffect(() => {
    return () => {
      footers?.remove(footerKey)
    }
  }, [])

  useEffect(() => {
    footers?.set(content, footerKey)
  }, [props])

  return !footers || inline ? content : <View />
}

const Content = observer(({ style, ...props }) => {
  const { store } = useContext(StoreContext)
  const { isInFrame } = useContext(FrameContext)
  const { isMobile } = useLayout()
  const [, setPageDimension] = useAtom(pageDimensionAtom)

  const handleLayout = useCallback(function handleLayout(event) {
    const { width, height } = event.nativeEvent.layout
    store.viewStore.setPageLayout(width - 60, height)
    setPageDimension({ width: width - 60, height })
  }, [])

  useEffect(() => {
    return () => {
      setPageDimension(null)
    }
  }, [])
  const insets = useSafeAreaInsets()
  const [footerContext, footer, FooterWrapper] = useFooter()
  const isPlanPermissionLoaded = store.core.session?.isPlanPermissionLoaded
  return (
    <KeyboardAvoidingView
      behavior='height'
      key={_.get(store, 'core.session.store.storeUuid')}
      style={{ flex: 1, paddingBottom: insets.bottom }}
    >
      {isPlanPermissionLoaded && !store.core.switchingStore ? (
        <FooterWrapper value={footerContext}>
          <View
            style={{
              ...StyleSheet.absoluteFill,
              bottom: '30%',
              ...(Platform.OS !== 'web' && { backgroundColor: palette.primary })
            }}
          />
          <ScrollView
            nestedScrollEnabled
            onLayout={isInFrame ? null : handleLayout}
            contentContainerStyle={
              (footer || isMobile) && { minHeight: '100%', backgroundColor: 'white' }
            }
            style={[{ height: '100%' }, style]}
            {...props}
          >
            {props.children}
          </ScrollView>
          {footer || <View />}
        </FooterWrapper>
      ) : (
        <ActivityIndicator />
      )}
    </KeyboardAvoidingView>
  )
})

/**
 * @type {React.ForwardedRef}
 */
const Page = forwardRef(
  (
    {
      style,
      children,
      title,
      headerStyle,
      notitle,
      subtitle,
      reminder = '',
      reminderStyle,
      suggestionText = null,
      rightItems,
      bottomItems,
      hasBackArrow,
      backArrowType = 'ahead',
      customBackArrowPath = '',
      titleDescriptionComponent,
      onTitlePress,
      ...props
    },
    ref
  ) => {
    const { isMobile } = useLayout()
    const router = useRouter()
    const { store } = useStores()
    const { isInFrame } = useContext(FrameContext)
    const mobilePageBase = isMobile ? { padding: 20 } : {}
    const mobilePageHeaderText = isMobile ? { fontSize: 18 } : {}
    const pathArray = _.get(router, 'pathname', '').split('/')
    const showBackArrow = _.isUndefined(hasBackArrow)
      ? isMobile && pathArray.length > 2
      : hasBackArrow
    const [pageHeaderHeight, setPageHeaderHeight] = useState(0)

    useImperativeHandle(ref, () => {
      return { pageHeaderHeight }
    })

    const handleBackArrowRoute = useCallback(() => {
      switch (backArrowType) {
        case 'ahead':
          return router.push({
            pathname: `${_.take(pathArray, pathArray.length - 1).join('/')}`,
            query: router.query
          })
        case 'custom':
          return router.push({ pathname: customBackArrowPath })
        case 'goBack':
        case 'default':
          return router.history.goBack()
      }
    }, [router, backArrowType, pathArray, customBackArrowPath])

    const onHeadLayout = useCallback((event) => {
      setPageHeaderHeight(event.nativeEvent.layout.height)
    }, [])

    const titleInFramePadding = 16

    return (
      <DropdownBaseView
        style={[
          styles.pageBase,
          mobilePageBase,
          isInFrame && { paddingTop: titleInFramePadding },
          style
        ]}
        {...props}
      >
        <Observer>
          {() => {
            title = title || _.get(store, 'viewStore.pageHeader.title')
            if (notitle || !title) {
              return <View />
            }
            return (
              <View style={headerStyle} onLayout={onHeadLayout}>
                <View
                  style={[
                    isInFrame ? styles.pageHeaderInFrame : styles.pageHeader,
                    isMobile ? { paddingBottom: 10, marginBottom: 10 } : {}
                  ]}
                >
                  <View
                    style={[
                      manipulator.container(
                        'row',
                        'flex-start',
                        showBackArrow ? 'flex-start' : 'center'
                      )
                    ]}
                  >
                    {showBackArrow && (
                      <HoverableOpacity style={{ marginRight: 5 }} onPress={handleBackArrowRoute}>
                        <MaterialIcons name='arrow-back' color={palette.primary} size={24} />
                      </HoverableOpacity>
                    )}
                    <View style={[template.rowContainer, { flex: 1 }]}>
                      <Text
                        onPress={onTitlePress}
                        style={[
                          template.bold,
                          styles.pageHeaderText,
                          mobilePageHeaderText,
                          { width: '100%' }
                        ]}
                      >
                        {title}{' '}
                        {_.isEmpty(subtitle)
                          ? ''
                          : _.isObject(subtitle)
                          ? subtitle
                          : ` - ${subtitle}`}{' '}
                        {titleDescriptionComponent}
                      </Text>
                    </View>
                    <View style={template.rowContainer}>{rightItems}</View>
                  </View>
                  {bottomItems}
                  {!_.isEmpty(reminder) &&
                    (_.isString(reminder) ? (
                      <Text
                        style={[
                          { marginTop: isMobile ? 0 : 20, marginBottom: isMobile ? 10 : 0 },
                          reminderStyle
                        ]}
                      >
                        {reminder}
                      </Text>
                    ) : (
                      reminder
                    ))}
                </View>
                {suggestionText && (
                  <View className='mb-6 rounded-1 bg-primary-100 p-5'>
                    <Text>{suggestionText}</Text>
                  </View>
                )}
              </View>
            )
          }}
        </Observer>
        {children}
      </DropdownBaseView>
    )
  }
)

function IconImage(props) {
  if (props.uri) {
    return (
      <View style={{ marginRight: 10 }}>
        <Image style={{ height: 30, width: 30, borderRadius: 15 }} source={{ uri: props.uri }} />
      </View>
    )
  }

  const { label: rawLabel = '', isMobile = false } = props
  const labels = _.isArray(rawLabel) ? rawLabel : [rawLabel, '']
  const alphabetOnly = /^[a-zA-Z]+$/.test(labels.join(''))
  const targetLetters = [
    alphabetOnly ? labels[0].charAt(0) : (labels[1] || labels[0]).charAt(0),
    alphabetOnly ? labels[1].charAt(0) : (labels[1] || labels[0]).charAt(1)
  ]
  const formattedLabel = _.map(targetLetters, (letter) => letter.toUpperCase()).join('')

  return (
    <View
      style={{
        marginRight: isMobile ? 8 : 10,
        borderColor: palette.white,
        borderWidth: 1,
        height: isMobile ? 28 : 35,
        width: isMobile ? 28 : 35,
        borderRadius: 17.5,
        backgroundColor: props.color || palette.darkBrand,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Text style={{ color: palette.white, fontSize: 12, fontWeight: 'bold' }}>
        {formattedLabel}
      </Text>
    </View>
  )
}

const Layout = inject(
  'core',
  'theme',
  'store',
  't'
)(
  observer(
    class Layout extends Component {
      static contextType = SafeAreaContext

      constructor(props, ...args) {
        super(props, ...args)

        this.state = {
          isWebviewFreshdeskWidgetShow: false,
          isFreshdeskReady: false
        }
      }

      styles = StyleSheet.create({
        base: {
          flexDirection: 'column',
          flex: 1,
          width: '100%',
          backgroundColor: '#f0f0f2'
        },
        mobileBase: {},
        paddingWrapper: {
          padding: 20
        }
      })

      showAppFreshdesk = () => {
        this.state.isFreshdeskReady && this.setState({ isWebviewFreshdeskWidgetShow: true })
      }

      renderApp = () => {
        const insets = this.context
        const webviewTranslateX = this.state.isWebviewFreshdeskWidgetShow ? 0 : 5000
        const env = getEnv()

        return (
          <View
            style={{
              backgroundColor: 'white',
              flex: 1,
              width: '100%',
              paddingTop: insets.top
            }}
          >
            <View
              style={{
                backgroundColor: palette.primary,
                height: insets.top,
                marginTop: -insets.top
              }}
            />
            <WarningHeader />
            <StatusBar backgroundColor={palette.primary} barStyle='light-content' />
            <Header hideNav={this.props.hideNav} showAppFreshdesk={this.showAppFreshdesk} />
            <Content>
              <ErrorBoundary>{this.props.children}</ErrorBoundary>
            </Content>
            {!this.props.hideNav && !this.state.isWebviewFreshdeskWidgetShow && (
              <Navigator mode='tabs' />
            )}
            {Platform.OS !== 'web' && (
              <SafeAreaView
                style={[
                  StyleSheet.absoluteFill,
                  { transform: [{ translateX: webviewTranslateX }] }
                ]}
              >
                <WebView
                  originWhitelist={['*']}
                  onError={(e) => {
                    console.error(e)
                  }}
                  onMessage={(m) => {
                    m?.nativeEvent?.data === 'close' &&
                      this.setState({ isWebviewFreshdeskWidgetShow: false })
                    m?.nativeEvent?.data === 'done' && this.setState({ isFreshdeskReady: true })
                  }}
                  source={{
                    uri: `https://www${env === 'prod' ? '' : `-${env}`}.rezio.io/${
                      this.props.core.lang
                    }/freshdesk?lang=${formatFreshdeskLocale(this.props.core.lang)}`
                  }}
                  style={{ flex: 1 }}
                />
              </SafeAreaView>
            )}
          </View>
        )
      }

      renderDesktop = () => {
        const { theme, hideNav } = this.props

        return (
          <View style={this.styles.base}>
            <WarningHeader />
            <Header hideNav={hideNav} />
            <View style={[{ flex: 1, flexDirection: 'row' }]}>
              <View style={{ flexDirection: 'row', width: '100%', paddingVertical: 15 }}>
                {hideNav ? <View style={{ width: 15 }} /> : <Navigator mode='sidebar' />}
                <View style={{ flex: 1, paddingRight: 15 }}>
                  <Content theme={theme}>
                    <ErrorBoundary>{this.props.children}</ErrorBoundary>
                  </Content>
                </View>
              </View>
            </View>
            <WhitePortal name='addition' />
          </View>
        )
      }

      render() {
        const { theme, core, store } = this.props
        if (this.props.pageOnly) {
          return <Content theme={theme}>{this.props.children}</Content>
        }
        let content = null
        const { layout } = store.viewStore
        switch (layout) {
          case 'desktop':
            content = this.renderDesktop()
            break
          case 'mobile':
          case 'app':
            content = this.renderApp()
            break
        }
        return (
          <ScopeProvider scope={userScope} value={core.account?.uuid}>
            <ScopeProvider scope={storeScope} value={core?.decodedToken?.storeUuid}>
              <Provider theme={theme}>{content}</Provider>
            </ScopeProvider>
          </ScopeProvider>
        )
      }
    }
  )
)

export { IconImage, Layout, Page }
