import {
  CountryPhoneInput,
  FilterableSelector,
  FilterableTable,
  ImageUploader,
  MaterialIcons,
  SearchSelector,
  ThemedButton,
  ThemedCheckBox,
  ThemedCheckBoxes,
  ThemedColorSelector,
  ThemedExtendableTextInput,
  ThemedLabelSelector,
  ThemedLandmarkSelector,
  ThemedOptionGroupInput,
  ThemedPicker,
  ThemedRadioButton,
  ThemedRegionLandmarkSelector,
  ThemedSwitch,
  ThemedTagSelector,
  ThemedTextInput,
  ThemedTextTag,
  ThemedTimeSelector,
  ThemedWeekdayTimeRangePanel,
  PageWidthModal
} from '@rezio/components'
import { DatePicker } from '@rezio/components/datePicker2/datePicker'
import { DateRangePicker } from '@rezio/components/datePicker2/dateRangePicker'
import { PageFooter } from '@rezio/components/layout'
import { MarkdownEditor } from '@rezio/components/markdown'
import { CustomSelector, SelectorCategory } from '@rezio/components/resource/customSelector'
import { useLayout, useStores } from '@rezio/core/hooks'
import { StoreContext } from '@rezio/core/stores'
import { manipulator, palette, template } from '@rezio/res/theme'
import { passportNameRegex } from '@rezio/utils/format'
import _ from 'lodash'
import { inject } from 'mobx-react'
import moment from 'moment'
import React, { Component, createContext, createRef } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import {
  FieldControl,
  FieldGroup,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup
} from 'react-reactive-form'
import { NoticeArea } from '@rezio/components/notice'
import { SortableWrapper, SortableItem } from '@rezio/components/sortableComponents'
import { DSButton } from '@rezio/components/ds/button'
import { ThemedNumberSelector } from '@rezio/components/shareComponents'
import { nanoid } from 'nanoid/non-secure'
import MarkdownRestriction from './markdownRestriction'
import ItemWrapper from './legacyForm/itemWrapper'

const debug = require('debug')('tako:form_component')

const BorderPanel = (props) => {
  return (
    <View
      testID={props.label}
      style={[
        manipulator.panel(20, 20, 20, 20),
        manipulator.border('all', 'light', 5),
        { marginBottom: 20 },
        props.containerStyle
      ]}
    >
      <View
        style={[manipulator.container('row', 'space-between', 'center'), props.titleContainerStyle]}
      >
        <View style={[manipulator.container('row', 'space-between', 'center')]}>
          {!_.isEmpty(props.label) && (
            <Text style={[template.title, { marginBottom: 10 }, props.labelStyle]}>
              {props.label}
            </Text>
          )}
          {!!props.placeholder && (
            <Text style={{ marginBottom: 10, marginLeft: 20 }}>{props.placeholder}</Text>
          )}
        </View>
        {!_.isEmpty(props.rightItem) && props.rightItem}
      </View>
      {!_.isEmpty(props.subTitle) && (
        <Text style={[{ paddingBottom: 20 }, props.subTitleStyle]}>{props.subTitle}</Text>
      )}
      {props.noticeKey ? <NoticeArea className='mb-5' translationKey={props.noticeKey} /> : null}
      {props.children}
    </View>
  )
}

const TitlePanel = (props) => {
  return (
    <View style={props.style}>
      <View
        style={[
          manipulator.panel(0, 0, 20, 0),
          manipulator.container('row', 'space-between', 'center'),
          props.titleContainerStyle
        ]}
      >
        {typeof props.label === 'string' ? (
          <Text key='label' style={[template.title, props.labelStyle]}>
            {props.label}
          </Text>
        ) : (
          props.label
        )}
        {!_.isEmpty(props.rightItem) && props.rightItem}
      </View>
      {props.noticeKey ? <NoticeArea className='mb-5' translationKey={props.noticeKey} /> : null}
      {props.children}
    </View>
  )
}

const errorToString = (error, type) => {
  const result = []
  if (type === 'pattern' && error?.requiredPattern === `${passportNameRegex}`) {
    result.push('FORM.ERROR_PASSPORT_NAME')
  } else if (type === 'custom') {
    result.push(error)
  } else {
    result.push([`FORM.ERROR_${type.toUpperCase()}`, 'FORM.ERROR'])
  }
  if (_.isObject(error)) {
    result.push(error)
  }
  return result
}

const PageStepControl = (props) => {
  const { t } = useStores()
  const { isMobile } = useLayout()
  const { style, buttons, inline, isRootDisabled } = props
  const negativeButtons = buttons.filter((each) => each.type === 'negative')
  const warningButtons = buttons.filter((each) => each.type === 'warning')
  const neutralButtons = buttons.filter((each) => each.type === 'neutral')
  const positiveButtons = buttons.filter((each) => each.type === 'positive')

  return (
    <PageFooter
      inline={inline}
      key='__page_step_control'
      style={[template.rowContainer, style, isRootDisabled ? { position: 'relative' } : {}]}
    >
      {isRootDisabled && (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              zIndex: 99,
              opacity: 0.6,
              backgroundColor: palette.black,
              alignItems: 'center',
              margin: 0
            }
          ]}
        />
      )}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: isMobile ? 'space-between' : 'flex-start',
          width: isMobile ? '100%' : 'auto'
        }}
      >
        {!_.isEmpty(negativeButtons) &&
          negativeButtons.map(({ onPress, ...negative }, key) => {
            return (
              <ThemedButton
                layout='default'
                key={`negative-${key}`}
                onPress={onPress}
                disabled={_.get(negative, 'disabled', false) || isRootDisabled}
                containerStyle={isMobile ? { flex: 1 } : {}}
                loadingText={negative.loadingText}
                style={[
                  template.defaultPanel,
                  { marginRight: 10 },
                  {
                    backgroundColor:
                      negative?.disabled || !negative?.backgroundColor
                        ? palette.negative
                        : negative?.backgroundColor
                  }
                ]}
                {...negative.props}
              >
                <Text
                  style={[
                    { color: negative?.disabled ? palette.white : palette.black },
                    negative.textStyle
                  ]}
                >
                  {negative.text}
                </Text>
              </ThemedButton>
            )
          })}
        {!_.isEmpty(neutralButtons) &&
          neutralButtons.map(({ onPress, ...neutral }, key) => {
            return (
              <ThemedButton
                layout='default'
                key={`neutral-${key}`}
                onPress={onPress}
                disabled={_.get(neutral, 'disabled', false) || isRootDisabled}
                containerStyle={isMobile ? { flex: 1 } : {}}
                loadingText={neutral.loadingText || t('FORM.BUTTON_SAVING')}
                style={[
                  template.defaultPanel,
                  { marginRight: 10 },
                  {
                    backgroundColor:
                      _.get(neutral, 'disabled', false) || isRootDisabled
                        ? palette.negative
                        : _.get(neutral, 'backgroundColor', palette.neutral)
                  }
                ]}
                {...neutral.props}
              >
                {neutral.text}
              </ThemedButton>
            )
          })}
        {!_.isEmpty(warningButtons) &&
          warningButtons.map(({ onPress, ...warning }, key) => {
            return (
              <ThemedButton
                layout='default'
                key={`warning-${key}`}
                onPress={onPress}
                disabled={_.get(warning, 'disabled', false) || isRootDisabled}
                containerStyle={isMobile ? { flex: 1 } : {}}
                loadingText={warning.loadingText || t('FORM.BUTTON_DELETING')}
                style={[
                  template.defaultPanel,
                  { marginRight: 10 },
                  {
                    backgroundColor:
                      _.get(warning, 'disabled', false) || isRootDisabled
                        ? palette.gray
                        : _.get(warning, 'backgroundColor', palette.warning)
                  }
                ]}
                {...warning.props}
              >
                {warning.text}
              </ThemedButton>
            )
          })}
        {isMobile &&
          !_.isEmpty(positiveButtons) &&
          positiveButtons.map(({ onPress, ...positive }, key) => {
            const marginStyle = +key + 1 < +_.size(positiveButtons) ? { marginRight: 10 } : ''
            return (
              <ThemedButton
                layout='default'
                key={`positive-${key}`}
                onPress={onPress}
                disabled={_.get(positive, 'disabled', false) || isRootDisabled}
                containerStyle={isMobile ? { flex: 1 } : {}}
                loadingText={positive.loadingText || t('FORM.BUTTON_SAVING')}
                style={[
                  template.defaultPanel,
                  marginStyle,
                  {
                    backgroundColor:
                      _.get(positive, 'disabled', false) || isRootDisabled
                        ? palette.gray
                        : _.get(positive, 'backgroundColor', palette.positive)
                  }
                ]}
                {...positive.props}
              >
                <Text style={{ color: palette.white }}>{positive.text}</Text>
              </ThemedButton>
            )
          })}
      </View>
      <>
        {!isMobile &&
          !_.isEmpty(positiveButtons) &&
          positiveButtons.map(({ onPress, ...positive }, key) => {
            const marginStyle = +key + 1 < +_.size(positiveButtons) ? { marginRight: 10 } : ''
            return (
              <ThemedButton
                layout='default'
                key={`positive-${key}`}
                onPress={onPress}
                disabled={_.get(positive, 'disabled', false) || isRootDisabled}
                containerStyle={isMobile ? { flex: 1 } : {}}
                loadingText={positive.loadingText || t('FORM.BUTTON_SAVING')}
                style={[
                  template.defaultPanel,
                  marginStyle,
                  {
                    backgroundColor:
                      _.get(positive, 'disabled', false) || isRootDisabled
                        ? palette.gray
                        : _.get(positive, 'backgroundColor', palette.positive)
                  }
                ]}
                {...positive.props}
              >
                <Text style={{ color: palette.white }}>{positive.text}</Text>
              </ThemedButton>
            )
          })}
      </>
    </PageFooter>
  )
}

const ThemedLabel = inject('theme')(
  class ThemedLabel extends Component {
    render() {
      const { children } = this.props
      return children ? (
        <Text
          key='label'
          style={[{ lineHeight: this.props.theme.elementHeight }, this.props.style]}
        >
          {children}
        </Text>
      ) : null
    }
  }
)

const ErrorMessage = inject('t')(
  class ErrorMessage extends React.Component {
    render() {
      const { errors, touched, pristine = true, style, t, ...rest } = this.props
      const errorStrs = _.map(errors, (error, type) => t(...errorToString(error, type)))

      return (touched || !pristine) && errors ? (
        <Text {...rest} style={[{ color: palette.warning, marginBottom: 4 }, style]}>
          {errorStrs.join('\n')}
        </Text>
      ) : null
    }
  }
)

class FoldablePanel extends React.Component {
  state = {
    isOpen: typeof this.props.isOpen !== 'undefined' ? this.props.isOpen : true
  }

  render() {
    const { isOpen } = this.state
    const {
      children,
      label = '',
      profixText = '',
      style,
      profixTextStyle,
      containerStyle
    } = this.props
    return (
      <View style={[isOpen ? { marginBottom: 30 } : { paddingBottom: 20 }, containerStyle]}>
        <View
          style={[
            manipulator.container('row', 'space-between', 'center'),
            manipulator.panel(5, 20, 5, 20, palette.panelBackground),
            isOpen ? { marginBottom: 10 } : {},
            style
          ]}
        >
          <View style={[manipulator.container('row', 'flex-start', 'baseline')]}>
            <Text style={[template.blackTitle, { fontSize: 16 }]}>{label}</Text>
            <Text style={[{ marginLeft: 10 }, profixTextStyle]}>{profixText}</Text>
          </View>
          <ThemedButton
            style={{ width: 25, backgroundColor: palette.panelBackground }}
            onPress={() => this.setState({ isOpen: !isOpen })}
          >
            {isOpen ? (
              <MaterialIcons name='keyboard-arrow-up' color={palette.primary} size={24} />
            ) : (
              <MaterialIcons name='keyboard-arrow-down' color={palette.primary} size={22} />
            )}
          </ThemedButton>
        </View>
        <View style={[isOpen ? { padding: 20 } : { display: 'none' }]}>{children}</View>
      </View>
    )
  }
}

export const ArrayFormItemContext = createContext()

/**
 * FormComponent field type Array
 * @param {boolean} editor  show editor, default true.
 * @param {boolean} isModalEditor  show modal editor, default false.
 * @param {boolean} isSortable use dnd-kit sortable, default false.
 * @param {boolean} isDragWholeItem use with isSortable. determine whether the whole item is draggable, default true.
 * @param {boolean} sortDisabled use with isSortable. determine whether the item is disabled.
 * @param {string} sortKey use with isSortable. unique key.
 * @param {string} emptyWording empty wording.
 * @param {string} disabledTitle sortDisabled list title.
 * @param {function} renderItem custom render item function.
 * @param {function} ItemsContainer custom items container.
 * @param {function} itemWrapper custom item wrapper.
 */

export const ArrayForm = inject('t')(
  class ArrayForm extends React.Component {
    constructor(props, ...args) {
      super(props, ...args)
      this.control = props.root.get(props.name)
      const length = this.control.controls.length
      this.inputControl = this.buildChildForm()
      this.state = {
        length,
        modalVisible: false,
        editingKey: null
      }
      this.inputRef = createRef()

      if (this.control.disabled) {
        this.inputControl.disable()
      }
    }

    static ItemWrapper = ItemWrapper

    static Wrapper = (props) => {
      return <View>{props.children}</View>
    }

    static ItemsContainer = (props) => {
      return (
        <View
          style={
            _.isEmpty(props.children)
              ? ''
              : [
                  manipulator.border('all', 'light', 5),
                  manipulator.panel(20, 20, 20, 20),
                  { marginTop: 20 },
                  props.style
                ]
          }
        >
          {props.children}
        </View>
      )
    }

    // beta flag
    SortableItemWrapper = (props) => {
      const { children, index, id, isDisabled, isDragWholeItem } = props

      return (
        <SortableItem
          index={index}
          id={id}
          isDisabled={isDisabled}
          isDragWholeItem={isDragWholeItem}
        >
          {children}
        </SortableItem>
      )
    }

    // beta flag
    SortableItemsContainer = (props) => {
      const { items, onChange, children } = props
      const itemsIds = items?.map((item) => item.sortKey)

      return (
        <SortableWrapper items={items} itemsIds={itemsIds} onChange={onChange}>
          {children}
        </SortableWrapper>
      )
    }

    updateFromValue = (value) => {
      this.props.root.markAsDirty()
      this.setState({ length: value.length })
    }

    updateEditorValue = (value) => {
      this.props.onEditorChange?.(value)
    }

    componentDidMount() {
      this.control.stateChanges.subscribe(() => {
        this.forceUpdate()
      })
      this.control.valueChanges.subscribe(this.updateFromValue)
      this.inputControl.valueChanges.subscribe(this.updateEditorValue)

      this.control.statusChanges.subscribe((nextStatus) => {
        if (nextStatus === 'DISABLED') {
          if (!this.inputControl.disabled) {
            this.inputControl.disable()
          }
        } else {
          if (this.inputControl.disabled) {
            this.inputControl.enable()
          }
        }
      })

      if (!this.control._arrayForm_originalPatchValue) {
        this.control._arrayForm_originalPatchValue = this.control.patchValue
        this.control.patchValue = (items, options) => {
          const length = this.control.value.length
          const nextLength = _.size(items)
          if (nextLength > length) {
            _.range(0, nextLength - length).map(() => {
              const control = this.buildChildForm()
              this.control.controls.push(control)
              this.control._registerControl(control)
            })
            this.control._onCollectionChange()
          } else if (nextLength < length) {
            _.range(0, length - nextLength).map((key) => {
              if (this.control.controls[length - key]) {
                this.control.controls[length - key]._registerOnCollectionChange(() => {})
              }
            })
            this.control.controls.splice(nextLength)
          }
          this.control._arrayForm_originalPatchValue(items, options)
        }
      }
    }

    componentWillUnmount() {
      this.control.valueChanges.unsubscribe()
      this.control.statusChanges.unsubscribe()
      this.control.stateChanges.unsubscribe()
      this.inputControl.valueChanges.unsubscribe()
    }

    handleAddItem = (value = {}) => {
      if (this.control.disabled) {
        return
      }

      this.inputControl.markAsSubmitted()
      if (this.inputControl.valid) {
        this.control.push(this.buildChildForm(this.inputControl.value))
        this.props.root.markAsDirty()
        this.inputControl.reset()
      }
    }

    handleCloseEditorModal = () => {
      this.setState({ modalVisible: false, editingKey: null })
    }

    handleCancelItem = () => {
      this.inputControl.reset()
      this.handleCloseEditorModal()
    }

    handleModalDeleteItem = async () => {
      const { editingKey } = this.state
      // const tempValue = _.cloneDeep(this.control.at(editingKey).value)
      this.control.removeAt(editingKey)
      this.props.root.markAsDirty()
      if (this.props.onModalDelete) {
        const { status, callback } = await this.props.onModalDelete?.(editingKey)
        if (status === 'success') {
          callback?.()
          this.handleCloseEditorModal()
        } else {
          // 塞不回原來排序，直接 reload
          setTimeout(() => {
            location.reload()
          }, 3000)
        }
      } else {
        this.handleCloseEditorModal()
      }
    }

    handleModalEditItem = async () => {
      const { editingKey } = this.state
      const onSuccess = () => {
        this.inputControl.reset()
        this.handleCloseEditorModal()
      }

      this.inputControl.markAsSubmitted()
      if (this.inputControl.valid) {
        this.control.at(editingKey).patchValue(this.inputControl.value)
        this.props.root.markAsDirty()
        if (this.props.onModalEdit) {
          const { status, callback } = await this.props.onModalEdit?.(this.inputControl.value)
          if (status === 'success') {
            onSuccess()
            callback?.()
          }
        } else {
          onSuccess()
        }
      }
    }

    handleModalAddItem = async () => {
      const onSuccess = () => {
        this.props.root.markAsDirty()
        this.inputControl.reset()
        this.handleCloseEditorModal()
      }

      this.inputControl.markAsSubmitted()
      if (this.inputControl.valid) {
        this.inputControl.setValue({ ...this.inputControl.value, sortKey: nanoid() })
        this.control.push(this.buildChildForm(this.inputControl.value))
        if (this.props.onModalCreate) {
          const { status, callback } = await this.props.onModalCreate?.(this.inputControl.value)
          if (status === 'success') {
            onSuccess()
            callback?.()
          } else {
            // 新增失敗，移除 form value
            this.control.removeAt(this.control.value.length - 1)
          }
        } else {
          onSuccess()
        }
      }
    }

    handleOpenEditor = (index) => {
      if (this.control.disabled) return
      const isEdit = _.isNumber(index)
      this.inputControl.reset()
      isEdit && this.inputControl.setValue(this.control.at(index).value)
      this.setState({ modalVisible: true, editingKey: index })
    }

    buildChildForm(value) {
      return this.props.buildForm(
        this.props.form,
        value,
        this.props.childFormOptions,
        this.props.childFormAfterCreate
      )
    }

    remove = (index) => {
      if (this.control.disabled) {
        return
      }

      const last = this.control.controls.length - 1
      if (this.control.controls[last]) {
        this.control.controls[last]._registerOnCollectionChange(() => {})
      }
      this.control.controls.splice(last, 1)
      const nextValue = [
        ..._.slice(this.control.value, 0, index),
        ..._.slice(this.control.value, index + 1)
      ]
      this.props.root.markAsDirty()
      this.control.patchValue(nextValue)
    }

    reset = (index) => {
      if (this.control.disabled) {
        return
      }
      const nextValue = [
        ..._.slice(this.control.value, 0, index),
        ...[
          _.reduce(
            this.control.value?.[index],
            (result, value, key) => {
              return {
                ...result,
                ..._.set({}, key, [])
              }
            },
            {}
          )
        ],
        ..._.slice(this.control.value, index + 1)
      ]
      this.control.at(index).reset()
      this.props.root.markAsDirty()
      this.control.patchValue(nextValue)
    }

    handleMoveItem = (fromIndex, delta) => {
      if (this.control.disabled) {
        return
      }

      const toIndex = fromIndex + delta
      if (toIndex < 0 || toIndex >= this.state.length) {
        return
      }
      const middle = _.slice(
        this.control.value,
        Math.min(toIndex, fromIndex),
        Math.max(toIndex, fromIndex) + 1
      )
      if (fromIndex > toIndex) {
        middle.unshift(middle.pop())
      } else {
        middle.push(middle.shift())
      }
      this.props.root.markAsDirty()
      this.control.patchValue([
        ..._.slice(this.control.value, 0, Math.min(toIndex, fromIndex)),
        ...middle,
        ..._.slice(this.control.value, Math.max(toIndex, fromIndex) + 1)
      ])
    }

    handleSort = (nextValue) => {
      this.control.patchValue(nextValue)
    }

    renderForm = (layer = '', root) => {
      const { form } = this.props
      return !_.isEmpty(form) && form.map((f) => this.props.renderForm(f, layer, root))
    }

    renderItem = (key) => {
      const { controls, value } = this.control.at(key)
      const defaultWrapper = this.props.isSortable
        ? this.SortableItemWrapper
        : ArrayForm.ItemWrapper

      const {
        t,
        itemWrapper: ItemWrapper = defaultWrapper,
        movable = true,
        renderItem: renderCustomItem,
        isDragWholeItem = true
      } = this.props

      const id = this.props.isSortable ? value.sortKey : key

      const CustomItem = (props) => {
        return renderCustomItem({
          item: value,
          index: key,
          controls,
          formControl: this.control,
          openEditModal: () => this.handleOpenEditor(key),
          listeners: props.listeners
        })
      }

      return (
        this.control.at(key) && (
          <ArrayFormItemContext.Provider key={key} value={value}>
            <ItemWrapper
              id={id}
              key={key}
              itemCount={this.state.length}
              disabled={this.control.disabled || value.sortDisabled}
              onRemove={() => this.remove(key)}
              onUp={movable && key !== 0 ? () => this.handleMoveItem(key, -1) : undefined}
              onDown={
                movable && key !== this.state.length - 1
                  ? () => this.handleMoveItem(key, 1)
                  : undefined
              }
              onReset={() => this.reset(key)}
              onMove={this.handleMoveItem}
              isFirst={key === 0}
              isLast={key === this.state.length - 1}
              // below for props.isSortable
              item={value}
              index={key}
              isDragWholeItem={isDragWholeItem}
              t={t}
            >
              {renderCustomItem ? <CustomItem /> : this.renderForm(`${this.props.name}.${key}.`)}
            </ItemWrapper>
          </ArrayFormItemContext.Provider>
        )
      )
    }

    renderModalEditor = () => {
      const { t } = this.props
      const { modalVisible, editingKey } = this.state
      const isEdit = _.isNumber(editingKey)
      const buttons = [
        {
          type: 'negative',
          text: t('FORM.BUTTON_CANCEL'),
          onPress: this.handleCancelItem
        },
        {
          ...(isEdit && {
            type: 'warning',
            text: t('FORM.BUTTON_DELETE'),
            onPress: this.handleModalDeleteItem
          })
        },
        {
          type: 'positive',
          text: t('FORM.BUTTON_SAVE'),
          onPress: isEdit ? this.handleModalEditItem : this.handleModalAddItem,
          backgroundColor: palette.neutral
        }
      ]

      return (
        <PageWidthModal isVisible={modalVisible}>
          <View>
            <View className='mb-6 border-b-1 border-gray-400 pb-7'>
              <Text className='text-primary text-h3'>{isEdit ? t('編輯') : t('新增')}</Text>
            </View>
            <BorderPanel>
              <FieldGroup
                ref={this.inputRef}
                control={this.inputControl}
                render={() => this.renderForm(undefined, this.inputControl)}
              />
            </BorderPanel>
            <PageStepControl buttons={buttons} />
          </View>
        </PageWidthModal>
      )
    }

    render() {
      const {
        t,
        wrapper: Wrapper = ArrayForm.Wrapper,
        itemsContainer: ItemsContainer = this.props.isSortable
          ? this.SortableItemsContainer
          : ArrayForm.ItemsContainer,
        itemWrapper: ItemWrapper = ArrayForm.ItemWrapper,
        editor = true,
        options,
        isModalEditor = false,
        isSortable = false,
        emptyWording = '',
        disabledTitle = ''
      } = this.props

      const showable = _.get(options, 'display.showable', true)
      const itemPanelStyle = _.get(options, 'display.containerStyle', {})
      const sortableKeys = []
      const disabledKeys = []

      this.control.controls?.forEach((item, index) => {
        const isSortDisabled = item?.value?.sortDisabled
        if (isSortable && isSortDisabled) {
          disabledKeys.push(index)
        } else {
          sortableKeys.push(index)
        }
      })

      const isSortDisabledItemsVisible = isSortable && disabledKeys.length > 0

      return !showable || this.control._hiding ? null : (
        <Wrapper onAdd={this.handleAddItem} onMove={this.handleMoveItem}>
          {editor && (
            <ItemWrapper t={t} onAdd={this.handleAddItem}>
              <FieldGroup
                ref={this.inputRef}
                control={this.inputControl}
                render={() => (
                  <ArrayFormItemContext.Provider value={this.inputControl.value}>
                    {this.renderForm(undefined, this.inputControl)}
                  </ArrayFormItemContext.Provider>
                )}
              />
            </ItemWrapper>
          )}
          {isModalEditor && this.renderModalEditor()}
          {isModalEditor && (
            <View className='mb-4 flex flex-row justify-end'>
              <DSButton
                className='grow-0'
                intent='normal'
                label={t('COMMON.BUTTON_ADD')}
                icon='add'
                onPress={() => this.handleOpenEditor(null)}
              />
            </View>
          )}
          <ItemsContainer
            style={itemPanelStyle}
            items={this.control?.value}
            {...(isSortable
              ? {
                  onChange: this.handleSort,
                  items: this.control?.value
                }
              : {})}
          >
            {sortableKeys.map(this.renderItem)}
          </ItemsContainer>
          {isSortDisabledItemsVisible && (
            <View className='mt-6 border-t-1 border-gray-400 pt-6'>
              {disabledTitle && (
                <Text className='mb-6 text-[18px] text-primary'>{disabledTitle}</Text>
              )}
              {disabledKeys.map(this.renderItem)}
            </View>
          )}
          {_.isEmpty(sortableKeys) && _.isEmpty(disabledKeys) && <Text>{emptyWording}</Text>}
        </Wrapper>
      )
    }
  }
)

class FormComponent extends React.Component {
  constructor(props) {
    super(props)
    const { validators, asyncValidators, updateOn, afterCreate } = props
    this.form = this.buildForm(
      props.formConfig,
      props.value,
      { validators, asyncValidators, updateOn },
      afterCreate
    )
    this.arrayRefs = React.createRef()
    this.arrayRefs.current = {}
  }

  unsubscribes = []

  componentDidMount() {
    this.form.valueChanges.subscribe(() => {
      const onChange = this.props.onValueChange || this.props.onChange
      if (onChange) {
        onChange(this.form.value)
      }
    })
  }

  componentWillUnmount() {
    this.form.valueChanges.unsubscribe()
    _(this.unsubscribes)
      .compact()
      .flatten()
      .map((f) => typeof f === 'function' && f())
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps.formConfig, this.props.formConfig)
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.formConfig, this.props.formConfig)) {
      debug('formConfig changed!!')
      this.props.formConfig.map((c) => {
        this.traversalMetas(
          c,
          prevProps.formConfig.find((p) => c.key === p.key)
        )
      })
    }

    if (
      !_.isEqual(this.props.value, prevProps.value) &&
      !_.isEqual(this.props.value, this.form.value)
    ) {
      debug('form value changed!!!')
      this.form.patchValue(this.props.value, {
        emitEvent: false
      })
    }
  }

  traversalMetas(current, prev, paths = []) {
    if (current.form && _.get(prev, 'form')) {
      current.form.map((c) => {
        this.traversalMetas(
          c,
          prev.form.find((p) => c.key === p.key),
          current.group === false ? paths : paths.concat([current.key])
        )
      })
    }
    if (current.isStatic !== false) {
      return
    }

    if (!_.isEqual(current.disabled, prev.disabled)) {
      const control = this.getControl(paths.concat([current.key]))
      if (current.disabled) {
        control.disable()
      } else {
        control.enable()
      }
    }

    if (!_.isEqual(current.meta, prev.meta)) {
      debug(`${current.key}'s meta is dirty`)
      const control = this.getControl(paths.concat([current.key]))
      control.meta = current.meta
      control.stateChanges.next()
    }

    if (!_.isEqual(_.get(current, 'options.display'), _.get(prev, 'options.display'))) {
      debug(`${current.key}'s options is dirty`)
      const control = this.getControl(paths.concat([current.key]))
      const showable = _.get(current, 'options.display.showable', true)
      const prevShowable = _.get(prev, 'options.display.showable', true)
      control.validatorsOrOpts = current.options
      if (prevShowable && !showable) {
        control.disable({
          onlySelf: true,
          emitEvent: false
        })
      } else if (!prevShowable && showable) {
        control.enable({
          onlySelf: true,
          emitEvent: false
        })
      }
      control.stateChanges.next()
    }
  }

  reduceForm(control, fn = (i) => i, name) {
    if (control.controls) {
      const res = _.flatMap(control.controls, (child, key) => {
        return this.reduceForm(child, fn, key)
      })
      res.push(fn(control, name, true))
      return res
    }
    return fn(control, name)
  }

  get value() {
    return this.form.value
  }

  get valid() {
    return this.form.valid
  }

  get dirty() {
    return this.form.dirty
  }

  markAsPristine() {
    this.form.markAsPristine()
  }

  formatErrors(_key, errors) {
    return {
      _key,
      ..._.mapValues(errors, (error, type) => {
        return {
          ...error,
          _message: errorToString(error, type)
        }
      })
    }
  }

  get errors() {
    return _(
      this.reduceForm(this.form, (control, _key, isGroup = false) => {
        if (!control.errors || !_key) {
          return null
        }

        return this.formatErrors(_key, control.errors)
      })
    )
      .flatten()
      .compact()
      .value()
  }

  reset = (value, options) => {
    return this.form.reset(value, options)
  }

  getControl = (paths) => {
    if (paths.length === 0) {
      return this.form
    }

    return this.form.get(paths.join('.'))
  }

  buildForm = (configs, value, options, afterCreate, asArray) => {
    const reducer = (result, config, index) => {
      if (asArray) {
        config.key = index
      }

      if (['PageStepControl', 'Button'].includes(config.type)) {
        return result
      }

      if (_.isArray(config.form) && config.group === false) {
        result = config.form.reduce(reducer, result)
        return result
      }

      let defaultValue = _.get(value, config.key, config.formState)
      if (typeof config.preProcess === 'function') {
        defaultValue = config.preProcess(defaultValue)
      }

      if (config.type === 'Array') {
        defaultValue = _.isArray(defaultValue) ? defaultValue : []
        result[config.key] = FormBuilder.array(
          defaultValue.map((item, key) => {
            const childForm = this.buildForm(config.form, item)
            if (typeof config.childFormAfterCreate === 'function') {
              this.unsubscribes.push(config.childFormAfterCreate(childForm, key))
            }
            return childForm
          })
        )
      } else if (_.isArray(config.form)) {
        defaultValue = _.isObject(defaultValue) ? defaultValue : {}
        result[config.key] = this.buildForm(
          config.form,
          defaultValue,
          undefined,
          undefined,
          config.asArray
        )
      } else {
        if (config.type === 'Picker' && _.isEqual(_.get(config, 'meta.placeholder'), {})) {
          defaultValue =
            typeof defaultValue === 'undefined'
              ? _.get(config, 'meta.items[0].value')
              : defaultValue
        }

        result[config.key] = new FormControl(defaultValue, config.options)

        if (this.props.filterHasValue) {
          if (!defaultValue) {
            result[config.key]._hiding = true
          }
        }

        if (config.type === 'Picker' && _.isEqual(_.get(config, 'meta.placeholder'), {})) {
          result[config.key]._originalReset = result[config.key].reset
          result[config.key].reset = (formState, options) => {
            result[config.key]._originalReset(
              formState || _.get(config, 'meta.items[0].value'),
              options
            )
          }
        }

        if (['LabelSelector'].includes(config.type)) {
          result[config.key]._originalReset = result[config.key].reset
          result[config.key].reset = (formState, options) => {
            result[config.key]._originalReset(
              formState || _.get(config, 'meta.items[0].value'),
              options
            )
          }
        }

        if (
          ['Switch', 'Checkbox', 'RadioButton', 'Checkboxes', 'TagSelector'].includes(config.type)
        ) {
          result[config.key]._originalReset = result[config.key].reset
          result[config.key].reset = (options) => {
            const formState =
              result[config.key].formState === 0 ? 0 : result[config.key].formState || null
            result[config.key]._originalReset(formState, options)
          }
        }

        if (['Image'].includes(config.type)) {
          result[config.key]._originalReset = result[config.key].reset
          result[config.key].reset = (options) => {
            const formState =
              (result[config.key].formState?.length ?? 0) === 0
                ? []
                : result[config.key].formState || null
            result[config.key]._originalReset(formState, options)
          }
        }
      }
      if (typeof config.preProcess === 'function') {
        result[config.key]._preProcess_originalSetValue = result[config.key].setValue
        result[config.key].setValue = function (value, ...args) {
          result[config.key]._preProcess_originalSetValue(config.preProcess(value), ...args)
        }

        if (config.form) {
          result[config.key]._preProcess_originalPatchValue = result[config.key].patchValue
          result[config.key].patchValue = function (value, ...args) {
            result[config.key]._preProcess_originalPatchValue(config.preProcess(value), ...args)
          }
        }
      }
      if (typeof config.postProcess === 'function') {
        result[config.key]._postProcess_originalUpdateValue = result[config.key]._updateValue
        result[config.key].value = config.postProcess(result[config.key].value)
        result[config.key]._updateValue = function () {
          result[config.key]._postProcess_originalUpdateValue()
          result[config.key].value = config.postProcess(result[config.key].value)
        }
      }

      if (config.disabled) {
        result[config.key].disable()
      }

      Object.assign(result[config.key], {
        hide() {
          if (this._hiding) {
            return
          }
          this._hiding = true
          this.stateChanges.next()
        },
        show() {
          if (!this._hiding) {
            return
          }
          this._hiding = false
          this.stateChanges.next()
        }
      })

      if (typeof config.afterCreate === 'function') {
        this.unsubscribes.push(config.afterCreate(result[config.key]))
      }

      return result
    }

    const control = new (asArray ? FormArray : FormGroup)(
      configs.reduce(reducer, asArray ? [] : {}),
      options
    )
    if (typeof afterCreate === 'function') {
      this.unsubscribes.push(afterCreate(control))
    }
    return control
  }

  renderGroup = (
    { type, form, group, name, meta, wrapper: WrapComponent = View, options },
    layer,
    root
  ) => {
    switch (type) {
      case 'Group':
      case 'BorderPanel':
        WrapComponent = BorderPanel
        break
      case 'Setion':
      case 'FoldablePanel':
        WrapComponent = FoldablePanel
        break
      case 'Container':
      case 'TitlePanel':
        WrapComponent = TitlePanel
        break
    }
    return _.get(options, 'display.showable') !== false &&
      !_.get(root.get(name), '_hiding', false) ? (
      <WrapComponent key={name} {...meta}>
        {!_.isEmpty(form) &&
          form.map((f, i) => {
            return (
              <React.Fragment key={`${i}`}>
                {this.renderForm(f, group ? `${name}.` : layer)}
              </React.Fragment>
            )
          })}
      </WrapComponent>
    ) : undefined
  }

  renderControl =
    (renderItem) =>
    ({ meta, validatorsOrOpts, _hiding = false, ...restProps }) => {
      const {
        errStyle,
        containerStyle,
        renderContainerStyle,
        renderItemStyle,
        isColumn = false,
        label,
        labelStyle,
        labelContainerStyle,
        postfixLabel,
        postfixLabelStyle,
        keeponColumn = true,
        commentText,
        commentTextStyle,
        restriction,
        errorContainerStyle
      } = _.get(validatorsOrOpts, 'display', {})

      return _hiding ? null : (
        <StoreContext.Consumer>
          {(stores) => {
            const { store } = stores
            const { layout } = store.viewStore
            const isColumnDisplay = (isColumn || layout !== 'desktop') && keeponColumn
            const hasMarkdownRestriction = restriction?.max > 0 || restriction?.min > 0

            return (
              <View style={[{ marginBottom: 15 }, containerStyle]}>
                {isColumnDisplay ? (
                  <>
                    <View
                      style={[
                        manipulator.container('row', 'flex-start', 'baseline'),
                        labelContainerStyle
                      ]}
                    >
                      {!_.isEmpty(label) &&
                        (typeof label === 'string' ? (
                          <ThemedLabel style={labelStyle}>{label}</ThemedLabel>
                        ) : (
                          label
                        ))}
                    </View>
                    <View
                      style={[
                        { flex: 1 },
                        template.rowContainer,
                        { alignItems: 'center' },
                        renderContainerStyle
                      ]}
                    >
                      <View style={[{ width: '100%' }, renderItemStyle]}>
                        {renderItem({ meta, validatorsOrOpts, ...restProps })}
                      </View>
                      {!_.isEmpty(postfixLabel) &&
                        (typeof postfixLabel === 'string' ? (
                          <ThemedLabel style={[{ width: 100, marginLeft: 10 }, postfixLabelStyle]}>
                            {postfixLabel}
                          </ThemedLabel>
                        ) : (
                          postfixLabel
                        ))}
                    </View>
                    {hasMarkdownRestriction && (
                      <MarkdownRestriction
                        value={restProps.value}
                        isColumnDisplay={isColumnDisplay}
                        restriction={restriction}
                      />
                    )}
                    {!_.isEmpty(commentText) && (
                      <ThemedLabel
                        style={[
                          {
                            width: '100%',
                            lineHeight: 15,
                            fontSize: 12,
                            color: palette.gray,
                            marginTop: 3
                          },
                          commentTextStyle
                        ]}
                      >
                        {commentText}
                      </ThemedLabel>
                    )}
                    <ErrorMessage
                      style={[errStyle]}
                      touched={restProps.touched || restProps.submitted}
                      errors={restProps.errors}
                      pristine={restProps.pristine}
                    />
                  </>
                ) : (
                  <>
                    {/* ios App會因此閃退 問題未知 */}
                    <View
                      style={[
                        manipulator.container(
                          'row',
                          'flex-start',
                          Platform.OS === 'web' ? 'baseline' : 'center'
                        ),
                        renderContainerStyle
                      ]}
                    >
                      {!_.isEmpty(label) &&
                        (typeof label === 'string' ? (
                          <ThemedLabel
                            style={[
                              { minWidth: 150, maxWidth: 150, lineHeight: 22, marginRight: 10 },
                              labelStyle
                            ]}
                          >
                            {label}
                          </ThemedLabel>
                        ) : (
                          label
                        ))}
                      <View style={[{ flex: 1 }, renderItemStyle]}>
                        {renderItem({ meta, validatorsOrOpts, ...restProps })}
                        {hasMarkdownRestriction && (
                          <MarkdownRestriction
                            value={restProps.value}
                            isColumnDisplay={isColumnDisplay}
                            restriction={restriction}
                          />
                        )}
                        {!_.isEmpty(commentText) && (
                          <ThemedLabel
                            style={[
                              {
                                width: '100%',
                                lineHeight: 15,
                                fontSize: 12,
                                color: palette.gray,
                                marginTop: 3
                              },
                              commentTextStyle
                            ]}
                          >
                            {commentText}
                          </ThemedLabel>
                        )}
                        <View style={errorContainerStyle}>
                          <ErrorMessage
                            style={[errStyle]}
                            touched={restProps.touched || restProps.submitted}
                            errors={restProps.errors}
                          />
                        </View>
                      </View>
                      {!_.isEmpty(postfixLabel) &&
                        (typeof postfixLabel === 'string' ? (
                          <ThemedLabel style={[{ width: 100, marginLeft: 10 }, postfixLabelStyle]}>
                            {postfixLabel}
                          </ThemedLabel>
                        ) : (
                          postfixLabel
                        ))}
                    </View>
                  </>
                )}
              </View>
            )
          }}
        </StoreContext.Consumer>
      )
    }

  renderForm = (config, layer = '', root = this.form) => {
    const { type, form, group = true, ...restConfig } = config
    restConfig.name = `${layer}${restConfig.name || restConfig.key}`
    if (
      [
        'Group',
        'BorderPanel',
        'Setion',
        'FoldablePanel',
        'Container',
        'TitlePanel',
        'Wrapper'
      ].includes(type)
    ) {
      return this.renderGroup({ type, form, group, ...restConfig }, layer, root)
    }

    if (type === 'Array') {
      const arrayRef = React.createRef()
      this.arrayRefs.current[restConfig.name] = arrayRef
      return (
        <ArrayForm
          ref={arrayRef}
          buildForm={this.buildForm}
          renderForm={this.renderForm}
          root={root}
          form={form}
          {...restConfig}
        />
      )
    }

    let renderItem
    const props = {
      ...restConfig
    }

    if (type === 'Hidden') {
      return <FieldControl strict {...restConfig} render={() => null} />
    }

    if (type === 'Button') {
      return <ThemedButton {...restConfig.meta}>{restConfig.meta.text}</ThemedButton>
    }

    if (type === 'PageStepControl') {
      const { meta, options } = config
      const showable = _.get(options, 'display.showable', true)
      const style =
        meta.buttons.length > 1 ? { flexDirection: 'row', justifyContent: 'space-between' } : {}

      return showable ? (
        <PageStepControl
          key={`PageStepControl-${meta.buttons.map((each) => each.type).join('-')}`}
          buttons={meta.buttons}
          isRootDisabled={meta?.isRootDisabled || false}
          style={style}
        />
      ) : null
    }
    switch (type) {
      case 'Picker':
        renderItem = ({ handler, meta }) => {
          const { onChange, disabled, editable, ...handlers } = handler()
          return (
            <ThemedPicker
              key='picker'
              onValueChange={onChange}
              disabled={Platform.OS === 'web' ? disabled : !editable}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'TextInput':
        renderItem = ({ handler, meta }) => {
          const { onChange, disabled, editable, ...handlers } = handler()
          return (
            <ThemedTextInput
              key='input'
              {...handlers}
              onChangeText={onChange}
              disabled={Platform.OS === 'web' ? disabled : !editable}
              {...meta}
            />
          )
        }
        break
      case 'Markdown':
        renderItem = ({ handler, meta, ...rest }) => {
          return <MarkdownEditor key='mk' {...handler()} {...meta} {...rest} />
        }
        break
      case 'ExtendableTextInput':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedExtendableTextInput {...handlers} onChange={onChange} {...meta} />
        }
        break
      case 'RadioButton':
        renderItem = ({ handler, meta }) => {
          return <ThemedRadioButton {...handler()} {...meta} />
        }
        break
      case 'TimeSelector':
        renderItem = ({ handler, meta }) => {
          return <ThemedTimeSelector {...handler()} {...meta} />
        }
        break
      case 'Checkbox':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedCheckBox onValueChange={onChange} {...handlers} {...meta} />
        }
        break
      case 'Text':
        renderItem = ({ meta }) => {
          const { style = {}, label } = meta
          const textStyle = { fontSize: 14, fontWeight: '400' }
          return <Text style={[textStyle, style]}>{label}</Text>
        }
        break
      case 'TagSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedTagSelector onValueChange={onChange} {...handlers} {...meta} />
        }
        break
      case 'LandmarkSelector':
        renderItem = ({ handler, meta }) => {
          const { ...handlers } = handler()
          return <ThemedLandmarkSelector {...handlers} {...meta} />
        }
        break
      case 'TextTag':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedTextTag onValueChange={onChange} {...handlers} {...meta} />
        }
        break
      case 'Palette':
        renderItem = ({ handler, meta }) => {
          return <ThemedColorSelector {...handler()} {...meta} />
        }
        break
      case 'Checkboxes':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedCheckBoxes onValueChange={onChange} {...handlers} {...meta} />
        }
        break
      case 'FilterableTable':
        renderItem = ({ handler, meta }) => {
          return <FilterableTable {...handler()} {...meta} />
        }
        break
      case 'FilterableSelector':
        renderItem = ({ handler, meta }) => {
          return <FilterableSelector {...handler()} {...meta} />
        }
        break
      case 'LabelSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedLabelSelector onValueChange={onChange} {...handlers} {...meta} />
        }
        break
      case 'Switch':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedSwitch onValueChange={onChange} {...handlers} {...meta} />
        }
        break
      case 'ProductSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return (
            <CustomSelector
              type={SelectorCategory.Product}
              onChange={onChange}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'CouponSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return (
            <CustomSelector
              type={SelectorCategory.Coupon}
              onChange={onChange}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'DeviceSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return (
            <CustomSelector
              type={SelectorCategory.Device}
              onChange={onChange}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'DeviceOperationSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return (
            <CustomSelector
              type={SelectorCategory.DeviceOperation}
              onChange={onChange}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'ResourceSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return (
            <CustomSelector
              type={SelectorCategory.Resource}
              onChange={onChange}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'TicketSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return (
            <CustomSelector
              type={SelectorCategory.Ticket}
              onChange={onChange}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'DatePicker':
        renderItem = ({ handler, meta, formState }) => {
          const { onChange, value, disabled, editable } = handler()
          const content = (
            <DatePicker
              value={
                _.isEmpty(value)
                  ? _.isEmpty(formState)
                    ? undefined
                    : moment(formState)
                  : moment(value)
              }
              onChange={onChange}
              disabled={Platform.OS === 'web' ? disabled : !editable}
              {...meta}
            />
          )
          return content
        }
        break
      case 'DateRangePicker':
        renderItem = ({ handler, meta, formState }) => {
          const { onChange, value } = handler()
          const content = (
            <DateRangePicker {...handler()} value={value} onChange={onChange} {...meta} />
          )
          return content
        }
        break
      case 'NumberSelector':
        renderItem = ({ handler, meta }) => {
          return <ThemedNumberSelector {...handler()} {...meta} />
        }
        break
      case 'Image':
        renderItem = ({ handler, meta }) => {
          return <ImageUploader {...handler()} {...meta} />
        }
        break
      case 'SearchSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, disabled, editable, ...handlers } = handler()
          return (
            <SearchSelector
              onChange={onChange}
              disabled={Platform.OS === 'web' ? disabled : !editable}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'CountryPhoneInput':
        renderItem = ({ handler, meta }) => {
          const { onChange, disabled, editable, ...handlers } = handler()
          return (
            <CountryPhoneInput
              onChange={onChange}
              disabled={Platform.OS === 'web' ? disabled : !editable}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'OptionGroupInput':
        renderItem = ({ handler, meta }) => {
          const { onChange, disabled, editable, ...handlers } = handler()
          return (
            <ThemedOptionGroupInput
              onChange={onChange}
              disabled={Platform.OS === 'web' ? disabled : !editable}
              {...handlers}
              {...meta}
            />
          )
        }
        break
      case 'RegionLandmarkSelector':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedRegionLandmarkSelector onChange={onChange} {...handlers} {...meta} />
        }
        break
      case 'WeekdayTimeRangePanel':
        renderItem = ({ handler, meta }) => {
          const { onChange, ...handlers } = handler()
          return <ThemedWeekdayTimeRangePanel onChange={onChange} {...handlers} {...meta} />
        }
        break
      case 'Custom':
        renderItem = restConfig.renderItem
        break
      default:
        _.set(props, 'options.updateOn', 'blur')
        renderItem = ({ handler, meta }) => {
          const { label, placeholder, ...restMeta } = meta
          const { onChange, value, ...handlers } = handler()
          const jsonValue = typeof value === 'object' ? JSON.stringify(value) : value
          return (
            <ThemedTextInput
              key='input'
              multiline
              onChangeText={(value) => {
                try {
                  onChange(JSON.parse(value))
                } catch (e) {
                  onChange(value)
                }
              }}
              value={jsonValue}
              {...handlers}
              placeholder={`[UNIMPLEMENT <${type}> COMPONENT]\nWrite JSON here as value`}
              {...restMeta}
            />
          )
        }
        break
    }

    const { showable = true } = _.get(config.options, 'display', {})

    props.render = this.renderControl(renderItem)

    props.options = Object.assign({}, props.options)
    _.unset(props.options, 'validators')
    return showable ? <FieldControl {...props} /> : null
  }

  render() {
    const { formConfig, isStrict = true } = this.props

    return (
      <FieldGroup
        control={this.form}
        strict={isStrict}
        render={() => <>{formConfig.map((f) => this.renderForm(f))}</>}
      />
    )
  }
}

export { BorderPanel, FoldablePanel, FormComponent, PageStepControl, TitlePanel, errorToString }
