import styled from '@emotion/native'
import { MaterialIcons } from '@expo/vector-icons'
import { palette, template } from '@rezio/res/theme'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'react-native'

import { ThemedButton } from './ThemedButton'
import { ThemedTextInput } from './ThemedTextInput'

interface Props {
  tagItems: string[]
  placeholder?: string
  onAddTag: (newItemList: string[]) => void
  onError: (errMsg: string) => void
}

export const TagListBuilder = ({ tagItems, placeholder, onAddTag, onError }: Props) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const handleAddTag = () => {
    if (tagItems.includes(inputValue)) return onError(t('PRODUCT.TEXT_TAG_DUPLICATE_ERROR'))
    onAddTag([...tagItems, inputValue])
    setInputValue('')
    onError('')
  }

  return (
    <TextInputContainer {...{ tagItems }}>
      <ThemedTextInput
        placeholder={placeholder || ''}
        style={{ marginRight: 10, flex: 1 }}
        value={inputValue}
        onChangeText={setInputValue}
        onSubmitEditing={handleAddTag}
      />
      <ThemedButton disabled={!inputValue} style={addButtonStyle} onPress={handleAddTag}>
        <MaterialIcons name='add' size={20} color={palette.white} />
        <Text style={addTextStyle}>{t('PRODUCT.BUTTON_ADD_TEXT_TAG')}</Text>
      </ThemedButton>
    </TextInputContainer>
  )
}

const addTextStyle = { color: palette.white, marginLeft: 4 }

const addButtonStyle = { width: 100, backgroundColor: palette.lightBrand, padding: 7 }

const TextInputContainer = styled.View<{ tagItems: string[] }>(
  template.rowContainer,
  { display: 'flex', paddingBottom: 5 },
  ({ tagItems }) => (!tagItems.length ? { marginBottom: 10 } : {})
)
