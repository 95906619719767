// modified from https://github.com/n4kz/react-native-material-dropdown
import _ from 'lodash'
import { nanoid } from 'nanoid/non-secure'
import React, { PureComponent, createContext, useState, useMemo, useLayoutEffect } from 'react'
import {
  View,
  StyleSheet,
  Animated,
  ScrollView,
  Dimensions,
  Platform,
  I18nManager,
  findNodeHandle
} from 'react-native'
import { BlackPortal, WhitePortal } from 'react-native-portal'

import { template } from '../res/theme'
import { HoverableOpacity } from './hoverableOpacity'

const DropdownContext = createContext({})

export function DropdownBaseView(props) {
  const [hostId, setHostId] = useState()
  const containerRef = React.useRef()
  useLayoutEffect(() => {
    let mounted = true
    ;(async function () {
      const id = nanoid()
      mounted && setHostId(id)
    })()
    return () => {
      mounted = false
    }
  }, [])

  const context = useMemo(
    () => ({
      hostId,
      ref: containerRef
    }),
    [containerRef, hostId]
  )

  return hostId ? (
    <DropdownContext.Provider value={context}>
      <View ref={containerRef} {...props}>
        {props.children}
      </View>
      <WhitePortal name={hostId} />
    </DropdownContext.Provider>
  ) : null
}

// export function DropdownHost (props) {
//   const [hostId, setHostId] = useState()
//   const testRef = React.useRef()
//   useEffect(() => {
//     ;(async function () {
//       setHostId(nanoid())
//     })()
//   }, [])

//   const context = useMemo(() => ({
//     hostId,
//     ref: _.get(testRef, 'current')
//   }), [testRef, hostId])

//   return hostId ? <DropdownContext.Provider ref={testRef} value={context}>
//     {props.children}
//     <WhitePortal name={hostId} />
//   </DropdownContext.Provider> : null
// }

const styles = StyleSheet.create({
  accessory: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center'
  },

  triangle: {
    width: 8,
    height: 8,
    transform: [
      {
        translateY: -4
      },
      {
        rotate: '45deg'
      }
    ]
  },

  triangleContainer: {
    width: 12,
    height: 6,
    overflow: 'hidden',
    alignItems: 'center',

    backgroundColor: 'transparent' /* XXX: Required */
  },

  overlay: {
    ...StyleSheet.absoluteFillObject
  },

  picker: {
    backgroundColor: 'rgba(255, 255, 255, 1.0)',
    borderRadius: 2,
    position: 'absolute'
  },

  item: {
    textAlign: 'left'
  },

  scroll: {
    flex: 1,
    borderRadius: 2
  },

  scrollContainer: {
    paddingVertical: 8
  }
})

export default class Dropdown extends PureComponent {
  static contextType = DropdownContext

  static defaultProps = {
    hitSlop: { top: 6, right: 4, bottom: 6, left: 4 },

    disabled: false,

    data: [],

    labelExtractor: ({ label } = {}, index) => label,
    propsExtractor: () => null,

    absoluteRTLLayout: false,

    dropdownOffset: {
      top: 40,
      left: 0
    },

    dropdownMargins: {
      min: 8,
      max: 16
    },

    shadeOpacity: 0.12,
    animationDuration: 100,

    fontSize: 16,

    textColor: 'rgba(0, 0, 0, .87)',
    baseColor: 'rgba(0, 0, 0, .38)',

    supportedOrientations: [
      'portrait',
      'portrait-upside-down',
      'landscape',
      'landscape-left',
      'landscape-right'
    ],

    useNativeDriver: false
  }

  constructor(props) {
    super(props)

    this.onClose = this.onClose.bind(this)
    this.handleWrapperOnLayout = this.handleWrapperOnLayout.bind(this)

    this.updateContainerRef = this.updateRef.bind(this, 'container')
    this.updateScrollRef = this.updateRef.bind(this, 'scroll')

    this.handleModalBlur = () => this.onClose()
    this.close = () => this.onClose()
    this.focus = this.onPress

    this.mounted = false
    this.focused = false

    this.state = {
      opacity: new Animated.Value(0),
      modal: false
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onPress = (event) => {
    const {
      disabled,
      onFocus,
      dropdownOffset,
      dropdownMargins: { min: minMargin, max: maxMargin },
      animationDuration,
      absoluteRTLLayout,
      useNativeDriver
    } = this.props

    if (disabled) {
      return
    }

    const timestamp = Date.now()

    this.focused = true

    if (typeof onFocus === 'function') {
      onFocus()
    }
    const dimensions = Dimensions.get('window')
    this.container.measureLayout(findNodeHandle(_.get(this.context, 'ref.current')), (...args) => {
      let [x, y, containerWidth] = args
      const { opacity } = this.state

      /* Adjust coordinates for relative layout in RTL locale */
      if (I18nManager.isRTL && !absoluteRTLLayout) {
        x = dimensions.width - (x + containerWidth)
      }

      const delay = Math.max(0, 0 - animationDuration - (Date.now() - timestamp))

      let leftInset
      let left = x + dropdownOffset.left - maxMargin

      if (left > minMargin) {
        leftInset = maxMargin
      } else {
        left = minMargin
        leftInset = minMargin
      }

      let right = x + containerWidth + maxMargin
      let rightInset

      if (dimensions.width - right > minMargin) {
        rightInset = maxMargin
      } else {
        right = dimensions.width - minMargin
        rightInset = minMargin
      }
      const top = y + dropdownOffset.top

      this.setState({
        modal: true,
        width: right - left,
        top,
        left,
        leftInset,
        rightInset
      })

      setTimeout(() => {
        if (this.mounted) {
          this.resetScrollOffset()

          Animated.timing(opacity, {
            duration: animationDuration,
            toValue: 1,
            useNativeDriver
          }).start(() => {
            if (this.mounted && Platform.OS === 'ios') {
              const { flashScrollIndicators } = this.scroll || {}

              if (typeof flashScrollIndicators === 'function') {
                flashScrollIndicators.call(this.scroll)
              }
            }
          })
        }
      }, delay)
    })
  }

  onClose() {
    const { onBlur, animationDuration, useNativeDriver } = this.props
    const { opacity } = this.state

    Animated.timing(opacity, {
      duration: animationDuration,
      toValue: 0,
      useNativeDriver
    }).start(() => {
      this.focused = false

      if (typeof onBlur === 'function') {
        onBlur()
      }

      if (this.mounted) {
        this.setState({ modal: false })
      }
    })
  }

  handleWrapperOnLayout(event) {
    const { onLayout } = this.props

    if (typeof onLayout === 'function') {
      onLayout(event)
    }
  }

  isFocused() {
    return this.focused
  }

  resetScrollOffset() {
    const offset = 0
    if (this.scroll) {
      this.scroll.scrollToOffset({ offset, animated: false })
    }
  }

  updateRef(name, ref) {
    this[name] = ref
  }

  render() {
    const {
      renderBase,
      renderAccessory,
      style,
      overlayStyle: overlayStyleOverrides,
      pickerStyle: pickerStyleOverrides,

      hitSlop,
      pressRetentionOffset,
      testID,
      nativeID,
      accessible,
      accessibilityLabel,

      supportedOrientations,

      ...props
    } = this.props

    const { disabled } = props

    const { left, top, width, opacity, modal } = this.state

    const overlayStyle = { opacity }

    const pickerStyle = {
      width,
      // height,
      top,
      left
    }

    const touchableProps = {
      disabled,
      hitSlop,
      pressRetentionOffset,
      onPress: this.onPress,
      testID,
      nativeID,
      accessible,
      accessibilityLabel
    }

    return (
      <View onLayout={this.handleWrapperOnLayout} ref={this.updateContainerRef} style={style}>
        <HoverableOpacity
          style={[
            this.props.labelStyle,
            { justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' },
            this.props.layoutStyle
          ]}
          {...touchableProps}
        >
          {props.label}
        </HoverableOpacity>
        {this.context.hostId ? (
          <BlackPortal name={this.context.hostId}>
            {modal && (
              <View style={StyleSheet.absoluteFill}>
                <Animated.View
                  style={[styles.overlay, overlayStyle, overlayStyleOverrides]}
                  onStartShouldSetResponder={() => true}
                  onResponderRelease={this.handleModalBlur}
                >
                  <View
                    style={[styles.picker, pickerStyle, pickerStyleOverrides, template.shadow]}
                    onStartShouldSetResponder={() => true}
                  >
                    <ScrollView
                      nestedScrollEnabled
                      style={[
                        {
                          maxHeight: 300,
                          boxSizing: 'border-box'
                        },
                        props.menuStyle
                      ]}
                      onScroll={this.props.onScroll}
                      scrollEventThrottle={16}
                    >
                      {props.children}
                    </ScrollView>
                  </View>
                </Animated.View>
              </View>
            )}
          </BlackPortal>
        ) : null}
      </View>
    )
  }
}
