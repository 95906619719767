import {
  CountryPhoneInput,
  FilterableSelector,
  FilterableTable,
  HoverableOpacity,
  ImageUploader,
  MaterialIcons,
  SearchSelector,
  ThemedButton,
  ThemedCheckBox,
  ThemedCheckBoxes,
  ThemedColorSelector,
  ThemedCustomItems,
  ThemedExtendableTextInput,
  ThemedLabelSelector,
  ThemedLandmarkSelector,
  ThemedOptionGroupInput,
  ThemedPicker,
  ThemedRadioButton,
  ThemedRegionLandmarkSelector,
  ThemedSwitch,
  ThemedTagSelector,
  ThemedTextInput,
  ThemedTextTag,
  ThemedTimeSelector,
  ThemedWeekdayTimeRangePanel
} from '@rezio/components'
import { DatePicker } from '@rezio/components/datePicker2/datePicker'
import { DateRangePicker } from '@rezio/components/datePicker2/dateRangePicker'
import { BorderPanel } from '@rezio/components/formComponent'
import { MarkdownEditor } from '@rezio/components/markdown'
import { CustomSelector, SelectorCategory } from '@rezio/components/resource/customSelector'
import { ThemedNumberSelector } from '@rezio/components/shareComponents'
import { useLayout, useStores } from '@rezio/core/hooks'
import { manipulator, palette } from '@rezio/res/theme'
import _ from 'lodash'
import moment from 'moment'
import type { ReactNode } from 'react'
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import type { FieldError, UseFormReturn, FieldErrorsImpl, Merge } from 'react-hook-form'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import type { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Text, View } from 'react-native'

type LabelWithStyle =
  | {
      label?: string
      labelStyle?: StyleProp<TextStyle>
      labelTips?: ReactNode
    }
  | {
      label?: Exclude<ReactNode, string>
      labelStyle?: StyleProp<ViewStyle>
      labelTips?: ReactNode
    }

type PostfixLabelWithStyle =
  | {
      postfixLabel?: string
      postfixLabelStyle?: StyleProp<TextStyle>
    }
  | {
      postfixLabel?: Exclude<ReactNode, string>
      postfixLabelStyle?: StyleProp<ViewStyle>
    }

type FieldWrapperProps = PropsWithChildren<
  {
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
    info?: string | JSX.Element
    errStyle?: StyleProp<TextStyle>
    contentStyle?: StyleProp<ViewStyle>
    style?: StyleProp<ViewStyle>
    isColumn?: boolean
    isHiddenType?: boolean
  } & LabelWithStyle &
    PostfixLabelWithStyle
>

export type FormFieldType =
  | 'Picker'
  | 'Markdown'
  | 'ExtendableTextInput'
  | 'RadioButton'
  | 'TimeSelector'
  | 'Checkbox'
  | 'TagSelector'
  | 'LandmarkSelector'
  | 'TextTag'
  | 'Palette'
  | 'Checkboxes'
  | 'FilterableTable'
  | 'FilterableSelector'
  | 'LabelSelector'
  | 'Switch'
  | 'ProductSelector'
  | 'ResourceSelector'
  | 'TicketSelector'
  | 'DatePicker'
  | 'DateRangePicker'
  | 'NumberSelector'
  | 'Image'
  | 'SearchSelector'
  | 'CountryPhoneInput'
  | 'OptionGroupInput'
  | 'RegionLandmarkSelector'
  | 'WeekdayTimeRangePanel'
  | 'TextInput'
  | 'Array'
  | 'CustomItems'
  | 'DeviceSelector'
  | 'DeviceOperationSelector'
  | 'Hidden'
  | 'Text'
  | 'Custom'
interface FieldArrayFormProps {
  useFormReturn: UseFormReturn
  formConfig: { type: string; key: string; label?: string; form: object[] }[]
}

type FormFieldProps<T> = PropsWithChildren<
  {
    type: FormFieldType
    onChange?: (value: T) => void
    value?: T
    meta?: Record<string, any>
    disabled?: boolean
  } & LabelWithStyle &
    PostfixLabelWithStyle &
    FieldWrapperProps
>

export function FieldWrapper({
  label,
  labelStyle,
  postfixLabel,
  postfixLabelStyle,
  children,
  error,
  info,
  errStyle,
  style,
  contentStyle,
  isColumn,
  isHiddenType,
  labelTips,
  ...props
}: FieldWrapperProps) {
  const { t } = useStores()
  const { isMobile } = useLayout()
  const styles = {
    label: {
      minWidth: 140,
      maxWidth: 140,
      marginRight: 10,
      marginBottom: isMobile ? 10 : 0,
      marginTop: isMobile ? 5 : 10
    },
    postfixLabel: {
      marginLeft: 10,
      marginBottom: isMobile ? 10 : 0,
      marginTop: isMobile ? 5 : 10
    }
  }

  return (
    !isHiddenType && (
      <View
        style={[
          isMobile || isColumn
            ? manipulator.container('column', 'flex-start', 'flex-start')
            : manipulator.container('row', 'flex-start', 'flex-start'),
          {
            marginBottom: 20
          },
          isMobile ? { width: '100%' } : { flex: 1 },
          style
        ]}
      >
        {label ? (
          typeof label === 'string' ? (
            <Text style={[styles.label, labelStyle]}>
              {label}
              {labelTips || null}
            </Text>
          ) : (
            <View style={[styles.label, labelStyle]}>{label}</View>
          )
        ) : null}
        <View
          style={[
            isMobile || isColumn ? { width: '100%', flexWrap: 'wrap' } : { flex: 1 },
            contentStyle
          ]}
        >
          {children}
          {info && <Text style={[{ color: palette.text, paddingTop: 7 }, errStyle]}>{info}</Text>}
          {error && (
            <Text style={[{ color: palette.warning, paddingTop: 7 }, errStyle]}>
              {/* @ts-expect-error */}
              {error.message || t('FORM.ERROR', { context: error.type.toUpperCase() })}
            </Text>
          )}
        </View>
        {postfixLabel ? (
          typeof postfixLabel === 'string' ? (
            <Text style={[styles.postfixLabel, postfixLabelStyle]}>{postfixLabel}</Text>
          ) : (
            <View style={[styles.postfixLabel, postfixLabelStyle]}>{postfixLabel}</View>
          )
        ) : null}
      </View>
    )
  )
}

function FormField<T = any>({
  type,
  onChange,
  value,
  error,
  info,
  errStyle,
  meta = {},
  style,
  label,
  labelStyle,
  postfixLabel,
  postfixLabelStyle,
  contentStyle,
  isColumn,
  labelTips
}: FormFieldProps<T>): JSX.Element {
  let content: ReactNode = <View />
  const isHiddenType = type === 'Hidden'

  switch (type) {
    case 'Picker':
      content = <ThemedPicker onValueChange={onChange} value={value} {...meta} />
      break
    case 'Markdown':
      content = <MarkdownEditor onChange={onChange} value={value} {...meta} />
      break
    case 'ExtendableTextInput':
      content = <ThemedExtendableTextInput onChange={onChange} value={value} {...meta} />
      break
    case 'RadioButton':
      content = <ThemedRadioButton onChange={onChange} value={value} {...meta} />
      break
    case 'TimeSelector':
      content = <ThemedTimeSelector onChange={onChange} value={value} {...meta} />
      break
    case 'Checkbox':
      content = <ThemedCheckBox onChange={onChange} value={value} {...meta} />
      break
    case 'TagSelector':
      content = <ThemedTagSelector onValueChange={onChange} value={value} {...meta} />
      break
    case 'LandmarkSelector':
      content = <ThemedLandmarkSelector onChange={onChange} value={value} {...meta} />
      break
    case 'TextTag':
      content = <ThemedTextTag onValueChange={onChange} value={value} {...meta} />
      break
    case 'Palette':
      content = <ThemedColorSelector onChange={onChange} value={value} {...meta} />
      break
    case 'Checkboxes':
      content = <ThemedCheckBoxes onValueChange={onChange} value={value} {...meta} />
      break
    case 'FilterableTable':
      content = <FilterableTable onChange={onChange} value={value} {...meta} />
      break
    case 'FilterableSelector':
      content = <FilterableSelector onChange={onChange} value={value} {...meta} />
      break
    case 'LabelSelector':
      content = <ThemedLabelSelector onValueChange={onChange} value={value} {...meta} />
      break
    case 'Switch':
      content = <ThemedSwitch onValueChange={onChange} value={value} {...meta} />
      break
    case 'ProductSelector':
      content = (
        <CustomSelector
          type={SelectorCategory.Product}
          onChange={onChange}
          value={value}
          {...meta}
        />
      )
      break
    case 'ResourceSelector':
      content = (
        <CustomSelector
          type={SelectorCategory.Resource}
          onChange={onChange}
          value={value}
          {...meta}
        />
      )
      break
    case 'TicketSelector':
      content = (
        <CustomSelector
          type={SelectorCategory.Ticket}
          onChange={onChange}
          value={value}
          {...meta}
        />
      )
      break
    case 'DeviceSelector':
      content = (
        <CustomSelector
          type={SelectorCategory.Device}
          onChange={onChange}
          value={value}
          {...meta}
        />
      )
      break
    case 'DeviceOperationSelector':
      content = (
        <CustomSelector
          type={SelectorCategory.DeviceOperation}
          onChange={onChange}
          value={value}
          {...meta}
        />
      )
      break
    case 'DatePicker':
      content = (
        <DatePicker
          value={_.isEmpty(value) ? undefined : moment(value).format('YYYY-MM-DD')}
          // @ts-expect-error
          onChange={onChange}
          {...meta}
        />
      )
      break
    case 'DateRangePicker':
      content = (
        <DateRangePicker
          // @ts-expect-error
          value={value}
          // @ts-expect-error
          onChange={onChange}
          {...meta}
        />
      )
      break
    case 'NumberSelector':
      content = (
        <ThemedNumberSelector
          onChange={(value: number) => onChange(value as T)}
          value={+value}
          {...meta}
        />
      )
      break
    case 'Image':
      content = <ImageUploader onChange={onChange} value={value} {...meta} />
      break
    case 'SearchSelector':
      content = <SearchSelector onChange={onChange} value={value} {...meta} />
      break
    case 'CountryPhoneInput':
      content = <CountryPhoneInput onChange={onChange} value={value} {...meta} />
      break
    case 'OptionGroupInput':
      content = <ThemedOptionGroupInput onChange={onChange} value={value} {...meta} />
      break
    case 'RegionLandmarkSelector':
      content = <ThemedRegionLandmarkSelector onChange={onChange} value={value} {...meta} />
      break
    case 'WeekdayTimeRangePanel':
      content = <ThemedWeekdayTimeRangePanel onChange={onChange} value={value} {...meta} />
      break
    case 'CustomItems':
      content = <ThemedCustomItems onChange={onChange} value={value} {...meta} />
      break
    case 'Hidden':
    case 'Text':
      content = null
      break
    case 'Custom':
      content = meta?.renderItem?.({ onChange, value, meta }) ?? null
      break
    case 'TextInput':
    default:
      content = <ThemedTextInput onChangeText={onChange} value={value} {...meta} />
  }

  const wrapperConfig = {
    isColumn,
    isHiddenType,
    label,
    labelStyle,
    postfixLabel,
    postfixLabelStyle,
    contentStyle,
    style,
    error,
    info,
    errStyle,
    labelTips
  }
  return <FieldWrapper {...wrapperConfig}>{content}</FieldWrapper>
}

enum ArrayAction {
  Add = 'ADD',
  Edit = 'EDIT'
}
interface RenderFieldsProps {
  action: ArrayAction
  field?: {
    id: string
    [key: string]: any
  }
  index?: number
}

const CustomWrapper = ({ children, addMarginBottom }) => {
  return (
    <BorderPanel
      containerStyle={[
        manipulator.border('all', 'light', 5),
        { marginBottom: addMarginBottom ? 20 : 0 }
      ]}
    >
      {children}
    </BorderPanel>
  )
}

function renderGroup(
  {
    type,
    form,
    label,
    key,
    meta,
    wrapper: WrapComponent = View
  }: {
    type
    form
    label
    key
    meta
    wrapper: React.ComponentType
  },
  control,
  errors,
  fieldIndex
) {
  switch (type) {
    case 'BorderPanel':
      WrapComponent = BorderPanel
      break
  }
  return (
    <WrapComponent key={key} label={label} {...meta} fieldIndex={fieldIndex}>
      {(form ?? []).map((config) => (
        <FormFieldHandler key={config.key} {...{ config, control, errors, fieldIndex }} />
      ))}
    </WrapComponent>
  )
}

function FormFieldHandler({ config, control, errors, fieldIndex }) {
  const {
    type,
    key,
    label,
    labelStyle,
    labelTips,
    postfixLabel,
    postfixLabelStyle,
    form,
    style,
    contentStyle,
    meta,
    rules = {},
    ...restConfig
  } = config

  const isUnControlledFormField = ['Text'].includes(type)
  const defaultValue = useWatch({
    control,
    name: key
  })

  if (['BorderPanel', 'Wrapper'].includes(type)) {
    return renderGroup({ type, key, label, form, meta, ...restConfig }, control, errors, fieldIndex)
  }

  return isUnControlledFormField ? (
    <FormField
      type={type}
      label={label}
      labelStyle={labelStyle}
      labelTips={labelTips}
      postfixLabel={postfixLabel}
      postfixLabelStyle={postfixLabelStyle}
      style={style}
      contentStyle={contentStyle}
      meta={meta}
    />
  ) : (
    <Controller
      name={key}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormField
          onChange={onChange}
          value={value}
          type={type}
          error={_.get(errors, key)}
          label={label}
          labelStyle={labelStyle}
          labelTips={labelTips}
          postfixLabel={postfixLabel}
          postfixLabelStyle={postfixLabelStyle}
          style={style}
          contentStyle={contentStyle}
          meta={meta}
        />
      )}
    />
  )
}

const FieldArrayForm: React.FC<FieldArrayFormProps> = ({ useFormReturn, formConfig }) => {
  const {
    control,
    formState: { errors }
  } = useFormReturn
  const { fields } = useFieldArray({
    control,
    name: 'fieldArray'
  })

  return (
    <>
      {fields.map((field, fieldIndex) => (
        <View key={field.id}>
          {formConfig.map((config) => {
            return (
              <FormFieldHandler key={config.key} {...{ config, control, errors, fieldIndex }} />
            )
          })}
        </View>
      ))}
    </>
  )
}

function ArrayController(props) {
  const {
    control,
    errors,
    render,
    arrayName,
    renderConfig,
    hasQuickAdd = false,
    isEditMode = false,
    isShowAddButton = false,
    customButton = {},
    fieldContainerStyle = {},
    isDeletable = false,
    isSortable = true
  } = props

  const renderConfigUsage = useCallback(
    ({ fieldIndex = null } = {}) =>
      renderConfig instanceof Function
        ? renderConfig?.({ fieldIndex })
        : renderConfig?.length > 0
        ? renderConfig
        : [],
    [renderConfig]
  )
  const defaultFields =
    props.defaultFields ??
    _.reduce(
      renderConfigUsage(),
      (result, value) => {
        return {
          ...result,
          ...{ [value.key]: value.defaultValue ?? '' }
        }
      },
      {}
    )

  const {
    handleSubmit,
    control: newFieldControl,
    watch,
    reset,
    trigger,
    formState: { errors: newFieldErrors }
  } = useForm({ defaultValues: defaultFields, mode: 'onChange', shouldUnregister: false })
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: arrayName
  })
  const fieldState = watch()
  const handleNewItem = useCallback((values) => append(values), [])
  const handleUpItem = useCallback((index) => swap(index, Math.max(+index - 1, 0)), [])
  const handleDownItem = useCallback(
    (index) => swap(index, Math.min(+index + 1, +fields.length - 1)),
    []
  )
  const handleDeleteItem = useCallback(
    (index) => {
      remove(index)
    },
    [renderConfigUsage]
  )

  const onAppendItem = useCallback(async (value) => {
    await handleNewItem(value)
    reset(defaultFields)
  }, [])

  const onAppendCustomItem = useCallback(async (value, type, onAdd, key) => {
    const result = await trigger(`${key}`)

    if (result) {
      onAdd(type, value)
      reset(defaultFields)
    }
  }, [])

  const generateFields = useCallback((target) => {
    const fields = _.reduce(
      target,
      (result, value, key) => {
        return {
          ...result,
          ..._.set({}, key, value ?? '')
        }
      },
      {}
    )
    return fields
  }, [])

  useEffect(() => {
    if (!hasQuickAdd) return
    if (!_.isEmpty(customButton)) return
    if (
      JSON.stringify(generateFields(defaultFields)) === JSON.stringify(generateFields(fieldState))
    )
      return
    onAppendItem(fieldState).catch(console.error)
  }, [hasQuickAdd, defaultFields, fieldState])

  const buttons = [
    ...(isDeletable ? [{ iconName: 'delete', onPress: handleDeleteItem }] : []),
    ...(isSortable ? [{ iconName: 'arrow-upward', onPress: handleUpItem }] : []),
    ...(isSortable ? [{ iconName: 'arrow-downward', onPress: handleDownItem }] : [])
  ]

  const renderFields = useCallback(
    (props: RenderFieldsProps) => {
      const { action, field, index: fieldIndex } = props
      return renderConfigUsage({ fieldIndex }).map((item, index) => {
        const isEditMode = action === ArrayAction.Edit
        const {
          borderPanel,
          key,
          defaultValue,
          rules,
          type,
          label,
          labelStyle,
          style,
          meta,
          render
        } = item
        const defaultValueUsage = isEditMode ? field?.[key] ?? defaultValue : ''
        return borderPanel ? (
          <CustomWrapper
            key={index}
            addMarginBottom={index !== renderConfigUsage({ fieldIndex }).length - 1}
          >
            <Controller
              name={isEditMode ? `${arrayName}.${fieldIndex}.${key}` : key}
              control={isEditMode ? control : newFieldControl}
              rules={rules}
              defaultValue={defaultValueUsage}
              render={
                render ||
                (({ field: { onChange, value } }) => {
                  return (
                    <>
                      <FormField
                        type={type}
                        label={label}
                        labelStyle={labelStyle}
                        onChange={onChange}
                        value={value}
                        error={
                          isEditMode
                            ? _.get(errors, `${arrayName}.${fieldIndex}.${key}`)
                            : newFieldErrors?.[key]
                        }
                        style={style}
                        meta={meta}
                      />
                      {type === 'TextInput' && customButton.isVisible && (
                        <>
                          <Text
                            style={[
                              {
                                marginTop: 5,
                                marginLeft: 150,
                                color: palette.gray
                              },
                              customButton.remindTextStyle
                            ]}
                          >
                            {customButton.remindText}
                          </Text>
                          <View style={{ marginTop: 20 }}>
                            <ThemedButton
                              style={{
                                backgroundColor: palette.lightBlue,
                                minWidth: 56,
                                height: 40
                              }}
                              onPress={async () =>
                                await onAppendCustomItem(value, meta.type, meta.onAdd, key)
                              }
                            >
                              <Text style={{ color: palette.white, fontSize: 16 }}>
                                {customButton?.text}
                              </Text>
                            </ThemedButton>
                          </View>
                        </>
                      )}
                    </>
                  )
                })
              }
            />
          </CustomWrapper>
        ) : (
          <Controller
            key={index}
            name={isEditMode ? `${arrayName}.${fieldIndex}.${key}` : key}
            control={isEditMode ? control : newFieldControl}
            rules={rules}
            defaultValue={defaultValueUsage}
            render={
              render ||
              (({ field: { onChange, value } }) => {
                return (
                  <FormField
                    type={type}
                    onChange={onChange}
                    value={value}
                    error={
                      isEditMode
                        ? _.get(errors, `${arrayName}.${fieldIndex}.${key}`)
                        : newFieldErrors?.[key]
                    }
                    style={style}
                    meta={meta}
                  />
                )
              })
            }
          />
        )
      })
    },
    [arrayName, renderConfigUsage, newFieldErrors]
  )

  const renderer = render || renderFields
  const areFieldsDisabled = renderConfigUsage().every(
    ({ type, meta: { disabled = false } = {} }) => (type === 'Hidden' ? true : disabled)
  )
  const hasQuickAddShowable = useMemo(
    () => hasQuickAdd && (!areFieldsDisabled || (areFieldsDisabled && !fields.length)),
    [hasQuickAdd, areFieldsDisabled, fields]
  )

  return (
    <View>
      {fields.map((field, index) => (
        <View
          key={field.id}
          style={[
            { display: 'flex' },
            hasQuickAdd || isShowAddButton || isEditMode
              ? {}
              : { borderBottomColor: palette.lightGray, borderBottomWidth: 1 },
            manipulator.container('row', 'flex-start', 'baseline'),
            fieldContainerStyle
          ]}
        >
          {(hasQuickAdd || isShowAddButton) && (
            <HoverableOpacity
              style={{
                marginBottom: 20,
                marginRight: 20,
                cursor: areFieldsDisabled ? 'default' : 'pointer'
              }}
              onPress={() => (areFieldsDisabled ? null : handleDeleteItem(index))}
            >
              <MaterialIcons
                name='remove-circle'
                size={20}
                color={areFieldsDisabled ? palette.lightGray : palette.warning}
              />
            </HoverableOpacity>
          )}
          {renderer({ action: ArrayAction.Edit, field, index })}
          {buttons.length > 0 && (
            <View style={[manipulator.container('row', 'flex-end'), { flex: 1 }]}>
              {buttons.map((eachBtn) => {
                const { iconName, onPress } = eachBtn
                const disabled =
                  !onPress ||
                  (index === 0 && iconName === 'arrow-upward') ||
                  (index + 1 === fields.length && iconName === 'arrow-downward')
                return props.onAdd ? null : (
                  <ThemedButton
                    key={iconName}
                    disabled={disabled}
                    style={{
                      backgroundColor: 'transparent',
                      marginRight: 14,
                      height: 'auto',
                      paddingVertical: 0,
                      width: 18
                    }}
                    onPress={() => onPress(index)}
                  >
                    <MaterialIcons
                      name={iconName}
                      color={disabled ? palette.lightGray : palette.gray}
                      size={18}
                    />
                  </ThemedButton>
                )
              })}
            </View>
          )}
        </View>
      ))}
      <View
        style={[
          { display: 'flex' },
          hasQuickAddShowable || isShowAddButton
            ? manipulator.container('row', 'flex-start', 'baseline')
            : {}
        ]}
      >
        {(hasQuickAddShowable || isShowAddButton) && (
          <HoverableOpacity
            disabled={areFieldsDisabled && !isShowAddButton}
            style={{ marginBottom: 20, marginRight: 20 }}
            onPress={handleSubmit(onAppendItem)}
          >
            <MaterialIcons
              name='add-circle'
              size={20}
              color={areFieldsDisabled ? palette.lightGray : palette.primary}
            />
          </HoverableOpacity>
        )}
        {(hasQuickAddShowable || !isEditMode) &&
          renderer({ action: ArrayAction.Add, newFieldControl, newFieldErrors })}
      </View>
    </View>
  )
}

export { ArrayController, FieldArrayForm, FormField }
