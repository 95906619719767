import { StoreContext, store } from '@rezio/core/stores'
import { formatPriceTier } from '@rezio/utils/format'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import memoize from 'memoize-one'
import { autorun, values } from 'mobx'
import React, { forwardRef, memo, useCallback, useContext, useEffect, useState } from 'react'
import { Platform } from 'react-native'

import { Option } from './core'
import { Stores, useRouter } from './hooks'
import { authNotify } from './notification'
import {
  PERMISSION_ACTION,
  PERMISSION_GROUP_UUID,
  PERMISSION_TITLE,
  PLAN_FEATURES,
  PlanFeature,
  ROLE_TITLE,
  SUBSCRIPTION_COUNTRY,
  SUBSCRIPTION_PLAN_PERIOD,
  SUBSCRIPTION_STATUS,
  TIERS,
  TIER_PLATFORM
} from './permission'

export * from './permission'

const isWeb = Platform.OS === 'web'

interface JWTContent {
  account: {
    uuid: string
    account: string
    email?: string
    phoneITI?: number
    phone?: string
    firstName?: string
    lastName?: string
    languageUuid: string
    timezoneUuid: string
    verifyStatus: number
    activeStatus: number
    createdAt: string
    updatedAt?: string
    jobTitle?: string
    photo?: string
    lastLoginTime?: string
    systemPermission: number
    isRobot: boolean
    googleId?: string
  }
  storeUuid: string
  ownerUuid: string
  storeMainAccountUuid: string
  ownerMainAccountUuid: string
  permissionGroupUuid: string
  priceTier: string
  priceTierTitle: string
  subscriptionStatus: number
  countryUuid: string
  exp?: number
}

interface ClientJWTContent {
  priceTier: string
  period: SUBSCRIPTION_PLAN_PERIOD
  tierPlatform: TIER_PLATFORM
  authTier: TIERS
}

type TokenData = JWTContent & ClientJWTContent

const ROLE_PERMISSION = [
  {
    role: ROLE_TITLE.REDEEM_STUFF,
    permission: [
      {
        title: PERMISSION_TITLE.VOUCHER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.SELF_ACCOUNT,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      }
    ]
  },
  {
    role: ROLE_TITLE.ORDER_ONLY,
    permission: [
      {
        title: PERMISSION_TITLE.VOUCHER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.SELF_ACCOUNT,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.ORDER_OWNER,
        action: [PERMISSION_ACTION.NEW] // 只能新增自己
      },
      {
        title: PERMISSION_TITLE.ABNORMAL_ORDER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      }
    ]
  },
  {
    role: ROLE_TITLE.USER,
    permission: [
      {
        title: PERMISSION_TITLE.VOUCHER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.SELF_ACCOUNT,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.ORDER_OWNER,
        action: [PERMISSION_ACTION.NEW] // 只能新增自己
      },
      {
        title: PERMISSION_TITLE.ABNORMAL_ORDER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      }
    ]
  },
  {
    role: ROLE_TITLE.ADMIN,
    permission: [
      {
        title: PERMISSION_TITLE.VOUCHER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.SELF_ACCOUNT,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.ORDER_OWNER,
        action: [PERMISSION_ACTION.NEW, PERMISSION_ACTION.DELETE] // 只能新增自己
      },
      {
        title: PERMISSION_TITLE.OTHER_ACCOUNT,
        action: [
          PERMISSION_ACTION.GET,
          PERMISSION_ACTION.EDIT,
          PERMISSION_ACTION.NEW,
          PERMISSION_ACTION.DELETE
        ]
      },
      {
        title: PERMISSION_TITLE.STORE_BASIC,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.REDEEM_PERMISSION,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.API_TOKEN,
        action: [PERMISSION_ACTION.GET]
      },
      {
        title: PERMISSION_TITLE.ABNORMAL_ORDER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      }
    ]
  },
  {
    role: ROLE_TITLE.REZIO_EXPERT,
    permission: [
      {
        title: PERMISSION_TITLE.VOUCHER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.SELF_ACCOUNT,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.ORDER_OWNER,
        action: [PERMISSION_ACTION.NEW, PERMISSION_ACTION.DELETE] // 只能新增自己
      },
      {
        title: PERMISSION_TITLE.OTHER_ACCOUNT,
        action: [
          PERMISSION_ACTION.GET,
          PERMISSION_ACTION.EDIT,
          PERMISSION_ACTION.NEW,
          PERMISSION_ACTION.DELETE
        ]
      },
      {
        title: PERMISSION_TITLE.STORE_BASIC,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.REDEEM_PERMISSION,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.API_TOKEN,
        action: [PERMISSION_ACTION.GET]
      },
      {
        title: PERMISSION_TITLE.SUBSCRIPTION,
        action: [PERMISSION_ACTION.GET]
      },
      {
        title: PERMISSION_TITLE.ABNORMAL_ORDER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      }
    ]
  },
  {
    role: ROLE_TITLE.STORE_OWNER,
    permission: [
      {
        title: PERMISSION_TITLE.VOUCHER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.SELF_ACCOUNT,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.ORDER_OWNER,
        action: [PERMISSION_ACTION.NEW, PERMISSION_ACTION.DELETE] // 只能新增自己
      },
      {
        title: PERMISSION_TITLE.OTHER_ACCOUNT,
        action: [
          PERMISSION_ACTION.GET,
          PERMISSION_ACTION.EDIT,
          PERMISSION_ACTION.NEW,
          PERMISSION_ACTION.DELETE
        ]
      },
      {
        title: PERMISSION_TITLE.STORE_BASIC,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.REDEEM_PERMISSION,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.API_TOKEN,
        action: [PERMISSION_ACTION.GET]
      },
      {
        title: PERMISSION_TITLE.SUBSCRIPTION,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      },
      {
        title: PERMISSION_TITLE.ABNORMAL_ORDER,
        action: [PERMISSION_ACTION.GET, PERMISSION_ACTION.EDIT]
      }
    ]
  }
]

const subscriptionStatusMapping = {
  '-1': SUBSCRIPTION_STATUS.OVERDUE,
  0: SUBSCRIPTION_STATUS.NONE,
  1: SUBSCRIPTION_STATUS.PAID,
  2: SUBSCRIPTION_STATUS.PAUSE,
  3: SUBSCRIPTION_STATUS.BANNED,
  4: SUBSCRIPTION_STATUS.TRIAL
}

export const countryUuidMapping = {
  '8d85cdb0-16c5-4b77-a4b4-254154bdc1aa': SUBSCRIPTION_COUNTRY.JP,
  '233fe8fe-4605-2a26-8906-1eb71523856b': SUBSCRIPTION_COUNTRY.KO,
  '23fdb4b8-0a8a-46aa-957a-e4d938d9866d': SUBSCRIPTION_COUNTRY.TW
}

const getPlanFeatures = (tier: TIERS) => {
  const planPermission: PlanFeature[] = store?.core?.session?.planPermission
    ? [...values(store?.core?.session?.planPermission)]
    : []
  const extraPermission: PlanFeature[] = store?.core?.session?.extraPermission
    ? [...values(store?.core?.session?.extraPermission)]
    : []
  const { SUBSCRIPTION_BALANCE } = PLAN_FEATURES
  switch (tier) {
    case TIERS.NONE:
      return []
    case TIERS.FREE:
    case TIERS.LITE:
    case TIERS.SILVER:
    case TIERS.BASIC:
    case TIERS.GROW:
    case TIERS.RISE:
    case TIERS.GOLD:
    case TIERS.PRO:
    case TIERS.SUPER:
    case TIERS.PLATINUM:
    case TIERS.PREMIUM:
    case TIERS.ENTRY:
    case TIERS.STANDARD:
    case TIERS.ADVANCE:
    case TIERS.PREMIUM_PLUS_ONE:
    case TIERS.PREMIUM_PLUS_TWO:
    case TIERS.KKDAY:
    case TIERS.KKDAYRISE:
    case TIERS.KKDAYSUPER:
    case TIERS.SPECIAL:
      return [...planPermission, ...extraPermission]
    case TIERS.ENTERPRISE:
      return [...planPermission, ...extraPermission, SUBSCRIPTION_BALANCE]
    default:
      return []
  }
}

export const checkFeatureValid = (authTier: TIERS, featureName: PlanFeature) => {
  return getPlanFeatures(authTier).includes(featureName)
}

export const checkPermissionValid = (
  role: ROLE_TITLE,
  permissionName: PERMISSION_TITLE,
  actionName: PERMISSION_ACTION
) => {
  if (!role || !permissionName || !actionName) {
    return false
  }

  return ROLE_PERMISSION.reduce((result, permissionItem) => {
    return permissionItem.role === role
      ? permissionItem.permission.reduce((permssionResult, { title, action }) => {
          return title === permissionName ? action.includes(actionName) : permssionResult
        }, false)
      : result
  }, false)
}

interface Authentication {
  isLogin: boolean
  /**
   * @deprecated 直接使用`role === ROLE_TITLE.STORE_OWNER`判斷
   */
  isStoreOwner: boolean
  isStoreAdmin: boolean
  /**
   * @deprecated 直接使用`role === ROLE_TITLE.USER`判斷
   */
  isStaffGroup: boolean
  /**
   * @deprecated 直接使用`role === ROLE_TITLE.ADMIN`判斷
   */
  isAdminGroup: boolean
  /**
   * @deprecated 直接使用`role === ROLE_TITLE.REZIO_EXPERT`判斷
   */
  isExpertGroup: boolean
  priceTier: string
  subscriptionStatus: SUBSCRIPTION_STATUS
  planFeatures: PlanFeature[]
  hasPlanFeature: (feature?: PlanFeature) => boolean
  country: SUBSCRIPTION_COUNTRY
  authTier?: TIERS
  role?: ROLE_TITLE
  tierPlatform?: TIER_PLATFORM
  hasRolePermission?: (permission: PERMISSION_TITLE, action: PERMISSION_ACTION) => boolean
  isAnnually?: boolean
  /**
   * @deprecated 直接使用`role === ROLE_TITLE.REDEEM_STAFF`判斷
   */
  isScanner?: boolean
}

export function validate(token: string, registerCountry?: Option[]) {
  let authentication: Authentication = {
    isLogin: false,
    isStoreOwner: false,
    isStoreAdmin: false,
    isStaffGroup: false,
    isAdminGroup: false,
    isExpertGroup: false,
    priceTier: TIERS.NONE,
    subscriptionStatus: SUBSCRIPTION_STATUS.NONE,
    planFeatures: [],
    hasPlanFeature: () => true,
    country: SUBSCRIPTION_COUNTRY.TW
  }

  if (token) {
    // 之後可以考慮把 availablePlans (在訂閱頁顯示的方案)整理進來
    const decoded = decodeToken(token)
    const {
      account,
      ownerMainAccountUuid,
      permissionGroupUuid,
      authTier,
      priceTier,
      tierPlatform,
      period,
      countryUuid,
      exp
    } = decoded
    const isStoreOwner = account?.uuid === ownerMainAccountUuid
    const role = isStoreOwner
      ? ROLE_TITLE.STORE_OWNER
      : (_.reduce(
          PERMISSION_GROUP_UUID,
          (result, uuid, id) => {
            return permissionGroupUuid === uuid ? id : result
          },
          ''
        ) as ROLE_TITLE)

    // token expired
    if (Date.now() < exp * 1000) {
      authentication = {
        ...authentication,
        isLogin: true,
        isStoreOwner,
        isStoreAdmin:
          isStoreOwner ||
          [PERMISSION_GROUP_UUID.ADMIN, PERMISSION_GROUP_UUID.REZIO_EXPERT].includes(
            decoded.permissionGroupUuid
          ),
        priceTier, // 和後端的 api 的 tier/priceTier 格式對齊
        authTier, // 主要用來做前端權限的判斷，和 period 拆開
        role,
        tierPlatform,
        hasRolePermission: (permission, action) => checkPermissionValid(role, permission, action),
        isAnnually: period === SUBSCRIPTION_PLAN_PERIOD.YEAR,
        subscriptionStatus: subscriptionStatusMapping[decoded.subscriptionStatus],
        isScanner: role === ROLE_TITLE.REDEEM_STUFF,
        isAdminGroup: role === ROLE_TITLE.ADMIN,
        isStaffGroup: role === ROLE_TITLE.USER,
        isExpertGroup: role === ROLE_TITLE.REZIO_EXPERT,
        planFeatures: getPlanFeatures(authTier),
        hasPlanFeature: (feature: PlanFeature) => checkFeatureValid(authTier, feature),
        country: registerCountry?.find(
          (eachCountry: { uuid: string }) => eachCountry.uuid === countryUuid
        )?.countrycode
      }
    }
  }

  return authentication
}

export function getAccount(token: string) {
  return decodeToken(token)?.account
}

export const decodeToken = memoize((token: string): TokenData => {
  const { priceTier: orgPriceTier, ...rest } = jwtDecode<JWTContent>(token)
  const formatedPriceTier: ClientJWTContent = formatPriceTier(orgPriceTier)
  return { ...rest, ...formatedPriceTier }
})

export const login = (token: string) => {
  if (!validate(token).isLogin) {
    return false
  }
  if (isWeb) {
    const cookie = require('js-cookie')
    cookie.set('token', token, { expires: 1 })
  }
  return true
}

export const logout = () => {
  if (isWeb) {
    const cookie = require('js-cookie')
    cookie.remove('token')
    // to support logging out from all windows
    window.localStorage.setItem('logout', `${Date.now()}`)
  } else {
    authNotify().catch(console.error)
  }
}

type UsePermissionType = 'basic' | 'admin' | 'owner' | 'expert' | 'enterprise' | 'feature'

export function usePermission(
  type?: UsePermissionType,
  params?: PLAN_FEATURES,
  countries?: SUBSCRIPTION_COUNTRY[]
) {
  const { core, t } = useContext<Stores>(StoreContext)
  const [permission, setPermission] = useState(validate(core.token, core.options?.country))
  const router = useRouter()

  const syncLogout = useCallback(
    (event) => {
      if (event.key === 'logout') {
        router.push('/')
      }
    },
    [router]
  )

  useEffect(() => {
    const dispose = autorun(() => {
      setPermission(validate(core.token, core.options?.country))
    })

    if (isWeb) {
      window.addEventListener('storage', syncLogout)
    }

    return () => {
      dispose()
      if (isWeb) {
        window.removeEventListener('storage', syncLogout)
        window.localStorage.removeItem('logout')
      }
    }
  }, [])

  useEffect(() => {
    if (!type || !permission) {
      return
    }

    const { isLogin, role, priceTier, hasPlanFeature, authTier, country } = permission
    const isPlanPermissionLoaded =
      authTier === TIERS.NONE || store?.core?.session?.planPermission?.length > 0

    if (!isLogin) {
      router.push('/')
      return
    }

    if (role === ROLE_TITLE.REDEEM_STUFF) {
      const isValidPath = [
        '/account/profile',
        '/account/password',
        '/favorite',
        '/redeem/report',
        '/scanner'
      ].includes(router.pathname)
      if (!isValidPath) {
        router.replace('/scanner')
      }
      return
    }

    if (role === ROLE_TITLE.ORDER_ONLY) {
      const isValidPath =
        router.pathname.startsWith('/order') ||
        router.pathname.startsWith('/report') ||
        [
          '/account/profile',
          '/account/password',
          '/redeem/report',
          '/favorite',
          '/scanner',
          '/dashboard',
          '/customReport',
          '/conversation',
          '/toBeProcessed',
          '/quicksearch'
        ].includes(router.pathname)
      if (!isValidPath) {
        router.replace('/dashboard')
      }
      return
    }

    if (
      type === 'feature' &&
      isPlanPermissionLoaded &&
      hasPlanFeature(params) &&
      countries?.length > 0 &&
      !countries?.includes(country)
    ) {
      core
        .setRuntimeError(
          t('COMMON.API_EXCEPTION_DESCRIPTION_UNSUPPORTED'),
          'permission',
          {
            permission,
            need: countries
          },
          {
            title: t('COMMON.API_EXCEPTION_TITLE_UNSUPPORTED')
          }
        )
        .catch(console.error)
      router.replace('/')
    }

    if (
      (type === 'admin' && role !== ROLE_TITLE.ADMIN) ||
      (type === 'owner' && role !== ROLE_TITLE.STORE_OWNER) ||
      (type === 'expert' &&
        !(role === ROLE_TITLE.STORE_OWNER || role === ROLE_TITLE.REZIO_EXPERT)) ||
      (type === 'enterprise' && priceTier !== TIERS.ENTERPRISE) ||
      (type === 'feature' && isPlanPermissionLoaded && !hasPlanFeature(params))
    ) {
      core
        .setRuntimeError(t('COMMON.API_EXCEPTION_TITLE_DENY'), 'permission', {
          permission,
          need: type
        })
        .catch(console.error)
      router.replace('/')
    }
  }, [type, countries, permission])

  return permission
}

const wrapperHelper = memoize((args: Parameters<typeof usePermission>, WrappedComponent) => {
  const Wrapper = memo(
    forwardRef(function Wrapper(props, ref) {
      const permission = usePermission(...args)
      return <WrappedComponent ref={ref} {...props} permission={permission} />
    })
  )
  Wrapper.displayName = 'WithPermission'
  return Wrapper
})

export const withPermission =
  (...args: Parameters<typeof usePermission>) =>
  (WrappedComponent) => {
    return wrapperHelper(args, WrappedComponent)
  }

export default {
  validate,
  login,
  logout,
  getAccount,
  decodeToken
}
