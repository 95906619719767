import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor
} from '@dnd-kit/core'
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { manipulator } from '@rezio/res/theme'
import React, { PropsWithChildren, useCallback } from 'react'
import { View, ViewProps } from 'react-native'

type SortableWrapperProps = PropsWithChildren<{
  items: any[]
  itemsIds: string[]
  onChange: (nextValue: any[]) => void
}>

interface SortableItemProps extends ViewProps {
  index: number
  id: string
  children: React.ReactNode
  isDragWholeItem?: boolean
  disabled?: boolean
}

export const useAllDNDSensors = () =>
  useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

export const SortableWrapper = ({ children, items, itemsIds, onChange }: SortableWrapperProps) => {
  const sensors = useAllDNDSensors()
  const handleDragEnd = useCallback(
    ({ active, over }) => {
      if (items.length === 1) return items
      if (active.id !== over.id) {
        const oldIndex = active.data.current.sortable.index
        const newIndex = over.data.current.sortable.index

        onChange(arrayMove(items, oldIndex, newIndex))
      }
    },
    [onChange, itemsIds, items]
  )

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={itemsIds} strategy={verticalListSortingStrategy}>
        <View className='w-full' style={{ zIndex: -1 }}>
          <View
            style={[
              manipulator.container('row', 'flex-start', 'flex-start'),
              { flexWrap: 'wrap', width: '100%' }
            ]}
          >
            {children}
          </View>
        </View>
      </SortableContext>
    </DndContext>
  )
}

/**
 * Pass props to children
 */
const PassPropsToChildrenFrame = ({ children, ...childProps }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...childProps
      })
    }
    return child
  })
  return childrenWithProps
}

export const SortableItem = ({
  children,
  index,
  id,
  className = '',
  isDragWholeItem,
  disabled = false,
  style: styleProps
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, activeIndex } = useSortable({
    id,
    disabled,
    animateLayoutChanges: () => false // 不然動畫會跑兩次
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  }

  if (disabled) return <View>{children}</View>

  return (
    <View
      className={className}
      ref={setNodeRef}
      style={[{ width: '100%', ...style, zIndex: activeIndex === index ? 9 : 0 }, styleProps]}
      {...(attributes as any)}
      {...(isDragWholeItem && listeners)}
    >
      <PassPropsToChildrenFrame listeners={listeners}>{children}</PassPropsToChildrenFrame>
    </View>
  )
}
