import React, { useCallback, useRef } from 'react'
import { Pressable } from 'react-native'

export function HoverableOpacity(props) {
  const {
    style,
    disabled,
    hoverClass = 'hover:!opacity-80',
    activeClass = '',
    ...restProps
  } = props
  const touchableRef = useRef()

  const mouseEnter = useCallback(() => {
    !disabled && props.mouseEnter?.()
  }, [disabled])

  const mouseLeave = useCallback(() => {
    !disabled && props.mouseLeave?.()
  }, [disabled])

  return (
    <Pressable
      ref={touchableRef}
      disabled={disabled}
      className={!disabled && `${hoverClass} ${activeClass}`}
      style={style}
      onHoverIn={mouseEnter}
      onHoverOut={mouseLeave}
      {...restProps}
    />
  )
}
