import { atom, useAtomValue } from 'jotai'
import { Dimensions, Platform } from 'react-native'
import { PropsWithChildren, createContext, useCallback, useReducer } from 'react'
import _ from 'lodash'
import { ScopeProvider } from 'bunshi/react'
import { pageScope } from './scopes'

/**
 * same as tailwindcss md breakpoint
 */
export const LAYOUT_BREAKPOINT = 832

type LayoutType = 'desktop' | 'app'

interface Layout {
  isMobile: boolean
  layout: LayoutType
}

interface LayoutDimension {
  width: number
  height: number
}

const initDimension = Dimensions.get('window')

/**
 * @warning 為了測試可以mock, 平常不要更動
 * */
export const platformAtom = atom(Platform.OS)

export const dimensionAtom = atom({
  width: initDimension.width,
  height: initDimension.height
})

export const layoutAtom = atom((get) =>
  get(dimensionAtom).width <= LAYOUT_BREAKPOINT || get(platformAtom) !== 'web' ? 'app' : 'desktop'
)

export function useLayout(): Layout {
  const layout: LayoutType = useAtomValue(layoutAtom)
  return { isMobile: layout !== 'desktop', layout }
}

const rawPageDimensionAtom = atom<LayoutDimension>({ width: -1, height: -1 })

/**
 * 儲存Page的寬高，讓PageFullModal顯示的頁面寬度一致
 */
export const pageDimensionAtom = atom(
  (get) => {
    const current = get(rawPageDimensionAtom)
    if (current.width < 0 || current.height < 0) {
      return calcPageDimension(get(dimensionAtom))
    }
    return current
  },
  (get, set, dimension: LayoutDimension | null) => {
    if (dimension == null) {
      dimension = { width: -1, height: -1 }
    }
    set(rawPageDimensionAtom, dimension)
  }
)

function calcPageDimension(dimension: LayoutDimension) {
  return {
    // 預設為：桌機 畫面寬度減掉縮起來的menu寬度110px, 灰色部分padding 15px * 3 = 45px
    width: dimension.width > LAYOUT_BREAKPOINT ? dimension.width - 45 - 110 : dimension.width,
    height: dimension.width > LAYOUT_BREAKPOINT ? dimension.height - 90 : dimension.height - 124
  }
}

export function usePageWidth(): number {
  const { width: dimensionWidth } = useAtomValue(dimensionAtom)
  const { width: pageWidth } = useAtomValue(pageDimensionAtom)
  const { isMobile } = useLayout()

  if (isMobile) {
    return dimensionWidth
  }

  return pageWidth
}

export const FooterContext = createContext<FooterState & FooterAction>(undefined)

interface FooterState {
  stack: number[]
  footers: Record<number, React.ReactNode>
  count: number
}

interface FooterAction {
  set: (component: React.ReactNode, key?: number) => number
  remove: (key: number) => void
}

function footerReducer(
  { stack, footers, count }: FooterState,
  action:
    | { type: 'set'; component: React.ReactNode; key?: number }
    | { type: 'destroy'; key?: number }
) {
  const nextFooters = Object.assign({}, footers)
  let nextStack = [...stack]
  let key = action.key
  switch (action.type) {
    case 'set':
      if (!key || key > count) {
        if (!key) {
          count++
          key = count
        } else {
          count = key
        }
        nextStack.push(key)
      }
      nextFooters[key] = action.component
      break
    case 'destroy':
      nextStack = _.without(nextStack, key)
      delete nextFooters[key]
      break
  }
  return { stack: nextStack, footers: nextFooters, count }
}

const PageProvider = ({
  value,
  children
}: PropsWithChildren<{ value: FooterAction & FooterState }>) => (
  <ScopeProvider scope={pageScope}>
    <FooterContext.Provider value={value}>{children}</FooterContext.Provider>
  </ScopeProvider>
)

export function useFooter() {
  const [footerStore, dispatch] = useReducer(footerReducer, {
    stack: [],
    footers: {},
    count: 0
  })

  const set: FooterAction['set'] = useCallback(
    function set(component, key) {
      dispatch({
        type: 'set',
        component,
        key
      })
      return key || _.last(footerStore.stack)
    },
    [footerStore, dispatch]
  )

  const remove: FooterAction['remove'] = useCallback(
    function remove(key) {
      dispatch({
        type: 'destroy',
        key
      })
    },
    [dispatch]
  )

  const footer = footerStore.footers[_.last(footerStore.stack)]
  const footerContextContent = {
    set,
    remove,
    ...footerStore
  }

  return [footerContextContent, footer, PageProvider] as const
}
