// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Core from '@rezio/core/core'
import { computed } from 'mobx'
import { model, Model, modelFlow, prop, _async, _await } from 'mobx-keystone'
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import moment from 'moment'

import { APIResponse, PagedList } from '../api'

export type DashboardType =
  | 'orderTotalInfo'
  | 'orderAmountInfo'
  | 'cancelOrderInfo'
  | 'voucherInfo'
  | 'hotProductInfo'
  | 'hotSourceInfo'

export const DASHBOARD_CARDS: DashboardType[] = [
  'orderTotalInfo',
  'orderAmountInfo',
  'cancelOrderInfo',
  'voucherInfo',
  'hotProductInfo',
  'hotSourceInfo'
]

interface OrderTotalInfoCard {
  type: 'orderTotalInfo'
  content: {
    currentCount: number
    lastCount: number
    diff: number
  }
}

interface OrderAmountInfoCard {
  type: 'orderAmountInfo'
  content: {
    currencyUuid: string
    currentAmount: number
    lastAmount: number
    diffAmount: number
  }
}

interface CancelOrderInfoCard {
  type: 'cancelOrderInfo'
  content: {
    currentCount: number
    lastCount: number
    diff: number
    currencyUuid: string
    currentAmount: number
    lastAmount: number
    diffAmount: number
  }
}

interface VoucherInfoCard {
  type: 'voucherInfo'
  content: {
    voucherCurrentCount: number
    voucherLastCount: number
    voucherDiff: number
    redeemCurrentCount: number
    redeemLastCount: number
    redeemDiff: number
  }
}

interface HotProductInfoCard {
  type: 'hotProductInfo'
  content: {
    productUuid: string
    productName: string
    totalCount: number
  }[]
}

interface HotSourceInfoCard {
  type: 'hotSourceInfo'
  content: {
    orderSourceLabel: string
    orderCount: number
  }[]
}

export type DashboardCard<T extends DashboardType = DashboardType> = T extends 'orderTotalInfo'
  ? OrderTotalInfoCard
  : T extends 'orderAmountInfo'
  ? OrderAmountInfoCard
  : T extends 'cancelOrderInfo'
  ? CancelOrderInfoCard
  : T extends 'voucherInfo'
  ? VoucherInfoCard
  : T extends 'hotProductInfo'
  ? HotProductInfoCard
  : T extends 'hotSourceInfo'
  ? HotSourceInfoCard
  : never

export enum DashboardV2RequestOption {
  Today = 1,
  Yesterday,
  Prev7Days,
  Prev30Days,
  CurrentMonth
}

type DashboardV2Response = DashboardCard[]

export interface RedeemRecord {
  productCode: string
  productName: string
  salesOptionName: string
  title: string
  voucherCount: number
  redeemCount: number
}

@model('admin/Dashboard')
class Dashboard extends Model({
  cards: prop<DashboardCard[]>(() => []),
  redeem: prop<PagedList<RedeemRecord>>(null),
  lastUpdate: prop<number>(null),
  lastRedeemUpdate: prop<number>(null),
  loading: prop(false),
  loadingRedeem: prop(false)
}) {
  core = Core.getInstance()

  @modelFlow
  setDashboard = _async(function* (this: Dashboard, cardKeys: DashboardType[]) {
    yield* _await(
      this.core.api
        .put<APIResponse, { cardKeys: DashboardType[] }>('account/dashboard', { cardKeys })[0]
        .then(async () => await this.loadDashboard())
    )
  })

  @modelFlow
  loadDashboard = _async(function* (
    this: Dashboard,
    option: DashboardV2RequestOption = DashboardV2RequestOption.Today
  ) {
    this.loading = true
    try {
      const result = yield* _await(
        this.core.api.get<APIResponse<DashboardV2Response>>(
          `store/dashboard/v2?option=${option}`
        )[0]
      )
      this.cards = result.data
      this.lastUpdate = this.lastTimeStamp
    } catch (e) {}
    this.loading = false
  })

  @modelFlow
  loadRedeem = _async(function* (this: Dashboard, page = 1) {
    this.loadingRedeem = true
    try {
      const result = yield* _await(
        this.core.api.get<APIResponse<PagedList<RedeemRecord>>>(
          `voucher/redeem/total/${page}?size=5&languageUuid=${this.core.i18n.t('LANGUAGE_UUID')}`
        )[0]
      )
      if (result.data.currentPage === 1 || !this.redeem) {
        this.redeem = result.data
      } else {
        this.redeem.currentPage = page
        this.redeem.totalCount = result.data.totalCount
        this.redeem.list = [...this.redeem.list, ...result.data.list]
      }
      this.lastRedeemUpdate = this.lastTimeStamp
    } catch (e) {}
    this.loadingRedeem = false
  })

  @computed
  get hasMoreRedeem() {
    if (!this.redeem) {
      return false
    }
    return this.redeem.totalCount / this.redeem.itemPerPage > this.redeem.currentPage
  }

  loadMoreRedeem = async () => {
    if (this.hasMoreRedeem) {
      return await this.loadRedeem(this.redeem.currentPage + 1)
    }
  }

  get lastTimeStamp() {
    const now = moment()
    return (
      moment()
        .minute(now.minute() - (now.minute() % 15))
        .unix() * 1000
    )
  }
}

export default Dashboard

/*
export const MOCK_DATA: { code: string, message: string, data: DashboardCard[] } = {
  code: '0000',
  message: 'success',
  data: [
    {
      type: 'orderTotalInfo',
      content: {
        currentCount: 3,
        lastCount: 21,
        diff: 18
      }
    },
    {
      type: 'orderAmountInfo',
      content: {
        currencyUuid: '393ac76a-d4b0-4c80-9d8e-f09fe7ba0f8e',
        currentAmount: 1011111,
        lastAmount: 12341234,
        diffAmount: 300
      }
    },
    {
      type: 'cancelOrderInfo',
      content: {
        currentCount: 3,
        lastCount: 21,
        diff: 300,
        currencyUuid: '4fc314ab-a67a-436c-b0bc-88c72b9fbebd',
        currentAmount: 1011111,
        lastAmount: 12341234,
        diffAmount: -34343
      }
    },
    {
      type: 'voucherInfo',
      content: {
        voucherCurrentCount: 239,
        voucherLastCount: 179,
        voucherDiff: 60,
        redeemCurrentCount: 0,
        redeemLastCount: 0,
        redeemDiff: 0
      }
    },
    {
      type: 'hotProductInfo',
      content: [
        {
          productUuid: 'fdbc82b2-66ce-4a27-86fb-eba114aa3536',
          productName: 'channel order 測試',
          totalCount: 1522
        },
        {
          productUuid: 'cbec474f-26a0-4662-b2d0-774e2ccdc41d',
          productName: '美元 2020-07-06 ALL DAY ALL FLOW:【東京富士山一日遊】河口湖、富岳風穴、鳴沢冰穴、忍野八海',
          totalCount: 296
        },
        {
          productUuid: '940a06f0-58a0-4ff0-877d-229857311759',
          productName: '台北士林｜兒童新樂園一日票',
          totalCount: 208
        }
      ]
    },
    {
      type: 'hotSourceInfo',
      content: [
        {
          orderSourceLabel: 'MySite',
          totalCount: 65
        },
        {
          orderSourceLabel: 'Admin',
          totalCount: 45
        },
        {
          orderSourceLabel: 'test',
          totalCount: 12
        }
      ]
    }
  ]
}

const MOCK_REDEEM = {
  errorCode: 0,
  message: 'success',
  data: {
    totalCount: 6,
    itemPerPage: 5,
    currentPage: 1,
    list: [
      {
        productCode: '',
        productName: '日本山梨｜富士山周辺・河口湖観光・御殿場アウトレット日帰りツアー｜東京発',
        salesOptionName: '河口湖日帰りツアー',
        title: 'TEST_AAA',
        voucherCount: 70,
        redeemCount: 70
      },
      {
        productCode: '',
        productName: '日本山梨｜富士山周辺・河口湖観光・御殿場アウトレット日帰りツアー｜東京発',
        salesOptionName: '河口湖日帰りツアー',
        title: 'TEST_BBB',
        voucherCount: 50,
        redeemCount: 120
      },
      {
        productCode: '',
        productName: '日本山梨｜富士山周辺・河口湖観光・御殿場アウトレット日帰りツアー｜東京発',
        salesOptionName: '河口湖日帰りツアー',
        title: 'TEST_AAA',
        voucherCount: 70,
        redeemCount: 70
      },
      {
        productCode: '',
        productName: '日本山梨｜富士山周辺・河口湖観光・御殿場アウトレット日帰りツアー｜東京発',
        salesOptionName: '河口湖日帰りツアー',
        title: 'TEST_BBB',
        voucherCount: 50,
        redeemCount: 120
      },
      {
        productCode: '',
        productName: '日本山梨｜富士山周辺・河口湖観光・御殿場アウトレット日帰りツアー｜東京発',
        salesOptionName: '河口湖日帰りツアー',
        title: 'TEST_AAA',
        voucherCount: 70,
        redeemCount: 70
      }
    ]
  }
}
*/
