import { MaterialIcons } from '@expo/vector-icons'
import { useStores } from '@rezio/core/hooks'
import { manipulator, palette, template } from '@rezio/res/theme'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Text, View } from 'react-native'

import { BorderPanel } from '../formComponent'
import { PageFooter } from '../layout'
import { PageWidthModal } from '../modal'
import { ThemedPicker } from '../themedComponents'
import { ThemedTextInput } from './ThemedTextInput'
import { ThemedButton } from './ThemedButton'

export function ThemedTagSelector(props) {
  const { t } = useStores()
  const { items, required = false } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [customInput, setCustomInput] = useState('')
  const [filterInput, setFilterInput] = useState('')
  const [filterType, setFilterType] = useState(null)
  const [tagOnAddError, setTagOnAddError] = useState('')
  const [editedItems, setEditedItems] = useState(props.items || [])
  const [checkedItems, setCheckedItems] = useState(props.value || [])
  const limit = props.limit || -1
  const isDisabledConfirmButton = required && checkedItems.length < 1

  useEffect(() => {
    props.items && setEditedItems(props.items)
  }, [props.items])

  const handleCustomInput = useCallback((text) => {
    const targetText = _.trim(text)
    return setCustomInput(targetText)
  }, [])

  const onAddCustomTag = useCallback(
    async () => tagValidation(customInput) && handleEdit({ label: customInput }),
    [customInput, editedItems]
  )

  const tagValidation = useCallback(
    (targetTag) => {
      const isEmptyTag = _.isEmpty(targetTag) || _.isNil(targetTag)
      const isExistingTag = !_.isEmpty(_.find(editedItems, (item) => item.label === targetTag))

      if (isEmptyTag || isExistingTag) {
        setTagOnAddError(t('PRODUCT.TAG_ON_ADD_ERROR'))
        return false
      } else {
        setTagOnAddError('')
        return true
      }
    },
    [t, editedItems, setTagOnAddError]
  )

  const ExistTag = ({ data }) => {
    const { label, uuid } = data
    const onEditCustomTag = useCallback(
      (text) => tagValidation(text) && handleEdit({ uuid, label: text }),
      [uuid]
    )
    return (
      <ThemedTextInput
        defaultValue={label}
        style={{ width: 120, marginRight: 10, marginBottom: 10 }}
        onChangeText={onEditCustomTag}
      />
    )
  }

  const getEditPanel = useCallback(() => {
    return (
      <View>
        <ThemedButton
          style={{
            width: 40,
            backgroundColor: palette.negative,
            position: 'absolute',
            top: -45,
            right: 0
          }}
        >
          <MaterialIcons name='edit' color={palette.white} size={20} />
        </ThemedButton>
        {!_.isEmpty(tagOnAddError) && (
          <View style={{ marginBottom: 4 }}>
            <Text style={{ color: palette.warning }}>{tagOnAddError}</Text>
          </View>
        )}
        <View
          style={[
            template.rowContainer,
            { borderBottomWidth: 1, paddingBottom: 30, borderBottomColor: palette.lightGray }
          ]}
        >
          <ThemedTextInput
            placeholder={_.get(props, 'placeholder', '')}
            style={{ marginRight: 10, width: '85%' }}
            value={customInput}
            onChangeText={handleCustomInput}
          />
          <ThemedButton
            onPress={onAddCustomTag}
            style={[
              {
                width: 40,
                height: 40,
                backgroundColor: 'transparent',
                borderRadius: 5,
                borderColor: palette.primary,
                borderWidth: 1
              }
            ]}
          >
            <MaterialIcons name='add' size={28} color={palette.primary} />
          </ThemedButton>
        </View>
        <View
          style={[
            manipulator.container('row', 'flex-start', 'center'),
            { flexWrap: 'wrap', marginTop: 20 }
          ]}
        >
          {_.map(editedItems, (existingItem, index) => (
            <ExistTag key={index} data={existingItem} />
          ))}
        </View>
      </View>
    )
  }, [tagOnAddError, customInput, editedItems])

  const getSelectPanel = useCallback(() => {
    const {
      category = {},
      groupKey = '',
      typeText,
      inputText,
      showSelectAll = true,
      editable = false,
      onChangeFilters,
      filteredItems: propsFilteredItems,
      showDisableItems = false,
      selectPanelDescription,
      selectPanelDescriptionStyle,
      showCategoryFilter = true,
      exclude = {},
      skipOnValueChange
    } = props

    const allItemValue = _.map(
      _.filter(items, ({ isDisabled, addable }) => !isDisabled && addable !== false),
      ({ value }) => value
    )
    const filteredCategory = filterType
      ? _.filter(category, (each) => each.uuid === filterType)
      : category
    const currentItems =
      propsFilteredItems ||
      (exclude.getStatus?.()
        ? _.differenceWith(items, exclude.list, (s, t) => s.uuid === t)
        : items)
    const showFilterForm = !_.isEmpty(category) && (showCategoryFilter || onChangeFilters)

    return (
      <View>
        {editable && (
          <ThemedButton
            style={{
              width: 40,
              backgroundColor: palette.primary,
              position: 'absolute',
              top: -46,
              right: 0
            }}
            onPress={() => setIsEditing(true)}
          >
            <MaterialIcons name='edit' color={palette.white} size={20} />
          </ThemedButton>
        )}
        <View
          style={[
            manipulator.container('row', 'flex-start', 'center'),
            {
              flexWrap: 'wrap',
              borderBottomWidth: 1,
              paddingBottom: 20,
              borderBottomColor: palette.lightGray
            }
          ]}
        >
          {checkedItems.length !== allItemValue.length && +limit === -1 && showSelectAll && (
            <ThemedButton
              onPress={() => setCheckedItems(allItemValue)}
              style={{
                minWidth: 120,
                marginBottom: 7,
                height: 40,
                backgroundColor: palette.primary,
                marginRight: 10
              }}
            >
              <Text style={{ color: palette.white }}>{`+ ${t('COMMON.SELECT_ALL')}`}</Text>
            </ThemedButton>
          )}
          {!_.isEmpty(checkedItems) && (
            <ThemedButton
              onPress={() =>
                setCheckedItems(() =>
                  items.filter((item) => item.isFixed === true).map((fixedItem) => fixedItem.value)
                )
              }
              style={{
                minWidth: 120,
                marginBottom: 7,
                height: 40,
                backgroundColor: palette.warning,
                marginRight: 10
              }}
            >
              <MaterialIcons
                name='clear'
                size={14}
                color={palette.white}
                style={{ marginRight: 3 }}
              />
              <Text style={{ color: palette.white }}>{t('COMMON.REMOVE_ALL')}</Text>
            </ThemedButton>
          )}
          {_.compact(
            _.map(items, (each, index) => {
              return checkedItems.includes(each.value)
                ? renderTagItem({ each, index, isSelected: true, isFixed: each.isFixed })
                : undefined
            })
          )}
        </View>
        {showFilterForm && (
          <View
            style={[
              template.rowContainer,
              {
                flexWrap: 'wrap',
                marginTop: 14,
                paddingBottom: 12,
                borderBottomColor: palette.border,
                borderBottomWidth: 1
              }
            ]}
          >
            {showCategoryFilter && (
              <ThemedPicker
                styleWeb={{ marginRight: 16, width: 200 }}
                value={filterType}
                placeholder={{ label: typeText, value: undefined }}
                items={category.map((each) => {
                  const { label, title, uuid: value } = each
                  return { label: label || title, value }
                })}
                onValueChange={setFilterType}
                skipOnValueChange={skipOnValueChange}
              />
            )}
            {onChangeFilters && (
              <>
                <ThemedTextInput
                  placeholder={inputText}
                  value={filterInput}
                  onChangeText={(e) => setFilterInput(e)}
                  onKeyPress={(event) =>
                    event?.key === 'Enter' &&
                    props.onChangeFilters &&
                    props.onChangeFilters(filterInput)
                  }
                />
                <ThemedButton
                  onPress={() => props.onChangeFilters && props.onChangeFilters(filterInput)}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <MaterialIcons name='search' size={30} color={palette.brand} />
                </ThemedButton>
              </>
            )}
          </View>
        )}
        <Text style={[{ marginTop: 20 }, selectPanelDescriptionStyle]}>
          {selectPanelDescription}
        </Text>
        {_.isEmpty(category) ? (
          <View style={[template.rowContainer, { flexWrap: 'wrap', marginTop: 20 }]}>
            {currentItems
              .filter((each) => !checkedItems.includes(each.value) && each.addable !== false)
              .map((each, index) => renderTagItem({ each, index, isSelected: false }))}
          </View>
        ) : (
          _.map(filteredCategory, (categoryItem, index) => {
            const { label, title, uuid: categoryUuid } = categoryItem
            const filteredItems = currentItems.filter(
              (each) =>
                [categoryUuid, categoryItem[groupKey]].includes(_.get(each, groupKey)) &&
                !checkedItems.includes(each.value) &&
                each.addable !== false
            )
            return (
              _.size(filteredItems) > 0 && (
                <View key={`group-${categoryUuid}`}>
                  <Text style={[template.title, { marginTop: 20, fontSize: 16 }]}>
                    {label || title}
                  </Text>
                  <View style={[template.rowContainer, { flexWrap: 'wrap', marginTop: 20 }]}>
                    {filteredItems.map((each, index) =>
                      renderTagItem({
                        each,
                        index,
                        isSelected: false,
                        isDisabled: each.isDisabled && showDisableItems
                      })
                    )}
                  </View>
                </View>
              )
            )
          })
        )}
      </View>
    )
  }, [props, items, checkedItems, filterInput, filterType])

  const handlePressNdSubmit = useCallback(
    (value) => {
      const changeHandler = props.onValueChange || props.onChange
      const activeItems = _.isEmpty(props.value) ? checkedItems : props.value
      const itemsObj =
        activeItems.indexOf(value) === -1
          ? validateIsLimit()
            ? activeItems
            : _.concat(activeItems, value)
          : _.filter(activeItems, (n) => n !== value)
      setCheckedItems(itemsObj)
      changeHandler?.(itemsObj)
    },
    [checkedItems, props.onValueChange, props.onChange, JSON.stringify(props.value)]
  )

  const renderTagItem = useCallback(
    (renderTagProps) => {
      const {
        each,
        index,
        isSelected = false,
        needSubmit = false,
        isDisabled = false,
        isFixed = false,
        TagItemComponent
      } = renderTagProps
      const value = _.get(each, 'value') || each
      const label =
        _.get(each, 'label') ||
        _.get(
          items.filter((i) => i.value === value),
          '0.label'
        ) ||
        ''
      const defaultTagStyle = {
        minWidth: 100,
        marginRight: 10,
        height: 40,
        borderWidth: 1,
        borderRadius: 5,
        backgroundColor: 'transparent',
        paddingLeft: 5,
        paddingRight: 5,
        marginBottom: 10
      }
      const currentCheckedItems =
        checkedItems.indexOf(value) === -1
          ? validateIsLimit()
            ? checkedItems
            : _.concat(checkedItems, value)
          : _.filter(checkedItems, (n) => n !== value)

      if (TagItemComponent) {
        return (
          <TagItemComponent
            key={index}
            onPress={
              needSubmit
                ? () => handlePressNdSubmit(value)
                : () => setCheckedItems(currentCheckedItems)
            }
            {...renderTagProps}
          />
        )
      }

      return isDisabled || isFixed ? (
        <View
          style={[
            defaultTagStyle,
            manipulator.container('row', 'center', 'center'),
            isDisabled
              ? { borderColor: palette.border }
              : { borderColor: palette.text, marginBottom: 5 }
          ]}
        >
          <Text style={isDisabled ? { color: palette.border } : { color: palette.text }}>
            {label}
          </Text>
        </View>
      ) : (
        <ThemedButton
          key={index}
          style={[
            defaultTagStyle,
            isSelected
              ? { marginBottom: 5, borderColor: palette.primary }
              : { borderColor: palette.gray }
          ]}
          onPress={
            needSubmit
              ? () => handlePressNdSubmit(value)
              : () => setCheckedItems(currentCheckedItems)
          }
        >
          {isSelected && (
            <MaterialIcons
              name='clear'
              size={14}
              color={palette.primary}
              style={[{ marginRight: 3 }]}
            />
          )}
          {isSelected ? (
            <Text style={{ color: palette.primary }}>{label}</Text>
          ) : (
            <Text style={{ fontSize: 14, fontWeight: '500' }}>
              <MaterialIcons
                name='add'
                size={14}
                color={palette.black}
                style={[{ marginRight: 5 }]}
              />
              {label}
            </Text>
          )}
        </ThemedButton>
      )
    },
    [items, checkedItems, handlePressNdSubmit]
  )

  const validateIsLimit = useCallback(() => {
    return +limit === -1 ? false : +checkedItems.length >= +limit
  }, [checkedItems, limit])

  const handleEdit = useCallback(
    ({ uuid, label }) => {
      if (_.isUndefined(uuid)) {
        setEditedItems([...editedItems, { uuid: '', label }])
        setCustomInput('')
      } else {
        const currentEditedItems = [...editedItems]
        const index = _.findIndex(currentEditedItems, ['uuid', uuid])
        currentEditedItems[index] = { uuid, label }
        setEditedItems(currentEditedItems)
        setCustomInput('')
      }
    },
    [editedItems]
  )

  const handleSubmit = useCallback(() => {
    const prevCheckedItems = [...checkedItems]
    const changeHandler = props.onValueChange || props.onChange
    setCheckedItems([])
    props.onChangeFilters?.('')
    changeHandler?.(prevCheckedItems)
  }, [checkedItems, props.onValueChange, props.onChange, props.onChangeFilters])

  const {
    label,
    value: propsValue,
    disabled,
    inputHint = '',
    subTitle = '',
    inputLabel,
    inputLabelStyle = {},
    inputResultPanel = null,
    containerStyle = {},
    inputStyle = {},
    inputEditButtonStyle,
    InputEditButtonComponent,
    InputRenderTagComponent
  } = props
  const activeItems = _.isEmpty(propsValue) ? checkedItems : propsValue

  return disabled ? (
    <ThemedTextInput
      disabled
      value={_.map(propsValue, (eachValue) =>
        _.get(
          items.filter((i) => i.value === eachValue),
          '0.label'
        )
      ).join('、')}
      layout='block'
      style={containerStyle}
    />
  ) : (
    <View style={containerStyle}>
      {!!inputHint && (
        <Text style={{ color: palette.gray, marginBottom: 8, fontSize: 14 }}>{inputHint}</Text>
      )}
      <View
        style={[
          manipulator.container('row', 'flex-start', 'center'),
          manipulator.border('all', '', 5),
          { flexWrap: 'wrap', paddingTop: 5, paddingLeft: 5, paddingRight: 5 },
          inputStyle
        ]}
      >
        {inputLabel && <Text style={[{ marginRight: 10 }, inputLabelStyle]}>{inputLabel}</Text>}
        {_.isArray(propsValue) &&
          _.compact(
            _.map(items, (each, index) => {
              return propsValue.includes(each.value)
                ? renderTagItem({
                    each,
                    index,
                    isSelected: true,
                    isFixed: each.isFixed,
                    needSubmit: true,
                    TagItemComponent: InputRenderTagComponent
                  })
                : undefined
            })
          )}
        <ThemedButton
          style={[
            {
              marginBottom: 7,
              height: 40,
              width: 40,
              borderWidth: 1,
              borderRadius: 5,
              borderColor: palette.primary,
              backgroundColor: 'transparent',
              paddingLeft: 5,
              paddingRight: 5
            },
            inputEditButtonStyle
          ]}
          onPress={() => {
            setIsModalVisible(true)
            setCheckedItems(activeItems)
          }}
          accessibilityRole='spinbutton'
          testId='spinbutton'
        >
          {InputEditButtonComponent || (
            <MaterialIcons name='add' size={28} color={palette.primary} />
          )}
        </ThemedButton>
      </View>
      {inputResultPanel}
      <PageWidthModal
        testID='themed-tag-selector-modal'
        isVisible={isModalVisible}
        onBackdropPress={() => setIsModalVisible(!isModalVisible)}
      >
        <View style={{ backgroundColor: palette.white, justifyContent: 'center', margin: 0 }}>
          <BorderPanel label={label} subTitle={subTitle} containerStyle={{ width: '100%' }}>
            {isEditing ? getEditPanel() : getSelectPanel()}
          </BorderPanel>
          <PageFooter>
            <View style={manipulator.container('row', 'space-between', 'center')}>
              <ThemedButton
                onPress={() => {
                  if (isEditing) {
                    setIsEditing(false)
                  } else {
                    setIsModalVisible(false)
                    setCheckedItems([])
                  }
                }}
                style={[template.defaultPanel, { width: 150, backgroundColor: palette.negative }]}
              >
                <Text style={{ color: palette.black }}>{t('FORM.BUTTON_CANCEL')}</Text>
              </ThemedButton>
              <ThemedButton
                onPress={() => {
                  if (isEditing) {
                    setIsEditing(false)
                    props.onEditSubmit(editedItems)
                  } else {
                    setIsModalVisible(false)
                    handleSubmit()
                  }
                }}
                disabled={isDisabledConfirmButton}
                style={[
                  template.defaultPanel,
                  {
                    width: 150,
                    backgroundColor: isDisabledConfirmButton ? palette.gray : palette.positive
                  }
                ]}
              >
                <Text style={{ color: palette.white }}>{t('FORM.BUTTON_CONFIRM')}</Text>
              </ThemedButton>
            </View>
          </PageFooter>
        </View>
      </PageWidthModal>
    </View>
  )
}
