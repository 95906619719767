import styled from '@emotion/native'
import { MaterialIcons } from '@expo/vector-icons'
import { manipulator, palette } from '@rezio/res/theme'
import { complement, concat, pluck, pull } from 'lodash/fp'
import { identity } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { Text, View, Platform } from 'react-native'
import { animated, useSpring } from 'react-spring/native'

import { ThemedButton } from './ThemedButton'
import { ThemedCheckBox } from './ThemedCheckBox'

export interface ExpendableCellProps<T> {
  label: string
  uuid?: string
  value?: T
  childrenItems: { label: string; uuid?: string }[]
  preCheckedChildrenItems?: string[]
  preShowChildren?: boolean
  onChange: (value: boolean, label: string, checkedChildren: string[]) => void
  t?: (input: string) => string
}

export const ExpendableCell = <T,>({
  onChange,
  childrenItems,
  label,
  uuid,
  preCheckedChildrenItems = [],
  preShowChildren = false,
  t = identity
}: ExpendableCellProps<T>) => {
  const [showChildren, setShowChildren] = useState(preShowChildren)
  const [checkedChild, setCheckedChild] = useState(preCheckedChildrenItems)
  const skipFirstChange = useRef(true)
  const slideOutLeft = useSpring({ marginLeft: showChildren ? -40 : 12 })
  const slideDown = useSpring({ height: showChildren ? 'auto' : 0 })
  const labelStyle = useSpring({ paddingLeft: showChildren ? 12 : 0 })

  useEffect(() => {
    if (skipFirstChange.current) {
      skipFirstChange.current = false
      return
    }
    onChange?.(showChildren, uuid || label, checkedChild)
  }, [showChildren, checkedChild])

  return (
    <CellWrapper>
      <CellLabelWrapper titleize={showChildren}>
        <CellLabel
          containerStyle={{ flex: 1 }}
          style={[labelStyle, { backgroundColor: 'white', overflow: 'hidden', height: 'auto' }]}
        >
          <CellCheckbox
            enablePartial
            // @ts-expect-error web style
            style={[{ width: Platform.OS === 'web' ? 0 : 40, height: 40 }, slideOutLeft]}
            disabled={showChildren}
            disabledColor='gray'
            value={checkedChild.length === childrenItems.length}
            onChange={(checked) => {
              setCheckedChild(checked ? pluck('uuid', childrenItems) : [])
            }}
          />
          <LabelWrapper>
            <Text
              onPress={() =>
                setCheckedChild(
                  checkedChild.length !== childrenItems.length ? pluck('uuid', childrenItems) : []
                )
              }
              style={{ flex: 1 }}
            >
              {label}
            </Text>
            <ThemedButton
              onPress={() => {
                setShowChildren(complement(Boolean))
                setCheckedChild([])
              }}
              style={[
                { height: 'auto' },
                !showChildren && {
                  color: palette.active,
                  borderWidth: 1,
                  borderColor: palette.active,
                  backgroundColor: 'white'
                },
                showChildren && {
                  color: 'white',
                  backgroundColor: palette.active
                }
              ]}
            >
              {showChildren ? (
                <>
                  <MaterialIcons
                    style={{ color: 'white', marginRight: 5 }}
                    name='check'
                    size={16}
                  />
                  <Text style={{ color: 'white' }}>{t('DISCOUNT.SPECIFIC_PRODUCT')}</Text>
                </>
              ) : (
                <Text style={{ color: palette.active }}>
                  {t('DISCOUNT.SELECT_SPECIFIC_PRODUCT')}
                </Text>
              )}
            </ThemedButton>
          </LabelWrapper>
        </CellLabel>
      </CellLabelWrapper>
      {/* @ts-expect-error web style */}
      <CellChildren style={[{ marginLeft: 12 }, slideDown]}>
        {childrenItems.map(({ label, uuid }) => (
          <ChildrenCheckbox
            key={`${label}-${uuid}`}
            label={label}
            value={checkedChild.includes(uuid)}
            onChange={(childCheck) => setCheckedChild((childCheck ? concat : pull)(uuid))}
          />
        ))}
      </CellChildren>
    </CellWrapper>
  )
}

const CellWrapper = styled.View`
  background-color: white;
  overflow: hidden;
  justify-content: space-between;
`
const CellLabelWrapper = styled.View<{ titleize: boolean }>(
  manipulator.container('row', 'center', 'center'),
  { minHeight: 40 },
  ({ titleize }) => titleize && { backgroundColor: '#F4F4F4' }
)
const CellLabel = animated(ThemedButton)
const CellChildren = animated(View)
const CellCheckbox = animated(ThemedCheckBox)
const LabelWrapper = styled.View(manipulator.container('row', 'space-between', 'center'), {
  flex: 1
})
const ChildrenCheckbox = styled(ThemedCheckBox)({
  justifyContent: 'flex-start',
  marginLeft: 12,
  height: 'auto'
})
