import { MaterialIcons } from '@rezio/components'
import { manipulator, palette } from '@rezio/res/theme'
import { formatBytes } from '@rezio/utils/format'
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { Image, View, Text, StyleProp, ImageStyle } from 'react-native'

import { TipsInfoText } from './tipsInfoText'
import { ImageFile } from './types'

interface DynamicImagePropsType {
  file: ImageFile
  imageStyle?: StyleProp<ImageStyle>
  isOutOffLimit: boolean
  isSelected: boolean
}

export function DynamicImage({
  file = {
    url: 'https://img.rezio.io/img/default-images.jpg',
    size: 0,
    clientFileName: 'Image'
  },
  imageStyle,
  isOutOffLimit = false,
  isSelected = false
}: DynamicImagePropsType) {
  const { url, size, clientFileName } = file
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(150)

  const getImageSize = useCallback(async () => {
    await Image.getSize(url, (width, height) => {
      setHeight(height)
      setWidth(width)
    })
  }, [url])

  useEffect(() => {
    getImageSize().catch((error) => console.error(error))

    return () => {
      setHeight(0)
      setWidth(0)
    }
  }, [getImageSize])

  return (
    <View style={{ backgroundColor: palette.white, width: 180, zIndex: 1 }}>
      <View style={{ marginTop: 0 }}>
        {isSelected && (
          <MaterialIcons
            name='check-circle'
            color={palette.primary}
            size={44}
            style={{ zIndex: 99, position: 'absolute', top: '40%', left: '40%' }}
          />
        )}
        <Image
          source={{ uri: url ?? require('../../assets/default-images.jpeg') }}
          style={[{ width, height, maxHeight: 150, borderRadius: 4 }, imageStyle]}
        />
      </View>
      <View style={manipulator.container('column', 'flex-start')}>
        <View
          style={[
            manipulator.container(
              `${isOutOffLimit ? 'column' : 'row'}`,
              `${clientFileName ? 'space-between' : 'flex-end'}`,
              'baseline'
            ),
            { marginTop: 10 }
          ]}
        >
          {!_.isEmpty(clientFileName) && (
            <TipsInfoText
              context={clientFileName}
              containerStyle={{ paddingRight: 5, maxWidth: '100%' }}
              style={{ textAlign: 'left' }}
            />
          )}
          <View
            style={[
              manipulator.container('row', 'flex-end', 'flex-start'),
              { alignItems: 'flex-end', zIndex: 1, marginTop: isOutOffLimit ? 5 : 0 }
            ]}
          >
            <Text
              style={{
                fontSize: 14,
                color: isOutOffLimit ? palette.warning : palette.gray,
                textAlign: 'left'
              }}
            >
              {!isOutOffLimit && formatBytes(size ?? 0)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
