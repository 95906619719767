import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Updates from 'expo-updates'
import { createContext } from 'react'
import { Platform } from 'react-native'

import { Basic } from './response.types'

let overrideEnv: Environment = null

export function getIsDev() {
  return ['uat', 'sit', 'sit01'].includes(getEnv())
}

export function getIsSit() {
  return getEnv() === 'sit'
}

export function getIsBelowEnv(targetEnv: Environment) {
  return Environments[getEnv()] <= Environments[targetEnv]
}

enum Environments {
  sit,
  sit01,
  uat,
  sta,
  prod
}

type Environment = 'sit' | 'sit01' | 'uat' | 'sta' | 'prod'

declare global {
  interface Window {
    __REZIO_CONFIG__: RezioConfig
  }
}

interface RezioConfig {
  API_BASE_URL: string
  MYSITE_BASE_URL: string
  GALLEON_BASE_URL: string
  SCM_PROXY: string
  DATA_STONE_BASE_URL: string
}

type Service = 'api' | 'nimda' | 'mysite' | 'sonic' | 'galleon' | 'wdfc' | 'scm' | 'agent' | 'ds'

// HARDCODE: 先半寫死，之後按照sit domain調整
const getSITPrefix = (service: Service): string => {
  if (service === 'ds') {
    return ''
  }

  if (Platform.OS === 'web' && window?.location?.host.startsWith('admin')) {
    return ''
  }

  return 'prototype-'
}

export function getEnv(): Environment {
  if (overrideEnv || Updates.channel) {
    return overrideEnv || (Updates.channel === 'preview' ? 'uat' : 'prod')
  }

  switch (Platform.OS === 'web' ? window?.location?.host : 'sit') {
    case 'admin.uat.rezio.io':
    case 'admin.uat.rezio.link':
      return 'uat'
    // case 'admin.box.rezio.io':
    // case 'admin.box.rezio.link':
    //   return 'box'
    case 'admin.sta.rezio.io':
    case 'admin.sta.rezio.link':
      return 'sta'
    case 'admin.rezio.io':
    case 'admin.rezio.link':
      return 'prod'
    case 'prototype-admin.sit01.rezio.io':
      return 'sit01'
    default:
      return 'sit'
  }
}

export const getServiceHost = (service: Service = 'api', subdomain?: string): string => {
  const env = getEnv()
  const domain =
    Platform.OS === 'web' && window?.location?.host.endsWith('.link') ? 'rezio.link' : 'rezio.io'

  switch (env) {
    case 'sit':
      return service === 'wdfc'
        ? `lumen-wdfc.sit.${domain}`
        : `${getSITPrefix(service)}${service}.sit.${domain}`
    case 'sit01':
      return service === 'wdfc'
        ? `lumen-wdfc.sit01.${domain}`
        : `${getSITPrefix(service)}${service}.sit01.${domain}`
    case 'sta':
      // no stage sonic
      if (['sonic'].includes(service)) {
        return `${service}.${domain}`
      }
      return service === 'mysite' && subdomain
        ? `${subdomain}.sta.rezio.shop`
        : `${service}.sta.${domain}`
    case 'prod':
      return service === 'mysite' && subdomain ? `${subdomain}.rezio.shop` : `${service}.${domain}`
    default:
      return service === 'mysite' && subdomain
        ? `${subdomain}.${env}.rezio.shop`
        : `${service}.${env}.${domain}`
  }
}

interface AppConfig {
  store?: any
  basic?: Partial<Basic>
  reloadBasic: () => Promise<void>
}

export const AppConfigContext = createContext<AppConfig>(null)

export function getConfig(subdomain?: string): RezioConfig {
  const config = {
    API_BASE_URL: `https://${getServiceHost('nimda')}/`,
    MYSITE_BASE_URL: `https://${getServiceHost('mysite', subdomain)}/`,
    GALLEON_BASE_URL: `https://${getServiceHost('galleon')}/`,
    WD40_BASE_URL: `https://${getServiceHost('wdfc')}/`,
    SCM_PROXY: `https://${getServiceHost('scm')}`,
    DATA_STONE_BASE_URL: `https://${getServiceHost('ds')}/api/`
  }

  window.__REZIO_CONFIG__ = config
  return config
}

export function setEnv(env: Environment): void {
  overrideEnv = env
  getConfig()
  AsyncStorage.setItem('override_env', env).catch(console.error)
}

export const { API_BASE_URL, MYSITE_BASE_URL, GALLEON_BASE_URL } = getConfig()
export default getConfig()
;(async function () {
  overrideEnv = (await AsyncStorage.getItem('override_env')) as Environment
})().catch(console.error)

export function getKKDayOrMKPBaseURL() {
  const env = getEnv()
  switch (env) {
    case 'uat':
      return 'https://www.sit.kkday.com'
    case 'sta':
      return 'https://www.stage.kkday.com'
    case 'prod':
      return 'https://www.kkday.com'
    case 'sit':
      return 'https://www-207.sit.kkday.com'
    default:
      return ''
  }
}
