import { nanoid } from 'nanoid/non-secure'

type Role = 'rezio' | 'scm'

interface BaseRPCMessage {
  sender: Role
  id: string
}

interface RPCRequest extends BaseRPCMessage {
  func: string
  args?: any[]
}

interface RPCResponse extends BaseRPCMessage {
  res?: any
  err?: any
}

const isRequest = (data: any): data is RPCRequest => data.func

export default class RezioRPC {
  constructor(role: Role, postMessage: (message: string) => void) {
    this.me = role
    this.postMessage = postMessage

    this.expose('ping', () => {
      this.liveness = true
      if (this.me === 'rezio') {
        clearTimeout(this._livenessTimeout)
        this._livenessTimeout = setTimeout(() => {
          this.liveness = false
        }, 5000)
      }
      return 'pong'
    })

    if (this.me === 'scm') {
      this.checkLiveness()
    }
  }

  private postMessage: (message: string) => void

  /**
   * 讓rezio/scm判斷是否已經有連結成功
   * scm端: 這個值會在ping成功收到回應'pong'時設為true，並每秒ping一次
   * rezio端: 這個值會在收到scm的ping指令時設為true，並在5秒後設為false
   */
  get liveness(): boolean {
    return this._liveness
  }

  set liveness(value: boolean) {
    if (value !== this._liveness) {
      this._liveness = value
    }
  }

  private _liveness = false
  private _livenessTimeout?: NodeJS.Timeout

  /**
   * scm端每秒ping會一次rezio，確保連線正常
   */
  checkLiveness = async () => {
    if (this.me === 'scm') {
      const r = await Promise.race([
        this.call('ping'),
        new Promise((resolve) => setTimeout(() => resolve(null), 1000))
      ])
      this.liveness = r === 'pong'
      setTimeout(this.checkLiveness, 1000)
    }
  }

  me: Role
  get target(): Role {
    return this.me === 'scm' ? 'rezio' : 'scm'
  }

  call = async (func: string, ...args: any[]) => {
    const id = nanoid()
    const message: RPCRequest = { sender: this.me, id, func, args }
    const data = await new Promise<RPCResponse>((resolve) => {
      this.__rpc_reqs.set(id, resolve)
      this.postMessage(JSON.stringify(message))
    })
    this.__rpc_reqs.delete(id)
    if (data.err) {
      throw Object.assign(new Error(), data.err)
    } else {
      return data.res
    }
  }

  handle = async (data: string | RPCRequest | RPCResponse) => {
    // 檢查/整理資料成 RPCRequest or RPCResponse
    // 由於可能會onmessage可能會收到如瀏覽器插件的訊息，如果不是對方送來的話就直接drop掉
    try {
      if (typeof data === 'string') {
        data = JSON.parse(data) as RPCRequest | RPCResponse
      }
      if (!data) return
      if (data.sender !== this.target) return
    } catch (e) {
      return
    }

    if (isRequest(data)) {
      const message: RPCResponse = { sender: this.me, id: data.id }
      try {
        if (!this.__rpc_exposes.has(data.func)) {
          throw new Error(`Function ${data.func} not found`)
        }
        message.res = await this.__rpc_exposes.get(data.func)(...data.args)
      } catch (e) {
        message.err = Object.assign({ message: e.message }, e.output?.payload)
      }
      this.postMessage(JSON.stringify(message))
    } else {
      this.__rpc_reqs.get(data.id)?.(data)
    }
  }

  expose = (func: string, handler: (...args: any) => any) => {
    this.__rpc_exposes.set(func, handler)
  }

  __rpc_exposes: Map<string, (...args: any) => any> = new Map()
  __rpc_reqs: Map<string, (value: RPCResponse) => void> = new Map()
}
