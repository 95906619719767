import { View, Text } from 'react-native'

import { Page, StaticImage, ThemedButton } from '@rezio/components'
import { useStores, useRouter } from '@rezio/core/hooks'
import { palette, template } from '@rezio/res/theme'

export default function Page404() {
  const { t } = useStores()
  const router = useRouter()
  return (
    <Page notitle>
      <View style={{ alignItems: 'center', paddingVertical: 70 }}>
        <Text style={{ fontSize: 28, fontWeight: 'bold', color: palette.brand, marginBottom: 40 }}>
          {t('COMMON.API_EXCEPTION_PAGE_NOT_FOUND')}
        </Text>
        <StaticImage
          style={[template.center, { width: 365, height: 360 }]}
          source={require('../assets/404.png')}
        />
        <Text style={{ color: '#c3c3c3', fontSize: 12, paddingBottom: 20 }}>
          {JSON.stringify({ path: router.pathname, query: router.query })}
        </Text>
        <ThemedButton onPress={() => router.push('/')} style={{ width: 160 }}>
          {t('COMMON.BACK_TO_HOME')}
        </ThemedButton>
      </View>
    </Page>
  )
}
