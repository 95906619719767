export enum PLAN_FEATURES {
  REZIO_BASIC = 'REZIO_BASIC', // 基本設定
  REZIO_APP = 'REZIO_APP', // rezio app 使用
  REZIO_EXTRA = 'REZIO_EXTRA', // 加購項目 - 加購項目 & 新增
  API_TOKEN = 'API_TOKEN', // API 金鑰
  CUSTOM_VOUCHER_TEMPLATE = 'CUSTOM_VOUCHER_TEMPLATE', // 自訂憑證
  VOUCHER_CUSTOMER_REDEEM = 'VOUCHER_CUSTOMER_REDEEM', // 銷售工具 - 憑證設定 & 行程編輯 - 上架設定/憑證設定/顧客核銷
  ONLINE_PAYMENT = 'ONLINE_PAYMENT', // 一般設定  - 預訂網站付款設定  - 線上金流
  ORDER_BCCSETTING = 'ORDER_BCCSETTING', // 一般設定  - 訂單通知信設定  - 訂單通知信BCC開關
  VOUCHER_THIRD_PARTY = 'VOUCHER_THIRD_PARTY', // 上架設定/憑證設定/憑證類型-第三方串接
  VOUCHER_OTA_KKDAY = 'VOUCHER_OTA_KKDAY', // 上架設定/憑證設定/憑證類型-OTA憑證-KKday
  VOUCHER_GEN_MULTIPLE_TIMES = 'VOUCHER_GEN_MULTIPLE_TIMES', // 上架設定/憑證設定/憑證產生數量-依預訂人/項目數量
  VOUCHER_REDEEM_MULTIPLE_TIMES = 'VOUCHER_REDEEM_MULTIPLE_TIMES', // 上架設定/憑證設定-一券N銷
  ORDER_PRODUCT_SNAPSHOT = 'ORDER_PRODUCT_SNAPSHOT', // 行程明細（Snapshot）
  ACCOUNT_STATEMENT = 'ACCOUNT_STATEMENT', // 結算報表
  ALGOLIA = 'ALGOLIA', // ALGOLIA
  DISTRIBUTION = 'DISTRIBUTION', // 分銷商
  DISTRIBUTION_API_TOKEN = 'DISTRIBUTION_API_TOKEN', // 分銷商 API 金鑰
  CHANNEL = 'CHANNEL', // 通路
  CHANNEL_MYSITE = 'CHANNEL_MYSITE', // 通路-MYSITE
  CHANNEL_KKDAY = 'CHANNEL_KKDAY', // 通路-KKDAY
  CHANNEL_KKDAYMKP = 'CHANNEL_KKDAYMKP', // 通路-KKDAY 商城
  CHANNEL_ACTIVITYJAPAN = 'CHANNEL_ACTIVITYJAPAN', // 通路-AJ
  CHANNEL_KLOOK = 'CHANNEL_KLOOK', // 通路-KLOOK ＊＊
  CHANNEL_VIATOR = 'CHANNEL_VIATOR',
  CHANNEL_TRIP = 'CHANNEL_TRIP',
  CHANNEL_POS = 'CHANNEL_POS', // 裝置-POS
  CHANNEL_KIOSK = 'CHANNEL_KIOSK', // 裝置-KIOSK
  CHANNEL_TTD = 'CHANNEL_TTD', // 通路-Google ttd 設定
  CHANNEL_JALAN = 'CHANNEL_JALAN', // 通路-Jalan
  CHANNEL_GYG = 'CHANNEL_GYG', // 通路-GYG
  GATE = 'GATE', // 裝置-閘門
  KKDAY_SCM = 'KKDAY_SCM', // kkday scm
  SHOPPING_CART = 'SHOPPING_CART',
  TICKET = 'TICKET', // Non Session Ticket (NST)
  MESSAGE_CENTER = 'MESSAGE_CENTER', // 訊息
  REDEEM_PERMISSION = 'REDEEM_PERMISSION', // 系統設定  - 核銷權限設定
  CURRENCY_OPTIONS = 'CURRENCY_OPTIONS', // Mysite 幣別選單
  LANGUAGE_MENU = 'LANGUAGE_MENU', // Mysite 語系選單
  MYSITE_CHANNEL_LAYOUT_SWITCH = 'MYSITE_CHANNEL_LAYOUT_SWITCH', // Mysite 通路版面切換
  // 純前端判斷用
  SUBSCRIPTION_BALANCE = 'SUBSCRIPTION_BALANCE',
  ON_REQUEST_ORDER = 'ON_REQUEST_ORDER' // 候補單
}

export enum EXTRA_FEATURES {
  INVOICE = 'INVOICE', // 電子發票
  RECEIPT = 'RECEIPT' // 代收轉付收據
}

export enum SUBSCRIPTION_PLAN_PERIOD {
  NONE = 'NONE',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  YEAR_3 = 'YEAR-3',
  YEAR_5 = 'YEAR-5',
  PERIOD = 'PERIOD' // 30天一期
}

export type PlanFeature = PLAN_FEATURES | EXTRA_FEATURES

/*
和後端的subscriptionStatus對照
  -1: OVERDUE 逾時未繳費
  0 : NONE    帳號初始未訂閱狀態 (預設)
  1 : PAID    正常訂閱狀態
  2 : PAUSE   不再使用，停止訂閱
  3 : BANNED  帳號停權
  4 : TRIAL   試用 (不出現升級按鈕)
*/
export enum SUBSCRIPTION_STATUS {
  OVERDUE = 'OVERDUE',
  NONE = 'NONE',
  PAID = 'PAID',
  PAUSE = 'PAUSE',
  BANNED = 'BANNED',
  TRIAL = 'TRIAL'
}

export enum SUBSCRIPTION_COUNTRY {
  TW = 'TW',
  JP = 'JP',
  KO = 'KO'
}

/**
 * 訂閱方案
 * @author @guychienll-kkday
 * @date 2024-08-06
 * @description
 * 1. PREMIUM +n 代表綁定 n 台機器
 * 2. 落日方案 定義 SUNSET_TIERS
 */
export enum TIERS {
  NONE = 'NONE',
  FREE = 'FREE',
  SPECIAL = 'SPECIAL',
  LITE = 'LITE',
  GROW = 'GROW',
  RISE = 'RISE',
  GROW_YEAR = 'GROW-YEAR',
  RISE_YEAR = 'RISE-YEAR',
  SUPER_YEAR = 'SUPER-YEAR',
  PRO = 'PRO',
  SUPER = 'SUPER',
  ENTERPRISE = 'ENTERPRISE',
  'RISE-YEAR' = 'RISE-YEAR',
  'SUPER-YEAR' = 'SUPER-YEAR',
  KKDAY = 'KKDAY',
  KKDAYRISE = 'KKDAYRISE',
  KKDAYSUPER = 'KKDAYSUPER',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  'GOLD-YEAR' = 'GOLD-YEAR',
  'GOLD-YEAR-3' = 'GOLD-YEAR-3',
  'GOLD-YEAR-5' = 'GOLD-YEAR-5',
  PLATINUM = 'PLATINUM',
  'PLATINUM-YEAR' = 'PLATINUM-YEAR',
  'PLATINUM-YEAR-3' = 'PLATINUM-YEAR-3',
  'PLATINUM-YEAR-5' = 'PLATINUM-YEAR-5',
  BASIC = 'BASIC',
  ENTRY = 'ENTRY',
  STANDARD = 'STANDARD',
  'STANDARD-YEAR' = 'STANDARD-YEAR',
  ADVANCE = 'ADVANCE',
  'ADVANCE-YEAR' = 'ADVANCE-YEAR',
  PREMIUM = 'PREMIUM',
  'PREMIUM-YEAR' = 'PREMIUM-YEAR',
  'PREMIUM-YEAR-3' = 'PREMIUM-YEAR-3',
  'PREMIUM-YEAR-5' = 'PREMIUM-YEAR-5',
  PREMIUM_PLUS_ONE = 'PREMIUM+1',
  PREMIUM_PLUS_ONE_YEAR = 'PREMIUM+1-YEAR',
  PREMIUM_PLUS_TWO = 'PREMIUM+2',
  PREMIUM_PLUS_TWO_YEAR = 'PREMIUM+2-YEAR'
}

/**
 * @author @guychienll-kkday
 * @date 2024-08-06
 *
 * 與 april 定義目前落日方案有哪些
 */
export const SUNSET_TIERS = [
  TIERS.FREE,
  TIERS.LITE,
  TIERS.GROW,
  TIERS.PRO,
  TIERS.KKDAYRISE,
  TIERS.KKDAYSUPER,
  TIERS.SILVER,
  TIERS.GOLD,
  TIERS['GOLD-YEAR'],
  TIERS['GOLD-YEAR-3'],
  TIERS['GOLD-YEAR-5'],
  TIERS.PLATINUM,
  TIERS['PLATINUM-YEAR'],
  TIERS['PLATINUM-YEAR-3'],
  TIERS['PLATINUM-YEAR-5'],
  TIERS.BASIC,
  TIERS.ENTRY,
  TIERS.STANDARD,
  TIERS['STANDARD-YEAR'],
  TIERS['PREMIUM-YEAR-3'],
  TIERS['PREMIUM-YEAR-5']
]

export enum TIER_SERIES {
  Default,
  VIP,
  KKday,
  JpVersion
}

export enum TIER_PLATFORM {
  REZIO = 'REZIO',
  KKDAY = 'KKDAY',
  SPECIAL = 'SPECIAL'
}

export enum SubscriptionType {
  Trial = 'T',
  Reservation = 'R',
  Subscription = 'S'
}

export enum ROLE_TITLE {
  REDEEM_STUFF = 'REDEEM_STUFF', // 核銷員
  USER = 'USER', // 一般使用者
  ADMIN = 'ADMIN', // 管理員
  REZIO_EXPERT = 'REZIO_EXPERT', // rezio專家帳號
  STORE_OWNER = 'STORE_OWNER', // 付費帳號
  ORDER_ONLY = 'ORDER_ONLY' // 理單員
}

export const PERMISSION_GROUP_UUID = {
  [ROLE_TITLE.ADMIN]: '324f23fe-663e-4d83-837f-390e89fed106',
  [ROLE_TITLE.USER]: '1b7f5e5f-13b8-4c13-ab3d-707dd3d64c6b',
  [ROLE_TITLE.REZIO_EXPERT]: '9c7baf5d-7510-468b-b1e1-82f16ebee434',
  [ROLE_TITLE.REDEEM_STUFF]: 'df4fe508-3621-44e1-991e-e5209530e24e',
  [ROLE_TITLE.ORDER_ONLY]: 'd5911a7b-0185-4c04-aaa5-a7fa4454095a'
}

export enum PERMISSION_TITLE {
  ORDER_OWNER = 'ORDER_OWNER', // action new 只能 new 自己，作為 OP 領單
  SELF_ACCOUNT = 'SELF_ACCOUNT',
  OTHER_ACCOUNT = 'OTHER_ACCOUNT',
  STORE_BASIC = 'STORE_BASIC',
  REDEEM_PERMISSION = 'REDEEM_PERMISSION',
  API_TOKEN = 'API_TOKEN',
  VOUCHER = 'VOUCHER',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ABNORMAL_ORDER = 'ABNORMAL_ORDER'
}

export enum PERMISSION_ACTION {
  GET = 'GET',
  EDIT = 'EDIT',
  NEW = 'NEW',
  DELETE = 'DELETE'
}

export enum SubscriptionPaymentType {
  ATM = 'ATM',
  FREE = 'FREE',
  ZERO = 'ZERO',
  NEWEBATM = 'NEWEBATM', // TODO: 進行「訂閱資訊頁顯示調整」時，要記得跟後端確認「藍新 ATM」拿到的資料
  UNPAID = 'UNPAID',
  WITHFEE = 'WITHFEE'
}

export interface PlanInfo {
  uuid: string
  planName: string
  tier: TIERS
  countryUuid: string
  currencyUuid: string
  planPrice: number
  createdAt: string
  updatedAt: string
  storeLimit: number
  status: number
  trialDays: number
  accountLimit: number
  repeatRule: string
  startDate: string
  endDate: string
  title: TIERS
  planPermission: string
  couponLimit: number
  trialType: string
  trialDate: string
  perMonthPrice: number
  paymentType: SubscriptionPaymentType
  period: SUBSCRIPTION_PLAN_PERIOD
  discountPrice: number
  isJPPlan: boolean
}

export interface SortedPlan {
  group: TIERS
  items: PlanInfo[]
}
