/* eslint-disable @typescript-eslint/no-floating-promises */
import styled from '@emotion/native'
import AsyncStorageLib from '@react-native-async-storage/async-storage'
import { ThemedButton } from '@rezio/components'
import { Modal } from '@rezio/components/modal'
import { useLayout, useStores } from '@rezio/core/hooks'
import { manipulator, palette, template } from '@rezio/res/theme'
import { every, merge, pipe, prop } from 'lodash/fp'
import React, { useEffect, useRef, useState } from 'react'
import { Image, Text, View, ButtonProps, StyleSheet } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import Markdown from 'react-native-markdown-display'
import CustomScrollbar from './CustomScrollView/CustomScrollView'

interface AnnouncementType {
  uuid: string
  content: string
  title: string
  startTs: number
  endTs: number
}

interface PanelContentType {
  announcements: AnnouncementType[]
  handleOnPress: ButtonProps['onPress']
  showCountdown: boolean
  isMobile: boolean
}

interface PanelType {
  announcements: AnnouncementType[]
  isVisible: boolean
  showCountdown: boolean
  closeModal: () => void
  setAllToChecked: () => void
}

export const AnnouncementPanel = ({
  isVisible,
  announcements,
  closeModal,
  setAllToChecked,
  showCountdown
}: PanelType) => {
  const { isMobile } = useLayout()
  return (
    <Modal isVisible={isVisible}>
      <View style={manipulator.container('column', 'center', 'center')}>
        <View
          className='max-h-[480px] w-[100%] max-w-[350px] sm:max-h-[420px] sm:max-w-[800px]'
          style={[
            manipulator.panel(...(isMobile ? [20, 20, 20, 20] : [40, 40, 40, 40])),
            { borderRadius: isMobile ? 3 : 10 }
          ]}
        >
          <AnnouncementPanelContent
            handleOnPress={pipe(setAllToChecked, closeModal)}
            {...{ showCountdown, announcements, isMobile }}
          />
        </View>
      </View>
    </Modal>
  )
}

export const AnnouncementPanelContent = ({
  announcements,
  handleOnPress,
  showCountdown,
  isMobile
}: PanelContentType) => {
  const countdownSecond = useCountdown(showCountdown && 5)
  const { t } = useStores()
  const [childrenHeight, setChildrenHeight] = useState(0)

  return (
    <View className='flex h-[100%] flex-col  items-center justify-center sm:flex-row sm:items-start'>
      <View className='mb-6 shrink-0 sm:mb-0 sm:mr-8'>
        <Greeting text={t('ANNOUNCEMENT.GREETING')} {...{ isMobile }} />
        <WatchHere {...{ isMobile }} />
      </View>
      <View className='flex h-[100%] w-[100%] shrink flex-col'>
        <CustomScrollbar childrenHeight={childrenHeight}>
          <FlatList<AnnouncementType>
            data={announcements}
            renderItem={genAnnouncementCell(isMobile)}
            ItemSeparatorComponent={Hr}
            ListFooterComponent={styled.View({ height: isMobile ? 10 : 20 })}
            keyExtractor={prop('uuid')}
            onLayout={(event) => {
              setChildrenHeight(event.nativeEvent.layout.height)
            }}
          />
        </CustomScrollbar>
        <FadeoutBlock {...{ isMobile }} />
        <View
          style={[
            {
              borderRadius: 5,
              width: '100%',
              overflow: 'hidden',
              margin: 'auto'
            }
          ]}
        >
          <ThemedButton disabled={!!countdownSecond} onPress={handleOnPress}>
            <Text style={{ color: palette.white }}>
              {t('ANNOUNCEMENT.HIDE_FOR_NOW') + `${countdownSecond ? ` (${countdownSecond})` : ''}`}
            </Text>
          </ThemedButton>
        </View>
      </View>
    </View>
  )
}

const FadeoutBlock = styled.View<{ isMobile: boolean }>`
  margin-top: ${({ isMobile }) => (isMobile ? '-10px' : '-20px')};
  height: ${({ isMobile }) => (isMobile ? '11px' : '20px')};
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 50%);
`

export const Hr = ({ color = 'black', style = {} }) => (
  <View style={{ marginTop: 15, borderBottomColor: color, borderBottomWidth: 1, ...style }} />
)

const Greeting = ({ text, isMobile }) => (
  <Text
    className='hidden sm:inline'
    style={[
      template.bold,
      { display: isMobile ? 'none' : 'flex' },
      { fontSize: isMobile ? 18 : 32 },
      { textAlign: 'center', color: palette.primary }
    ]}
  >
    {text}
  </Text>
)

const WatchHere = ({ isMobile }) => (
  <Image
    resizeMode='contain'
    style={[
      template.center,
      isMobile ? { width: 145, height: 120 } : { width: 219, height: 199 },
      manipulator.margin(isMobile ? 0 : 30, 'auto' as any, 0, 'auto' as any)
    ]}
    source={require('../assets/announcement.png')}
  />
)

const announceMDStyle = (isMobile) =>
  StyleSheet.create({
    heading1: {
      fontSize: isMobile ? 14 : 22,
      fontWeight: 'bold',
      color: palette.primary,
      marginTop: 30,
      marginBottom: isMobile ? 10 : 20
    },
    ...(isMobile && { paragraph: { fontSize: 12 } })
  })

const genAnnouncementCell =
  (isMobile) =>
  ({ item }) => (
    // @ts-expect-error // * related to react 18 FC type no longer contains implicit children
    <Markdown mergeStyle style={announceMDStyle(isMobile)}>
      {`# ${item.title}\n ${item.content}`}
    </Markdown>
  )

const useCountdown = (initSeconds: number, interval = 1000) => {
  const [seconds, setSeconds] = useState(initSeconds)
  const timer = useRef(null)
  useEffect(() => {
    timer.current = setInterval(() => setSeconds((x) => x - 1), interval)
  }, [])
  useEffect(() => {
    seconds < 1 && clearInterval(timer.current)
  }, [seconds])
  return seconds
}

const genState = (announcements: AnnouncementType[], flag = false) =>
  announcements?.map(prop('uuid'))?.reduce((state, ele) => ({ ...state, [ele]: flag }), {})

export const getIsAllChecked = async (announcements: AnnouncementType[]) =>
  await AsyncStorageLib.getItem('announcementState')
    .then(JSON.parse)
    .then(merge(genState(announcements)))
    .then(every(Boolean))

export const setAllToChecked = async (announcements: AnnouncementType[]) =>
  await AsyncStorageLib.setItem('announcementState', JSON.stringify(genState(announcements, true)))
