import { lazy } from 'react'

import pageformPages from './platformPages'
import type { PageConfig } from './types'

const PageDashboard = lazy(() => import('@rezio/pages/index'))
const PageLogin = lazy(() => import('@rezio/pages/login'))
const PageInternal = lazy(() => import('@rezio/pages/internal'))
const PageForgetPassword = lazy(() => import('@rezio/pages/forgetPassword'))
const PageWithoutStore = lazy(() => import('@rezio/pages/withoutStore'))
const PageProduct = lazy(() => import('@rezio/pages/product'))
const PageProductAdd = lazy(() => import('@rezio/pages/product/add'))
const PagePublishCalendar = lazy(() => import('@rezio/pages/publish/calendar'))
const PagePublishStockCalendar = lazy(() => import('@rezio/pages/publish/stockCalendar'))
const PageResourceCalendar = lazy(() => import('@rezio/pages/publish/resourceCalendar'))
const PageDiscountCouponCode = lazy(() => import('@rezio/pages/discount/couponCode'))
const PageDiscountCouponCodeAdd = lazy(() => import('@rezio/pages/discount/couponCode/add'))
const PagePublishSession = lazy(() => import('@rezio/pages/publish/session'))
const PageOrderQuickSearch = lazy(() => import('@rezio/pages/order/quickSearchPage'))
const PageOrder = lazy(() => import('@rezio/pages/order/index'))
const PageOrderDetail = lazy(() => import('@rezio/pages/order/detail'))
const PageOrderAdd = lazy(() => import('@rezio/pages/order/add'))
const PageScanner = lazy(() => import('@rezio/pages/redeem/index'))
const PageLanRedeem = lazy(() => import('@rezio/pages/redeem/lan'))
const PageRedeemReport = lazy(() => import('@rezio/pages/redeem/redeemReport'))
const PageRedeemRecord = lazy(() => import('@rezio/pages/redeem/redeemRecord'))
const PageInit = lazy(() => import('@rezio/pages/init'))
const PageWarning = lazy(() => import('@rezio/pages/warning'))
const PageOrderCalendar = lazy(() => import('@rezio/pages/order/calendar'))
const PageAbnormalOrder = lazy(() => import('@rezio/pages/channel/abnormalOrder'))
const PageAbnormalOrderEdit = lazy(() => import('@rezio/pages/channel/abnormalOrder/edit'))
const PageConversationList = lazy(() => import('@rezio/pages/conversation/index'))
const PageDataStudioReport = lazy(() => import('@rezio/pages/report/dataStudio'))
const PageToBeProcessedDashboard = lazy(() => import('@rezio/pages/dashboard/toBeProcessed'))
const PageCheckConnection = lazy(() => import('@rezio/pages/check'))

export const pages: PageConfig[] = [
  { name: 'PageDashboard', path: '/dashboard', screen: PageDashboard },
  { name: 'PageLogin', path: '/(login|)', screen: PageLogin, withoutLayout: true },
  { name: 'PageInternal', path: '/internal', screen: PageInternal },
  { name: 'PageInit', path: '/init', screen: PageInit, headerOnly: true },
  {
    name: 'PageWithoutStore',
    path: '/withoutStore',
    screen: PageWithoutStore,
    withoutLayout: true
  },
  {
    name: 'PageForgetPassword',
    path: '/forgetPassword',
    screen: PageForgetPassword,
    withoutLayout: true
  },
  { name: 'PageProduct', path: '/product', screen: PageProduct },
  { name: 'PageProductAdd', path: '/product/add', screen: PageProductAdd },
  {
    name: 'PagePublishCalendar',
    path: '/publish/calendar',
    screen: PagePublishCalendar
  },
  {
    name: 'PagePublishStockCalendar',
    path: '/publish/stockCalendar',
    screen: PagePublishStockCalendar
  },
  {
    name: 'PageResourceCalendar',
    path: '/publish/resourceCalendar',
    screen: PageResourceCalendar
  },
  {
    name: 'PageDiscountCouponCode',
    path: '/discount/couponCode',
    screen: PageDiscountCouponCode
  },
  {
    name: 'PageDiscountCouponCodeAdd',
    path: '/discount/couponCode/add',
    screen: PageDiscountCouponCodeAdd
  },
  {
    name: 'PageDiscountCouponCodeEdit',
    path: '/discount/couponCode/detail',
    screen: PageDiscountCouponCodeAdd
  },
  {
    name: 'PagePublishSession',
    path: '/publish/session',
    screen: PagePublishSession
  },
  {
    name: 'PageOrder',
    path: '/quicksearch',
    screen: PageOrderQuickSearch
  },
  { name: 'PageOrder', path: '/order', screen: PageOrder },
  { name: 'PageOrderDetail', path: '/order/detail', screen: PageOrderDetail },
  { name: 'PageOrderAdd', path: '/order/add', screen: PageOrderAdd },
  { name: 'PageScanner', path: '/(scanner|redeem)', screen: PageScanner },
  { name: 'PageLanRedeem', path: '/lan', screen: PageLanRedeem },
  {
    name: 'PageRedeemReport',
    path: '/redeem/report',
    screen: PageRedeemReport
  },
  {
    name: 'PageRedeemRecord',
    path: '/redeem/record',
    screen: PageRedeemRecord
  },
  {
    name: 'PageWarning',
    path: '/warning',
    screen: PageWarning,
    headerOnly: true
  },
  {
    name: 'PageOrderCalendar',
    path: '/order/calendar',
    screen: PageOrderCalendar
  },
  {
    name: 'PageAbnormalOrder',
    path: '/abnormalOrder',
    screen: PageAbnormalOrder
  },
  {
    name: 'PageAbnormalOrderEdit',
    path: '/abnormalOrder/edit',
    screen: PageAbnormalOrderEdit
  },
  {
    name: 'PageConversationList',
    path: '/conversation',
    screen: PageConversationList
  },
  {
    name: 'PageDataStudioReport',
    path: '/customReport',
    screen: PageDataStudioReport
  },
  {
    name: 'PageToBeProcessedDashboard',
    path: '/toBeProcessed',
    screen: PageToBeProcessedDashboard
  },
  {
    name: 'PageCheckConnection',
    path: '/check',
    screen: PageCheckConnection
  },
  ...pageformPages
]
