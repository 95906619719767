/* global MouseEvent */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { Platform, View } from 'react-native'

const Droppable = forwardRef(function Droppable(props, ref) {
  const {
    prevSelectedFiles = [],
    multiple = false,
    disabled = false,
    imageCategory = '',
    style
  } = props
  const inputRef = useRef()

  const onDrop = useCallback(
    (acceptedFiles) => {
      props.onFiles?.(acceptedFiles, prevSelectedFiles, imageCategory)
    },
    [JSON.stringify(prevSelectedFiles), imageCategory]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    disabled
  })

  useEffect(() => {
    props.onDragActive && props.onDragActive(isDragActive)
  }, [isDragActive])

  const { onClick, ...handlers } = getRootProps()

  useImperativeHandle(
    ref,
    () => ({
      open() {
        onClick(new MouseEvent('click'))
      }
    }),
    [onClick]
  )

  if (Platform.OS !== 'web') {
    return <View {...props} />
  }

  return (
    <div style={style} {...handlers}>
      <input ref={inputRef} {...getInputProps()} />
      {props.children}
    </div>
  )
})

export { Droppable }
