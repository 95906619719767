import { range } from 'lodash'
import { of } from 'rxjs'
import { delay } from 'rxjs/operators'

export const sleep = async (milliSeconds: number) =>
  await of(1).pipe(delay(milliSeconds)).toPromise()

export async function* genStepWithDelay(steps: number, milliSeconds: number) {
  for (const step of range(steps)) {
    await sleep(milliSeconds)
    yield step
  }
}
