import {
  ThemedButton,
  ThemedCheckBox,
  DialogModal,
  PageWidthModal,
  DynamicImage,
  MediaLibrary,
  HoverableOpacity
} from '@rezio/components'
import { MaterialIcons } from '@expo/vector-icons'
import { Modal } from '@rezio/components/modal'
import { Tooltip } from '@rezio/components/tooltip/tooltip'
import { TransText } from '@rezio/components/transText'
import { ErrorIcon } from '@rezio/core/errorHandler'
import { useStores } from '@rezio/core/hooks'
import { manipulator, palette, template } from '@rezio/res/theme'
import { isUuid } from '@rezio/utils/format'
import { ImageLayoutType, MediaLibraryType } from '@rezio/utils/types'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect, useState, useMemo, useRef, useCallback, ReactNode } from 'react'
import { Text, View, Platform, ViewStyle, TextStyle, StyleProp } from 'react-native'

import { DroppableWrapper } from './droppableWrapper'
import { MediaLibraryActionButton } from './mediaLibraryActionButton'
import { SortableItem } from './sortableComponents'
import { ImageItem, MediaLibraryCategory, WrapperProps, ContainerProps } from './types'

interface ImageUploaderProps {
  title?: string
  rule?: React.ReactNode
  placeholder?: string
  value?: string[]
  imageLayoutType: ImageLayoutType
  category: MediaLibraryCategory
  singleImage?: boolean
  required?: boolean
  disabledButton?: boolean
  isVisibleLabel?: boolean
  containerStyle?: StyleProp<ViewStyle>
  droppableStyle?: StyleProp<ViewStyle>
  emptyImageTextStyle?: StyleProp<TextStyle>
  wrapper?: (props: WrapperProps) => JSX.Element
  imageContainer?: (props: ContainerProps) => JSX.Element
  customImageContent?: ReactNode[]
  onSwapImages?: (nextImagesIndex: number[], originSelectedImages: string[]) => void
  onChange?: (value: string[]) => void
}

const defaultProps: ImageUploaderProps = {
  category: 'productManager',
  onChange: () => {},
  imageLayoutType: ImageLayoutType.Single
}

export const ImageUploader: React.FC<ImageUploaderProps> = observer((props) => {
  const { store, t } = useStores()
  const droppableRef = useRef(null)
  const mediaDroppableRef = useRef(null)
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [images, setImages] = useState<ImageItem[]>([])
  const [openMediaLibrary, setOpenMediaLibrary] = useState(false)

  const {
    title = '',
    rule = '',
    placeholder = '',
    value = [],
    droppableStyle = {},
    imageLayoutType = ImageLayoutType.Single,
    category = 'productManager',
    singleImage = false,
    required = false,
    disabledButton = false,
    customImageContent = [],
    isVisibleLabel = true,
    wrapper: Wrapper = View,
    imageContainer: ImageCustomContainer = View,
    emptyImageTextStyle = {}
  } = props

  const isEmptyImages = _.isEmpty(images)
  const isDisabledButton = (singleImage && !_.isEmpty(images)) || disabledButton
  const imageStore = store.imageStore.getCategory(category)

  const fetchIntegralImageData = useCallback(async () => {
    const emptyValueIndex = []
    const defaultImageItem = {
      size: 0,
      url: 'https://img.rezio.io/img/default-images.jpg',
      clientFileName: '',
      storeMediaUuid: '',
      storeMediaUsageUuid: ''
    }

    // 若資料是 URL, 則不需再透過 storeMediaUsageUuid 拿資料, 直接顯示即可
    if (singleImage && !isUuid(value[0])) {
      setImages([{ ...defaultImageItem, url: value[0] }])
      return
    }

    const formatUuid = value?.map((eachUuid) => {
      // 行程表的資料需要區分第 n 天的行程的圖片, 資料會是 "groupID_uuid" 的形式
      const regexUnderline = new RegExp(/_/g)
      const hasGroupIDImageData = regexUnderline.test(eachUuid)
      if (hasGroupIDImageData) {
        const imageStoreMediaUsageUuid = eachUuid.split('_')[1]
        return isUuid(imageStoreMediaUsageUuid) ? imageStoreMediaUsageUuid : ''
      }

      return eachUuid
    })

    // storeMediaUsageUuid === '' 資料的 index
    if (_.isArray(value)) {
      emptyValueIndex.push(
        ...formatUuid.reduce((result, item, index) => {
          if (_.isEmpty(item)) {
            return [...result, index]
          }
          return result
        }, [])
      )
    }

    const integralImageData: ImageItem[] = await imageStore.loadImages(
      _.isArray(formatUuid) ? _.filter(formatUuid, (item) => !_.isEmpty(item)) : [value]
    )

    const formatImageData = _.map(integralImageData, (image: ImageItem, index) => {
      // API response 的 storeMediaUsageUuid 為無效 uuid, 補預設資料
      if (image == null) {
        return { ...defaultImageItem, storeMediaUsageUuid: `${index}` }
      }

      return image
    })

    if (!_.isEmpty(emptyValueIndex)) {
      emptyValueIndex.forEach((index) =>
        formatImageData.splice(index, 0, { ...defaultImageItem, storeMediaUsageUuid: `${index}` })
      )
    }

    setImages(formatImageData)
  }, [imageStore, JSON.stringify(value)])

  useEffect(() => {
    if (!_.isEmpty(value)) {
      fetchIntegralImageData().catch((error) => console.error(error))
    } else {
      setImages([])
    }
  }, [fetchIntegralImageData])

  const handleUploadedImage = useCallback((nextImages: [], selectedFiles: ImageItem[]) => {
    props.onChange([...selectedFiles, ...nextImages].map((file) => file.storeMediaUsageUuid))
  }, [])

  const handleSelectImage = useCallback(() => {
    Platform.OS === 'web' && droppableRef.current.open()
  }, [])

  const handleSelectMediaImage = useCallback(() => {
    Platform.OS === 'web' && mediaDroppableRef.current.open()
  }, [])

  const handleUploadImageClick = useCallback(() => {
    setOpenMediaLibrary(true)
  }, [])

  const handleCloseMediaLibrary = useCallback(() => {
    setOpenMediaLibrary(false)
  }, [])

  const handleUpdateSelectedItem = useCallback((mediaLibrarySelectedItems: string[]) => {
    props.onChange([...mediaLibrarySelectedItems])
  }, [])

  const handleSwappedImage = useCallback(
    (nextImagesIndex: number[], originSelectedImages: string[]) => {
      props.onSwapImages(nextImagesIndex, originSelectedImages)
    },
    [images]
  )

  const handleToggleSelectedAll = useCallback((nextSelectedAll) => {
    setIsSelectedAll((prevIsSelectedAll) => nextSelectedAll ?? !prevIsSelectedAll)
  }, [])

  const handleToggleIsDeleting = useCallback((nextIsDeleting) => {
    setIsDeleting((prevIsDeleting) => nextIsDeleting ?? !prevIsDeleting)
  }, [])

  const imageButtons = useMemo(() => {
    const disabledStyle = isDisabledButton ? palette.negative : palette.primary

    return [
      {
        containerStyle: {
          borderColor: disabledStyle,
          maxWidth: 'none',
          borderWidth: 1,
          backgroundColor: palette.white
        },
        iconColor: disabledStyle,
        textStyle: { color: disabledStyle },
        type: 'negative',
        text: t('COMMON.CHOOSE_IMAGE_LIBRARY_BUTTON'),
        icon: 'image' as const,
        disabled: isDisabledButton,
        onPress: handleUploadImageClick
      },
      {
        type: 'negative',
        text: t('COMMON.UPLOAD_IMAGE_BUTTON_NAME'),
        containerStyle: {
          backgroundColor: disabledStyle,
          maxWidth: 'none'
        },
        textStyle: { color: palette.white },
        icon: 'file-upload' as const,
        iconColor: palette.white,
        disabled: isDisabledButton,
        onPress: handleSelectImage
      }
    ]
  }, [isDisabledButton])

  const ImageContainer = ({
    children,
    index,
    sort,
    originSelectedImages,
    id,
    style
  }: ContainerProps) => {
    const [isHover, setIsHover] = useState(false)
    const imageTitle = singleImage
      ? t('MEDIA.SECTION_IMAGE')
      : `${t('MEDIA.PRODUCT_IMAGE_SORT', { sort })} ${
          sort === 1 ? `(${t('MEDIA.PRODUCT_IMAGE_COVER')})` : ''
        }`

    const handleMouseEnter = useCallback(() => setIsHover(true), [])
    const handleMouseLeave = useCallback(() => setIsHover(false), [])

    const handleRemoveImage = useCallback(() => {
      const nextImagesIndex = []
      const nextImages = images.filter((item, nextIndex) => {
        if (_.isString(item)) {
          return item !== id
        }

        if (nextIndex !== index) {
          nextImagesIndex.push(nextIndex)
        }
        return nextIndex !== index
      })

      setImages(nextImages)
      props?.onSwapImages
        ? props.onSwapImages(nextImagesIndex, originSelectedImages)
        : props.onChange(nextImages.map((image) => image.storeMediaUsageUuid))
    }, [id, images, props?.onSwapImages])

    if (ImageCustomContainer !== SortableItem) {
      return (
        <ImageCustomContainer
          index={index}
          sort={sort}
          id={id}
          style={{ backgroundColor: palette.white, borderRadius: 10, ...style }}
        >
          <View style={[manipulator.border('all', 'light', 10), { padding: 10 }]}>
            <View
              style={[manipulator.container('row', 'flex-end', 'center'), { marginBottom: 10 }]}
            >
              {!singleImage && (
                <ThemedButton
                  style={{ margin: 0, padding: 0, minWidth: 24, backgroundColor: 'transparent' }}
                  layout='default'
                >
                  <MaterialIcons name='drag-indicator' size={24} color='black' />
                </ThemedButton>
              )}
              <View style={{ marginLeft: singleImage ? 0 : 10, marginRight: 10, width: 100 }}>
                <Tooltip popover={imageTitle}>
                  <Text style={{ fontSize: 14 }} numberOfLines={1} ellipsizeMode='tail'>
                    {imageTitle}
                  </Text>
                </Tooltip>
              </View>
              <HoverableOpacity onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <ThemedButton
                  onPress={handleRemoveImage}
                  style={{ margin: 0, padding: 0, minWidth: 20, backgroundColor: 'transparent' }}
                  layout='default'
                >
                  <MaterialIcons
                    name='remove-circle'
                    size={20}
                    color={isHover ? palette.error : palette.gray}
                  />
                </ThemedButton>
              </HoverableOpacity>
            </View>
            {children}
          </View>
        </ImageCustomContainer>
      )
    }

    return (
      <ImageCustomContainer
        index={index}
        sort={sort}
        id={id}
        style={{ backgroundColor: palette.white, borderRadius: 10, ...style }}
      >
        {(dragListeners) => (
          <View style={[manipulator.border('all', 'light', 10), { padding: 10 }]}>
            <View
              style={[manipulator.container('row', 'flex-end', 'center'), { marginBottom: 10 }]}
            >
              {!singleImage && (
                <ThemedButton
                  {...dragListeners}
                  style={{ margin: 0, padding: 0, minWidth: 24, backgroundColor: 'transparent' }}
                  layout='default'
                >
                  <MaterialIcons name='drag-indicator' size={24} color='black' />
                </ThemedButton>
              )}
              <View
                {...dragListeners}
                style={{ marginLeft: singleImage ? 0 : 10, marginRight: 10, width: 100 }}
              >
                <Tooltip popover={imageTitle}>
                  <Text style={{ fontSize: 14 }} numberOfLines={1} ellipsizeMode='tail'>
                    {imageTitle}
                  </Text>
                </Tooltip>
              </View>
              <HoverableOpacity onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <ThemedButton
                  onPress={handleRemoveImage}
                  style={{ margin: 0, padding: 0, minWidth: 20, backgroundColor: 'transparent' }}
                  layout='default'
                >
                  <MaterialIcons
                    name='remove-circle'
                    size={20}
                    color={isHover ? palette.error : palette.gray}
                  />
                </ThemedButton>
              </HoverableOpacity>
            </View>
            <View {...dragListeners}>{children}</View>
          </View>
        )}
      </ImageCustomContainer>
    )
  }

  return (
    <View
      style={[
        manipulator.container('row', 'flex-start', 'stretch'),
        manipulator.border('all', 'light', 10),
        props?.containerStyle
      ]}
    >
      {isVisibleLabel && (
        <View style={{ marginRight: 10, width: 240 }}>
          {!_.isEmpty(title) && (
            <Text style={{ marginBottom: 10, fontSize: 18 }}>
              {title}
              {required && <TransText textStyle={{ paddingLeft: 2, color: 'red' }} transText='*' />}
            </Text>
          )}
          {!_.isEmpty(rule) && <Text style={{ marginBottom: 10, fontSize: 14 }}>{rule}</Text>}
          <View style={manipulator.container('row', 'flex-start', 'center')}>
            {imageButtons.map((button, index, row) => {
              return (
                <ThemedButton
                  key={index}
                  async={false}
                  disabled={button.disabled}
                  onPress={button.onPress}
                  containerStyle={[{ flex: '1 1 0px' }]}
                  style={[
                    { maxWidth: 125, marginLeft: index + 1 === row.length ? 10 : 0 },
                    { ...button.containerStyle }
                  ]}
                >
                  <MaterialIcons
                    name={button.icon}
                    size={20}
                    color={button.iconColor}
                    style={{ marginRight: 8 }}
                  />
                  <Text style={button.textStyle}>{button.text}</Text>
                </ThemedButton>
              )
            })}
          </View>
          <View className='mt-4 rounded-1 bg-primary-100 p-5'>
            <Text>{t('MEDIA.IMAGE_COPYRIGHT_DISCLAIMER')}</Text>
          </View>
        </View>
      )}
      <View style={{ flex: 1 }}>
        {!_.isEmpty(placeholder) && (
          <Text style={{ fontSize: 14, marginBottom: 10 }}>{placeholder}</Text>
        )}
        <DroppableWrapper
          droppableRef={droppableRef}
          isMultiple={!singleImage}
          storeCategory={category}
          prevSelectedFiles={images}
          onUploadedImage={handleUploadedImage}
          style={{ flex: 1, display: 'flex' }}
        >
          <View
            style={[
              manipulator.container(
                'column',
                isEmptyImages ? 'center' : 'flex-start',
                isEmptyImages ? 'center' : 'flex-start'
              ),
              {
                padding: 10,
                borderRadius: 10,
                backgroundColor: palette.panelBackground,
                overflow: 'hidden',
                flex: 1
              },
              droppableStyle
            ]}
          >
            {isEmptyImages ? (
              <Text style={[{ color: 'black' }, emptyImageTextStyle]}>
                {t('COMMON.EMPTY_IMAGE_WARNING_TEXT')}
              </Text>
            ) : (
              <Wrapper
                selectedImages={_.map(_.range(_.size(images)), (index) => index)}
                originSelectedImages={value}
                onChange={handleSwappedImage}
                style={[manipulator.container('row', 'flex-start', 'flex-start')]}
              >
                {images?.map((item: ImageItem, index: number) => {
                  return (
                    <ImageContainer
                      key={`${item?.storeMediaUsageUuid}-${index}`}
                      index={index}
                      sort={index + 1}
                      originSelectedImages={value}
                      id={item?.storeMediaUsageUuid}
                      style={{ margin: 5, borderRadius: 10 }}
                    >
                      <DynamicImage
                        file={item}
                        imageStyle={{ width: '100%', backgroundColor: palette.lightGray }}
                        isSelected={false}
                      />
                      {customImageContent[index]}
                    </ImageContainer>
                  )
                })}
              </Wrapper>
            )}
          </View>
        </DroppableWrapper>
        <PageWidthModal
          isVisible={openMediaLibrary}
          onBackdropPress={handleCloseMediaLibrary}
          pageWidthContainerStyle={{ borderTopRightRadius: 10, borderTopLeftRadius: 10 }}
        >
          <View
            style={[
              manipulator.container('row', 'space-between', 'center'),
              { paddingBottom: 20, borderBottom: `1px solid ${palette.border}` }
            ]}
          >
            <Text style={{ color: palette.primary, fontSize: 18, fontWeight: '500' }}>
              {t('COMMON.CHOOSE_IMAGE_LIBRARY_BUTTON')}-
              {t(`MEDIALIBRARY.CATEGORY_${category.toUpperCase()}`)}
            </Text>
            <MediaLibraryActionButton
              disabled={isDeleting}
              isEmptyMediaImages={_.isEmpty([...imageStore.images.values()])}
              onDeleteImage={handleToggleIsDeleting}
              onUploadImage={handleSelectMediaImage}
            />
          </View>
          {(imageLayoutType !== ImageLayoutType.Single || isDeleting) && (
            <View style={manipulator.container('row', 'flex-end', 'center')}>
              <ThemedCheckBox
                value={isSelectedAll}
                label={t('COMMON.SELECT_ALL')}
                onValueChange={handleToggleSelectedAll}
              />
            </View>
          )}
          <MediaLibrary
            type={MediaLibraryType.PopUp}
            isSelectedAll={isSelectedAll}
            isDeleting={isDeleting}
            selectedImage={_.map(images, (item: ImageItem) => ({
              storeMediaUsageUuid: item.storeMediaUsageUuid,
              storeMediaUuid: item.storeMediaUuid
            }))}
            storeCategory={category}
            imageLayoutType={imageLayoutType}
            droppableRef={mediaDroppableRef}
            toggleSelectedAll={handleToggleSelectedAll}
            toggleIsDeleting={handleToggleIsDeleting}
            onBackdropPress={handleCloseMediaLibrary}
            onSubmitSelected={handleUpdateSelectedItem}
          />
        </PageWidthModal>
        <DialogModal isVisible={!_.isEmpty(errMsg)} onBackdropPress={() => setErrMsg('')}>
          <View style={[manipulator.panel(20, 20, 20, 20), { alignItems: 'center' }]}>
            <ErrorIcon />
            <Text
              style={[
                template.bold,
                { color: palette.primary, fontSize: 24, paddingBottom: 10, paddingTop: 20 }
              ]}
            >
              {t('COMMON.TITLE_TIPS')}
            </Text>
            <Text>{errMsg}</Text>
          </View>
          <ThemedButton onPress={() => setErrMsg('')}>{t('FORM.BUTTON_OK')}</ThemedButton>
        </DialogModal>
        <Modal>
          <View />
        </Modal>
      </View>
    </View>
  )
})

ImageUploader.defaultProps = defaultProps
