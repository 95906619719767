import Markdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import remarkRezio from 'remark-rezio-channel-plugin'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { HTMLAttributes } from 'react'
import { ChannelType } from '../channel/constants'

const getChannelColorClass = (channel: ChannelType) => {
  switch (channel) {
    case ChannelType.KKDAY:
    case ChannelType.KKDAYMKP:
      return 'text-kkday'
    case ChannelType.ACTIVITYJAPAN:
      return 'text-aj'
    case ChannelType.VIATOR:
      return 'text-viator'
    case ChannelType.JALAN:
      return 'text-jalan'
    case ChannelType.GYG:
      return 'text-gyg'
    default:
      return 'text-black'
  }
}

export interface NoticeAreaBaseProps extends HTMLAttributes<HTMLDivElement> {
  content: string
}

// 純 web component
export const NoticeAreaBase = (props: NoticeAreaBaseProps) => {
  const { t } = useTranslation()

  return (
    <div className={clsx('rounded-1 bg-primary-100 p-5', props.className)}>
      <Markdown
        remarkPlugins={[remarkRezio, remarkBreaks]}
        className='list-inside leading-7 [&>ol]:ps-3 [&>ul]:ps-3 [&_*]:my-0'
        components={{
          li(props) {
            const { children } = props
            return <li className='[&>p:nth-child(1)]:inline'>{children}</li>
          },
          ul(props) {
            const { children } = props
            return <ul className='flex flex-col ps-5'>{children}</ul>
          },
          ol(props) {
            const { children } = props
            return <ol className='flex flex-col ps-5'>{children}</ol>
          },
          hr() {
            return <hr className='!my-5' />
          },
          span(props) {
            const { children, className, node } = props
            if (node.properties['data-type'] === 'rezio-channels') {
              const channels = node.children
                .map((content) => {
                  const channel = (((content as any)?.value as string) ?? '').toUpperCase()
                  return Object.values(ChannelType).find((key) => key === channel)
                })
                .filter((key) => key)
              const colorClasses = channels.reduce<ReturnType<typeof getChannelColorClass>[]>(
                (colors, channel) => {
                  const color = getChannelColorClass(channel)
                  if (!colors.includes(color)) {
                    colors.push(color)
                  }
                  return colors
                },
                []
              )
              return (
                <span
                  className={clsx(
                    'inline-flex items-center gap-2 font-bold',
                    colorClasses.length > 1 ? 'text-black' : colorClasses[0]
                  )}
                >
                  <span>[</span>
                  {channels.reduce((res, channel, index) => {
                    const colorClass = getChannelColorClass(channel)
                    res.push(
                      <span className={clsx(colorClass)} key={channel}>
                        {t('COMMON.SOURCE', { context: channel })}
                      </span>
                    )
                    if (channels[index + 1]) {
                      res.push(<span key={index}>/</span>)
                    }
                    return res
                  }, [])}
                  <span>]</span>
                  <span
                    className={clsx([
                      'hidden',
                      'text-aj',
                      'text-gyg',
                      'text-jalan',
                      'text-kkday',
                      'text-viator'
                    ])}
                  />
                </span>
              )
            } else {
              return <span className={className}>{children}</span>
            }
          }
        }}
        linkTarget='_blank'
      >
        {props.content}
      </Markdown>
    </div>
  )
}
