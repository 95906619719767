import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor
} from '@dnd-kit/core'
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { manipulator } from '@rezio/res/theme'
import React from 'react'
import { View } from 'react-native'

import type { ContainerProps, WrapperProps } from './types'

export const useAllDNDSensors = () =>
  useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

export const SortableWrapper = ({
  children,
  selectedImages,
  originSelectedImages,
  onChange
}: WrapperProps) => {
  const sensors = useAllDNDSensors()

  const items = selectedImages.map(String)
  function handleDragEnd({ active, over }) {
    if (items.length === 1) return items
    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id)
      const newIndex = items.indexOf(over.id)

      onChange(arrayMove(items.map(Number), oldIndex, newIndex), originSelectedImages)
    }
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items}>
        <View className='w-fit' style={{ zIndex: -1 }}>
          <View
            style={[
              manipulator.container('row', 'flex-start', 'flex-start'),
              { flexWrap: 'wrap', width: '100%' }
            ]}
          >
            {children}
          </View>
        </View>
      </SortableContext>
    </DndContext>
  )
}

export const SortableItem = ({ children, index, style: styleProps }: ContainerProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, activeIndex } = useSortable({
    id: `${index}`
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  }

  return (
    <View
      ref={setNodeRef as any}
      style={{ ...style, ...styleProps, zIndex: activeIndex === index ? 9 : 0 }}
      {...(attributes as any)}
    >
      {children(listeners)}
    </View>
  )
}
