import _ from 'lodash'
import { types, getParent, getChildType } from 'mobx-state-tree'
export const Page = types
  .model('Page', {
    list: types.optional(types.array(types.string), []),
    currentPage: types.optional(types.number, 1),
    totalCount: types.optional(types.number, 0),
    itemPerPage: types.optional(types.number, 20),
    source: 'data'
  })
  .views((self) => ({
    get data() {
      const source = getParent(self)[self.source]
      return _.compact(
        self.list.map((key) => {
          return source.get(key)
        })
      )
    }
  }))
  .actions((self) => {
    return {
      update: (page) => {
        Object.assign(self, page)
      }
    }
  })

export function extendPage(self, mapName, setterName /*, loadingSetterName, request */) {
  return {
    actions: {
      updatePage: (data, pagePathName = 'page') => {
        if (!self[pagePathName]) {
          return
        }

        const { currentPage, totalCount, itemPerPage } = data
        const list = _.map(data.list, (item) => {
          self[setterName](item)
          return item[getChildType(self[mapName]).identifierAttribute]
        })
        self[pagePathName].update({
          currentPage,
          totalCount,
          itemPerPage,
          totalPage: Math.ceil((totalCount || 1) / itemPerPage),
          list
        })
      }
    }
  }
}
