import { lazy } from 'react'
import type { PageConfig } from './types'

const PageSignUp = lazy(() => import('@rezio/pages/signUp'))
const PageAccountDetail = lazy(() => import('@rezio/pages/account/detail'))
const PageAccountInviteUser = lazy(() => import('@rezio/pages/account/inviteUser'))
const PageAccountList = lazy(() => import('@rezio/pages/account/list'))
const PageAccountPassword = lazy(() => import('@rezio/pages/account/password'))
const PageAccountProfile = lazy(() => import('@rezio/pages/account/profile'))
const PageMyFreeTrial = lazy(() => import('@rezio/pages/myplan/freeTrial'))
const PageMySite = lazy(() => import('@rezio/pages/mysite/index'))
const PageMySiteTheme = lazy(() => import('@rezio/pages/mysite/theme'))
const PageMySiteWidget = lazy(() => import('@rezio/pages/mysite/widget'))
const PageMySiteVoucherSetting = lazy(() => import('@rezio/pages/mysite/voucherSetting'))
const PageDevice = lazy(() => import('@rezio/pages/device/index'))
const PageDeviceDetail = lazy(() => import('@rezio/pages/device/detail'))
const PageDeviceSetting = lazy(() => import('@rezio/pages/device/setting'))
const PageDeviceSettleAccountLogPage = lazy(() => import('@rezio/pages/device/settleAccountLog'))
const PageDeviceOperationRecordPage = lazy(() => import('@rezio/pages/device/operationRecord'))
const PageFavoriteSetting = lazy(() => import('@rezio/pages/favorite'))
const PageMySubscribe = lazy(() => import('@rezio/pages/myplan/subscribe'))
const PageMyToken = lazy(() => import('@rezio/pages/myplan/token'))
const PageInvoiceTemplate = lazy(() => import('@rezio/pages/myplan/invoice'))
const PagePublishPricingSetting = lazy(() => import('@rezio/pages/publish/pricingSetting'))
const PagePublishPricingSettingEdit = lazy(() => import('@rezio/pages/publish/pricingSetting/edit'))
const PagePublishActionHistory = lazy(() => import('@rezio/pages/publish/actionHistory'))
const PageDiscountReport = lazy(() => import('@rezio/pages/discount/discountReport'))
const PageOrderTagSetting = lazy(() => import('@rezio/pages/order/orderTag'))
const PageOrderTagSettingEdit = lazy(() => import('@rezio/pages/order/orderTag/edit'))
const PageOrderSourceSetting = lazy(() => import('@rezio/pages/order/source'))
const PageOrderSourceSettingEdit = lazy(() => import('@rezio/pages/order/source/edit'))
const PageReportSales = lazy(() => import('@rezio/pages/report/salesReport'))
const PageReportTraveler = lazy(() => import('@rezio/pages/report/travelerReport'))
const PageReportRealName = lazy(() => import('@rezio/pages/report/realNameReport'))
const PageReportPassangerTransfer = lazy(() => import('@rezio/pages/report/passengerTransfer'))
const PageAccountStatement = lazy(() => import('@rezio/pages/report/accountStatement'))
const PageResetPassword = lazy(() => import('@rezio/pages/resetPassword'))
const PageFirstLogin = lazy(() => import('@rezio/pages/resetPassword'))
const PageSettingBookingInfo = lazy(() => import('@rezio/pages/setting/bookingInfo'))
const PageSettingBookingInfoEdit = lazy(() => import('@rezio/pages/setting/bookingInfo/edit'))
const PageSettingCustomedBookingInfo = lazy(
  () => import('@rezio/pages/setting/bookingInfo/customedBookingInfo')
)
const PageSettingCustomedBookingInfoEdit = lazy(
  () => import('@rezio/pages/setting/bookingInfo/editCustom')
)
const PageSettingCancel = lazy(() => import('@rezio/pages/setting/cancel'))
const PageSettingCancelEdit = lazy(() => import('@rezio/pages/setting/cancel/edit'))
const PageSettingDeposit = lazy(() => import('@rezio/pages/setting/deposit'))
const PageSettingDepositEdit = lazy(() => import('@rezio/pages/setting/deposit/edit'))
const PageSettingExtra = lazy(() => import('@rezio/pages/setting/extra'))
const PageSettingExtraEdit = lazy(() => import('@rezio/pages/setting/extra/edit'))
const PageSettingFeendtax = lazy(() => import('@rezio/pages/setting/feendtax'))
const PageSettingFeendtaxEdit = lazy(() => import('@rezio/pages/setting/feendtax/edit'))
const PageSettingPickup = lazy(() => import('@rezio/pages/setting/pickup'))
const PageSettingPickupEdit = lazy(() => import('@rezio/pages/setting/pickup/edit'))
const PageSettingPricing = lazy(() => import('@rezio/pages/setting/pricing'))
const PageSettingPricingEdit = lazy(() => import('@rezio/pages/setting/pricing/edit'))
const PageSettingResource = lazy(() => import('@rezio/pages/setting/resource'))
const PageSettingResourceEdit = lazy(() => import('@rezio/pages/setting/resource/edit'))
const PageSettingVendor = lazy(() => import('@rezio/pages/setting/vendor'))
const PageSettingVendorEdit = lazy(() => import('@rezio/pages/setting/vendor/edit'))
const PageSettingMediaLibrary = lazy(() => import('@rezio/pages/setting/mediaLibrary'))
const PageChannelIndex = lazy(() => import('@rezio/pages/channel/index'))
const PageChannelManagerInfo = lazy(() => import('@rezio/pages/channel/managerInfo'))
const PageChannelProductList = lazy(() => import('@rezio/pages/channel/productList'))
const PageChannelProductDetail = lazy(() => import('@rezio/pages/channel/productDetail'))
const PageChannelProductTtdDetail = lazy(() => import('@rezio/pages/channel/productTtdSetting'))
const PageReservePublishSetting = lazy(() => import('@rezio/pages/channel/reservePublish'))
const PageChannelStoreInfo = lazy(() => import('@rezio/pages/channel/storeInfo'))
const PageStoreBasic = lazy(() => import('@rezio/pages/store/basic'))
const PageRedeemPermissionForceRedeem = lazy(
  () => import('@rezio/pages/redeemPermission/forceRedeem')
)
const PageRedeemPermissionSetting = lazy(() => import('@rezio/pages/redeemPermission/setting'))
const PageRedeemPermissionSettingDetail = lazy(
  () => import('@rezio/pages/redeemPermission/settingDetail')
)
const PageStoreLegal = lazy(() => import('@rezio/pages/store/legal'))
const PageStoreOpDayOff = lazy(() => import('@rezio/pages/store/opDayOff'))
const PageStorePayment = lazy(() => import('@rezio/pages/store/payment'))
const PageStoreReceipt = lazy(() => import('@rezio/pages/store/receipt'))
const PageStoreRegional = lazy(() => import('@rezio/pages/store/regional'))
const PageStoreSessionDayOff = lazy(() => import('@rezio/pages/store/sessionDayOff'))
const PageStoreNotification = lazy(() => import('@rezio/pages/store/notification'))
const PageStoreOther = lazy(() => import('@rezio/pages/store/other'))
const PageTranslation = lazy(() => import('@rezio/pages/translation/index'))
const PageTranslationAdd = lazy(() => import('@rezio/pages/translation/add'))
const PageVoucherTemplate = lazy(() => import('@rezio/pages/voucherTemplate/index'))
const PagePrintOrder = lazy(() => import('@rezio/pages/order/print'))
const PagePrintOrderDetail = lazy(() => import('@rezio/pages/order/printDetail'))
const PageOrderCalendar = lazy(() => import('@rezio/pages/order/calendar'))
const PageDistributorProductList = lazy(() => import('@rezio/pages/distributor/productList'))
const PageDistributorProductDetail = lazy(() => import('@rezio/pages/distributor/productDetail'))
const PageDistributorIndex = lazy(() => import('@rezio/pages/distributor'))
const PageDistributorManagerInfo = lazy(() => import('@rezio/pages/distributor/managerInfo'))
const PageTicket = lazy(() => import('@rezio/pages/ticket'))
const PageTicketAdd = lazy(() => import('@rezio/pages/ticket/add'))
const PageTicketInventory = lazy(() => import('@rezio/pages/ticket/inventory'))
const PageTicketReport = lazy(() => import('@rezio/pages/ticket/report'))
const PageTicketDetail = lazy(() => import('@rezio/pages/ticket/detail'))

const siteMap: PageConfig[] = [
  { name: 'PageSignUp', path: '/signUp', screen: PageSignUp, withoutLayout: true },
  {
    name: 'PageAccountDetail',
    path: '/account/detail',
    screen: PageAccountDetail
  },
  {
    name: 'PageAccountInviteUser',
    path: '/account/inviteUser',
    screen: PageAccountInviteUser
  },
  { name: 'PageAccountList', path: '/account/list', screen: PageAccountList },
  {
    name: 'PageAccountProfile',
    path: '/account/profile',
    screen: PageAccountProfile
  },
  {
    name: 'PageAccountPassword',
    path: '/account/password',
    screen: PageAccountPassword
  },
  { name: 'PageMyToken', path: '/myplan/token', screen: PageMyToken },
  {
    name: 'PageInvoiceTemplate',
    path: '/myplan/invoice',
    screen: PageInvoiceTemplate,
    withoutLayout: true
  },
  {
    name: 'PageMySubscription',
    path: '/myplan/subscription',
    screen: PageMySubscribe
  },
  {
    name: 'PageMyFreeTrial',
    path: '/myplan/freeTrial',
    screen: PageMyFreeTrial
  },
  {
    name: 'PagePublishPricingSetting',
    path: '/publish/pricingSetting',
    screen: PagePublishPricingSetting
  },
  {
    name: 'PagePublishPricingSettingEdit',
    path: '/publish/pricingSetting/edit',
    screen: PagePublishPricingSettingEdit
  },
  {
    name: 'PagePublishActionHistory',
    path: '/publish/actionHistory',
    screen: PagePublishActionHistory
  },
  {
    name: 'PageDiscountReport',
    path: '/discount/discountReport',
    screen: PageDiscountReport
  },
  {
    name: 'PageOrderTagSetting',
    path: '/order/orderTag',
    screen: PageOrderTagSetting
  },
  {
    name: 'PageOrderTagSettingEdit',
    path: '/order/orderTag/edit',
    screen: PageOrderTagSettingEdit
  },
  {
    name: 'PageOrderSourceSetting',
    path: '/order/source',
    screen: PageOrderSourceSetting
  },
  {
    name: 'PageOrderSourceSettingEdit',
    path: '/order/source/edit',
    screen: PageOrderSourceSettingEdit
  },
  {
    name: 'PageResetPassword',
    path: '/resetPassword',
    screen: PageResetPassword,
    withoutLayout: true
  },
  {
    name: 'PageFirstLogin',
    path: '/firstLogin',
    screen: PageFirstLogin,
    withoutLayout: true
  },
  {
    name: 'PageSettingBookingInfo',
    path: '/setting/bookingInfo',
    screen: PageSettingBookingInfo
  },
  {
    name: 'PageSettingBookingInfoEdit',
    path: '/setting/bookingInfo/edit',
    screen: PageSettingBookingInfoEdit
  },
  {
    name: 'PageSettingCustomedBookingInfo',
    path: '/setting/bookingInfo/custom',
    screen: PageSettingCustomedBookingInfo
  },
  {
    name: 'PageSettingCustomedBookingInfoEdit',
    path: '/setting/bookingInfo/custom/edit',
    screen: PageSettingCustomedBookingInfoEdit
  },
  {
    name: 'PageSettingCancel',
    path: '/setting/cancel',
    screen: PageSettingCancel
  },
  {
    name: 'PageSettingCancelEdit',
    path: '/setting/cancel/edit',
    screen: PageSettingCancelEdit
  },
  {
    name: 'PageSettingDeposit',
    path: '/setting/deposit',
    screen: PageSettingDeposit
  },
  {
    name: 'PageSettingDepositEdit',
    path: '/setting/deposit/edit',
    screen: PageSettingDepositEdit
  },
  {
    name: 'PageSettingExtra',
    path: '/setting/extra',
    screen: PageSettingExtra
  },
  {
    name: 'PageSettingExtraEdit',
    path: '/setting/extra/edit',
    screen: PageSettingExtraEdit
  },
  {
    name: 'PageSettingFeendtax',
    path: '/setting/feendtax',
    screen: PageSettingFeendtax
  },
  {
    name: 'PageSettingFeendtaxEdit',
    path: '/setting/feendtax/edit',
    screen: PageSettingFeendtaxEdit
  },
  {
    name: 'PageSettingPickup',
    path: '/setting/pickup',
    screen: PageSettingPickup
  },
  {
    name: 'PageSettingPickupEdit',
    path: '/setting/pickup/edit',
    screen: PageSettingPickupEdit
  },
  {
    name: 'PageSettingPricing',
    path: '/setting/pricing',
    screen: PageSettingPricing
  },
  {
    name: 'PageSettingPricingEdit',
    path: '/setting/pricing/edit',
    screen: PageSettingPricingEdit
  },
  {
    name: 'PageSettingResource',
    path: '/setting/resource',
    screen: PageSettingResource
  },
  {
    name: 'PageSettingResourceEdit',
    path: '/setting/resource/edit',
    screen: PageSettingResourceEdit
  },
  {
    name: 'PageSettingVendor',
    path: '/setting/vendor',
    screen: PageSettingVendor
  },
  {
    name: 'PageSettingVendorEdit',
    path: '/setting/vendor/edit',
    screen: PageSettingVendorEdit
  },
  {
    name: 'PageStoreMediaLibrary',
    path: '/setting/mediaLibrary',
    screen: PageSettingMediaLibrary
  },
  { name: 'PageChannelIndex', path: '/channel', screen: PageChannelIndex },
  {
    name: 'PageChannelManagerInfo',
    path: '/channel/:manager',
    screen: PageChannelManagerInfo
  },
  {
    name: 'PageChannelProductList',
    path: '/channel/:manager/product',
    screen: PageChannelProductList
  },
  {
    name: 'PageChannelProductDetail',
    path: '/channel/:manager/product/:productId',
    screen: PageChannelProductDetail
  },
  {
    name: 'PageChannelProductTtdDetail',
    path: '/channel/:manager/product/:productId/ttd',
    screen: PageChannelProductTtdDetail
  },
  {
    name: 'PageReservePublishSetting',
    path: '/channel/:manager/product/:productId/reservePublish',
    screen: PageReservePublishSetting
  },
  {
    name: 'PageChannelStoreInfo',
    path: '/channel/:manager(kkdaymkp)/storeInfo',
    screen: PageChannelStoreInfo
  },
  { name: 'PageStoreBasic', path: '/store/basic', screen: PageStoreBasic },
  {
    name: 'PageRedeemPermissionForceRedeem',
    path: '/redeemPermission/forceRedeem',
    screen: PageRedeemPermissionForceRedeem
  },
  {
    name: 'PageRedeemPermissionSetting',
    path: '/redeemPermission/setting',
    screen: PageRedeemPermissionSetting
  },
  {
    name: 'PageRedeemPermissionSettingAdd',
    path: '/redeemPermission/setting/add',
    screen: PageRedeemPermissionSettingDetail
  },
  {
    name: 'PageRedeemPermissionSettingDetail',
    path: '/redeemPermission/setting/:redeemPermissionId',
    screen: PageRedeemPermissionSettingDetail
  },
  {
    name: 'PageStorePayment',
    path: '/store/payment',
    screen: PageStorePayment
  },
  {
    name: 'PageStoreReceipt',
    path: '/store/receipt',
    screen: PageStoreReceipt
  },
  {
    name: 'PageStoreRegional',
    path: '/store/regional',
    screen: PageStoreRegional
  },
  {
    name: 'PageStoreSessionDayOff',
    path: '/store/sessiondayoff',
    screen: PageStoreSessionDayOff
  },
  {
    name: 'PageStoreNotification',
    path: '/store/notification',
    screen: PageStoreNotification
  },
  {
    name: 'PageStoreOpDayOff',
    path: '/store/opdayoff',
    screen: PageStoreOpDayOff
  },
  { name: 'PageStoreLegal', path: '/store/legal', screen: PageStoreLegal },
  { name: 'PageTranslation', path: '/translation', screen: PageTranslation },
  {
    name: 'PageTranslationAdd',
    path: '/translation/add',
    screen: PageTranslationAdd
  },
  { name: 'PageMySite', path: '/mysite', screen: PageMySite },
  {
    name: 'PageVoucherTemplate',
    path: '/voucherTemplate',
    screen: PageVoucherTemplate
  },
  { name: 'PageMySiteTheme', path: '/mysite/theme', screen: PageMySiteTheme },
  {
    name: 'PageMySiteWidget',
    path: '/mysite/widget',
    screen: PageMySiteWidget
  },
  {
    name: 'PageMySiteVoucherSetting',
    path: '/mysite/voucherSetting',
    screen: PageMySiteVoucherSetting
  },
  { name: 'PageDevice', path: '/device', screen: PageDevice },
  {
    name: 'PageDeviceDetail',
    path: '/device/detail/:deviceUuid',
    screen: PageDeviceDetail
  },
  {
    name: 'PageDeviceSetting',
    path: '/device/setting',
    screen: PageDeviceSetting
  },
  {
    name: 'PageDeviceSettleAccountLogPage',
    path: '/device/settleAccountLog',
    screen: PageDeviceSettleAccountLogPage
  },
  {
    name: 'PageDeviceOperationRecordPage',
    path: '/device/operationRecord',
    screen: PageDeviceOperationRecordPage
  },
  {
    name: 'PageFavoriteSetting',
    path: '/favorite',
    screen: PageFavoriteSetting
  },
  {
    name: 'PageReportSales',
    path: '/report/salesReport',
    screen: PageReportSales
  },
  {
    name: 'PageReportTraveler',
    path: '/report/travelerReport',
    screen: PageReportTraveler
  },
  {
    name: 'PageReportRealName',
    path: '/report/realNameReport',
    screen: PageReportRealName
  },
  {
    name: 'PageReportPassangerTransfer',
    path: '/report/passengerTransfer',
    screen: PageReportPassangerTransfer
  },
  {
    name: 'PageReportPrintOrder',
    path: '/order/print',
    screen: PagePrintOrder,
    withoutLayout: true
  },
  {
    name: 'PageReportPrintOrderDetail',
    path: '/order/printDetail',
    screen: PagePrintOrderDetail,
    withoutLayout: true
  },
  {
    name: 'PageAccountStatement',
    path: '/report/accountStatement',
    screen: PageAccountStatement
  },
  {
    name: 'PageOrderCalendar',
    path: '/order/calendar',
    screen: PageOrderCalendar
  },
  {
    name: 'PageDistributorProductList',
    path: '/distributor/:manager/product',
    screen: PageDistributorProductList
  },
  {
    name: 'PageDistributorProductDetail',
    path: '/distributor/:manager/product/:productId',
    screen: PageDistributorProductDetail
  },
  {
    name: 'PageDistributorIndex',
    path: '/distributor',
    screen: PageDistributorIndex
  },
  {
    name: 'PageDistributorManagerInfo',
    path: '/distributor/:manager',
    screen: PageDistributorManagerInfo
  },
  { name: 'PageTicket', path: '/ticket', screen: PageTicket },
  { name: 'PageTicketAdd', path: '/ticket/add', screen: PageTicketAdd },
  {
    name: 'PageTicketInventory',
    path: '/ticket/inventory',
    screen: PageTicketInventory
  },
  {
    name: 'PageTicketReport',
    path: '/ticket/report',
    screen: PageTicketReport
  },
  {
    name: 'PageTicketDetail',
    path: '/ticket/detail',
    screen: PageTicketDetail
  },
  {
    name: 'PageStoreOther',
    path: '/store/other',
    screen: PageStoreOther
  }
]

export default siteMap
