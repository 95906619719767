import { instance } from '@rezio/core/i18n'
import { RawTicketType, ExchangeValidity } from '@rezio/utils/types'
import { compact } from 'lodash'
import { partition } from 'lodash/fp'
import moment from 'moment'
import { concat, lensProp, over, replace, toUpper, propEq, compose } from 'ramda'
import type { FormControl } from 'react-reactive-form'
import { ChannelKey } from '@rezio/components/product/overview/types'

interface TicketPeriodTsDetail {
  ticketStartTs: number
  ticketEndTs: number
  ticketTimezoneTsDiff: number
}
interface TicketValidationTextProps {
  exchangeValidity: ExchangeValidity
  ticketPeriodTsDetail?: TicketPeriodTsDetail
  targetTime?: string
  withTypeTitleWording?: boolean
}

// {PLAT(2)}{YYMMDD}{RAND(2)}{SERIAL(4)}
export const isRezioOrderNo = (orderNo: string) => /^\w{2}\d{6}\w{2}\d{4}$/.test(orderNo)

export const toggleState = (key: string) => (state) => ({ [key]: !state[key] })

export const syncPrice = (control: FormControl, priceType: 'originalPrice' | 'cost') => {
  const syncFn = (value) => control.get(priceType).setValue(value)
  control.get('price').valueChanges.subscribe(syncFn)
  // control.get(`${priceType}FollowPrice`).valueChanges.subscribe(isFollow => {
  //   if (isFollow) {
  //     control.get(priceType).setValue(control.get('price').value)
  //     control.get('price').valueChanges.subscribe(syncFn)
  //   } else {
  //     control.get('price').valueChanges.unsubscribe(syncFn)
  //   }
  // })
}

export const ticketValidationText = ({
  exchangeValidity,
  ticketPeriodTsDetail,
  targetTime = '',
  withTypeTitleWording = false
}: TicketValidationTextProps) => {
  if (!exchangeValidity?.type) {
    return ''
  }

  let headerTextType = exchangeValidity.type
  let textType = exchangeValidity.type
  let context = ''
  let dateObj = {}
  switch (exchangeValidity?.type) {
    case RawTicketType.Relative:
      dateObj = { date: moment(targetTime).add(exchangeValidity.day, 'days') }
      context = targetTime ? 'DATETIME' : ''
      break
    case RawTicketType.Limit:
      context = targetTime ? 'DATETIME' : ''
      break
    case RawTicketType.RedeemHour:
    case RawTicketType.Redeem:
    case RawTicketType.RedeemDay:
      headerTextType = RawTicketType.Redeem
      context = targetTime ? 'DATETIME' : ''
      break
    case RawTicketType.RelativeRange:
      textType = ticketPeriodTsDetail ? RawTicketType.Range : RawTicketType.RelativeRange // 在 order 頁面(會傳 ticketPeriodTsDetail) 會需要顯示 RawTicketType.Range 格式，
      dateObj = ticketPeriodTsDetail
        ? {
            // NOTE: 依照票券設定的時區為主
            startDate: moment(
              (+ticketPeriodTsDetail.ticketStartTs + +ticketPeriodTsDetail.ticketTimezoneTsDiff) *
                1000
            )
              .utc()
              .format('YYYY-MM-DD'),
            date: moment(
              (+ticketPeriodTsDetail.ticketEndTs + +ticketPeriodTsDetail.ticketTimezoneTsDiff) *
                1000
            )
              .utc()
              .format('YYYY-MM-DD')
          }
        : {}
      break
  }
  return `${
    withTypeTitleWording ? `${instance?.t(`SALES.TICKET_TYPE_${headerTextType}`)}:` : ''
  }${instance?.t(`PUBLISH.SESSION_TICKET_TEXT_${textType}`, {
    context,
    ...exchangeValidity,
    ...dateObj
  })}`
}

type BasicActionLogType =
  | 'languageUuid'
  | 'timezoneUuid'
  | 'redeemTimesLimit'
  | 'quotaTotal'
  | 'useRedeemPermission'
  | 'title'
  | 'code'

interface BasicActionLog {
  new: string | number
  org: string | number
  column: BasicActionLogType
}

interface ListActionLog {
  new: string[]
  org: string[]
  column: 'redeemPermissionUuidList'
}

interface ObjectActionLog {
  new: {
    type: string
    value: number
  }
  org: {
    type: string
    value: number
  }
  column: 'safetyStock'
}

interface ExchangeValidityActionLog {
  new: ExchangeValidity
  org: ExchangeValidity
  column: 'exchangeValidity'
}

type ActionLog = BasicActionLog | ListActionLog | ObjectActionLog | ExchangeValidityActionLog

const diffObjectKeys = (source: object, target: object) =>
  Object.keys({ ...source, ...target }).filter((key) => source[key] !== target[key])

const logTransform = (key, newObject, oldObject) =>
  ['type', 'canRedeem'].includes(key)
    ? {
        new: newObject[key],
        org: oldObject[key],
        column: key
      }
    : {
        new: newObject,
        org: oldObject,
        column: key
      }

const diffObject = (source: object, target: object, transformer = logTransform) =>
  diffObjectKeys(source, target)
    // NOTE: 將 RawTicketType.Redeem, RawTicketType.RedeemDay, RawTicketType.RedeemHour 的轉換過濾掉，三者皆屬於 TicketType.Redeem
    .filter(
      (key) =>
        !(
          key === 'type' &&
          [source[key], target[key]].every((type) => type.split('_')[0] === RawTicketType.Redeem)
        )
    )
    .map((key) => transformer(key, source, target))

const uniqueObject = (targetArrayObject, uniqueKey) => {
  const uniqueTarget = new Map()

  return targetArrayObject.filter((eachObj) => {
    const targetKey = uniqueTarget.get(eachObj[uniqueKey])
    if (targetKey) return false
    uniqueTarget.set(eachObj[uniqueKey], eachObj)
    return true
  })
}

// * purpose: bypass every type of action log except `exchangeValidity` type
const translateActionPayload = (actionPayload: ActionLog[]) => {
  const [exLog, normalLog] = partition(
    // NOTE: payload.column = exchangeValidity 需要前端自行轉換 generate 需求的 logs，即該單一 log 擁有多異動項目，其餘為單一 log 僅有單一異動項目
    propEq('column', 'exchangeValidity'),
    actionPayload
  ) as [ExchangeValidityActionLog[], ActionLog[]]

  return [
    ...normalLog,
    ...exLog.flatMap((log) => {
      return uniqueObject(
        diffObject(log.new, log.org).map(
          over(
            lensProp('column'),
            compose(
              concat('exchangeValidity'),
              // NOTE: 在 exchangeValidity 內無論是哪一種類型，固定都會有的 key，其餘會因為種類差異變動的 key 會被規例在 date
              replace(/^./, toUpper),
              replace(/hour|day|startDay|startDate/, 'date'),
              // NOTE: 將 是否可於場次開始前核銷 - 不指定, 指定類型轉換成同一個 key
              replace(/earlyRedeemMin|earlyRedeemHour/, 'earlyRedeemType')
            )
          )
        ),
        'column'
      )
    })
  ]
}

export const formattedTicketActionLog = (actionLog) => {
  return actionLog
    .sort((a, b) => b.actionTs - a.actionTs)
    .reduce((collection, eachLog) => {
      return [
        ...collection,
        ...(eachLog.actionType !== 'CREATE'
          ? compact(
              translateActionPayload(eachLog.actionPayload).map((each) => {
                return ['useRedeemPermission', 'redeemPermissionUuidList'].includes(each.column)
                  ? each.column === 'useRedeemPermission'
                    ? {
                        ...eachLog,
                        actionPayload: [
                          each,
                          eachLog.actionPayload.find((log) =>
                            ['redeemPermissionUuidList'].includes(log.column)
                          )
                        ]
                      }
                    : null
                  : {
                      ...eachLog,
                      actionPayload: [each]
                    }
              })
            )
          : [{ ...eachLog }])
      ]
    }, [])
}

export function formatVersion(version: string): number[] {
  const numericVersion = /(\d+(\.\d+)*)/.exec(version)?.[0]

  // without any decimal
  if (!numericVersion) {
    return []
  }
  return numericVersion.split('.').map(Number)
}

/**
 * isAvailableVersion: Compare two version numbers.
 * @param {string} currentVersion - The current version number.
 * @param {string} latestVersion - The latest version number.
 * @returns {boolean} Returns false if currentVersion is less than latestVersion, true if they are equal, or true if currentVersion is greater than latestVersion.
 */
export const isAvailableVersion = ({
  currentVersion,
  latestVersion
}: {
  currentVersion: string
  latestVersion: string
}) => {
  if (typeof latestVersion !== 'string') {
    return true
  }

  const partsCurrent = formatVersion(currentVersion)
  const partsLatest = formatVersion(latestVersion)

  for (let i = 0; i <= Math.max(partsCurrent.length, partsLatest.length) - 1; i++) {
    const partCurrent = Number.isInteger(partsCurrent[i]) ? partsCurrent[i] : 0
    const partLatest = Number.isInteger(partsLatest[i]) ? partsLatest[i] : 0

    if (partCurrent < partLatest) {
      return false // currentVersion is less than latestVersion
    } else if (partCurrent > partLatest) {
      return true // currentVersion is greater than latestVersion
    }
  }

  return true // currentVersion is equal to latestVersion
}

export const makeRedirectChannelProductPathname = ({
  uuid,
  key
}: {
  uuid: string
  key: ChannelKey
}) => {
  let subpath: string = uuid
  const isMysite = key === ChannelKey.MySite
  const isTix = [ChannelKey.KIOSK, ChannelKey.POS].includes(key)
  if (isMysite) {
    subpath = `${uuid}/reservePublish`
  }
  if (isTix) {
    subpath = ''
  }
  return `/channel/${key.toLowerCase()}/product/${subpath}`
}

export const bitwiseFlagsToArray: (number: number) => number[] = (number) => {
  const bitwiseArray: number[] = []
  let position = 0
  while (number > 0) {
    if (number & 1) {
      bitwiseArray.push(1 << position)
    }
    number >>= 1
    position++
  }
  return bitwiseArray
}
