import AsyncStorage from '@react-native-async-storage/async-storage'
import { useCallback } from 'react'
import { proxy, snapshot, subscribe, useSnapshot } from 'valtio'

export const currentFlags = {
  beta_flags: {
    description: '啟用Beta功能(關掉便會關閉所有Beta功能)'
  },
  remove_store_pricing_policy: {
    description: '取消全店共用價格設定'
  },
  simple_pricing_policy_binding: {
    description: '簡化價格設定綁定'
  },
  web_scanner: {
    description: '網頁啟用相機掃描'
  }
} as const

export type BetaFlag = keyof typeof currentFlags

type FlagsState = {
  [K in BetaFlag]?: boolean
}

export const FLAG_STORAGE_KEY = 'flags'

const flagState = proxy(
  Object.keys(currentFlags).reduce<FlagsState>(
    (res, flag) => ({
      ...res,
      [flag]: false
    }),
    {}
  )
)

export async function loadFlagsFromAsyncStorage() {
  const flags = await AsyncStorage.getItem(FLAG_STORAGE_KEY).then((value) =>
    JSON.parse(value || '{}')
  )
  Object.keys(flagState).forEach((flag) => {
    if (flag in flags) {
      flagState[flag] = flags[flag] === true
    }
  })
}

subscribe(flagState, async () => {
  await AsyncStorage.setItem(FLAG_STORAGE_KEY, JSON.stringify(snapshot(flagState)))
})

const check = (state: FlagsState, flag: BetaFlag) => {
  return state?.['beta_flags'] && state?.[flag] === true
}

export const checkFlag = (flag: BetaFlag) => check(snapshot(flagState), flag)

export const setFlag = (flag: BetaFlag, enabled: boolean) => {
  flagState[flag] = enabled
}

export const useFlags = () => {
  const flags = useSnapshot(flagState)

  const checkFlag = useCallback(
    (flag: BetaFlag) => {
      return check(flags, flag)
    },
    [flags]
  )

  return { checkFlag, setFlag, flags }
}

export const useFlag = (flag: BetaFlag) => {
  const flags = useSnapshot(flagState)
  return check(flags, flag)
}
