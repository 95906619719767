import { ApolloClient, HttpLink, ApolloLink, split, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from 'apollo-utilities'
import gql from 'graphql-tag'
import { autorun } from 'mobx'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { getServiceHost, getEnv } from './config'
import Core from './core'

export const AUTH_MUTATION = gql`
  mutation Auth($id: String!, $token: String!, $pushToken: String, $lang: String) {
    auth(id: $id, token: $token, pushToken: $pushToken, lang: $lang) {
      accessToken
      id
    }
  }
`

const clients: Record<string, ApolloClient<any>> = {}

export async function auth(id = 'anonymous', token = 'anonymous', pushToken?: string) {
  if (id === 'anonymous' && !pushToken) {
    return undefined
  }

  const apollo = getClient()
  const result = await apollo.mutate({
    mutation: AUTH_MUTATION,
    variables: {
      id,
      token,
      pushToken,
      lang: Core.getInstance().lang
    }
  })
  return result?.data?.auth?.accessToken
}

export function getClient() {
  let lastToken
  let tokenObserver

  const env = getEnv()
  if (clients[env]) {
    return clients[env]
  }
  const config = {
    get header() {
      return global.store?.core?.session?.notifyToken
        ? { authorization: `Bearer ${global.store.core.session.notifyToken}` }
        : {}
    }
  }
  const sonicEndpoint = getServiceHost('sonic') + '/v1/graphql'

  const wsClient = new SubscriptionClient(
    `${sonicEndpoint.startsWith('localhost') ? 'ws' : 'wss'}://${sonicEndpoint}`,
    {
      // lazy: true,
      reconnect: true,
      connectionParams: () => {
        if (!tokenObserver) {
          tokenObserver = autorun(() => {
            if (lastToken !== global?.store?.core?.session?.notifyToken) {
              console.log('reset ws')
              lastToken = global?.store?.core?.session?.notifyToken
              clients[env].resetStore().catch(console.error)
              wsClient.close()
            }
          })
        }
        return {
          headers: {
            ...config.header
          }
        }
      }
    }
  )
  const httpLink = new HttpLink({
    uri: `https://${sonicEndpoint}`
  })
  const wsLink = new WebSocketLink(wsClient)
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...config.header
      }
    }))

    return forward(operation)
  })

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink
  )
  const retryLink = new RetryLink({
    delay: {
      initial: 500
    },
    attempts: {
      max: 10
    }
  })

  clients[env] = new ApolloClient({
    link: ApolloLink.from([
      authMiddleware,
      retryLink,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${JSON.stringify(path)}`
            )
          )
        }
        if (networkError) {
          console.log('[Network error]:', networkError)
          // @ts-expect-error
          if (networkError?.extensions?.code === 'validation-failed') {
            console.log('validation-failed close ws')
            wsClient.close(false, false)
            clients[env].resetStore().catch(console.error)
            if (typeof tokenObserver === 'function') {
              tokenObserver()
            }
          }
        }
      }),
      link
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        user: {
          fields: {
            notifications: {
              keyArgs: (args) => {
                // apollo client cache storeFieldName
                if (args?.where) {
                  // scmNotifications
                  return ['where', ['notification', ['type']]]
                }
                return false
              },
              merge(existing = [], incoming: any[] = [], { args: { offset = 0 } }) {
                const merged = existing ? existing.slice(0) : []
                for (let i = 0; i < incoming.length; ++i) {
                  merged[offset + i] = incoming[i]
                }
                return merged
              }
            }
          }
        }
      }
    })
  })

  return clients[env]
}
