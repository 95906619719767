import AsyncStorage from '@react-native-async-storage/async-storage'
import { Page } from '@rezio/components/layout'
import { getConfig } from '@rezio/core/config'
import { ErrorIcon } from '@rezio/core/errorHandler'
import { useStores, useRouter } from '@rezio/core/hooks'
import { palette, template, manipulator } from '@rezio/res/theme'
import WebView from '@rezio/unimodules/webview'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import qs from 'query-string'
import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react'
import { View, Text, Platform, ActivityIndicator } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue } from 'react-native-reanimated'
import { useTranslation } from 'react-i18next'
import { useCore } from '@rezio/core/core'
import type { i18n } from 'i18next'
import { openURL } from '@rezio/core/linking'
import RezioRPC from './rpc'

const acceptableArgs = ['year_month', 'stmtOid']

function matchLang(lang) {
  const supportLangs = ['zh-cn', 'zh-hk', 'zh-tw', 'ja', 'ko', 'en', 'th', 'vi']

  lang = lang.toLowerCase()
  if (supportLangs.includes(lang)) {
    return lang
  } else if (supportLangs.includes(lang.slice(0, 2))) {
    return lang.slice(0, 2)
  } else {
    return 'en'
  }
}

interface KKSCMViewProps {
  path?: string
  isMkp?: boolean
  height?: number
  asComponent?: boolean
}

const checkSCMInMaintenance = (i18n: i18n) => {
  const now = Date.now()
  return (
    now >
      new Date(
        i18n.exists('SCM.MAINTENANCE_START_DATETIME')
          ? i18n.t('SCM.MAINTENANCE_START_DATETIME')
          : '2020-07-17 00:00:00+0800'
      ).getTime() &&
    now <
      new Date(
        i18n.exists('SCM.MAINTENANCE_END_DATETIME')
          ? i18n.t('SCM.MAINTENANCE_END_DATETIME')
          : '2020-07-17 09:00:00+0800'
      ).getTime()
  )
}

export const KKSCMView = (props: KKSCMViewProps) => {
  const isMkp = props.isMkp
  const scmProxyHost = getConfig().SCM_PROXY
  const webviewRef = useRef<WebView>(null)
  const router = useRouter()
  const { store } = useStores()
  const core = useCore()
  const { t, i18n } = useTranslation()
  const Wrapper = props.asComponent ? View : Page

  const fullPath = useMemo(() => {
    const pathname =
      props.path?.replace?.('zh-tw', matchLang(core.lang)) ||
      router.pathname.replace(/\/scm(\w)*/, `/v1/${matchLang(core.lang) || 'zh-tw'}`)

    const acceptedArgs = Object.keys(router.query).filter((key) => acceptableArgs.includes(key))
    if (acceptedArgs.length === 0) {
      return pathname
    }
    return `${pathname}?${qs.stringify(
      acceptedArgs
        .filter((key) => acceptableArgs.includes(key))
        .reduce((res, key) => {
          return {
            ...res,
            [key]: router.query[key]
          }
        }, {})
    )}`
  }, [core.lang, props.path, router.pathname, router.query])

  const [initPath, setInitPath] = useState(fullPath)
  const [isLogin, setIsLogin] = useState(true)
  const [oid, setOid] = useState<number>(null)
  const [error, setError] = useState<string>(null)
  const [token, setToken] = useState<string>(null)
  const [reloadCount, setReloadCount] = useState(0)

  useEffect(() => {
    ;(async function precheck() {
      const [channelInfo, storageToken] = await Promise.all([
        core.api.get(
          `channel/${
            isMkp ? 'ff51b387-705d-470e-bcf5-06b78cea76c4' : 'c7dab402-54ed-44f7-ac55-491e0f733492'
          }`
        )[0],
        AsyncStorage.getItem('scm_token')
      ])
      setToken(storageToken)
      setOid(parseInt(channelInfo?.data?.meta?.oid || 0, 10))
    })().catch(console.error)
  }, [core.api, isMkp])

  const pageHeight = useSharedValue(props.height ?? store.viewStore.pageHeight)
  const animatedStyle = useAnimatedStyle(() => {
    return {
      height: props.height || pageHeight.value,
      flex: 1,
      flexBasis: 'auto'
    }
  })

  useEffect(() => {
    if (Platform.OS !== 'android') {
      return autorun(() => {
        pageHeight.value = store.viewStore.pageHeight
      })
    }
  }, [store, pageHeight, props.height])

  const rpc = useMemo(() => {
    return new RezioRPC('rezio', (message) => webviewRef.current?.postMessage?.(message, '*'))
  }, [])

  useEffect(() => {
    if (rpc.liveness) {
      rpc.call('route', fullPath)
    } else {
      setInitPath(fullPath)
    }
  }, [rpc, fullPath])

  const logout = useCallback(() => {
    setIsLogin(false)
    setError('SCM.LOGIN_SESSION_EXPIRED')
  }, [])

  useEffect(() => {
    rpc.expose('expired', async () => {
      if (reloadCount < 2) {
        setReloadCount((c) => c + 1)
      } else {
        logout()
      }
    })

    rpc.expose('logout', logout)
  }, [rpc, router, reloadCount, logout])

  useEffect(() => {
    rpc.expose('layout', (layout: { height: number; width: number }) => {
      if (Platform.OS === 'android' && layout.height > store.viewStore.pageHeight) {
        pageHeight.value = layout.height
      }
      return true
    })
  }, [rpc, pageHeight, store.viewStore.pageHeight])

  useEffect(() => {
    rpc.expose('route', openURL)
  }, [rpc, router])

  const handleMessage = useCallback(
    ({ nativeEvent: event }) => {
      try {
        if (event.origin !== scmProxyHost) {
          return false
        }
        rpc.handle(event.data)
      } catch (e) {
        console.error(e)
      }
    },
    [rpc, scmProxyHost]
  )

  const source = useMemo(
    () => ({
      uri: `${scmProxyHost}/rezio_auth?path=${encodeURIComponent(
        initPath
      )}&ts=${Date.now()}${reloadCount}&oid=${oid}${
        token ? `&token=${encodeURIComponent(token)}` : ''
      }`
    }),
    [scmProxyHost, token, oid, initPath, reloadCount]
  )

  // SCM 維護中
  if (checkSCMInMaintenance(i18n)) {
    return (
      <PageHeightContainer>
        <View style={[manipulator.panel(20, 20, 20, 20), { alignItems: 'center' }]}>
          <ErrorIcon />
          <Text
            style={[
              template.bold,
              { color: palette.primary, fontSize: 24, paddingBottom: 10, paddingTop: 20 }
            ]}
          >
            {t('SCM.MAINTENANCE_TITLE')}
          </Text>
          <Text style={{ color: palette.primary, textAlign: 'center' }}>
            {t('SCM.MAINTENANCE_MESSAGE')}
          </Text>
        </View>
      </PageHeightContainer>
    )
  }

  // 未設定通路店家
  if (!oid) {
    return (
      <Wrapper notitle style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ maxWidth: 300, justifyContent: 'center' }}>
          {oid === 0 ? <Text>{t('SCM.KKDAY_CHANNEL_NO_SETTING')}</Text> : <ActivityIndicator />}
        </View>
      </Wrapper>
    )
  }

  // 登入失敗
  if (!isLogin) {
    return (
      <Wrapper notitle style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ maxWidth: 300, justifyContent: 'center' }}>
          {error && <Text style={{ color: palette.warning, paddingVertical: 10 }}>{t(error)}</Text>}
        </View>
      </Wrapper>
    )
  }

  return (
    <Animated.View style={animatedStyle}>
      <WebView
        ref={webviewRef}
        sharedCookiesEnabled
        source={source}
        className='flex-1'
        scrollEnabled={Platform.OS === 'ios'}
        onMessage={handleMessage}
      />
    </Animated.View>
  )
}

const PageHeightContainer = observer(function PageHeightContainer(props) {
  const { store } = useStores()
  return <View style={{ flex: 1, height: store.viewStore.pageHeight }}>{props.children}</View>
})
