export const DeviceCustomPayWay = { uuid: '1', label: 'CASH' }

export const DeviceCustomPayWayKey = 'DEVICE.CUSTOM'

export enum DeviceSettingTab {
  Pos = 'pos',
  Kiosk = 'kiosk',
  PrintTemplate = 'printTemplate',
  InvoiceSetting = 'invoiceSetting'
}

export enum DeviceScreenSaverImageType {
  Image = 'image'
  // Youtube = 'youtube', // TODO: phace2 討論好規格會補上
  // Video = 'video'
}

export enum DeviceHeaderTitleType {
  Title = 1,
  Logo
}
