import _ from 'lodash'
import { Platform, StyleSheet } from 'react-native'

const primaryColors = [
  '#e6f6ff',
  '#9bcff2',
  '#6eb2e6',
  '#4594d9',
  '#2176cc',
  '#005abe',
  '#004299',
  '#002e73',
  '#001c4d',
  '#000d26'
]

// do not use colors here direcitly !!
const baseColors = {
  black: '#000000',
  white: '#FFFFFF',
  basic0: primaryColors[8],
  basic1: primaryColors[6],
  basic2: primaryColors[5],
  basic3: primaryColors[4],
  basic4: primaryColors[3],
  basic5: primaryColors[2],
  basic6: primaryColors[1],
  basicGradiant: '#64B3F4',
  gray0: '#7B7B7B',
  gray1: '#979797',
  gray2: '#DCDCDC',
  gray3: '#F4F4F4',
  gray4: '#FBFBFB',
  gray5: '#E8E8E8',
  gray6: '#B2B2B2',
  gray7: '#EEEEEE',
  hoverLightBlue: '#A0D4FA',
  veryLightBlue: '#E6F5FF',
  statusRed: '#C80A28',
  statusLightRed: '#FFEBF0',
  statusOrange: '#E95200',
  statusLightOrange: '#F5E6DC',
  statusBlue: '#4897E0',
  statusLightBlue: '#D7EBFF',
  statusYellow: '#FFA800',
  statusLightYellow: '#FAF0E1',
  statusGreen: '#32BE64',
  statusLightGreen: '#E6FAF0',
  statusLowInventory: '#f6a303'
}

export const breakpoints = {
  xs: 480,
  sm: 640,
  md: 832,
  lg: 1024
}

export const mq = (pageWidth, target = 'lg') => {
  return pageWidth <= _.get(breakpoints, target)
}

export const mqStyle = ({ xs = undefined, sm = undefined, md = undefined, lg = undefined }) => {
  // TODO
  // const pageWidth = usePageWidth()

  // if (+pageWidth <= +breakpoints.xs) {
  //   style = xs
  // } else if (+pageWidth <= +breakpoints.sm) {
  //   style = sm
  // } else if (+pageWidth <= +breakpoints.md) {
  //   style = md
  // } else {
  //   style = lg
  // }

  // console.log('enter style', sm)
  return {}
}

export const palette = {
  ashGray: '#c1c0c1',
  darkGray: '#999999',
  darkGrayBg: '#4f4f4f',
  lightNotice: '#FFA800CC',
  // usage colors
  brand: baseColors.basic2,
  darkBrand: baseColors.basic0,
  lightBrand: baseColors.basic4,
  veryLightBrand: baseColors.basic6,
  active: baseColors.basic1,
  border: baseColors.gray2,
  primary: baseColors.basic2,
  positive: baseColors.statusGreen,
  negative: baseColors.gray2,
  neutral: baseColors.statusBlue,
  notice: baseColors.statusYellow,
  warning: baseColors.statusRed,
  error: baseColors.statusRed,
  positiveBg: baseColors.statusLightGreen,
  neutralBg: baseColors.statusLightBlue,
  noticeBg: baseColors.statusLightYellow,
  warningBg: baseColors.statusLightRed,
  disable: baseColors.gray3,
  disableLight: baseColors.gray6,
  icon: baseColors.gray0,
  text: baseColors.black,
  dot: baseColors.gray7,
  grayText: baseColors.gray1,
  calendarBorder: baseColors.gray5,
  lightBluePanelBackground: baseColors.veryLightBlue,
  lightePanelBackground: baseColors.gray4,
  panelBackground: baseColors.gray3,
  menuBackground: baseColors.gray3,
  darkerBackground: baseColors.gray2,
  weekend: baseColors.statusRed,
  paid: baseColors.statusRed,
  refund: baseColors.statusBlue,
  lowInventory: baseColors.statusLowInventory,
  // product status
  productStatusInProgress: baseColors.statusOrange,
  productStatusInProgressBg: baseColors.statusLightOrange,
  productStatusAvailable: baseColors.statusGreen,
  productStatusAvailableBg: baseColors.statusLightGreen,
  productStatusDiscontinued: baseColors.statusRed,
  productStatusDiscontinuedBg: baseColors.statusLightRed,
  productStatusFullyBooked: baseColors.statusYellow,
  productStatusFullyBookedBg: baseColors.statusLightYellow,
  productStatusEndSelling: baseColors.black,
  productStatusEndSellingBg: baseColors.gray2,
  // order status
  orderStatusNew: baseColors.statusYellow,
  orderStatusNewBg: baseColors.statusLightYellow,
  orderStatusOnHold: baseColors.statusYellow,
  orderStatusOnHoldBg: baseColors.statusLightYellow,
  orderStatusPending: baseColors.statusYellow,
  orderStatusPendingBg: baseColors.statusLightYellow,
  orderStatusConfirmed: baseColors.statusGreen,
  orderStatusConfirmedBg: baseColors.statusLightGreen,
  orderStatusCheckIn: baseColors.basic2,
  orderStatusCheckInBg: baseColors.statusLightBlue,
  orderStatusNoShow: baseColors.black,
  orderStatusNoShowBg: baseColors.gray3,
  orderStatusCancelProcessing: baseColors.statusRed,
  orderStatusCancelProcessingBg: baseColors.statusLightRed,
  orderStatusToBeCancel: baseColors.black,
  orderStatusToBeCancelBg: baseColors.gray2,
  orderStatusInvalid: baseColors.gray0,
  orderStatusInvalidBg: baseColors.gray2,
  // payout status
  payoutStatusNone: baseColors.basic2,
  payoutStatusPending: baseColors.statusRed,
  payoutStatusAvailable: baseColors.statusOrange,
  payoutStatusWithdrawed: baseColors.statusGreen,
  // channel status
  channelStatusInit: baseColors.statusOrange,
  channelStatusInitBg: baseColors.statusLightOrange,
  channelStatusPending: baseColors.statusYellow,
  channelStatusPendingBg: baseColors.statusLightYellow,
  channelStatusSyncing: baseColors.basic2,
  channelStatusSyncingBg: baseColors.statusLightBlue,
  channelStatusPosted: baseColors.statusGreen,
  channelStatusPostedBg: baseColors.statusLightGreen,
  channelStatusPulleded: baseColors.black,
  channelStatusPullededBg: baseColors.gray3,
  channelStatusInvalid: baseColors.statusRed,
  channelStatusInvalidBg: baseColors.statusLightRed,
  // couponCode status
  couponCodeStatusNotStart: baseColors.statusOrange,
  couponCodeStatusNotStartBg: baseColors.statusLightOrange,
  couponCodeStatusDiscount: baseColors.statusGreen,
  couponCodeStatusDiscountBg: baseColors.statusLightGreen,
  couponCodeStatusExpired: baseColors.black,
  couponCodeStatusExpiredBg: baseColors.gray2,
  // pure colors
  orange: baseColors.statusOrange,
  white: baseColors.white,
  black: baseColors.black,
  gray: baseColors.gray0,
  hoverLightBlue: baseColors.hoverLightBlue,
  lightGray: baseColors.gray2,
  lightBlue: baseColors.statusBlue,
  statusLightYellow: baseColors.statusLightYellow,
  statusYellow: baseColors.statusYellow
}

export const variable = {
  defaultWidth: 210,
  defaultHeight: 40
}

const defaultVariable = {
  // font-size
  fontsizeBig: 20,
  fontsizeNormal: 16,
  fontSizeBase: 14,
  fontsizeSmall: 13,
  fontsizeTiny: 10,

  // font-weight
  fontweightLight: '300' as '300',
  fontweightNormal: '400' as '400',
  fontweightBold: '700' as '700',

  // screen-size
  screenXSmin: '480px',
  screenSMmin: '768px',
  screenMDmin: '992px',
  screenLGmin: '1200px',

  white: palette.white,
  black: palette.black,
  gray: palette.gray
}

const generatedStyles = new Map()

function getStyleSheetRegistry(style) {
  const key = JSON.stringify(style)
  if (generatedStyles.has(key)) {
    return generatedStyles.get(key)
  } else {
    const styles = StyleSheet.create({
      ref: style
    })

    generatedStyles.set(key, styles.ref)
    return styles.ref
  }
}

export const manipulator = {
  container: (flexDirection = 'column', justifyContent = 'flex-start', alignItems?) => {
    return getStyleSheetRegistry({
      flexDirection,
      justifyContent,
      alignItems
    })
  },
  border: (
    position: 'all' | 'top' | 'bottom' | 'left' | 'right' = 'all',
    level?: 'light' | string,
    radius = 0,
    width = 1
  ) => {
    return getStyleSheetRegistry({
      borderColor: level === 'light' ? palette.lightGray : level || palette.gray,
      borderRadius: radius,
      borderTopWidth:
        ['all', 'top'].includes(position) || !_.isEmpty(_.intersection(position, ['all', 'top']))
          ? width
          : 0,
      borderBottomWidth:
        ['all', 'bottom'].includes(position) ||
        !_.isEmpty(_.intersection(position, ['all', 'bottom']))
          ? width
          : 0,
      borderLeftWidth:
        ['all', 'left'].includes(position) || !_.isEmpty(_.intersection(position, ['all', 'left']))
          ? width
          : 0,
      borderRightWidth:
        ['all', 'right'].includes(position) ||
        !_.isEmpty(_.intersection(position, ['all', 'right']))
          ? width
          : 0
    })
  },
  margin: (top = 10, right = 10, bottom = 10, left = 10) => {
    return getStyleSheetRegistry({
      marginTop: top,
      marginRight: right,
      marginBottom: bottom,
      marginLeft: left
    })
  },
  panel: (top = 10, right = 10, bottom = 10, left = 10, color = defaultVariable.white) => {
    return getStyleSheetRegistry({
      paddingTop: top,
      paddingRight: right,
      paddingBottom: bottom,
      paddingLeft: left,
      backgroundColor: color
    })
  },
  text: (
    color = palette.black,
    fontSize = defaultVariable.fontSizeBase,
    fontWeight = defaultVariable.fontweightNormal
  ) => {
    return getStyleSheetRegistry({
      color,
      fontSize,
      fontWeight
    })
  }
}

export const template = StyleSheet.create({
  columnContainer: {
    flexDirection: 'column'
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  rowContainerAlignStart: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  rowContainerAlignEnd: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  defaultMargin: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10
  },
  defaultPanel: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: baseColors.white
  },
  defaultBorder: {
    borderWidth: 1,
    borderColor: palette.lightGray
  },
  darkBorder: {
    borderWidth: 1,
    borderColor: palette.darkGray
  },
  primaryBorder: {
    borderColor: palette.primary,
    borderWidth: 1,
    borderRadius: 5
  },
  pageTitle: {
    fontSize: defaultVariable.fontsizeBig,
    fontWeight: defaultVariable.fontweightBold
  },
  center: {
    marginTop: 0,
    marginRight: 'auto',
    marginBottom: 0,
    marginLeft: 'auto'
  },
  title: {
    color: palette.brand,
    fontSize: 18,
    fontWeight: '500'
  },
  blackTitle: {
    color: palette.black,
    fontSize: 18,
    fontWeight: '500'
  },
  largeText: {
    color: palette.black,
    fontSize: 18
  },
  shadow: {
    ...Platform.select({
      ios: {
        shadowColor: 'rgb(0,0,0)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 6
      },
      web: {
        shadowColor: 'rgb(0,0,0)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 6
      },
      android: {
        elevation: 6
      }
    })
  },
  defaultWidth: {
    width: 210
  },
  defaultHeight: {
    height: 40
  },
  text: {
    // fontFamily: '"Roboto", "Noto Sans TC", "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"'
  },
  bold: {
    fontWeight: '700'
    // fontFamily: '"Roboto", "Noto Sans TC", "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"'
  }
})

const themes = {
  default: Object.assign({}, defaultVariable, {
    elementHeight: 40,
    elementWidth: 210,
    defaultBtnWidth: 110,
    blockWidth: '100%',
    primaryColor: baseColors.basic2,

    linkColor: '#50c8fa',
    successColor: '#14dca0',
    warningColor: '#faad14',
    errorColor: '#f5222d',

    headingColor: 'rgba(0, 0, 0, .85)',
    textColor: 'rgba(0, 0, 0, .65)',
    textColorSecondary: 'rgba(0, 0, 0, .45)',
    disabledColor: 'rgba(0, 0, 0, .25)',
    borderRadiusBase: 4,
    borderColorBase: palette.lightGray,
    borderColorDark: palette.darkGray,
    boxShadowBase: '0 2px 8px rgba(0, 0, 0, .15)',
    backgroundColor: palette.white,
    shadowColor: palette.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 8
  })
}

const currentTheme = Object.assign({ update: (update) => {} }, themes.default)

currentTheme.update = (update) => {
  Object.assign(currentTheme, update)
}

export function getTheme(name = 'default') {
  currentTheme.update(themes[name])
  return currentTheme
}

export const getFontFamilyByLanguage = (lang) => {
  return `'Roboto',
          ${
            lang.startsWith('ja')
              ? '"Noto Sans JP", "Noto Sans TC"'
              : '"Noto Sans TC", "Noto Sans JP"'
          },
          -apple-system,
          BlinkMacSystemFont,
          'Segoe UI',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          sans-serif`
}
