import {
  ThemedButton,
  DynamicImage,
  PageFooter,
  FilterableTable,
  HoverableOpacity
} from '@rezio/components'
import { MaterialIcons } from '@expo/vector-icons'
import { useStores, usePermission } from '@rezio/core/hooks'
import { PLAN_FEATURES } from '@rezio/core/permission'
import { manipulator, palette, template } from '@rezio/res/theme'
import { formatBytes, toBytes } from '@rezio/utils/format'
import { MediaLibraryType, ImageLayoutType } from '@rezio/utils/types'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, ActivityIndicator } from 'react-native'

import { DroppableWrapper } from './droppableWrapper'
import { ImageItem, MediaLibraryCategory, FileLimitType } from './types'

interface MediaLibraryPropsType {
  isDeleting?: boolean
  isSelectedAll: boolean
  type: MediaLibraryType
  imageLayoutType: ImageLayoutType
  selectedImage: SelectedImage[]
  storeCategory: MediaLibraryCategory
  droppableRef: React.MutableRefObject<HTMLInputElement>
  toggleIsDeleting?: (isDeleting?: boolean) => void
  toggleSelectedAll?: (selectedAll?: boolean) => void
  onBackdropPress: () => void
  onSubmitSelected?: (selectedItem: ImageItem[]) => ImageItem[]
}

interface SelectedImage {
  storeMediaUuid?: string
  storeMediaUsageUuid?: string
}

interface FilterType {
  path?: string
  value?: string
  type?: string
}

interface PromptMessagePropsType {
  isVisible: boolean
  extraMessage: string
  iconName: 'info' | 'warning'
}
type MediaLibraryTypeConfig = Record<MediaLibraryCategory, FileLimitType>

type FiltersToParamsCallbackType = (filters: FilterType[]) => {
  filterType?: string
  selectAll?: string
}

export const config: MediaLibraryTypeConfig = {
  all: {
    sizeLimitMB: 0,
    placeholderKey: '',
    requiredPlanFeature: null,
    fileNameLengthLimit: 100
  },
  productManager: {
    sizeLimitMB: 6,
    sizeLimitWarningMB: 5,
    acceptFileTypesRex: /jpeg|jpg|png.*/,
    requiredPlanFeature: null,
    placeholderKey: 'MEDIA.PHOTO_SIZE',
    warningLimitTranslateKey: 'COMMON.FILE_SIZE_OUT_OF_CHANNEL_LIMIT',
    fileNameLengthLimit: 100
  },
  mysiteFavicon: {
    sizeLimitMB: 1,
    acceptSquareOnly: false,
    placeholderKey: 'MYSITE.FAVICON_SIZE',
    requiredPlanFeature: PLAN_FEATURES.REZIO_BASIC,
    fileNameLengthLimit: 100
  },
  mysiteLogo: {
    sizeLimitMB: 1,
    placeholderKey: 'MYSITE.LOGO_SIZE',
    requiredPlanFeature: PLAN_FEATURES.REZIO_BASIC,
    fileNameLengthLimit: 100
  },
  mysiteBanner: {
    sizeLimitMB: 1,
    placeholderKey: 'MYSITE.BANNER_SIZE',
    requiredPlanFeature: PLAN_FEATURES.REZIO_BASIC,
    fileNameLengthLimit: 100
  },
  mysitePinnedBanner: {
    sizeLimitMB: 1,
    placeholderKey: 'MYSITE.PINNED_BANNER_SIZE',
    requiredPlanFeature: PLAN_FEATURES.MYSITE_CHANNEL_LAYOUT_SWITCH,
    fileNameLengthLimit: 100
  },
  mysiteCarousel: {
    sizeLimitMB: 1,
    placeholderKey: 'MYSITE.CAROUSEL_IMAGE_SIZE',
    requiredPlanFeature: PLAN_FEATURES.MYSITE_CHANNEL_LAYOUT_SWITCH,
    fileNameLengthLimit: 100
  },
  extraImage: {
    sizeLimitMB: 1.8,
    placeholderKey: 'EXTRA.IMAGE_SIZE',
    acceptFileTypesRex: /jpeg|jpg|png.*/,
    requiredPlanFeature: PLAN_FEATURES.REZIO_EXTRA,
    fileNameLengthLimit: 100
  },
  kioskBanner: {
    sizeLimitMB: 2,
    acceptFileTypesRex: /jpeg|jpg|png.*/,
    placeholderKey: 'DEVICE.SETTING_STORE_BANNER_SIZE',
    requiredPlanFeature: PLAN_FEATURES.CHANNEL_KIOSK,
    fileNameLengthLimit: 100
  },
  kioskIdleScreen: {
    sizeLimitMB: 2,
    acceptFileTypesRex: /jpeg|jpg|png|gif.*/,
    placeholderKey: 'DEVICE.SETTING_SCREEN_SAVER_IMAGE_SIZE',
    requiredPlanFeature: PLAN_FEATURES.CHANNEL_KIOSK,
    fileNameLengthLimit: 100
  },
  kioskVoucherLogo: {
    sizeLimitMB: 1,
    acceptFileTypesRex: /jpeg|jpg|png|bmp.*/,
    placeholderKey: 'DEVICE.SETTING_LOGO_SIZE',
    requiredPlanFeature: PLAN_FEATURES.CHANNEL_POS,
    fileNameLengthLimit: 100
  },
  ttd: {
    sizeLimitMB: 1.8,
    acceptFileTypesRex: /jpeg|jpg|png.*/,
    acceptFileMinWidth: 300,
    acceptFileMinHeight: 300,
    acceptSquareOnly: true,
    placeholderKey: 'CHANNEL.GOOGLE_TTD_ITINERARY_PICTURES_RULE',
    requiredPlanFeature: PLAN_FEATURES.CHANNEL_TTD,
    fileNameLengthLimit: 100
  },
  channelStoreLogo: {
    sizeLimitMB: 1,
    acceptFileTypesRex: /jpeg|jpg|png.*/,
    placeholderKey: 'CHANNEL.STORE_INFO_FORM_SUPPLIER_LOGO_SIZE',
    requiredPlanFeature: PLAN_FEATURES.CHANNEL_KKDAYMKP,
    fileNameLengthLimit: 100
  }
}

export const MediaLibrary = observer(
  ({
    isDeleting = false,
    isSelectedAll = false,
    type = MediaLibraryType.PopUp,
    imageLayoutType = ImageLayoutType.Single,
    selectedImage = [],
    storeCategory = 'all',
    droppableRef = null,
    toggleIsDeleting,
    toggleSelectedAll,
    onBackdropPress,
    onSubmitSelected
  }: MediaLibraryPropsType) => {
    const { t } = useTranslation()
    const { store } = useStores()
    const { hasPlanFeature } = usePermission()
    const imageStore = store.imageStore.getCategory(storeCategory)
    const { currentPage = 1, totalCount, itemPerPage = 20 } = imageStore.page ?? {}
    const [isReady, setIsReady] = useState(false)
    const [statePage, setStatePage] = useState(1)
    const [librarySelectedImage, setLibrarySelectedImage] = useState([])
    const [selectedAllPage, setSelectedAllPage] = useState([{ page: 1, selectAll: false }])

    const prevStatePage = useRef(1)
    const mediaLibraryImages: ImageItem[] = [...imageStore.images.values()]
    const isEmptyImage = _.isEmpty(mediaLibraryImages)
    const isMediaLibraryPage = type === MediaLibraryType.Page
    const hiddenPopUpSelectAll = imageLayoutType === ImageLayoutType.Single && !isDeleting
    const isAllFilterType = storeCategory === 'all'
    const mediaLibraryFilters = useMemo(
      () =>
        Object.keys(config).reduce((result, key) => {
          if (
            config[key].requiredPlanFeature === null ||
            hasPlanFeature(config[key].requiredPlanFeature)
          ) {
            result.push({
              label: t(`MEDIALIBRARY.CATEGORY_${key.toUpperCase()}`),
              value: key,
              placeholder: t(`${config[key]?.placeholderKey}`, {
                fileSize: config[key]?.sizeLimitMB
              })
            })
          }

          return result
        }, []),
      []
    )

    const selectedImageUuidInMediaLibrary = useMemo(
      () =>
        _.map(
          [...imageStore.images.values()].filter((storeImage) =>
            selectedImage.find((image) => image.storeMediaUuid === storeImage.storeMediaUuid)
          ),
          'storeMediaUsageUuid'
        ),
      [selectedImage, JSON.stringify(imageStore.images)]
    )

    const currentPageSelectiveImages = useMemo(
      () =>
        _.map(
          _.filter([...imageStore.images.values()], (image) => {
            const currentConfig = config[storeCategory]
            const isInSizeLimit =
              currentConfig.sizeLimitMB > 0 &&
              image.size <= toBytes(currentConfig.sizeLimitMB, 'MB')
            const isNotInSelectedImages = !selectedImageUuidInMediaLibrary.includes(
              image.storeMediaUsageUuid
            )

            return isDeleting ? image : isInSizeLimit && isNotInSelectedImages
          }),
          'storeMediaUsageUuid'
        ),
      [
        storeCategory,
        JSON.stringify(imageStore.images),
        isDeleting,
        selectedImageUuidInMediaLibrary
      ]
    )

    const mediaLibraryStyle = {
      emptyMessageView: {
        padding: 0,
        marginTop: 20,
        marginBottom: 20
      },
      droppableView: {
        textAlign: 'center',
        padding: isMediaLibraryPage ? (isEmptyImage ? 100 : 10) : 0,
        borderRadius: 10,
        backgroundColor: isMediaLibraryPage ? palette.panelBackground : 'transparent'
      }
    }

    useEffect(() => {
      setStatePage(1)
      setSelectedAllPage([])
    }, [storeCategory])

    useEffect(() => {
      prevStatePage.current = statePage
    }, [statePage])

    useEffect(() => {
      if (isDeleting) {
        setLibrarySelectedImage([])
      }
    }, [isDeleting])

    useEffect(() => {
      setSelectedAllPage((prevState) => {
        return prevState.map((item) => {
          if (item?.page === statePage) {
            return { page: statePage, selectAll: isSelectedAll }
          }
          return item
        })
      })

      setLibrarySelectedImage((prevLibrarySelectedImage) => {
        const prevLibrarySelectedImageSize = prevLibrarySelectedImage.length

        return isSelectedAll
          ? currentPageSelectiveImages
          : prevLibrarySelectedImageSize > 0 &&
            prevLibrarySelectedImageSize < currentPageSelectiveImages.length
          ? prevLibrarySelectedImage
          : prevLibrarySelectedImage.filter(
              (storeMediaUsageUuid) => !currentPageSelectiveImages.includes(storeMediaUsageUuid)
            )
      })
    }, [isSelectedAll, currentPageSelectiveImages])

    useEffect(() => {
      const librarySelectedImageSize = librarySelectedImage.length
      const currentPageImagesSize = currentPageSelectiveImages.length

      if (!mediaLibraryImages) {
        return
      }

      if (isSelectedAll) {
        if (librarySelectedImageSize > 0 && librarySelectedImageSize < currentPageImagesSize) {
          toggleSelectedAll?.()
        }
      }
    }, [isSelectedAll, mediaLibraryImages, librarySelectedImage, currentPageSelectiveImages])

    const handleUpdateImageItem = useCallback(async () => {
      if (isDeleting) {
        await imageStore.deleteImage(librarySelectedImage, storeCategory)
        await handlePageChange(statePage, {}).catch(console.error)
        setLibrarySelectedImage([])
        toggleIsDeleting?.(false)
        return
      }

      onSubmitSelected?.(
        _.uniqWith(
          [..._.map(selectedImage, 'storeMediaUsageUuid'), ...librarySelectedImage],
          _.isEqual
        )
      )
      onBackdropPress?.()
    }, [isDeleting, librarySelectedImage, itemPerPage, storeCategory])

    const handleCloseMediaLibrary = useCallback(() => {
      toggleSelectedAll?.(false)
      setLibrarySelectedImage([])
      setSelectedAllPage([])

      if (isDeleting) {
        toggleIsDeleting?.(false)
        return
      }

      if (!isMediaLibraryPage) {
        setStatePage(1)
        imageStore.clearImage()
      }

      onBackdropPress?.()
    }, [JSON.stringify(imageStore.images), isSelectedAll, isDeleting, isMediaLibraryPage])

    const ImageWrapper = ({ children, uuid, isOutOffLimit, showSizeLimitWarning, fileBytes }) => {
      const { t } = useTranslation()
      const [isHover, setIsHover] = useState(false)

      const handleMouseEnter = useCallback(() => setIsHover(true), [])
      const handleMouseLeave = useCallback(() => setIsHover(false), [])

      const handleRemoveImage = useCallback(async () => {
        await imageStore.deleteImage([`${uuid}`], storeCategory)
      }, [uuid, storeCategory])

      const PromptMessage = ({
        isVisible = false,
        extraMessage = '',
        iconName = 'info'
      }: PromptMessagePropsType) => {
        return isVisible ? (
          <View
            style={[
              manipulator.container('row', 'flex-end', 'flex-start'),
              { flex: 1, marginTop: isVisible ? 5 : 0, width: 180 }
            ]}
          >
            <MaterialIcons
              name={iconName}
              size={20}
              color={iconName === 'info' ? palette.warning : palette.notice}
              style={{ marginRight: 5 }}
            />
            <Text
              style={{
                fontSize: 14,
                color: iconName === 'info' ? palette.warning : palette.notice,
                textAlign: 'left'
              }}
            >
              {extraMessage}
            </Text>
          </View>
        ) : null
      }

      return (
        <View
          style={[
            manipulator.border('all', 'light', 5),
            {
              margin: isMediaLibraryPage ? 8 : 5,
              padding: 10,
              backgroundColor: palette.white,
              borderRadius: 10
            }
          ]}
        >
          {isOutOffLimit && !isDeleting && (
            <View
              style={[manipulator.container('row', 'flex-end', 'center'), { marginBottom: 10 }]}
            >
              <HoverableOpacity onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <ThemedButton
                  onPress={handleRemoveImage}
                  style={{ margin: 0, padding: 0, minWidth: 20, backgroundColor: 'transparent' }}
                  layout='default'
                >
                  <MaterialIcons
                    name='remove-circle'
                    size={20}
                    color={isHover || isOutOffLimit ? palette.error : palette.gray}
                  />
                </ThemedButton>
              </HoverableOpacity>
            </View>
          )}
          {children}
          <PromptMessage
            isVisible={!isOutOffLimit && showSizeLimitWarning}
            extraMessage={t(`${config[storeCategory].warningLimitTranslateKey}`, {
              fileSize: config[storeCategory].sizeLimitWarningMB
            })}
            iconName='warning'
          />
          <PromptMessage
            isVisible={isOutOffLimit}
            extraMessage={t('COMMON.FILE_SIZE_OUT_OF_LIMIT', {
              fileSize: formatBytes(fileBytes ?? 0),
              maxSize: config[storeCategory].sizeLimitMB
            })}
            iconName='info'
          />
        </View>
      )
    }

    const ImageContainer = ({ children, uuid, isOutOffLimit }) => {
      const buttonDisabled =
        !isDeleting &&
        (isOutOffLimit || selectedImageUuidInMediaLibrary.includes(uuid) || isMediaLibraryPage)

      const handleSelectImage = useCallback(
        (uuid) => {
          setLibrarySelectedImage((prevSelectedImage) => {
            const removeUuid = prevSelectedImage.find(
              (selectedImageUuid) => selectedImageUuid === uuid
            )
            if (removeUuid) {
              return prevSelectedImage.filter(
                (selectedImageUuid) => selectedImageUuid !== removeUuid
              )
            }

            return !isDeleting && imageLayoutType === ImageLayoutType.Single
              ? [uuid]
              : [...prevSelectedImage, uuid]
          })
        },
        [librarySelectedImage, isDeleting]
      )

      return (
        <ThemedButton
          disabled={buttonDisabled}
          containerStyle={{ zIndex: 1 }}
          style={{ backgroundColor: 'transparent', padding: 0, height: 'auto' }}
          onPress={() => handleSelectImage(uuid)}
        >
          {children}
        </ThemedButton>
      )
    }

    const getImageSelectedStatus = useCallback(
      (image: ImageItem, isOutOffLimit: boolean) => {
        const isInTheLibrarySelectedImage = librarySelectedImage.some(
          (storeMediaUsageUuid: string) => storeMediaUsageUuid === image.storeMediaUsageUuid
        )
        const isInTheSelectedImage = selectedImageUuidInMediaLibrary.some(
          (storeMediaUsageUuid) => storeMediaUsageUuid === image.storeMediaUsageUuid
        )

        const isSelected = isDeleting
          ? isInTheLibrarySelectedImage
          : isSelectedAll
          ? !isOutOffLimit && (isInTheSelectedImage || isInTheLibrarySelectedImage)
          : isInTheSelectedImage || isInTheLibrarySelectedImage

        return isSelected
      },
      [isSelectedAll, isDeleting, selectedImageUuidInMediaLibrary, librarySelectedImage]
    )

    const renderImage = useCallback(() => {
      const images = [...imageStore.images.values()]

      return (
        <DroppableWrapper
          droppableRef={droppableRef}
          isMultiple
          storeCategory={storeCategory}
          style={mediaLibraryStyle.droppableView}
          onUploadedImage={handleUploadedImage}
        >
          {_.isEmpty(images) ? (
            <Text
              style={[
                manipulator.container('column', 'center', 'center'),
                { color: 'black', textAlign: 'center' }
              ]}
            >
              {isAllFilterType
                ? t('COMMON.EMPTY_ALL_MEDIA_IMAGE_WARNING')
                : t('COMMON.EMPTY_IMAGE_WARNING_TEXT')}
            </Text>
          ) : (
            <View
              style={[
                manipulator.container('row', 'flex-start', 'flex-start'),
                { flexWrap: 'wrap' }
              ]}
            >
              {images.map((image: ImageItem) => {
                const { storeMediaUsageUuid, size } = image
                const { sizeLimitMB, sizeLimitWarningMB } = config[storeCategory]
                const isOutOffLimit = sizeLimitMB > 0 && image.size > toBytes(sizeLimitMB, 'MB')
                const showSizeLimitWarning =
                  sizeLimitWarningMB > 0 && image.size > toBytes(sizeLimitWarningMB, 'MB')

                return (
                  <ImageWrapper
                    uuid={storeMediaUsageUuid}
                    isOutOffLimit={isOutOffLimit}
                    showSizeLimitWarning={showSizeLimitWarning}
                    fileBytes={size}
                    key={storeMediaUsageUuid}
                  >
                    <ImageContainer uuid={storeMediaUsageUuid} isOutOffLimit={isOutOffLimit}>
                      <DynamicImage
                        file={image}
                        imageStyle={{ width: '100%' }}
                        isSelected={getImageSelectedStatus(image, isOutOffLimit)}
                      />
                    </ImageContainer>
                  </ImageWrapper>
                )
              })}
            </View>
          )}
        </DroppableWrapper>
      )
    }, [isAllFilterType, storeCategory, JSON.stringify(imageStore), getImageSelectedStatus])

    const renderStoreCategoryPlaceholder = useCallback(() => {
      const placeholder = mediaLibraryFilters.find((filter) => filter.value === storeCategory)
        ?.placeholder
      return placeholder ? (
        <View style={{ marginBottom: 15 }}>
          <Text>{placeholder}</Text>
        </View>
      ) : null
    }, [storeCategory, mediaLibraryFilters])

    const renderItem = useCallback(() => {
      return (
        <>
          {isMediaLibraryPage ? renderStoreCategoryPlaceholder() : null}
          <View style={{ justifyContent: 'center', margin: 0, borderRadius: 10 }}>
            {isReady ? renderImage() : <ActivityIndicator />}
            {(isDeleting || !isMediaLibraryPage) && (
              <PageFooter style={{ borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}>
                <View style={manipulator.container('row', 'space-between', 'center')}>
                  <ThemedButton
                    onPress={handleCloseMediaLibrary}
                    style={[
                      template.defaultPanel,
                      { width: 150, backgroundColor: palette.negative }
                    ]}
                  >
                    <Text style={{ color: palette.black }}>{t('FORM.BUTTON_CANCEL')}</Text>
                  </ThemedButton>
                  <ThemedButton
                    disabled={isDeleting && _.isEmpty(librarySelectedImage)}
                    onPress={handleUpdateImageItem}
                    style={[
                      template.defaultPanel,
                      {
                        width: 150,
                        backgroundColor: isDeleting ? palette.warning : palette.positive
                      }
                    ]}
                  >
                    <Text style={{ color: palette.white }}>
                      {isDeleting ? t('FORM.BUTTON_CONFIRM_DELETE') : t('COMMON.BUTTON_ADD')}
                    </Text>
                  </ThemedButton>
                </View>
              </PageFooter>
            )}
          </View>
        </>
      )
    }, [isReady, isDeleting, isMediaLibraryPage, librarySelectedImage, renderImage])

    const filtersToParams = useCallback<FiltersToParamsCallbackType>((filters) => {
      if (!filters) {
        return
      }

      const params = {}
      _.forEach(filters, (filter) => {
        params[filter.path] = filter.value
      })
      return params
    }, [])

    const handlePageChange = useCallback(
      async (page, filters) => {
        const { selectAll, filterType } = filtersToParams(filters)
        const selectedImageStoreMediaUuid = _.map(selectedImage, 'storeMediaUuid')
        const hasSelectedImage = [...imageStore.images.values()].some((item) =>
          selectedImageStoreMediaUuid.includes(item.storeMediaUuid)
        )

        setIsReady(false)
        if (hasSelectedImage) {
          imageStore.clearImage()
        }

        setStatePage(page)

        if (selectAll) {
          toggleSelectedAll?.(isSelectedAll)
        }

        if (prevStatePage.current !== page) {
          imageStore.clearImage()
          toggleSelectedAll?.(false)

          setSelectedAllPage([
            ...selectedAllPage,
            ...(selectedAllPage.every((item) => item.page !== page)
              ? [{ page, selectAll: false }]
              : [])
          ])

          selectedAllPage.map((item) => {
            if (item.page === page && item.selectAll) {
              toggleSelectedAll?.(true)
            }
            return item
          })
        }

        await imageStore.fetchImages({
          page,
          num: itemPerPage,
          type: filterType || storeCategory
        })
        setIsReady(true)
      },
      [storeCategory, selectedImage, selectedAllPage]
    )

    const handleFilterChange = useCallback(
      async (filters) => {
        handlePageChange(statePage, filters).catch(console.error)
      },
      [storeCategory, statePage]
    )

    const handleUploadedImage = useCallback(() => {
      imageStore.clearImage()
      handlePageChange(1, {}).catch(console.error)
    }, [imageStore, handlePageChange])

    return (
      <FilterableTable
        data={[mediaLibraryImages]}
        showListHeaderDivider={false}
        renderItem={renderItem}
        currentPage={currentPage}
        totalPage={Math.ceil((totalCount || 1) / itemPerPage)}
        showTotalCount={false}
        showPagination={!isEmptyImage}
        onGotoPage={handlePageChange}
        filterProps={{
          filterFormContainerStyle: { width: '100%' },
          gridContainerStyle: {
            justifyContent: isMediaLibraryPage ? 'space-between' : 'flex-end',
            paddingBottom: isMediaLibraryPage ? 10 : 0
          }
        }}
        filters={[]}
        onChangeFilters={handleFilterChange}
        updateFilterOn='blur'
        disableFiltering
        isStrict={false}
        renderRowStyle={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: hiddenPopUpSelectAll ? 30 : 0
        }}
        emptyMessageViewStyle={mediaLibraryStyle.emptyMessageView}
      />
    )
  }
)
