// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { APIErrorCode } from '@rezio/core/api'
import Core from '@rezio/core/core'
import DataStore from '@rezio/core/data'
import { model, Model, modelAction, modelFlow, prop, _async, _await } from 'mobx-keystone'
import moment from 'moment'

export enum DiscountType {
  Percentage = 'PERCENTAGE',
  Fixed = 'FIXED'
}

export enum RuleType {
  All = 'ALL',
  Category = 'CATEGORY',
  Product = 'PRODUCT'
}

export enum ValidityStatus {
  NotStarted = 'NOT_STARTED',
  Discount = 'DISCOUNT',
  Expired = 'EXPIRED'
}

export interface Coupon {
  id: number
  uuid: string
  label: string
  code: string
  isActive: boolean
  discountType: DiscountType
  discountValue: string
  percentageDiscountValue?: string
  fixedDiscountValue?: string
  currencyUuid: string
  ruleType: RuleType
  rule: any
  categoryRule?: any
  productRule?: any
  startTs: number
  endTs: number
  tsDiff: number
  note: string
  repeatable: boolean
  usedTimes: number
  timesLimit: number
  minimumAmount: string
  minimumAmountCurrencyUuid: string
  validityStatus: ValidityStatus
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  editable: boolean
  actionLog: any
}

enum OnOff {
  off,
  on
}
export interface FormatCoupon extends Omit<Coupon, 'repeatable'> {
  repeatable: OnOff
}
@model('admin/couponCode')
class CouponCode extends Model({
  loading: prop<boolean>(false),
  loadingTable: prop<boolean>(false),
  couponList: prop<Coupon[]>(() => []),
  totalCount: prop<number>(0),
  itemPerPage: prop<number>(20),
  currentPage: prop<number>(1),
  couponFormData: prop<FormatCoupon>(null),
  allProductWithMenu: prop<any>(() => []),
  storeUtcOffset: prop<string | number>(-new Date().getTimezoneOffset()),
  isCouponLimitModalShow: prop<boolean>(false)
}) {
  @modelAction
  resetCoupon = () => {
    this.loading = false
    this.loadingTable = false
    this.couponList = []
    this.totalCount = 0
    this.itemPerPage = 20
    this.currentPage = 1
    this.couponFormData = null
    this.allProductWithMenu = []
    this.isCouponLimitModalShow = false
  }

  @modelAction
  setStoreUtcOffset = (value: string | number) => {
    this.storeUtcOffset = value
  }

  @modelAction
  setIsCouponLimitModalShow = (value: boolean) => {
    this.isCouponLimitModalShow = value
  }

  @modelFlow
  fetchCouponCodeList = _async(function* (
    this: CouponCode,
    params,
    page: number = this.currentPage,
    isForce: boolean = false
  ) {
    try {
      if (!isForce && this.loadingTable) {
        return
      }
      this.loadingTable = true
      const core = Core.getInstance()
      const dataStore = DataStore.getInstance({}, core)
      const timezoneData = core?.options?.timezone?.find(
        ({ uuid }) => uuid === dataStore?.store?.regional?.timezoneUuid
      )
      const tsDiff = timezoneData?.timeDiff
        ? +timezoneData?.timeDiff * 60 * 60
        : -(new Date().getTimezoneOffset() * 60)

      const [request] = core.api.get(`coupon/${page}`, { params: { ...params, tsDiff } })
      const { data } = yield* _await(request)

      this.totalCount = data.totalCount ?? 0
      this.itemPerPage = data.itemPerPage ?? 20
      this.couponList = [...data.list] ?? []
      this.currentPage = page
    } catch (err) {
      console.error(err)
    } finally {
      this.loadingTable = false
    }
  })

  @modelFlow
  addCoupon = _async(function* (this: CouponCode, formValues, callback: () => void) {
    try {
      this.loading = true

      const data = {
        ...formValues,
        code: formValues.code?.toUpperCase(),
        currencyUuid:
          formValues.discountType === DiscountType.Percentage ? null : formValues.currencyUuid,
        endDate: formValues.endDateSwitch ? formValues.endDate : null,
        endTime: formValues.endDateSwitch ? formValues.endTime : null,
        minimumAmount: formValues.hasMinimumOrder ? formValues.minimumAmount : -1,
        minimumAmountCurrencyUuid: formValues.hasMinimumOrder
          ? formValues.minimumAmountCurrencyUuid
          : null,
        repeatable: Boolean(formValues.repeatable),
        timesLimit: formValues.hasTimesLimit ? formValues.timesLimit : -1
      }

      delete data.endDateSwitch
      delete data.hasMinimumOrder
      delete data.hasTimesLimit

      const [request] = Core.getInstance().api.post('coupon', data)
      const { message } = yield* _await(request)
      message === 'success' && callback()
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  getCoupon = _async(function* (this: CouponCode, uuid: string, pageBack: () => void = () => {}) {
    try {
      this.loading = true

      if (!uuid) {
        this.couponFormData = null
        pageBack()
        return
      }

      const [request] = Core.getInstance().api.get(`coupon/${uuid}`)
      const { data } = yield* _await(request)

      if (!data) {
        this.couponFormData = null
        pageBack()
        return
      }
      this.couponFormData = {
        ...data,
        endDateSwitch: data.endDate ? 1 : 0,
        hasMinimumOrder: data.minimumAmount === '-1' ? 0 : 1, // 這邊後端給 '-1'
        repeatable: data.repeatable ? 1 : 0,
        hasTimesLimit: data.timesLimit === -1 ? 0 : 1,
        timesLimit: data.timesLimit === -1 ? null : data.timesLimit,
        startDate: moment(data.startTs * 1000)
          .utcOffset(this.storeUtcOffset)
          .format('YYYY-MM-DD'),
        startTime: moment(data.startTs * 1000)
          .utcOffset(this.storeUtcOffset)
          .format('HH:mm'),
        endDate: data.endTs
          ? moment(data.endTs * 1000)
              .utcOffset(this.storeUtcOffset)
              .format('YYYY-MM-DD')
          : null,
        endTime: data.endTs
          ? moment(data.endTs * 1000)
              .utcOffset(this.storeUtcOffset)
              .format('HH:mm')
          : null,
        categoryRule: data.ruleType === RuleType.Category ? data.rule : [],
        productRule: data.ruleType === RuleType.Product ? data.rule : [],
        percentageDiscountValue:
          data.discountType === DiscountType.Percentage ? data.discountValue : null,
        fixedDiscountValue: data.discountType === DiscountType.Fixed ? data.discountValue : null
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  editCoupon = _async(function* (this: CouponCode, formValues, callback: () => void) {
    try {
      this.loading = true

      const data = {
        ...formValues,
        code: formValues.code?.toUpperCase(),
        currencyUuid:
          formValues.discountType === DiscountType.Percentage ? null : formValues.currencyUuid,
        endDate: formValues.endDateSwitch ? formValues.endDate : null,
        endTime: formValues.endDateSwitch ? formValues.endTime : null,
        minimumAmount: formValues.hasMinimumOrder ? formValues.minimumAmount : -1,
        minimumAmountCurrencyUuid: formValues.hasMinimumOrder
          ? formValues.minimumAmountCurrencyUuid
          : null,
        repeatable: Boolean(formValues.repeatable),
        timesLimit: formValues.hasTimesLimit ? formValues.timesLimit : -1
      }

      delete data.endDateSwitch
      delete data.hasMinimumOrder
      delete data.hasTimesLimit

      const [request] = Core.getInstance().api.put(`coupon/${this.couponFormData.uuid}`, data)
      const { message } = yield* _await(request)
      message === 'success' && callback()
      yield* _await(this.getCoupon(this.couponFormData.uuid, () => {}))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  deleteCoupon = _async(function* (this: CouponCode, callback: () => void) {
    try {
      this.loading = true

      const [request] = Core.getInstance().api.delete(`coupon/${this.couponFormData.uuid}`)
      const { message } = yield* _await(request)
      message === 'success' && callback()
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  activeCoupon = _async(function* (
    this: CouponCode,
    uuid: string,
    isActive: boolean,
    params = null
  ) {
    try {
      this.loadingTable = true

      const [request] = Core.getInstance().api.put(`coupon/${uuid}/active`, { isActive })
      yield* _await(request)
      // list update
      if (params) {
        yield* _await(this.fetchCouponCodeList(params, undefined, true))
        return
      }

      // edit update
      yield* _await(this.getCoupon(uuid))
    } catch (err) {
      if (err.code === APIErrorCode.Frontend && err?.data?.[0]?.type === 'couponCountOverLimit') {
        this.setIsCouponLimitModalShow(true)
      }
      console.error(err)
    } finally {
      this.loadingTable = false
    }
  })

  @modelFlow
  fetchProductMenu = _async(function* (this: CouponCode, page: number = 1) {
    try {
      this.loading = true
      const params = {
        num: 999
      }
      const [request] = Core.getInstance().api.get(`/product/menu/${page}`, { params })
      const { data } = yield* _await(request)

      if (data.list) {
        const newProducts = [...this.allProductWithMenu, ...data.list]
        this.allProductWithMenu = newProducts
        if (newProducts.length < data.totalCount) {
          yield* _await(this.fetchProductMenu(page + 1))
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })
}

export default CouponCode
