import type { TFunction } from 'i18next'
import type { Instance } from 'mobx-state-tree'
import { useState, useEffect, useRef, useContext, useCallback, createContext } from 'react'
import { Animated, LayoutChangeEvent } from 'react-native'
import qs, { type ParsedQuery } from 'query-string'
import { usePermission, ROLE_TITLE } from '@rezio/core/auth'
import { usePageWidth } from '@rezio/core/layout'
import { useHistory, useRouteMatch, useParams } from '../unimodules/router'
import { getPageNameFromPath, parseDestination } from './routing/utils'

import type { Destination } from './routing/types'
import type Core from './core'
import type Data from './data'
import type { RootStore } from './stores'

interface RezioRouterLocation {
  pathname: string
  key: string
}

export function useRouter() {
  const history = useHistory()
  const params = useParams<Record<string, string>>()
  const match = useRouteMatch(history.location.pathname)

  const router = useRef(
    (function () {
      return {
        params,
        history,
        match,
        get location(): RezioRouterLocation {
          return this.history.location
        },
        get route(): RezioRouterLocation {
          return this.location
        },
        get back(): () => void {
          return this.history.goBack
        },
        get pathname(): string {
          return this.location.pathname
        },
        get query(): ParsedQuery {
          return qs.parse(this.location.search)
        },
        get pageName(): string {
          return getPageNameFromPath(this.pathname)
        },
        push(destination: Destination) {
          this.history.push(parseDestination(destination, this.location.pathname))
        },
        replace(destination: Destination) {
          this.history.replace(parseDestination(destination, this.location.pathname))
        }
      }
    })()
  )

  useEffect(() => {
    router.current.params = params
    router.current.history = history
    router.current.match = match
  }, [params, history])

  return router.current
}

export interface Stores {
  t?: TFunction
  core?: Core
  data?: Data
  store?: Instance<typeof RootStore>
}

export const StoreContext = createContext<Stores>({})

function useStores(): Stores {
  return useContext(StoreContext)
}

function useAnimatedValue(initialValue) {
  const ref = useRef(new Animated.Value(initialValue))
  return ref.current
}

function getInitialValue(config) {
  if (typeof config.initialValue !== 'undefined') return config.initialValue
  else {
    return config.toValue
  }
}

function useAnimation(config) {
  const animatedValue = useAnimatedValue(getInitialValue(config))
  const type = config.type || 'timing'
  const animate = () => {
    if (type === 'timing') {
      Animated.timing(animatedValue, config).start()
    } else if (type === 'spring') {
      Animated.spring(animatedValue, config).start()
    }
  }

  useEffect(animate, [config.toValue])

  return animatedValue
}

function useRezioCheats() {
  const { role } = usePermission('basic')
  const [cheatCodes, setCheatCodes] = useState(0)
  const resetCheats = useCallback(() => setCheatCodes(0), [])
  const triggerCheats = useCallback(
    () => setCheatCodes((count) => (role === ROLE_TITLE.REZIO_EXPERT ? count + 1 : 0)),
    [role]
  )

  return {
    resetCheats,
    triggerCheats,
    validCheatCode: cheatCodes >= 10
  }
}

// function useTracking () {
//   const trackingEvent = useCallback(async (eventName, eventObj = {}) => {
//     return await eventTrigger(eventName, eventObj)
//   }, [])

//   return {
//     trackingEvent
//   }
// }

export function useLayoutWidth(): [number, (e: LayoutChangeEvent) => void] {
  const pageWidth = usePageWidth()
  const [layoutWidth, setLayoutWidth] = useState(pageWidth)
  const handleLayout = useCallback((e: LayoutChangeEvent) => {
    const { width } = e.nativeEvent.layout
    setLayoutWidth(width)
  }, [])
  return [layoutWidth, handleLayout]
}

export { useLayout, usePageWidth } from '@rezio/core/layout'
export {
  useAnimation,
  useAnimatedValue,
  useRezioCheats,
  usePermission,
  useStores
  // useTracking
}
