// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Core from '@rezio/core/core'
import { SessionQuotaType } from '@rezio/utils/types'
import _ from 'lodash'
import { model, Model, modelAction, modelFlow, prop, _async, _await } from 'mobx-keystone'
import moment from 'moment'

interface SalesSelectOption {
  label: string
  value: string
  periodDisplayEndTime: string
}

interface BatchSalesOptions {
  label: string
  value: string
  sessionSetting: any
}

export interface Filters {
  startDate: string
  productUuid: [string]
  salesOption?: string[]
}

interface Params {
  s: string
  e: string
  p: string
  so?: string
  r: boolean
}

interface Time {
  startAt: string
  mins: number
  selected?: boolean
  endAt?: string
  days?: number
}

interface SalesOption {
  sessionList: {
    isAllDay: boolean
    sessionStartTime: string
    sessionEndTime: string
  }[]
  sessionKeys?: string[]
  date: {
    [date: string]: {
      [time: string]: {
        isAllDay?: boolean
        sessionUuid?: string
        sessionStartTime?: string
        sessionEndTime?: string
      }
    }
  }
  periodDisplayEndTime?: string
  salesOptionUuid: string
}

interface SalesOptionDate {
  [time: string]: {
    isAllDay?: boolean
    sessionUuid?: string
    sessionStartTime?: string
    sessionEndTime?: string
  }
}

@model('admin/stockCalendar')
class StockCalendar extends Model({
  loading: prop<boolean>(false),
  loadingTable: prop<boolean>(false),
  salesSelectOptions: prop<SalesSelectOption[]>(() => []),
  product: prop<any>(null),
  lastUpdateTime: prop<string>(null),
  startDate: prop<string>(null),
  selectedSession: prop<any>(null),
  selectedSalesOptionUuid: prop<string>(null),
  selectedPublishStatusUid: prop<string>(null),
  sessionPreview: prop<any>(null),
  isSessionSuccessTipShow: prop<boolean>(false),
  isBatchModalShow: prop<boolean>(false),
  batchEditLoading: prop<boolean>(false),
  batchFilters: prop<any>({
    productUuid: null,
    salesOptionUuid: null,
    startDate: undefined,
    endDate: undefined
  }),
  batchSalesOptions: prop<BatchSalesOptions[]>(() => []),
  batchFilteredSessionSetting: prop<any>(() => []),
  batchQuotaType: prop<SessionQuotaType>(null),
  batchItemList: prop<any>(() => []),
  batchQuota: prop<number>(null)
}) {
  @modelAction
  resetStockCalendar = () => {
    this.loading = false
    this.loadingTable = false
    this.salesSelectOptions = []
    this.product = null
    this.lastUpdateTime = null
    this.startDate = null
    this.selectedSession = null
    this.selectedSalesOptionUuid = null
    this.sessionPreview = null
    this.isSessionSuccessTipShow = false
  }

  @modelAction
  setSalesSelectOptions = (value: SalesSelectOption[]) => {
    this.salesSelectOptions = value
  }

  @modelAction
  setSelectedSession = (value: any | null) => {
    this.selectedSession = value
  }

  @modelAction
  setSelectedSalesOptionUuid = (value: string | null) => {
    this.selectedSalesOptionUuid = value
  }

  @modelAction
  setSessionPreview = (value: any | null) => {
    this.sessionPreview = value
  }

  @modelAction
  setIsSessionSuccessTipShow = (boolean: boolean) => {
    this.isSessionSuccessTipShow = boolean
  }

  @modelAction
  clearTableData = () => {
    this.loading = false
    this.loadingTable = false
    this.product = null
    this.lastUpdateTime = null
    this.startDate = null
    this.selectedSession = null
    this.selectedSalesOptionUuid = null
    this.sessionPreview = null
    this.isSessionSuccessTipShow = false
  }

  @modelAction
  setSelectedPublishStatusUid = (value: string) => {
    this.selectedPublishStatusUid = value
  }

  @modelFlow
  fetchDateList = _async(function* (this: StockCalendar, filters: Filters) {
    try {
      this.loading = true
      this.loadingTable = true

      const params: Params = {
        s: filters.startDate,
        e: moment(filters.startDate).add(13, 'days').format('YYYY-MM-DD'),
        p: filters.productUuid[0],
        so: filters.salesOption ? filters.salesOption.join(',') : '',
        r: true
      }

      const [request] = Core.getInstance().api.get('publish/calendar/1', { params })
      const { data } = yield* _await(request)

      const [sessionRequest] = Core.getInstance().api.get(`product/${params.p}/sessionSetting`)
      const { data: sessionSetting } = yield* _await(sessionRequest)

      const product = data?.list?.[0] ?? null
      if (!product) {
        this.product = null
        return
      }

      product?.salesOption.forEach((salesOpt: SalesOption) => {
        salesOpt?.sessionList?.sort((a, b) => {
          if (a.isAllDay) return -1
          if (b.isAllDay) return 1
          if (a.sessionStartTime > b.sessionStartTime) return 1
          if (a.sessionStartTime < b.sessionStartTime) return -1
          return 0
        })
        const sessionKeys = []
        salesOpt?.sessionList?.forEach((session) => {
          sessionKeys.push(
            session.isAllDay ? 'allDay' : `${session.sessionStartTime}-${session.sessionEndTime}`
          )
        })
        salesOpt.sessionKeys = sessionKeys

        salesOpt.periodDisplayEndTime = this.salesSelectOptions.find(
          (opt) => opt.value === salesOpt.salesOptionUuid
        )?.periodDisplayEndTime

        Object.keys(salesOpt.date).forEach((dateString) => {
          const singleDateData = salesOpt.date[dateString]

          // format API data key to `${startTime}-${EndTime}`
          const newSingleDateData = Object.values(singleDateData).reduce<SalesOptionDate>(
            (acc, current) => {
              acc[
                current.isAllDay
                  ? 'allDay'
                  : `${current.sessionStartTime}-${current.sessionEndTime}`
              ] = current
              return acc
            },
            {}
          )

          salesOpt.date[dateString] = newSingleDateData
        })
      })

      sessionSetting.forEach((setting) => {
        const salesOption = product?.salesOption.find(
          (salesOpt) => salesOpt.salesOptionUuid === setting.salesOptionUuid
        )

        if (salesOption) {
          if (setting.timezoneTsDiff) {
            product.timezoneTsDiff = setting.timezoneTsDiff
          }

          if (_.isArray(salesOption?.sessionSetting)) {
            salesOption.sessionSetting.push(setting)
          } else {
            salesOption.sessionSetting = [setting]
          }

          if (
            !salesOption.periodEndDate ||
            moment(setting.periodEndDate).isAfter(moment(salesOption.periodEndDate))
          ) {
            salesOption.periodStartDate = setting.periodStartDate
            salesOption.periodEndDate = setting.periodEndDate
          }
        }
      })

      this.product = product
      this.startDate = filters.startDate
      this.lastUpdateTime = moment().format('YYYY/MM/DD HH:mm')
      this.selectedSession = null
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
      this.loadingTable = false
    }
  })

  @modelFlow
  fetchSalesOptionsByUuid = _async(function* (this: StockCalendar, uid: string) {
    try {
      this.loadingTable = true
      if (!uid) {
        this.setSalesSelectOptions([])
        return
      }
      const [request] = Core.getInstance().api.get(`product/${uid}/salesOption`)
      const { data } = yield* _await(request)

      if (_.isArray(data)) {
        this.setSalesSelectOptions(
          data.map((opt) => ({
            label: opt.title ?? '',
            value: opt.uuid ?? '',
            periodDisplayEndTime: opt.availableUntil ?? null
          }))
        )
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loadingTable = false
    }
  })

  @modelFlow
  deleteSingleSession = _async(function* (
    this: StockCalendar,
    target: 'session' | 'following',
    filters: Filters
  ) {
    try {
      const [request] = Core.getInstance().api.delete(
        `publish/session/${this.selectedSession.sessionUuid}`,
        {
          params: {
            productUuid: this.product.productUuid,
            target,
            time: this.selectedSession.sessionStartTime
          }
        }
      )
      yield* _await(request)
      yield* _await(this.fetchDateList(filters))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  previewSalesOptionSession = _async(function* (
    this: StockCalendar,
    periodStartDate: string,
    periodEndDate: string,
    salesOption
  ) {
    try {
      const {
        repeatSetting,
        productUuid,
        salesOptionUuid,
        isAllDay,
        quotaType,
        quotaSetting,
        seats,
        resourceUuids,
        uuid
      } = salesOption?.sessionSetting?.[0]
      const { weekdays = {}, times = [] } = repeatSetting
      const initWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const revertWeekdays = {}

      Object.keys(weekdays).forEach((key) => {
        revertWeekdays[initWeek[key]] = {
          key: initWeek[key],
          from: weekdays[key][0],
          to: weekdays[key][1]
        }
      })

      const revertTime = []
      times.forEach((time) => {
        const days = Math.floor(time.mins / 1440)
        const hours = Math.floor((time.mins % 1440) / 60)
        const mins = Math.floor((time.mins % 1440) % 60)
        revertTime.push({ start: time.startAt, days, hours, mins })
      })

      const data = {
        productUuid,
        salesOptionUuid,
        isAllDay,
        quotaType,
        quotaSetting: JSON.parse(quotaSetting),
        seats,
        periodStartDate,
        periodEndDate,
        time: revertTime,
        weekdays: revertWeekdays,
        resource: _.cloneDeep(resourceUuids),
        target: 'single',
        sessionSettingUuid: uuid
      }

      const [request] = Core.getInstance().api.post('publish/sessionSetting/preview', data)
      const { data: preview } = yield* _await(request)

      this.setSessionPreview({
        ...preview,
        request: {
          content: data
        }
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  editSalesOptionSession = _async(function* (this: StockCalendar, filters: Filters) {
    try {
      const [request] = Core.getInstance().api.put(
        `publish/sessionSetting/${this.sessionPreview.request.content.sessionSettingUuid}`,
        this.sessionPreview.request.content
      )
      yield* _await(request)
      this.setSelectedSalesOptionUuid(null)
      this.setSessionPreview(null)
      this.setIsSessionSuccessTipShow(true)
      yield* _await(this.fetchDateList(filters))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  updateSessionQuota = _async(function* (
    this: StockCalendar,
    data,
    filters: Filters,
    callback: () => void
  ) {
    try {
      const content = {
        productUuid: this.product.productUuid,
        ...data
      }
      const [request] = Core.getInstance().api.put(
        `publish/session/${this.selectedSession.sessionUuid}`,
        content
      )
      yield* _await(request)
      callback()
      yield* _await(this.fetchDateList(filters))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  setSingleSessionStatus = _async(function* (
    this: StockCalendar,
    status,
    filters: Filters,
    callback
  ) {
    try {
      const data = {
        active: status,
        productUuid: this.product.productUuid,
        target: 'session'
      }
      const [request] = Core.getInstance().api.put(
        `/publish/session/${this.selectedSession.sessionUuid}/active`,
        data
      )
      yield* _await(request)
      callback()
      yield* _await(this.fetchDateList(filters))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  putSalesOptionPublishStatus = _async(function* (
    this: StockCalendar,
    status: 0 | 1,
    filters: Filters,
    salesOptionUuid?: string
  ) {
    try {
      this.loading = true
      const data = {
        publishStatus: status
      }
      const [request] = Core.getInstance().api.put(
        `/product/${this.product.productUuid}/${
          salesOptionUuid || this.selectedPublishStatusUid
        }/publishStatus`,
        data
      )
      yield* _await(request)
      this.setSelectedPublishStatusUid(null)
      yield* _await(this.fetchDateList(filters))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  // batch edit modal

  @modelAction
  setIsBatchModalShow = (boolean: boolean) => {
    this.isBatchModalShow = boolean
  }

  @modelAction
  setBatchModalShowWithDefaultValue = (t) => {
    this.batchFilters = {
      productUuid: this.product.productUuid,
      salesOptionUuid: null,
      startDate: this.startDate,
      endDate: moment(this.startDate).add(13, 'd').format('YYYY-MM-DD')
    }
    this.isBatchModalShow = true
    this.fetchSalesOptionsAndSessionSetting(this.product.productUuid, t).catch(console.error)
  }

  @modelAction
  setBatchSalesOptions = (value) => {
    this.batchSalesOptions = value
  }

  @modelAction
  setBatchFilters = (key: string, value) => {
    const filters = { ...this.batchFilters, [key]: value }

    if (key === 'productUuid') {
      filters.salesOptionUuid = null
      this.setBatchSalesOptions([])
      this.batchFilteredSessionSetting = []
    }

    this.batchFilters = filters
    if (filters.productUuid && filters.salesOptionUuid && filters.startDate && filters.endDate) {
      this.setBatchFilteredSessionSetting()
    } else {
      this.batchFilteredSessionSetting = []
    }
  }

  @modelAction
  setBatchFilteredSessionSetting = () => {
    const { salesOptionUuid } = this.batchFilters

    const filteredSetting = this.batchSalesOptions.find(
      (opt) => opt.value === salesOptionUuid
    ).sessionSetting

    this.batchQuotaType = filteredSetting?.[0]?.quotaType ?? null
    const times = []
    filteredSetting?.forEach((setting) =>
      setting.repeatSetting.times.forEach((time: { startAt: string; mins: number }) => {
        times.push(time)
      })
    )

    const batchFilteredSessionSetting = Object.values(
      times.reduce((acc, current) => {
        const key = `t${current.startAt.replace(':', '')}${current.mins}`
        acc[key] = _.cloneDeep(current)
        return acc
      }, {})
    ).sort((a: Time, b: Time) => {
      if (a.startAt === b.startAt) {
        return b.mins - a.mins
      }
      if (a.startAt > b.startAt) return 1
      if (a.startAt < b.startAt) return -1
    })

    batchFilteredSessionSetting.forEach((time: Time) => {
      time.selected = true
      time.endAt = moment(time.startAt, 'HH:mm').add(time.mins, 'm').format('HH:mm')
      time.days = Math.floor(time.mins / (60 * 24))
    })
    this.batchFilteredSessionSetting = batchFilteredSessionSetting
    this.fetchBatchSession().catch(console.error)
  }

  @modelAction
  setFilteredSessionSettingStatus = (index, selected: boolean) => {
    const { batchFilteredSessionSetting } = this
    batchFilteredSessionSetting[index].selected = selected
    this.batchFilteredSessionSetting = [...batchFilteredSessionSetting]
    this.fetchBatchSession().catch(console.error)
  }

  @modelAction
  setFilteredSessionSettingAllStatus = (all: boolean) => {
    const { batchFilteredSessionSetting } = this
    batchFilteredSessionSetting.forEach((setting) => {
      setting.selected = all
    })
    this.batchFilteredSessionSetting = [...batchFilteredSessionSetting]
    this.fetchBatchSession().catch(console.error)
  }

  @modelAction
  setBatchQuota = (number: number) => {
    this.batchQuota = number
  }

  @modelAction
  setBatchItemList = (index: number, value: number) => {
    const { batchItemList } = this
    batchItemList[index].quota = value
    this.batchItemList = _.cloneDeep(batchItemList)
  }

  @modelAction
  resetBatchData = () => {
    this.batchEditLoading = false
    this.isBatchModalShow = false
    this.batchFilters = {
      productUuid: null,
      salesOptionUuid: null,
      startDate: null,
      endDate: null
    }
    this.batchSalesOptions = []
    this.batchFilteredSessionSetting = []
    this.batchQuotaType = null
    this.batchItemList = []
    this.batchQuota = null
  }

  @modelFlow
  fetchSalesOptionsAndSessionSetting = _async(function* (
    this: StockCalendar,
    productUuid: string,
    t
  ) {
    try {
      const { startDate, endDate } = this.batchFilters
      this.setBatchSalesOptions([])
      this.batchFilteredSessionSetting = []
      const [request] = Core.getInstance().api.get(`product/${productUuid}/salesOption`)
      const { data: salesOptions } = yield* _await(request)
      const [sessionRequest] = Core.getInstance().api.get(`product/${productUuid}/sessionSetting`)
      const { data: sessionSetting } = yield* _await(sessionRequest)

      const batchSalesOptions = salesOptions?.map((opt) => {
        const activeSessionSetting = sessionSetting?.filter(
          (setting) =>
            setting.salesOptionUuid === opt.uuid &&
            (moment(startDate).isBetween(
              setting.periodStartDate,
              setting.periodEndDate,
              undefined,
              '[]'
            ) ||
              moment(endDate).isBetween(
                setting.periodStartDate,
                setting.periodEndDate,
                undefined,
                '[]'
              ) ||
              moment(setting.periodStartDate).isBetween(startDate, endDate, undefined, '[]') ||
              moment(setting.periodEndDate).isBetween(startDate, endDate, undefined, '[]'))
        )

        return {
          label:
            activeSessionSetting.length > 0
              ? opt.title
              : `${opt.title}(${t('PUBLISH.STOCK_BATCH_NO_SESSION')})`,
          value: opt.uuid ?? '',
          sessionSetting: activeSessionSetting
        }
      })
      this.setBatchSalesOptions(batchSalesOptions)
    } catch (err) {
      console.error(err)
    } finally {
    }
  })

  @modelFlow
  fetchBatchSession = _async(function* (this: StockCalendar) {
    try {
      this.batchEditLoading = true
      let isAllDay = 0

      const sessionTimeArr = this.batchFilteredSessionSetting
        .filter((setting: Time) => setting.selected)
        .map((setting: Time) => {
          if (setting.mins >= 1440) {
            isAllDay = 1
          }
          return `${setting.startAt}~${setting.endAt}`
        })

      if (sessionTimeArr.length === 0) return

      const params = {
        ...this.batchFilters,
        sessionTime: sessionTimeArr.join(','),
        isAllDay
      }
      const [request] = Core.getInstance().api.get('/publish/session/batch', { params })
      const { data } = yield* _await(request)

      data?.itemList.forEach((item) => {
        item.quota = -1
      })
      this.batchQuota = data.salesOptionQuotaTotal ?? null
      this.batchItemList = data.itemList
    } catch (err) {
      console.error(err)
    } finally {
      this.batchEditLoading = false
    }
  })

  @modelFlow
  putBatchSession = _async(function* (this: StockCalendar, data, quotaCallback) {
    try {
      this.batchEditLoading = true
      let isAllDay = false
      const sessionTime = this.batchFilteredSessionSetting
        .filter((setting) => setting.selected)
        .map((setting) => {
          if (setting.days >= 1) {
            isAllDay = true
          }
          return `${setting.startAt}~${setting.endAt}`
        })

      if (sessionTime.length === 0) {
        quotaCallback('PUBLISH.STOCK_ERRORMSG_SESSION_TIME_REQUIRED')
        return
      }

      const body = {
        ...this.batchFilters,
        sessionTime,
        isAllDay,
        ...data
      }

      if (data.target === 'quota') {
        if (
          [
            SessionQuotaType.BySession,
            SessionQuotaType.BySessionAllocatedByIdentityOrItem
          ].includes(this.batchQuotaType) &&
          !this.batchQuota
        ) {
          quotaCallback('PUBLISH.STOCK_ERRORMSG_QUOTA_REQUIRED')
          return
        }

        body.quota = this.batchQuota
        if (
          [
            SessionQuotaType.BySalesOptionAllocatedByIdentityOrItem,
            SessionQuotaType.BySessionAllocatedByIdentityOrItem
          ].includes(this.batchQuotaType)
        ) {
          let errorMsg = null
          const editableItemList = this.batchItemList.filter((item) => item.allowEditSpecify)
          for (const key in editableItemList) {
            if (!Number.isInteger(editableItemList[key].quota)) {
              errorMsg = 'PUBLISH.STOCK_ERRORMSG_QUOTA_REQUIRED'
              break
            }

            if (
              editableItemList[key].quota !== -1 &&
              editableItemList[key].quota % editableItemList[key].seat !== 0
            ) {
              errorMsg = 'PUBLISH.SESSION_ERRORMSG_QUOTA_SETTING_MASS_PER_UNIT'
              break
            }
          }

          if (errorMsg) {
            quotaCallback(errorMsg)
            return
          }

          body.quotaSetting = editableItemList.map(({ type, targetUuid, quota }) => ({
            type,
            targetUuid,
            quota
          }))
        }
      }

      const [request] = Core.getInstance().api.put('publish/session/batch', body)
      yield* _await(request)

      this.setIsSessionSuccessTipShow(true)
      this.resetBatchData()
    } catch (err) {
      console.error(err)
    } finally {
      this.batchEditLoading = false
    }
  })
}

export default StockCalendar
