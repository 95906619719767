import { MaterialIcons } from '@rezio/components'
import { template, manipulator, palette } from '@rezio/res/theme'
import { TFunction } from 'i18next'
import _ from 'lodash'
import { inject } from 'mobx-react'
import React, { Component } from 'react'
import { type StyleProp, type ViewProps, Text, View } from 'react-native'

import { ThemedButton } from '../themedComponents'
import { ThemedTextInput } from './ThemedTextInput'
import type { BaseThemedListElement } from './types'

export type BaseThemedTagList = Omit<BaseThemedListElement, 'value'>[]

export const ThemedTextTag = inject('t')(
  class ThemedTextTag extends Component<
    {
      t: TFunction
      onValueChange: (value: BaseThemedTagList) => void
      onChange: (value: BaseThemedTagList) => void
      validators?: RegExp
      containerStyle?: StyleProp<ViewProps>
      placeholder?: string
    },
    {
      input: string
      errorMsg: string
      tagItems: BaseThemedTagList
    }
  > {
    constructor(props) {
      super(props)
      this.state = {
        input: '',
        errorMsg: '',
        tagItems: props.value || []
      }
    }

    handlePress = (onRemoveTargetValue) => {
      const { onValueChange, onChange, validators, t } = this.props
      const validateOnRemoveTargetValue = _.has(onRemoveTargetValue, 'uuid')
        ? onRemoveTargetValue
        : undefined
      const changeHandler = onValueChange || onChange
      const { tagItems: prevTagItems, input: currentInput } = this.state
      const isUniqueInput = _.isEmpty(validateOnRemoveTargetValue)
        ? _.isEmpty(_.find(prevTagItems, ['label', currentInput]))
        : true
      const isValidInput = _.isEmpty(validateOnRemoveTargetValue)
        ? _.isNil(validators) || validators.test(currentInput)
        : true
      if (!isValidInput || !isUniqueInput) {
        const errorEx = []
        !isValidInput && errorEx.push(t('PRODUCT.TEXT_TAG_PATTERN_ERROR'))
        !isUniqueInput && errorEx.push(t('PRODUCT.TEXT_TAG_DUPLICATE_ERROR'))
        return this.setState({ errorMsg: errorEx.join(t('COMMON.FIELD_SEPARATOR')) })
      } else {
        const itemsObj = _.isEmpty(validateOnRemoveTargetValue)
          ? _.concat(prevTagItems, { uuid: '', label: currentInput })
          : _.remove(prevTagItems, (n) =>
              _.isEmpty(onRemoveTargetValue.uuid)
                ? n.label !== onRemoveTargetValue.label
                : n.uuid !== onRemoveTargetValue.uuid
            )
        const input = _.isEmpty(validateOnRemoveTargetValue) && isUniqueInput ? '' : currentInput
        changeHandler?.(itemsObj)
        return this.setState({ tagItems: itemsObj, input, errorMsg: '' })
      }
    }

    render() {
      const { t, placeholder } = this.props
      const { tagItems, input, errorMsg } = this.state
      return (
        <View>
          {!_.isEmpty(errorMsg) && (
            <Text style={{ marginBottom: 5, color: palette.warning }}>{errorMsg}</Text>
          )}
          <View
            style={[
              template.rowContainer,
              { display: 'flex' },
              _.isEmpty(tagItems) ? {} : { marginBottom: 10 }
            ]}
          >
            <ThemedTextInput
              placeholder={placeholder || ''}
              style={{ marginRight: 10, flex: 1 }}
              value={input}
              onChangeText={(value) => this.setState({ input: _.trim(value) })}
              onSubmitEditing={this.handlePress}
            />
            <ThemedButton
              disabled={_.isEmpty(input)}
              style={{ width: 100, backgroundColor: palette.lightBrand, padding: 7 }}
              onPress={this.handlePress}
            >
              <MaterialIcons name='add' size={20} color={palette.white} />
              <Text style={{ color: palette.white, marginLeft: 4 }}>
                {t('PRODUCT.BUTTON_ADD_TEXT_TAG')}
              </Text>
            </ThemedButton>
          </View>
          <View
            style={[manipulator.container('row', 'flex-start', 'center'), { flexWrap: 'wrap' }]}
          >
            {tagItems.map((each, index) => {
              return (
                <ThemedButton
                  key={`${index}-${each.uuid}`}
                  onPress={() => this.handlePress(each)}
                  style={{
                    minWidth: 100,
                    marginRight: 10,
                    height: 40,
                    borderWidth: 1,
                    borderRadius: 5,
                    backgroundColor: 'transparent',
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginBottom: 5,
                    borderColor: palette.primary
                  }}
                >
                  <MaterialIcons
                    name='clear'
                    size={14}
                    color={palette.primary}
                    style={{ marginRight: 3 }}
                  />
                  <Text style={{ color: palette.primary }}>{each.label}</Text>
                </ThemedButton>
              )
            })}
          </View>
        </View>
      )
    }
  }
)
