import type { TOptions } from 'i18next'
import { Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import RenderHtml, { CustomBlockRenderer } from 'react-native-render-html'
import { HTMLAttributes, useEffect, useMemo, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { styled } from 'nativewind'
import clsx from 'clsx'
import { NoticeAreaBase } from './base'

// App先能顯示就好，樣式先放棄
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderers: Record<string, CustomBlockRenderer> = {
  span({ TDefaultRenderer, ...props }) {
    const Wrapped = styled(TDefaultRenderer)
    const classes = props.tnode.classes.filter(
      (c) => c.startsWith('text-') || c.includes('font-bold')
    )
    return <Wrapped tw={clsx(...classes)} {...props} />
  },
  div({ TDefaultRenderer, ...props }) {
    const Wrapped = styled(TDefaultRenderer)
    return <Wrapped tw={clsx(props.tnode.classes)} {...props} />
  }
}

const isUseWeb = Platform.OS === 'web'

export const NoticeArea = ({
  translationKey,
  translationOptions,
  hideWhenKeyNotExist,
  ...props
}: NoticeAreaProps) => {
  const [source, setSource] = useState<string>(null)
  const { i18n, t } = useTranslation()

  const rendered = useMemo(
    () => <NoticeAreaBase {...props} content={t(translationKey, translationOptions)} />,
    []
  )
  useEffect(() => {
    if (!isUseWeb) setSource(renderToString(rendered))
  }, [rendered])

  if (
    hideWhenKeyNotExist !== false &&
    !i18n.exists(translationKey, { fallbackLng: 'dev', ...translationOptions })
  ) {
    return null
  }

  if (isUseWeb) return rendered
  return (
    <RenderHtml
      source={{ html: source }}
      renderers={renderers}
      tagsStyles={{
        ul: { marginTop: 0, marginBottom: 0 },
        ol: { marginTop: 0, marginBottom: 0 },
        p: { marginTop: 0, marginBottom: 0 }
      }}
    />
  )
}

interface NoticeAreaProps extends HTMLAttributes<HTMLDivElement> {
  translationKey: string
  /** second parameter of TFunction */
  translationOptions?: TOptions
  /** default: true */
  hideWhenKeyNotExist?: boolean
}
