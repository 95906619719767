import { MaterialIcons, ThemedButton } from '@rezio/components'
import { DialogModal } from '@rezio/components/modal'
import { APIError, APIErrorCode, RuntimeError, WDFCError } from '@rezio/core/api'
import { getServiceHost } from '@rezio/core/config'
import { useRouter, useStores } from '@rezio/core/hooks'
import { openURL } from '@rezio/core/linking'
import { manipulator, palette, template } from '@rezio/res/theme'
import LottieView from '@rezio/unimodules/lottie'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { Platform, Text, View } from 'react-native'

export function ErrorIcon(props) {
  const lottieRef = useRef(null)
  useEffect(() => {
    setTimeout(() => lottieRef.current?.play(), 0)
  }, [])

  if (Platform.OS === 'android') {
    return <MaterialIcons name='error' size={100} color='rgb(244,86,87)' />
  }

  return (
    <LottieView
      loop={false}
      ref={lottieRef}
      style={[
        {
          width: 100,
          height: 100,
          backgroundColor: 'white'
        },
        props.style
      ]}
      source={require('@rezio/assets/lotties/error-icon.json')}
    />
  )
}

export const GlobalErrorDialog = observer((props) => {
  const { core, t } = useStores()
  const router = useRouter()
  const error = core.error
  const clearError = useCallback(() => {
    core.setError(undefined)
    error?.callback?.()
    router.query?.agent && openURL(`https://${getServiceHost('agent')}/`)
  }, [router.query?.agent, error])

  const errorContent = useMemo(() => {
    if (!error) {
      return <View />
    }
    let iconName = ''
    const iconColor = error?.iconConfig?.iconColor || palette.notice
    let title = t('COMMON.GENERAL_ERROR_TITLE')
    let customCodeMessage = ''
    let description = t('COMMON.GENERAL_ERROR_DESCRIPTION')

    if (error?.code) {
      customCodeMessage = error.code
    }
    if (error instanceof APIError) {
      switch (error.code) {
        case APIErrorCode.NotFound:
          title = t('COMMON.API_EXCEPTION_PAGE_NOT_FOUND')
          break
        default: {
          const context = error.message.toUpperCase()
          title = t('COMMON.API_EXCEPTION_TITLE', { context })
        }
      }
      const errorGroup = _.groupBy(error.data, ({ type, desc = '', detail }) =>
        type ? `type-${type}-${detail?.title || ''}-${desc}` : `desc-${desc}`
      )
      description = _.map(errorGroup, (eachGroup, key) => {
        return eachGroup[0]?.type
          ? t(`ERROR_MESSAGE.TYPE_${eachGroup[0].type.toUpperCase()}`, {
              detail: _.get(eachGroup[0], 'detail.title')
            })
          : eachGroup[0].desc
      }).join('\n')
    } else if (error instanceof RuntimeError) {
      iconName = error?.iconConfig?.iconName || iconName
      title = error?.title || title
      description = error.description
    } else if (error instanceof WDFCError) {
      title = t('COMMON.API_EXCEPTION_TITLE')
      if (_.get(error, 'config.method') && _.get(error, 'config.method') !== 'options') {
        description = error.message
      }
      description = t('COMMON.API_EXCEPTION_DESCRIPTION', { description })
    } else if (error.message === 'Network Error') {
      if (_.get(error, 'config.method') && _.get(error, 'config.method') !== 'options') {
        description = `[${error.config.method.toUpperCase() ?? ''}] ${error.config.url ?? ''}`
      }
      title = t('COMMON.API_EXCEPTION_TITLE')
      description = t('COMMON.API_EXCEPTION_DESCRIPTION', { description })
    }

    return (
      <View style={[manipulator.panel(20, 20, 20, 20), { alignItems: 'center' }]}>
        {_.isEmpty(iconName) ? (
          <ErrorIcon />
        ) : (
          <MaterialIcons name={iconName} size={100} color={iconColor} />
        )}
        <Text
          style={[
            template.bold,
            { color: palette.primary, fontSize: 24, paddingBottom: 10, paddingTop: 20 }
          ]}
        >
          {title}
        </Text>
        {!_.isEmpty(customCodeMessage) && (
          <Text
            style={[
              template.bold,
              { color: palette.primary, fontSize: 12, paddingBottom: 10, paddingTop: 0 }
            ]}
          >
            {customCodeMessage}
          </Text>
        )}
        <Text
          style={{
            maxHeight: 350,
            overflow: 'scroll',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {description}
        </Text>
      </View>
    )
  }, [error])

  return (
    <>
      <Fragment {...props} />
      {error ? (
        <DialogModal isVisible={error} containerStyle={{ justifyContent: 'center' }}>
          {errorContent}
          <ThemedButton onPress={clearError}>{t('FORM.BUTTON_OK')}</ThemedButton>
        </DialogModal>
      ) : null}
    </>
  )
})
