import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Text, View } from 'react-native'

import { DatePicker } from './datePicker'
import { DateRangePickerProps } from './datePicker.type'

const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
  const {
    disabled = false,
    value,
    onChange,
    maximumNights,
    startDateRule,
    endDateRule,
    idPrefix = ''
  } = props

  const [tempValue, setTempValue] = useState<[string, string]>(value ? [...value] : [null, null])
  const [forceEndDateOpen, setForceEndDateOpen] = useState<boolean>(false)

  useEffect(() => {
    setTempValue(value ? [...value] : [null, null])
  }, [value])

  const startDateDisabledRule = useCallback(
    (date: string) => {
      return startDateRule?.(date, tempValue) ?? false
    },
    [maximumNights, tempValue, startDateRule]
  )

  const endDateDisabledRule = useCallback(
    (date: string) => {
      const [startDate, endDate] = tempValue
      if (!_.isNil(startDate)) {
        const maximumDate = moment(startDate).add(maximumNights - 1, 'd')
        if (maximumNights && moment(date).isAfter(maximumDate)) return true
        if (moment(date).isBefore(moment(startDate))) return true
      }
      return endDateRule?.(date, [startDate, endDate]) ?? false
    },
    [maximumNights, tempValue, endDateRule]
  )

  const handleChangeStartDate = useCallback(
    (startDate: string) => {
      setTempValue([startDate, null])
      if (startDate) {
        setForceEndDateOpen(true)
        setTimeout(() => setForceEndDateOpen(false), 1000)
      }
    },
    [onChange, tempValue]
  )

  const handleChangeEndDate = useCallback(
    (endDate: string) => {
      const [startDate] = tempValue
      setTempValue([startDate, endDate])
      startDate && onChange([startDate, endDate])
    },
    [onChange, tempValue]
  )

  const handleNotSavedClose = useCallback(() => {
    setTempValue(value ? [...value] : [null, null])
  }, [value])

  return (
    <View className='flex-1 flex-row items-center justify-center'>
      <DatePicker
        isMultiple={false}
        allowClear={false}
        disabled={disabled}
        value={tempValue[0]}
        onChange={handleChangeStartDate}
        dateDisabledRule={startDateDisabledRule}
        isRangeStart
        webId={idPrefix ? `${idPrefix}StartDate` : 'startDate'}
        onClose={handleNotSavedClose}
      />
      <Text className='mx-2'>～</Text>
      <DatePicker
        isMultiple={false}
        allowClear={false}
        disabled={disabled || !tempValue[0]}
        value={tempValue[1]}
        onChange={handleChangeEndDate}
        dateDisabledRule={endDateDisabledRule}
        forceOpen={forceEndDateOpen}
        isRangeEnd
        webId={idPrefix ? `${idPrefix}EndDate` : 'endDate'}
        onClose={handleNotSavedClose}
      />
    </View>
  )
}

export { DateRangePicker }
