/* global FormData */
import { ChannelType } from '@rezio/components/channel/constants'
import { DeviceCustomPayWayKey } from '@rezio/components/device/status'
import { ReceiptType } from '@rezio/components/receipt/status'
import { APIErrorCode, RuntimeError } from '@rezio/core/api'
import { StoreContext } from '@rezio/core/hooks'
import { ROLE_TITLE } from '@rezio/core/permission'
import { userIdTrigger, userPropertyTrigger } from '@rezio/unimodules/tracking'
import {
  formatAscendingPlanOrder,
  formatDate,
  formatName,
  formatPayStatus,
  formatPriceTier,
  formatStatus,
  formatTime,
  formatTimezone,
  getSpecificPlan
} from '@rezio/utils/format'
import { formattedTicketActionLog } from '@rezio/utils/misc'
import {
  CancelPolicyRulePeriodUnit,
  CancelPolicyRuleUnit,
  CancelPolicyType,
  DefaultStockType,
  ExtraPriceType,
  ImageCroppingSizeType,
  OrderHistoryActionType,
  OrderMailType,
  OrderSaleFromType,
  OrderSource,
  OrderStatus,
  PayStatus,
  PaymentMethod,
  PricingPolicyType,
  ProductCategories,
  RedeemStatus,
  ResourceStockStatus,
  SessionStatus,
  SyncStrategyType,
  SystemIdentity,
  VoucherQRCodeType,
  VoucherType
} from '@rezio/utils/types'
import DataLoader from 'dataloader'
import _, { partition } from 'lodash'
import { compose, inRange, multiply, now, prop, propEq } from 'lodash/fp'
import { autorun, observable, values, when } from 'mobx'
import {
  applySnapshot,
  destroy,
  flow,
  getEnv,
  getParent,
  getRoot,
  getSnapshot,
  resolveIdentifier,
  types
} from 'mobx-state-tree'
import moment from 'moment'
import qs from 'query-string'
import { Dimensions, Platform } from 'react-native'

import { parseDestination } from '../routing/utils'
import { converge } from '../../utils/fp'
import auth, {
  PERMISSION_ACTION,
  PERMISSION_TITLE,
  PLAN_FEATURES,
  SUBSCRIPTION_COUNTRY,
  SUBSCRIPTION_PLAN_PERIOD,
  TIERS
} from '../auth'
import DataStore from '../data'
import { authNotify } from '../notification'
import { Page, extendPage } from './page'
import { ChannelStore } from './channel'

export { StoreContext }

export function formatAbnormalOrder(order) {
  if (!order) {
    return {}
  }

  const {
    uuid,
    orderNo,
    sourceOrderNo = '',
    productUuid = '',
    salesOptionUuid = '',
    sessionUuid = '',
    currencyUuid = '',
    channelOrderInfo = {},
    sessionStartTs
  } = order
  const sessionStartMoment = sessionStartTs
    ? moment(+sessionStartTs * 1000).utcOffset(channelOrderInfo?.datetime)
    : moment()
  return {
    ...order,
    uuid,
    orderNo: orderNo || `uuid-${uuid}`,
    sourceOrderNo,
    productUuid: productUuid || '',
    productName: '',
    salesOptionUuid: salesOptionUuid || '',
    salesOptionName: '',
    sessionUuid: sessionUuid || '',
    channelOrderInfo,
    currencyUuid,
    chargeAmount: channelOrderInfo?.chargeAmount,
    chargeCurrencyUuid: channelOrderInfo?.chargeCurrencyUuid,
    orderStatus: -1,
    sessionSettingUuid: '',
    payType: 1,
    purchaseQuantity: 0,
    purchaseContent: _.map(channelOrderInfo?.purchaseContent, (content, index) => ({
      quantity: content?.quantity,
      pricePolicyUuid: index.toString(),
      label: content?.purchaseItem,
      identityUuid: '',
      itemUuid: ''
    })),
    priceConfig: {
      PERSON: {},
      ITEM: _.reduce(
        channelOrderInfo?.purchaseContent,
        (result, content, index) => ({
          ...result,
          [index]: {
            currencyUuid: '',
            policy: [],
            policyLabel: `policy-${index}-policyLabel`,
            pricePolicyUuid: index,
            type: PricingPolicyType.ByItem
          }
        }),
        {}
      )
    },
    seatQuantity: 0,
    sessionStartDate: sessionStartMoment.format('YYYY-MM-DD'),
    sessionStartTime: sessionStartMoment.format('HH:mm'),
    sessionStartTs: sessionStartTs || moment().valueOf(),
    sessionEndDate: sessionStartMoment.format('YYYY-MM-DD'),
    sessionEndTime: sessionStartMoment.format('HH:mm'),
    extras: _.map(channelOrderInfo?.extras ?? [], (extra, index) => ({
      productExtraUuid: '',
      quantity: extra?.quantity
    }))
  }
}

const lazyReference = (Type) =>
  types.reference(Type, {
    get(identifier, parent) {
      return resolveIdentifier(Type, getRoot(parent), identifier)
    },
    set(value) {
      return value
    }
  })

const createViewGetters = (target, node, views, load) => {
  Object.defineProperties(
    target,
    views.reduce((res, { name, identifierName, type }) => {
      res[name] = {
        configurable: true,
        enumerable: true,
        get() {
          return resolveIdentifier(type, getRoot(node), node[identifierName])
        }
      }
      return res
    }, {})
  )
  return target
}

const safeNumber = types.snapshotProcessor(types.number, {
  preProcessor(snapshot) {
    return parseFloat(snapshot)
  }
})

const Image = types.model('Image', {
  storeMediaUsageUuid: types.identifier,
  storeMediaUuid: types.maybe(types.string),
  url: types.string,
  sort: types.maybeNull(types.number),
  size: types.maybeNull(types.number),
  usageType: types.maybeNull(types.number),
  languageUuids: types.maybeNull(types.array(types.string)),
  clientFileName: types.maybeNull(types.string)
})

const Extra = types
  .model('Extra', {
    uuid: types.identifier,
    imageInfo: types.union(
      types.string,
      types.maybe(types.safeReference(Image, { acceptsUndefined: false }))
    ),
    imageUrl: types.maybe(types.string),
    label: types.string,
    price: types.union(types.string, types.number),
    priceType: types.union(types.string, types.number),
    currencyUuid: types.string,
    description: types.string,
    quantity: types.number,
    bindingProductCnt: types.optional(types.number, 0),
    bindingProductUuids: types.array(types.string),
    productCount: types.optional(types.number, 0),
    storeUuid: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    updatedAt: types.maybe(types.string),
    isAvailable: true
  })
  .views((self) => ({
    get isPerOrder() {
      return parseInt(self.priceType) === ExtraPriceType.PerOrder
    }
  }))
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    priceType: `${snapshot.priceType}`
  }))

const ExtraStore = types
  .model('ExtraStore', {
    extras: types.map(Extra),
    page: types.optional(Page, { source: 'extras' }),
    isLoading: true
  })
  .actions((self) => {
    const { api } = getEnv(self)

    function markLoading(loading) {
      self.isLoading = loading
    }

    const extraLoader = new DataLoader(
      async (keys) => {
        const result = await api.get('extra', { params: { id: keys.join(',') } })[0]
        return keys.map((key) => {
          return result.data.list.find((item) => item.uuid === key)
        })
      },
      {
        cacheKeyFn: (key) => `${key}-${getEnv(self).core.lang}`
      }
    )

    function setExtra({ imageInfo, ...extra }) {
      if (!_.isEmpty(imageInfo)) {
        getParent(self).imageStore.getCategory('extra').setImage(imageInfo)
        extra.imageInfo = imageInfo.storeMediaUsageUuid
      }
      self.extras.put(extra)
    }

    const loadExtraPage = flow(function* loadExtraPage(params = {}) {
      const { page = 1, label = '', currency, productUuid } = params
      markLoading(true)
      const result = yield api.get(`extra/${page}`, { params: { label, currency, productUuid } })[0]
      self.updatePage(result.data)
      markLoading(false)
    })

    const loadExtra = flow(function* loadExtra(uuid) {
      markLoading(true)
      const result = yield extraLoader.load(uuid)
      setExtra(result)
      markLoading(false)
    })

    const loadExtras = flow(function* loadExtras(uuids = []) {
      markLoading(true)
      const result = yield extraLoader.loadMany(uuids)
      _.map(result, (extra) => setExtra(extra))
      markLoading(false)
    })

    const createExtra = flow(function* createExtra(extra) {
      try {
        extra = Object.assign({ quantity: -1 }, extra)
        const result = yield api.post('extra', extra)[0]
        yield loadExtra(result.data.uuid)
      } catch (e) {
        console.error(e)
      }
    })

    const updateExtra = flow(function* updateExtra(extra) {
      extra = Object.assign({ quantity: -1 }, extra)
      yield api.put(`extra/${extra.uuid}`, extra)[0]
      extraLoader.clear(extra.uuid)
      yield loadExtra(extra.uuid)
    })

    const deleteExtra = flow(function* deleteExtra(uuid) {
      const result = yield api.delete(`extra/${uuid}`)[0]
      if (result.errorCode === APIErrorCode.Success) {
        extraLoader.clear(uuid)
        destroy(self.extras.get(uuid))
      }
    })

    return {
      loadExtraPage,
      setExtra,
      loadExtras,
      loadExtra,
      updateExtra,
      createExtra,
      deleteExtra
    }
  })
  .extend((self) => {
    const page = extendPage(self, 'extras', 'setExtra')
    return page
  })

const SessionPricePolicy = types.model('SessionPricePolicy', {
  identityUuid: types.string,
  identityTitle: types.maybe(types.string),
  range: types.array(types.maybeNull(types.number)),
  isGroup: types.boolean,
  seat: types.maybe(types.number),
  quantityWeight: types.maybe(types.number),
  price: types.number,
  currencyUuid: types.string,
  pricePolicyUuid: types.string,
  pricePolicyLabel: types.string
})

const SessionPriceItem = types.model('SessionPriceItem', {
  sessionPriceItemUuid: types.string,
  currencyUuid: types.string,
  policyLabel: types.string,
  policy: types.array(
    types.model('SessionPriceItemPolicy', {
      label: types.string,
      price: types.number,
      itemUuid: types.maybeNull(types.string)
    })
  ),
  unit: types.maybeNull(types.string) // 依項目的單位 uuid，目前 SessionStore 才有
})

const PriceConfig = types
  .model({
    PERSON: types.map(SessionPricePolicy),
    ITEM: types.map(SessionPriceItem)
  })
  .views((self) => ({
    get flatten() {
      return [
        ...[...self.PERSON.values()].map((personPrice) => {
          return {
            type: PricingPolicyType.ByPerson,
            content: {
              ...personPrice,
              label: personPrice.identityTitle,
              uuid: personPrice.pricePolicyUuid
            }
          }
        }),
        ..._.reduce(
          [...self.ITEM.values()],
          (res, { policy, ...itemPrice }) => {
            res = [
              ...res,
              ...policy.map((content) => ({
                type: PricingPolicyType.ByItem,
                content: {
                  uuid: itemPrice.sessionPriceItemUuid,
                  ...itemPrice,
                  ...content
                }
              }))
            ]
            return res
          },
          []
        )
      ]
    }
  }))
  .preProcessSnapshot((snapshot) => {
    return _.mapValues(snapshot, (by, type) => {
      by = _.isEmpty(by) ? {} : by
      if (type === PricingPolicyType.ByPerson) {
        return _.mapValues(by, (sessionPricePolicy, identityUuid) => {
          return { identityUuid, ...sessionPricePolicy }
        })
      } else {
        return _.mapValues(by, (sessionPricePolicy, sessionPriceItemUuid) => {
          return { sessionPriceItemUuid, ...sessionPricePolicy }
        })
      }
    })
  })

const PricingPolicyTypeList = types.enumeration(
  'PricingPolicyTypeList',
  Object.values(PricingPolicyType)
)
const PricePolicyPrice = types
  .model({
    type: PricingPolicyTypeList,
    uuid: types.string,
    _label: types.maybeNull(types.string),
    cost: types.maybeNull(types.number),
    originalPrice: types.maybeNull(types.number),
    price: types.maybeNull(types.number),
    seat: types.maybeNull(types.number),
    range: types.maybeNull(types.array(types.maybeNull(types.number))),
    isGroup: types.maybeNull(types.boolean),
    isConstraint: types.maybeNull(types.boolean),
    spec: types.maybeNull(types.frozen())
  })
  .views((self) => ({
    get label() {
      return (
        self._label ||
        getEnv(self).core.getOptionContent('identity', self.uuid, { title: '' }).title
      )
    }
  }))
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    _label: snapshot.label
  }))

const PricePolicy = types
  .model({
    policyUuid: types.string,
    policyLabel: types.string,
    currencyUuid: types.string,
    prices: types.array(PricePolicyPrice)
  })
  .preProcessSnapshot((snapshot) => {
    if (_.isArray(snapshot)) {
      snapshot = {
        policyUuid: snapshot[0].uuid,
        policyLabel: snapshot[0].policyLabel,
        currencyUuid: snapshot[0].currencyUuid,
        prices: snapshot.map((price) => ({
          ...price,
          uuid:
            price.type === PricingPolicyType.ByPerson
              ? price.identityUuid
              : price.itemUuid || price.label
        }))
      }
    }
    return snapshot
  })

const SalesOption = types
  .model('SalesOption', {
    uuid: types.identifier,
    availability: types.maybeNull(types.number),
    availabilityType: types.maybeNull(types.number),
    booked: types.maybeNull(types.number),
    brief: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    description: types.maybeNull(types.maybeNull(types.string)),
    maxQuotaPerBooking: types.maybeNull(types.number),
    minQuotaPerBooking: types.maybeNull(types.number),
    quotaType: types.maybeNull(types.number),
    quotaSetting: types.maybeNull(types.frozen()),
    plural: types.maybeNull(types.string),
    pricePolicyConfig: types.maybeNull(
      types.array(
        types.model('SalesOptionPricePolicyBinding', {
          period: types.frozen(),
          pricePolicy: PricePolicy,
          spec: types.maybeNull(types.frozen())
        })
      )
    ),
    productUuid: types.maybeNull(types.string),
    publishStatus: types.maybeNull(types.number),
    // quantityLimit: {PERSON: {175087ec-03a7-4aa4-a288-29f65bb8966e: {min: 0, max: 10},…}, ITEM: []}
    ratingCount: types.maybeNull(types.number),
    saleCount: types.maybeNull(types.number),
    singular: types.maybeNull(types.string),
    sort: types.maybeNull(types.number),
    status: types.maybeNull(types.number),
    storeUuid: types.maybeNull(types.string), // types.reference(Store),
    timezoneTsDiff: types.maybeNull(types.number),
    timezoneUuid: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    validity: types.maybeNull(types.number),
    voucherRedeemptionAddress: types.maybeNull(types.number),
    voucherRequirePrint: types.maybeNull(types.number),
    voucherUse: types.maybeNull(types.number),
    exchangeValidity: types.maybeNull(
      types.model({
        type: types.string,
        date: types.maybeNull(types.string),
        day: types.maybeNull(types.union(types.string, types.number)),
        startDate: types.maybeNull(types.string)
      })
    )
  })
  .views((self) => ({
    get fullTitle() {
      return `${'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.charAt(self.sort - 1)}. ${
        self.title
      }`
    },
    get pricePolicySpec() {
      return _.flatMap(self.pricePolicyConfig, ({ spec, pricePolicy }) =>
        _.map(spec, (eachSpec) => ({ ...eachSpec, policyUuid: _.get(pricePolicy, '0.uuid') }))
      )
    },
    get specAllowedPolicy() {
      // {
      //   [policySpecUuid]: {
      //     [policySpecInfoUuid]: ...allowedPolicyUuid
      //   }
      // }
      return _.reduce(
        self.pricePolicyConfig,
        (res, { spec, pricePolicy }) => ({
          ...res,
          ..._.reduce(
            pricePolicy.prices,
            (pricesResult, { spec: policySpec, uuid: policyUuid }) => ({
              ...pricesResult,
              ..._.reduce(
                policySpec,
                (policySpecResult, policySpecInfoUuid, policySpecUuid) => ({
                  ...policySpecResult,
                  [policySpecUuid]: {
                    ..._.get(pricesResult, policySpecUuid, []),
                    [policySpecInfoUuid]: _.union(
                      _.get(pricesResult, `${policySpecUuid}.${policySpecInfoUuid}`, []),
                      [policyUuid]
                    )
                  }
                }),
                {}
              )
            }),
            {}
          )
        }),
        {}
      )
    }
  }))
  .actions((self) => {
    const togglePublishStatus = flow(function* () {
      const { api } = getEnv(self)
      const { productUuid, publishStatus, uuid } = self
      const currentPublishStatus = publishStatus === 1 ? 0 : 1
      const result = yield api.put(`/product/${productUuid}/${uuid}/publishStatus`, {
        publishStatus: currentPublishStatus
      })[0]
      if (result.errorCode === APIErrorCode.Success) {
        self.publishStatus = currentPublishStatus
      }
    })

    return {
      togglePublishStatus
    }
  })

const FeeAndTaxRule = types.model('FeeAndTaxRule', {
  uuid: types.string,
  storeUuid: types.string,
  label: types.string,
  type: types.string,
  chargeMode: types.string,
  charge: types.string,
  createdAt: types.string,
  unit: types.string,
  currencyUuid: types.string,
  included: types.boolean,
  updatedAt: types.string
})

const CancelPolicyTypeList = types.enumeration(
  'CancelPolicyTypeList',
  Object.values(CancelPolicyType)
)
const CancelPolicyRulePeriodUnitList = types.enumeration(
  'CancelPolicyRulePeriodUnitList',
  Object.values(CancelPolicyRulePeriodUnit)
)
const CancelPolicyRuleUnitList = types.enumeration(
  'CancelPolicyRuleUnitList',
  Object.values(CancelPolicyRuleUnit)
)
const CancelPolicyRule = types.optional(
  types.array(
    types.model('CancelPolicyRule', {
      charge: types.union(types.number, types.string),
      from: types.maybeNull(types.number),
      periodUnit: types.optional(CancelPolicyRulePeriodUnitList, CancelPolicyRulePeriodUnit.Day),
      to: types.maybeNull(types.number),
      unit: types.optional(CancelPolicyRuleUnitList, CancelPolicyRuleUnit.Percentage)
    })
  ),
  []
)
const CancelPolicy = types.model('CancelPolicy', {
  label: types.string,
  currencyUuid: types.maybeNull(types.string),
  type: types.optional(CancelPolicyTypeList, CancelPolicyType.Default),
  description: types.maybeNull(types.string),
  policyRule: CancelPolicyRule
})

const Device = types.model('Device', {
  uuid: types.identifier,
  deviceId: types.string,
  accountName: types.string,
  model: types.string,
  deviceType: types.string
})

const Accountant = types.model('Accountant', {
  uuid: types.identifier,
  firstName: types.string,
  lastName: types.string
})

const SettleAccountLog = types.model('SettleAccountLog', {
  uuid: types.identifier,
  accountName: types.string,
  deviceId: types.string,
  deviceType: types.string,
  startDate: types.number,
  endDate: types.number,
  accountant: types.string,
  paymentAmount: types.number,
  refundedAmount: types.number,
  currencyUuid: types.string
})

const OperatorRecord = types.model('OperatorRecord', {
  uuid: types.identifier,
  deviceId: types.string,
  actionTs: types.number,
  deviceName: types.string,
  accountName: types.string,
  email: types.string
})

const Cashier = types.model('Cashier', {
  uuid: types.identifier,
  firstName: types.string,
  lastName: types.string
})

const SettleAccountLogs = types
  .model('SettleAccountLogs', {
    logs: types.map(SettleAccountLog),
    page: types.optional(Page, { source: 'logs' })
  })
  .actions((self) => {
    const { api } = getEnv(self)

    function setLog(log) {
      self.logs.put(
        Object.assign({}, self.logs.has(log.uuid) && getSnapshot(self.logs.get(log.uuid)), log)
      )
    }

    const loadLogPage = flow(function* loadLogPage(params = {}) {
      const { page = 1, dateRange, accountantUuids, deviceUuids, languageUuid } = params

      try {
        const result = yield api.get(`device/report/preview/${page}`, {
          params: {
            ...(dateRange && { searchStartDate: dateRange[0], searchEndDate: dateRange[1] }),
            ...(accountantUuids && { accountantUuids }),
            ...(deviceUuids && { deviceUuids }),
            languageUuid
          }
        })[0]
        self.updatePage(result.data)
      } catch (error) {
        if (error.code === 404) {
          const { core } = getEnv(self)
          core.setRuntimeError(core.i18n.t('COMMON.API_EXCEPTION_PAGE_NOT_FOUND'))
        }
      }
    })

    return {
      setLog,
      loadLogPage
    }
  })
  .extend((self) => {
    const page = extendPage(self, 'logs', 'setLog')
    return page
  })

const OperatorRecords = types
  .model('OperatorRecords', {
    records: types.map(OperatorRecord),
    page: types.optional(Page, { source: 'records' })
  })
  .actions((self) => {
    const { api } = getEnv(self)

    function setRecord(record) {
      self.records.put(
        Object.assign(
          {},
          self.records.has(record.uuid) && getSnapshot(self.records.get(record.uuid)),
          record
        )
      )
    }

    const loadRecordPage = flow(function* loadRecordPage(params = {}) {
      const { page = 1, dateRange, accountUuids, deviceIds, num = 20 } = params

      try {
        const result = yield api.get(`device/deviceLog/${page}`, {
          params: {
            ...(dateRange && { startDate: dateRange[0], endDate: dateRange[1] }),
            ...(accountUuids && { accountUuids }),
            ...(deviceIds && { deviceIds }),
            num
          }
        })[0]
        self.updatePage(result.data)
      } catch (error) {
        if (error.code === 404) {
          const { core } = getEnv(self)
          core.setRuntimeError(core.i18n.t('COMMON.API_EXCEPTION_PAGE_NOT_FOUND'))
        }
      }
    })

    return {
      setRecord,
      loadRecordPage
    }
  })
  .extend((self) => {
    const page = extendPage(self, 'records', 'setRecord')
    return page
  })

const DeviceStore = types
  .model('DeviceStore', {
    devices: types.map(Device),
    deviceType: types.array(types.string),
    accountants: types.map(Accountant),
    cashiers: types.map(Cashier),
    page: types.optional(Page, { source: 'devices' }),
    settleAccountLogs: types.optional(SettleAccountLogs, {}),
    operatorRecords: types.optional(OperatorRecords, {})
  })
  .actions((self) => {
    const { api } = getEnv(self)

    function setDevice(device) {
      self.devices.put(
        Object.assign(
          {},
          self.devices.has(device.uuid) && getSnapshot(self.devices.get(device.uuid)),
          device
        )
      )
    }

    function setDeviceType(types) {
      self.deviceType = types
    }

    function setAccountant(accountant) {
      self.accountants.put(
        Object.assign(
          {},
          self.accountants.has(accountant.uuid) &&
            getSnapshot(self.accountants.get(accountant.uuid)),
          accountant
        )
      )
    }

    function setCashier(cashier) {
      self.cashiers.put(
        Object.assign(
          {},
          self.cashiers.has(cashier.uuid) && getSnapshot(self.cashiers.get(cashier.uuid)),
          cashier
        )
      )
    }

    const loadDevicePage = flow(function* loadDevicePage(params = {}) {
      const { text, deviceType } = params
      try {
        const result = yield api.get('/device/list', {
          params: {
            ...(text && { text }),
            ...(deviceType && { deviceType })
          }
        })[0]
        const formatResult = _.sortBy(result.data, ['deviceType', 'deviceId'])

        _.isEmpty(params) && setDeviceType(_.uniq(_.map(result.data, 'deviceType')))
        self.updatePage({ list: formatResult })
      } catch (error) {
        if (error.code === 404) {
          const { core } = getEnv(self)
          core.setRuntimeError(core.i18n.t('COMMON.API_EXCEPTION_PAGE_NOT_FOUND'))
        }
      }
    })

    const loadAccountants = flow(function* loadAccountants() {
      try {
        const result = yield api.get('/device/accountant')[0]
        yield Promise.all(_.map(result.data, setAccountant))
      } catch (error) {
        if (error.code === 404) {
          const { core } = getEnv(self)
          core.setRuntimeError(core.i18n.t('COMMON.API_EXCEPTION_PAGE_NOT_FOUND'))
        }
      }
    })

    const loadCashier = flow(function* loadCashier() {
      try {
        const result = yield api.get('/device/cashier')[0]
        yield Promise.all(_.map(result.data, setCashier))
      } catch (error) {
        if (error.code === 404) {
          const { core } = getEnv(self)
          core.setRuntimeError(core.i18n.t('COMMON.API_EXCEPTION_PAGE_NOT_FOUND'))
        }
      }
    })

    const updateDeviceInfo = flow(function* updateDeviceInfo(uuid, accountName = '') {
      try {
        yield api.put(`device/${uuid}/info`, { accountName })[0]
        yield loadDevicePage()
      } catch (error) {
        if (error.code === 404) {
          const { core } = getEnv(self)
          core.setRuntimeError(core.i18n.t('COMMON.API_EXCEPTION_PAGE_NOT_FOUND'))
        }
      }
    })

    return {
      setDevice,
      loadAccountants,
      loadCashier,
      loadDevicePage,
      updateDeviceInfo
    }
  })
  .extend((self) => {
    const page = extendPage(self, 'devices', 'setDevice')
    return page
  })

const Product = types
  .model('Product', {
    uuid: types.identifier,
    storeUuid: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    alias: types.maybeNull(types.string),
    brief: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    highlights: types.maybeNull(types.string),
    addtionalInfo: types.maybeNull(types.string),
    warnings: types.maybeNull(types.string),
    countryUuid: types.maybeNull(types.string),
    cityUuid: types.maybeNull(types.string),
    latitude: types.maybeNull(types.number),
    longitude: types.maybeNull(types.number),
    geoHash: types.maybeNull(types.string),
    currencyUuid: types.maybeNull(types.string),
    priceStartAt: types.maybeNull(types.string),
    productType: types.maybe(types.number),
    productStatus: types.maybeNull(types.union(types.string, types.number)),
    publishStatus: types.maybe(types.number),
    publishFrom: types.maybeNull(types.string),
    publishTo: types.maybeNull(types.string),
    categoryUuid: types.maybeNull(types.string),
    viewCount: types.maybe(types.number),
    averageScore: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    languageUuid: types.maybeNull(types.string),
    timeszoneUuid: types.maybeNull(types.string),
    timezoneTsDiff: types.maybeNull(types.number),
    productCode: types.maybeNull(types.string),
    saleCount: types.maybe(types.number),
    ratingCount: types.maybeNull(types.number),
    createdAt: types.maybeNull(types.string),
    guideLanguages: types.maybeNull(types.array(types.string)),
    audioHeadsetLanguages: types.maybeNull(types.array(types.string)),
    translationLanguages: types.maybeNull(types.array(types.string)),
    writtenLanguages: types.maybeNull(types.array(types.string)),
    pickupUuid: types.maybeNull(types.string),
    pickup: types.maybeNull(types.frozen()),
    regionUuid: types.maybeNull(types.string),
    stateUuid: types.maybeNull(types.string),
    depositPolicyUuid: types.maybeNull(types.string),
    cancelPolicyUuid: types.maybeNull(types.string),
    bookingInfoUuid: types.maybeNull(types.string),
    bookingInfo: types.maybe(types.frozen()),
    voucherType: types.maybeNull(types.number),
    voucherSetting: types.maybeNull(types.frozen()),
    longestBookingAcceptableDay: types.maybeNull(types.number),
    saleable: types.maybeNull(types.frozen()),
    confirmType: types.maybeNull(types.number),
    confirmHour: types.maybeNull(types.number),
    confirmMode: types.maybeNull(types.number),
    acceptPayType: types.maybeNull(types.frozen()),
    _salesOptions: types.map(SalesOption),
    feeNdTaxUuids: types.maybeNull(types.array(types.string)),
    _feeNdTaxs: types.optional(types.array(FeeAndTaxRule), []),
    extraUuids: types.maybeNull(types.array(types.string)),
    _extraLoaded: false,
    cover: types.array(types.safeReference(Image, { acceptsUndefined: false })),
    image: types.array(types.safeReference(Image, { acceptsUndefined: false })),
    video: types.array(
      types.model('Video', {
        url: types.string,
        languageList: types.array(types.string)
      })
    )
  })
  .views((self) => ({
    get extras() {
      if (!self.extraUuids) {
        return []
      }
      const result = _.compact(
        self.extraUuids.map((uuid) => {
          return resolveIdentifier(Extra, getRoot(self), uuid)
        })
      )
      if (!self._extraLoaded && self.extraUuids.length !== result.length) {
        setTimeout(() => {
          self.loadExtras()
        }, 0)
      }
      return result
    },
    get fullTitle() {
      return `${_.isEmpty(self.productCode) ? '' : `[${self.productCode}]`} ${self.title}`
    },
    get salesOptions() {
      self._salesOptions.size === 0 && self.loadSalesOptions()
      return self._salesOptions
    },
    get feeNdTaxs() {
      if (!self.feeNdTaxUuids) {
        return []
      }
      self.feeNdTaxUuids.length !== self._feeNdTaxs.length && self.loadFeeAndTaxRules()
      return self._feeNdTaxs
    }
  }))
  .actions((self) => {
    const { api, core } = getEnv(self)

    function setSalesOption(salesOption) {
      self._salesOptions.put(
        Object.assign(
          {},
          self._salesOptions.has(salesOption.uuid) &&
            getSnapshot(self._salesOptions.get(salesOption.uuid)),
          salesOption
        )
      )
    }

    const loadExtras = flow(function* loadExtras() {
      if (!self._extraLoaded && self.extraUuids) {
        yield getRoot(self).extraStore.loadExtras(self.extraUuids)
        self._extraLoaded = true
      }
    })

    const productSalesOptionLoader = new DataLoader(
      async (keys) => {
        const products = await Promise.all(
          keys.map((id) => {
            return api.get(`product/${id}/salesOption`)[0]
          })
        )
        return products
      },
      {
        cacheKeyFn: (key) => `${key}-${core.lang}`
      }
    )

    const loadSalesOptions = flow(function* loadSalesOptions(reset = false) {
      if (reset) {
        self._salesOptions.clear()
        productSalesOptionLoader.clearAll()
      }
      const result = yield productSalesOptionLoader.load(self.uuid)
      yield Promise.all(_.map(result.data, setSalesOption))
    })

    const loadFeeAndTaxRules = flow(function* loadFeeAndTaxRules() {
      const results = yield Promise.all(
        self.feeNdTaxUuids.map((uuid) => {
          return api.get(`/feeNdTax/${uuid}`)[0]
        })
      )
      self._feeNdTaxs.replace(results.map((result) => result.data))
    })

    const loadBookingInfo = flow(function* loadBookingInfo() {
      const result = yield api.get(`/bookingInfo/${self.bookingInfoUuid}`)[0]
      self._bookingInfo = result.data
    })

    return {
      setSalesOption,
      loadSalesOptions,
      loadFeeAndTaxRules,
      loadExtras,
      loadBookingInfo
    }
  })

const Ticket = types.model('Ticket', {
  uuid: types.identifier,
  title: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  exchangeValidity: types.maybeNull(types.frozen()),
  languageUuid: types.maybeNull(types.string),
  timezoneUuid: types.maybeNull(types.string),
  isUnderSafetyStock: types.maybeNull(types.optional(types.boolean, true)),
  quotaTotal: types.maybeNull(types.number),
  quotaAvailable: types.maybeNull(types.number),
  quotaBooked: types.maybeNull(types.number),
  redeemTimesLimit: types.maybeNull(types.number),
  useRedeemPermission: types.maybeNull(types.boolean),
  redeemPermissionUuidList: types.maybeNull(types.array(types.string)),
  note: types.maybeNull(types.string),
  safetyStock: types.maybeNull(types.frozen()),
  editable: types.maybeNull(types.optional(types.boolean, true)),
  bindingSalesOptionCnt: types.maybeNull(types.optional(types.number, 0)),
  actionLog: types.maybeNull(types.frozen()),
  bindingSalesOptionList: types.maybeNull(types.frozen())
})

const TicketStore = types
  .model('TicketStore', {
    tickets: types.map(Ticket),
    page: types.optional(Page, { source: 'tickets' }),
    isLoading: false
  })
  .actions((self) => {
    const { api } = getEnv(self)

    function markLoading(loading) {
      self.isLoading = loading
    }

    function setTicket(ticket) {
      self.tickets.put(
        Object.assign(
          {},
          self.tickets.has(ticket.uuid) && getSnapshot(self.tickets.get(ticket.uuid)),
          ticket
        )
      )
    }

    const loadTicket = flow(function* loadTicket(ticketUuid) {
      const { core } = getEnv(self)
      try {
        const result = yield api.get(`ticket/${ticketUuid}`)[0]
        const formattedActionLog = formattedTicketActionLog(result.data.actionLog)
        result.data.actionLog = formattedActionLog
        self.setTicket(result.data)
        return self.tickets.get(ticketUuid)
      } catch (error) {
        if (error.code === 404) {
          core.setRuntimeError(core.i18n.t('TICKET.API_EXCEPTION_ORDER_NOT_FOUND'), 'validation')
        }
      }
    })

    const loadTicketPage = flow(function* loadTicketPage(params = {}) {
      markLoading(true)
      const { page = 1, num = 20, text, ...rest } = params
      const result = yield api.get(`ticket/${page}`, {
        params: { num, text: text?.trim(), ...rest }
      })[0]
      yield Promise.all(_.map(result.data.list, setTicket))
      self.updatePage(result.data)
      markLoading(false)
      return result.data.list
    })

    const createTicket = flow(function* createTicket(ticket) {
      const result = yield api.post('ticket', { ...ticket })[0]
      return result.data
    })

    const updateTicket = flow(function* updateTicket(ticket) {
      yield api.put(`ticket/${ticket.uuid}`, { ...ticket })[0]
      yield loadTicket(ticket.uuid)
    })

    const deleteTicket = flow(function* deleteTicket(uuid) {
      const result = yield api.delete(`ticket/${uuid}`)[0]
      if (result.errorCode === APIErrorCode.Success) {
        destroy(self.tickets.get(uuid))
      }
    })

    return {
      setTicket,
      createTicket,
      updateTicket,
      deleteTicket,
      loadTicket,
      loadTicketPage
    }
  })
  .extend((self) => {
    const page = extendPage(self, 'tickets', 'setTicket')
    return page
  })

const ProductStore = types
  .model('ProductStore', {
    products: types.map(Product),
    filteredProductUuids: types.array(types.maybeNull(types.string)),
    filteresMapping: types.map(
      types.model({
        filter: types.identifier,
        uuids: types.array(types.maybeNull(types.string))
      })
    ),
    page: types.optional(Page, { source: 'products' }),
    isLoading: false
  })
  .views((self) => ({
    get filteredProducts() {
      return self.filteredProductUuids.map((uuid) => self.products.get(uuid))
    }
  }))
  .actions((self) => {
    const { api, core } = getEnv(self)

    function markLoading(loading) {
      self.isLoading = loading
    }

    function setProduct(product) {
      self.products.put(
        Object.assign(
          {},
          self.products.has(product.uuid) && getSnapshot(self.products.get(product.uuid)),
          product
        )
      )
    }

    const productLoader = new DataLoader(
      async (keys) => {
        const products = await Promise.all(
          keys.map((id) => {
            return api.get(`product/${id}`)[0]
          })
        )
        return products
      },
      {
        cacheKeyFn: (key) => `${key}-${core.lang}`
      }
    )

    const loadProduct = flow(function* loadProduct(uuid, reload) {
      if (!uuid) {
        return
      }
      markLoading(true)
      if (reload) {
        productLoader.clear(`${uuid}-${core.lang}`)
      }
      const result = yield productLoader.load(uuid)
      setProduct(result.data)
      markLoading(false)
    })

    const loadAllProducts = flow(function* loadAllProducts(status = '', skipIsLoading = false) {
      yield loadProducts({ status, forceRefresh: true }, skipIsLoading)
    })

    const formatProductFilter = (filters) => {
      return _.reduce(
        filters,
        (result, value, key) =>
          value != null && value !== ''
            ? {
                ...result,
                [key]: value
              }
            : result,
        {}
      )
    }

    const loadProducts = flow(function* loadProducts(params = {}, skipIsLoading = false) {
      if (self.isLoading && !skipIsLoading) {
        return
      }

      markLoading(true)
      self.filteredProductUuids.clear()
      const { page = 1, forceRefresh = false, ...restParams } = params
      const formatedParams = formatProductFilter({ num: 999, ...restParams }) // 提高到 999，未來可能要批次全拿或是別的解法
      const filterText = qs.stringify(formatedParams)

      if (!forceRefresh && self.filteresMapping.has(`id-${filterText}`)) {
        // 搜過的就直接拿紀錄的
        self.filteredProductUuids = [...self.filteresMapping.get(`id-${filterText}`).uuids.values()]
      } else {
        const result = yield api.get(`product/${page}?${filterText}`)[0]
        yield Promise.all(_.map(result.data.list, setProduct))
        const productUuids = Object.keys(result.data.list)
        self.filteredProductUuids = productUuids

        if (forceRefresh) {
          self.filteresMapping.clear()
        }
        self.filteresMapping.put({
          filter: `id-${filterText}`,
          uuids: productUuids
        })
        self.updatePage(result.data)
      }
      markLoading(false)
    })

    const loadProductMedia = flow(function* loadProductMedia(uuid) {
      markLoading(true)
      const result = yield api.get(`/product/${uuid}/media`)[0]
      setProduct(Object.assign({ uuid, ...result.data }, self.products.get(uuid)))
      markLoading(false)
    })

    const updateProduct = flow(function* updateProduct({ uuid, extraUuid, video, cover }) {
      const updates = []
      if (extraUuid) {
        updates.push(api.put(`/product/${uuid}/extra`, { extraUuid })[0])
      }
      if (video) {
        updates.push(api.put(`/product/${uuid}/video`, { video })[0])
      }
      if (cover) {
        updates.push(api.put(`/product/${uuid}/cover`, { cover })[0])
      }
      productLoader.clear(uuid)
      yield Promise.all(updates)
    })

    return {
      setProduct,
      updateProduct,
      loadProduct,
      loadProducts,
      loadAllProducts,
      loadProductMedia
    }
  })
  .extend((self) => {
    const page = extendPage(self, 'products', 'setProduct')
    return page
  })

const ImageCategory = types
  .model('ImageCategory', {
    images: types.map(Image),
    page: types.maybe(
      types.model({
        currentPage: types.optional(types.number, 1),
        totalCount: types.optional(types.number, 0),
        itemPerPage: types.optional(types.number, 20)
      })
    ),
    cover: types.maybe(types.safeReference(Image, { acceptsUndefined: false })),
    category: types.identifier,
    isLoading: false
  })
  .views((self) => ({
    get type() {
      return self.category.split('/')[0]
    },
    get subtype() {
      return self.category.split('/')[1]
    }
  }))
  .actions((self) => {
    const { api } = getEnv(self)

    function markLoading(loading) {
      self.isLoading = loading
    }

    function setImage(image) {
      self.images.put(
        Object.assign(
          {},
          self.images.has(image.storeMediaUsageUuid) &&
            getSnapshot(self.images.get(image.storeMediaUsageUuid)),
          image
        )
      )
    }

    function clearImage() {
      self.images.clear()
    }

    function removeImage(image) {
      destroy(self.images.get(image))
    }

    const imageLoader = new DataLoader(async (keys) => {
      const result = await api.get('/media/getInfo', { params: { uuid: keys.join(',') } })[0]
      return keys.map((key) => {
        return _.get(result.data, key, null)
      })
    })

    const loadImages = flow(function* loadImages(uuids = []) {
      markLoading(true)
      const result = yield imageLoader.loadMany(uuids)
      markLoading(false)
      return result
    })

    const fetchImages = flow(function* fetchImageStore({ page = 1, num = 20, type }) {
      markLoading(true)

      const result = yield api.get(`/media/${page}`, {
        params: Object.assign({}, { num, type })
      })[0]
      const { totalCount, currentPage, list } = result.data
      const images =
        list.map((imageInfo) => ({
          ...imageInfo,
          url: /size/.test(imageInfo.url)
            ? imageInfo.url.replace('size', ImageCroppingSizeType.MediaLibraryCard1x)
            : imageInfo.url
        })) || []
      _.isArray(images) ? images.map(setImage) : setImage(images)

      self.page = {
        itemPerPage: 20,
        totalCount,
        currentPage
      }

      markLoading(false)
    })

    const createImage = flow(function* createImage(file, category, targetNo) {
      const data = new FormData()
      let result = []

      switch (self.type) {
        // 以下兩種皆和媒體庫無關聯
        case 'voucherFile':
          data.append('file[0]', file)
          result = yield api.post(`/order/${targetNo}/voucher/upload`, data)[0]
          break
        case 'qrCodeImage':
          data.append('file[0]', file)
          result = yield api.post(`/order/${targetNo}/voucher/customQrCodeImage`, data)[0]
          break
        default:
          file.forEach((eachFile, index) => data.append(`file[${index}]`, eachFile))
          result = yield api.post(`/media/${category}`, data)[0]
          break
      }

      if (_.isEmpty(targetNo)) {
        const uuids = _.get(result, 'data.file', [])
          .filter((file) => file.success)
          .map((image) => image.storeMediaUsageUuid)

        _.get(result, 'data.file')
          ? _.map(result.data.file, setImage)
          : yield self.loadImages(uuids)
        return uuids
      } else {
        return result?.response?.data?.data?.file[0]
      }
    })

    const deleteImage = flow(function* deleteImage(storeMediaUsageUuids, category) {
      const result = yield api.delete(`/media/${category}`, {
        data: { image: storeMediaUsageUuids }
      })[0]

      if (result.errorCode === APIErrorCode.Success) {
        const target = _.isArray(storeMediaUsageUuids)
          ? storeMediaUsageUuids
          : [storeMediaUsageUuids]
        target.map(removeImage)
        return result.message
      }
    })

    const updateCover = flow(function* updateCover(storeMediaUsageUuid) {
      if (self.type !== 'product') {
        return
      }
      yield api.put(`/product/${self.subtype}/cover/${storeMediaUsageUuid}`)[0]
      yield fetchImages()
    })

    return {
      setImage,
      loadImages,
      createImage,
      clearImage,
      deleteImage,
      fetchImages,
      updateCover
    }
  })
  .extend((self) => {
    return extendSync(self, 'images', 'loadImages')
  })

const ImageStore = types
  .model('ImageStore', {
    isLoading: true,
    categories: types.map(ImageCategory)
  })
  .views((self) => ({
    getCategory(category) {
      if (!self.categories.has(category)) {
        self.createCategory(category)
      }
      return self.categories.get(category)
    }
  }))
  .actions((self) => ({
    createCategory(category) {
      self.categories.put({ category })
    }
  }))

export const identityUuids = {
  everyone: '76d2d935-4a55-4c09-80df-42311e7bbaa9',
  adult: 'd6871da2-c391-468e-ad9b-f8d5148fe0cd',
  child: '2497e6db-8f22-47a0-92f5-dd6ad8d61a6b',
  infant: '175087ec-03a7-4aa4-a288-29f65bb8966e',
  senior: '4f0e13fa-9a2a-4e9e-818b-4a1e86188659',
  teenager: 'df20a49e-85f9-490b-893a-f45106f00887',
  student: '5c747340-033c-4d12-982b-3826f0721753',
  concession: '8694d0f9-9297-4eaa-a1bf-fb81e7afcd49',
  double: '5d831844-2321-4cea-b1d5-372ae6332e87',
  triple: '867932d2-4b09-49a6-a422-db98da3af894',
  quad: 'f2c443b7-988a-45b8-aafa-a7d7afc81fcc',
  group_fixed: '4be805d2-aa83-4fd2-9e24-4c5cc6a704fc',
  group_person: '88defd8f-8d63-477d-a8ef-1b0b8062a18a'
}

const Session = types
  .model('Session', {
    sessionUuid: types.identifier,
    productUuid: types.maybe(types.string),
    salesOption: types.maybe(types.safeReference(SalesOption, { acceptsUndefined: false })),
    salesOptionUuid: types.maybe(types.string),
    sessionSettingUuid: types.maybe(types.string),
    sessionTemplateUuid: types.maybe(types.string),
    _sessionSetting: types.maybe(types.frozen()),
    _orderList: types.maybe(types.array(types.string)),
    sessionStartDate: types.string,
    sessionStartTime: types.string,
    sessionEndDate: types.string,
    sessionEndTime: types.string,
    sessionPrice: types.maybe(PriceConfig),
    resourceUuids: types.maybeNull(types.array(types.string)),
    quotaTotal: types.maybe(types.number),
    quotaBooked: types.maybe(types.number),
    quotaAvailable: types.maybe(types.number),
    resourceData: types.maybeNull(types.frozen()),
    quotaType: types.maybe(types.number),
    quotaSetting: types.maybe(types.frozen()),
    quotaBookedDetail: types.maybe(types.frozen()),
    active: types.maybe(types.boolean),
    isAllDay: types.maybe(types.boolean)
  })
  .views((self) => ({
    get sessionStartDateTime() {
      return `${self.sessionStartDate} ${self.sessionStartTime.slice(
        0,
        2
      )}:${self.sessionStartTime.slice(2)}`
    },
    get sessionEndDateTime() {
      return `${self.sessionEndDate} ${self.sessionEndTime.slice(0, 2)}:${self.sessionEndTime.slice(
        2
      )}`
    },
    get sessionSetting() {
      if (!self._sessionSetting) {
        setTimeout(self.loadSessionSetting, 0)
      }
      return self._sessionSetting
    },
    get currencyUuid() {
      if (self.sessionPrice) {
        return _.get(self.sessionPrice.flatten, [0, 'content', 'currencyUuid'])
      }
    },
    get priceStartAt() {
      if (!self.sessionPrice) {
        return null
      }
      return self.sessionPrice.flatten
        .reduce((res, { type, content }) => {
          let { price, range, identityUuid } = content
          const category =
            identityUuid === identityUuids.everyone
              ? 0
              : identityUuid === identityUuids.adult
              ? 1
              : type === PricingPolicyType.ByPerson
              ? 2
              : 3

          switch (identityUuid) {
            case identityUuids.double:
              price = price / 2
              break
            case identityUuids.triple:
              price = price / 3
              break
            case identityUuids.quad:
              price = price / 4
              break
            case identityUuids.group_fixed:
              price = price / _.get(range, 1, 1)
              break
          }
          price = Number.parseFloat(price).toFixed(2)

          if (!res[category]) {
            res[category] = price
          } else {
            res[category] = Math.min(price, res[category])
          }
          return res
        }, [])
        .find((price) => !_.isNil(price))
    },
    get product() {
      const { productStore } = getRoot(self)
      const product = resolveIdentifier(Product, productStore, self.productUuid)
      if (!product) {
        setTimeout(() => {
          productStore.loadProduct(self.productUuid)
        }, 0)
      }
      return product
    },
    get orders() {
      if (!self._orderList) {
        setTimeout(() => {
          self.loadOrders()
        })
        return []
      }
      const { orderStore } = getRoot(self)
      return self._orderList.map((orderNo) => resolveIdentifier(Order, orderStore, orderNo))
    }
  }))
  .actions((self) => {
    const loadSessionSetting = flow(function* loadSessionSetting() {
      const result = yield getEnv(self).api.get(
        `/publish/sessionSetting/${self.sessionSettingUuid}`
      )[0]
      self._sessionSetting = result.data
    })

    const loadOrders = flow(function* loadOrders() {
      const { orderStore } = getRoot(self)

      const result = yield getEnv(self).api.get(`order/${1}`, {
        params: { sessionUuid: self.sessionUuid, num: 999 }
      })[0]
      self._orderList = result.data.list.map((item) => {
        orderStore.setOrder(item)
        return item.orderNo
      })
    })

    return {
      loadOrders,
      loadSessionSetting
    }
  })
  .preProcessSnapshot((snapshot) => {
    return {
      ...snapshot,
      isAllDay: !!snapshot.isAllDay,
      sessionUuid: snapshot.uuid || snapshot.sessionUuid,
      salesOption: snapshot.salesOptionUuid,
      quotaAvailable:
        snapshot.quotaAvailable == null
          ? snapshot.quotaTotal - snapshot.quotaBooked
          : snapshot.quotaAvailable
    }
  })

const SessionStore = types
  .model('SessionStore', {
    sessions: types.map(Session),
    dateList: types.map(types.array(types.safeReference(Session, { acceptsUndefined: false }))),
    isLoading: true,
    calendarPage: types.optional(
      types.model({
        totalCount: types.number,
        itemPerPage: types.number,
        currentPage: types.number,
        productUuids: types.array(types.maybeNull(types.string))
      }),
      {
        totalCount: 0,
        itemPerPage: 20,
        currentPage: 1,
        productUuids: []
      }
    )
  })
  .views((self) => ({
    byDate(date) {
      return self.dateList.has(date) ? values(self.dateList.get(date)) : []
    },
    byDays(dates) {
      let result = []
      for (const date of dates) {
        result = [...result, ...self.byDate(date)]
      }
      return result
    },
    get hasSessionProductUuids() {
      return _.map(
        _.filter(
          [...getRoot(self).productStore.products.values()],
          (product) => product.categoryUuid !== ProductCategories.NonSessionProduct
        ),
        ({ uuid }) => uuid
      )
    }
  }))
  .actions((self) => {
    const { api } = getEnv(self)

    function setSession(session) {
      self.sessions.put(session)
    }

    function markLoading(loading) {
      self.isLoading = loading
    }

    const loadSession = flow(function* loadSession(productUuid, sessionUuid) {
      markLoading(true)
      const result = yield api.get(`/publish/session/${sessionUuid}?productUuid=${productUuid}`)[0]
      setSession(result.data)
      markLoading(false)
    })

    function putFormattedCalendarSalesOption(salesOption, productUuid) {
      const productStore = getRoot(self).productStore
      const product = productStore.products.get(productUuid)
      const { salesOptionUuid, salesOptionTitle, publishStatus } = salesOption
      const quotaType = _.values(_.values(salesOption.date)[0])[0]?.quotaType
      product.setSalesOption({
        uuid: salesOptionUuid,
        title: salesOptionTitle,
        publishStatus,
        productUuid,
        quotaType
      })
      _.map(salesOption.date, (day, date) => {
        const uuids = _.map(day, (session) => {
          setSession({ salesOptionUuid, productUuid, ...session })
          return session.sessionUuid
        })
        self.dateList.set(
          date,
          _.uniq([...self.byDate(date).map((session) => session.sessionUuid), ...uuids])
        )
      })
    }

    /**
     * @param {boolean} isForceShowAllSalesOption 是否強制顯示所有銷售選項。用途於當使用套餐顯示設定時，如果強制顯示所有套餐，則會透過 header 去忽略套餐顯示設定
     */
    const loadCalendar = flow(function* loadCalendar(start, end, params = {}) {
      const { page = 1, isForceShowAllSalesOption = false, ...restParams } = params
      const productStore = getRoot(self).productStore
      const result = yield api.get(`/publish/calendar/${page}`, {
        params: Object.assign({}, { s: start, e: end }, restParams),
        headers: {
          'X-Switch-SalesOptionPublishedSetting': isForceShowAllSalesOption ? 0 : 1
        }
      })[0]

      const { totalCount, itemPerPage, currentPage, list } = result.data
      const productUuids = _.map(
        list,
        ({
          salesOption: salesOptions,
          productUuid,
          productTitle,
          productCode,
          productCategoryUuid
        }) => {
          productStore.setProduct({
            uuid: productUuid,
            title: productTitle,
            productCode,
            categoryUuid: productCategoryUuid
          })
          salesOptions.forEach((salesOption) =>
            putFormattedCalendarSalesOption(salesOption, productUuid)
          )
          return productUuid
        }
      )

      self.calendarPage = {
        totalCount,
        itemPerPage,
        currentPage,
        productUuids: _.union(self.calendarPage.productUuids, productUuids)
      }
    })

    const loadDateRange = flow(function* loadDateRange(start, end, params = {}) {
      const { productNum = 20, forceRefresh = true, page = 1, ...restParams } = params
      markLoading(true)
      const isSameDay = start === end
      const startMoment = moment(start)
      const endMoment = isSameDay
        ? moment(start).add(1, 'days')
        : end
        ? moment(end)
        : moment(start).add(7, 'days')
      const durationDays = endMoment.diff(startMoment, 'days')

      if (forceRefresh) {
        self.sessions.clear()
        self.dateList.clear()
        self.calendarPage = {
          totalCount: 0,
          itemPerPage: 20,
          currentPage: 1,
          productUuids: []
        }
      }

      // 檢查要求的 date 是否已經拿過，只拿沒那過的日期最大最小拿 api
      const dateRange = forceRefresh
        ? [startMoment.format('YYYY-MM-DD'), endMoment.format('YYYY-MM-DD')]
        : page && self.calendarPage.currentPage > page && _.isEmpty(restParams.p)
        ? _.compact(
            _.map(_.range(0, durationDays + 1), (dayDiff) => {
              const currentDay = moment(start).add(dayDiff, 'days').format('YYYY-MM-DD')
              return self.dateList.has(currentDay) ? undefined : currentDay
            })
          )
        : [startMoment.format('YYYY-MM-DD'), endMoment.format('YYYY-MM-DD')]

      if (!_.isEmpty(dateRange)) {
        const startAt = _.head(dateRange)
        const endAt = _.last(dateRange)
        if (page === 'all') {
          // 特別傳 page 是 all 就全拿
          yield loadCalendar(startAt, endAt, { page: 1, num: 200, ...restParams })
          const { totalCount, itemPerPage } = self.calendarPage
          if (itemPerPage < totalCount) {
            _.each(_.range(2, Math.ceil(totalCount / itemPerPage) + 1), async (page) => {
              await loadCalendar(startAt, endAt, { page, num: 200, ...restParams })
            })
          }
        } else if (+page > 0) {
          loadCalendar(startAt, endAt, { page, num: productNum, ...restParams })
        }
      }
      markLoading(false)
    })

    const updateSessionActive = flow(
      function* updateSessionActive(sessionUuid, active, target, payload) {
        yield api.put(`/publish/session/${sessionUuid}/active`, {
          productUuid: self.sessions.get(sessionUuid).productUuid,
          active: active ? SessionStatus.Active : SessionStatus.Inactive,
          target,
          ...payload
        })[0]
        // yield loadDateRange(self.sessions.get(sessionUuid).sessionStartDate, self.sessions.get(sessionUuid).sessionStartDate)
      }
    )

    const deleteSession = flow(function* deleteSession(sessionUuid, target, payload) {
      const result = yield api.delete(`/publish/session/${sessionUuid}`, {
        params: { ...payload, productUuid: self.sessions.get(sessionUuid).productUuid, target }
      })[0]
      if (result.errorCode === APIErrorCode.Success) {
        const toBeDelete = self.sessions.get(sessionUuid)
        switch (target) {
          case 'session':
            destroy(toBeDelete)
            break
          case 'following':
          case 'all': {
            const now = moment()
            const sessionSettingUuid = toBeDelete.sessionSettingUuid
            self.sessions.forEach((session) => {
              if (
                session.sessionSettingUuid === sessionSettingUuid &&
                (target === 'all' ||
                  (target === 'following' && moment(session.sessionStartDate).isAfter(now)))
              ) {
                destroy(session)
              }
            })
            break
          }
        }
      }
    })

    const updateSession = flow(function* updateSession(sessionUuid, change) {
      yield api.put(`/publish/session/${sessionUuid}`, {
        productUuid: self.sessions.get(sessionUuid).productUuid,
        ...change
      })[0]
      // yield loadDateRange(self.sessions.get(sessionUuid).sessionStartDate, self.sessions.get(sessionUuid).sessionStartDate)
    })

    return {
      setSession,
      putFormattedCalendarSalesOption,
      loadDateRange,
      updateSessionActive,
      deleteSession,
      updateSession,
      loadSession
    }
  })

const DayOff = types
  .model('DayOff', {
    type: types.enumeration(['session', 'op']),
    dates: types.array(types.string),
    isLoading: true
  })
  .actions((self) => {
    const { api } = getEnv(self)

    const fetchDayOff = flow(function* fetchDayOff() {
      self.isLoading = true
      const result = yield api.get(`/store/${self.type}DayOff`)[0]
      self.dates.replace(result.data[`${self.type}DayOff`])
      self.isLoading = false
    })

    const updateDayOff = flow(function* updateDayOff(add, remove) {
      const key = `${self.type}DayOff`
      add.length > 0 &&
        (yield api.put(`/store/${self.type}DayOff`, {
          action: 'add',
          ..._.set({}, key, add)
        })[0])
      remove.length > 0 &&
        (yield api.put(`/store/${self.type}DayOff`, {
          action: 'remove',
          ..._.set({}, key, remove)
        })[0])
      yield fetchDayOff()
    })

    return {
      fetchDayOff,
      updateDayOff
    }
  })

const PeriodStatus = types.model('PeriodStatus', {
  start: types.string,
  end: types.string,
  orderCount: types.number,
  amount: types.frozen(),
  paid: types.frozen()
})

const Dashboard = types
  .model('Dashboard', {
    period: types.model({
      last: types.optional(PeriodStatus, {
        start: '',
        end: '',
        orderCount: 0,
        amount: {},
        paid: {}
      }),
      previous: types.optional(PeriodStatus, {
        start: '',
        end: '',
        orderCount: 0,
        amount: {},
        paid: {}
      })
    }),
    orderList: types.model({
      byDeparture: types.model({
        totalCount: types.number,
        orderNo: types.maybeNull(types.array(types.string))
      }),
      byBooking: types.model({
        totalCount: types.number,
        orderNo: types.maybeNull(types.array(types.string))
      })
    })
  })
  .views((self) => ({
    get orderByDeparture() {
      return self.orderList.byDeparture.orderNo.map((orderNo) =>
        resolveIdentifier(Order, getRoot(self).orderStore, orderNo)
      )
    },
    get orderByBooking() {
      return self.orderList.byBooking.orderNo.map((orderNo) =>
        resolveIdentifier(Order, getRoot(self).orderStore, orderNo)
      )
    }
  }))
  .actions((self) => {
    const loadOrders = flow(function* (page = 1, type = 'byDeparture') {
      const tsDiff = -(new Date().getTimezoneOffset() * 60)
      const targetStatusList = [
        OrderStatus.OnHold,
        OrderStatus.New,
        OrderStatus.Pending,
        OrderStatus.Confirmed
      ]
      const formatedTargetStatus = _.join(
        _.map(targetStatusList, (eachOrderStatus) => formatStatus(eachOrderStatus)[0]),
        ','
      )

      const requestQuery =
        type === 'byDeparture'
          ? `dateType=departure&from=${moment().format(
              'YYYY-MM-DD'
            )}&status=${formatedTargetStatus}&to=${moment()
              .add(7, 'days')
              .format('YYYY-MM-DD')}&sort=departureASC&upcoming=true`
          : `dateType=booking&from=${moment()
              .subtract(7, 'days')
              .format('YYYY-MM-DD')}&to=${moment().format('YYYY-MM-DD')}&sort=bookingDESC`
      const result = yield getEnv(self).api.get(
        `/order/${page}?num=5&tsDiff=${tsDiff}&${requestQuery}`
      )[0]
      if (result) {
        const orderStore = getRoot(self).orderStore
        const { list: orders, totalCount } = result.data
        if (self.orderList[type]) {
          self.orderList[type].totalCount = totalCount
          self.orderList[type].orderNo = _.union(
            self.orderList[type].orderNo,
            _.map(orders, (order) => {
              orderStore.setOrder(order)
              return order.orderNo
            })
          )
        }
      }
    })

    const loadPeriod = flow(function* (num = 1) {
      const result = yield getEnv(self).api.get(`/store/dashboard?num=${num}`)[0]
      if (result) {
        self.period = result.data.period
      }
    })

    return {
      loadPeriod,
      loadOrders
    }
  })

const ResourceSession = types
  .model('ResourceSession', {
    sessionUuid: types.identifier, // resourceSessionUuid
    resourceUuid: types.string,
    label: types.string,
    isGroup: types.boolean,
    bindingProducts: types.map(
      types.model({
        productUuid: types.identifier,
        salesOptions: types.array(types.string)
      })
    ),
    startDate: types.string,
    endDate: types.string,
    startTime: types.string,
    endTime: types.string,
    timezone: types.string,
    booked: types.optional(types.number, 0),
    total: types.optional(types.number, 0),
    allocation: types.frozen(),
    isAllDay: types.boolean,
    displayAll: types.optional(types.boolean, false),
    displayFollowing: types.optional(types.boolean, false),
    _orderList: types.array(types.maybeNull(types.string)),
    _orderTagList: types.frozen(),
    _orderTagListLoaded: false,
    _bindingSessionList: types.array(types.maybeNull(types.string)),
    _bindingSessionListLoaded: false,
    groupDetail: types.array(
      types.maybeNull(
        types.model('ResourceGroupItem', {
          label: types.string,
          uuid: types.string, // resourceSessionUuid
          startDate: types.string,
          endDate: types.string,
          startTime: types.string,
          endTime: types.string,
          booked: types.optional(types.number, 0),
          total: types.optional(types.number, 0),
          allocation: types.frozen()
        })
      )
    )
  })
  .views((self) => ({
    get orders() {
      if (self._orderList.length !== _.size(self.allocation)) {
        setTimeout(() => {
          self.loadOrders()
        })
        return []
      }
      const { orderStore } = getRoot(self)
      return self._orderList.map((orderNo) => resolveIdentifier(Order, orderStore, orderNo))
    },
    get resource() {
      const { resourceStore } = getRoot(self)
      return resourceStore.resources.get(self.resourceUuid)
    },
    get stockStatus() {
      const stockWarning = self.resource.stockWarning
      const safetyStockNum =
        stockWarning.type === DefaultStockType.None
          ? 0
          : stockWarning.type === DefaultStockType.Percentage
          ? self.total * +stockWarning.number * 0.01
          : +stockWarning.number
      return self.total <= self.booked
        ? ResourceStockStatus.SoldOut
        : self.total - self.booked <= safetyStockNum // IN_STOCK, LOW_INVENTORY, SOLD_OUT, UNAVAILABLE
        ? ResourceStockStatus.LowInventory
        : ResourceStockStatus.InStock
    },
    get interval() {
      const sessionStartMoment = moment(`${self.startDate} ${self.startTime}`, 'YYYY-MM-DD HHmm')
      const sessionEndMoment = moment(`${self.endDate} ${self.endTime}`, 'YYYY-MM-DD HHmm')
      const internalMin = sessionEndMoment.diff(sessionStartMoment, 'minutes')

      return {
        day: Math.floor(internalMin / 60 / 24),
        hour: Math.floor((internalMin / 60) % 24),
        min: internalMin % 60
      }
    },
    get startDateTime() {
      return `${self.startDate} ${self.startTime.slice(0, 2)}:${self.startTime.slice(2)}`
    },
    get endDateTime() {
      return `${self.endDate} ${self.endTime.slice(0, 2)}:${self.endTime.slice(2)}`
    },
    get orderTags() {
      if (!self._orderTagListLoaded) {
        setTimeout(self.loadOrderTagList, 0)
        return []
      }
      return self._orderTagList
    },
    get bindingSessions() {
      // ResourceSession 的 bindingProducts 資料後端是依照 sessionSetting，所以有可能後來 session 被刪除就會變成 ResourceSession 裡有 bindingProducts 但沒有對應的 session
      const { sessionStore } = getRoot(self)
      if (!self._bindingSessionListLoaded) {
        setTimeout(self.loadBindingSessions, 0)
        return []
      }
      const bindingSessions = _.map([...self._bindingSessionList.values()], (sessionUuid) =>
        resolveIdentifier(Session, sessionStore, sessionUuid)
      )
      return bindingSessions
    }
  }))
  .actions((self) => {
    const { api } = getEnv(self)

    function setBindingProduct(bindingProduct) {
      self.bindingProducts.put(
        Object.assign({}, self.bindingProducts.get(bindingProduct.productUuid), bindingProduct)
      )
    }

    const loadOrders = flow(function* loadOrders() {
      if (_.isEmpty(self.allocation)) {
        return
      }

      const { orderStore } = getRoot(self)
      const orderNo = _.keys(self.allocation)
        .filter((eachOrderNo) => !store.orderStore.orders.has(eachOrderNo))
        .join(',')
      if (orderNo?.length > 0) {
        const result = yield api.get('order/1', { params: { orderNo, num: 999 } })[0]
        self._orderList = result.data.list.map((item) => {
          orderStore.setOrder(item)
          return item.orderNo
        })
      } else {
        self._orderList = _.keys(self.allocation)
      }
    })

    const updateAllocation = flow(function* updateAllocation(params) {
      yield api.put(`/publish/resourceSession/${self.resourceUuid}`, params)[0]
    })

    const loadBindingSessions = flow(function* loadBindingSessions() {
      if (self.bindingProducts.size === 0) {
        return
      }
      const { sessionStore, productStore } = getRoot(self)
      const productUuids = [...new Set([...self.bindingProducts.values()].map(prop('productUuid')))]
      const salesOptionUuids = _.union(
        ...[...self.bindingProducts.values()].map(({ salesOptions }) => [...salesOptions.values()])
      )
      const result = yield api.get('/publish/calendar/1', {
        params: {
          p: productUuids.join(),
          s: self.startDate,
          e: self.startDate,
          so: salesOptionUuids.join(),
          resourceUuid: self.resourceUuid
        }
      })[0]

      _.each(
        result.data?.list,
        ({ salesOption: salesOptions, productUuid, productTitle, productCode }) => {
          productStore.setProduct({ uuid: productUuid, title: productTitle, productCode })
          salesOptions.forEach((salesOption) => {
            sessionStore.putFormattedCalendarSalesOption(salesOption, productUuid)
            self._bindingSessionList = _.union(
              self._bindingSessionList,
              _.map(salesOption.date?.[self.startDate], ({ sessionUuid }) => sessionUuid)
            )
          })
        }
      )
      self._bindingSessionListLoaded = true
    })

    const loadOrderTagList = flow(function* loadOrderTagList() {
      if (self._orderTagListLoaded) {
        return
      }
      const { orderStore } = getRoot(self)
      const result = yield api.get('/orderTag')[0]
      self._orderTagList = result.data
      _.each(result?.data, (orderTag) => orderStore.setOrderTag(orderTag))
      self._orderTagListLoaded = true
    })

    return {
      loadOrders,
      updateAllocation,
      setBindingProduct,
      loadBindingSessions,
      loadOrderTagList
    }
  })

const Resource = types
  .model('Resource', {
    uuid: types.identifier,
    label: types.string,
    seat: types.optional(types.number, 0),
    storeUuid: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    isGroup: types.number,
    groupDetail: types.maybeNull(
      types.array(
        types.model('ResourceGroupItem', {
          uuid: types.identifier,
          label: types.string,
          seat: types.optional(types.number, 0),
          date: types.frozen()
        })
      )
    ),
    updatedAt: types.maybeNull(types.string),
    resourceTypeUuid: types.maybeNull(types.string),
    startAt: types.maybeNull(types.string),
    interval: types.maybeNull(types.frozen()),
    resourceTypeLabel: types.maybeNull(types.string),
    bindingProductUuids: types.frozen(),
    bindingProductCnt: types.optional(types.number, 0),
    bindingMap: types.frozen(),
    booked: types.optional(types.number, 0),
    total: types.optional(types.number, 0),
    date: types.frozen(),
    stockWarning: types.model('ResourceStockWaring', {
      number: types.maybeNull(types.number),
      type: types.number
    }),
    useType: types.optional(types.number, 1)
  })
  .views((self) => ({
    get isAllDay() {
      if (self.interval?.day === 1 && self.interval?.hour === 1 && self.interval?.min === 1) {
        return true
      }
      return false
    }
  }))

const ResourceStore = types
  .model('ResourceStore', {
    resources: types.map(Resource),
    dateList: types.map(
      types.array(types.safeReference(ResourceSession, { acceptsUndefined: false }))
    ),
    sessions: types.map(ResourceSession),
    isLoading: false,
    page: types.optional(Page, { source: 'resources' }),
    filteresMapping: types.map(
      types.model({
        filter: types.identifier,
        uuids: types.array(types.maybeNull(types.string))
      })
    )
  })
  .views((self) => ({
    byDate(date, params = {}) {
      const { startTime, resourceUuid } = params
      return self.dateList.has(date)
        ? startTime || resourceUuid
          ? values(self.dateList.get(date)).filter((item) => {
              if (startTime && item.startTime !== startTime) {
                return false
              }

              if (resourceUuid && item.resourceUuid !== resourceUuid) {
                return false
              }

              return item
            })
          : values(self.dateList.get(date))
        : []
    },
    byDays(dates, params) {
      let result = []
      for (const date of dates) {
        result = [...result, ...self.byDate(date, params)]
      }
      return result
    },
    get title() {
      return self.label
    }
  }))
  .actions((self) => {
    const { api } = getEnv(self)

    function markLoading(isLoading) {
      self.isLoading = isLoading
    }

    function setResource(resource) {
      self.resources.put(
        Object.assign(
          {},
          self.resources.has(resource.uuid) && getSnapshot(self.resources.get(resource.uuid)),
          resource
        )
      )
    }

    function setResourceSession(resourceSession) {
      self.sessions.put(Object.assign({}, self.sessions.get(resourceSession.uuid), resourceSession))
    }

    function formatResourceFromAPI(resource) {
      return {
        ...resource,
        groupDetail: _.map(resource.groupDetail, (detail) => ({
          ...detail,
          seat: +detail.seat
        })),
        stockWarning: {
          type: +resource.stockWarning.type,
          number: +(resource.stockWarning.number ?? 0)
        }
      }
    }

    const loadCalendar = flow(function* loadCalendar(params = {}) {
      markLoading(true)
      const result = yield api.get('/publish/resourceCalendar', { params })[0]
      _.map(result.data.list, (resource) => {
        const { resourceUuid, detail, isGroup, bindingProductUuids, stockWarning, ...rest } =
          resource
        setResource({
          ...rest,
          uuid: resourceUuid,
          bindingProductUuids,
          isGroup: +isGroup,
          groupDetail: _.map(detail, ({ detailUuid, ...groupItem }) => ({
            ...groupItem,
            uuid: detailUuid
          })),
          stockWarning: {
            type: +stockWarning.type,
            number: stockWarning.number ? +stockWarning.number : 0
          }
        })
        const resourceSession = self.resources.get(resourceUuid)
        _.each(resource.date, (dateDetail, date) => {
          if (resourceSession) {
            const resourceSessions = _.map(
              dateDetail,
              ({ resourceSessionUuid, ...eachSession }, time) => {
                setResourceSession({
                  ...eachSession,
                  sessionUuid: resourceSessionUuid,
                  resourceUuid,
                  isAllDay: time === 'allDay',
                  label: rest.label,
                  isGroup,
                  groupDetail: _.map(detail, (groupItem) => {
                    const { resourceSessionUuid, label, ...targetGroupItemDate } =
                      groupItem.date[eachSession.startDate][time]
                    return {
                      ...targetGroupItemDate,
                      label: groupItem.label,
                      uuid: resourceSessionUuid
                    }
                  })
                })
                const targetResourceSession = self.sessions.get(resourceSessionUuid)
                _.each(bindingProductUuids, (salesOptionUuids, productUuid) =>
                  targetResourceSession?.setBindingProduct?.({
                    productUuid,
                    salesOptions: salesOptionUuids
                  })
                )
                return resourceSessionUuid
              }
            )
            self.dateList.set(
              date,
              _.uniq([
                ...self.byDate(date).map((resourceSession) => resourceSession.sessionUuid),
                ...resourceSessions
              ])
            )
          }
        })
      })
      markLoading(false)
    })

    const loadDateRange = flow(function* loadDateRange(start, end, params = {}) {
      const { forceRefresh = true, ...restParams } = params
      const isSameDay = start === end
      const startMoment = moment(start)
      const endMoment = isSameDay
        ? moment(start).add(1, 'days')
        : end
        ? moment(end)
        : moment(start).add(7, 'days')
      const durationDays = endMoment.diff(startMoment, 'days')

      if (forceRefresh) {
        self.dateList.clear()
        loadCalendar({
          ...restParams,
          startDate: startMoment.format('YYYY-MM-DD'),
          endDate: endMoment.format('YYYY-MM-DD')
        })
      } else {
        const formatedFiltersStr = JSON.stringify({
          ...restParams,
          startDate: startMoment.format('YYYY-MM-DD'),
          endDate: endMoment.format('YYYY-MM-DD')
        })
        const isLoaded =
          self.filteresMapping.has(formatedFiltersStr) ||
          (!_.isEmpty(restParams.resourceUuids) &&
            _.range(0, durationDays + 1).every((dayDiff) => {
              const currentDay = moment(start).add(dayDiff, 'days').format('YYYY-MM-DD')
              return (
                _.intersection(self.dateList[currentDay], restParams.resourceUuids?.spilt?.(','))
                  ?.length !== 0
              )
            }))
        !isLoaded &&
          loadCalendar({
            ...restParams,
            startDate: startMoment.format('YYYY-MM-DD'),
            endDate: endMoment.format('YYYY-MM-DD')
          })
      }
    })

    const loadResourceList = flow(function* loadResourceList(params) {
      markLoading(true)
      const { page = 1, updatePage = true, ...restParams } = params
      const result = yield api.get(`resource/${page}`, { params: restParams })[0]
      const list = _.map(result.data?.list, (resource) => {
        const resourceData = formatResourceFromAPI(resource)
        setResource(resourceData)
        return resourceData
      })
      updatePage && self.updatePage({ ...result.data, list })
      markLoading(false)
    })

    const fetchResource = flow(function* fetchResource(uuid) {
      markLoading(true)
      if (_.isEmpty(uuid)) {
        return
      }

      const result = yield api.get(`resource/${uuid}`)[0]
      setResource(formatResourceFromAPI(result.data))
      markLoading(false)
    })

    const createResource = flow(function* createResource(params) {
      const result = yield api.post('resource', _.omitBy(params, _.isUndefined))[0]
      setResource(formatResourceFromAPI({ ...params, ...result.data }))
    })

    const updateResource = flow(function* updateResource(params) {
      const { uuid, ...restParams } = params
      const result = yield api.put(`resource/${uuid}`, restParams)[0]
      setResource(formatResourceFromAPI({ ...params, ...result.data }))
    })

    const deleteResource = flow(function* deleteResource(uuid) {
      yield api.delete(`resource/${uuid}`)[0]
      self.resources.delete(uuid)
    })

    return {
      setResource,
      formatResourceFromAPI,
      loadDateRange,
      loadResourceList,
      fetchResource,
      createResource,
      updateResource,
      deleteResource
    }
  })
  .extend((self) => {
    const page = extendPage(self, 'resources', 'setResource')
    return page
  })

const StoreStore = types
  .model('StoreStore', {
    sessionDayOff: types.optional(DayOff, { dates: [], type: 'session' }),
    opDayOff: types.optional(DayOff, { dates: [], type: 'op' }),
    dashboard: types.optional(Dashboard, {
      period: {},
      orderList: {
        byDeparture: { totalCount: 0, orderNo: [] },
        byBooking: { totalCount: 0, orderNo: [] }
      }
    })
  })
  .actions((self) => {
    const { api } = getEnv(self)

    const fetchSessionDayOff = flow(function* fetchSessionDayOff() {
      const result = yield api.get('/store/sessionDayOff')[0]
      self.sessionDayOff.dates.replace(result.data.sessionDayOff)
    })

    const fetchOpDayOff = flow(function* fetchOpDayOff() {
      const result = yield api.get('/store/opDayOff')[0]
      self.opDayOff.dates.replace(result.data.opDayOff)
    })

    const updateSessionDayOff = flow(function* updateSessionDayOff(add, remove) {
      yield Promise.all([
        add &&
          api.put('/store/sessionDayOff', {
            action: 'add',
            sessionDayOff: add
          })[0],
        remove &&
          api.put('/store/sessionDayOff', {
            action: 'remove',
            sessionDayOff: remove
          })[0]
      ])
      yield fetchSessionDayOff()
    })

    const updateOpDayOff = flow(function* updateOpDayOff(change) {
      yield api.put('/store/opDayOff', {
        action: 'add',
        opDayOff: change
      })[0]
      yield fetchOpDayOff()
    })

    return {
      fetchSessionDayOff,
      fetchOpDayOff,
      updateSessionDayOff,
      updateOpDayOff
    }
  })

// View
const defaultWidth = Dimensions.get('window').width
const defaultPageWidth = defaultWidth > 768 ? defaultWidth - 45 - 60 - 110 : defaultWidth
const defaultPageHeight =
  defaultWidth > 768 ? Dimensions.get('window').height - 90 : Dimensions.get('window').height - 124

const ViewStore = types
  .model('ViewStore', {
    dimensionWidth: types.optional(types.maybeNull(types.number), defaultWidth),
    pageWidth: types.optional(types.maybeNull(types.number), defaultPageWidth),
    pageHeight: types.optional(types.maybeNull(types.number), defaultPageHeight),
    menuOpening: types.optional(types.boolean, false),
    pageHeader: types.optional(
      types.model('PageHeader', {
        title: types.maybe(types.string),
        subtitle: types.maybe(types.string)
      }),
      {}
    )
  })
  .views((self) => ({
    get layout() {
      let type = 'desktop'
      if (self.dimensionWidth <= 832 || Platform.OS !== 'web') {
        type = 'app'
      }
      return type
    }
  }))
  .actions((self) => {
    let emitter
    return {
      setPageLayout(width, height) {
        self.pageWidth = width
        self.pageHeight = height
      },
      setDimensionWidth(width) {
        self.dimensionWidth = width
      },
      setPageHeader(title, subtitle) {
        self.pageHeader.title = title
        self.pageHeader.subtitle = subtitle
      },
      toggleMenu() {
        self.menuOpening = !self.menuOpening
      },
      afterCreate() {
        emitter = Dimensions.addEventListener('change', (change) => {
          const { width } = change.window
          self.setDimensionWidth(width)
        })
      },
      beforeDestroy() {
        emitter?.remove()
      }
    }
  })

const AccountBook = types
  .model('payment', {
    uuid: types.identifier,
    currencyUuid: types.string,
    storeUuid: types.string,
    accountUuid: types.maybeNull(types.string),
    paymentType: types.string,
    amount: types.maybe(safeNumber),
    fee: types.maybe(safeNumber),
    feeCurrencyUuid: types.maybeNull(types.string),
    netAmount: types.maybe(safeNumber),
    netCurrencyUuid: types.maybeNull(types.string),
    settleCurrencyUuid: types.maybeNull(types.string),
    settleAmount: types.maybe(safeNumber),
    settleFeeDetail: types.maybeNull(types.frozen()),
    settleStatus: types.number,
    numberNote: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    isSystem: types.boolean,
    paymentUuid: types.maybeNull(types.string),
    residualValue: types.maybeNull(types.number),
    paymentStatus: types.maybeNull(types.number),
    paymentRefund: types.maybeNull(types.string),
    paymentService: types.maybeNull(types.string),
    transactionId: types.maybeNull(types.string),
    creditCard6: types.maybeNull(types.string),
    creditCard4: types.maybeNull(types.string),
    paidDate: types.string,
    source: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    orderNo: types.string,
    refundCodeIndex: types.maybeNull(types.string)
  })
  .views((self) => ({
    get type() {
      return parseFloat(self.amount) > 0 ? 'paid' : 'refund'
    },
    get creditCard() {
      return self.creditCard6 && self.creditCard4
        ? `${self.creditCard6}**-****-${self.creditCard4}`
        : ''
    }
  }))
  .actions((self) => {
    const remove = flow(function* remove(contact) {
      yield getEnv(self).api.delete(`order/${self.orderNo}/payment`, {
        data: { accountBookUuid: self.uuid }
      })[0]
      getRoot(self).orderStore.orders.get(self.orderNo).loadPayments()
      getRoot(self).orderStore.orders.get(self.orderNo).reload()
    })

    return {
      delete: remove
    }
  })

const OrderNote = types
  .model('OrderNote', {
    uuid: types.identifier,
    storeUuid: types.maybeNull(types.string),
    orderNo: types.maybeNull(types.string),
    accountUuid: types.maybeNull(types.string),
    accountFirstName: types.maybeNull(types.string),
    accountLastName: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string)
  })
  .actions((self) => {
    const remove = flow(function* remove() {
      try {
        const order = getParent(self, 2)
        const route = order?.isOnRequestOrder ? 'onRequestOrder' : 'order'
        yield getEnv(self).api.delete(`${route}/${self.orderNo}/note/${self.uuid}`)[0]
        getRoot(self).orderStore.orders.get(self.orderNo).loadNotes()
      } catch (error) {
        console.error(error)
      }
    })

    return {
      delete: remove
    }
  })

const OrderActionLog = types.model('OrderActionLog', {
  uuid: types.identifier,
  storeUuid: types.string,
  orderNo: types.string,
  accountUuid: types.maybeNull(types.string),
  actionType: types.string,
  actionSource: types.string,
  actionPayload: types.frozen(),
  actionTs: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  accountFirstName: types.maybeNull(types.string),
  accountLastName: types.maybeNull(types.string),
  accountIsRobot: types.maybeNull(types.boolean),
  accountTitle: types.maybeNull(types.string)
})

const OrderReceiptItemDetail = types.model('OrderReceiptItemDetail', {
  itemUuid: types.string,
  itemAmt: types.number,
  itemCount: types.number,
  itemCurrencyUuid: types.string,
  itemName: types.string,
  itemPrice: types.number
})
const OrderReceiptRecord = types.model('OrderReceiptRecord', {
  uuid: types.identifier,
  orderNo: types.string,
  receiptNo: types.maybeNull(types.string),
  receiptStatus: types.number,
  receiptType: types.string,
  createTs: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  buyerEmail: types.maybeNull(types.string),
  buyerName: types.maybeNull(types.string),
  buyerUBN: types.maybeNull(types.string),
  carrierNo: types.maybeNull(types.string),
  carrierType: types.maybeNull(types.number),
  merchantId: types.maybeNull(types.string),
  receiptCategory: types.string,
  currencyUuid: types.string,
  amount: types.maybeNull(types.number),
  taxAmount: types.maybeNull(types.number),
  taxType: types.maybeNull(types.number),
  timezoneTsDiff: types.maybeNull(types.number),
  totalAmount: types.number,
  itemDetail: types.array(OrderReceiptItemDetail),
  cancelDetail: types.maybeNull(types.frozen())
})

export const Order = types
  .model('Order', {
    orderNo: types.identifier,
    uuid: types.string,
    productUuid: types.string,
    salesOptionUuid: types.string,
    productName: types.string,
    productCode: types.maybeNull(types.string),
    productAlias: types.maybeNull(types.string),
    salesOptionName: types.string,
    sessionUuid: types.string,
    shoppingCartNo: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    currencyUuid: types.string,
    amount: types.maybe(safeNumber),
    discountAmount: types.maybe(safeNumber),
    discountRecordList: types.optional(
      types.array(
        types.model({
          discountType: types.string,
          targetUuid: types.string,
          discountAmount: types.maybe(safeNumber),
          discountName: types.string,
          currencyUuid: types.maybeNull(types.string)
        })
      ),
      []
    ),
    discountedTotalAmount: types.maybe(safeNumber),
    balance: types.maybe(safeNumber),
    paid: types.maybe(safeNumber),
    refund: types.maybe(safeNumber),
    totalFee: types.maybe(safeNumber),
    totalNetAmount: types.maybe(safeNumber),
    chargeCurrencyUuid: types.maybeNull(types.string),
    chargeAmount: types.maybeNull(safeNumber),
    chargeBalance: types.maybeNull(safeNumber),
    chargePaid: types.maybeNull(safeNumber),
    chargeRefund: types.maybeNull(safeNumber),
    chargeTotalFee: types.maybeNull(safeNumber),
    chargeTotalNetAmount: types.maybeNull(safeNumber),
    settleCurrencyUuid: types.maybeNull(types.string),
    chooseCurrencyUuid: types.maybeNull(types.string),
    settleTotalFee: types.maybeNull(safeNumber),
    settleTotalNetAmount: types.maybeNull(safeNumber),
    settleStatus: types.maybeNull(types.number),
    cancelDetail: types.frozen(),
    cancelOrderDisplayRule: types.maybeNull(types.string),
    optionsData: types.frozen(),
    orderStatus: types.maybeNull(types.number),
    isOnRequestOrder: types.maybeNull(types.boolean),
    onRequestOrderStatus: types.maybeNull(types.number),
    saleFrom: types.string,
    canceledDt: types.maybeNull(types.string),
    bookingCode: types.maybeNull(types.string),
    storeUuid: types.string, // types.reference(Store),
    rejectedDt: types.maybeNull(types.string),
    confirmedDt: types.maybeNull(types.string),
    confirmExpireDt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    sessionSettingUuid: types.string, // types.reference(SessionSetting),
    isAllDay: types.maybeNull(types.boolean),
    timezoneTsDiff: types.maybe(types.number),
    attendTs: types.maybeNull(types.number),
    platform: types.maybeNull(types.string),
    affiliate: types.maybeNull(types.string),
    distributorUuid: types.maybeNull(types.string),
    extras: types.array(
      types.model({
        productExtra: lazyReference(Extra),
        productExtraUuid: types.string,
        quantity: types.number
      })
    ),
    specialRequirement: types.maybeNull(types.string),
    sourceOrderNo: types.maybeNull(types.string),
    priceConfig: PriceConfig,
    purchaseContent: types.array(
      types.model({
        pricePolicyUuid: types.string,
        quantity: types.number,
        label: types.maybeNull(types.string),
        identityUuid: types.maybeNull(types.string),
        itemUuid: types.maybeNull(types.string)
      })
    ),
    shoppingCartDetail: types.maybeNull(types.frozen()),
    pickupRequest: types.maybeNull(types.frozen()),
    preferLanguage: types.maybeNull(types.frozen()),
    amountDetail: types.frozen(),
    resourceAllocation: types.frozen(),
    resourceUuids: types.maybeNull(types.array(types.string)),
    contactInfo: types.frozen(),
    contactFirstName: types.maybeNull(types.string),
    contactLastName: types.maybeNull(types.string),
    contactCountry: types.maybeNull(types.string),
    mailAvailable: types.frozen(),
    payStatus: types.maybeNull(types.number),
    payType: types.number,
    gatewayPayWay: types.maybeNull(types.string),
    paymentMethodList: types.frozen(),
    paymentRecordList: types.frozen(),
    depositAmount: types.maybeNull(types.union(types.string, types.number)),
    cancelFeeDetail: types.maybeNull(CancelPolicyRule), // * equal to cancelPolicy.policyRule
    cancelPolicy: types.maybeNull(CancelPolicy),
    depositDetail: types.maybeNull(types.frozen()),
    bookingInfoDetail: types.maybeNull(types.frozen()),
    bookingInfoConfig: types.maybeNull(types.frozen()),
    voucherUuids: types.maybeNull(types.array(types.string)),
    voucherData: types.maybeNull(types.frozen()),
    voucherSetting: types.maybeNull(types.frozen()),
    voucherInfo: types.maybeNull(types.frozen()),
    voucherIsRedeem: types.maybeNull(types.frozen()),
    voucherDisplay: types.maybeNull(types.number),
    resourceDisplay: types.maybeNull(types.number),
    voucherType: types.maybeNull(types.number),
    sysIdentity: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    orderTs: types.maybeNull(types.number),
    orderTagUuids: types.array(types.string),
    purchaseQuantity: types.number,
    purchaseTypeQuantity: types.maybeNull(
      types.model({
        person: types.number,
        item: types.number
      })
    ),
    resourceQuantity: types.maybeNull(types.frozen()),
    seatQuantity: types.number,
    resourceSessionUuids: types.array(types.string), // types.array(types.reference(ResourceSession))
    sessionStartDate: types.string,
    sessionStartTs: types.number,
    sessionStartTime: types.string,
    sessionEndDate: types.string,
    sessionEndTime: types.string,
    channelOrderInfo: types.maybeNull(types.frozen()),
    ticketInfo: types.maybe(types.frozen()),
    utmSource: types.maybe(types.string),
    isShowRefunding: types.optional(types.boolean, false),
    customSourceLabel: types.maybeNull(types.string),
    customSourceUuid: types.maybeNull(types.string),
    _resourceRemains: types.maybe(types.frozen()),
    _shoppingCartList: types.maybe(types.frozen()),
    _salesOption: types.maybe(types.frozen()),
    _payments: types.array(AccountBook),
    _paymentsLoaded: false,
    _productSnapshot: types.maybe(types.frozen()),
    _notes: types.array(OrderNote),
    _notesLoaded: false,
    _notesLoading: false,
    _actionHistory: types.array(OrderActionLog),
    _actionHistoryLoaded: false,
    _actionHistoryLoading: false,
    _receiptRecords: types.array(OrderReceiptRecord),
    _receiptRecordsLoaded: false,
    _receiptRecordsLoading: false,
    _receiptTemplate: types.maybeNull(types.frozen()),
    _receiptTemplateLoading: false,
    _receiptTemplateLoaded: false,
    _couponAdding: false,
    refundStatus: types.maybe(types.number),
    bookingInfoUuid: types.maybeNull(types.string), // types.reference(BookingInfo)
    opAccountFirstName: types.maybeNull(types.string),
    opAccountLastName: types.maybeNull(types.string),
    isThirdPartyVoucher: types.maybeNull(types.number),
    thirdPartyVoucherData: types.maybeNull(
      types.array(
        types.model('ThirdPartyVoucherData', {
          uuid: types.identifier,
          voucherSerial: types.string,
          isRedeem: types.number,
          redeemTimes: types.number,
          redeemTimesLimit: types.number
        })
      )
    ),
    isEscrow: types.maybeNull(types.boolean),
    hasQRCode: types.maybeNull(types.boolean)
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    extras: snapshot.extras
      ? snapshot.extras.map((extra) => ({
          ...extra,
          productExtra: extra.productExtraUuid
        }))
      : []
  }))
  .views((self) =>
    createViewGetters(
      {
        get isAsyncOrderStatus() {
          return (
            self?.saleFrom === OrderSaleFromType.API &&
            [
              ChannelType.ACTIVITYJAPAN,
              ChannelType.VIATOR,
              ChannelType.JALAN,
              ChannelType.GYG
            ].includes(self.platform?.toUpperCase())
          )
        },
        get nextStatus() {
          if (
            self.voucherType === 4 &&
            self.sysIdentity &&
            self.sysIdentity.toUpperCase() === SystemIdentity.ASOVIEW
          ) {
            return []
          }
          const originOrderStatus =
            self?.cancelDetail?.originOrderStatus || self?.optionsData?.originOrderStatus
          const rejectCacnelNextStatus =
            originOrderStatus && formatStatus(originOrderStatus)
              ? formatStatus(originOrderStatus)[1]
              : OrderStatus.Pending
          switch (self.orderStatus) {
            case 0:
            case OrderStatus.Canceled:
              return []
            case 1:
            case OrderStatus.OnHold:
              return [OrderStatus.Pending, OrderStatus.Confirmed, OrderStatus.CancelProcessing]
            case 2:
            case OrderStatus.New:
              return [OrderStatus.Pending, OrderStatus.Confirmed, OrderStatus.CancelProcessing]
            case 3:
            case OrderStatus.Pending:
              return [OrderStatus.Confirmed, OrderStatus.CancelProcessing]
            case 4:
            case OrderStatus.Confirmed:
              return ['KKday', 'KKdayMkp'].includes(self?.platform)
                ? self.voucherSetting?.qRCodeType === VoucherQRCodeType.Default
                  ? [OrderStatus.CheckIn, OrderStatus.CancelProcessing]
                  : [OrderStatus.CancelProcessing]
                : [
                    OrderStatus.CheckIn,
                    OrderStatus.NoShow,
                    OrderStatus.CancelProcessing,
                    OrderStatus.Departed
                  ]
            case 5:
            case OrderStatus.CheckIn:
              // 如果此訂單沒有核銷功能需可回到「已確認」狀態
              return self.isRedeemType
                ? self.redeemStatus === RedeemStatus.All
                  ? []
                  : [OrderStatus.CheckIn]
                : [OrderStatus.Confirmed]
            case 6:
            case OrderStatus.NoShow:
              return [OrderStatus.Confirmed]
            case 7:
            case OrderStatus.CancelProcessing: {
              // 新增訂單與客製訂單來源可以確定取消或恢復訂單
              return _.isEmpty(self.platform) || self.platform === 'CUSTOM'
                ? [OrderStatus.Canceled, rejectCacnelNextStatus]
                : []
            }
            case 8:
            case OrderStatus.ToBeCancel:
              return [rejectCacnelNextStatus, OrderStatus.CancelProcessing]
            case 9:
            case OrderStatus.Departed:
              return [OrderStatus.Confirmed]
            case 10:
            case OrderStatus.RedeemByCH:
              return []
            default:
              return []
          }
        },
        get orderStatusTitle() {
          switch (self.orderStatus) {
            case -2:
              return OrderStatus.Invalid
            case 0:
              return OrderStatus.Canceled
            case 1:
              return OrderStatus.OnHold
            case 2:
              return OrderStatus.New
            case 3:
              return OrderStatus.Pending
            case 4:
              return OrderStatus.Confirmed
            case 5:
              return OrderStatus.CheckIn
            case 6:
              return OrderStatus.NoShow
            case 7:
              return OrderStatus.CancelProcessing
            case 8:
              return OrderStatus.ToBeCancel
            case 9:
              return OrderStatus.Departed
            case 10:
              return OrderStatus.RedeemByCH
            case 11:
              return OrderStatus.SpecialCancelByCh
            case 12:
              return OrderStatus.CloseByCh
            default:
              return ''
          }
        },
        get orderPayStatusTitle() {
          return formatPayStatus(self.payStatus)[1]
        },
        get orderAcceptPayTypeList() {
          if (self.paymentMethodList == null) {
            return []
          }
          if (self.orderPayStatusTitle === PayStatus.NoPaymentNeeded) {
            return ['COMMON.FIELD_HYPHEN_EMPTY']
          }
          return self.paymentMethodList.map((paymentMethod) =>
            paymentMethod.includes(DeviceCustomPayWayKey)
              ? paymentMethod
              : `PAYMENT.${paymentMethod.replace('.', '_')}`
          )
        },
        get orderTimezone() {
          return `UTC ${formatTimezone(self.timezoneTsDiff)}`
        },
        get opName() {
          return self.opAccountFirstName
            ? formatName(self.opAccountFirstName, self.opAccountLastName)
            : ''
        },
        get formatedSource() {
          return !_.isEmpty(self.distributorUuid)
            ? 'DISTRI'
            : (self.platformName || self.saleFrom || '').toUpperCase()
        },
        get customerName() {
          return formatName(self.contactFirstName, self.contactLastName, self.contactGender)
        },
        get bookingDateTime() {
          return `${formatDate(self.orderTs * 1000)} ${formatTime(self.orderTs * 1000)}`
        },
        get sessionStartDateTime() {
          return `${self.sessionStartDate} ${self.sessionStartTime.slice(
            0,
            2
          )}:${self.sessionStartTime.slice(2)}`
        },
        get sessionEndDateTime() {
          return `${self.sessionEndDate} ${self.sessionEndTime.slice(
            0,
            2
          )}:${self.sessionEndTime.slice(2)}`
        },
        get contactGender() {
          return (
            _.get(self.contactInfo, '10662538-eef3-4171-9972-41300fa473d9') &&
            (self.contactInfo['10662538-eef3-4171-9972-41300fa473d9'] === 'ORDER.GENDER_REFUSE' ||
            self.contactInfo['10662538-eef3-4171-9972-41300fa473d9'] === 'ORDER.GENDER_OTHER'
              ? ' '
              : self.contactInfo['10662538-eef3-4171-9972-41300fa473d9'] === 'ORDER.GENDER_MALE'
              ? 'male'
              : 'female')
          )
        },
        get purchasedItems() {
          return _.compact(
            self.purchaseContent.map(
              ({ identityUuid, label, quantity, pricePolicyUuid, itemUuid }) => {
                let content
                let type = PricingPolicyType.ByItem
                if (label) {
                  content = _.get(self.priceConfig.ITEM.get(pricePolicyUuid), 'policy', []).find(
                    (policy) =>
                      policy.itemUuid && itemUuid
                        ? policy.itemUuid === itemUuid
                        : policy.label === label
                  )
                } else {
                  type = PricingPolicyType.ByPerson
                  content = self.priceConfig.PERSON.get(identityUuid)
                }
                return quantity !== 0
                  ? {
                      type,
                      identityUuid,
                      content,
                      quantity,
                      pricePolicyUuid
                    }
                  : null
              }
            )
          )
        },
        get distributorInfo() {
          const { channelStore } = getRoot(self)
          const distributor = channelStore.distributors.get(self.distributorUuid)
          return distributor
        },
        get chargeDetail() {
          return _.isEmpty(self.chargeCurrencyUuid)
            ? {}
            : {
                currencyUuid: self.chargeCurrencyUuid,
                amount: self.chargeAmount,
                balance: self.chargeBalance,
                paid: self.chargePaid,
                refund: self.chargeRefund,
                totalFee: self.chargeTotalFee,
                totalNetAmount: self.chargeTotalNetAmount
              }
        },
        get headCount() {
          return self.purchasedItems.reduce((sum, { quantity, type, content }) => {
            const targetQuantityWeight =
              type === PricingPolicyType.ByItem ? 1 : content.quantityWeight
            return sum + quantity * targetQuantityWeight
          }, 0)
        },
        get product() {
          const { productStore } = getRoot(self)
          const product = resolveIdentifier(Product, productStore, self.productUuid)

          return product
        },
        get session() {
          const { sessionStore } = getRoot(self)
          const session = resolveIdentifier(Session, sessionStore, self.sessionUuid)
          return session
        },
        get payments() {
          if (!self._paymentsLoaded) {
            setTimeout(self.loadPayments, 0)
            return []
          }
          return self._payments
        },
        get notes() {
          if (!self._notesLoaded) {
            setTimeout(self.loadNotes, 0)
            return []
          }
          return self._notes
        },
        get actionHistory() {
          if (!self._actionHistoryLoaded) {
            setTimeout(self.loadActionHistory, 0)
            return []
          }
          return self._actionHistory
        },
        get receiptRecords() {
          if (!self._receiptRecordsLoaded) {
            setTimeout(self.loadReceiptRecords, 0)
            return []
          }
          return self._receiptRecords
        },
        get receiptTemplate() {
          if (!self._receiptTemplateLoaded) {
            setTimeout(self.loadReceiptTemplate, 0)
            return {}
          }
          return self._receiptTemplate
        },
        get _resources() {
          const resourceUuids = _.get(self, 'resourceUuids')
          if (!resourceUuids || resourceUuids.length === 0) {
            return resourceUuids
          }
          const resourceStore = getRoot(self).resourceStore
          const resource = resolveIdentifier(Resource, resourceStore.resources, resourceUuids[0])
          if (!resource) {
            setTimeout(() => resourceStore.loadResourceList({ id: resourceUuids.join(',') }), 0)
            return undefined
          }
          return _.compact(
            resourceUuids.map((uuid) => resolveIdentifier(Resource, resourceStore.resources, uuid))
          )
        },
        get resources() {
          if (!self._resources) {
            return self._resources
          }

          if (!self._resourceRemains) {
            setTimeout(self.loadResourceRemain, 0)
            return self._resources
          }

          return self._resources.map((resource) => {
            const remain = _.get(self._resourceRemains, `${resource.uuid}`)
            return {
              ...resource,
              ...{
                groupDetail: (resource.groupDetail || []).map((item) => ({
                  ...item,
                  available: _.get(remain, `detail.${item.uuid}.available`) || 0
                })),
                available: remain?.available ?? 0
              }
            }
          })
        },
        get shoppingCartList() {
          if (!self._shoppingCartList) {
            setTimeout(self.loadShoppingCartList, 0)
            return []
          }
          return self._shoppingCartList
        },
        get salesOption() {
          if (!self._salesOption) {
            setTimeout(() => {
              self.loadSalesOption(self.salesOptionUuid)
            }, 0)
            return null
          }
          return self._salesOption
        },
        get exchangeValidity() {
          if (!self.salesOption) {
            ;(() => self.product)()
          }
          return self.salesOption?.exchangeValidity
        },
        get productSnapshot() {
          if (!self._productSnapshot) {
            setTimeout(self.loadProductSnapshot, 0)
          }
          return self._productSnapshot
        },
        get bookingInfoDetailValue() {
          return {
            ORDER: _.get(self.bookingInfoDetail, 'ORDER', []),
            PARTICIPANT: _.every(_.get(self.bookingInfoDetail, 'PARTICIPANT', []), (item) =>
              _.isEmpty(item)
            )
              ? []
              : _.get(self.bookingInfoDetail, 'PARTICIPANT')
          }
        },
        get editable() {
          const [, orderStatusString] = formatStatus(self.orderStatus)
          const paymentMethod = self.gatewayPayWay?.split?.('.')[1]
          const isAllowedSaleFrom =
            [OrderSaleFromType.MySite, OrderSaleFromType.Admin, OrderSaleFromType.Widget].includes(
              self.saleFrom
            ) || !_.isEmpty(self.distributorUuid)
          const isAllowedOrderStatus = [
            OrderStatus.OnHold,
            OrderStatus.New,
            OrderStatus.Pending,
            OrderStatus.Confirmed,
            OrderStatus.ToBeCancel
          ].includes(orderStatusString) // 保留中、新訂單、待確認、已確認、取消申請中
          const isOfflinePaymentMethod = [
            PaymentMethod.ATM,
            PaymentMethod.CVS,
            PaymentMethod.BANK
          ].includes(paymentMethod)
          const hasPaidOfflinePayment = self.payments.some(
            (eachPaymentRecord) => eachPaymentRecord?.paymentType?.split('.')?.[1] === paymentMethod
          )

          return isOfflinePaymentMethod
            ? hasPaidOfflinePayment
            : isAllowedSaleFrom && isAllowedOrderStatus
        },
        get redeemStatus() {
          if (!self.isRedeemType) {
            return RedeemStatus.All
          }

          const { redeemTimes, redeemTimesLimit } = _.get(self, 'voucherData.0.uuid')
            ? _.reduce(
                self.voucherData,
                (result, { redeemTimes, redeemTimesLimit }) => {
                  return {
                    redeemTimes: result.redeemTimes + redeemTimes,
                    redeemTimesLimit: result.redeemTimesLimit + redeemTimesLimit
                  }
                },
                { redeemTimes: 0, redeemTimesLimit: 0 }
              )
            : { redeemTimes: 0, redeemTimesLimit: 0 }

          if (redeemTimes === 0) {
            return RedeemStatus.None
          }

          if (redeemTimes === redeemTimesLimit) {
            return RedeemStatus.All
          }

          return RedeemStatus.Partial
        },
        get redeemLogList() {
          return _.reduce(
            self.voucherData,
            (
              result,
              { redeemLog, uuid: voucherUuid, redeemTimes, redeemTimesLimit, voucherSerial }
            ) => {
              return [
                ...result,
                ..._.map(redeemLog, (log) => {
                  return { ...log, voucherUuid, voucherSerial }
                })
              ]
            },
            []
          )
        },
        get isRedeemType() {
          const isVoucherType = [VoucherType.Default, VoucherType.Custom].includes(self.voucherType)
          const isSelfUploadType = +self?.voucherType === VoucherType.Upload
          const isUploadQrCode =
            +self?.voucherSetting?.qRCodeType === VoucherQRCodeType.Custom_QR_Code
          const isAsoviewType = self.productSysIdentity === SystemIdentity.ASOVIEW
          return (
            isVoucherType &&
            !isSelfUploadType &&
            !isUploadQrCode &&
            !isAsoviewType &&
            self.hasQRCode
          )
        },
        get productSysIdentity() {
          if (
            self.voucherType === 4 &&
            _.isString(self.sysIdentity) &&
            self.sysIdentity.toUpperCase() === SystemIdentity.ASOVIEW
          ) {
            return SystemIdentity.ASOVIEW
          }
          return SystemIdentity.REZIO
        },
        get repeatRedeemVoucherUuids() {
          if (self.voucherSetting?.repeatRedeem !== 2) {
            return []
          }

          const accountUuid = getRoot(self).core?.session?.account?.uuid
          return _.keys(
            _.groupBy(
              _.filter(self.redeemLogList, ({ account }) => account.uuid === accountUuid),
              'voucherUuid'
            )
          )
        },
        get unfinishedVoucher() {
          return _.compact(
            _.map(self?.voucherData, (voucher) =>
              voucher?.redeemTimesLimit > voucher?.redeemTimes ? voucher : undefined
            )
          )
        },
        get platformName() {
          return !_.isEmpty(self.platform) ? self.affiliate || self.platform : ''
        }
      },
      self,
      [{ name: 'session', type: Session, identifierName: 'sessionUuid' }]
    )
  )
  .actions((self) => {
    const rootStore = getRoot(self)

    const loadSalesOption = flow(function* loadSalesOption(id) {
      try {
        //NOTE: X-Switch-SalesOptionPublishedSetting 後端用來判別是否要參考套餐顯示設定用的 header
        const { data } = yield getEnv(self).api.get(`product/${self.productUuid}/salesOption`, {
          headers: {
            'X-Switch-SalesOptionPublishedSetting': 0
          }
        })[0]
        self._salesOption = data.find((so) => so.uuid === id)
      } catch (error) {
        console.error(error)
        self._salesOption = null
      }
    })

    const updateBookingInfo = flow(function* updateBookingInfo(bookingInfo) {
      try {
        const route = self.isOnRequestOrder ? 'onRequestOrder' : 'order'
        yield getEnv(self).api.put(`${route}/${self.orderNo}/bookingDetail`, bookingInfo)[0]
        yield reload()
      } catch (error) {
        console.error(error)
      }
    })

    const loadShoppingCartList = flow(function* loadShoppingCartList() {
      if (self.shoppingCartNo) {
        const result = yield getEnv(self).api.get(
          `/order/shoppingCart/${self.shoppingCartNo}/list`
        )[0]
        self._shoppingCartList = result.data
        return
      }
      return []
    })

    const loadResourceRemain = flow(function* loadResourceRemain() {
      const result = yield getEnv(self).api.get(
        `/resource/remain?resourceUuid=${self._resources.map((r) => r.uuid).join(',')}&s=${moment(
          self.sessionStartDateTime
        ).format('YYYY-MM-DDTHH:mm:ss')}&e=${moment(self.sessionEndDateTime).format(
          'YYYY-MM-DDTHH:mm:ss'
        )}`
      )[0]
      self._resourceRemains = result.data
    })

    const sendMail = flow(function* sendMail(status, body) {
      const payload = qs.stringify(body)
      const targetNo = [
        OrderMailType.OrderReceiptShoppingCart,
        OrderMailType.CreatedCartOrderUnpaid
      ].includes(status)
        ? self.shoppingCartNo
        : self.orderNo
      const url = `mail/${status}/${targetNo}${_.isEmpty(payload) ? '' : `?${payload}`}`
      const result = yield getEnv(self).api.get(url)[0]
      yield reload()
      return result
    })

    const voucherRedeem = flow(function* voucherRedeem(body, type = 1, shouldReload = true) {
      const redeemBody = Object.assign({}, body, { type, device: Platform.OS.toUpperCase() })
      const result = yield getEnv(self).api.put(
        `order/${self.orderNo}/voucher/redeem`,
        redeemBody
      )[0]
      if (shouldReload) {
        yield reload()
      }

      if (result.data?.newStatus) {
        self.orderStatus = result.data?.newStatus
      }

      return result
    })

    const generateVoucher = flow(function* generateVoucher(payload) {
      const result = yield getEnv(self).api.post(
        `order/${self.orderNo}/voucher/generate`,
        payload
      )[0]
      yield reload()
      return result
    })

    const deleteVoucher = flow(function* deleteVoucher(targetVoucher) {
      const result = yield getEnv(self).api.delete(
        `order/${self.orderNo}/voucher/${targetVoucher}`
      )[0]
      yield reload()
      return result
    })

    const updateContact = flow(function* updateContact(contact) {
      try {
        const route = self.isOnRequestOrder ? 'onRequestOrder' : 'order'
        yield getEnv(self).api.put(`${route}/${self.orderNo}/contact`, contact)[0]
        yield reload()
      } catch (error) {
        console.error(error)
      }
    })

    const updateVoucherInfoNote = flow(function* updateVoucherInfoNote(payload) {
      const result = yield getEnv(self).api.put(
        `order/${self.orderNo}/voucher/noteAsInfo`,
        payload
      )[0]
      yield reload()
      return result
    })

    const updateVoucherItem = flow(function* updateVoucherItem(payload) {
      const result = yield getEnv(self).api.put(`order/${self.orderNo}/voucher/detail`, payload)[0]
      return result
    })

    /**
     * 更新訂單
     * @param {Boolean} confirmBreakRule 確認過要跳過規則
     * @param {Boolean} confirmOverSeat 確認過要略過位置不足
     * @param {Boolean} confirmOverResource 確認過要略過資源不足
     * - false 狀態下: 不知道有沒有不符合規範，所以也還沒有確認到底要不要跳過，會送回 APIErrorCode.Confirm 檢核跳 popup
     * - true 狀態下: 已經確認過要把這些不符合規範的狀況，全部跳過強制處理，會送回 APIErrorCode.Success 強制成單
     */
    const update = flow(function* update(changes, config) {
      const original = JSON.parse(
        JSON.stringify({
          productUuid: self.productUuid,
          sessionUuid: self.sessionUuid,
          purchaseContent: self.purchaseContent,
          extras: self.extras,
          amount: self.amount,
          bookingContact: self.contactInfo,
          bookingDetail: {
            pickupRequest: self.pickupRequest,
            preferLanguage: self.preferLanguage,
            bookingInfoDetail: self.bookingInfoDetail
          },
          confirmBreakRule: false,
          confirmOverSeat: false,
          confirmOverResource: false
        })
      )

      const payload = {
        ...original,
        ...config,
        ...changes,
        orderDate: moment().add(5, 'minutes').isAfter(moment(), 'day')
          ? moment().add(1, 'day').format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD')
      }

      yield getEnv(self).api.put(`order/${self.orderNo}`, payload)[0]
      yield reload()
    })

    function loadExtras() {
      rootStore.extraStore.loadExtras(self.extras.map(({ productExtraUuid }) => productExtraUuid))
    }

    function loadProduct() {
      self.productUuid && rootStore.productStore.loadProduct(self.productUuid)
    }

    function loadSession() {
      rootStore.sessionStore.loadSession(self.productUuid, self.sessionUuid)
    }

    const loadProductSnapshot = flow(function* loadProductSnapshot() {
      const result = yield getEnv(self).api.get(`order/${self.orderNo}/productSnapshot`)[0]
      self._productSnapshot = result.data
    })

    const loadNotes = flow(function* loadNotes() {
      try {
        if (self._notesLoading) {
          return
        }
        self._notesLoading = true
        const route = self.isOnRequestOrder ? 'onRequestOrder' : 'order'
        const result = yield getEnv(self).api.get(`${route}/${self.orderNo}/note`)[0]
        self._notes = result.data
        self._notesLoaded = true
        self._notesLoading = false
      } catch (error) {
        console.error(error)
      }
    })

    const addNote = flow(function* addNote(note) {
      try {
        const route = self.isOnRequestOrder ? 'onRequestOrder' : 'order'
        yield getEnv(self).api.post(`${route}/${self.orderNo}/note`, note)[0]
        self.loadNotes()
      } catch (error) {
        console.error(error)
      }
    })

    const loadActionHistory = flow(function* loadActionHistory() {
      try {
        if (self._actionHistoryLoading) {
          return
        }
        self._actionHistoryLoading = true
        const route = self.isOnRequestOrder ? 'onRequestOrder' : 'order'
        const result = yield getEnv(self).api.get(`${route}/${self.orderNo}/actionHistory`)[0]
        self._actionHistory = result.data.map((record) => ({
          ...record,
          actionType:
            record.actionType === 'AUTO_GENERATE_VOUCHER'
              ? OrderHistoryActionType.GenerateVoucher
              : record.actionType
        }))
        self._actionHistoryLoaded = true
        self._actionHistoryLoading = false
      } catch (error) {
        console.error(error)
      }
    })

    const loadReceiptRecords = flow(function* loadReceiptRecords() {
      if (self._receiptRecordsLoading) {
        return
      }
      self._receiptRecordsLoading = true
      const result = yield getEnv(self).api.get(`order/${self.orderNo}/receipt`)[0]
      self._receiptRecords = result.data
      self._receiptRecordsLoaded = true
      self._receiptRecordsLoading = false
    })

    const loadReceiptTemplate = flow(function* loadReceiptTemplate() {
      const twdUuid = '4fc314ab-a67a-436c-b0bc-88c72b9fbebd'
      const storeReceipt = yield getEnv(self).api.get('store/receiptSetting')[0]
      const valid =
        self.chargeCurrencyUuid === twdUuid &&
        [ReceiptType.Invoice, ReceiptType.Receipt].includes(
          storeReceipt?.data?.acceptReceiptType
        ) &&
        (_.isEmpty(self.shoppingCartDetail)
          ? self.discountedTotalAmount !== 0
          : self.shoppingCartDetail?.amount?.total !== 0) &&
        // 訂單狀態不得為 已失效 -2 / 初始訂單 -1 / 待處理訂單 null
        self.orderStatus &&
        +self.orderStatus >= 0
      if (self._receiptTemplateLoading || !valid) {
        return
      }
      self._receiptTemplateLoading = true
      const result = yield getEnv(self).api.get(`order/${self.orderNo}/receiptTemplate`)[0]
      self._receiptTemplate = result.data
      self._receiptTemplateLoaded = true
      self._receiptTemplateLoading = false
    })

    const loadPayments = flow(function* loadPayments() {
      try {
        if (self.isOnRequestOrder) return // on request 不打這支
        self._paymentsLoaded = true
        const path = self.isOnRequestOrder ? 'onRequestOrder' : 'order'
        const result = yield getEnv(self).api.get(`${path}/${self.orderNo}/payment`)[0]
        self._payments = result.data
      } catch (error) {
        console.error(error)
      }
    })

    const addPayment = flow(function* addPayment(payment) {
      const result = yield getEnv(self).api.post(`order/${self.orderNo}/payment`, payment)[0]
      result && self.loadPayments()
      self.reload()
    })

    const refundPayment = flow(function* refundPayment(refund) {
      const result = yield getEnv(self).api.post(`order/${self.orderNo}/refund`, refund)[0]
      if (result) {
        setTimeout(() => {
          self.loadPayments()
          self.reload()
        }, 500)
      }
    })

    const syncPlatformPayments = flow(function* syncPlatformPayments(payload) {
      // 同步金流平台後台的付款紀錄回 account book
      try {
        yield getEnv(self).api.post(`order/${self.orderNo}/syncLedger`, payload)[0]
        yield self.loadPayments()
        yield self.reload()
      } catch (error) {
        console.error(error)
      }
    })

    const updateResource = flow(function* updateResource(allocation) {
      yield getEnv(self).api.put(`order/${self.orderNo}/resource`, allocation)[0]
      self.reload()
    })

    const reload = flow(function* reload(options = {}) {
      yield getRoot(self).orderStore.loadOrder(self.orderNo)
      options.extras && loadExtras()
      options.product && loadProduct()
      options.session && loadSession()
    })

    const updateStatus = flow(function* updateStatus(params, shouldReload = true) {
      const result = yield getEnv(self).api.put(`order/${self.orderNo}/status`, params)[0]
      if (shouldReload) {
        yield reload()
      }
      return result
    })

    const updateOnRequestStatus = flow(function* updateOnRequestStatus(
      params,
      shouldReload = true
    ) {
      try {
        const result = yield getEnv(self).api.put(
          `onRequestOrder/${self.orderNo}/status`,
          params
        )[0]
        if (shouldReload) {
          yield reload()
        }
        return result
      } catch (error) {
        console.error(error)
        return error
      }
    })

    const confirmOnRequestOrder = flow(function* confirmOnRequestOrder(isForce = false) {
      try {
        const forceConfirmParams = {
          confirmBreakRule: isForce,
          confirmOverSeat: isForce,
          confirmOverResource: isForce
        }
        const result = yield getEnv(self).api.put(
          `onRequestOrder/${self.orderNo}/order`,
          forceConfirmParams
        )[0]
        yield reload()
        yield loadPayments()
        return result
      } catch (error) {
        console.error(error)
        throw error
      }
    })

    const rejectCancellation = flow(function* rejectCancellation() {
      yield getEnv(self).api.get(`mail/orderCancelRejected/${self.orderNo}`)[0]
      yield reload()
    })

    const updateCancelNote = flow(function* updateCancelNote(params, shouldReload = true) {
      try {
        const route = self.isOnRequestOrder ? 'onRequestOrder' : 'order'
        const result = yield getEnv(self).api.put(`${route}/${self.orderNo}/cancelNote`, params)[0]
        if (shouldReload) {
          yield reload()
        }
        return result
      } catch (error) {
        console.error(error)
      }
    })

    const updateOrderTag = flow(function* updateOrderTag(params) {
      try {
        const route = self.isOnRequestOrder ? 'onRequestOrder' : 'order'
        const result = yield getEnv(self).api.put(`${route}/${self.orderNo}/orderTag`, params)[0]
        yield reload()
        return result
      } catch (error) {
        console.error(error)
      }
    })

    const updateReceiptStatus = flow(function* updateReceiptStatus(params) {
      const result = yield getEnv(self).api.put(`order/${self.orderNo}/receiptStatus`, params)[0]
      yield self.loadReceiptRecords()
      return result
    })

    const addCoupon = flow(function* addCoupon({ targetUuid, confirmBreakRule }) {
      if (self._couponAdding) return
      try {
        self._couponAdding = true
        yield getEnv(self).api.post(`order/${self.orderNo}/coupon/${targetUuid}`, {
          confirmBreakRule
        })[0]
        yield reload()
      } finally {
        self._couponAdding = false
      }
    })

    const removeCoupon = flow(function* removeCoupon({ targetUuid }) {
      yield getEnv(self).api.delete(`order/${self.orderNo}/coupon/${targetUuid}`)[0]
      yield reload()
    })

    return {
      loadExtras,
      loadProduct,
      loadSession,
      loadProductSnapshot,
      loadResourceRemain,
      loadShoppingCartList,
      loadNotes,
      addNote,
      loadPayments,
      addPayment,
      refundPayment,
      syncPlatformPayments,
      loadActionHistory,
      loadReceiptRecords,
      loadReceiptTemplate,
      update,
      updateResource,
      updateStatus,
      updateOnRequestStatus,
      confirmOnRequestOrder,
      updateReceiptStatus,
      rejectCancellation,
      updateBookingInfo,
      loadSalesOption,
      sendMail,
      voucherRedeem,
      generateVoucher,
      deleteVoucher,
      updateContact,
      updateVoucherItem,
      updateVoucherInfoNote,
      updateCancelNote,
      reload,
      updateOrderTag,
      addCoupon,
      removeCoupon,
      afterCreate() {
        const permission = getEnv(self).core?.permission
        if (permission.role !== ROLE_TITLE.REDEEM_STUFF) {
          loadExtras()
        }
        loadProduct()
      }
    }
  })

export function formatSourceConfig(source) {
  const sourceConfig = _.reduce(
    source,
    (result, value) => {
      const [sourceType, sourceValue, ...restSourceInfo] = value.split('_')

      switch (sourceType.toUpperCase()) {
        case ChannelType.Distributor.toUpperCase():
          return {
            ...result,
            source: result.source.includes(sourceType)
              ? result.source
              : `${result.source}${result.source ? ',' : ''}${sourceType}`,
            distributorUuids: `${result.distributorUuids}${
              result.distributorUuids ? ',' : ''
            }${sourceValue}`
          }
        case OrderSource.Affiliate:
          return {
            ...result,
            source: result.source.includes(sourceType)
              ? result.source
              : `${result.source}${result.source ? ',' : ''}${sourceType}`,
            affiliate: `${result.affiliate}${result.affiliate ? ',' : ''}${sourceValue}`
          }
        case OrderSource.Machine: {
          // Machine 要把底線以後的 sourceValue (KIOSK, POS 等等) 串在 source 後面
          // 加上 machineUuids
          const sourceUuid = restSourceInfo[0] ?? ''
          return {
            ...result,
            source: result.source.includes(sourceValue)
              ? result.source
              : `${result.source}${result.source ? ',' : ''}${sourceValue}`,
            machineUuids:
              result.machineUuids && sourceUuid
                ? `${result.machineUuids},${sourceUuid}`
                : `${result.machineUuids}${sourceUuid}`
          }
        }
        case OrderSource.Custom:
          return {
            ...result,
            source: result.source.includes(sourceType)
              ? result.source
              : `${result.source}${result.source ? ',' : ''}${sourceType}`,
            customSourceUuid: `${result.customSourceUuid}${result.customSourceUuid ? ',' : ''}${
              sourceValue ?? ''
            }`
          }
        default:
          return {
            ...result,
            source: result.source.includes(sourceType)
              ? result.source
              : `${result.source}${result.source ? ',' : ''}${sourceType}`,
            ...(sourceValue === 'TTD' && { utmSource: sourceValue })
          }
      }
    },
    { source: '', distributorUuids: '', affiliate: '', customSourceUuid: '', machineUuids: '' }
  )

  return sourceConfig
}

const OrderTag = types.model('OrderTag', {
  category: types.string,
  color: types.string,
  createdAt: types.string,
  display: types.boolean,
  editable: types.boolean,
  label: types.string,
  storeUuid: types.maybeNull(types.string),
  updatedAt: types.string,
  uuid: types.identifier
})

const OrderStore = types
  .model('OrderStore', {
    orders: types.map(Order),
    page: types.optional(Page, { source: 'orders' }),
    abnormalOrderPage: types.optional(Page, { source: 'orders' }),
    sourceOptionList: types.array(
      types.maybeNull(
        types.model('SourceOption', {
          id: types.string, // key
          category: types.string,
          isFlatten: types.optional(types.boolean, false),
          list: types.array(types.frozen())
        })
      )
    ),
    _orderTagList: types.map(OrderTag),
    _orderTagsListLoading: false,
    _orderTagsListLoaded: false,
    isLoading: true
  })
  .views((self) => ({
    get sourceOptions() {
      if (!self.sourceOptionList.length) {
        setTimeout(() => self.loadSourceOptions(), 0)
        return []
      }

      const { core } = getEnv(self)

      // custom source 屬於 admin 下的 group
      const adminGroup = [
        { label: core.i18n.t('COMMON.SOURCE_ADMIN_DEFAULT'), value: 'Admin_default' }
      ]
      self.sourceOptionList
        .find((option) => {
          return option.id === 'Custom'
        })
        ?.list.forEach((item) => adminGroup.push({ label: item.title, value: item.key }))

      return _.flatMap(
        self.sourceOptionList.filter((source) => source.category !== 'custom'),
        ({ isFlatten, list, category, id }) =>
          isFlatten
            ? _.map(list, (listItem) => {
                const option = {
                  label: core.i18n.t(`COMMON.SOURCE_${listItem.title.toUpperCase()}`),
                  value: listItem.key
                }

                if (category === 'default' && listItem.key === 'Admin') {
                  option.group = adminGroup
                }

                return option
              })
            : {
                label: core.i18n.t(`COMMON.SOURCE_${category.toUpperCase()}`),
                value: id,
                group: _.map(list, (subOption) => ({
                  label: subOption.title.toUpperCase(),
                  value: `${id}_${subOption.key}`
                }))
              }
      )
    },
    get orderTagList() {
      if (!self._orderTagsListLoaded && !self._orderTagsListLoading) {
        setTimeout(self.loadOrderTagList, 0)
        return []
      }
      return self._orderTagList
    }
  }))
  .actions((self) => {
    const { api } = getEnv(self)

    function markLoading(loading) {
      self.isLoading = loading
    }

    function setOrder(order) {
      // const {
      //   sessionUuid,
      //   sessionWeekday,
      //   sessionStartDate,
      //   sessionStartTime,
      //   sessionEndDate,
      //   sessionEndTime,
      //   sessionStartTs,
      //   productUuid,
      //   productName,
      //   productCode,
      //   salesOptionUuid,
      //   salesOptionName
      // } = order

      // rootStore.sessionStore.setSession({
      //   sessionUuid,
      //   sessionWeekday,
      //   sessionStartDate,
      //   sessionStartTime,
      //   sessionEndDate,
      //   sessionEndTime,
      //   sessionStartTs
      // })

      // rootStore.productStore.setProduct({
      //   uuid: productUuid,
      //   title: productName,
      //   productCode,
      //   ..._.set({}, `salesOptions.${salesOptionUuid}`, {
      //     uuid: salesOptionUuid,
      //     title: salesOptionName
      //   })
      // })

      self.orders.put(
        Object.assign(
          {},
          self.orders.has(order.orderNo) && getSnapshot(self.orders.get(order.orderNo)),
          order
        )
      )
    }

    function setOrderTag(orderTag) {
      self._orderTagList.put(
        Object.assign(
          {},
          self._orderTagList.has(orderTag.uuid) &&
            getSnapshot(self._orderTagList.get(orderTag.uuid)),
          orderTag
        )
      )
    }

    const loadOrder = flow(function* loadOrder(orderNo) {
      markLoading(true)
      try {
        const result = yield api.get(`order/${orderNo}`)[0]
        self.setOrder(result.data)
        markLoading(false)
        return self.orders.get(orderNo) // result.data
      } catch (error) {
        console.error(error)
        markLoading(false)
        if (error.code === APIErrorCode.NotFound) {
          const { core } = getEnv(self)
          core.setRuntimeError(core.i18n.t('ORDER.API_EXCEPTION_ORDER_NOT_FOUND'), 'validation')
        }
      }
    })

    const loadOrderPage = flow(function* loadOrderPage(params = {}) {
      const {
        page = 1,
        sessionUuid = '',
        num = 50,
        text,
        status,
        source = '',
        dateType = 'booking',
        from,
        to,
        sort = 'bookingDESC',
        tsDiff = -(new Date().getTimezoneOffset() * 60),
        payStatus,
        paymentType,
        opStatus,
        orderTagUuid,
        reconciliationStatus,
        searchType = 'orderNo',
        onRequestOrderStatus
      } = params

      const sourceConfig = formatSourceConfig(source.split(','))

      markLoading(true)
      try {
        const result = yield api.get(`order/${page}`, {
          params: {
            ...sourceConfig,
            sessionUuid,
            num,
            [searchType]: text?.trim(),
            status,
            dateType,
            from,
            to,
            sort,
            tsDiff,
            payStatus,
            paymentType,
            opStatus,
            orderTagUuid,
            reconciliationStatus,
            onRequestOrderStatus
          }
        })[0]
        self.updatePage(result.data)
      } catch (error) {
        console.error(error)
      }
      markLoading(false)
    })

    const createOrder = flow(function* createOrder(order, force) {
      markLoading(true)
      const result = yield api.post('order', {
        ...order,
        ...(force
          ? {
              confirmBreakRule: true,
              confirmOverSeat: true,
              confirmOverResource: true
            }
          : {})
      })[0]
      self.setOrder(result.data)
      markLoading(false)
      return result.data.orderNo
    })

    /**
     * 查詢訂單編號
     * * 雖然兩種是同一隻api，但brain是從路由認uuid就分開處理的
     * @param {string} code 可以是Voucher UUID或是第三方訂單編號
     * @returns {Object} result 回傳結果
     * @returns {string} result.orderNo rezio訂單編號
     */
    const loadOrderNo = flow(function* loadOrderNo(code) {
      const result = yield getEnv(self).api.get(`/order/orderNo/${code}`)[0]
      return result.data
    })

    const redeemVoucher = flow(function* redeemVoucher(voucherUuid, body, transaction) {
      let redeemResult
      const spanRedeem = transaction?.startChild({ op: 'redeem-voucher' })
      try {
        redeemResult = (yield getEnv(self).api.put(
          `/order/redeemStatus/${voucherUuid}`,
          { ...body, device: Platform.OS.toUpperCase() },
          { skipErrorHandle: true }
        )[0]).data
      } catch (e) {
        spanRedeem?.finish()
        spanRedeem?.setStatus('unknown_error')
        const errorType = e.data?.[0]?.type
        if (e.code === APIErrorCode.Confirm) {
          throw e.code
        }
        switch (errorType) {
          case 'repeatVoucherRedeem':
          case 'voucherIsInvalid':
          case 'voucherIsNotYetAvailable':
            throw new RuntimeError(`ERROR_MESSAGE.TYPE_${errorType.toUpperCase()}`)
          case 'orderNotExists':
            throw new RuntimeError('SCAN.DISABLE_REDEEM_ERROR')
          default:
            throw new RuntimeError('ERROR_MESSAGE.TYPE_VOUCHERREDEEMFAIL')
        }
      }
      if (redeemResult.status !== true) {
        spanRedeem?.setStatus('unknown_error')
        spanRedeem?.finish()
        throw new RuntimeError('ERROR_MESSAGE.TYPE_VOUCHERREDEEMFAIL')
      }
      spanRedeem?.finish()
      const span = transaction?.startChild({ op: 'load-order' })
      yield self.loadOrder(redeemResult.orderNo)
      span?.finish()
      return self.orders.get(redeemResult.orderNo)
    })

    const addOwnerIntoOrders = flow(function* addOwnerIntoOrders(data, callback) {
      try {
        markLoading(true)
        const { orderNos, onRequestOrderNos } = data
        const results = yield Promise.all([
          ...(orderNos?.length > 0
            ? [
                api.put('order/addOpIntoOrder', {
                  orderNos
                })[0]
              ]
            : []),
          ...(onRequestOrderNos?.length > 0
            ? [
                api.put('onRequestOrder/addOperator', {
                  orderNos: onRequestOrderNos
                })[0]
              ]
            : [])
        ])
        if (results.every((result) => result.errorCode === APIErrorCode.Success)) {
          callback?.()
        }
      } catch (e) {
        console.error(e)
      } finally {
        markLoading(false)
      }
    })

    const removeOwnerFromOrders = flow(function* removeOwnerFromOrders(
      orders,
      isOnRequestOrder = false
    ) {
      try {
        markLoading(true)
        const route = isOnRequestOrder ? 'onRequestOrder/removeOperator' : 'order/removeOpFromOrder'
        const result = yield api.put(route, {
          orderNos: orders
        })[0]
        return result
      } catch (e) {
        console.error(e)
      } finally {
        markLoading(false)
      }
    })

    const loadAbnormalOrder = flow(function* loadAbnormalOrder(uuid, skipErrorHandle = false) {
      try {
        markLoading(true)
        const result = yield api.get(`abnormalOrder/${uuid}`, { skipErrorHandle })[0]
        const formatedResult = formatAbnormalOrder(result.data)
        self.setOrder(formatedResult)
        return formatedResult
      } catch (error) {
        console.error(error)
      } finally {
        markLoading(false)
      }
    })

    const deleteAbnormalOrder = flow(function* deleteAbnormalOrder(uuid) {
      markLoading(true)
      yield api.delete(`abnormalOrder/${uuid}`)[0]
      self.orders.delete(uuid)
      markLoading(false)
    })

    const loadAbnormalOrderPage = flow(function* loadAbnormalOrderPage(page = 1, filters = {}) {
      markLoading(true)
      const { num = 20, ...params } = filters
      const result = yield api.get(`/abnormalOrder/${page}`, { params: { num, ...params } })[0]
      const formatedResult = result?.data
        ? {
            ...result.data,
            list: _.map(result.data?.list, (order) => formatAbnormalOrder(order))
          }
        : {}
      self.updatePage(formatedResult, 'abnormalOrderPage')
      markLoading(false)
    })

    const loadSourceOptions = flow(function* loadSourceOptions() {
      const result = yield api.get('/store/filter')[0]
      const [[{ list: utmCategories } = { list: [] }], normalCategory] = partition(
        result.data,
        propEq('category', 'utmSource')
      )

      const formattedResult = _.map(normalCategory, ({ category, key, list, ...sourceOption }) => {
        switch (category.toUpperCase()) {
          case OrderSource.Distributor:
            return {
              ...sourceOption,
              category,
              id: key,
              list: _.map(
                list,
                ({ distributorUuid, distributorName, contactLastName, contactFirstName }) => ({
                  key: distributorUuid,
                  title:
                    distributorName ||
                    `${contactLastName ? `${contactLastName} ` : ''}${contactFirstName}` ||
                    distributorUuid
                })
              )
            }
          case OrderSource.Affiliate:
            return {
              ...sourceOption,
              category,
              id: key,
              list: _.map(list, ({ key: subOptionKey }) => ({
                key: `${key}_${subOptionKey}`,
                title: subOptionKey
              }))
            }
          case OrderSource.Custom:
            return {
              ...sourceOption,
              category,
              id: key,
              list: _.map(list, ({ uuid, title }) => ({
                key: `Custom_${uuid}`,
                title
              }))
            }
          case ChannelType.KIOSK:
          case ChannelType.POS:
            return {
              ...sourceOption,
              category,
              id: 'Machine',
              list: _.map(list, ({ uuid, title }) => ({
                key: `${category.toUpperCase()}_${uuid}`,
                title
              }))
            }
          default:
            return {
              ...sourceOption,
              category,
              id: key,
              list: _.map(list, ({ key: subOptionKey }) => ({
                key: subOptionKey,
                title: subOptionKey
              }))
            }
        }
      })

      utmCategories.forEach(({ saleFrom, key: subOptionKey }) => {
        const target = formattedResult.find(({ list }) => list.some(propEq('key', saleFrom)))
        target.list = target.list.reduce(
          (acc, ele) =>
            acc
              .concat(ele)
              .concat(
                ele.key === saleFrom ? { key: `${ele.key}_TTD`, title: `${ele.key}_TTD` } : []
              ),
          []
        )
      })

      self.sourceOptionList = formattedResult
    })

    const loadOrderTagList = flow(function* loadOrderTagList() {
      if (self._orderTagsListLoaded || self._orderTagsListLoading) {
        return
      }
      self._orderTagsListLoading = true
      const result = yield api.get('/orderTag')[0]
      _.each(result?.data, (orderTag) => setOrderTag(orderTag))
      self._orderTagsListLoaded = true
      self._orderTagsListLoading = false
    })

    return {
      setOrder,
      createOrder,
      loadOrder,
      loadOrderNo,
      loadOrderPage,
      redeemVoucher,
      addOwnerIntoOrders,
      removeOwnerFromOrders,
      loadAbnormalOrder,
      deleteAbnormalOrder,
      loadAbnormalOrderPage,
      loadSourceOptions,
      setOrderTag,
      loadOrderTagList,
      afterCreate() {
        autorun(() => {
          const isPlanPermissionLoaded = _.get(getRoot(self), 'core.session.isPlanPermissionLoaded')
          if (isPlanPermissionLoaded) {
            const permission = getEnv(self).core?.permission
            const isValidPermission =
              permission?.hasRolePermission(
                PERMISSION_TITLE.ABNORMAL_ORDER,
                PERMISSION_ACTION.GET
              ) && permission?.planFeatures.includes(PLAN_FEATURES.CHANNEL)
            isValidPermission && self.loadAbnormalOrderPage()
          }
        })
      }
    }
  })
  .extend((self) => {
    const page = extendPage(self, 'orders', 'setOrder')
    return page
  })

const Account = types.model('Account', {
  account: types.string,
  activeStatus: types.number,
  createdAt: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  firstName: types.optional(types.string, ''),
  isRobot: types.optional(types.boolean, false),
  jobTitle: types.maybeNull(types.string),
  languageUuid: types.maybeNull(types.string),
  lastLogin: types.maybeNull(types.string),
  lastLoginTime: types.maybeNull(types.string),
  lastName: types.optional(types.string, ''),
  phone: types.maybeNull(types.string),
  phoneITI: types.maybeNull(types.number),
  photo: types.maybeNull(types.string),
  systemPermission: types.number,
  timezoneUuid: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  uuid: types.identifier,
  verifyStatus: types.number
})

const LoginSession = types
  .model('LoginSession', {
    token: types.string,
    account: types.maybe(Account),
    profile: types.frozen(),
    notifyToken: types.maybe(types.string),
    planPermission: types.array(types.string),
    extraPermission: types.maybeNull(types.array(types.string)),
    menu: types.optional(types.array(types.string), ['UNSET']),
    _stores: types.maybe(types.frozen())
  })
  .preProcessSnapshot((snapshot) => {
    if (snapshot) {
      const { account, ...profile } = auth.decodeToken(snapshot.token)
      return {
        ...snapshot,
        account,
        profile
      }
    }
    return snapshot
  })
  .views((self) => ({
    get stores() {
      if (!self._stores) {
        setTimeout(self.loadStores, 0)
      }
      return self._stores
    },
    get store() {
      return (
        self.profile && _.find(self.stores, (item) => item.storeUuid === self.profile.storeUuid)
      )
    },
    get isPlanPermissionLoaded() {
      return self.store?.authTier === TIERS.NONE || self.planPermission?.length > 0
    }
  }))
  .actions((self) => {
    const { core } = getEnv(self)
    const data = DataStore.getInstance({}, core)
    const rootStore = getRoot(self)
    const loadStores = flow(function* loadStores() {
      const result = yield getEnv(self).api.get('account/relationList')[0]
      self._stores = _.map(result.data, (store) => {
        const { priceTier: originalPriceTier, ...rest } = store
        const { priceTier, tierPlatform, authTier } = formatPriceTier(originalPriceTier)
        return {
          ...rest,
          priceTier,
          tierPlatform,
          authTier
        }
      })
    })

    const loadMenu = flow(function* loadMenu() {
      const result = yield getEnv(self).api.get('account/menu')[0]
      self.menu.replace(result.data)
    })

    const saveMenu = flow(function* saveMenu(items) {
      yield getEnv(self).api.put('account/menu', { items })[0]
      self.menu.replace(items)
    })

    const loadAccount = flow(function* loadAccount() {
      const result = yield getEnv(self).api.get('account/profile')[0]
      self.account = Object.assign({}, self.account, result.data.account)
    })

    const loadPlanPermission = flow(function* loadPlanPermission() {
      const result = yield getEnv(self).api.get('store/planPermission')[0]
      self.extraPermission = result.data.extraPermission
      self.planPermission = result.data.planPermission
    })

    const authNotifyToken = flow(function* authNotifyToken(uuid, token) {
      self.notifyToken = yield authNotify(uuid, token)
    })

    const afterSetToken = () => {
      const { account, ...profile } = auth.decodeToken(self.token)
      self.account = account
      self.profile = profile
      if (!self.notifyToken) {
        authNotifyToken(account.uuid, self.token)
      }
      Promise.all([loadPlanPermission(), loadMenu(), loadStores(), data.loadStoreRegional()]).then(
        () => {
          rootStore.changeStore()
          userIdTrigger(account.uuid)
          userPropertyTrigger({
            store_title: self.store.storeTitle,
            price_tier: self.profile.priceTier
          })
        }
      )
    }

    return {
      loadStores,
      loadAccount,
      authNotifyToken,
      loadPlanPermission,
      saveMenu,
      setToken(token) {
        if (_.isEmpty(token)) {
          authNotify()
          destroy(self)
          rootStore.changeStore()
        } else if (token !== self.token) {
          self.token = token
          afterSetToken()
        }
      },
      afterAttach() {
        if (self.token) {
          afterSetToken()
        }
      }
    }
  })

const Subscription = types
  .model('Subscription', {
    info: types.frozen(),
    sortedPlanGroup: types.frozen(),
    inUsingPlan: types.frozen()
  })
  .actions((self) => {
    const updateBillingInfo = flow(function* updateBillingInfo(update) {
      const result = yield getEnv(self).api.put('/subscription/bill', update)[0]
      self.info = {
        ...self.info,
        ...result.data
      }
    })

    const changeCreditCard = flow(function* changeCreditCard(update) {
      yield getEnv(self).api.post('/subscription/cardchange', update)[0]
    })

    const subscribe = flow(function* subscribe(update) {
      const result = yield getEnv(self).api.post('/subscription/subscribe', {
        ...update,
        isTrial: false
      })[0]
      return result
    })

    const trial = flow(function* trial(update) {
      yield getEnv(self).api.post('/subscription/subscribe', { ...update, isTrial: true })[0]
    })

    const changeGeneralTierToTrial = flow(function* changeGeneralTierToTrial(update) {
      // 只有 方案為KKDay,Special 且 沒有試用紀錄的店家，申請試用 Rezio 方案 (此 API 僅專家帳號權限)
      yield getEnv(self).api.post('/subscription/trial', { ...update })[0]
    })

    const upgrade = flow(function* upgrade(update) {
      const result = yield getEnv(self).api.post('/subscription/upgrade', update)[0]
      return result
    })

    function reload() {
      getParent(self).loadSubscription()
    }

    return {
      changeCreditCard,
      subscribe,
      trial,
      changeGeneralTierToTrial,
      upgrade,
      updateBillingInfo,
      reload
    }
  })

const Announcement = types.model('Announcement', {
  uuid: types.string,
  content: types.string,
  title: types.string,
  startTs: types.number,
  endTs: types.number
})

const Core = types
  .model('Core', {
    session: types.maybe(LoginSession),
    announcements: types.maybe(types.array(Announcement)),
    switchingStore: types.optional(types.boolean, false),
    _subscription: types.maybe(Subscription)
  })
  .views((self) => ({
    get subscription() {
      if (!self._subscription) {
        setTimeout(self.loadSubscription, 0)
      }
      return self._subscription
    }
  }))
  .actions((self) => {
    const { core } = getEnv(self)
    const bindDevicePlans = [
      TIERS.PREMIUM_PLUS_ONE,
      TIERS.PREMIUM_PLUS_TWO,
      TIERS.PREMIUM_PLUS_ONE_YEAR,
      TIERS.PREMIUM_PLUS_TWO_YEAR
    ]

    function setToken(token) {
      if (self._subscription) {
        destroy(self._subscription)
      }
      if (self.session) {
        self.session.setToken(token)
      } else if (_.isEmpty(token)) {
        self.session = undefined
      } else {
        self.session = { token }
      }
    }

    function setSubscription(subscription) {
      self._subscription = subscription
    }

    function isGeneralTier(tier) {
      return [TIERS.KKDAY, TIERS.SPECIAL].includes(tier)
    }

    function isBindDeviceTier(tier) {
      return bindDevicePlans.includes(tier)
    }

    const loadSubscription = flow(function* loadSubscription() {
      const result = yield Promise.all([
        getEnv(self).api.get('/subscription/info')[0],
        getEnv(self).api.get('/subscription/planPrice')[0]
      ])
      if (_.size(_.compact(result)) > 0) {
        const [{ data: info }, { data: plans }] = result
        let { country: permissionCountry } = core?.permission

        if (!permissionCountry) {
          yield core.fetchOptions(['country'])
          permissionCountry = core.permission?.country
        }

        const defaultPlan = {
          FREE: {
            tier: TIERS.FREE,
            priceTier: TIERS.FREE,
            title: TIERS.FREE,
            planName: TIERS.FREE,
            planPrice: 0,
            perMonthPrice: 0,
            discountPrice: 0,
            currencyUuid: '',
            period: SUBSCRIPTION_PLAN_PERIOD.NONE
          },
          // * 試用期過後的猶豫期狀態 isSuspended： tier: NONE, info.last4 = null, subscriptionStatus = 3
          NONE: {
            tier: TIERS.NONE,
            priceTier: TIERS.NONE,
            title: TIERS.NONE,
            planName: TIERS.NONE,
            planPrice: 0,
            perMonthPrice: 0,
            discountPrice: 0,
            currencyUuid: '',
            period: SUBSCRIPTION_PLAN_PERIOD.NONE
          }
        }
        const nextPlans = {
          ...plans,
          ...([TIERS.NONE, TIERS.FREE].includes(info.priceTier)
            ? { [info.priceTier]: defaultPlan[info.priceTier] }
            : [])
        }
        const isJPPlan = permissionCountry === SUBSCRIPTION_COUNTRY.JP

        const filteredTiers = [TIERS.KKDAY, TIERS.SPECIAL, TIERS.ENTERPRISE]
          .filter((tier) => tier !== info.priceTier)
          .concat(bindDevicePlans)

        setSubscription({
          info: {
            ...info,
            ...formatPriceTier(info.priceTier, isBindDeviceTier(info.priceTier))
          },
          inUsingPlan: getSpecificPlan(nextPlans, info.priceTier, isJPPlan),
          sortedPlanGroup: formatAscendingPlanOrder(nextPlans, filteredTiers, isJPPlan)
        })
      }
    })

    const switchStore = flow(function* (storeId, target) {
      console.log('Switching store to', storeId)
      const { core, history } = getEnv(self)
      const root = getRoot(self)
      const lastRevision = root
      self.switchingStore = true
      yield core.setStore(storeId)
      yield when(() => storeId === self.session?.store?.storeUuid && lastRevision !== root.revision)
      if (target) {
        const resolvedTarget = typeof target === 'function' ? yield target() : target
        history.push(parseDestination(resolvedTarget, history.location.pathname))
      }
      self.switchingStore = false
      console.log('Switched')
    })

    const loadAnnouncement = flow(function* () {
      const { api } = getEnv(self)
      try {
        // 網頁待機時公告會跳錯，隱藏錯誤訊息避免 user 誤會
        self.announcements = (yield api.get(
          `store/announcement?display=${Platform.OS === 'web' ? 'web' : 'app'}`,
          { skipErrorHandle: true }
        )[0]).data.filter(
          converge(inRange, [prop('startTs'), prop('endTs'), compose(multiply(0.001), now)])
        )
      } catch (error) {
        console.error(error)
      }
    })

    return {
      setToken,
      loadSubscription,
      switchStore,
      loadAnnouncement,
      isGeneralTier,
      isBindDeviceTier,
      afterCreate() {
        autorun(function () {
          self.setToken(core.token)
        })
      }
    }
  })

export const RootStore = types
  .model('RootStore', {
    orderStore: types.optional(OrderStore, {}),
    storeStore: types.optional(StoreStore, {}),
    sessionStore: types.optional(SessionStore, {}),
    extraStore: types.optional(ExtraStore, {}),
    resourceStore: types.optional(ResourceStore, {}),
    productStore: types.optional(ProductStore, {}),
    deviceStore: types.optional(DeviceStore, {}),
    ticketStore: types.optional(TicketStore, {}),
    imageStore: types.optional(ImageStore, {}),
    viewStore: types.optional(ViewStore, {}),
    channelStore: types.optional(ChannelStore, {}),
    core: types.optional(Core, {}),
    revision: 0,
    lastUpdate: types.Date
  })
  .actions((self) => {
    function changeStore() {
      ;[
        'orderStore',
        'storeStore',
        'sessionStore',
        'extraStore',
        'ticketStore',
        'productStore',
        'deviceStore',
        'imageStore',
        'channelStore',
        'resourceStore'
      ].forEach((store) => {
        destroy(self[store])
      })
      self.revision += 1
    }
    return {
      changeStore
    }
  })

export let store = null

export function initializeStore({ core, history }, snapshot = null) {
  if (store === null) {
    store = RootStore.create({ lastUpdate: Date.now() }, { api: core.api, core, history })
  }
  if (snapshot) {
    applySnapshot(store, snapshot)
  }
  global.store = store
  return store
}

export function extendSync(self, mapName, multiLoaderName, singleQuery) {
  const needToSync = observable([])
  return {
    views: {
      getAndFetch: (uuids, strategy = SyncStrategyType.CacheFirst) => {
        const single = !_.isArray(uuids)
        if (_.isEmpty(uuids)) {
          return single ? undefined : []
        }
        if (single) {
          uuids = [uuids]
        }
        const owned = uuids.map((uuid) => {
          const extra = self[mapName].get(uuid)
          if (typeof extra !== 'undefined') {
            if (strategy === SyncStrategyType.StaleWhileRevalidate) {
              self._addToNeedToSync(uuid)
            }
            return extra
          } else {
            self._addToNeedToSync(uuid)
            return undefined
          }
        })
        return single ? owned[0] : owned
      }
    },
    actions: {
      _addToNeedToSync: (uuid) => {
        needToSync.push(uuid)
      },
      sync: (uuid) => {
        self._addToNeedToSync(uuid)
      },
      afterCreate: () => {
        autorun((reaction) => {
          if (getRoot(self).revision) {
            needToSync.replace([])
          }
        })
        self.__needToSyncDisposer = autorun(() => {
          const loading = _(values(needToSync))
            .uniq()
            .filter((uuid) => {
              return !self[mapName].has(uuid)
            })
            .value()
          if (loading.length > 0) {
            clearTimeout(self.delayedLoader)
            self.delayedLoader = setTimeout(() => {
              ;(singleQuery
                ? Promise.all(loading.map(self[multiLoaderName]))
                : self[multiLoaderName](loading)
              )
                .then(() => {
                  needToSync.clear()
                })
                .catch((e) => console.error(e))
            }, 500)
          }
        })
      },
      beforeDestroy: () => {
        clearTimeout(self.delayedLoader)
        self.__needToSyncDisposer && self.__needToSyncDisposer()
      }
    }
  }
}
