import { useStores } from '@rezio/core/hooks'
import { palette } from '@rezio/res/theme'
import { GoogleTTDStatus, ProductChannelStatusIcon } from '@rezio/utils/types'
import _ from 'lodash'
import React, { PropsWithChildren } from 'react'
import { View, Text } from 'react-native'
import { ChannelProductStatus, ChannelType, ChannelIdentityType, ChannelStatus } from './constants'

export const channelProductStatusList = Object.values(ChannelProductStatus).filter(
  (status) => status !== ChannelProductStatus.Pending
)

export function formatChannelAllowedSpec(channelKey: ChannelType) {
  const {
    Init,
    ReadyToMap,
    Ready,
    Posting,
    Mapped,
    Posted,
    Pulled,
    MapFailed,
    PostFailed,
    Invalid,
    UnavailablePost
  } = ChannelProductStatus
  const defaultList = [Posted, Pulled, UnavailablePost]
  const ALLOWED_STATUS_TYPE_SUPPLY = [Init, Ready, Posted, Pulled, PostFailed, Invalid, Posting]
  const ALLOWED_STATUS_TYPE_CONNECTION = [Init, ReadyToMap, Invalid, Mapped, MapFailed]

  switch (channelKey) {
    case ChannelType.KKDAY:
      return {
        productIdentityType: [ChannelIdentityType.Supply, ChannelIdentityType.Connection],
        productStatusList: channelProductStatusList
      }
    case ChannelType.KKDAYMKP:
      return {
        productIdentityType: [ChannelIdentityType.Supply],
        productStatusList: ALLOWED_STATUS_TYPE_SUPPLY
      }
    case ChannelType.KLOOK:
      return {
        productIdentityType: [ChannelIdentityType.Connection],
        productStatusList: [Init, ReadyToMap, Mapped, MapFailed]
      }
    case ChannelType.VIATOR:
    case ChannelType.ACTIVITYJAPAN:
      return {
        productIdentityType: [ChannelIdentityType.Connection],
        productStatusList: ALLOWED_STATUS_TYPE_CONNECTION
      }
    case ChannelType.JALAN:
    case ChannelType.GYG:
      return {
        productIdentityType: [ChannelIdentityType.Connection],
        productStatusList: [Init, ReadyToMap, Mapped, Invalid]
      }
    case ChannelType.POS:
    case ChannelType.KIOSK:
      return {
        productIdentityType: [ChannelIdentityType.Connection],
        productStatusList: ALLOWED_STATUS_TYPE_CONNECTION
      }
    case ChannelType.MySite:
      return {
        productIdentityType: [ChannelIdentityType.MySite],
        productStatusList: [Init, ...defaultList]
      }
    default:
      return {
        productIdentityType: [ChannelIdentityType.Connection],
        productStatusList: defaultList
      }
  }
}

export function formatIdentityType(identityType: ChannelIdentityType | number) {
  switch (identityType) {
    case 0:
    case ChannelIdentityType.MySite:
      return [0, ChannelIdentityType.MySite]
    case 1:
    case ChannelIdentityType.Supply:
      return [1, ChannelIdentityType.Supply]
    case 2:
    case ChannelIdentityType.Connection:
    default:
      return [2, ChannelIdentityType.Connection]
  }
}

export function formatChannelProductStatus(
  channelStatus: ChannelProductStatus,
  channelKey: ChannelType = ChannelType.None
) {
  switch (channelStatus) {
    case ChannelProductStatus.Init:
      return {
        status: [1, ChannelProductStatus.Init],
        color: palette.channelStatusInit,
        bgColor: palette.channelStatusInitBg,
        tipTextColor: palette.text,
        icon: ProductChannelStatusIcon.Warning
      }
    case ChannelProductStatus.Ready:
      return {
        status: [2, ChannelProductStatus.Ready],
        color: palette.channelStatusPending,
        bgColor: palette.channelStatusPendingBg,
        tipTextColor: palette.text,
        icon: ProductChannelStatusIcon.Warning
      }
    case ChannelProductStatus.Syncing:
      return {
        status: [4, ChannelProductStatus.Syncing],
        color: palette.channelStatusSyncing,
        bgColor: palette.channelStatusSyncingBg,
        tipTextColor: palette.channelStatusPosted,
        icon: ProductChannelStatusIcon.Warning
      }
    case ChannelProductStatus.Posted:
      return {
        status: [5, ChannelProductStatus.Posted],
        color: palette.channelStatusPosted,
        bgColor: palette.channelStatusPostedBg,
        tipTextColor: palette.channelStatusPosted,
        icon: ProductChannelStatusIcon.Success
      }
    case ChannelProductStatus.Pulled:
      return {
        status: [8, ChannelProductStatus.Pulled],
        color: palette.channelStatusPulleded,
        bgColor: palette.channelStatusPullededBg,
        tipTextColor: palette.text,
        icon: ProductChannelStatusIcon.Warning
      }
    case ChannelProductStatus.PostFailed:
      return {
        status: [9, ChannelProductStatus.PostFailed],
        color: palette.channelStatusInvalid,
        bgColor: palette.channelStatusInvalidBg,
        tipTextColor: palette.channelStatusInvalid,
        icon: ProductChannelStatusIcon.Critical
      }
    case ChannelProductStatus.Invalid:
      return {
        status: [11, ChannelProductStatus.Invalid],
        color: palette.channelStatusInvalid,
        bgColor: palette.channelStatusInvalidBg,
        tipTextColor: palette.channelStatusInvalid,
        icon: ProductChannelStatusIcon.Critical
      }
    case ChannelProductStatus.Mapped:
      return {
        status: [13, ChannelProductStatus.Mapped],
        color: palette.channelStatusPosted,
        bgColor: palette.channelStatusPostedBg,
        tipTextColor: [ChannelType.VIATOR].includes(channelKey)
          ? palette.text
          : palette.channelStatusPosted,
        icon: ProductChannelStatusIcon.Success
      }
    case ChannelProductStatus.MapFailed:
      return {
        status: [14, ChannelProductStatus.MapFailed],
        color: palette.channelStatusInvalid,
        bgColor: palette.channelStatusInvalidBg,
        tipTextColor: palette.channelStatusInvalid,
        icon: ProductChannelStatusIcon.Critical
      }
    case ChannelProductStatus.ReadyToMap:
      return {
        status: [15, ChannelProductStatus.ReadyToMap],
        color: palette.channelStatusPending,
        bgColor: palette.channelStatusPendingBg,
        tipTextColor: palette.text,
        icon: ProductChannelStatusIcon.Warning
      }
    case ChannelProductStatus.UnavailablePost:
      return {
        status: [17, ChannelProductStatus.UnavailablePost],
        color: palette.channelStatusPulleded,
        bgColor: palette.channelStatusPullededBg,
        tipTextColor: palette.text,
        icon: ProductChannelStatusIcon.Critical
      }
    case ChannelProductStatus.Posting:
      return {
        status: [19, ChannelProductStatus.Posting],
        color: palette.channelStatusSyncing,
        bgColor: palette.channelStatusSyncingBg,
        tipTextColor: palette.channelStatusPosted,
        icon: ProductChannelStatusIcon.Warning
      }
  }
  return {
    status: [-1, ''],
    color: '',
    bgColor: '',
    icon: null
  }
}

export function formatGoogleTtdStatus(status: GoogleTTDStatus) {
  const textStatus = _.snakeCase(GoogleTTDStatus[status]).toUpperCase()
  switch (status) {
    case GoogleTTDStatus.Unpublished:
      return {
        status: [status, textStatus],
        color: palette.channelStatusPulleded,
        bgColor: palette.disable
      }
    case GoogleTTDStatus.Published:
      return {
        status: [status, textStatus],
        color: palette.channelStatusPosted,
        bgColor: palette.channelStatusPostedBg
      }
    case GoogleTTDStatus.UnableToPublish:
      return {
        status: [status, textStatus],
        color: palette.channelStatusInvalid,
        bgColor: palette.channelStatusInvalidBg
      }
  }

  return {
    status: [-1, ''],
    color: '',
    bgColor: ''
  }
}

export function formatStatus(status: ChannelStatus | number) {
  switch (status) {
    case ChannelStatus.Off:
    case 0:
      return {
        status: [0, ChannelStatus.Off],
        color: palette.warning,
        bgColor: palette.warningBg
      }
    case ChannelStatus.On:
    case 1:
      return {
        status: [1, ChannelStatus.On],
        color: palette.positive,
        bgColor: palette.positiveBg
      }
    case ChannelStatus.Connecting:
    case 2:
      return {
        status: [2, ChannelStatus.Connecting],
        color: palette.neutral,
        bgColor: palette.neutralBg
      }
    case ChannelStatus.Locked:
    case 3:
      return {
        status: [3, ChannelStatus.Locked],
        color: palette.icon,
        bgColor: palette.white
      }
    case ChannelStatus.ConnectFailed:
    case 4:
      return {
        status: [4, ChannelStatus.ConnectFailed],
        color: palette.warning,
        bgColor: palette.warningBg
      }
  }
  return {
    status: [-1, ''],
    color: '',
    bgColor: ''
  }
}
interface StatusProps {
  color: string
  bgColor: string
  type?: 'normal' | 'large'
}

function StyledStatus(props: PropsWithChildren<StatusProps>) {
  const { type = 'normal', color, bgColor, children } = props

  return (
    <View
      style={{
        maxWidth: 110,
        backgroundColor: bgColor,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 5
      }}
    >
      <Text
        style={{
          fontSize: 12,
          color,
          textAlign: 'left',
          fontWeight: type === 'large' ? 'bold' : 'normal'
        }}
      >
        {children}
      </Text>
    </View>
  )
}
export function StyledChannelStatus(props: Pick<StatusProps, 'type'> & { status: ChannelStatus }) {
  const { t } = useStores()
  const { status, color, bgColor } = formatStatus(props.status)
  const outputText = t(`CHANNEL.TITLE_STATUS_${status[1]}`)

  return (
    <StyledStatus type={props.type} color={color} bgColor={bgColor}>
      {outputText}
    </StyledStatus>
  )
}
export function StyledProductStatus(
  props: Pick<StatusProps, 'type'> & { status: ChannelProductStatus }
) {
  const { t } = useStores()
  const { status, color, bgColor } = formatChannelProductStatus(props.status)
  const outputText = t(`CHANNEL.STATUS_${status[1]}`)

  return (
    <StyledStatus type={props.type} color={color} bgColor={bgColor}>
      {outputText}
    </StyledStatus>
  )
}

export function StyledGoogleTTDStatus(
  props: Pick<StatusProps, 'type'> & { status: GoogleTTDStatus }
) {
  const { t } = useStores()
  const { status, color, bgColor } = formatGoogleTtdStatus(props.status)
  const outputText = t(`CHANNEL.GOOGLE_TTD_TYPE_${status[1]}`)

  return (
    <StyledStatus type={props.type} color={color} bgColor={bgColor}>
      {outputText}
    </StyledStatus>
  )
}
