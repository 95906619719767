import {
  ProductBookingCutline,
  ProductEmailSetting,
  ProductSaleable,
  VoucherType,
  OnOff,
  OrderConfirmMethod
} from '@rezio/utils/types'
import {
  ChannelProductStatusNumber,
  ChannelIdentityTypeNumber
} from '@rezio/components/channel/constants'

// 後端欄位
export enum ChannelKey {
  Trip = 'Trip',
  KKdayMkp = 'KKdayMkp',
  KLOOK = 'KLOOK',
  KKday = 'KKday',
  Viator = 'Viator',
  POS = 'POS',
  ActivityJapan = 'ActivityJapan',
  KIOSK = 'KIOSK',
  MySite = 'MySite',
  Jalan = 'Jalan',
  GYG = 'GYG'
}

export interface OverviewProduct {
  productCode: string
  categoryUuid: string
  timezoneUuid: string
  currencyUuid: string
  emailSetting: ProductEmailSetting
  bookingCutline: ProductBookingCutline
  confirmMethod: OrderConfirmMethod
  confirmType: 1 | 2 // 1: 自動 & 即時, 2: 手動
  confirmHour: number // 0: 即時確認, >= 1: 自動確認
  saleable: ProductSaleable
  acceptPayType: number // 支付方式 bitwise
  acceptOnlinePayWays: string[] // 線上支付方式
  voucherType: VoucherType // 憑證類型
}

export interface BoundChannel {
  uuid: string
  channelUuid: string
  title: string
  key: ChannelKey
  linkStatus: ChannelProductStatusNumber
  ruleCheckResult: {
    status: boolean
    summary: string[]
  }
  identityType: ChannelIdentityTypeNumber
  isSettingChange: boolean
}

export interface Channel {
  key: ChannelKey
  title: string
  uuid: string
  status: OnOff
  binary: number
  currencyUuid: string
  defaultProfitRate: number
  description: string
  icon: string
  sort: number
  useChannelSort: boolean
  connectType: number
  connectConfig: Record<any, { key: string; type: string; required: boolean }>
  integrationEnable: { order: number; stock: number; switch: number; product: number }
  initialDate?: string
  productCount: number
  storeConnectStatus: OnOff
  identityTypes?: ChannelIdentityTypeNumber[]
}

export type AddToChannel = ({
  identityType,
  icon,
  title,
  channelUuid
}: Pick<Channel, 'icon' | 'title'> &
  Pick<BoundChannel, 'channelUuid'> & { identityType: ChannelIdentityTypeNumber }) => void

export interface OverviewScope {
  productUuid: string
  isNSTProduct: boolean | null
}
