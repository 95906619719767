import _ from 'lodash'
import lottie from 'lottie-web'
import React, { PureComponent } from 'react'
import { View } from 'react-native'

class Animation extends PureComponent {
  animationDOMNode = null

  componentDidMount() {
    this.loadAnimation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    if (this.props.source && nextProps.source && this.props.source.nm !== nextProps.source.nm) {
      this.loadAnimation(nextProps)
    }
  }

  componentWillUnmount() {
    _.get(this.props, 'progress.removeAllListeners', () => {})()
  }

  setProgress = (progress) => {
    const reverse = this.props.speed < 0
    const frame = (reverse ? 1 - progress : progress) * this.anim.totalFrames
    this.anim.setCurrentRawFrameValue(frame)
  }

  loadAnimation = (props) => {
    if (this.anim) {
      this.anim.destroy()
    }

    const speed = typeof props.speed !== 'undefined' ? Math.abs(props.speed) : 1
    const direction = (typeof props.speed === 'undefined' || props.speed) > 0 ? 1 : -1

    this.anim = lottie.loadAnimation({
      container: this.animationDOMNode,
      animationData: props.source,
      renderer: 'svg',
      loop: props.loop || false,
      autoplay: direction !== -1 && props.autoPlay
    })
    this.anim.setSpeed(speed)

    if (direction === -1 && props.autoPlay) {
      this.play()
    }
    if (props.progress) {
      if (_.isNumber(props.progress)) {
        this.setProgress(props.progress)
      } else {
        props.progress.addListener &&
          props.progress.addListener(({ value }) => this.setProgress(value))
      }
    }
  }

  play = (startFrame = -1, endFrame = -1) => {
    const start = 0
    const end = this.anim.totalFrames + 1
    const reverse = this.props.speed < 0
    startFrame = startFrame === -1 ? (reverse ? end : start) : startFrame
    endFrame = endFrame === -1 ? (reverse ? start : end) : endFrame
    this.anim.playSegments([startFrame, endFrame], true)
  }

  reset = () => {
    const start = 0
    const end = this.anim.totalFrames + 1
    const reverse = this.props.speed < 0
    this.anim.goToAndStop(reverse ? start : end)
  }

  setAnimationDOMNode = (ref) => (this.animationDOMNode = ref)

  render() {
    return <View style={this.props.style} ref={this.setAnimationDOMNode} />
  }
}

export default React.forwardRef((props, ref) => (
  <Animation {...props} ref={typeof ref === 'function' ? (c) => ref(c && c.anim) : ref} />
))
