import React, { forwardRef } from 'react'
import { View } from 'react-native'
import { Modal as BaseModal, ModalHost } from 'react-native-modal'
import { WhitePortal } from 'react-native-portal'

const Modal = forwardRef(function Modal(props, ref) {
  const { children, isVisible, ...restProps } = props
  return (
    <BaseModal isVisible={!!isVisible} ref={ref} {...restProps}>
      {children || <View />}
      <WhitePortal name='addition' />
    </BaseModal>
  )
})

export { ModalHost }
export default Modal
