// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Core from '@rezio/core/core'
import fp from 'lodash/fp'
import { model, Model, modelAction, modelFlow, prop, _async, _await } from 'mobx-keystone'

interface Filter {
  bookingStartDate?: string
  bookingEndDate?: string
  bookingStartTime?: string
  bookingEndTime?: string
  couponUuids?: string
}

interface DiscountOrderItem {
  label: string
  code: string
  orderNo: string
  productCode: string
  productName: string
  salesOptionName: string
  chargeCurrencyUuid: string
  chargeAmount: string
  discountAmount: string
}

@model('admin/discountReport')
class DiscountReport extends Model({
  loading: prop<boolean>(false),
  list: prop<DiscountOrderItem[]>(() => []),
  totalCount: prop<number>(0),
  itemPerPage: prop<number>(20),
  currentPage: prop<number>(1),
  filter: prop<Filter>(() => ({}))
}) {
  core = Core.getInstance()

  @modelAction
  setPage = (page: number) => {
    this.currentPage = page
  }

  @modelAction
  setFilter = (filter: Filter) => {
    this.filter = filter
    this.currentPage = 1
  }

  @modelFlow
  fetchDiscountReport = _async(function* (this: DiscountReport, num: number = this.itemPerPage) {
    const tsDiff = -(new Date().getTimezoneOffset() * 60)
    try {
      this.loading = true
      const requestBody = fp.omitBy(fp.isUndefined)({
        num,
        tsDiff,
        ...this.filter
      })
      const [request] = Core.getInstance().api.get(`/report/discount/preview/${this.currentPage}`, {
        params: requestBody
      })
      const { data } = yield* _await(request)
      this.list = data.list
      this.totalCount = data.totalCount
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })
}

export default DiscountReport
