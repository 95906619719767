import { MaterialIcons } from '@expo/vector-icons'
import { manipulator, palette } from '@rezio/res/theme'
import { noop } from 'lodash'
import { without } from 'ramda'
import React from 'react'
import { Text, View } from 'react-native'

import { ThemedButton } from './ThemedButton'

interface Props {
  items: string[]
  inputLabel?: string
  isFull?: boolean
  hideAddButton?: boolean
  hideBorder?: boolean
  onCancelItem: (uuids: string[]) => void
  onPressAdd: () => void
}

export const ThemedTagList = ({
  items,
  inputLabel,
  onCancelItem,
  onPressAdd,
  isFull,
  hideAddButton,
  hideBorder
}: Props) => (
  <View
    style={[
      manipulator.container('row', 'flex-start', 'center'),
      !hideBorder && manipulator.border('all', '', 5),
      !hideBorder && { paddingTop: 5, paddingLeft: 5, paddingRight: 5 },
      { flexWrap: 'wrap' }
    ]}
  >
    {inputLabel && <Text style={{ marginRight: 10 }}>{inputLabel}</Text>}
    {items.filter(Boolean).map((itemValue, index) => (
      <ThemedButton
        key={`${itemValue}-${index}`}
        style={[defaultTagStyle, { marginBottom: 5, borderColor: palette.primary }]}
        onPress={() => onCancelItem(without([itemValue], items))}
      >
        <MaterialIcons name='clear' size={14} color={palette.primary} style={{ marginRight: 3 }} />
        <Text style={{ color: palette.primary }}>{itemValue}</Text>
      </ThemedButton>
    ))}
    {!hideAddButton && (
      <ThemedButton
        style={[plusIconStyle, { borderColor: isFull ? palette.ashGray : palette.primary }]}
        onPress={isFull ? noop : onPressAdd}
        accessibilityRole='spinbutton'
        testId='spinbutton'
      >
        <MaterialIcons name='add' size={28} color={isFull ? palette.ashGray : palette.primary} />
      </ThemedButton>
    )}
  </View>
)

const defaultTagStyle = {
  minWidth: 100,
  marginRight: 10,
  height: 40,
  borderWidth: 1,
  borderRadius: 5,
  backgroundColor: 'transparent',
  paddingLeft: 5,
  paddingRight: 5,
  marginBottom: 10
}

const plusIconStyle = {
  marginBottom: 7,
  height: 40,
  width: 40,
  borderWidth: 1,
  borderRadius: 5,
  backgroundColor: 'transparent',
  paddingLeft: 5,
  paddingRight: 5
}
