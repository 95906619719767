/* global FileReader Image */
import {
  ThemedButton,
  PageWidthModal,
  PageFooter,
  StaticImage,
  DialogModal
} from '@rezio/components'
import { Droppable } from '@rezio/components/droppable'
import { MaterialIcons } from '@expo/vector-icons'
import { dataURLtoBlob } from '@rezio/components/imageUploader/utils'
import { ErrorIcon } from '@rezio/core/errorHandler'
import { useStores } from '@rezio/core/hooks'
import { manipulator, palette, template } from '@rezio/res/theme'
import * as ImagePicker from 'expo-image-picker'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Text, View, ImageBackground, Platform, ActivityIndicator } from 'react-native'

async function blobToDataURL(blob) {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    reader.readAsDataURL(blob)
  })
}

async function getImageMeta(dataURL) {
  return await new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
    img.src = dataURL
  })
}

const VoucherUploader = observer((props) => {
  const {
    category,
    handleConfirmUploadFile,
    index,
    order,
    type,
    disabled = false,
    data,
    style = {},
    title = ''
  } = props
  const { t, store } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState(data?.qrCode ?? null)
  const [isOpenUploadPanel, setIsOpenUploadPanel] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const droppableRef = useRef(null)

  const storeUsage = store.imageStore.getCategory(category)
  const handleDeleteImage = useCallback(() => setSelected(null), [])

  const handleOpenUploadPanel = useCallback(() => setIsOpenUploadPanel(true), [])

  const onHandleConfirmUploadFile = useCallback(async () => {
    switch (type) {
      case 'image':
        await handleConfirmUploadFile(index, {
          storeMediaUsageUuid: selected.storeMediaUsageUuid,
          clientFileName: selected.clientFileName,
          url: selected.url
        })
        break
      case 'file':
        await storeUsage.createImage(selected.uploaderTypeFileTarget, null, order?.orderNo)
        await handleConfirmUploadFile()
        break
    }
    return setIsOpenUploadPanel(false)
  }, [index, selected, order])

  const handleWebSelectFile = useCallback(
    async (e) => {
      // * type - image: createImage 觸發在 onFile 後
      // * type - file: createImage 觸發在 onFile + 按下確認後
      const errorEx = []
      const { maxUploadSize } = props
      const maxUploadSizeOfMB = +(maxUploadSize / 1024).toFixed(2)
      const files = _.get(e, 'target.files', e)
      const file =
        type === 'image'
          ? _.find(files, (file) => file && file.type.match(/image.*/))
          : _.get(files, '0')
      const fileName = _.dropRight((file.name || '').split('.')).join('')
      const isInvalidFileName = !/^[a-zA-Z0-9_]+$/.test(fileName)

      switch (type) {
        case 'image': {
          const defaultPixel = 168 * Math.pow(10, 5)
          const maxImageSize = 200 * Math.pow(10, 3)
          const dataURL = await blobToDataURL(file)
          const { width, height } = await getImageMeta(dataURL)
          const isInvalidSize = file.size > maxImageSize
          const isInvalidPixel = width * height > defaultPixel
          if (isInvalidSize) {
            errorEx.push(t('COMMON.IMAGE_FILE_SIZE_UPLOAD_FAILED', { max: '200 KB' }))
          }
          if (isInvalidPixel) {
            errorEx.push(t('COMMON.IMAGE_FILE_PIXEL_UPLOAD_FAILED'))
          }
          if (isInvalidFileName) {
            errorEx.push(t('COMMON.INVALID_UPLOADED_FILE_NAME'))
          }
          break
        }
        case 'file': {
          const isOverRestFileSpace = Math.min(maxUploadSizeOfMB, 1.8) === maxUploadSizeOfMB
          const maxFileSize = Math.min(maxUploadSizeOfMB, 1.8) * 1024 * Math.pow(10, 3)
          const isInvalidSize = file.size > maxFileSize
          if (isInvalidSize) {
            errorEx.push(
              t(
                isOverRestFileSpace
                  ? 'COMMON.OVER_REST_FILE_SPACE_SIZE'
                  : 'COMMON.FILE_SIZE_UPLOAD_FAILED',
                { max: `${Math.min(maxUploadSizeOfMB, 1.8)} MB` }
              )
            )
          }
          if (isInvalidFileName) {
            errorEx.push(t('COMMON.INVALID_UPLOADED_FILE_NAME'))
          }
          break
        }
      }

      if (errorEx.length > 0) {
        setIsLoading(false)
        setErrMsg(errorEx.join('\n'))
      } else {
        if (file) {
          setIsLoading(true)
          try {
            const selected =
              type === 'image' ? await storeUsage.createImage(file, null, order?.orderNo) : file
            droppableRef.current.value = ''
            switch (type) {
              case 'image':
                setSelected(selected)
                return
              case 'file':
                setSelected({
                  uploaderTypeFileTarget: selected,
                  clientFileName: file.name
                })
            }
          } catch (e) {
          } finally {
            setIsLoading(false)
          }
        }
      }
    },
    [selected, order]
  )

  const handleSelectImage = useCallback(async () => {
    if (Platform.OS === 'web') {
      droppableRef.current.open()
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [4, 3],
        base64: true
      })

      if (!result.canceled) {
        const image = result.uri
        try {
          setIsLoading(true)
          const selected = await storeUsage.createImage(dataURLtoBlob(image), null, order?.orderNo)
          setSelected(selected)
          droppableRef.current.value = ''
        } catch (e) {
        } finally {
          setIsLoading(false)
        }
      }
    }
  }, [])
  const isMobile = store.viewStore.layout !== 'desktop'
  const qrCodeData = data?.qrCode

  useEffect(() => {
    // reset selected
    if (!isOpenUploadPanel) setSelected(null)
  }, [isOpenUploadPanel])

  return (
    <View>
      <View
        style={manipulator.container(
          isMobile ? 'column' : 'row',
          'flex-start',
          isMobile ? 'flex-start' : 'center'
        )}
      >
        <Text style={{ minWidth: 145 }}>
          <MaterialIcons name='fiber-manual-record' color={palette.black} size={10} />
          <Text style={{ marginHorizontal: 5 }}>
            {title || t(`VOUCHER.EXTERNAL_UPLOAD_${type.toUpperCase()}`)}
          </Text>
        </Text>
        {type === 'image' ? (
          <>
            {!_.isEmpty(qrCodeData) && (
              <View
                style={[
                  manipulator.container('row', 'flex-start', 'center'),
                  isMobile ? { marginBottom: 5 } : { marginRight: 5 }
                ]}
              >
                <MaterialIcons name='check-circle' color={palette.positive} size={16} />
                <Text style={{ color: palette.positive, marginLeft: 4, minWidth: 50 }}>
                  {t('VOUCHER.STATUS_UPLOADED')}
                </Text>
                <Text style={{ marginLeft: 10 }}>{qrCodeData?.clientFileName}</Text>
              </View>
            )}
            {disabled ? (
              _.isEmpty(qrCodeData) && <Text>{t('COMMON.NONE')}</Text>
            ) : (
              <ThemedButton
                style={{ backgroundColor: 'transparent' }}
                onPress={handleOpenUploadPanel}
              >
                <MaterialIcons name='cloud-upload' color={palette.lightBrand} size={20} />
                <Text style={{ color: palette.lightBrand, marginLeft: 4 }}>
                  {t(`VOUCHER.LABEL_${_.isEmpty(qrCodeData) ? '' : 'RE'}UPLOAD_FILE`)}
                </Text>
              </ThemedButton>
            )}
          </>
        ) : (
          <ThemedButton
            disabled={disabled}
            style={{ backgroundColor: 'transparent' }}
            onPress={handleOpenUploadPanel}
          >
            <MaterialIcons
              name='cloud-upload'
              color={disabled ? palette.gray : palette.lightBrand}
              size={20}
            />
            <Text style={{ color: disabled ? palette.gray : palette.lightBrand, marginLeft: 4 }}>
              {t('VOUCHER.LABEL_UPLOAD_FILE')}
            </Text>
          </ThemedButton>
        )}
      </View>
      <PageWidthModal
        isVisible={isOpenUploadPanel}
        onBackdropPress={() => setIsOpenUploadPanel(false)}
      >
        <View style={[template.defaultPanel, template.defaultBorder, { padding: 20 }]}>
          <Text style={[template.title, { textAlign: 'center', marginBottom: 30 }]}>
            {t(`VOUCHER.TITLE_EXTERNAL_UPLOAD_${type.toUpperCase()}`)}
          </Text>
          <Text style={{ color: palette.warning, marginBottom: 10 }}>
            {t(`VOUCHER.SUBTITLE_EXTERNAL_UPLOAD_${type.toUpperCase()}`)}
          </Text>
          <View
            style={[
              {
                display: 'flex',
                borderWidth: 1,
                borderColor: palette.lightGray,
                borderRadius: 5,
                marginBottom: 20
              },
              manipulator.container('row', 'flex-start', 'center')
            ]}
          >
            <View style={{ backgroundColor: palette.panelBackground, padding: 10, width: 100 }}>
              <Text style={{ color: palette.black, textAlign: 'center' }}>
                {t('VOUCHER.LABEL_FILE_NAME')}
              </Text>
            </View>
            <Text style={{ flex: 1, marginLeft: 10 }}>{selected?.clientFileName}</Text>
            <ThemedButton
              containerStyle={{ width: 150 }}
              style={{ backgroundColor: 'transparent' }}
              async={false}
              onPress={handleSelectImage}
            >
              <MaterialIcons name='cloud-upload' color={palette.lightBrand} size={20} />
              <Text style={{ color: palette.lightBrand, marginLeft: 4 }}>
                {t('VOUCHER.LABEL_UPLOAD_FILE')}
              </Text>
            </ThemedButton>
          </View>
          {type === 'image' && selected?.storeMediaUsageUuid && (
            <>
              <Text>{t('VOUCHER.LABEL_FILE_PREVIEW')}</Text>
              <View style={[template.rowContainer, { marginBottom: 10 }]}>
                <Text>{selected?.clientFileName}</Text>
                <ThemedButton
                  style={{ backgroundColor: 'transparent', textAlign: 'left' }}
                  onPress={handleDeleteImage}
                >
                  <MaterialIcons name='cancel' color={palette.icon} size={16} />
                </ThemedButton>
              </View>
            </>
          )}
          <Droppable ref={droppableRef} onFiles={handleWebSelectFile}>
            {type === 'image' && (
              <ImageBackground
                resizeMode='contain'
                style={[
                  manipulator.container('column', 'center', 'center'),
                  { position: 'relative', borderStyle: 'dashed', height: 150, width: 150 },
                  style
                ]}
                source={{ uri: selected?.url || null }}
              >
                {isLoading && (
                  <StaticImage
                    style={[template.center, { width: 110, height: 42 }]}
                    source={require('../assets/animation.gif')}
                  />
                )}
              </ImageBackground>
            )}
            {type === 'file' && isLoading && <ActivityIndicator />}
          </Droppable>
        </View>
        <PageFooter>
          <View style={manipulator.container('row', 'space-between', 'center')}>
            <ThemedButton
              onPress={() => setIsOpenUploadPanel(false)}
              style={[template.defaultPanel, { width: 150, backgroundColor: palette.negative }]}
            >
              <Text style={{ color: palette.black }}>{t('FORM.BUTTON_CANCEL')}</Text>
            </ThemedButton>
            <ThemedButton
              onPress={onHandleConfirmUploadFile}
              style={[template.defaultPanel, { width: 150, backgroundColor: palette.positive }]}
            >
              <Text style={{ color: palette.white }}>{t('FORM.BUTTON_CONFIRM')}</Text>
            </ThemedButton>
          </View>
        </PageFooter>
      </PageWidthModal>
      <DialogModal isVisible={!_.isEmpty(errMsg)} onBackdropPress={() => setErrMsg('')}>
        <View style={[manipulator.panel(20, 20, 20, 20), { alignItems: 'center' }]}>
          <ErrorIcon />
          <Text
            style={[
              template.bold,
              { color: palette.primary, fontSize: 24, paddingBottom: 10, paddingTop: 20 }
            ]}
          >
            {t('COMMON.TITLE_TIPS')}
          </Text>
          <Text>{errMsg}</Text>
        </View>
        <ThemedButton onPress={() => setErrMsg('')}>{t('FORM.BUTTON_OK')}</ThemedButton>
      </DialogModal>
    </View>
  )
})

export { VoucherUploader }
