import { palette } from '@rezio/res/theme'

export const themeDefaultSetting = {
  displayMenu: {
    currency: true,
    language: true
  },
  displayFooter: {
    storeName: true,
    businessAddress: true,
    email: true,
    telephone: true,
    socialMediaLink: true,
    policyNdTerms: true,
    specifiedCT: true
  },
  customDisplayFooter: [
    {
      index: 0,
      content: [
        {
          sort: 0,
          label: '',
          isHyperLink: false,
          hyperLink: ''
        },
        {
          sort: 1,
          label: '',
          isHyperLink: false,
          hyperLink: ''
        },
        {
          sort: 2,
          label: '',
          isHyperLink: false,
          hyperLink: ''
        },
        {
          sort: 3,
          label: '',
          isHyperLink: false,
          hyperLink: ''
        },
        {
          sort: 4,
          label: '',
          isHyperLink: false,
          hyperLink: ''
        }
      ]
    }
  ],
  customHeader: {
    enable: false,
    title: '',
    contents: []
  },
  accessoryColor: palette.hoverLightBlue,
  accessoryTextColor: palette.gray,
  btnMainColor: palette.primary,
  btnTextColor: palette.white,
  contentTextColor: palette.text,
  calendarType: 1,
  displayCurrencyUuids: [],
  displayLanguageUuids: [],
  displayRemainingQuota: true,
  displayTagUuids: [],
  displayTagMapping: [],
  mainColor: palette.primary,
  safetyStock: {}
}
