import { MaterialIcons } from '@expo/vector-icons'
import config from './config'

// TODO: use https://www.nativewind.dev/v4/api/css-interop when nativewind v4 is released
const parseColor = (textClassName: string): string => {
  const color = textClassName.includes('text-white')
    ? config.theme.colors.white
    : textClassName.includes('text-black')
    ? config.theme.colors.black
    : textClassName.includes('text-gray-600')
    ? config.theme.colors.gray[600]
    : config.theme.colors.primary.DEFAULT
  return color
}

const DSIcon = ({ name, size = 14, textClassName }) => {
  const color = parseColor(textClassName)
  return <MaterialIcons color={color} name={name} size={size} />
}
DSIcon.displayName = 'DSIcon'

export { DSIcon }
