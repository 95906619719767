import Core from '@rezio/core/core'
import { TFunction } from 'i18next'

import { ImageMeta } from './types'

export const dataURLtoBlob = async (dataUrl: string) => {
  return await (await fetch(dataUrl)).blob()
}

export const blobToDataURL = async (blob: Blob) => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const getImageMeta = async (dataURL: any) => {
  return await new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
    img.src = dataURL
  })
}

export const formatImageFile = async (file: File, t: TFunction<'translation', undefined>) => {
  const core = Core.getInstance()
  const dataURL = await blobToDataURL(file)

  if (!file?.type.match(/^(?!image.heic).*image.*$/)) {
    core.setRuntimeError(t('COMMON.IMAGE_FILE_INVALID_TYPE_UPLOAD_FAILED')).catch(console.error)
    return null
  }

  const { width, height }: ImageMeta = await getImageMeta(dataURL)

  return {
    lastModified: file.lastModified,
    name: file.name,
    size: file.size,
    type: file.type,
    width,
    height
  }
}

export const validateImageFile = ({ formattedFile, fileLimit, t }) => {
  const errMsg: string[] = []
  const { width, height, type, size, name } = formattedFile
  const {
    sizeLimitMB,
    acceptFileTypesRex,
    acceptFileMinWidth,
    acceptFileMinHeight,
    acceptSquareOnly,
    defaultPixel,
    fileNameLengthLimit
  } = fileLimit

  const isInvalidAcceptType = !acceptFileTypesRex.test(type)
  const isInvalidSize = size / (1024 * 1024) > sizeLimitMB
  const isInvalidPixel = width * height > defaultPixel
  const isInvalidFileWidth = width < acceptFileMinWidth
  const isInvalidFileHeight = height < acceptFileMinHeight
  const isInvalidFileProportion = acceptSquareOnly && width !== height
  const isOverFileNameLengthLimit = fileNameLengthLimit && name.length > fileNameLengthLimit

  if (isInvalidAcceptType) {
    errMsg.push(t('COMMON.IMAGE_FILE_INVALID_TYPE_UPLOAD_FAILED'))
  }
  if (isInvalidSize) {
    errMsg.push(t('COMMON.IMAGE_FILE_SIZE_UPLOAD_FAILED', { max: `${sizeLimitMB} MB` }))
  }
  if (isInvalidPixel) {
    errMsg.push(t('COMMON.IMAGE_FILE_PIXEL_UPLOAD_FAILED'))
  }
  if (isInvalidFileWidth || isInvalidFileHeight) {
    errMsg.push(
      t('COMMON.IMAGE_FILE_CUSTOM_SIZE_UPLOAD_FAILED', {
        minWidth: acceptFileMinWidth,
        minHeight: acceptFileMinHeight
      })
    )
  }
  if (isInvalidFileProportion && acceptSquareOnly) {
    errMsg.push(t('COMMON.IMAGE_FILE_PROPORTION_UPLOAD_FAILED', { acceptFileProportion: 1 }))
  }
  if (isOverFileNameLengthLimit) {
    errMsg.push(t('COMMON.IMAGE_FILE_NAME_LIMIT_RULE', { number: fileNameLengthLimit }))
  }

  return errMsg
}
