import { KKSCMView } from '@rezio/components/kkday/scmView'
import { getIsDev } from '@rezio/core/config'
import MaintenancePage from '@rezio/pages/maintenancePage'
import Page404 from '@rezio/pages/404'
import _ from 'lodash'
import React, { Suspense } from 'react'
import { ActivityIndicator, ViewProps } from 'react-native'

import { Layout, Page } from '@rezio/components/layout'
import { Route, Switch } from '@rezio/unimodules/router'
import { pages } from '../core/routing/pages'

const debugable = getIsDev()

const headerOnlyPaths = pages.filter((page) => page.headerOnly).map((page) => page.path)

const lazySite = pages.map(({ path, screen: Screen, name, withoutLayout, debugging }) => {
  let screen = Screen
  switch (path) {
    case '/':
      break
    default:
      screen = (props) => {
        return (
          <Suspense
            fallback={
              <Page>
                <ActivityIndicator />
              </Page>
            }
          >
            <Screen name={name} {...props} />
          </Suspense>
        )
      }
      break
  }
  return {
    path,
    name,
    screen,
    type: withoutLayout ? 'raw' : 'layout',
    debugging
  }
})

const routes = _(lazySite)
  .groupBy('type')
  .mapValues((routes) => {
    return routes.map(({ path, screen, name, debugging }) => {
      return !debugable && debugging ? undefined : (
        <Route key={name} exact path={path} component={screen} />
      )
    })
  })
  .value()

export default function Routes({
  pageOnly,
  isMaintaining = false
}: { pageOnly?: boolean; isMaintaining?: boolean } & ViewProps) {
  if (isMaintaining) {
    return <Route path='/' component={MaintenancePage} />
  }

  return (
    <Switch>
      {routes.raw}
      <Route
        path='/'
        render={(props) => (
          <Layout
            {...props}
            pageOnly={pageOnly}
            hideNav={headerOnlyPaths.includes(props.location.pathname)}
          >
            <Switch>
              {routes.layout}
              <Route path='/scm' render={(props) => <KKSCMView />} />
              <Route path='/scmmkp' render={(props) => <KKSCMView isMkp />} />
              <Route component={Page404} />
            </Switch>
          </Layout>
        )}
      />
    </Switch>
  )
}
