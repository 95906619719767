import { SearchSelector, ThemedTextInput } from '@rezio/components'
import { useLayout } from '@rezio/core/hooks'
import { manipulator } from '@rezio/res/theme'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useState, useEffect } from 'react'
import { View } from 'react-native'

const CountryPhoneInput = observer(function CountryPhoneInput(props) {
  const {
    onChange,
    value,
    selectorConfig,
    inputConfig,
    items: countryItems = [],
    isOnlyCountryCode = false,
    ...rest
  } = props
  const { isMobile } = useLayout()
  const isValidInputValue = _.isArray(value)
  const countryItemsWithDisplayTitle = _.map(countryItems, (country) => {
    return {
      ...country,
      displayTitle: `${!isOnlyCountryCode ? _.get(country, 'countrycode', '') : ''} +${_.get(
        country,
        'value'
      )}`
    }
  })
  const getCountryUuidbyParse = useCallback(
    (value) => {
      if (isValidInputValue) {
        const parsePhoneCountry = _.get(parsePhoneNumberFromString(`+${value.join('')}`), 'country')
        return parsePhoneCountry
          ? _.get(
              _.find(countryItems, (country) => country.countrycode === parsePhoneCountry),
              'uuid'
            )
          : _.get(
              _.find(countryItems, (country) => country.code === value[0]),
              'uuid'
            )
      }
      return undefined
    },
    [countryItems, isValidInputValue]
  )
  const [countryUuid, setCountryUuid] = useState(getCountryUuidbyParse(value))
  const [phone, setPhone] = useState(
    isValidInputValue
      ? _.get(parsePhoneNumberFromString(`+${value.join('')}`), 'nationalNumber')
      : ''
  )
  const getCountryValuebyUuid = useCallback(
    (uuid) => {
      return _.get(
        _.find(countryItems, (country) => country.uuid === uuid),
        'value'
      )
    },
    [countryItems]
  )

  useEffect(() => {
    props.isOnSubmit &&
      setPhone(
        isValidInputValue
          ? _.get(parsePhoneNumberFromString(`+${value.join('')}`), 'nationalNumber')
          : ''
      )
  }, [props.isOnSubmit])

  const handleSelectorChange = useCallback(
    (inputValue) => {
      if (!_.isEmpty(inputValue)) {
        setCountryUuid(inputValue)
        onChange?.([getCountryValuebyUuid(inputValue), phone])
      } else {
        setCountryUuid(_.get(countryItems, '0.uuid'))
        onChange?.([_.get(countryItems, '0.uuid'), phone])
      }
    },
    [phone]
  )

  const handleTextInputChange = useCallback(
    (inputValue) => {
      const formatPhone = inputValue?.replace(/[^0-9]/gi, '')
      if (!_.isEmpty(formatPhone)) {
        setPhone(formatPhone)
        onChange?.([getCountryValuebyUuid(countryUuid), formatPhone])
      } else {
        setPhone('')
        onChange?.([getCountryValuebyUuid(countryUuid), ''])
      }
    },
    [countryUuid]
  )

  return (
    <View style={[manipulator.container('row', 'flex-start', 'center'), isMobile ? {} : {}]}>
      <SearchSelector
        key={countryUuid}
        onChange={handleSelectorChange}
        value={countryUuid}
        items={countryItemsWithDisplayTitle}
        uniqueKey='uuid'
        {...selectorConfig}
        style={isMobile ? { flex: 1 } : {}}
        titleDisplayKey='displayTitle'
        {...rest}
      />
      <ThemedTextInput
        onChangeText={handleTextInputChange}
        value={phone}
        style={[isMobile ? { flex: 1 } : { flex: 3 }, { marginLeft: 10 }]}
        keyboardType='phone-pad'
        {...inputConfig}
        {...rest}
      />
    </View>
  )
})

export default CountryPhoneInput
