export { ChannelType } from '@rezio/utils/types'

export enum ChannelStatus {
  On = 'ON',
  Off = 'OFF',
  Connecting = 'CONNECTING',
  ConnectFailed = 'CONNECT_FAILED',
  Locked = 'LOCKED'
}

export enum ChannelIntegrationEnableStatus {
  Off,
  On,
  Fuzzy
}

export enum ChannelCostRuleType {
  Default,
  Cost, // 成本制
  Commission // 佣金制
}

/**
 * detail: {@link https://docs.google.com/spreadsheets/d/1t3EjHpsuKzRsTijaZxfUZ4xX-9rsVlaSxSOmRhmRZI0/edit#gid=1591794897&range=B2}
 */
export enum ChannelProductStatus {
  Init = 'INIT',
  ReadyToMap = 'READY_TO_MAP',
  Ready = 'READY',
  Pending = 'PENDING', // 跟後端確認過沒有 PENDING
  Syncing = 'SYNCING',
  Posting = 'POSTING',
  Mapped = 'MAPPED',
  Posted = 'POSTED',
  Pulled = 'PULLED',
  MapFailed = 'MAP_FAILED',
  PostFailed = 'POST_FAILED',
  Invalid = 'INVALID',
  UnavailablePost = 'UNAVALIABLE_POST', // 後端錯字
  UnavailablePost2 = 'UNAVALIABLE_POST'
}

// 通路行程連結狀態
export enum ChannelProductStatusNumber {
  Init = 1,
  Ready = 2,
  Syncing = 4,
  Posted = 5,
  Pulled = 8,
  PostFailed = 9,
  Invalid = 11,
  Mapped = 13,
  MapFailed = 14,
  ReadyToMap = 15,
  UnavailablePost = 17,
  UnavailablePost2 = 18,
  Posting = 19
}

export enum ProductSource {
  Distributor = 'distributor',
  Channel = 'channel'
}
// 通路產品上下架狀態

export enum ChannelProductActiveStatus {
  Init = 'INIT',
  On = 'ON',
  Off = 'OFF'
}

export enum ChannelProductVoucherTemplateType {
  Default,
  KKday,
  Rezio
}

export enum ChannelPricingType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
  FromChannel = 'FROM_CHANNEL' // 佣金制後： 新增價格設定來自於第三方
}

export enum ChannelProductTransfer {
  Init = 'INIT',
  Processing = 'PROCESSING',
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export enum ChannelIdentityType {
  MySite = 'MYSITE',
  Supply = 'SUPPLY',
  Connection = 'CONNECTION'
}

export enum ChannelIdentityTypeNumber {
  MySite = 0,
  Supply = 1,
  Connection = 2
}

export enum ChannelIdentityDisplayType {
  TextInput = 'TEXTINPUT',
  Selector = 'SELECTOR',
  Hidden = 'HIDDEN'
}

export enum ChannelSalesOptionExtendStatus {
  Default,
  Success,
  Fail,
  Processing
}

export enum ChannelProductExtendType {
  Calendar = 'calendar'
}

export enum ChannelUrlType {
  MYSITE = 'MYSITE',
  WIDGET = 'WIDGET'
}

export enum CannelPayOnSiteSyncStatus {
  Default,
  Syncing,
  Success,
  Fail
}

export enum PriceSettingType {
  Custom = 'CUSTOM',
  SameAsProduct = 'SAME_AS_PRODUCT'
}
