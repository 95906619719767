import { StaticImage } from '@rezio/components'
import { Droppable } from '@rezio/components/droppable'
import { useStores } from '@rezio/core/hooks'
import { template } from '@rezio/res/theme'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { config } from './mediaLibrary'
import { FileLimitType, ImageItem, MediaLibraryCategory, DroppableWrapperProps } from './types'
import { formatImageFile, validateImageFile } from './utils'

const defaultConfig: FileLimitType = {
  sizeLimitMB: 1,
  acceptFileTypesRex: /image.*/,
  acceptFileMinWidth: 100,
  acceptFileMinHeight: 100,
  acceptSquareOnly: false,
  defaultPixel: 168 * Math.pow(10, 5)
}

export const DroppableWrapper = observer(
  ({
    children,
    droppableRef,
    storeCategory,
    style,
    isMultiple,
    prevSelectedFiles,
    onUploadedImage
  }: DroppableWrapperProps) => {
    const { t } = useTranslation()
    const { core, store } = useStores()
    const imageStore = store.imageStore.getCategory(storeCategory)
    const [isLoading, setIsLoading] = useState(false)

    const handleWebSelectFile = useCallback(
      async (
        imageFiles: File[],
        prevSelectedFiles: ImageItem[],
        imageCategory: MediaLibraryCategory
      ) => {
        const validatedImages = await Promise.all(
          imageFiles.map(async (image: File) => {
            const file = _.get(image, 'target.files', image)
            const formattedFile = await formatImageFile(file, t)
            if (!formattedFile) return null

            const errors = validateImageFile({
              formattedFile,
              fileLimit: { ...defaultConfig, ...config[storeCategory] },
              t
            })

            if (errors.length > 0) {
              core.setRuntimeError(errors.join('\n')).catch(console.error)
              return null
            }

            return image
          })
        ).then((images) => images.filter((image) => image !== null))

        if (validatedImages.length > 0) {
          try {
            setIsLoading(true)
            const selectedUuids = await imageStore.createImage(validatedImages, imageCategory, null)
            const nextImages = selectedUuids.map((uuid: string) => {
              const integralImageData = imageStore.images.get(uuid)

              return {
                url: _.get(integralImageData, 'url'),
                storeMediaUsageUuid: _.get(integralImageData, 'storeMediaUsageUuid'),
                clientFileName: _.get(integralImageData, 'clientFileName'),
                size: _.get(integralImageData, 'size', 0)
              }
            })

            droppableRef.current.value = ''
            onUploadedImage?.(nextImages, prevSelectedFiles)
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        }
      },
      [storeCategory, config, droppableRef]
    )

    return (
      <Droppable
        ref={droppableRef}
        onFiles={handleWebSelectFile}
        style={style}
        multiple={isMultiple}
        disabled={storeCategory === 'all'}
        imageCategory={storeCategory}
        prevSelectedFiles={prevSelectedFiles}
      >
        {isLoading ? (
          <StaticImage
            style={[template.center, { width: 110, height: 42 }]}
            source={require('../../assets/animation.gif')}
          />
        ) : (
          children
        )}
      </Droppable>
    )
  }
)
