import styled from '@emotion/native'
import { MaterialIcons } from '@expo/vector-icons'
import { manipulator, palette } from '@rezio/res/theme'
import React, { type ReactNode } from 'react'
import { Text, View } from 'react-native'

interface Props {
  list: ReactNode[] | JSX.Element[]
  desc: string | ReactNode | JSX.Element
}

export function WarningPanel({ list, desc }: Props) {
  return (
    <View
      style={{ paddingVertical: 14, paddingHorizontal: 25, backgroundColor: palette.warningBg }}
    >
      {list}
      <Br />
      <Text>{desc}</Text>
    </View>
  )
}

export const Dot = ({ style, ...props }: { style?: object; [k: string]: unknown }) => (
  <MaterialIcons
    name='fiber-manual-record'
    color={palette.black}
    size={10}
    style={{ marginRight: 5, ...style }}
    {...props}
  />
)

export const Br = () => (
  <View>
    <Text>{'\n'}</Text>
  </View>
)

export const SamplePanel = styled.View<{ border?: true; hide?: boolean }>(
  ({ hide }) => hide && { display: 'none' },
  ({ border }) => border && manipulator.border('all', 'light', 5),
  manipulator.panel(20, 20, 20, 20),
  { marginTop: 20 }
)
