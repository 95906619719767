import { AppConfigContext, getEnv } from '@rezio/core/config'
import { useStores } from '@rezio/core/hooks'
import { openURL } from '@rezio/core/linking'
import { isAvailableVersion } from '@rezio/utils/misc'
import { nativeApplicationVersion } from 'expo-application'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AppState, Platform } from 'react-native'

import { loadFlagsFromAsyncStorage } from './flags'

interface CustomReportConfig {
  type: 'CUSTOM_REPORT'
  src: string
  description?: string
}

interface RemoteConfig {
  version: string
  store: Record<string, CustomReportConfig[]>
}

export const fetchAppConfig = async (): Promise<RemoteConfig> => {
  return await (
    await fetch(
      getEnv() === 'prod'
        ? 'https://rezio-web-config.s3.ap-northeast-1.amazonaws.com/admin/runtime.json'
        : `https://rezio-web-config.s3.ap-northeast-1.amazonaws.com/admin/runtime-${getEnv()}.json`,
      { cache: 'no-cache' }
    )
  ).json()
}

export const AppConfigProvider = observer((props) => {
  const [rawConfig, setConfig] = useState(null)
  const [storeBasic, setStoreBasic] = useState(null)
  const { store, core, data } = useStores()
  const { t } = useTranslation()

  const appState = useRef(AppState.currentState)

  const displayForceUpdate = useCallback(() => {
    const currentVersion = nativeApplicationVersion
    const latestVersion: string = rawConfig?.version
    Alert.alert(
      t('COMMON.APP_FORCE_UPDATE_TITLE'),
      t('COMMON.APP_FORCE_UPDATE_DESCRIPTION', { currentVersion, latestVersion }),
      [
        {
          text: t('COMMON.APP_UPDATE_NOW'),
          onPress: async () => {
            const appStoreUrl = Platform.select({
              ios: 'https://apps.apple.com/tw/app/id1480364623',
              android: 'https://play.google.com/store/apps/details?id=io.rezio.admin'
            })
            await openURL(appStoreUrl, true)
          }
        }
      ]
    )
  }, [rawConfig?.version])

  const handleCheckUpdate = useCallback(() => {
    if (Platform.OS === 'web' || !rawConfig?.version) {
      return
    }

    const currentVersion = nativeApplicationVersion
    const latestVersion: string = rawConfig?.version

    if (!isAvailableVersion({ currentVersion, latestVersion })) {
      displayForceUpdate()
    }
  }, [rawConfig?.version, displayForceUpdate])

  const handleChangeAppState = useCallback(
    (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        handleCheckUpdate()
      }
      appState.current = nextAppState
    },
    [handleCheckUpdate]
  )

  useEffect(() => {
    handleCheckUpdate()
  }, [handleCheckUpdate])

  useEffect(() => {
    if (Platform.OS === 'web') {
      return
    }
    const appEventSubscription = AppState.addEventListener('change', handleChangeAppState)
    return () => {
      appEventSubscription?.remove()
    }
  }, [handleChangeAppState])

  useEffect(() => {
    loadFlagsFromAsyncStorage()

    fetchAppConfig().then(setConfig).catch(console.error)

    // long-polling config file every 5 minutes
    const intervalId = setInterval(
      () => {
        fetchAppConfig().then(setConfig).catch(console.error)
      },
      5 * 60 * 1000
    )
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const loadBasic = useCallback(async () => {
    const result = (await core.api.get('store/basic')[0]).data
    setStoreBasic(result)
    data.setStoreBasic(result)
  }, [core])

  // 每 24 小時更新一次 store basic
  useEffect(() => {
    let storeInterval = null
    if (store?.core?.session?.store?.storeUuid) {
      clearInterval(storeInterval)
      loadBasic().catch(console.error)
      storeInterval = setInterval(
        () => {
          loadBasic().catch(console.error)
        },
        24 * 60 * 60 * 1000
      )
    }

    return () => {
      clearInterval(storeInterval)
    }
  }, [store?.core?.session?.store?.storeUuid])

  const config = useMemo(() => {
    return {
      store: rawConfig?.store?.[store?.core?.session?.store?.storeUuid] ?? null,
      basic: storeBasic,
      reloadBasic: loadBasic
    }
  }, [rawConfig, storeBasic, store?.core?.session?.store?.storeUuid])

  return <AppConfigContext.Provider value={config}>{props.children}</AppConfigContext.Provider>
})
