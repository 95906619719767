import moment from 'moment'

type GenerateCalendar = (year: number, monthIndex: number, startDayOfWeek: number) => string[][]
export const generateCalendar: GenerateCalendar = (year, monthIndex, startDayOfWeek) => {
  const calendar = []
  const lastDayOfMonth = new Date(year, monthIndex + 1, 0)
  const currentDate = new Date(year, monthIndex, 1)

  while (currentDate.getDay() !== startDayOfWeek) {
    currentDate.setDate(currentDate.getDate() - 1)
  }

  while (currentDate.getTime() <= lastDayOfMonth.getTime()) {
    const week = []
    for (let i = 0; i < 7; i++) {
      week.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate.setDate(currentDate.getDate() + 1)
    }
    calendar.push(week)
  }

  return calendar
}

type GenerateNewYearMonth = (year: number, monthIndex: number, diff: 1 | -1) => [number, number]
export const generateNewYearMonth: GenerateNewYearMonth = (year, monthIndex, diff) => {
  const newDate = new Date(year, monthIndex + diff, 1)
  return [newDate.getFullYear(), newDate.getMonth()]
}
