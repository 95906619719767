import _ from 'lodash'
import React, { Fragment } from 'react'
import { View, Text, StyleSheet, ScrollView, Dimensions, ViewProps } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import type { ModalProps } from 'react-native-modal'
import { useFooter } from '@rezio/core/layout'
import { usePageWidth, useLayout } from '../core/hooks'
import { palette, manipulator } from '../res/theme'
import Modal from '../unimodules/modal'
import { ThemedButton } from './themedComponents'
import { Separator } from './filterableTable'
import { PageStepControl, type PageStepControlButton } from './formComponent'

const styles = StyleSheet.create({
  container: {
    width: 500,
    padding: 20,
    backgroundColor: palette.white,
    alignSelf: 'center',
    borderRadius: 4
  }
})

export const SlotModal = ({
  as: Slot,
  children,
  ...props
}: PageWidthModalProps & {
  as: typeof Fragment | typeof View | typeof PageWidthModal | typeof FullModal
}) => {
  if (Slot === Fragment) {
    return <>{children}</>
  } else {
    return <Slot {...props}>{children}</Slot>
  }
}

export interface PageWidthModalProps extends ModalProps {
  containerStyle?: ViewProps['style']
  pageWidthContainerStyle?: ViewProps['style']
  contentContainerStyle?: ViewProps['style']
  footerStyle?: ViewProps['style']
  notUseScrollView?: boolean
}

export function PageWidthModal({
  children,
  style,
  containerStyle,
  contentContainerStyle,
  footerStyle,
  notUseScrollView,
  pageWidthContainerStyle,
  ...props
}: PageWidthModalProps) {
  const pageWidth = usePageWidth()
  const { isMobile } = useLayout()
  const [footerContext, footer, FooterWrapper] = useFooter()
  const insets = useSafeAreaInsets()
  const RenderView = notUseScrollView ? View : ScrollView

  return (
    <Modal style={[{ margin: 0, backgroundColor: isMobile ? 'white' : undefined }]} {...props}>
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: insets.top,
            paddingBottom: insets.bottom
          },
          containerStyle
        ]}
      >
        <FooterWrapper value={footerContext}>
          <View
            style={[
              {
                width: pageWidth,
                maxHeight: isMobile ? '100%' : '90%',
                backgroundColor: palette.white,
                maxWidth: 1300,
                minWidth: 300
              },
              isMobile ? { flex: 1 } : {},
              pageWidthContainerStyle
            ]}
          >
            <RenderView
              contentContainerStyle={contentContainerStyle}
              style={[{ flex: 1, maxHeight: '100%', padding: 30 }, style]}
            >
              {children}
            </RenderView>
            <View style={[{ width: '100%' }, footerStyle]}>{footer || <View />}</View>
          </View>
        </FooterWrapper>
      </View>
    </Modal>
  )
}

export function FullModal({ children, ...props }: ModalProps) {
  return (
    <Modal
      style={[
        StyleSheet.absoluteFill,
        { justifyContent: 'center', alignItems: 'center', margin: 0 }
      ]}
      {...props}
    >
      {children}
    </Modal>
  )
}

export function DialogModal({
  children,
  style,
  containerStyle,
  ...props
}: ModalProps & { containerStyle?: ViewProps['style'] }) {
  return (
    <Modal
      animationIn='slideInUp'
      style={[
        StyleSheet.absoluteFill,
        { justifyContent: 'center', alignItems: 'center', margin: 0 },
        style
      ]}
      {...props}
    >
      <View
        style={[
          {
            backgroundColor: 'white',
            borderRadius: 5,
            padding: 20,
            width: Math.min(340, Dimensions.get('window').width - 20)
          },
          containerStyle
        ]}
      >
        {children}
      </View>
    </Modal>
  )
}

export function MultiMessageModal(
  props: ModalProps & {
    buttonsType: { type: string; text: string }[]
    title: string
    description: string
    onClose: (type: string) => void
  }
) {
  const { buttonsType, title, description, isVisible, onClose } = props
  return (
    <Modal backdropOpacity={0.1} isVisible={isVisible}>
      <View style={styles.container}>
        <Text>{title}</Text>
        <Text>{description}</Text>
        <View style={[manipulator.container('row', 'space-between', 'center'), { marginTop: 30 }]}>
          {!_.isEmpty(buttonsType.filter((each) => each.type === 'following')) && (
            <ThemedButton
              style={{ width: 150, marginRight: 10 }}
              onPress={() => onClose('following')}
            >
              {buttonsType.filter((each) => each.type === 'following')[0].text}
            </ThemedButton>
          )}
          {!_.isEmpty(buttonsType.filter((each) => each.type === 'all')) && (
            <ThemedButton style={{ width: 150, marginRight: 10 }} onPress={() => onClose('all')}>
              {buttonsType.filter((each) => each.type === 'all')[0].text}
            </ThemedButton>
          )}
          {!_.isEmpty(buttonsType.filter((each) => each.type === 'cancel')) && (
            <ThemedButton style={{ width: 150 }} onPress={() => onClose('cancel')}>
              {buttonsType.filter((each) => each.type === 'cancel')[0].text}
            </ThemedButton>
          )}
        </View>
      </View>
    </Modal>
  )
}

export const DeleteModal = ({
  isShow,
  title,
  content,
  buttons
}: {
  isShow?: boolean
  title: string
  content: string
  buttons: PageStepControlButton[]
}) => {
  return (
    <PageWidthModal isVisible={isShow}>
      <View>
        <Text style={{ color: palette.primary, fontSize: 18, paddingBottom: 10 }}>{title}</Text>
        <View style={{ marginVertical: 20 }}>
          <Separator />
        </View>
        <Text style={{ textAlign: 'center' }}>{content}</Text>
      </View>
      <PageStepControl buttons={buttons} />
    </PageWidthModal>
  )
}

export { Modal }
