import * as Linking from 'expo-linking'
import * as WebBrowser from 'expo-web-browser'
import qs from 'query-string'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import parse from 'url-parse'

import { useRouter } from './routing/router'

const isWeb = Platform.OS === 'web'
const webProtocols = ['http:', 'https:']
const inAppProtocols = ['rezio:']
let firstOpen = true

function isAppSupportPath(path) {
  // todo: add app supported-page
  return false
}

async function prependInternalURL(url: string) {
  if (isWeb) return url
  return url[0] === '/' ? 'rezio://' + url : url
}

export async function openURL(
  url: string,
  newWindow?: boolean,
  options?: { windowFeatures?: string }
) {
  const targetUrl = await prependInternalURL(url)
  const supported = await Linking.canOpenURL(targetUrl)
  const windowFeatures = `noopener${options?.windowFeatures ? `,${options.windowFeatures}` : ''}`
  if (!supported) {
    return
  }

  if (newWindow) {
    if (isWeb) {
      window.open(targetUrl, '_blank', windowFeatures)
    } else {
      await Linking.openURL(targetUrl)
    }
  } else {
    await (isWeb ? Linking.openURL(targetUrl) : WebBrowser.openBrowserAsync(targetUrl))
  }
}

function handleUrl(router, url: string, inAppOnly?: boolean) {
  let { protocol, pathname, query } = parse(url, qs.parse)
  const isWebUrl = webProtocols.includes(protocol)
  if (isWebUrl && !isAppSupportPath(pathname)) {
    !inAppOnly && openURL(url, true).catch(console.error)
  } else if (!inAppProtocols.includes(protocol)) {
    !inAppOnly && openURL(url).catch(console.error)
  } else {
    if (!isWebUrl) {
      const linkingUrl = Linking.parse(url.replace(/^rezio:\/\//, 'rezio:///'))
      pathname = linkingUrl.path
      query = linkingUrl.queryParams
    }
    router.push({ pathname, query })
  }
}

export const useDeeplink = () => {
  const router = useRouter()

  useEffect(() => {
    if (isWeb) {
      return
    }

    if (firstOpen) {
      Linking.getInitialURL()
        .then((url) => {
          if (url) {
            handleUrl(router, url, true)
            firstOpen = false
          }
        })
        .catch(console.error)
    }

    const eventHandler = ({ url }) => handleUrl(router, url, true)
    const subscription = Linking.addEventListener('url', eventHandler)

    return () => {
      subscription.remove()
    }
  }, [router])
}
