import React, { forwardRef } from 'react'

import { useRouter } from '@rezio/core/hooks'

export { Redirect } from '@rezio/unimodules/router'
export { useRouter } from '@rezio/core/hooks'

export function withRouter(ComposedComponent) {
  return forwardRef(function WithRouteWrapper(props, ref) {
    const router = useRouter()
    return <ComposedComponent ref={ref} router={router} {...props} />
  })
}
