import { template, manipulator, palette } from '@rezio/res/theme'
import _ from 'lodash'
import React, { Component } from 'react'
import { Text, View, StyleSheet, ActivityIndicator } from 'react-native'

import { HoverableOpacity } from '../hoverableOpacity'

export class ThemedButton extends Component<any> {
  static defaultProps = {
    pressIn: false,
    disabled: false,
    loading: false,
    async: true,
    loadingTime: 10,
    onPress: () => {},
    onPressIn: () => {},
    onPressOut: () => {},
    onShowUnderlay: () => {},
    onHideUnderlay: () => {}
  }

  willUnmount = false

  state = {
    pressIn: false,
    triggering: false
  }

  styles = StyleSheet.create({
    container: {
      padding: 8,
      backgroundColor: palette.primary,
      borderRadius: 4,
      ...template.defaultHeight
    },
    indicator: {
      paddingRight: 6
    }
  })

  handlePress = async (e) => {
    if (this.state.triggering || this.props.loading) {
      return true
    }
    if (!this.props.async || !this.props.loadingText) {
      return this.props.onPress()
    }
    this.setState({
      triggering: true
    })
    try {
      await this.props.onPress()
    } finally {
      if (!this.willUnmount) {
        setTimeout(() => {
          this.setState({ triggering: false })
        }, this.props.loadingTime)
      }
    }
    return true
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  handlePressIn = (e, ...arg) => {
    this.setState({ pressIn: true })
    if (this.props.onPressIn) {
      this.props.onPressIn(e, ...arg)
    }
  }

  handlePressOut = (e, ...arg) => {
    this.setState({ pressIn: false })
    if (this.props.onPressOut) {
      this.props.onPressOut(e, ...arg)
    }
  }

  render() {
    const {
      onPress,
      style,
      textStyle,
      containerStyle,
      // size = 'large',
      layout,
      loadingTextColor = palette.white,
      hoverClass = 'hover:!opacity-80',
      store,
      disabled,
      activeClass,
      loading,
      loadingText,
      indicatorColor,
      accessibilityState,
      ...restProps
    } = this.props
    const { triggering } = this.state
    const content =
      typeof this.props.children === 'string' ? (
        <Text
          style={[
            manipulator.text(palette.white),
            this.props.disabled && { color: 'black' },
            textStyle
          ]}
          accessibilityRole='text'
        >
          {this.props.children}
        </Text>
      ) : (
        this.props.children
      )
    const loadingContent = loadingText ? (
      <Text
        style={[
          manipulator.text(palette.white),
          this.props.disabled && { color: 'black' },
          triggering && { color: loadingTextColor },
          textStyle
        ]}
      >
        {loadingText}
      </Text>
    ) : (
      content
    )
    return (
      <View style={containerStyle}>
        <HoverableOpacity
          activeOpacity={0.4}
          hoverClass={hoverClass}
          style={[
            manipulator.container('row', 'center', 'center'),
            {
              minWidth:
                layout === 'default'
                  ? _.get(store, 'viewStore.layout') !== 'desktop'
                    ? 80
                    : 110
                  : layout === 'block'
                  ? '100%'
                  : undefined
            },
            this.styles.container,
            this.props.disabled
              ? { backgroundColor: palette.disable }
              : this.state.triggering
              ? { opacity: 0.7 }
              : {},
            style
          ]}
          disabled={disabled || this.state.triggering}
          onPress={this.handlePress}
          onPressIn={this.handlePressIn}
          onPressOut={this.handlePressOut}
          accessibilityRole='button'
          accessibilityState={Object.assign(
            {},
            { disabled: disabled || this.state.triggering },
            accessibilityState
          )}
          {...restProps}
        >
          {loading || triggering ? (
            <ActivityIndicator
              accessibilityHint='loading'
              style={this.styles.indicator}
              animating
              color={indicatorColor}
              size='small'
            />
          ) : null}
          {loading || triggering ? loadingContent : content}
        </HoverableOpacity>
      </View>
    )
  }
}
