import { manipulator } from '@rezio/res/theme'
import memoizeOne from 'memoize-one'
import React from 'react'
import type { FlatListProps } from 'react-native'
import { FlatList, View } from 'react-native'

import { ExpendableCell, ExpendableCellProps } from './ExpendableCell'

export type ItemT<T> = Omit<ExpendableCellProps<T>, 'onChange'>

interface ExpendableListProps<T> extends Omit<FlatListProps<T>, 'renderItem' | 'keyExtractor'> {
  t: (input: string) => string
  onChange: (showChildren: boolean, uuid: string, checkedChild: string[]) => void
}

const renderCell = memoizeOne((onChange, t) => ({ item }) => (
  <ExpendableCell onChange={onChange} t={t} {...item} />
))
export const ExpendableList = <T extends ItemT<unknown>>({
  data,
  onChange,
  t,
  ...rest
}: ExpendableListProps<T>) => (
  <FlatList<T>
    removeClippedSubviews
    keyExtractor={(item) => item.uuid}
    data={data}
    renderItem={renderCell(onChange, t)}
    ItemSeparatorComponent={Separator}
    showsHorizontalScrollIndicator={false}
    maxToRenderPerBatch={30}
    getItemLayout={(data, index) => ({ length: data.length, offset: 56 * index, index })}
    {...rest}
  />
)

export const Separator = ({ highlighted }) => {
  return <View style={[manipulator.border('bottom', 'light'), highlighted && { marginLeft: 0 }]} />
}
