import { MaterialIcons } from '@expo/vector-icons'
import { palette } from '@rezio/res/theme'
import React, { Component, ReactNode } from 'react'
import type { StyleProp, ViewStyle, ColorValue, View } from 'react-native'
import { Text } from 'react-native'

import { ThemedButton } from './ThemedButton'

export interface ThemedComponentType {
  value: unknown
  disabled?: boolean
  labelStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  disabledColor?: ColorValue
  label?: string | Text | View | ReactNode
  theme?: any
  onValueChange?: (checked: boolean) => void
  onChange?: (checked: boolean) => void
}

interface TCheckBox extends ThemedComponentType {
  value: boolean | string
  enablePartial?: boolean
}

export class ThemedCheckBox extends Component<TCheckBox> {
  handlePress = () => {
    const changeHandler = this.props.onValueChange || this.props.onChange
    changeHandler?.(!this.props.value)
  }

  render() {
    const {
      disabled,
      value: propsValue,
      labelStyle = {},
      style = {},
      disabledColor,
      enablePartial,
      label
    } = this.props
    return (
      <ThemedButton
        disabled={disabled}
        style={[{ backgroundColor: 'transparent' }, style]}
        onPress={this.handlePress}
        accessibilityRole='checkbox'
        accessibilityState={{ disabled: !!disabled, checked: !!propsValue }}
      >
        <MaterialIcons
          name={
            enablePartial && propsValue === 'partial'
              ? 'indeterminate-check-box'
              : propsValue
              ? 'check-box'
              : 'check-box-outline-blank'
          }
          color={
            disabled
              ? disabledColor || palette.active
              : propsValue
              ? palette.active
              : this.props.theme?.textColor
          }
          size={16}
        />
        <Text accessibilityRole='text' style={[{ marginLeft: 4 }, labelStyle]}>
          {label as string}
        </Text>
      </ThemedButton>
    )
  }
}
