declare global {
  interface Window {
    __WEB_REVISION_ID__?: string
  }
}

export type PropsOf<T> = T extends React.ComponentType<infer P> | React.Component<infer P>
  ? P
  : never

export enum ChannelType {
  None = 'NONE',
  Distributor = 'Distr',
  MySite = 'MYSITE',
  KKDAY = 'KKDAY',
  KLOOK = 'KLOOK',
  VIATOR = 'VIATOR',
  TRIP = 'TRIP',
  KKDAYMKP = 'KKDAYMKP',
  ACTIVITYJAPAN = 'ACTIVITYJAPAN',
  POS = 'POS',
  GYG = 'GYG',
  KIOSK = 'KIOSK',
  JALAN = 'JALAN'
}

export enum ButtonAction {
  Save = 'SAVE',
  Delete = 'DELETE',
  Open = 'OPEN',
  Close = 'CLOSE'
}

export enum DepartureType {
  ByBooking = 1,
  ByDeparture
}

export enum SyncStrategyType {
  CacheFirst = 'cacheFirst',
  StaleWhileRevalidate = 'staleWhileRevalidate'
}

export enum NavigatorStatus {
  Active = 'ACTIVE',
  Locked = 'LOCKED',
  Hidden = 'HIDDEN',
  Disabled = 'DISABLED'
}

export enum LoginStatus {
  Logined = 1,
  NotLogin
}

export enum ProductStatus {
  InProgress = 'IN_PROGRESS',
  AvailableUntil = 'AVAILABLE_UNTIL',
  Archived = 'ARCHIVED',
  Discontinued = 'DISCONTINUED',
  FullyBooked = 'FULLY_BOOKED',
  EndSelling = 'END_SELLING'
}

export enum OrderSource {
  Distributor = 'DISTRIBUTORS',
  Affiliate = 'AFFILIATE',
  Machine = 'MACHINE',
  UtmSource = 'UTMSOURCE',
  Custom = 'CUSTOM',
  Admin = 'ADMIN'
}

export enum ReconciliationStatus {
  Ignore = 'IGNORE', // 不結算
  Pending = 'PENDING', // 未結算
  Finished = 'FINISHED' // 已結算
}

export enum PayStatus {
  Unpaid = 'UNPAID',
  PaidPartial = 'PAID_PARTIAL',
  PaidAll = 'PAID_ALL',
  RefundPartial = 'REFUND_PARTIAL',
  RefundAll = 'REFUND_ALL',
  Refund = 'REFUND',
  NoPaymentNeeded = 'NO_PAYMENT_NEEDED',
  ChannelCheckout = 'CHANNEL_CHECKOUT',
  DistributorCheckout = 'DISTRIBUTOR_CHECKOUT',
  PayOnSite = 'PAY_ON_SITE'
}

export enum PayoutStatus {
  None = 'NONE',
  Pending = 'PENDING',
  Available = 'AVAILABLE',
  Withdrawed = 'WITHDRAWED',
  Offline = 'OFFLINE'
}

// 金流平台
export enum PaymentGateway {
  Neweb = 'NEWEB',
  Orico = 'ORICO',
  Stripe = 'STRIPE',
  Toss = 'TOSS',
  '2C2P' = '2C2P'
}

export enum PaymentPlatform {
  Neweb = 'NEWEB',
  Orico = 'ORICO',
  RezioPayOrico = 'REZIOPAYORICO',
  Stripe = 'STRIPE',
  StripeHK = 'STRIPEHK',
  StripeHKUSD = 'STRIPEHKUSD',
  StripeSG = 'STRIPESG',
  StripeSGUSD = 'STRIPESGUSD',
  StripeMY = 'STRIPEMY',
  StripeDirectPay = 'STRIPEDIRECTPAY',
  Toss = 'TOSS',
  '2C2PTH' = '2C2PTH',
  '2C2PHK' = '2C2PHK',
  '2C2PMY' = '2C2PMY',
  '2C2PSG' = '2C2PSG',
  '2C2PSGUSD' = '2C2PSGUSD'
}

// NOTE: @paymentType
export enum PaymentMethod {
  Cash = 'CASH',
  CashCoupon = 'CASH_COUPON',
  CreditCard = 'CREDIT_CARD',
  BankTransfer = 'BANK_TRANSFER',
  FromChannel = 'FROM_CHANNEL',
  CouponFromChannel = 'COUPON_FROM_CHANNEL',
  PointsFromChannel = 'POINTS_FROM_CHANNEL',
  Credit = 'CREDIT',
  Google = 'GOOGLE',
  SAMSUNG = 'SAMSUNG',
  ATM = 'ATM',
  CVS = 'CVS',
  BANK = 'BANK',
  Monthly = 'MONTHLY',
  Mobile = 'MOBILE',
  Grab = 'GRAB',
  Fpx = 'FPX',
  PayOnSite = 'PAY_ON_SITE',
  PayNow = 'PAYNOW',
  PayMe = 'PAYME',
  Prompt = 'PROMPT',
  Alipay = 'ALIPAY',
  AlipayHK = 'ALIPAYHK',
  Boost = 'BOOST',
  WeChat = 'WECHAT',
  TouchNGo = 'TOUCHNGO',
  TrueMoney = 'TRUEMONEY',
  LinePay = 'LINEPAY',
  '123SERVICE' = '123SERVICE'
}

export enum PaymentPlatformStatus {
  Initial, // 未開通 (console已設定費率，此時console管理員可以編輯，BD不可編輯)
  InProgress, // 申請中（已打到金流 gateway）
  Ready, // 已開通
  Failed
}

export enum PaymentAcceptPayType {
  AllowBankTransfer = 1,
  AllowCash = 2,
  AllowOnlinePayment = 4
}

export enum FeeDetailSystemValueType {
  Percentage = 'PERCENTAGE',
  Fixed = 'FIXED'
}

export enum OrderStatus {
  Invalid = 'INVALID',
  Canceled = 'CANCELED',
  OnHold = 'ON_HOLD',
  New = 'NEW',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  CheckIn = 'CHECKIN',
  NoShow = 'NOSHOW',
  CancelProcessing = 'CANCEL_PROCESSING',
  ToBeCancel = 'TO_BE_CANCEL',
  Departed = 'DEPARTED',
  RedeemByCH = 'REDEEMBYCH',
  SpecialCancelByCh = 'SPECIALCANCELBYCH',
  CloseByCh = 'CLOSEBYCH'
}

export enum OrderPaymentRefundStatus {
  NotRefundable = 'notRefundable',
  GatewayProcessing = 'gatewayProcessing',
  OverRefundableTime = 'overRefundableTime',
  NoResidualValue = 'noResidualValue',
  RefundProcessing = 'refundProcessing',
  Refunded = 'refunded',
  Refundable = 'refundable'
}

export enum OrderPaymentStatus {
  Expired = -2,
  Fail,
  Initial,
  Processing,
  Success
}

export enum OrderSaleFromType {
  API = 'API',
  Machine = 'Machine',
  Widget = 'Widget',
  Admin = 'Admin',
  Custom = 'CUSTOM',
  MySite = 'MySite',
  Distributor = 'Distri',
  POS = 'POS',
  Kiosk = 'Kiosk'
}

export enum OrderMailType {
  CreatedOrderNdUnpaid = 'createdOrderNdUnpaid', // 訂單等待付款中
  CreatedOrderNdPaid = 'createdOrderNdPaid', // 訂單正在處理中
  CreatedCartOrderUnpaid = 'createdCartOrderUnpaid', // 訂單待付款 - 購物車
  OrderReceiptDeposit = 'orderReceiptDeposit', // 收據 - 訂金
  OrderReceiptTotal = 'orderReceiptTotal', // 收據 - 總額
  OrderReceiptShoppingCart = 'orderReceiptShoppingCart', // 收據 - 購物車
  OrderConfirmed = 'orderConfirmed', // 訂單已確認
  VoucherReady = 'voucherReady', // 給顧客的憑證通知
  DepartureReminder = 'departureReminder', // 給顧客的行前通知
  OrderCanceled = 'orderCanceled', // 訂單已取消
  RefundNotification = 'refundNotification', // 退費完成通知
  RefundAccountRequest = 'refundAccountRequest', // 退款處理(請客戶回覆收款帳號)
  DisCreatedOrderNdUnpaid = 'disCreatedOrderNdUnpaid', // [分銷商] 訂單 -待付款訂單成立通知
  DisCreatedOrderNdPaid = 'disCreatedOrderNdPaid', // [分銷商] 訂單- 已付款訂單成立通知
  DisOrderReceiptTotal = 'disOrderReceiptTotal', // [分銷商] 收據 - 總額
  DisOrderConfirmed = 'disOrderConfirmed', // [分銷商] 訂單 - 已確認
  DisVoucherReady = 'disVoucherReady', // [分銷商] 訂單 - 憑證通知信
  DisOrderCanceled = 'disOrderCanceled', // [分銷商] 訂單 - 已取消(手動發信)
  DisDepartureReminder = 'disDepartureReminder', // [分銷商] 訂單 - 行前通知信
  DisRefundAccountRequest = 'disRefundAccountRequest', // [分銷商] 訂單 - 手動退款處理信
  DisRefundNotification = 'disRefundNotification', // [分銷商] 訂單 - 退費完成通知
  OnRequestOrderProcessing = 'onRequestOrderProcessing', // 訂單正在候補中
  OnRequestOrderCanceled = 'onRequestOrderCanceled' // 候補已取消
}

export enum OrderHistoryActionType {
  InlineMakeReservation = 'INLINE_MAKE_RESERVATION',
  GenerateVoucher = 'GENERATE_VOUCHER',
  SendEmail = 'SEND_EMAIL',
  Status = 'STATUS',
  CreateReceiptInvoice = 'CREATE_RECEIPT_INVOICE',
  CreateReceiptReceipt = 'CREATE_RECEIPT_RECEIPT',
  UpdateReceiptStatus = 'UPDATE_RECEIPT_STATUS',
  UpdatePayStatus = 'UPDATE_PAY_STATUS',
  Refund = 'REFUND',
  Authorize = 'AUTHORIZE',
  Cancel = 'CANCEL',
  SyncLedger = 'SYNC_LEDGER'
}

export enum OrderCancelDetailRefundType {
  RefundForCancellationPolicy = 1,
  RefundForSpecialReason = 2
}
export enum OrderExchangeValidityStatus {
  Normal = 'NORMAL',
  Early = 'EARLY',
  Expired = 'EXPIRED'
}

export enum OrderLimitStatus {
  Unlimited,
  Limited
}

export enum ChannelHistoryActionType {
  IsOfficialWebsite = 'IS_OFFICIAL_WEBSITE',
  IsNotOfficialWebsite = 'ISNOT_OFFICIAL_WEBSITE',
  UpdateUrlType = 'UPDATE_URL_TYPE',
  TtdEnable = 'TTD_ENABLE',
  TtdDisabled = 'TTD_DISABLE'
}

export enum RuleCheckStatus {
  Init = 0,
  Success = 1,
  Failed = 2
}

export enum VoucherStatus {
  None = 'NONE',
  Processing = 'PROCESSING',
  Locked = 'LOCKED',
  Invalid = 'INVALID',
  Expired = 'EXPIRED',
  Valid = 'VALID',
  Used = 'USED'
}

export enum RedeemStatus {
  None = 'NONE',
  Partial = 'PARTIAL',
  All = 'ALL'
}

export enum SystemIdentity {
  REZIO = 'REZIO',
  ASOVIEW = 'ASOVIEW'
}

export enum SessionEditorStep {
  Confirm = 'CONFIRM',
  Edit = 'EDIT',
  Orders = 'ORDERS'
}

export enum VoucherConfirmType {
  Instant = 'INSTANTLY',
  Automatic = 'AUTOMATICALLY',
  NonAutomatic = 'NON_AUTOMATIC'
}

export enum VoucherDeliveryType {
  Simultaneous = 'SIMULTANEOUSLY',
  Specific = 'SPECIFICALLY',
  Manual = 'MANUALLY'
}

export enum VoucherLanguage {
  ByOrder,
  ByCustom
}

export enum DistributorTransactionType {
  Unlimited,
  Limited
}

export enum DistributorStatus {
  On = 1,
  All = 0,
  Off = -1
}

export enum DistributorReconciliationType {
  FollowMySite = 3,
  ByCalendar = 1,
  ByMonth = 2
}

export enum SessionQuotaType {
  BySession = 1,
  BySalesOption,
  Unlimited,
  BySalesOptionAllocatedByIdentityOrItem, // 依套餐設定總可售量，並依身份別/項目設定分配數
  BySessionAllocatedByIdentityOrItem // 依場次設定可售量，並依身份別/項目設定分配數
}

export enum SessionQuotaSettingType {
  Unlimited = -1,
  Limited = 1
}

export enum TicketType {
  Limit = 'LIMIT',
  Absolute = 'ABSOLUTE',
  Range = 'RANGE',
  Relative = 'RELATIVE',
  RelativeRange = 'RELATIVE_RANGE',
  Redeem = 'REDEEM'
}

export interface ExchangeValidity {
  type: RawTicketType
  canRedeem: boolean
  earlyRedeemType: number
  date?: string
  startDate?: string
  day?: number
  startDay?: number
}

export enum RawTicketType {
  Limit = 'LIMIT',
  Absolute = 'ABSOLUTE',
  Range = 'RANGE',
  Relative = 'RELATIVE',
  RelativeRange = 'RELATIVE_RANGE',
  Redeem = 'REDEEM',
  RedeemHour = 'REDEEM_HOUR',
  RedeemDay = 'REDEEM_DAY'
}

export enum TicketRedeemType {
  ByDays = 'REDEEM_DAY',
  ByDaysExcludingEnableDay = 'REDEEM',
  ByHours = 'REDEEM_HOUR'
}

export enum ExpiredTicketStatus {
  Usable = 'USABLE',
  Unusable = 'UNUSABLE'
}

export enum TicketCanEarlyRedeemType {
  Default = 'NOT_EARLY',
  Early = 'EARLY'
}

export enum TicketEarlyRedeemType {
  NonSpecificTime = 'WITHOUT_SPECIFIC_TIME',
  SpecificTime = 'WITH_SPECIFIC_TIME'
}

export enum PickupType {
  None = 'NONE',
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

export enum PickupRouteType {
  None = 'NONE',
  Fixed = 'FIXED',
  Relative = 'RELATIVE',
  Custom = 'CUSTOM'
}

export enum PricingPolicyType {
  ByItem = 'ITEM',
  ByPerson = 'PERSON'
}

export enum CancelPolicyType {
  Default = 'DEFAULT',
  Custom = 'CUSTOM'
}

export enum CancelPolicyRulePeriodUnit {
  Hour = 'HOUR',
  Day = 'DAY'
}

export enum CancelPolicyRuleUnit {
  Percentage = 'PERCENTAGE',
  Fixed = 'FIXED'
}

export enum MySiteHeaderType {
  ByStoreName = 1,
  ByLogo
}

export enum MySiteLayoutType {
  TypeA = 1,
  TypeB
}

export enum StockWarningType {
  None,
  ByPercentage,
  ByCount
}

export enum ForceRedeemSettingType {
  Limited = 'LIMITED',
  All = 'ALL',
  Off = 'OFF'
}

export enum VoucherType {
  None = 1, // 無
  Default, // Rezio憑證
  Custom, // 自訂憑證
  Integration,
  Upload, // 自行上傳憑證
  KKDAY // OTA憑證 - KKDAY
}

export enum VoucherQRCodeType {
  Default = 1,
  Custom_QR_Code,
  API_QR_Code,
  API_Barcode,
  No_QR_Code_Required
}

export enum VoucherGenQuantityType {
  ByOrder = 1,
  ByItem
}

export enum VoucherRepeatRedeem {
  Enable = 1,
  Disable
}

export enum VoucherCustomerRedeemType {
  Disable,
  Enable
}

export enum VoucherCutlineType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE'
}

export enum VoucherAcceptBookingType {
  Unlimit = 'UNLIMITED',
  Currentday = 'CURRENTDAY',
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE'
}

export enum VoucherSourceType {
  Customer = '0fefae5c-ffb1-42e4-aab9-7844d42a9738'
}

export enum ChannelUuid {
  MySite = '413425da-c5cb-4a8e-ad73-2fb1cb3d9564',
  KKday = 'c7dab402-54ed-44f7-ac55-491e0f733492'
}

export enum MySiteRuleCheckType {
  TTD = 'TTD',
  MySite = 'MySite'
}

// * doc: https://github.com/kkday-it/tourtako-eyes code list
export enum ImageCroppingSizeType {
  AdminVideoImageUpload1x = 'avi1',
  AdminImagePopUp1x = 'aip1',
  AdminItinerary1x = 'ait1',
  AdminImageUpload1x = 'aiu1',
  AdminPageList1x = 'apl1',
  AdminMySiteLogo1x = 'aml1',
  AdminMySiteBanner1x = 'amb1',
  MediaLibraryCard1x = 'mlc1'
}

export enum ImageUsageType {
  ChannelStoreLogo = 11,
  KioskBanner = 12,
  KioskScreenSaver = 13,
  PrintTemplateVoucherLogo = 14,
  GoogleTTDProductImage = 15
}

export enum ExtraPriceType {
  PerOrder = 1,
  PerQuantity = 2
}

export enum CurrencyType {
  Tw = '4fc314ab-a67a-436c-b0bc-88c72b9fbebd'
}

export enum TranslationType {
  Pickup = 'pickup',
  Extra = 'extra',
  FeeNdTax = 'feeNdTax',
  Store = 'store',
  Tag = 'tag',
  BookingInfoField = 'bookingInfoField',
  CancelPolicy = 'cancelPolicy',
  Product = 'product',
  PricePolicy = 'pricePolicy',
  Mysite = 'mysite',
  Ticket = 'ticket',
  CustomSource = 'customSource'
}

export enum LanguageCode {
  EN = 'en-US',
  JP = 'ja-JP',
  KR = 'ko-KR',
  VI = 'vi-VN',
  ZHCN = 'zh-CN',
  TH = 'th',
  ZHTW = 'zh-TW'
}

export enum SaleOptionPublishStatus {
  Unpublished,
  Published
}

export enum GoogleTTDStatus {
  Unpublished,
  Published,
  UnableToPublish
}

export enum DeviceType {
  Pos = 'POS',
  Kiosk = 'KIOSK',
  Payment = 'Payment',
  Gate = 'GATE'
}

export enum ImageLayoutType {
  Single,
  Multiple,
  MediaLibrary
}

export enum MediaLibraryType {
  Page,
  PopUp
}

export enum CommonOptionType {
  Off,
  On
}

// 尚未補齊
export interface FormInstance<T = any> {
  errors: ({ _key: string } & Record<string, { _message: [string, string] }>)[]
  value: T
  valid: boolean
  dirty: boolean
  reset: (value?: T) => void
}
export enum SessionStatus {
  Active = 'ACTIVE',
  OnRequest = 'ON_REQUEST',
  FullBooked = 'FULL_BOOKED',
  Inactive = 'INACTIVE'
}

export enum CalendarMode {
  Month = 'month',
  Week = 'week'
}

export enum ApplySystemEmail {
  TW = 'service-tw@rezio.io',
  JP = 'service-jp@rezio.io',
  KO = 'service-kr@rezio.io',
  Default = 'service@rezio.io'
}

export enum OrderTagUuids {
  GenerateVoucherError = '6acd7fcc-ad88-4e7d-a6cd-3d5dd247f39b', // 產生憑證異常
  SyncFailed = 'cd6a7c73-9d16-4b58-828d-2115a953342b', // 同步失敗
  IssueInvoiceFailed = '2408819e-b1b7-4c10-ab5d-7bfc6603a7e9', // 單據開立失敗
  RefundFailed = 'c7cab5e3-425a-431d-8062-14c8f3c16a96', // 線上退款失敗
  RedemptionOverdue = 'aa563528-dabd-452b-809c-3a18252aa89e', // 逾期未核銷
  ResourceUnallocated = '38accfc4-4fa7-4a2b-9f9e-e25f94c84789' // 尚未分配資源
}

export enum ReserveType {
  Publish = 'PUBLISH',
  Expire = 'EXPIRE'
}

export enum OnRequestOrderStatus {
  Canceled, // 候補已取消
  New, // 新候補單
  Processing, // 候補單處理中
  ToBeCancel // 候補待取消
}

export enum DefaultStockType {
  None,
  Percentage,
  Number
}

export enum ModifySessionAction {
  TargetSession = 'session',
  TodaySession = 'today',
  AllSessions = 'all',
  FollowingSessions = 'following'
}

export enum ResourceStockStatus {
  InStock = 'IN_STOCK',
  LowInventory = 'LOW_INVENTORY',
  SoldOut = 'SOLD_OUT',
  Empty = 'EMPTY'
}

export enum ProductCategories {
  OneDayTour = '7b191497-9ef1-4c15-9231-fc4ac70ca4d9',
  MultiDayTour = '76aa35e0-4498-4f24-a00f-9225fe111a47',
  PrivateTour = '412ef1ab-0cb1-41b3-bfa9-29780eb36b70',
  Activity = 'a6b6ade4-da97-4124-928e-f5e40ac037f1',
  SessionTicket = '38e85de1-cc26-4631-bc49-0e016164e4a9',
  NonSessionProduct = '5114189e-bc08-43de-bf47-d53d1df08791'
}

export enum CustomItemsButtonType {
  Upward = 'arrow-upward',
  Downward = 'arrow-downward',
  Delete = 'delete'
}

export enum ProductActionMode {
  Archive = 'archive',
  Unarchive = 'unarchive',
  Delete = 'delete',
  Duplicate = 'duplicate'
}

export enum ProductChannelStatusIcon {
  Success = 'check-circle',
  Warning = 'warning',
  Critical = 'cancel'
}

export enum OnOff {
  Off = 0,
  On = 1
}

// 訂單確認方式
export enum OrderConfirmMethod {
  INSTANTLY = 1, // 即時確認
  AUTOMATICALLY, // 自動確認
  NON_AUTOMATIC // 手動確認
}

// 給顧客的行前通知信:
// 0:不發送
// 1:出發前一天發送
// 2:出發前三天發送
export interface ProductEmailSetting {
  departureReminder: 0 | 1 | 2
}

export enum ProductBookingCutlineType {
  Absolute = 'ABSOLUTE', // 指定時間
  Relative = 'RELATIVE' // 相對時間
}
// 結團時間
export interface ProductBookingCutline {
  isSessionStartTs: boolean // 依場次 true: 開始, false: 結束
  type: ProductBookingCutlineType
  day?: number
  hour?: number
  min?: number
  time?: string // hh:mm
}

// 行程-預定類型
export enum ProductSaleableType {
  Unlimited = 'UNLIMITED', // 無限制
  CurrentDay = 'CURRENTDAY', // 僅開放當日場次
  Absolute = 'ABSOLUTE', // 指定時間
  Relative = 'RELATIVE' // 相對時間
}

// 最遠可預訂時間
export interface ProductSaleable {
  type: ProductSaleableType // 預訂類型
  day: number
  time: string // hh:mm for ABSOLUTE
  hour: number // for RELATIVE
}

// jotai loadable state
export enum JotaiLoadableState {
  Loading = 'loading',
  HasError = 'hasError',
  HasData = 'hasData'
}

export enum CurrencyUuid {
  JPY = '5673fd73-41d8-4464-be9f-7bc9776301ab'
}
