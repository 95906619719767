import { MaterialIcons } from '@rezio/components'
import { palette } from '@rezio/res/theme'
import { TFunction } from 'i18next'
import _ from 'lodash'
import { inject } from 'mobx-react'
import React, { Component } from 'react'
import { Platform, View, type StyleProp, type ViewStyle } from 'react-native'
import RNPickerSelect from 'react-native-picker-select-legacy'

interface ChangeType {
  (value: string, index: number): void
  (currentValue: [value: string, index: number]): void
}

interface Props extends StyleProps {
  allowDonePress?: boolean
  isWhiteIcon?: boolean
  onValueChange?: ChangeType
  onChange?: ChangeType
  t?: TFunction
  disabled?: boolean
  placeholder?: { label: string; value: string } | object
  items?: { label: string; value: string }[]
  value: string
}

interface StyleProps {
  webPickerStyles?: StyleProp<ViewStyle>
  pickerStyles?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ViewStyle>
  styleIOS?: StyleProp<ViewStyle>
  styleAndroid?: StyleProp<ViewStyle>
  styleWeb?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  placeholderStyle?: StyleProp<ViewStyle>
}

interface State {
  change: [value: string, index: number]
}

export const ThemedPicker = inject(
  'theme',
  't'
)(
  class ThemedPicker extends Component<Props, State> {
    baseContainerStyle = {
      minHeight: 40
    }

    baseStyles = {
      base: {
        paddingVertical: 12,
        paddingHorizontal: 10,
        color: palette.black,
        backgroundColor: this.props.disabled ? palette.disable : palette.white,
        borderRadius: 5,
        borderWidth: this.props.disabled ? 0 : 1,
        borderColor: palette.gray
      },
      IOS: {},
      Android: {},
      Web: {}
    }

    pickerStyles =
      Platform.OS === 'web'
        ? {
            headlessAndroidContainer: {},
            headlessAndroidPicker: {
              color: 'black'
            },
            inputIOS: Object.assign({}, this.baseStyles.base, this.baseStyles.Web),
            inputAndroid: Object.assign(
              {},
              this.baseStyles.base,
              this.baseStyles.Web,
              this.props.webPickerStyles
            ),
            iconContainer: Object.assign(
              { top: 'calc(50% + -12px)', right: 5 },
              this.props.iconStyle || {}
            )
          }
        : {
            inputIOS: Object.assign(
              {},
              this.baseStyles.base,
              this.baseStyles.IOS,
              this.props.styleIOS
            ),
            inputAndroid: Object.assign(
              {},
              this.baseStyles.base,
              this.baseStyles.Android,
              this.props.styleAndroid
            ),
            iconContainer: Object.assign(
              {},
              { height: '100%', justifyContent: 'center' },
              this.props.iconStyle
            )
          }

    items = []

    handleValueChange = (value: string, index: number) => {
      let change: [string, number] = [value, index]

      if (!_.isEqual(this.props.placeholder, {})) {
        if (index === 0) {
          change = [undefined, 0]
        } else {
          change = [_.get(this.props, ['items', index - 1, 'value']), index]
        }
      } else {
        change = [_.get(this.props, ['items', index, 'value']), index]
      }

      const [nextValue, nextIndex] = change

      if (Platform.OS === 'ios' && this.props?.allowDonePress) {
        this.setState({ change })
        this.props?.onValueChange?.(nextValue, nextIndex)
      } else {
        this.props?.onChange?.(nextValue, nextIndex)
        this.props?.onValueChange?.(nextValue, nextIndex)
      }
    }

    /** this should be fixed, now the onChagne type signature is not align. see {@link ChangeType} */
    handleOnDonePress = () => {
      this.props?.onChange?.(this.state.change)
    }

    getFormatedValue = (input) => {
      return _.isNumber(input) ? `parsed_number_value_${input}` : input
    }

    render() {
      const {
        t,
        placeholder = { label: this.props.t('FORM.PICKER_DEFAULT_PLACEHOLDER'), value: undefined },
        items = [],
        value,
        disabled,
        onValueChange,
        style,
        containerStyle,
        ...rest
      } = this.props
      const isEmptyPicker = _.isEqual(placeholder, {}) && _.isEmpty(items)
      const outOfItemsRange =
        !_.isEmpty(value) &&
        !_.isNil(value) &&
        _.isEmpty(_.find(items, (item) => item.value === value))
      const formatedValue = this.getFormatedValue(value)

      this.items = items.map(({ label, value, ...item }) => {
        return { value: this.getFormatedValue(value), label: `${label}`, ...item }
      })

      return (
        <View
          style={[this.baseContainerStyle, containerStyle, this.props.styleWeb]}
          accessibilityRole='menu'
          accessibilityValue={{ text: formatedValue }}
        >
          <RNPickerSelect
            onValueChange={this.handleValueChange}
            onDonePress={this.props.allowDonePress ? this.handleOnDonePress : null}
            style={{
              placeholder: {
                color:
                  !isEmptyPicker && outOfItemsRange
                    ? palette.black
                    : disabled
                    ? palette.negative
                    : palette.gray,
                ...(this.props.placeholderStyle as any)
              },
              ...(this.props.pickerStyles || (this.pickerStyles as any))
            }}
            placeholder={
              isEmptyPicker
                ? { label: t('COMMON.NONE'), value: undefined }
                : outOfItemsRange
                ? {
                    label: `${value} ${t('FORM.PICKER_DEFAULT_INVALID_VALUE_PLACEHOLDER')}`,
                    value: undefined
                  }
                : placeholder
            }
            items={this.items}
            value={formatedValue}
            disabled={disabled}
            useNativeAndroidPickerStyle={false}
            Icon={
              (() => (
                <MaterialIcons
                  name='arrow-drop-down'
                  size={24}
                  color={
                    disabled
                      ? palette.negative
                      : this.props?.isWhiteIcon
                      ? palette.white
                      : palette.black
                  }
                />
              )) as any
            }
            {...rest}
          />
        </View>
      )
    }
  }
)
