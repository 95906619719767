import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import { ThemedButton } from '@rezio/components'
import { manipulator, palette } from '@rezio/res/theme'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'

interface MediaLibraryButtonPropsType {
  disabled: boolean
  isEmptyMediaImages: boolean
  onDeleteImage: (nextIsDeleting?: boolean) => void
  onUploadImage: () => void
}

export const MediaLibraryActionButton = ({
  disabled = false,
  isEmptyMediaImages = false,
  onDeleteImage = () => {},
  onUploadImage = () => {}
}: MediaLibraryButtonPropsType) => {
  const { t } = useTranslation()
  const isDisabled = disabled || isEmptyMediaImages
  const handleDeleteImage = useCallback(() => onDeleteImage?.(), [])
  const handleUploadImage = useCallback(() => onUploadImage?.(), [])

  return (
    <View style={manipulator.container('row', 'space-between', 'center')}>
      <ThemedButton
        style={{
          minWidth: 80,
          height: 40,
          backgroundColor: isDisabled ? palette.disable : 'transparent',
          alignItems: 'center',
          marginRight: 20,
          borderWidth: 1,
          borderColor: isDisabled ? palette.border : palette.primary
        }}
        onPress={handleDeleteImage}
        disabled={isDisabled}
      >
        <MaterialIcons
          name='delete'
          color={isDisabled ? palette.darkGray : palette.primary}
          size={16}
        />
        <Text
          style={{
            fontSize: 14,
            color: isDisabled ? palette.darkGray : palette.primary,
            paddingLeft: 4
          }}
        >
          {t('COMMON.IMAGE_DELETE_BUTTON')}
        </Text>
      </ThemedButton>
      <ThemedButton
        style={{
          minWidth: 80,
          height: 40,
          backgroundColor: disabled ? palette.disable : palette.primary,
          alignItems: 'center'
        }}
        onPress={handleUploadImage}
        disabled={disabled}
      >
        <MaterialIcons
          name='file-upload'
          color={disabled ? palette.darkGray : palette.white}
          size={16}
        />
        <Text
          style={{
            fontSize: 14,
            color: disabled ? palette.darkGray : palette.white,
            paddingLeft: 4
          }}
        >
          {t('COMMON.IMAGE_BATCH_UPLOAD_BUTTON')}
        </Text>
      </ThemedButton>
    </View>
  )
}
