import { ClassNames } from '@emotion/react'
import React, { useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import MdEditor from 'react-markdown-editor-lite'
import { View } from 'react-native'
import { getFontFamilyByLanguage } from '@rezio/res/theme'

const fontSizes = [12, 14, 16, 18, 22, 26, 30, 40, 52, 68, 80]

export default function ({
  style,
  value,
  onChange,
  disabled,
  placeholder,
  customStyle = '',
  ...props
}) {
  const markdownTextareaRef = useRef(null)
  const { i18n } = useTranslation()
  useEffect(() => {
    !!placeholder &&
      markdownTextareaRef.current?.nodeMdText?.setAttribute('placeholder', placeholder)
  }, [markdownTextareaRef, placeholder])

  const changeHandler = useCallback((e) => {
    onChange && onChange(e.text)
  }, [])

  return (
    <View style={style}>
      <ClassNames>
        {({ css }) => {
          const targetValue = value || ''
          const className = css`
            font-family: ${getFontFamilyByLanguage(i18n.language)};

            h1,
            h2,
            h3,
            h4,
            h4,
            p,
            div,
            span,
            li {
              line-height: 1.5;
            }

            h1 {
              font-size: ${fontSizes[5]}px;
            }

            h2 {
              font-size: ${fontSizes[4]}px;
            }

            h3 {
              font-size: ${fontSizes[3]}px;
            }

            h4 {
              font-size: ${fontSizes[2]}px;
            }

            p,
            div,
            span,
            li {
              white-space: break-spaces;
              font-size: ${fontSizes[1]}px;
            }

            li {
              p {
                display: initial;
              }
              input {
                margin-right: 5px;
              }
            }

            ul,
            ol {
              list-style-position: inside;
              padding-inline-start: 0.3rem;
            }
            ol {
              list-style-type: decimal;
            }
            ol ol {
              list-style-type: lower-roman;
            }
            ol ol ol {
              list-style-type: lower-alpha;
            }

            table {
              margin: 20px 0;
              background-color: #dcdcdc;
              border-spacing: 1px;
            }

            th,
            td {
              background-color: ${disabled ? '#f4f4f4' : 'white'};
              padding: 0.75rem;
            }

            th {
              word-break: keep-all;
            }

            pre {
              font-size: ${fontSizes[1]}px;
              background: ${disabled ? '#f4f4f4' : '#f8f8f8'};
              padding: 10px;
              margin-bottom: 20px;
            }

            a {
              color: #000;
              text-decoration: underline;
            }
            ${customStyle}
          `
          return disabled ? (
            <Markdown linkTarget='_blank' className={className}>
              {targetValue}
            </Markdown>
          ) : (
            <MdEditor
              ref={markdownTextareaRef}
              value={targetValue}
              onChange={changeHandler}
              fullScreen={false}
              renderHTML={(text) => ''}
              config={{
                htmlClass: className,
                markdownClass: className
              }}
              {...props}
            />
          )
        }}
      </ClassNames>
    </View>
  )
}
