import { useLayout } from '@rezio/core/layout'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { View, StyleSheet, Text, TextInputProps } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { palette } from '@rezio/res/theme'
import { ThemedButton } from './ThemedButton'
import { ThemedTextInput } from './ThemedTextInput'

const numberSelectorStyles = StyleSheet.create({
  button: {
    width: 20,
    height: 20,
    borderRadius: 12.5,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: { width: 120 },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 240,
    alignItems: 'center'
  }
})

export interface ThemedNumberSelectorProps {
  value?: number
  onChange?: (value: number) => void
  disabled?: boolean
  min?: number
  max?: number
  postfixLabel?: string
  style?: any
  onBlur?: TextInputProps['onBlur']
}

export function ThemedNumberSelector(props: ThemedNumberSelectorProps) {
  const { isMobile } = useLayout()
  const {
    onChange,
    onBlur,
    disabled = false,
    min = 0,
    style,
    max = Number.MAX_SAFE_INTEGER,
    postfixLabel
  } = props
  const [value, setValue] = useState(props.value || 0)

  const handleChange = useCallback(
    (e) => {
      const next = parseInt(e, 10)
      if (_.isFinite(next)) {
        setValue(Math.min(max, Math.max(next, min)))
      }
    },
    [min, max]
  )

  const add = useCallback(() => {
    if (disabled) {
      return
    }
    setValue(Math.min(max, Math.max(value + 1, min)))
  }, [value, disabled, min, max])

  const minus = useCallback(() => {
    if (disabled) {
      return
    }
    setValue(Math.min(max, Math.max(value - 1, min)))
  }, [value, disabled, min, max])

  useEffect(() => {
    onChange?.(value)
  }, [value, onChange])

  return (
    <View style={[numberSelectorStyles.container, isMobile ? { width: 100 } : {}, style]}>
      <ThemedButton onPress={minus} disabled={disabled} style={[numberSelectorStyles.button]}>
        <MaterialIcons color={palette.white} name='remove' size={isMobile ? 13 : 18} />
      </ThemedButton>
      <ThemedTextInput
        disabled={disabled}
        inputStyle={{ textAlign: 'center' }}
        style={[numberSelectorStyles.text, isMobile ? { width: 45, padding: 5 } : {}]}
        value={`${value}`}
        onChangeText={handleChange}
        onBlur={onBlur}
      />
      <ThemedButton onPress={add} disabled={disabled} style={[numberSelectorStyles.button]}>
        <MaterialIcons color={palette.white} name='add' size={isMobile ? 13 : 18} />
      </ThemedButton>
      {!_.isEmpty(postfixLabel) && (
        <Text
          accessibilityRole='text'
          style={{ fontSize: isMobile ? 13 : 14, paddingLeft: isMobile ? 8 : 0 }}
        >
          {postfixLabel}
        </Text>
      )}
    </View>
  )
}
