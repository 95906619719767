/** @type {import('tailwindcss').Config} */
const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    './App.tsx',
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './unimodules/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    screens: {
      xs: '480px',
      sm: '768px',
      md: '832px',
      lg: '1200px'
    },
    colors: {
      transparent: 'transparent',
      primary: {
        DEFAULT: '#005ABE',
        100: '#E6F5FF',
        200: '#A0D4FA',
        300: '#72B6ED',
        400: '#4897E0',
        500: '#0977F5',
        600: '#005ABE',
        700: '#0043A1',
        800: '#002F7A'
      },
      white: '#FFFFFF',
      black: '#000000',
      gray: {
        100: '#F9FAFA',
        200: '#F4F4F4',
        300: '#F0F0F0',
        400: '#DCDCDC',
        600: '#7B7B7B',
        700: '#4F4F4F'
      },
      popUpBG: 'rgba(0, 0, 0, .5)',
      green: '#32BE64',
      lightGreen: '#E6FAF0',
      red: '#C80A28',
      lightRed: '#FFEBF0',
      orange: '#E95200',
      lightOrange: '#F5E6DC',
      yellow: '#FFA800',
      lightYellow: '#FAF0E1',
      kkday: '#26BEC9',
      aj: '#1087DD',
      viator: '#3A759B',
      jalan: '#EB6522',
      gyg: '#E34E1E',
      systemGeneral1: '#855EF1',
      systemGeneral2: '#FF7878',
      systemGeneral3: '#F6A303',
      systemGeneral4: '#D1629C',
      systemGeneral5: '#990000',
      systemGeneral6: '#A4C2F4'
    },
    spacing: {
      0: '0px',
      1: '2px',
      2: '4px',
      3: '8px',
      4: '10px',
      5: '16px',
      6: '20px',
      7: '24px',
      8: '32px',
      9: '40px',
      10: '80px'
    },
    lineHeight: {
      0: '0px',
      1: '2px',
      2: '4px',
      3: '8px',
      4: '10px',
      5: '16px',
      6: '20px',
      7: '24px',
      8: '32px',
      9: '40px',
      10: '80px'
    },
    borderRadius: {
      none: '0',
      1: '4px',
      2: '8px',
      3: '10px',
      full: '9999px'
    },
    borderWidth: {
      0: '0px',
      1: '1px',
      2: '2px',
      3: '4px',
      4: '6px',
      5: '8px'
    },
    extend: {
      boxShadow: {
        DEFAULT: '0px 0px 10px rgba(0, 0, 0, 0.2)'
      }
    }
  },
  corePlugins: {
    preflight: false
  },
  plugins: [
    plugin(function ({ addUtilities }) {
      const fontSize = {
        '.text-h1': {
          fontSize: '24px',
          lineHeight: '28px',
          fontWeight: '700'
        },
        '.text-h2': {
          fontSize: '18px',
          lineHeight: '21px',
          fontWeight: '700'
        },
        '.text-h3': {
          fontSize: '16px',
          lineHeight: '19px',
          fontWeight: '500'
        },
        '.text-h4': {
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: '500'
        },
        '.text-h5': {
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: '400'
        },
        '.text-body': {
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        },
        '.text-button': {
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        },
        '.text-button-s': {
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        },
        '.text-links': {
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        },
        '.text-tip': {
          fontSize: '12px',
          lineHeight: '12px',
          fontWeight: '400'
        },
        '@media (min-width: 832px)': {
          '.text-h1': {
            fontSize: '32px',
            lineHeight: '37.5px',
            fontWeight: '700'
          },
          '.text-h2': {
            fontSize: '22px',
            lineHeight: '37.5px',
            fontWeight: '700'
          },
          '.text-h3': {
            fontSize: '18px',
            lineHeight: '21px',
            fontWeight: '500'
          },
          '.text-h4': {
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: '500'
          },
          '.text-h5': {
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: '500'
          },
          '.text-body': {
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: '400'
          },
          '.text-button': {
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: '400'
          },
          '.text-button-s': {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '400'
          },
          '.text-links': {
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: '400'
          },
          '.text-tip': {
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: '400'
          }
        }
      }
      addUtilities(fontSize)
    })
  ]
}
