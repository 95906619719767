import EventEmitter from 'events'
import type { ApolloClient, ObservableQuery } from '@apollo/client'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Dropdown, HoverableOpacity, MaterialIcons, ThemedButton } from '@rezio/components'
import { useLayout, useStores } from '@rezio/core/hooks'
import { useRouter } from '@rezio/core/routing/router'
import { palette } from '@rezio/res/theme'
import { formatName } from '@rezio/utils/format'
import * as Notifications from 'expo-notifications'
import gql from 'graphql-tag'
import { produce } from 'immer'
import { autorun } from 'mobx'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import type { NativeScrollEvent, NativeSyntheticEvent } from 'react-native'
import { ActivityIndicator, Platform, StyleSheet, Text, View } from 'react-native'
import { handleProductSalesOptionExpiration } from '@rezio/core/notification/handlers'
import { __, has, indexBy, pipe, prop, reject, toString } from 'ramda'
import { useTranslation } from 'react-i18next'
import { auth } from '../apollo'
import type {
  ConversationMessage,
  ConversationUser,
  ConversationUserWithParticipant
} from '../stores/conversation'

export enum NotificationReadStatus {
  UNREAD,
  READ
}

// type NotificationTypesOfEntity<ET extends string & EntityType = EntityType, NT extends NotificationType = NotificationType> = NT extends `${ET}${string}` ? NT : never
type EntityType = 'ORDER' | 'PRODUCT' | 'CONVERSATION' | 'BROADCAST' | 'SCM' | 'STORE' | 'CHANNEL'

export type NotificationPayload<T extends string & NotificationType = NotificationType> =
  T extends 'STORE_WARNING'
    ? {
        category: 'ABNORMAL_ORDER'
        done: boolean
      }
    : T extends 'ORDER_WARNING'
    ? {
        category: 'OVERSALE' | 'VOUCHER_FAIL' | 'CANCEL'
        done: boolean
      }
    : T extends 'ORDER_MODIFY'
    ? {
        category: 'CONTENT' | 'SESSION' | 'QUANTITY' | 'AMOUNT'
      }
    : T extends 'CHANNEL_PRODUCT_MODIFY'
    ? {
        category: 'PUBLISH' | 'ACTIVE' | 'INACTIVE' | 'LOCK' | 'UNLOCK'
        productId: string
      }
    : T extends 'CHANNEL_PRODUCT_LOCKED'
    ? {
        category?: 'EMPTY'
        productId: string
      }
    : T extends 'PRODUCT_SALESOPTION_EXPIRATION'
    ? {
        expireInDays: number
        salesOptionCode: string
        salesOptionId: string
        salesOptionName: string
        storeId: string
      }
    : T extends 'CONVERSATION_MESSAGE'
    ? ConversationMessagePayload
    : unknown

interface ConversationMessagePayload {
  eventType: 'onMessageAdded'
  mainConversationSid: string
  mainMessageSid: string
  users: Record<string, ConversationUserWithParticipant>
  message: ConversationMessage
}

export type NotificationType =
  | 'ORDER_STATUS'
  | 'ORDER_WARNING'
  | 'ORDER_MODIFY'
  | 'SCM_NEW_MESSAGE'
  | 'STORE_WARNING'
  | 'CHANNEL_STATEMENT'
  | 'CHANNEL_PRODUCT_MODIFY'
  | 'CHANNEL_PRODUCT_LOCKED'
  | 'CHANNEL_PERMISSION'
  | 'CONVERSATION_MESSAGE'
  | 'PRODUCT_SALESOPTION_EXPIRATION'

interface BaseSnapshot {
  storeId: string
  storeName: string
}

interface OrderSnapshot extends BaseSnapshot {
  orderNo: string
  orderStatus: string
  productName: string
  salesOptionName: string
  sourceOrderNo?: string
  platform: string
}

interface ChannelSnapshot extends BaseSnapshot {
  channel: string
}

interface ProductSnapshot extends BaseSnapshot {
  productCode: string
  productId: string
  productName: string
}

interface Entity<T extends EntityType> {
  snapshot: T extends 'ORDER'
    ? OrderSnapshot
    : T extends 'CHANNEL'
    ? ChannelSnapshot
    : T extends 'PRODUCT'
    ? ProductSnapshot
    : Partial<BaseSnapshot> & { [key: string]: any }
  entityId: string
  type: T
}

type EntityTypeOf<
  NT extends string & NotificationType = NotificationType,
  C extends string = string
> = NT extends `${infer Q}_${C}` ? Q : never

interface TNotification<T extends string & NotificationType> {
  type: T
  entity: Entity<EntityTypeOf<T>>
  actors?: {
    actor: {
      displayName?: string
      externalId: string
    }
  }[]
  payload?: NotificationPayload<T>
  createdAt: string
}

export interface UserNotification<T extends string & NotificationType = NotificationType> {
  notification: T extends NotificationType ? TNotification<T> : never
  createdAt: string
  status: NotificationReadStatus
  id: number
}

const NOTIFICATION_READ_MUTATION = gql`
  mutation ReadNotification($id: Int!) {
    updateUserNotificationById(pk_columns: { id: $id }, _set: { status: "1" }) {
      status
    }
  }
`

export function useNotificationHandler(): (userNotification: UserNotification) => Promise<void> {
  const { t } = useTranslation()
  const apollo = useApolloClient()
  const router = useRouter()
  const { store, core } = useStores()
  const handler = React.useCallback(
    async (userNotification: UserNotification) => {
      if (!userNotification?.notification?.type) {
        return
      }

      const { id, status, notification } = userNotification
      const read = status !== 0
      let target

      switch (notification.type) {
        case 'ORDER_MODIFY':
        case 'ORDER_WARNING':
        case 'ORDER_STATUS':
          target = {
            pathname: '/order/detail',
            query: { orderNo: notification.entity.snapshot.orderNo }
          }
          break
        case 'SCM_NEW_MESSAGE':
          target = { pathname: '/scm/home/index' }
          break
        case 'STORE_WARNING':
          target = {
            pathname: notification.payload.category === 'ABNORMAL_ORDER' ? '/abnormalOrder' : '/'
          }
          break
        case 'CHANNEL_STATEMENT':
          target = {
            pathname: `/${
              notification.entity.snapshot.channel.toUpperCase() === 'KKDAY' ? 'scm' : 'scmmkp'
            }/report/statement`
          }
          break
        case 'CHANNEL_PRODUCT_MODIFY':
        case 'CHANNEL_PRODUCT_LOCKED':
          if (Platform.OS !== 'web') {
            break
          }
          target = {
            pathname: `/channel/${notification.entity.snapshot.channel.toLowerCase()}/product/${
              notification.payload.productId
            }`
          }
          break
        case 'CHANNEL_PERMISSION':
          if (Platform.OS !== 'web') {
            break
          }
          target = {
            pathname: `/channel/${notification.entity.snapshot.channel.toLowerCase()}/product`
          }
          break
        case 'CONVERSATION_MESSAGE':
          target = {
            pathname: '/conversation',
            query: { id: notification.payload.mainConversationSid }
          }
          break
        case 'PRODUCT_SALESOPTION_EXPIRATION':
          target = async () => {
            try {
              return await handleProductSalesOptionExpiration(notification)
            } catch (e) {
              if (e.message === 'permission_denied') {
                router.push('/')
                core.setRuntimeError(t('ERROR_MESSAGE.TYPE_PERMISSIONDENY'))
              } else {
                throw new Error(e)
              }
            }
          }
          break
      }

      if (!read) {
        apollo
          .mutate({ mutation: NOTIFICATION_READ_MUTATION, variables: { id } })
          .catch(console.error)
      }

      if (!target) {
        return
      }

      const storeId = notification.entity?.snapshot?.storeId
      const currentStoreId = store.core.session.store.storeUuid

      if (storeId && storeId !== currentStoreId) {
        await store.core.switchStore(storeId, target)
        return
      }

      const resolvedTarget = typeof target === 'function' ? await target() : target
      router.push(resolvedTarget)
    },
    [store, apollo]
  )

  return handler
}

async function getAppToken() {
  let pushToken
  if (Platform.OS !== 'web') {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }

    if (finalStatus === 'granted') {
      const { data } = await Notifications.getExpoPushTokenAsync({
        // experienceId: '@rezioio/rezio-admin',
        // applicationId: 'io.rezio.admin'
      })
      pushToken = data
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'Default',
        sound: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250]
      }).catch(console.error)
    }
  }
  return pushToken
}

export async function authNotify(uuid?, token?) {
  const appToken = await getAppToken()
  try {
    const result = await auth(uuid, token, appToken)
    return result
  } catch (e) {
    // logout
    if (!uuid && !token) {
      console.log(e)
      return undefined
    }
    throw e
  }
}

const NOTIFICATION_READ_ALL_MUTATION = gql`
  mutation MarkAllAsRead {
    updateUserNotification(where: { status: { _eq: "0" } }, _set: { status: "1" }) {
      affected_rows
    }
  }
`

const hasCategory = (payload): payload is { category?: string } =>
  typeof payload?.category === 'string'
const hasDone = (payload): payload is { done?: boolean } => typeof payload?.done === 'boolean'
const getSource = (snapshot) =>
  typeof snapshot.platform === 'string'
    ? snapshot.platform
    : typeof snapshot.channel === 'string'
    ? snapshot.channel
    : null

const NotificationRow = function NotificationRow({
  notification,
  status,
  id,
  onClose,
  createdAt
}: UserNotification & { onClose: () => void }) {
  const { t, core } = useStores()
  const key = `${notification.type}${
    hasCategory(notification.payload) ? `_${notification.payload.category}` : ''
  }`
  const isResolved = hasDone(notification.payload) ? notification.payload.done : false
  const actors =
    notification.actors?.map(({ actor }) => actor.displayName || actor.externalId) || []
  const read = status !== 0
  const subtitle = notification.entity.snapshot.storeName
  const source = getSource(notification.entity.snapshot)
  const platform = core.i18n.exists(`COMMON.SOURCE_${source?.toUpperCase()}`)
    ? t(`COMMON.SOURCE_${source?.toUpperCase()}`)
    : source
  const handleNotification = useNotificationHandler()
  const handleClick = React.useCallback(() => {
    onClose()
    handleNotification({ notification, status, id, createdAt }).catch(console.error)
  }, [notification, status, id])

  const content = useMemo<Record<string, any>>(() => {
    let result: Record<string, any> = {
      payload: notification.payload,
      entity: {
        ...notification.entity.snapshot,
        platform,
        status:
          notification.entity.type === 'ORDER'
            ? t('ORDER.STATUS', { context: notification.entity.snapshot.orderStatus })
            : null
      },
      platform
    }
    switch (notification.type) {
      case 'CONVERSATION_MESSAGE': {
        const lastMessage = notification.payload.message
          ? notification.payload.message?.messageType === 'text'
            ? notification.payload.message?.body
            : notification.payload.message?.messageType === 'image'
            ? t('CONVERSATION.MESSAGE_IMAGE')
            : t('CONVERSATION.MESSAGE_FILE')
          : t('CONVERSATION.EMPTY_MESSAGE')
        const me = Object.values(
          notification.payload.users as Record<string, ConversationUser>
        ).find((user) => user.role === 'supplier')
        const targets = Object.values(
          notification.payload.users as Record<string, ConversationUser>
        ).filter((user) => user.mainUserSid !== me.mainUserSid)
        const title = targets
          .map(
            (target) =>
              `${
                target.attributes.firstName || target.attributes.lastName
                  ? formatName(target.attributes.firstName, target.attributes.lastName)
                  : t('CONVERSATION.PARTICIPANT_GUEST')
              } (${
                target.attributes.email ||
                (target.attributes.firstName || target.attributes.lastName ? '-' : target.identity)
              })`
          )
          .join(',')
        result = {
          ...result,
          lastMessage,
          title,
          platform: t(`COMMON.SOURCE_${'KKDAYMKP'.toUpperCase()}`)
        }
      }
    }
    return result
  }, [notification.type, createdAt, platform])

  const entityDetail = core.i18n.exists('NOTIFICATION.ENTITY_DETAIL', {
    context: notification.entity.type,
    entity: notification.entity.snapshot
  })
    ? t('NOTIFICATION.ENTITY_DETAIL', {
        context: notification.entity.type,
        ...content
      })
    : ''
  return (
    <HoverableOpacity
      onPress={handleClick}
      style={{
        borderBottomColor: palette.lightGray,
        borderBottomWidth: StyleSheet.hairlineWidth,
        paddingTop: 5,
        paddingBottom: 15,
        marginVertical: 5
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 10
        }}
      >
        <Text
          style={{
            paddingVertical: 3,
            paddingHorizontal: 10,
            borderRadius: 15,
            backgroundColor: palette.neutral,
            fontSize: 12,
            color: 'white'
          }}
        >
          {t('NOTIFICATION.ENTITY_TYPE', { context: notification.entity.type })}
        </Text>
        <Text
          numberOfLines={1}
          style={{ flex: 1, paddingHorizontal: 5, color: palette.lightBrand, fontSize: 13 }}
        >
          {subtitle}
        </Text>
        <Text>{moment(createdAt).format('YYYY-MM-DD')}</Text>
      </View>
      {actors.length > 0 ? <Text>{actors.join(', ')}</Text> : null}
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: read ? 'normal' : 'bold' }}>
            {t(
              [
                ...(isResolved ? [`NOTIFICATION.CONTENT_${key}_RESOLVED`] : []),
                `NOTIFICATION.CONTENT_${key}`,
                `NOTIFICATION.CONTENT_${notification.type}`,
                'NOTIFICATION.CONTENT'
              ],
              { ...content }
            )}
          </Text>
          <Text numberOfLines={1} style={{ fontWeight: read ? 'normal' : 'bold' }}>
            {entityDetail}
          </Text>
        </View>
        {!read && (
          <View
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: palette.orderStatusConfirmed
            }}
          />
        )}
      </View>
    </HoverableOpacity>
  )
}

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription unreadNotifications {
    currentUser {
      unreadNotificationCount
      updatedAt
    }
  }
`

const NOTIFICATION_QUERY = gql`
  query notifications($limit: Int = 20, $offset: Int = 0) {
    currentUser {
      scmNotifications: notifications(where: { notification: { type: { _eq: SCM_NEW_MESSAGE } } }) {
        createdAt
        notification {
          createdAt
          payload
          type
          entity {
            id
            type
            snapshot
          }
        }
        status
        id
      }
      notifications(order_by: { createdAt: desc }, limit: $limit, offset: $offset) {
        createdAt
        notification {
          createdAt
          payload
          type
          entity {
            id
            type
            snapshot
          }
        }
        status
        id
      }
      notificationCount
      unreadNotificationCount
      id
    }
  }
`

const NotificationByConversationIdQuery = gql`
  query NotificationByConversationIdQuery($id: String!) {
    currentUser {
      notifications(where: { notification: { entity: { entityId: { _eq: $id } } } }) {
        id
        status
      }
    }
  }
`

export async function markAsRead(apolloClient: ApolloClient<object>, id: string) {
  const result = await apolloClient.query({
    query: NotificationByConversationIdQuery,
    variables: { id }
  })
  if (result.data?.currentUser?.[0]?.notifications?.[0]?.status === 0) {
    await apolloClient.mutate({
      mutation: NOTIFICATION_READ_MUTATION,
      variables: { id: result.data?.currentUser?.[0]?.notifications?.[0]?.id }
    })
  }
}

interface NotificationData {
  user: {
    notifications: UserNotification[]
    scmNotifications: UserNotification[]
    notificationCount: number
    unreadNotificationCount: number
  }
  refetch: ObservableQuery['refetch']
  /**
   * 載入更多通知，直到沒有為止
   */
  handleLoadMoreNotification: () => Promise<void>
  loading: boolean
  events: EventEmitter
}
interface NotificationQueryData {
  currentUser: [NotificationData['user']]
}
interface NotificationQueryVariable {
  limit?: number
  offset?: number
}

export const NotificationContext = React.createContext<NotificationData>(null)

const notificationLimit = 20
export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [currentToken, setToken] = useState<string | null>(null)
  const apollo = useApolloClient()
  const eventsRef = useRef(new EventEmitter())
  const isUpdatingNotificationRef = useRef(false)
  const previousNotificationInfoRef = useRef<NotificationData['user'] | null>(null)
  const result = useQuery<NotificationQueryData | null, NotificationQueryVariable>(
    NOTIFICATION_QUERY,
    {
      skip: !currentToken,
      variables: { limit: notificationLimit },
      refetchWritePolicy: 'overwrite',
      onError: console.error
    }
  )

  const { data, loading, refetch, updateQuery, fetchMore } = result
  const { store } = useStores()

  useEffect(() => {
    if (data?.currentUser?.[0]?.notifications) {
      eventsRef.current.emit('notifications', data.currentUser[0].notifications)
    }
  }, [data?.currentUser?.[0]?.notifications])

  useEffect(() => {
    autorun(() => {
      if (store.core?.session?.notifyToken) {
        setToken(store.core.session.notifyToken)
      } else {
        setToken(null)
      }
    })
  }, [])

  const updateNotificationQueryData = useCallback(
    (
      nextNotificationInfo: NotificationData['user'],
      previousNotificationInfo: NotificationData['user']
    ) => {
      const { notifications: previousNotifications } = previousNotificationInfo
      const { notifications, ...restNotificationInfo } = nextNotificationInfo

      updateQuery(() => {
        previousNotificationInfoRef.current = {
          ...restNotificationInfo,
          notifications: [...previousNotifications, ...notifications]
        }

        return {
          currentUser: [previousNotificationInfoRef.current]
        }
      })
    },
    []
  )

  const handleLoadMoreNotification = useCallback(async () => {
    try {
      if (isUpdatingNotificationRef.current) return
      if (!data?.currentUser?.[0]) return

      const previousNotificationInfo = data.currentUser[0]
      const { notificationCount: previousNotificationCount, notifications: previousNotifications } =
        previousNotificationInfo

      if (previousNotificationCount <= previousNotifications.length) return

      isUpdatingNotificationRef.current = true
      await fetchMore({
        variables: {
          offset: previousNotifications.length
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      isUpdatingNotificationRef.current = false
    }
  }, [data?.currentUser?.[0]])

  const res = useMemo<NotificationData>(() => {
    const user = currentToken ? (data?.currentUser?.[0] as NotificationData['user']) : null
    return {
      user,
      loading,
      refetch,
      handleLoadMoreNotification,
      events: eventsRef.current
    }
  }, [handleLoadMoreNotification, currentToken, data?.currentUser?.[0], loading])

  useEffect(() => {
    if (data?.currentUser) {
      let firstTime = true
      const subscription = apollo
        .subscribe({
          query: NOTIFICATION_SUBSCRIPTION
        })
        .subscribe(() => {
          if (firstTime) {
            firstTime = false
            previousNotificationInfoRef.current = data.currentUser[0] ?? null
            return
          }

          async function getNewNotification() {
            try {
              isUpdatingNotificationRef.current = true

              // 取前 20 筆通知，並把舊資料移除，新資料放到最前面 (通知是同一則去做狀態上的更新，不會是每次都重建(除非是新的))
              // 大於 20 筆的部分，先當作 edge case 處理 (請重整頁面)
              const { data: refetchData } = await refetch({ limit: notificationLimit, offset: 0 })

              const previousNotificationInfo = previousNotificationInfoRef.current
              if (!previousNotificationInfo || !refetchData) return

              const newNotificationInfo = refetchData.currentUser[0]

              const newNotificationIds = indexBy<UserNotification, string>(
                pipe(prop('id'), toString)
              )(newNotificationInfo.notifications)

              const updatedPreviousNotifications = reject(
                pipe(prop('id'), toString, has(__, newNotificationIds))
              )(previousNotificationInfo.notifications)

              const updatedPreviousNotificationInfo = {
                ...previousNotificationInfo,
                notifications: updatedPreviousNotifications
              }
              // 把最新的放到最前面
              updateNotificationQueryData(updatedPreviousNotificationInfo, newNotificationInfo)
            } catch (error) {
              console.error(error)
            } finally {
              isUpdatingNotificationRef.current = false
            }
          }

          getNewNotification()
        })
      return () => subscription.unsubscribe()
    }
  }, [updateNotificationQueryData, !!data?.currentUser])

  return <NotificationContext.Provider value={res}>{children}</NotificationContext.Provider>
}

export function NotificationCenter() {
  const { isMobile } = useLayout()
  const { t } = useStores()

  const [markAllRead] = useMutation(NOTIFICATION_READ_ALL_MUTATION, {
    update(cache) {
      const existingNotifications: {
        currentUser: { notifications: UserNotification[]; unreadNotificationCount: number }[]
      } = cache.readQuery({ query: NOTIFICATION_QUERY })
      cache.writeQuery({
        query: NOTIFICATION_QUERY,
        data: produce(existingNotifications, (draft) => {
          for (const notification of draft.currentUser[0].notifications) {
            notification.status = NotificationReadStatus.READ
          }
          draft.currentUser[0].unreadNotificationCount = 0
        })
      })
    }
  })

  const { user, loading, handleLoadMoreNotification } = useContext(NotificationContext)

  const dropdownRef = useRef(null)
  const handleClose = useCallback(() => {
    if (dropdownRef.current?.close) {
      dropdownRef.current.close()
    }
  }, [])

  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { layoutMeasurement, contentSize, contentOffset } = event.nativeEvent
      if (contentOffset.y + layoutMeasurement.height >= contentSize.height - 20) {
        handleLoadMoreNotification()
      }
    },
    [handleLoadMoreNotification]
  )

  const notifications = user?.notifications && [
    ...user?.notifications?.filter(
      (un) =>
        un.status === 1 || !(user?.scmNotifications ?? []).map((scmUn) => scmUn.id).includes(un.id)
    ),
    ...(user?.scmNotifications ?? []).filter(
      (scmUn) => scmUn.status === 1 && !user?.notifications?.map((un) => un.id).includes(scmUn.id)
    )
  ]
  const scmUnread = user?.scmNotifications?.filter((scmUn) => scmUn.status === 0) ?? []
  return (
    <Dropdown
      ref={dropdownRef}
      layoutStyle={{ width: 'auto' }}
      pickerStyle={{ width: 320 }}
      menuStyle={{ maxHeight: 500 }}
      dropdownOffset={{ top: 30, left: isMobile ? -280 : -100 }}
      label={
        <View style={{ position: 'relative', marginRight: 24 }}>
          <MaterialIcons size={16} color='white' name='notifications-active' />
          {user && user.unreadNotificationCount > 0 && (
            <View
              style={{
                right: user.unreadNotificationCount > 99 ? -16 : -12,
                top: -10,
                width:
                  user.unreadNotificationCount > 99
                    ? 28
                    : user.unreadNotificationCount > 9
                    ? 20
                    : 16,
                paddingHorizontal: 2,
                height: 16,
                borderRadius: 3,
                justifyContent: 'center',
                alignContent: 'center',
                position: 'absolute',
                backgroundColor: palette.warning
              }}
            >
              <Text
                numberOfLines={1}
                style={{ textAlign: 'center', color: 'white', fontSize: 12, lineHeight: 16 }}
              >
                {user.unreadNotificationCount > 99 ? '99+' : user.unreadNotificationCount}
              </Text>
            </View>
          )}
        </View>
      }
      onScroll={handleScroll}
    >
      <View style={{ backgroundColor: 'white', paddingHorizontal: 15, borderRadius: 3 }}>
        <View
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{t('NOTIFICATION.LABEL')}</Text>
          <ThemedButton
            onPress={markAllRead}
            disabled={!user || user.unreadNotificationCount === 0}
            style={{ backgroundColor: 'transparent' }}
            textStyle={{
              color: !user || user.unreadNotificationCount === 0 ? palette.disable : palette.brand
            }}
          >
            {t('NOTIFICATION.MARK_ALL_AS_READ')}
          </ThemedButton>
        </View>
        <View style={{ minHeight: 100 }}>
          {user || (!loading && user) ? (
            notifications.length > 0 ? (
              <>
                {scmUnread.map((userNotification) => (
                  <NotificationRow
                    onClose={handleClose}
                    key={userNotification.id}
                    {...userNotification}
                  />
                ))}
                {scmUnread.length > 0 && (
                  <View
                    style={{
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      borderBottomColor: palette.border,
                      paddingVertical: 2,
                      borderStyle: 'dashed'
                    }}
                  />
                )}
                {notifications.map((userNotification) => (
                  <NotificationRow
                    onClose={handleClose}
                    key={userNotification.id}
                    {...userNotification}
                  />
                ))}
              </>
            ) : (
              <Text>{t('NOTIFICATION.EMPTY')}</Text>
            )
          ) : (
            <ActivityIndicator />
          )}
        </View>
      </View>
    </Dropdown>
  )
}
