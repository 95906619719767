export interface Basic {
  uuid: string
  title: string
  tourtakoSubDomain: string
  mySiteUrl: string
  website: string
  contactFirstName: string
  contactLastName: string
  contactEmail: string
  contactPhoneITI: number
  contactPhone: string
  contactMobileITI: string
  contactMobile: string
  contactFaxITI: string
  contactFax: string
  description: string
  introStreaming: any[]
  facebook: string
  twitter: string
  instagram: string
  youtube: string
  line: string
  linkedIn: string
  whatsapp: string
  telegram: string
  kaKaoTalk: string
  signal: string
  wechat: string
  tikTok: string
  xiaohongshu: string
  snapchat: string
  viber: string
  weibo: string
  naver: string
  zalo: string
  logo: any[]
  images: any[]
  mysiteLogo: Mysite[]
  mysiteBanner: Mysite[]
  mysiteFavicon: Mysite[]
  countryUuid: string
  stateProvinceUuid: string
  cityUuid: string
  address: string
  postcode: string
  privacyPolicy: string
  termsOfService: string
  specifiedCT: string
  latitude: null
  longitude: null
  state: string
  city: string
  accountLimit: number
  couponLimit: number
  enableCustomerRedeem: boolean
  enableWebVoucherQrCode: boolean
  enableWalletPass: boolean
  forceRedeemSetting: string
  localUrl: string
  localSvcKey: string
  ttd: Ttd
  mapSnapshotImage: string
  gracePeriodStatus: GracePeriodStatus
  periodEndDate: string
}

// 店家扣款狀態
export enum GracePeriodStatus {
  Success,
  OnlineFail,
  OfflineFail
}

export interface Mysite {
  storeMediaUsageUuid: string
  storeMediaUuid: string
  url: string
  clientFileName: string
  size: number
}

export interface Ttd {
  label: string
  enable: boolean
  isOfficialWebsite: boolean
  urlType: string
}
