import Core from '@rezio/core/core'
import { UserNotification } from '@rezio/core/notification'
import { getLatestSession, isNonSessionProduct } from '@rezio/core/notification/apis'
import { ROLE_TITLE } from '@rezio/core/permission'
import { Platform } from 'react-native'

async function handleProductSalesOptionExpiration(
  notification: UserNotification<'PRODUCT_SALESOPTION_EXPIRATION'>['notification']
) {
  const { payload, entity } = notification
  const { productId } = entity.snapshot

  //NOTE: app 部分不處理
  if (Platform.OS !== 'web') {
    return null
  }

  //NOTE: 理單員 / 核銷員不可進入
  const core = Core.getInstance()

  if ([ROLE_TITLE.REDEEM_STUFF, ROLE_TITLE.ORDER_ONLY].includes(core.permission.role)) {
    throw new Error('permission_denied')
  }

  //NOTE: NST 進入 票價/銷售期間
  const isNST = await isNonSessionProduct(productId)
  if (isNST) {
    return {
      pathname: '/product/add',
      query: {
        productId,
        salesOptionId: payload.salesOptionId,
        step: 'pricing'
      }
    }
  }

  const latestSession = await getLatestSession({
    productId,
    salesOptionId: payload.salesOptionId
  })

  //NOTE: 未來沒有 session 進入 新增日曆
  if (!latestSession) {
    return {
      pathname: '/product/add',
      query: {
        productId,
        step: 'session'
      }
    }
  }

  //NOTE: 未來有 session 到編輯 session 頁面
  return {
    pathname: '/publish/session',
    query: {
      productId,
      salesOptionId: payload.salesOptionId,
      sessionId: latestSession.sessionUuid,
      sessionSettingUuid: latestSession.sessionSettingUuid
    }
  }
}

export { handleProductSalesOptionExpiration }
