import { MaterialIcons } from '@expo/vector-icons'
import { usePageWidth, useLayout, useRouter, useStores } from '@rezio/core/hooks'
import { palette, template } from '@rezio/res/theme'
import Modal from '@rezio/unimodules/modal'
import { MemoryRouter } from '@rezio/unimodules/router'
import _ from 'lodash'
import React, { createContext, PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ScrollView, Text, Dimensions } from 'react-native'
import { WhitePortal } from 'react-native-portal'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { proxy, useSnapshot, snapshot } from 'valtio'

import { useFooter } from '@rezio/core/layout'
import { ThemedButton } from './themedComponents'

interface FrameState {
  title?: string
  pages: string[]
  index: number
  position: 'left' | 'right'
}

const defaultState: FrameState = {
  title: null,
  pages: [],
  index: 0,
  position: 'left'
}

export const state = proxy<FrameState>(defaultState)

global.__REZIO_FRAME_STATE__ = state

export function setPages(
  pages: FrameState['pages'],
  index?: FrameState['index'],
  title?: FrameState['title'],
  position: FrameState['position'] = 'left'
) {
  state.pages = pages
  if (index != null) {
    state.index = index
  }
  state.title = title
  state.position = position
}

export const FrameContext = createContext<{
  isInFrame: boolean
}>({
  isInFrame: false
})

export const close = () => {
  const { position } = snapshot(state)
  setPages([], 0, null, position)
}

export const prev = () => {
  const { index } = snapshot(state)
  state.index = Math.max(0, index - 1)
}

export const next = () => {
  const { pages, index } = snapshot(state)
  state.index = Math.min(pages.length - 1, index + 1)
}

export function Frame({ children }: PropsWithChildren<object>) {
  const { pages, position } = useSnapshot(state)
  const { isMobile } = useLayout()

  const containerStyle = {
    right: isMobile || position === 'right' ? 0 : 400,
    left: isMobile || position === 'left' ? 0 : 400
  }
  const { core } = useStores()
  const animationIn = position === 'right' ? 'slideInRight' : 'slideInLeft'
  const animationOut = position === 'right' ? 'slideOutRight' : 'slideOutLeft'

  useEffect(() => {
    const dispose = core.addChangeStoreListeners(() => {
      const resetState = _.cloneDeep(defaultState)
      Object.keys(resetState).forEach((key) => {
        state[key] = resetState[key]
      })
    })
    return () => {
      dispose()
    }
  }, [])

  return (
    <Modal
      style={[{ margin: 0, backgroundColor: isMobile ? 'white' : undefined }]}
      isVisible={pages.length > 0}
      onBackdropPress={close}
      onBackButtonPress={close}
      hasBackdrop={false}
      containerStyle={containerStyle}
      backdropOpacity={0}
      backdropTransitionInTiming={0}
      backdropTransitionOutTiming={0}
      animationIn={animationIn}
      animationOut={animationOut}
    >
      <MemoryRouter>
        <FrameContent>{children}</FrameContent>
      </MemoryRouter>
    </Modal>
  )
}

function FrameContent({ children }: PropsWithChildren<object>) {
  const { t } = useTranslation()
  const pageWidth = usePageWidth()
  const { isMobile } = useLayout()
  const [footerContext, footer, PageProvider] = useFooter()
  const insets = useSafeAreaInsets()
  const router = useRouter()
  const { pages, index, title } = useSnapshot(state)
  const page = pages[index]
  const width = isMobile ? pageWidth : Math.min(pageWidth, Dimensions.get('window').width - 400)
  useEffect(() => {
    router.push(page)
  }, [page])

  const isFirst = index === 0
  const isLast = index === pages.length - 1

  return (
    <View
      key={router.pathname}
      style={[
        {
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
          height: '100%'
        }
      ]}
    >
      <WhitePortal name='frameToast' />
      <PageProvider value={footerContext}>
        <View
          style={[
            {
              width,
              backgroundColor: palette.white,
              flex: 1
            },
            template.shadow
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              borderBottomWidth: 1,
              borderColor: palette.border,
              justifyContent: 'space-between',
              paddingVertical: 8,
              marginHorizontal: 20
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', flexShrink: 1 }}>
              <ThemedButton
                style={{ backgroundColor: 'transparent', width: 40, flexShrink: 0 }}
                onPress={close}
              >
                <MaterialIcons name='close' size={20} color={palette.black} />
              </ThemedButton>
              <Text
                numberOfLines={1}
                style={{ color: palette.primary, fontSize: 18, fontWeight: 'bold' }}
              >
                {title}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', flexShrink: 0 }}>
              <ThemedButton
                style={{
                  marginRight: 10,
                  backgroundColor: 'transparent',
                  borderColor: isFirst ? palette.ashGray : palette.primary,
                  borderWidth: 1
                }}
                onPress={prev}
                disabled={isFirst}
              >
                <MaterialIcons
                  name='arrow-back'
                  size={20}
                  color={isFirst ? palette.ashGray : palette.primary}
                />
                {!isMobile && (
                  <Text style={{ color: isFirst ? palette.ashGray : palette.primary }}>
                    {t('COMMON.FRAME_BUTTON_PREVIOUS')}
                  </Text>
                )}
              </ThemedButton>
              <ThemedButton
                style={{
                  marginRight: 10,
                  backgroundColor: 'transparent',
                  borderColor: isLast ? palette.ashGray : palette.primary,
                  borderWidth: 1
                }}
                onPress={next}
                disabled={isLast}
              >
                {!isMobile && (
                  <Text style={{ color: isLast ? palette.ashGray : palette.primary }}>
                    {t('COMMON.FRAME_BUTTON_NEXT')}
                  </Text>
                )}
                <MaterialIcons
                  name='arrow-forward'
                  size={20}
                  color={isLast ? palette.ashGray : palette.primary}
                />
              </ThemedButton>
            </View>
          </View>
          <ScrollView
            style={{ flex: 1, height: '100%' }}
            contentContainerStyle={{ height: '100%' }}
          >
            <FrameContext.Provider value={{ isInFrame: true }}>{children}</FrameContext.Provider>
          </ScrollView>
        </View>
        <View key='footer' style={[{ width }]}>
          {footer || <View />}
        </View>
      </PageProvider>
    </View>
  )
}
