/*
  to resolve mobile browser 100vh problem
  https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html
  https://github.com/mvasin/react-div-100vh
*/
import { Dimensions, Platform, View } from 'react-native'
import { use100vh } from 'react-div-100vh'

const View100vh = (props) => {
  const height = Platform.select({
    web: use100vh(),
    default: Dimensions.get('window').height
  })
  return <View style={{ height }} {...props} />
}

export default View100vh
