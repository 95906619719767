import { getEnv, getIsDev } from '@rezio/core/config'
import { getAnalytics, logEvent, setUserProperties, setUserId } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import _ from 'lodash'

const firebaseConfig = {
  apiKey: 'AIzaSyDIuKkFuPgySELBxQBy3L0BA-nREDpUM38',
  authDomain: 'rezio-admin.firebaseapp.com',
  databaseURL: 'https://rezio-admin.firebaseio.com',
  projectId: 'rezio-admin',
  storageBucket: 'rezio-admin.appspot.com',
  messagingSenderId: '576462581093',
  appId: '1:576462581093:web:deec0c8180342e122d458c',
  measurementId: 'G-QJYW2V5NZV'
}

const app = initializeApp(firebaseConfig)
const webAnalytics = getAnalytics(app)

const debugable = getIsDev()

export const log = (eventName: string, content, ...rest) => {
  if (!debugable) {
    return
  }
  let bgColor
  const eventType = eventName?.startsWith('user') ? 'initialize' : eventName

  switch (eventType) {
    case 'initialize':
      bgColor = 'gray'
      break
    default:
      bgColor = 'orange'
      break
  }

  console.log('%c' + eventName, `color: white; background-color: ${bgColor}`, content, ...rest)
}

export function userPropertyTrigger(property) {
  const userProperties = {
    env: getEnv(),
    ...property
  }
  _.reduce(
    userProperties,
    (result, value, key) => {
      setUserProperties(webAnalytics, { [key]: value })
      return result
    },
    {}
  )
  log('user_property', userProperties)
}

export function userIdTrigger(id) {
  setUserId(webAnalytics, id)
  log('user_id', id)
}

const trigger = (eventName: string, paramsObj) => {
  const eventParams = paramsObj ?? null
  try {
    logEvent(webAnalytics, eventName, eventParams)
    log(eventName, eventParams)
  } catch (error) {
    console.error(error)
  }
}

export function pageViewTrigger(pageName: string) {
  const eventParams = {
    screen_name: pageName
  }
  trigger('screen_view', eventParams)
}

export function fileDownloadTrigger(fileTitle: string) {
  const eventParams = {
    file_name: fileTitle
  }
  trigger('file_download', eventParams)
}
