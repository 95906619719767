import { manipulator, palette, template } from '@rezio/res/theme'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'

import { PageFooter } from '../layout'
import { DialogModal } from '../modal'
import { ThemedButton } from './ThemedButton'

export interface SampleModalProps extends PropsWithChildren {
  show: boolean
  singleButtomMode?: boolean
  onCancel: () => void
  onConfirm: () => void
}

export const SampleModal = ({
  show,
  children,
  onCancel,
  onConfirm,
  singleButtomMode
}: SampleModalProps) => {
  const { t } = useTranslation()
  return (
    <DialogModal isVisible={show} containerStyle={{ padding: 10 }}>
      {children}
      <PageFooter inline style={{ margin: -10, marginTop: 20 }}>
        {singleButtomMode ? (
          <ThemedButton
            onPress={onConfirm}
            style={[template.defaultPanel, { backgroundColor: palette.primary }]}
          >
            <Text style={{ color: palette.white }}>{t('FORM.BUTTON_OK')}</Text>
          </ThemedButton>
        ) : (
          <View style={manipulator.container('row', 'space-between', 'center')}>
            <ThemedButton
              onPress={onCancel}
              style={[template.defaultPanel, { width: 100, backgroundColor: palette.negative }]}
            >
              <Text style={{ color: palette.black }}>{t('FORM.BUTTON_CANCEL')}</Text>
            </ThemedButton>
            <ThemedButton
              onPress={onConfirm}
              style={[template.defaultPanel, { width: 100, backgroundColor: palette.positive }]}
            >
              <Text style={{ color: palette.white }}>{t('FORM.BUTTON_OK')}</Text>
            </ThemedButton>
          </View>
        )}
      </PageFooter>
    </DialogModal>
  )
}
