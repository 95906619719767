import { FormField } from '@rezio/components/form'
import { useStores, useLayout } from '@rezio/core/hooks'
import { useKeystoneStores } from '@rezio/core/stores/rootStore'
import { template, manipulator, palette } from '@rezio/res/theme'
import { DeviceType, ProductCategories } from '@rezio/utils/types'
import _ from 'lodash'
import { observer } from 'mobx-react'
import qs from 'query-string'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  ScrollView,
  FlatList,
  Text,
  View,
  ActivityIndicator,
  Platform,
  Dimensions
} from 'react-native'
import type { StyleProp, ViewStyle } from 'react-native'

import { HoverableOpacity, MaterialIcons, ThemedButton, PageStepControl, PageWidthModal } from '..'

enum SelectorCategory {
  Resource,
  Product,
  Ticket,
  Device,
  DeviceOperation,
  Coupon
}

enum SelectorDisplayType {
  Modal,
  Flatten
}

interface CustomSelectorProps<T = any> {
  type: SelectorCategory
  value?: any
  onChange?: (value) => void
  handleCustomChange?: (value) => void
  numColumns?: number
  displayType?: SelectorDisplayType
  isMultiSelect?: boolean
  listStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  inputContainerStyle?: StyleProp<ViewStyle>
  filter?: (instance: T) => boolean
  disabled?: boolean
  channel?: number
}

const CustomSelector = observer(function CustomSelector(props: CustomSelectorProps) {
  const [iconName, setIconName] = useState(null)
  const [title, setTitle] = useState(null)
  const [subtitle, setSubtitle] = useState(null)
  const [selectedCountTitle, setSelectedCountTitle] = useState(null)
  const [targetStore, setTargetStore] = useState(null)
  const [targetItems, setTargetItems] = useState(null)
  const { store, t, core, data } = useStores()
  const { CouponCode } = useKeystoneStores()
  const { isMobile } = useLayout()
  const [keyword, setKeyword] = useState('')
  const [isPopup, setPopup] = useState(false)
  const [productsForSelector, setProductsForSelector] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const {
    type = SelectorCategory.Resource,
    displayType = SelectorDisplayType.Modal,
    inputContainerStyle,
    isMultiSelect = false,
    filter = null,
    disabled = false,
    channel
  } = props

  const [selectedItems, setSelectedItems] = useState(props.value || [])
  const {
    control,
    getValues,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldUnregister: true })
  const tsDiff = useMemo(() => {
    const timezoneData = core?.options?.timezone?.find(
      ({ uuid }) => uuid === data?.store?.regional?.timezoneUuid
    )
    return +timezoneData?.timeDiff || 0
  }, [core?.options?.timezone, data?.store?.regional?.timezoneUuid])

  useEffect(() => {
    switch (type) {
      case SelectorCategory.Ticket:
        setIconName('local-activity')
        setTitle('TICKET.SELECT_TICKET')
        setSubtitle('PRICING.TICKET_FORM_TITLE_TICKET')
        setSelectedCountTitle('TICKET.SELECTED_COUNT_PLURAL')
        setTargetStore(store.ticketStore)
        setTargetItems([...store.ticketStore.tickets.values()])
        break
      case SelectorCategory.Product:
        setIconName('folder-special')
        setTitle('PRODUCT.SELECT_PRODUCT')
        setSubtitle('PUBLISH.SESSION_TITLE_PRODUCT')
        setSelectedCountTitle('PRODUCT.PRODUCT_SELECTED_COUNT_plural')
        setTargetStore(store.productStore)
        if (typeof filter === 'function') {
          setTargetItems(_.filter(productsForSelector, filter))
        } else {
          setTargetItems(productsForSelector)
        }
        break
      case SelectorCategory.Coupon:
        setIconName('label')
        setTitle('DISCOUNT.SELECT_COUPON')
        setSubtitle('DISCOUNT.SELECTOR_POPUP_SUBTITLE')
        setSelectedCountTitle('DISCOUNT.SELECTED_COUNT_PLURAL')
        setTargetStore(CouponCode.couponList)
        setTargetItems([...CouponCode.couponList.values()])
        break
      case SelectorCategory.Device:
      case SelectorCategory.DeviceOperation:
        setIconName('point-of-sale')
        setTitle('DEVICE.SELECTOR_POPUP_TITLE')
        setSubtitle('DEVICE.SELECTOR_POPUP_SUBTITLE')
        setSelectedCountTitle('DEVICE.SELECTED_COUNT_PLURAL')
        setTargetStore(store.deviceStore)
        setTargetItems([...store.deviceStore.devices.values()])
        break
      case SelectorCategory.Resource:
      default:
        setIconName('event')
        setTitle('RESOURCE.SELECTOR_POPUP_TITLE')
        setSubtitle('RESOURCE.RESOURCE_TITLE')
        setSelectedCountTitle('RESOURCE.SELECTED_COUNT_PLURAL')
        setTargetStore(store.resourceStore)
        setTargetItems([...store.resourceStore.resources.values()])
        break
    }
  }, [
    type,
    filter,
    _.size([...store.ticketStore.tickets.values()]),
    productsForSelector,
    _.size([...store.deviceStore.devices.values()]),
    _.size([...store.resourceStore.resources.values()]),
    _.size([...CouponCode.couponList.values()])
  ])

  useEffect(() => {
    async function loadTargetList(type: SelectorCategory) {
      switch (type) {
        case SelectorCategory.Ticket:
          await store.ticketStore.loadTicketPage({ num: 999 }).catch(console.error)
          break
        case SelectorCategory.Product: {
          // loadAllProducts for 標籤 badge 篩選用
          store.productStore.loadAllProducts('', true).catch(console.error)
          fetchProductForSearch().catch(console.error)
          break
        }
        case SelectorCategory.Coupon:
          await CouponCode.fetchCouponCodeList({ num: 999, tsDiff }).catch(console.error)
          break
        case SelectorCategory.Device:
        case SelectorCategory.DeviceOperation:
          await store.deviceStore
            .loadDevicePage({
              deviceType: `${DeviceType.Pos},${DeviceType.Kiosk},${DeviceType.Payment}`
            })
            .catch(console.error)
          break
        case SelectorCategory.Resource:
        default:
          await store.resourceStore.loadResourceList({ num: 999 }).catch(console.error)
          break
      }
    }
    loadTargetList(type).catch(console.error)
  }, [type, tsDiff])

  useEffect(() => {
    setSelectedItems(props.value || [])
  }, [props.value])

  const handleChange = useCallback(() => {
    props.onChange?.(selectedItems)
    props.handleCustomChange?.(selectedItems)
    setPopup(false)
  }, [selectedItems, displayType])

  const handleFilterChange = useCallback(() => {
    const keyword = getValues('keyword')?.toLowerCase()
    // TODO: temp 先改動 product，其他 type 待補
    switch (type) {
      case SelectorCategory.Product:
        fetchProductForSearch(keyword).catch(console.error)
        break
      default:
        setKeyword(keyword)
        break
    }
  }, [type])

  const handleSelectedItems = useCallback(
    (value) => {
      const changeHandler = props.onChange
      const items = !selectedItems.includes(value)
        ? isMultiSelect
          ? [...selectedItems, value]
          : [value]
        : _.remove(selectedItems, (n) => n !== value)
      setSelectedItems(items)
      return displayType === SelectorDisplayType.Flatten && changeHandler?.(items)
    },
    [selectedItems, displayType]
  )

  const removeSelectedItems = useCallback(async () => {
    const changeHandler = props.onChange
    changeHandler?.([])
    return setSelectedItems([])
  }, [selectedItems])

  const formattedItemList = useCallback(
    (list) => {
      switch (type) {
        case SelectorCategory.Product:
          return _.sortBy(list, 'title').map(({ title, alias, uuid, productCode }) => ({
            label: `[${productCode}] ${title}`,
            alias: alias || '',
            value: uuid,
            key: uuid
          }))
        case SelectorCategory.Ticket:
          return _.sortBy(list, 'title').map(({ title, code, uuid }) => ({
            label: `[${code}] ${title}`,
            value: uuid,
            key: uuid
          }))
        case SelectorCategory.Coupon:
          return _.sortBy(list, 'label').map(({ label, code, uuid }) => ({
            label: `[${code}] ${label}`,
            value: uuid,
            key: uuid
          }))
        case SelectorCategory.Device:
        case SelectorCategory.DeviceOperation:
          return _.sortBy(list, 'title').map(({ uuid, deviceId, accountName }) => ({
            label: `[${deviceId}] ${accountName}`,
            value: type === SelectorCategory.DeviceOperation ? deviceId : uuid,
            key: uuid
          }))
        case SelectorCategory.Resource:
          return _.sortBy(list, 'title').map(({ label, uuid }) => ({
            label,
            value: uuid,
            key: uuid
          }))
      }
    },
    [type]
  )

  const filteredItemList = useCallback(
    (targetItems) => {
      // TODO: temp 先改動 product，其他 type 待補
      switch (type) {
        case SelectorCategory.Product:
          return formattedItemList(targetItems)
        default:
          return keyword
            ? formattedItemList(targetItems).filter(({ label }) =>
                label.toLowerCase().includes(keyword)
              )
            : formattedItemList(targetItems)
      }
    },
    [type, targetStore, formattedItemList, keyword]
  )

  const fetchProductForSearch = useCallback(
    async (keyword: string = '') => {
      setIsSearching(true)
      const filterText = qs.stringify({ num: keyword ? 999 : 500, text: keyword, channel })
      const result = await core.api.get(`product/1?${filterText}`)[0]
      const data =
        result?.data?.list && !_.isArray(result?.data?.list)
          ? Object.values(result.data.list)
          : null
      setProductsForSelector(data)
      setIsSearching(false)
    },
    [channel]
  )

  if (!targetStore) {
    return <ActivityIndicator />
  }

  const itemFlatList = (
    <View
      style={[
        isMobile
          ? {}
          : { height: displayType === SelectorDisplayType.Flatten ? 500 : 'auto', minHeight: 200 },
        { marginBottom: 10 },
        props.listStyle
      ]}
    >
      <Text style={{ fontSize: 16, marginBottom: 10 }}>{t(subtitle)}</Text>
      <View
        style={[
          manipulator.container('row', 'space-between', 'center'),
          { maxHeight: 36, marginBottom: 20 }
        ]}
      >
        <Controller
          name='keyword'
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormField
              onChange={onChange}
              value={value}
              type='TextInput'
              error={errors.keyword}
              style={{
                height: '100%',
                marginBottom: 0,
                flex: 1,
                marginRight: 10
              }}
              meta={{
                style: {
                  ...Platform.select({
                    ios: { height: 'auto' },
                    android: { height: '100%' },
                    web: { height: '100%' }
                  })
                }, //NOTE: 不加 height: auto ios 會閃退，原因不明，未來 react native 版本或許可以拿掉試試 ( 會壞版本 0.72.5 )
                layout: 'block',
                placeholder: t('FORM.TEXTINPUT_FILTER_PLACEHOLDER'),
                returnKeyType: 'go',
                onSubmitEditing: handleFilterChange
              }}
            />
          )}
        />
        <ThemedButton
          style={{ paddingHorizontal: 16, height: '100%', width: 90 }}
          onPress={handleFilterChange}
        >
          {t('COMMON.SEARCH_PLACEHOLDER')}
        </ThemedButton>
      </View>
      {targetStore.isLoading || isSearching ? (
        <ActivityIndicator />
      ) : (
        <ScrollView nestedScrollEnabled style={isMobile ? { maxHeight: 500 } : {}}>
          <FlatList
            data={filteredItemList(targetItems)}
            numColumns={props.numColumns || (isMobile ? 1 : 2)}
            extraData={selectedItems}
            renderItem={({ item }) => {
              const isSelected = selectedItems.includes(item.value)
              return (
                <ThemedButton
                  onPress={() => handleSelectedItems(item.value)}
                  containerStyle={{ flex: 1, marginBottom: 10 }}
                  style={[
                    manipulator.container(
                      'row',
                      'flex-start',
                      isMobile ? 'flex-start' : 'baseline'
                    ),
                    { backgroundColor: 'transaprent', height: '100%' }
                  ]}
                >
                  <MaterialIcons
                    name={
                      isSelected
                        ? isMultiSelect
                          ? 'check-box'
                          : 'radio-button-checked'
                        : isMultiSelect
                        ? 'check-box-outline-blank'
                        : 'radio-button-unchecked'
                    }
                    color={isSelected ? palette.primary : palette.negative}
                    size={16}
                  />
                  <Text style={{ marginLeft: 10, fontSize: 14 }}>{item.label}</Text>
                </ThemedButton>
              )
            }}
            ListEmptyComponent={() => {
              return (
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Text>{t('COMMON.NONE')}</Text>
                </View>
              )
            }}
          />
        </ScrollView>
      )}
    </View>
  )

  if (displayType === SelectorDisplayType.Flatten) {
    return <View style={props.containerStyle}>{itemFlatList}</View>
  }

  return (
    <View>
      <HoverableOpacity onPress={() => !disabled && setPopup(true)}>
        <View
          style={[
            manipulator.container('row', 'flex-start', 'center'),
            manipulator.border('all', '', 5),
            { paddingHorizontal: 10, paddingVertical: 7, position: 'relative' },
            disabled && { backgroundColor: palette.disable },
            inputContainerStyle
          ]}
        >
          <MaterialIcons name={iconName} size={20} color={palette.gray} />
          <Text
            style={{ marginLeft: 10, color: palette.black }}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {selectedItems.length > 0
              ? isMultiSelect
                ? t(selectedCountTitle, { count: selectedItems.length })
                : _.find(targetItems, (item) => selectedItems.includes(item.uuid))?.title ||
                  t('FORM.PICKER_DEFAULT_INVALID_VALUE_PLACEHOLDER')
              : t(title)}
          </Text>
        </View>
      </HoverableOpacity>
      <PageWidthModal isVisible={isPopup} onBackdropPress={() => setPopup(false)}>
        <View
          style={[
            template.rowContainer,
            { marginBottom: 14, borderBottomWidth: 1, borderBottomColor: palette.border }
          ]}
        >
          <ThemedButton
            style={{ backgroundColor: 'transparent', width: 40 }}
            onPress={() => setPopup(false)}
          >
            <MaterialIcons name='close' size={20} color={palette.primary} />
          </ThemedButton>
          <Text style={{ color: palette.primary, fontWeight: 'bold', fontSize: 18 }}>
            {t(title)}
          </Text>
        </View>
        {itemFlatList}
        <PageStepControl
          buttons={[
            { type: 'negative', text: t('COMMON.REMOVE_ALL'), onPress: removeSelectedItems },
            { type: 'positive', text: t('REPORT.BUTTON_APPLY'), onPress: handleChange }
          ]}
        />
      </PageWidthModal>
    </View>
  )
})

export { CustomSelector, SelectorCategory }
