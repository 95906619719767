import styled from '@emotion/native'
import { MaterialIcons } from '@expo/vector-icons'
import { manipulator, palette, template } from '@rezio/res/theme'
import { atom, useAtomValue } from 'jotai'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'

import { SampleModal, SampleModalProps } from './simpleModal'

export enum KkdayRedeemState {
  init,
  isKkday,
  forceRedeemKkday,
  KkdayRedeemResult
}

export const kkdayRedeemStateAtom = atom(KkdayRedeemState.init)

interface RedeemPopupProps extends Omit<SampleModalProps, 'singleButtomMode'> {
  kkdayValidPeriod: {
    validStartDate: string
    validEndDate: string
  }
}

export const RedeemPopup = ({ show, onCancel, onConfirm, kkdayValidPeriod }: RedeemPopupProps) => {
  const step = useAtomValue(kkdayRedeemStateAtom)
  const { t } = useTranslation()
  return (
    <SampleModal
      singleButtomMode={step === KkdayRedeemState.KkdayRedeemResult}
      {...{ show, onConfirm, onCancel }}
    >
      {(() => {
        switch (step) {
          case KkdayRedeemState.isKkday:
            return (
              <>
                <ModalTitle title={t('SCAN.KKDAY_REDEEM_CONFIRM_TITLE')} />
                <ContentText text={t('SCAN.KKDAY_REDEEM_CONFIRM_CONTENT')} />
              </>
            )
          case KkdayRedeemState.forceRedeemKkday:
            return (
              <>
                <ModalTitle title={t('SCAN.KKDAY_REDEEM_CONFIRM_TITLE')} />
                <ContentText text={t('ORDER.TAB_FORCE_REDEEM_KKDAY_SUBTITLE', kkdayValidPeriod)} />
              </>
            )

          case KkdayRedeemState.KkdayRedeemResult:
            return (
              <RuntimeErrorLookModalContent
                title={t('ORDER.TAB_FORCE_REDEEM_ORDER_RESULT_SUCCESS')}
                content={t('SCAN.KKDAY_REDEEM_DONE_CONTENT')}
              />
            )
        }
      })()}
    </SampleModal>
  )
}

const ModalTitle = ({ title }: { title: string | JSX.Element }) => (
  <View style={{ alignItems: 'center' }}>
    <BlueAppTitleText>{title}</BlueAppTitleText>
  </View>
)

const RuntimeErrorLookModalContent = ({ title, content }) => (
  <View style={[manipulator.panel(20, 20, 20, 20), { alignItems: 'center' }]}>
    <MaterialIcons name='check-circle' size={100} color={palette.positive} />
    <Text
      style={[
        template.bold,
        { color: palette.primary, fontSize: 24, paddingBottom: 10, paddingTop: 20 }
      ]}
    >
      {title}
    </Text>
    <Text style={{ maxHeight: 350, overflow: 'scroll', width: '100%', textAlign: 'center' }}>
      {content}
    </Text>
  </View>
)

/**
 * @desc figma component
 * @figma 內文內文內文
 */
const ContentText = ({ text }: { text: string | JSX.Element }) => (
  <View style={[manipulator.container('column', 'center', 'center')]}>
    <View style={[manipulator.container('row', 'center', 'center'), { maxWidth: 380 }]}>
      <Text style={{ textAlign: 'center' }}>{text}</Text>
    </View>
  </View>
)

/**
 * @desc figma component
 * @figma 藍色大標題
 */
export const BlueAppTitleText = styled.Text({
  fontSize: 20,
  lineHeight: 40,
  color: palette.primary,
  paddingBottom: 8,
  fontWeight: 'bold',
  textAlign: 'center'
})

export const BlueAppSectionText = styled.Text({
  fontWeight: '500',
  fontSize: 18,
  color: palette.primary,
  paddingBottom: 20
})
