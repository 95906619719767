import { MaterialIcons } from '@rezio/components'
import { palette } from '@rezio/res/theme'
import React, { ReactNode, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import { View, Text, TextStyle, StyleProp } from 'react-native'

interface BoldTextProps {
  boldStyle?: {
    isListStyle?: boolean
    style?: StyleProp<TextStyle>
  }
  children?: ReactNode
}
interface ListItemTextProps {
  listItemStyle?: StyleProp<TextStyle>
  children?: ReactNode
}
type TransTextProps = PropsWithChildren<
  {
    transText: string
    textStyle?: StyleProp<TextStyle>
    customComponent?: Record<string, ReactNode>
  } & BoldTextProps &
    ListItemTextProps
>

const BoldText = (props: BoldTextProps): JSX.Element => {
  const { boldStyle, children } = props
  return (
    <Text style={[{ fontWeight: 'bold' }, boldStyle?.style]}>
      {boldStyle?.isListStyle && (
        <MaterialIcons
          name='fiber-manual-record'
          color={palette.black}
          size={10}
          style={{ marginRight: 5 }}
        />
      )}
      {children}
    </Text>
  )
}

export const ListItemText = (props: ListItemTextProps): JSX.Element => {
  const { listItemStyle, children } = props
  return (
    <View style={[{ display: 'flex', flexDirection: 'row' }, listItemStyle]}>
      <MaterialIcons name='fiber-manual-record' size={10} style={{ marginRight: 5 }} />
      <Text>{children}</Text>
    </View>
  )
}

export const TransText = (props: TransTextProps): JSX.Element => {
  const { textStyle, transText, boldStyle, listItemStyle, customComponent } = props

  return (
    <Text style={textStyle}>
      <Trans
        i18nKey={transText}
        components={{
          bold: <BoldText boldStyle={boldStyle} />,
          ListItem: <ListItemText listItemStyle={listItemStyle} />,
          ...customComponent
        }}
      />
    </Text>
  )
}
