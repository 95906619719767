import { ThemedTextInput } from '@rezio/components'
import { useStores, useLayout } from '@rezio/core/hooks'
import MarkdownEditorBase from '@rezio/unimodules/markdownEditor'
import _ from 'lodash'
import React, { useCallback } from 'react'

export const MARKDOWN_TAG = '[enable-markdown]: #'
const EMPTY = `${MARKDOWN_TAG}\n`
export function withoutMarkdownTag(value) {
  if (_.isString(value)) {
    if (value.startsWith(MARKDOWN_TAG)) {
      value = value.replace(`${MARKDOWN_TAG}\n`, '')
    }
  }
  return value
}

export function MarkdownEditor({ value, onChange, style, disabled = false, ...props }) {
  const { isMobile } = useLayout()
  const { t } = useStores()
  const changeHandler = useCallback((e) => {
    onChange && onChange(_.isEmpty(e) || e === EMPTY ? '' : `${MARKDOWN_TAG}\n${e}`)
  }, [])

  const { errors, touched, submitted, pristine = true } = props

  if (_.isString(value)) {
    if (!value.startsWith(MARKDOWN_TAG)) {
      value = value.replace(/(\s)\n/g, ' \n')
    } else {
      value = withoutMarkdownTag(value)
    }
  }
  const formatedValue = _.isEmpty(value) && disabled ? t('VOUCHER.NONE') : value
  const fieldChanged = touched || submitted || !pristine
  const errorStyle = errors && fieldChanged && { borderColor: 'red', borderWidth: 1 }

  return isMobile ? (
    <ThemedTextInput
      multiline
      style={[{ height: 80 }, style, errorStyle]}
      disabled={disabled}
      inputStyle={disabled ? { padding: 10 } : {}}
      onChangeText={changeHandler}
      defaultValue={formatedValue}
      {...props}
    />
  ) : (
    <MarkdownEditorBase
      style={[{ height: 300 }, style, errorStyle]}
      disabled={disabled}
      value={formatedValue}
      onChange={changeHandler}
      {...props}
    />
  )
}
