import { formatStatus, formatChannelAllowedSpec } from '@rezio/components/channel/status'
import {
  ChannelType,
  ChannelProductStatus,
  ChannelProductActiveStatus,
  ChannelStatus,
  ChannelCostRuleType,
  ChannelPricingType,
  ChannelProductTransfer,
  ChannelIdentityType,
  ProductSource,
  ChannelProductVoucherTemplateType,
  ChannelSalesOptionExtendStatus,
  PriceSettingType
} from '@rezio/components/channel/constants'
import Core from '@rezio/core'
import { APIErrorCode } from '@rezio/core/api'
import { PLAN_FEATURES, checkFeatureValid } from '@rezio/core/auth'
import { Page } from '@rezio/core/stores/page.js'
import { formatCurrency, exchangeRateObj, getAsArray } from '@rezio/utils/format'
import { PricingPolicyType, ChannelUuid, CommonOptionType } from '@rezio/utils/types'
import _ from 'lodash'
import { types, flow, getRoot, getEnv, destroy } from 'mobx-state-tree'
import qs from 'query-string'
import { reject, isNil } from 'ramda'

const ChannelStatusList = types.enumeration('ChannelStatusList', Object.values(ChannelStatus))
const ChannelPricingTypeList = types.enumeration(
  'ChannelPricingType',
  Object.values(ChannelPricingType)
)
const ChannelProductStatusList = types.enumeration(
  'ChannelProductStatusList',
  Object.values(ChannelProductStatus)
)
const ChannelProductActiveStatusList = types.enumeration(
  'ChannelProductActiveStatusList',
  Object.values(ChannelProductActiveStatus)
)
const ChannelProductTransferList = types.enumeration(
  'ChannelProductTransferStatus',
  Object.values(ChannelProductTransfer)
)
const PricingPolicyTypeList = types.enumeration(
  'PricingPolicyTypeList',
  Object.values(PricingPolicyType)
)

export const statusMapping = {
  1: ChannelProductStatus.Init,
  2: ChannelProductStatus.Ready,
  4: ChannelProductStatus.Syncing,
  5: ChannelProductStatus.Posted,
  8: ChannelProductStatus.Pulled,
  9: ChannelProductStatus.PostFailed,
  11: ChannelProductStatus.Invalid,
  13: ChannelProductStatus.Mapped,
  14: ChannelProductStatus.MapFailed,
  15: ChannelProductStatus.ReadyToMap,
  17: ChannelProductStatus.UnavailablePost,
  18: ChannelProductStatus.UnavailablePost,
  19: ChannelProductStatus.Posting,
  [ChannelProductStatus.Posted]: 5,
  [ChannelProductStatus.Pulled]: 8
}

const activeStatusMapping = {
  0: ChannelProductActiveStatus.Init,
  1: ChannelProductActiveStatus.On,
  2: ChannelProductActiveStatus.Off,
  [ChannelProductActiveStatus.On]: 1,
  [ChannelProductActiveStatus.Off]: 2
}

// 0:預設（不用顯示） 1:可售量/行程資料 拋轉中 2:可售量/行程資料 拋轉成功 3:可售量/行程資料 拋轉失敗
const transferStatusMapping = {
  0: ChannelProductTransfer.Init,
  1: ChannelProductTransfer.Processing,
  2: ChannelProductTransfer.Success,
  3: ChannelProductTransfer.Failed
}

const ChannelProductSalesOption = types
  .model('ChannelProductSalesOption', {
    canPayOnSite: types.maybeNull(types.boolean),
    salesOptionUuid: types.string,
    channelSalesOptionIdentity: types.maybeNull(types.string),
    channelSalesOptionItemOid: types.maybeNull(types.string),
    mapping: types.frozen(),
    quotaType: types.maybeNull(types.number),
    pricingTypeAmount: types.maybeNull(types.number),
    pricePolicies: types.array(
      types
        .model({
          policyUuid: types.string,
          prices: types.array(
            types.model({
              type: PricingPolicyTypeList,
              uuid: types.string,
              skuOid: types.maybeNull(types.string),
              price: types.union(types.string, types.number), // 通路價
              realPrice: types.maybeNull(types.union(types.string, types.number)), // 通路價格(for ChannelCostRuleType.Commission)
              label: types.maybeNull(types.string)
            })
          )
        })
        .preProcessSnapshot((snapshot) => ({
          ...snapshot,
          policyUuid: snapshot.pricePolicyUuid
        }))
    ),
    sessionList: types.array(
      types.model({
        label: types.string,
        value: types.string,
        isAllDay: types.optional(types.boolean, false)
      })
    ),
    extendStatus: types.maybeNull(types.number),
    extendResult: types.maybeNull(types.string),
    extendTs: types.maybeNull(types.number)
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    pricePolicies: _.isArray(snapshot.pricePolicies)
      ? snapshot.pricePolicies
      : [snapshot.pricePolicies]
  }))

const formatRuleCheckResult = types.snapshotProcessor(
  types.optional(types.union(types.string, types.array(types.string)), []),
  {
    preProcessor(snapshot) {
      return snapshot ? (_.isArray(snapshot) ? [...snapshot] : snapshot.split(',')) : []
    }
  }
)

const PublishSetting = types.model('PublishSetting', {
  list: types.array(
    types.model({
      salesOptionUuid: types.identifier,
      title: types.string,
      publishStatus: types.boolean,
      checked: types.boolean,
      disabled: types.boolean
    })
  ),
  lastUpdateTs: types.maybeNull(types.number)
})

const ReserveSetting = types.model('ReserveSetting', {
  reserveUuid: types.identifier,
  reserveType: types.string,
  reserveTs: types.number,
  salesOptionUuids: types.array(types.string),
  salesOptionTitle: types.array(types.string),
  createdTs: types.number,
  enableDelete: types.boolean
})

const ReservePublishSetting = types.model('ReservePublishSetting', {
  publishSetting: types.optional(PublishSetting, {}),
  reserveSetting: types.array(ReserveSetting)
})

const ChannelProduct = types
  .model('ChannelProduct', {
    productUuid: types.identifier,
    channelUuid: types.string,
    source: types.string,
    coverUrl: types.maybeNull(types.string),
    ttdRelationType: types.maybeNull(types.string),
    placeId: types.optional(types.array(types.maybeNull(types.string)), []),
    renew: types.optional(types.boolean, false),
    isSettingChange: types.optional(types.boolean, false),
    channelSort: types.maybeNull(types.number),
    identityType: types.maybeNull(types.number),
    invoiceType: types.optional(types.number, 0),
    voucherTemplateType: types.optional(types.number, ChannelProductVoucherTemplateType.Default),
    isShowVoucherTemplateType: types.optional(types.boolean, false),
    isSelector: types.optional(types.boolean, false),
    isLocked: types.optional(types.boolean, false),
    activeStatus: types.optional(ChannelProductActiveStatusList, ChannelProductActiveStatus.Init),
    channelProductIdentity: types.maybeNull(types.string),
    channelSalesOptionIdentities: types.optional(types.array(types.string), []),
    dataToChannelAt: types.maybeNull(types.string),
    ruleCheckResult: types.maybe(formatRuleCheckResult),
    ruleCheckStatus: types.maybeNull(types.number),
    isOfficialWebsite: types.maybeNull(types.boolean),
    finalizationResult: types.maybeNull(
      types.array(
        types.model({
          code: types.string,
          value: types.array(types.maybeNull(types.union(types.string, types.number)))
        })
      )
    ),
    payOnSiteSyncStatus: types.maybeNull(types.number),
    status: types.optional(ChannelProductStatusList, ChannelProductStatus.Init),
    ttdStatus: types.maybeNull(types.number),
    resendStatus: types.optional(ChannelProductTransferList, ChannelProductTransfer.Init),
    quotaTotalStatus: types.optional(ChannelProductTransferList, ChannelProductTransfer.Init),
    costRuleType: types.optional(types.number, ChannelCostRuleType.Default),
    channelProductTags: types.optional(types.array(types.string), []),
    subProductTags: types.optional(types.array(types.string), []),
    reservePublishSetting: types.maybeNull(types.optional(ReservePublishSetting, {})),
    allowEditLockedMarkets: types.maybeNull(types.boolean),
    lockedMarkets: types.optional(types.array(types.string), []),
    hasLockedMarkets: types.optional(types.number, CommonOptionType.Off),
    _pricingType: ChannelPricingTypeList,
    _salesOptions: types.optional(types.array(ChannelProductSalesOption), []),
    _extrasConfig: types.maybeNull(types.frozen()),
    _extraOptionList: types.maybeNull(types.frozen()),
    _specOptionList: types.maybeNull(types.frozen()),
    _reservePublishSetting: types.maybeNull(types.frozen())
  })
  .preProcessSnapshot((snapshot) => {
    if (_.isNumber(snapshot.status)) {
      snapshot.status = statusMapping[snapshot.status]
    }
    if (_.isNumber(snapshot.activeStatus)) {
      snapshot.activeStatus = activeStatusMapping[snapshot.activeStatus]
    }
    const defaultPricingType =
      snapshot.source === ProductSource.Channel
        ? ChannelPricingType.Fixed
        : ChannelPricingType.Percentage
    snapshot._pricingType = snapshot.pricingType || defaultPricingType
    snapshot._salesOptions = snapshot.salesOptions
    return snapshot
  })
  .volatile((self) => ({
    _detailLoaded: false,
    _detailLoading: false,
    _productLoaded: false
  }))
  .views((self) => ({
    get salesOptions() {
      if (!self._detailLoaded) {
        setTimeout(() => self.loadDetail(), 0)
      }
      if (self.product && self.product.salesOptions) {
        const formatSalesOption = _.map([...self.product.salesOptions.values()], (each) => {
          const { pricePolicyConfig, uuid, ...rest } = each
          const salesOptionConfig = _.get(
            _.filter(self._salesOptions, (each) => each.salesOptionUuid === uuid),
            '0',
            []
          )
          const formatPricePolicyConfig = _.map(pricePolicyConfig, (p) => {
            const { pricePolicy } = p
            return {
              policyUuid: pricePolicy.policyUuid,
              prices: _.map([...pricePolicy.prices.values()], (each) => each)
            }
          })
          return {
            salesOptionUuid: uuid,
            mapping: salesOptionConfig?.mapping,
            channelSalesOptionItemOid: salesOptionConfig?.channelSalesOptionItemOid || '',
            channelSalesOptionIdentity: _.get(salesOptionConfig, 'channelSalesOptionIdentity', ''),
            pricingTypeAmount: _.get(salesOptionConfig, 'pricingTypeAmount', 0),
            pricePolicies:
              _.size(_.get(salesOptionConfig, 'pricePolicies')) > 0
                ? salesOptionConfig.pricePolicies
                : formatPricePolicyConfig,
            sessionList: salesOptionConfig?.sessionList,
            ...rest
          }
        })
        return formatSalesOption
      } else {
        return []
      }
    },
    get filteredSalesOptions() {
      const salesOptions = _.isEmpty(self._salesOptions) ? self.salesOptions : self._salesOptions
      return _.isEmpty(salesOptions)
        ? []
        : salesOptions.filter((salesOption) => !_.isEmpty(salesOption.pricePolicies))
    },
    get extras() {
      if (!self._detailLoaded) {
        setTimeout(() => self.loadDetail(), 0)
      }
      return self._extrasConfig
    },
    get pricingType() {
      if (!self._detailLoaded) {
        setTimeout(() => self.loadDetail(), 0)
      }
      return self._pricingType
    },
    get product() {
      const { productStore } = getRoot(self)
      const product = productStore.products.get(self.productUuid)
      if (typeof product === 'undefined') {
        setTimeout(() => self.loadProduct(), 0)
      } else {
        return product
      }
    },
    get switchStatus() {
      return [ChannelProductStatus.Posted].includes(self.status)
    },
    get extraOptionList() {
      return self._extraOptionList
    },
    get specOptionList() {
      return self._specOptionList
    },
    get frozenReservePublishSetting() {
      return self._reservePublishSetting
    }
  }))
  .actions((self) => {
    const { productStore, channelStore } = getRoot(self)

    const loadDetail = flow(function* loadDetail({
      skipIsLoading = false,
      ruleCheckType = ''
    } = {}) {
      if (self._detailLoading && !skipIsLoading) {
        return
      }
      self._detailLoading = true
      try {
        const result = yield getEnv(self).api.get(
          `/${self.source}/${self.channelUuid}/product/${self.productUuid}${
            self.channelUuid === ChannelUuid.MySite && !!ruleCheckType
              ? `?ruleCheckType=${ruleCheckType}`
              : ''
          }`
        )[0]
        const channelKey = [...channelStore[`${self.source}s`].values()].filter(
          (eachChannel) => eachChannel.uuid === self.channelUuid
        )[0].key
        const isChannelPricingTypeBelongsToPercentage =
          self.source !== ProductSource.Channel ||
          [ChannelType.KKDAYMKP, ChannelType.ACTIVITYJAPAN].includes(channelKey)
        const defaultPricingType = isChannelPricingTypeBelongsToPercentage
          ? ChannelPricingType.Percentage
          : ChannelPricingType.Fixed

        Object.assign(self, {
          identityType: result?.data?.identityType,
          resendStatus: transferStatusMapping[result?.data?.resendStatus],
          invoiceType: _.get(result, 'data.invoiceType', 0),
          isSelector: _.get(result, 'data.isSelector', true),
          isLocked: _.get(result, 'data.isLocked', false),
          activeStatus: activeStatusMapping[result?.data?.activeStatus],
          dataToChannelAt:
            _.get(result, 'data.dataToChannelAt', null) ||
            _.get(result, 'data.dataToDistributorAt', null),
          ruleCheckResult: _.get(result, 'data.ruleCheckResult', ''),
          ruleCheckStatus: _.get(result, 'data.ruleCheckStatus', 0),
          cover: _.get(result, 'data.cover', ''),
          priceSettingType: _.get(result, 'data.priceSettingType', PriceSettingType.CUSTOM),
          ttdOptionCategory: _.get(result, 'data.ttdOptionCategory', []),
          ttdRelationType: _.get(result, 'data.ttdRelationType', null),
          placeId: _.get(result, 'data.placeId', []),
          isOfficialWebsite: _.get(result, 'data.isOfficialWebsite', false),
          finalizationResult: _.get(result, 'data.finalizationResult', null),
          voucherTemplateType: _.get(
            result,
            'data.voucherTemplateType',
            ChannelProductVoucherTemplateType.Default
          ),
          isShowVoucherTemplateType: _.get(result, 'data.isShowVoucherTemplateType', false),
          costRuleType: _.get(result, 'data.costRuleType', ChannelCostRuleType.Default),
          channelProductTags: _.get(result, 'data.channelProductTags', []),
          subProductTags: _.get(result, 'data.subProductTags', []),
          lockedMarkets: _.get(result, 'data.lockedMarkets', []),
          allowEditLockedMarkets: _.get(result, 'data.allowEditLockedMarkets', true),
          hasLockedMarkets: _.isEmpty(_.get(result, 'data.lockedMarkets', []))
            ? CommonOptionType.Off
            : CommonOptionType.On,
          payOnSiteSyncStatus: result?.data?.payOnSiteSyncStatus,
          ...(result?.data?.config
            ? {
                _pricingType: _.get(result, 'data.config.pricingType', defaultPricingType), // AJ: ChannelPricingType.Percentage
                _salesOptions: result.data.config.salesOptions,
                status: statusMapping[result.data.status],
                quotaTotalStatus: transferStatusMapping[result.data.quotaTotalStatus],
                channelProductIdentity: result.data.config.channelProductIdentity,
                channelSalesOptionIdentities: result.data.config.channelSalesOptionIdentities
              }
            : {}),
          ...(result?.data?.extrasConfig
            ? {
                _extrasConfig: _.groupBy(result.data.extrasConfig, 'salesOptionUuid')
              }
            : {}),
          _detailLoaded: true,
          isSettingChange: result?.data?.isSettingChange
        })
      } finally {
        self._detailLoading = false
      }
    })

    const checkProductIdentity = flow(function* checkProductIdentity() {
      try {
        const result = yield getEnv(self).api.get(
          `/${self.source}/${self.channelUuid}/product/${self.productUuid}`
        )[0]
        return result?.data?.config?.channelProductIdentity
      } catch (error) {
        console.error(error)
      }
    })

    const loadExtra = flow(function* loadExtra({ productUuid, priceType }) {
      const result = yield getEnv(self).api.get('extra/1', {
        params: { productUuid, priceType }
      })[0]
      if (result?.data) {
        self._extraOptionList = result?.data?.list
      }
    })

    const loadProductSpecOption = flow(
      function* loadProductSpecOption(productIdentity, salesOptionIdentity) {
        const queryString = qs.stringify(
          {
            productOid: productIdentity,
            packageOid: salesOptionIdentity
          },
          {
            skipNull: true
          }
        )
        const url = `/channel/${self.channelUuid}/channelSpec${
          _.isEmpty(productIdentity) && _.isEmpty(salesOptionIdentity) ? '' : `?${queryString}`
        }`
        const result = yield getEnv(self).api.get(url)[0]
        if (result?.data) {
          self._specOptionList = {
            ...self._specOptionList,
            ...result?.data
          }
        }
      }
    )

    const updateStatus = flow(function* updateStatus(status, skipErrorHandle = false) {
      const nextStatus = statusMapping[status]
      if (!nextStatus) {
        throw new Error('wrong status')
      }
      try {
        switch (self.source) {
          case ProductSource.Distributor:
            yield getEnv(self).api.put(`/distributor/${self.channelUuid}/product/status`, {
              productUuids: [self.productUuid],
              status: nextStatus
            })[0]
            break
          case ProductSource.Channel:
          default:
            yield getEnv(self).api.put(
              `/channel/${self.channelUuid}/product/${self.productUuid}/switch`,
              {
                status: nextStatus
              },
              { skipErrorHandle }
            )[0]
            break
        }
      } finally {
        yield self.loadDetail()
      }
    })

    const updateActiveStatus = flow(function* updateActiveStatus(activeStatus) {
      const nextActiveStatus = activeStatusMapping[activeStatus]
      try {
        if (!nextActiveStatus) {
          throw new Error('wrong activeStatus')
        }
        yield getEnv(self).api.put(
          `/channel/${self.channelUuid}/product/${self.productUuid}/active`,
          {
            activeStatus: nextActiveStatus
          }
        )[0]
      } catch (e) {
        console.error(e)
      } finally {
        yield self.loadDetail()
      }
    })

    const updateChannelProductStatus = flow(function* resend(payload) {
      yield getEnv(self).api.put(
        `/channel/${self.channelUuid}/product/${self.productUuid}`,
        payload
      )[0]
      yield self.loadDetail()
    })

    const reset = flow(function* reset({ ruleCheckType = '' } = {}) {
      yield getEnv(self).api.put(
        `/${self.source}/${self.channelUuid}/product/${self.productUuid}/reset`,
        {}
      )[0]
      yield self.loadDetail({ ruleCheckType })
      yield self.product.loadSalesOptions(true)
    })

    const resend = flow(function* resend(config) {
      const { skipErrorHandle = false } = config ?? {}
      yield getEnv(self).api.put(
        `/channel/${self.channelUuid}/product/${self.productUuid}/resend`,
        {},
        { skipErrorHandle }
      )[0]
      yield self.loadDetail()
    })

    const update = flow(function* update(
      { productCurrencyUuid, channelCurrencyUuid, isSelector, ...payload },
      reload = true
    ) {
      const requestPayload = {
        ...payload,
        isSelector,
        salesOptions: _.map(payload.salesOptions, (salesOption, salesOptionUuid) => {
          const isPercentagePricingType = payload.pricingType === ChannelPricingType.Percentage
          return {
            ...salesOption,
            salesOptionUuid,
            channelSalesOptionItemOid: salesOption?.channelSalesOptionItemOid || '',
            pricingTypeAmount: +_.trim(salesOption?.pricingTypeAmount),
            pricePolicies: _.map(salesOption.pricePolicies, (pricePolicy, pricePolicyUuid) => {
              return {
                pricePolicyUuid,
                prices: _.map(
                  pricePolicy,
                  ({
                    sellPrice,
                    specOptionUuid,
                    specOptionUuidSelector,
                    skuOid,
                    ...priceConfig
                  }) => {
                    const targetPrice =
                      self.costRuleType === ChannelCostRuleType.Commission
                        ? priceConfig.realPrice
                        : isPercentagePricingType
                        ? self.source === ProductSource.Distributor
                          ? 0
                          : formatCurrency(
                              channelCurrencyUuid,
                              exchangeRateObj(
                                productCurrencyUuid,
                                channelCurrencyUuid,
                                (+sellPrice * (100 - +salesOption?.pricingTypeAmount)) / 100,
                                false
                              )?.price
                            )
                        : priceConfig.price
                    const formattedPrice = _.isString(targetPrice)
                      ? targetPrice.replaceAll(',', '')
                      : targetPrice
                    const rawSkuOid =
                      skuOid ?? (isSelector ? specOptionUuidSelector : specOptionUuid)
                    return {
                      ...priceConfig,
                      skuOid: rawSkuOid === 'NONE' ? '' : rawSkuOid,
                      ...(self.costRuleType === ChannelCostRuleType.Commission
                        ? { realPrice: _.toNumber(formattedPrice) || 0 }
                        : { price: _.toNumber(formattedPrice) || 0 })
                    }
                  }
                )
              }
            })
          }
        })
      }
      yield getEnv(self).api.put(
        `/${self.source}/${self.channelUuid}/product/${self.productUuid}`,
        requestPayload
      )[0]
      if (reload) {
        yield self.loadDetail()
      }
    })

    const mapping = flow(function* mapping() {
      const result = yield getEnv(self).api.put(
        `/channel/${self.channelUuid}/product/${self.productUuid}/mapping`
      )[0]
      return result
    })

    const updateQuotaTotal = flow(function* updateQuotaTotal() {
      yield getEnv(self).api.put(
        `/channel/${self.channelUuid}/product/${self.productUuid}/quotaTotal`
      )[0]
      yield self.loadDetail()
    })

    const checkProductRule = flow(function* checkProductRule({ ruleCheckType = '' } = {}) {
      try {
        yield getEnv(self).api.get(
          `/channel/${self.channelUuid}/product/${self.productUuid}/ruleCheck?identityType=${self.identityType}`
        )[0]
      } catch (error) {
        console.error(error)
      } finally {
        self.loadDetail({ skipIsLoading: true, ruleCheckType })
      }
    })

    const extendCalendar = flow(function* extendCalendar({ type, salesOptionUuid }) {
      const result = yield getEnv(self).api.post(
        `/channel/${self.channelUuid}/product/${self.productUuid}/extend`,
        { type, salesOptionUuid }
      )[0]

      if (result.errorCode === APIErrorCode.Success) {
        self._salesOptions
          .filter((option) => option.salesOptionUuid === salesOptionUuid)
          .map((extendSalesOption) => {
            extendSalesOption.extendStatus = ChannelSalesOptionExtendStatus.Processing
            return extendSalesOption
          })
      }
    })

    const loadReservePublishSetting = flow(function* loadSessionReservePublishData() {
      try {
        const result = yield getEnv(self).api.get(
          `/channel/${self.channelUuid}/product/${self.productUuid}/publishInfo`
        )[0]

        const { publishSetting, reserveSetting } = result.data
        const now = Date.now()
        const reservePublishSetting = Object.assign(
          {},
          {
            publishSetting: {
              list: publishSetting.list.map((setting) => ({
                ...setting,
                checked: setting.publishStatus,
                disabled:
                  reserveSetting
                    ?.map(
                      ({ reserveTs, salesOptionUuids }) =>
                        now < +reserveTs * 1000 &&
                        salesOptionUuids?.includes(setting.salesOptionUuid)
                    )
                    .some((isReserved) => isReserved === true) ?? false
              })),
              lastUpdateTs: publishSetting.lastUpdateTs
            },
            reserveSetting
          }
        )

        self.reservePublishSetting = reservePublishSetting
        self._reservePublishSetting = reservePublishSetting
      } catch (error) {
        console.error(error)
      }
    })

    const updateReservePublishSetting = flow(function* updateReservePublishSetting(settings) {
      self.reservePublishSetting = _.cloneDeep(settings)
    })

    const postReservePublish = flow(function* reservationPublishSession(setting) {
      const { publishSetting } = setting
      const nextPublishSettingList = publishSetting.list.map((listItem) => ({
        salesOptionUuid: listItem.salesOptionUuid,
        publishStatus: listItem.publishStatus
      }))

      yield getEnv(self).api.post(
        `/channel/${self.channelUuid}/product/${self.productUuid}/publishInfo`,
        {
          publishSetting: nextPublishSettingList,
          reserveSetting: setting.reserveSetting
        }
      )[0]

      self.reservePublishSetting = setting
      self._reservePublishSetting = setting
    })

    const clearLockedMarkets = function clearLockedMarkets() {
      self.lockedMarkets = []
    }

    return {
      loadDetail,
      loadProduct() {
        if (!self._productLoaded) {
          productStore.loadProduct(self.productUuid)
          self._productLoaded = true
        }
      },
      publish(config) {
        const { skipErrorHandle = false } = config ?? {}
        if ([ChannelProductStatus.Posted, ChannelProductStatus.Pending].includes(self.status)) {
          return
        }
        if (!self.pricingType) {
          throw new Error('not ready yet')
        }
        updateStatus(ChannelProductStatus.Posted, skipErrorHandle)
      },
      suppress() {
        updateStatus(ChannelProductStatus.Pulled)
      },
      release() {
        const isNotAllowedToRelease =
          [ChannelType.MySite, ChannelType.KKDAY, ChannelType.KKDAYMKP].includes(self.key) &&
          ![
            ChannelProductStatus.Posted,
            ChannelProductStatus.Pulled,
            ChannelProductStatus.Mapped
          ].includes(self.activeStatus)
        if (isNotAllowedToRelease) return
        if (!self.pricingType) {
          throw new Error('not ready yet')
        }
        updateActiveStatus(ChannelProductActiveStatus.On)
      },
      unRelease() {
        updateActiveStatus(ChannelProductActiveStatus.Off)
      },
      reset,
      resend,
      update,
      mapping,
      updateQuotaTotal,
      updateChannelProductStatus,
      loadExtra,
      loadProductSpecOption,
      checkProductRule,
      extendCalendar,
      loadReservePublishSetting,
      updateReservePublishSetting,
      postReservePublish,
      clearLockedMarkets,
      checkProductIdentity
    }
  })

const Distributor = types
  .model('Distributor', {
    uuid: types.string,
    key: types.identifier,
    source: types.string,
    status: types.optional(ChannelStatusList, ChannelStatus.Off),
    travelAgentLicenceNumber: types.maybeNull(types.string),
    webhookSetting: types.array(
      types.model({
        key: types.enumeration(['VOUCHER', 'REDEEM']),
        isActive: types.optional(types.boolean, false),
        webhookUrl: types.string
      })
    ),
    title: types.maybeNull(types.string),
    distributorName: types.maybeNull(types.string),
    contactFirstName: types.maybeNull(types.string),
    contactLastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phoneITI: types.maybeNull(types.number),
    phone: types.maybeNull(types.string),
    mobileITI: types.maybeNull(types.number),
    mobile: types.maybeNull(types.string),
    isInner: types.maybeNull(types.boolean),
    distributorNo: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    currencyUuid: types.maybeNull(types.string),
    disableCurrency: types.optional(types.boolean, false),
    defaultProfitRate: types.optional(types.number, 10),
    pricingTypeAmount: types.optional(types.number, 10),
    maxTransactionAmount: types.maybeNull(types.string),
    productCount: types.optional(types.number, 0),
    _products: types.map(ChannelProduct),
    isLoading: false,
    page: types.optional(Page, { source: 'products' }),
    reconciliationType: types.maybeNull(types.number),
    reconciliationDay: types.maybeNull(types.number),
    autoDefaultProfitRate: types.optional(types.boolean, false),
    apiToken: types.maybeNull(types.string),
    isApiTokenActive: types.maybeNull(types.boolean),
    apiTokenRecords: types.array(
      types.model({
        uuid: types.maybeNull(types.string),
        storeUuid: types.maybeNull(types.string),
        distributorUuid: types.maybeNull(types.string),
        accountUuid: types.maybeNull(types.string),
        actionCategory: types.enumeration(['GENERATE_API_TOKEN', 'API_TOKEN_ACTIVE']),
        actionSource: types.maybeNull(types.string),
        actionPayload: types.union(
          types.model({
            apiToken: types.maybeNull(types.string),
            oldToken: types.maybeNull(types.string),
            new: types.maybeNull(types.boolean),
            old: types.maybeNull(types.boolean),
            org: types.maybeNull(types.boolean)
          }),
          types.frozen()
        ),
        actionTs: types.maybeNull(types.number),
        createdAt: types.maybeNull(types.string),
        updatedAt: types.maybeNull(types.string),
        accountFirstName: types.maybeNull(types.string),
        accountLastName: types.maybeNull(types.string)
      })
    ),
    report: types.array(
      types.model({
        key: types.string,
        label: types.string
      })
    )
  })
  .volatile((self) => ({
    _productsLoaded: false
  }))
  .views((self) => ({
    get products() {
      if (!self._productsLoaded) {
        setTimeout(() => self.loadAllProducts(), 0)
      }

      return self._products
    },
    get contactName() {
      const formatedContactName = `${self.contactLastName ? `${self.contactLastName} ` : ''}${
        self.contactFirstName
      }`
      return formatedContactName || '-'
    },
    get phoneNumber() {
      const formatedPhone = `${self.phoneITI ? `+${self.phoneITI} ` : ''}${self.phone || ''}`
      return formatedPhone || '-'
    },
    get mobileNumber() {
      const formatedMobile = `${self.mobileITI ? `+${self.mobileITI} ` : ''}${self.mobile || ''}`
      return formatedMobile || '-'
    }
  }))
  .actions((self) => {
    const changeStatus = flow(function* changeStatus(activeStatus) {
      const result = yield getEnv(self).api.put(`/distributor/${self.uuid}/switch`, {
        activeStatus
      })[0]
      if (result.errorCode === APIErrorCode.Success) {
        self.status = activeStatus === 1 ? ChannelStatus.On : ChannelStatus.Off
        yield self.loadInfo()
      }
    })

    const putProduct = ({ uuid: productUuid, renew = false, status, coverUrl }) => {
      self._products.put({
        source: ProductSource.Distributor,
        renew: !!renew,
        channelUuid: self.uuid,
        productUuid,
        coverUrl,
        status
      })
      return productUuid
    }

    const loadAllProducts = flow(function* loadAllProducts() {
      self._productsLoaded = true
      const result = yield getEnv(self).api.get(
        `/distributor/${self.uuid}/product/1?${qs.stringify({
          page: 1,
          status: '',
          text: '',
          num: 999
        })}`
      )[0]
      const products = getAsArray(result, 'data.data.list')
      products.map(putProduct)
    })

    const loadTargetProduct = flow(function* loadTargetProduct(productUuid) {
      self._productsLoaded = true
      const result = yield getEnv(self).api.get(
        `/distributor/${self.uuid}/product/1?${qs.stringify({
          page: 1,
          productUuid,
          num: 1
        })}`
      )[0]
      const products = getAsArray(result, 'data.data.list')
      products.map(putProduct)
    })

    const loadPage = flow(function* loadPage(page = 1, filters) {
      self.isLoading = true
      page = Math.max(1, page)
      const result = yield getEnv(self).api.get(
        `/distributor/${self.uuid}/product/${page}?${qs.stringify({
          status: '',
          text: '',
          ...filters
        })}`
      )[0]
      const products = getAsArray(result, 'data.data.list')
      self.page.list.replace(products.map(putProduct))
      self.page.currentPage = _.get(result, 'data.data.currentPage')
      self.page.totalCount = _.get(result, 'data.data.totalCount')
      self.page.itemPerPage = _.get(result, 'data.data.itemPerPage')
      self.isLoading = false
    })

    const loadInfo = flow(function* loadInfo() {
      self.isLoading = true
      const result = yield getEnv(self).api.get(`/distributor/${self.uuid}`)[0]
      if (result.data) {
        const distributor = result.data
        const {
          relDistributorNDStoreUuid,
          key,
          storeUuid,
          distributorUuid,
          distributorNo,
          activeStatus,
          defaultProfitRate = 10,
          isInner,
          distributorName,
          disableCurrency,
          report,
          webhookSetting,
          ...rest
        } = distributor
        Object.assign(self, {
          ...rest,
          key: distributorUuid,
          title: distributorName,
          distributorName,
          uuid: distributorUuid,
          status: activeStatus === 1 ? ChannelStatus.On : ChannelStatus.Off,
          pricingTypeAmount: defaultProfitRate,
          defaultProfitRate,
          isInner: isInner === 1,
          disableCurrency: disableCurrency === 1,
          report: _.map(report, ({ key }) => {
            return { label: key, key }
          }),
          webhookSetting: Object.keys(webhookSetting).map((eachWebhookSettingKey) => ({
            key: eachWebhookSettingKey,
            ...webhookSetting[eachWebhookSettingKey]
          }))
        })
        self.isLoading = false
      }
    })

    const loadTokenRecords = flow(function* loadTokenRecords() {
      const result = yield getEnv(self).api.get(`/distributor/${self.uuid}/actionHistory`)[0]
      if (result.data) {
        self.apiTokenRecords = result.data
      }
    })

    const deleteProduct = flow(function* deleteProduct(productUuid) {
      const result = yield getEnv(self).api.put(
        `/distributor/${self.uuid}/product/${productUuid}/unlink`
      )[0]
      destroy(self._products.get(productUuid))
      return result
    })

    const addProducts = flow(function* addProducts(productUuid) {
      const result = yield getEnv(self).api.post(`/distributor/${self.uuid}/product/link`, {
        productUuids: [productUuid]
      })[0]
      if (result.errorCode === APIErrorCode.Success) {
        _.difference([productUuid], [...self.products.keys()]).map((productUuid) => {
          self._products.put({
            source: ProductSource.Distributor,
            channelUuid: self.uuid,
            productUuid
          })
        })
        return result
      }
    })

    const putDistributorSetting = flow(function* putDistributorSetting(params) {
      const result = yield getEnv(self).api.put(`/distributor/${self.uuid}`, params)[0]
      if (result.errorCode === APIErrorCode.Success) {
        self.loadInfo()
      }
    })

    const activeApiToken = flow(function* activeApiToken(active = false) {
      const result = yield getEnv(self).api.put(`/distributor/${self.uuid}/apiToken/active`, {
        isApiTokenActive: active
      })[0]
      if (result.errorCode === APIErrorCode.Success) {
        yield self.loadTokenRecords()
        self.isApiTokenActive = active
      }
    })

    const sendApiTokenEmail = flow(function* sendApiTokenEmail() {
      const result = yield getEnv(self).api.post(`/mail/notifyDistributorKey/${self.uuid}`)[0]
      return result.errorCode === APIErrorCode.Success
    })

    const generateApiToken = flow(function* generateToken() {
      const result = yield getEnv(self).api.put(`/distributor/${self.uuid}/apiToken`)[0]
      if (result.errorCode === APIErrorCode.Success) {
        yield Promise.all([self.loadInfo(), self.loadTokenRecords()])
      }
    })

    return {
      loadPage,
      loadInfo,
      loadAllProducts,
      loadTokenRecords,
      loadTargetProduct,
      addProducts,
      deleteProduct,
      changeStatus,
      putDistributorSetting,
      activeApiToken,
      generateApiToken,
      sendApiTokenEmail
    }
  })

const ChannelActionLog = types.model('ChannelActionLog', {
  uuid: types.identifier,
  storeUuid: types.string,
  channelUuid: types.string,
  accountUuid: types.maybeNull(types.string),
  actionCategory: types.enumeration([
    'UPDATE_URL_TYPE',
    'IS_OFFICIAL_WEBSITE',
    'ISNOT_OFFICIAL_WEBSITE',
    'TTD_ENABLE',
    'TTD_DISABLE'
  ]),
  actionSource: types.string,
  actionPayload: types.frozen(),
  actionTs: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  accountFirstName: types.maybeNull(types.string),
  accountLastName: types.maybeNull(types.string),
  accountIsRobot: types.maybeNull(types.boolean),
  accountTitle: types.maybeNull(types.string)
})

const Channel = types
  .model('Channel', {
    uuid: types.string,
    key: types.identifier, // types.enumeration('ChannelKeys', ['MYSITE', 'KKDAY', 'ASOVIEW']),
    binary: types.number, // as item.value to replace formatChannelValue
    title: types.string,
    source: types.string,
    useChannelSort: types.optional(types.boolean, false),
    costRuleType: types.optional(types.number, ChannelCostRuleType.Default),
    camelCaseKey: types.maybeNull(types.string), // types.enumeration(payload.channel.key, ['KKday', 'KKdayMkp', 'Trip']
    description: types.maybeNull(types.string),
    currencyUuid: types.maybeNull(types.string),
    icon: types.maybeNull(types.string),
    initialDate: types.maybeNull(types.string),
    integrationEnable: types.frozen(),
    linkToChannel: types.maybeNull(types.string),
    ruleContent: types.maybeNull(types.string),
    status: types.optional(ChannelStatusList, ChannelStatus.Off),
    meta: types.frozen(),
    connectInfo: types.frozen(),
    productList: types.frozen(),
    reports: types.array(
      types.model({
        key: types.string,
        label: types.string
      })
    ),
    pricingType: types.optional(ChannelPricingTypeList, ChannelPricingType.Fixed),
    pricingTypeAmount: types.optional(types.number, 0),
    productCount: types.optional(types.number, 0),
    isLoading: false,
    isProductListLoading: false,
    connectConfig: types.frozen(),
    reconciliationType: types.maybeNull(types.number),
    reconciliationDay: types.maybeNull(types.string),
    allowPayOnSite: types.maybeNull(types.boolean),
    allowedProductIdentityType: types.optional(types.array(types.string), [
      ChannelIdentityType.Connection
    ]),
    allowedProductStatusList: types.optional(types.array(types.string), [
      ChannelProductStatus.Posted,
      ChannelProductStatus.Pulled,
      ChannelProductStatus.UnavailablePost
    ]),
    ttdTagList: types.array(
      types.model({
        label: types.string,
        value: types.string
      })
    ),
    allProducts: types.map(types.model({ productUuid: types.identifier })),
    _products: types.map(ChannelProduct),
    _storeInfo: types.maybeNull(
      types.model({
        storeTitle: types.maybeNull(types.string),
        supplierLogoUrl: types.maybeNull(types.string),
        supplierName: types.maybeNull(types.string),
        supplierContactEmail: types.maybeNull(types.string),
        supplierDescription: types.maybeNull(types.string)
      })
    ),
    _actionHistory: types.array(ChannelActionLog),
    _actionHistoryLoaded: false,
    _actionHistoryLoading: false,
    _channelLockedMarkets: types.optional(
      types.array(types.model({ code: types.string, title: types.string })),
      []
    ),
    page: types.optional(Page, { source: 'products' })
  })
  .volatile((self) => ({
    _productsLoaded: false
  }))
  .views((self) => ({
    get products() {
      if (!self._productsLoaded) {
        setTimeout(() => self.loadAllProducts(), 0)
      }

      return self._products
    },
    get storeInfo() {
      return self._storeInfo
    },
    get actionHistory() {
      if (!self._actionHistoryLoaded) {
        setTimeout(self.loadActionHistory, 0)
        return []
      }
      return self._actionHistory
    },
    getProduct(productUuid) {
      return self._products.has(productUuid) ? self._products.get(productUuid) : null
    },
    getActionHistoryLoading() {
      return self._actionHistoryLoading
    },
    get channelLockedMarkets() {
      return self._channelLockedMarkets
    }
  }))
  .actions((self) => {
    function open() {
      self.status = ChannelStatus.On
    }
    function close() {
      self.status = ChannelStatus.Off
    }

    const putProduct = ({
      uuid: productUuid,
      status,
      activeStatus,
      coverUrl,
      renew,
      channelSort,
      identityType,
      invoiceType = 0,
      isSelector = true,
      ttdStatus,
      ruleCheckResult
    }) => {
      self._products.put({
        ...self._products.get(productUuid), // 拿到原本 channelProduct._detailLoaded 過的 previous state
        source: ProductSource.Channel,
        channelUuid: self.uuid,
        renew: !!renew,
        channelSort,
        invoiceType,
        isSelector,
        identityType,
        productUuid,
        coverUrl,
        status,
        activeStatus,
        ttdStatus,
        ruleCheckResult
      })
      return productUuid
    }

    // 共用來取得所有通路商品的 function
    const fetchAllProducts = flow(function* fetchAllProducts() {
      const result = yield getEnv(self).api.get(
        `/channel/${self.uuid}/product/list?${qs.stringify({
          page: 1,
          status: '',
          text: '',
          num: 999
        })}`
      )[0]
      const products = getAsArray(result, 'data.data.list')
      return products
    })

    const loadAllProducts = flow(function* loadAllProducts() {
      self._productsLoaded = true
      const products = yield fetchAllProducts()
      products.map(putProduct)
    })

    const loadAllChannelProducts = flow(function* loadAllChannelProducts() {
      const products = yield fetchAllProducts()
      _.forEach(products, (product) => {
        self.allProducts.put({ productUuid: product.uuid })
      })
    })

    const loadTargetProduct = flow(function* loadTargetProduct(productUuid) {
      self._productsLoaded = true
      const result = yield getEnv(self).api.get(
        `/channel/${self.uuid}/product/list?${qs.stringify({
          page: 1,
          productUuid,
          num: 1
        })}`
      )[0]
      const products = getAsArray(result, 'data.data.list')
      products.map(putProduct)
    })

    const loadPage = flow(function* loadPage(page = 1, filters) {
      self.isLoading = true
      page = Math.max(1, page)
      const result = yield getEnv(self).api.get(
        `/channel/${self.uuid}/product/list?${qs.stringify({
          page,
          status: '',
          text: '',
          ...filters
        })}`
      )[0]

      const products = getAsArray(result, 'data.data.list')
      self.page.list.replace(products.map(putProduct))
      self.page.currentPage = _.get(result, 'data.data.currentPage')
      self.page.totalCount = _.get(result, 'data.data.totalCount')
      self.page.itemPerPage = _.get(result, 'data.data.itemPerPage')
      self.isLoading = false
    })

    const loadProductInfo = flow(function* loadProductInfo() {
      self.isProductListLoading = true
      const result = yield getEnv(self).api.get(`/channel/${self.uuid}/channelProduct`)[0]
      if (result.data) {
        const thirdPartyProductIsEqualToRezioSalesOptions = [
          ChannelType.ACTIVITYJAPAN,
          ChannelType.JALAN
        ].includes(self.key)
        const formatedProductList = _.map(result.data?.list, (product, key) => {
          const { uuid, salesOptions, storeUuid, ...rest } = product
          const salesOptionsUsage = thirdPartyProductIsEqualToRezioSalesOptions
            ? result.data?.list
            : salesOptions
          return {
            ...rest,
            uuid: _.toString(uuid),
            salesOptions: _.map(salesOptionsUsage, (so) => ({
              uuid: _.toString(so.uuid),
              title: so.title
            }))
          }
        })
        Object.assign(self, { productList: formatedProductList })
      }
      self.isProductListLoading = false
    })

    const loadChannelStoreInfo = flow(function* loadChannelStoreInfo() {
      const result = yield getEnv(self).api.get(`/channel/${self.uuid}/store/detail`)[0]
      self._storeInfo = _.get(result, 'data')
    })

    const updateChannelStoreInfo = flow(function* updateChannelStoreInfo(payload) {
      yield getEnv(self).api.post(`/channel/${self.uuid}/store/detail`, payload)[0]
      yield self.loadChannelStoreInfo()
    })

    const loadInfo = flow(function* loadInfo() {
      const result = yield getEnv(self).api.get(`/channel/${self.uuid}`)[0]
      if (result.data) {
        const channel = result.data
        const { key, storeConnectStatus, report, ...rest } = channel
        const [, status] = formatStatus(+storeConnectStatus).status
        const {
          productIdentityType: allowedProductIdentityType,
          productStatusList: allowedProductStatusList
        } = formatChannelAllowedSpec(key.toUpperCase())

        Object.assign(self, {
          ...rest,
          key: channel.key.toUpperCase(),
          camelCaseKey: channel.key,
          status,
          allowedProductIdentityType,
          allowedProductStatusList,
          pricingTypeAmount: channel.defaultProfitRate,
          reports: _.map(report, (eachReport) => {
            return { key: eachReport.key, label: eachReport.label || eachReport.key }
          })
        })
      }
    })
    const loadActionHistory = flow(function* loadChannelStoreInfo() {
      if (self._actionHistoryLoading) {
        return
      }
      self._actionHistoryLoading = true
      const result = yield getEnv(self).api.get(`/channel/${self.uuid}/actionHistory`)[0]
      self._actionHistory = _.get(result, 'data')
      self._actionHistoryLoaded = true
      self._actionHistoryLoading = false
    })

    const loadChannelLockedMarkets = flow(function* loadChannelLockedMarkets() {
      try {
        const result = yield getEnv(self).api.get(`/channel/${self.uuid}/markets`)[0]
        self._channelLockedMarkets = result.data
      } catch (e) {
        console.error(e)
      }
    })

    const loadTagList = flow(function* loadTagList() {
      try {
        const result = yield getEnv(self).api.get(`/channel/${self.uuid}/ttdTag/list`)[0]
        self.ttdTagList = _.get(result, 'data.list')
      } catch (error) {
        console.error('error', error)
      }
    })

    const loadPlaceId = flow(function* loadTagList(productUuid) {
      try {
        yield getEnv(self).api.get(`/channel/${self.uuid}/product/${productUuid}/placeId`)[0]
      } catch (error) {
        console.error('error', error)
      }
    })

    const updateChannelStatus = flow(function* updateChannelStatus(payload) {
      yield getEnv(self).api.post(`/channel/${self.uuid}/action`, payload)[0]
      yield self.loadInfo()
    })

    const updateChannelStoreUtmSourceTTD = flow(function* updateChannelStoreUtmSourceTTD(payload) {
      yield getEnv(self).api.put('/store/utmSource/TTD', payload)[0]
      yield self.loadInfo()
    })

    const init = flow(function* init(payload) {
      if ([ChannelStatus.On, ChannelStatus.Connecting].includes(self.status)) {
        return
      }
      let result
      // TODO: refactor to service
      switch (self.key) {
        case ChannelType.KLOOK:
        case ChannelType.JALAN:
        case ChannelType.GYG: {
          result = yield getEnv(self).api.post('/channel/link', {
            channelUuid: payload.channelUuid,
            user: payload.user,
            pass: payload.pass,
            currencyUuid: payload.currencyUuid,
            defaultProfitRate: +payload.defaultProfitRate,
            reconciliationType: payload.reconciliationType,
            reconciliationDay: payload.reconciliationDay
          })[0]
          break
        }
        case ChannelType.KKDAY:
        default:
          yield getEnv(self).api.post('/channel/link', {
            channelUuid: payload.channelUuid,
            oid: payload.oid,
            ...(payload?.apiKey && { apiKey: payload.apiKey }),
            currencyUuid: payload.currencyUuid,
            defaultProfitRate: +payload.defaultProfitRate,
            reconciliationType: payload.reconciliationType,
            reconciliationDay: payload.reconciliationDay
          })[0]
          break
      }
      yield self.loadInfo()
      return result
    })

    const registerAccount = flow(function* registerAccount(payload) {
      if ([ChannelStatus.Connecting].includes(self.status)) {
        return
      }
      let result
      try {
        const { channelUuid, ...rest } = payload
        result = yield getEnv(self).api.post(`/channel/${channelUuid}/action`, rest)[0]
        setTimeout(() => {
          self.loadInfo()
          self.loadProductInfo()
        }, 500)
      } catch (error) {
        console.error('error', error)
      }
      return result
    })

    const addProducts = flow(function* addProducts(productUuid, identityType) {
      // _.difference(productUuids, [...self.products.keys()]).map(addProduct)
      self.allProducts.put({ productUuid }) // 呼叫完 API 就已經加入 channelProduct，不論是否符合通路規範
      const result = yield getEnv(self).api.post(`/channel/${self.uuid}/product/link`, {
        productUuids: [productUuid],
        identityType
      })[0]
      if (result.errorCode === APIErrorCode.Success) {
        _.difference([productUuid], [...self.products.keys()]).map((productUuid) => {
          self._products.put({
            channelUuid: self.uuid,
            productUuid,
            source: ProductSource.Channel
          })
        })
        return result
      }
    })

    const deleteProduct = flow(function* deleteProduct(productUuid) {
      const result = yield getEnv(self).api.put(
        `/channel/${self.uuid}/product/${productUuid}/unlink`
      )[0]
      destroy(self._products.get(productUuid))
      self.allProducts.delete(productUuid)
      return result
    })

    const updateProductSort = flow(function* updateProductSort(sortableList) {
      const result = yield getEnv(self).api.put(`/channel/${self.uuid}/product/sort`, {
        sort: sortableList
      })[0]
      if (result.errorCode === APIErrorCode.Success) {
        return sortableList
      }
    })

    const captureProductList = flow(function* captureProductList() {
      self.isProductListLoading = true
      yield getEnv(self).api.post(`/channel/${self.uuid}/captureProductList`)[0]
      setTimeout(() => {
        self.loadInfo()
        self.loadProductInfo()
      }, 500)
    })

    const syncProduct = flow(function* syncProduct(productUuid, rest) {
      const { beginDate, country, currency, locale } = rest
      const result = yield getEnv(self).api.post(`/channel/${self.uuid}/syncProduct`, {
        channelProductId: productUuid,
        beginDate,
        country,
        currency,
        locale
      })[0]
      if (result.errorCode === APIErrorCode.Success) {
        yield self.loadProductInfo()
        return 'success'
      } else {
        return 'failed'
      }
    })

    const updateChannelInfo = flow(function* updateChannelInfo(payload) {
      yield getEnv(self).api.put(`/channel/${self.uuid}/relStoreInfo`, payload)[0]
      yield self.loadInfo()
    })

    const loadChannelProductTagsDetail = flow(
      function* loadChannelProductTagsDetail(paramsChannelProductTags) {
        const result = yield getEnv(self).api.get(`/channel/${self.uuid}/productCommissions`, {
          params: { channelProductTags: paramsChannelProductTags.join(',') }
        })[0]
        if (result.data) {
          const { result: calculatedResult, referenceData, domestic, inbound } = result.data

          return reject(isNil, {
            domestic: domestic?.result?.rate,
            inbound: inbound?.result?.rate,
            supplierTierName:
              domestic?.referenceData?.supplier?.supplierTierName ??
              referenceData?.supplier?.supplierTierName,
            rate: calculatedResult?.rate,
            cap: !isNil(calculatedResult?.cap)
              ? `${calculatedResult?.currency} ${calculatedResult?.cap}`
              : undefined
          })
        }
      }
    )

    return {
      init,
      loadPage,
      syncProduct,
      updateChannelInfo,
      updateChannelStatus,
      captureProductList,
      loadProductInfo,
      loadChannelStoreInfo,
      updateChannelStoreInfo,
      updateChannelStoreUtmSourceTTD,
      loadInfo,
      loadActionHistory,
      loadChannelLockedMarkets,
      loadTagList,
      loadPlaceId,
      loadAllProducts,
      loadAllChannelProducts,
      loadTargetProduct,
      loadChannelProductTagsDetail,
      deleteProduct,
      updateProductSort,
      addProducts,
      registerAccount,
      open,
      close,
      afterCreate() {
        const authTier = _.get(getRoot(self), 'core.session.profile.authTier')
        checkFeatureValid(authTier, PLAN_FEATURES.CHANNEL) && self.loadInfo()
      }
    }
  })
  .preProcessSnapshot((snapshot) => {
    snapshot.productCount = _.get(snapshot, 'productCount.cnt', snapshot.productCount)
    snapshot._products = snapshot.product
    return snapshot
  })

export const ChannelStore = types
  .model('ChannelStore', {
    _channels: types.optional(types.map(Channel), {}),
    distributors: types.optional(types.map(Distributor), {}),
    distributorsPage: types.optional(Page, { source: 'distributors' })
  })
  .volatile((self) => ({
    _isChannelValid: false,
    _channelsLoaded: false,
    _channelsLoading: false,
    _isDistributorlValid: false,
    _distributorsLoaded: false,
    _distributorsLoading: false
  }))
  .views((self) => ({
    get channelTypeList() {
      if (!self._channelsLoaded) {
        setTimeout(() => self.loadChannels(), 0)
      }
      const i18n = _.get(Core, 'instance.i18n')
      const defaultProductChannelType = [
        { label: i18n.t('PRODUCT.CHANNEL_PICKER_NONE'), value: -1 }
      ]
      const channelList = _.map([...self.channels?.values()], (channel) => {
        const { key, binary } = channel
        return { label: i18n.t(`COMMON.SOURCE_${key.toUpperCase()}`), value: `${binary}` }
      })

      return [...defaultProductChannelType, ...channelList]
    },
    hasChannel(channel) {
      return this.channels.get(channel)?.status === ChannelStatus.On
    },
    get channels() {
      if (!self._channelsLoaded) {
        setTimeout(() => {
          self.loadChannels()
        }, 0)
      }
      return self._channels
    },
    getDistributor(distributorUuid) {
      self.loadDistributorInfo(distributorUuid)
    }
  }))
  .actions((self) => {
    const loadChannels = flow(function* loadChannels() {
      const authTier = _.get(getRoot(self), 'core.session.profile.authTier')
      const isChannelValid = checkFeatureValid(authTier, PLAN_FEATURES.CHANNEL)
      if (!authTier || (self._channelsLoading && isChannelValid === self._isChannelValid)) {
        return
      }
      self._channelsLoading = true
      self._isChannelValid = isChannelValid
      try {
        const apiUrl = isChannelValid ? '/channel/list?num=20' : '/channel/listAsOption'
        const result = yield getEnv(self).api.get(apiUrl)[0]
        const sortedResult = _.sortBy(getAsArray(result, 'data'), 'sort')

        sortedResult.map((channel) => {
          const { key, storeConnectStatus, ...rest } = channel
          const [, status] = formatStatus(+storeConnectStatus).status
          const {
            productIdentityType: allowedProductIdentityType,
            productStatusList: allowedProductStatusList
          } = formatChannelAllowedSpec(key.toUpperCase())

          self.channels.put({
            ...rest,
            key: channel.key.toUpperCase(),
            camelCaseKey: channel.key,
            source: ProductSource.Channel,
            status,
            allowedProductIdentityType,
            allowedProductStatusList,
            pricingTypeAmount: channel.defaultProfitRate
          })
        })
        self._channelsLoading = false
        self._channelsLoaded = true
      } catch (e) {
        self._channelsLoading = false
        self._channelsLoaded = true
      }
    })

    const setDistributor = (distributor) => {
      const {
        relDistributorNDStoreUuid,
        key,
        storeUuid,
        distributorUuid,
        activeStatus,
        defaultProfitRate = 10,
        isInner,
        email,
        distributorName,
        disableCurrency,
        report,
        webhookSetting,
        ...rest
      } = distributor
      self.distributors.put({
        ...rest,
        title: distributorName || email,
        email,
        distributorName,
        uuid: distributorUuid,
        key: distributorUuid,
        status: activeStatus === 1 ? ChannelStatus.On : ChannelStatus.Off,
        pricingTypeAmount: defaultProfitRate,
        defaultProfitRate,
        isInner: isInner === 1,
        source: ProductSource.Distributor,
        disableCurrency: disableCurrency === 1,
        report: _.map(report, ({ key }) => {
          return { label: key, key }
        }),
        ...(webhookSetting && {
          webhookSetting: Object.keys(webhookSetting)?.map((eachWebhookSettingKey) => ({
            key: eachWebhookSettingKey,
            ...webhookSetting[eachWebhookSettingKey]
          }))
        })
      })
    }

    const getDistributorsUuid = (result) => {
      return getAsArray(result, 'data.list').map((distributor) => {
        self.setDistributor(distributor)

        return distributor.distributorUuid
      })
    }

    const setFilteredDistributor = (result) => {
      const allUuids = getDistributorsUuid(result)
      self.distributorsPage.list = allUuids
      self.distributorsPage.currentPage = _.get(result, 'data.currentPage')
      self.distributorsPage.totalCount = _.get(result, 'data.totalCount')
      self.distributorsPage.itemPerPage = _.get(result, 'data.itemPerPage')
    }

    // 因分銷商列表加上分頁功能, 目前專案內已沒有使用這隻 API, 保險起見, 等到 Sprint 85 release(2022/06/08) 後再刪除
    const loadAllDistributors = flow(function* loadAllDistributors() {
      const authTier = _.get(getRoot(self), 'core.session.profile.authTier')
      const isDistributorValid = checkFeatureValid(authTier, PLAN_FEATURES.DISTRIBUTION)

      if (self._distributorsLoading && isDistributorValid === self._isDistributorlValid) {
        return
      }

      self._distributorsLoading = true
      self._isDistributorlValid = isDistributorValid

      const apiUrl = isDistributorValid
        ? `/distributor/1?${qs.stringify({ num: 999, text: '' })}`
        : '/distributor/listAsOption'
      const result = yield getEnv(self).api.get(apiUrl)[0]

      self._distributorsLoading = false

      if (!self._distributorsLoaded) {
        setFilteredDistributor(result)

        self._distributorsLoaded = true
      }
    })

    const loadDistributors = flow(function* loadDistributors(
      config = { page: 1, num: 20, text: '', activeStatus: 1 }
    ) {
      const { page = 1, ...params } = config
      const result = yield getEnv(self).api.get(
        `/distributor/${page}?${qs.stringify({
          num: 20,
          ...params
        })}`
      )[0]

      setFilteredDistributor(result)
    })

    const loadDistributorInfo = flow(function* loadDistributorInfo(distributorUuid) {
      try {
        const result = yield getEnv(self).api.get(`/distributor/${distributorUuid}`)[0]
        if (result.data) {
          const distributor = result.data
          setDistributor(distributor)
        }
      } catch (error) {
        console.error(error)
      }
    })

    return {
      setDistributor,
      loadChannels,
      loadDistributors,
      loadAllDistributors,
      loadDistributorInfo
    }
  })
