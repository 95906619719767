import AsyncStorage from '@react-native-async-storage/async-storage'
import { WDFCResponse } from '@rezio/core/api'
import Core from '@rezio/core/core'
import { formatName } from '@rezio/utils/format'
import {
  Client as TwilioConversationsClient,
  SendMediaOptions,
  ConnectionState
} from '@twilio/conversations'
import type {
  Paginator,
  Conversation as TwilioConversation,
  Message as TwilioMessage,
  Participant as TwilipoParticipant
} from '@twilio/conversations'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { autorun, computed } from 'mobx'
import {
  model,
  Model,
  modelAction,
  modelFlow,
  prop,
  _async,
  _await,
  tProp,
  types,
  objectMap
} from 'mobx-keystone'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import qs from 'query-string'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import hash from 'hash-sum'
import DataLoader from 'dataloader'

import { apiWithExtractor } from '@rezio/utils/apiWrapper'
import { QuickSearchResponse } from '@rezio/components/order/quickSearch.misc'
import { NotificationContext, type UserNotification } from '../notification'
import { useStores } from '../hooks'

declare global {
  interface Window {
    _REZIO_CONVERSATION_MANAGER_: ConversationConnection
  }
}

export interface ConversationUser {
  mainUserSid: string
  role: 'supplier' | 'kkdayMember' | string
  identity: string
  lang: string
  attributes?: {
    firstName?: string
    lastName?: string
    email?: string
    photoUrl?: string
  }
}

interface ConversationParticipant {
  mainParticipantSid: string
  mainUserSid: string
  lastReadMainMessageSid?: string
  lastReadDate?: string
  unreadMessageCount: number
}

export type MediaBody = {
  publishUrl: string // 可能不一樣
  contentType: string
  filename: string
  sid: string
}[]

interface MessageAttributes {
  orderMid?: string
  productName: string
  productImageUrl: string
  linkUrl?: string
  productMid?: string
  productUrl?: string
  orderNo?: string
}

export interface ConversationMessage {
  index?: number
  mainMessageSid: string
  mainParticipantSid: string
  mainUserSid: string
  createDate: string
  messageType: 'text' | 'image' | 'file'
  body?: string | MediaBody
  attributes?: MessageAttributes
  pending?: boolean
}

export interface MessageConversation {
  mainConversationSid: string
  ownMainParticipantSid: string
  ownMainUserSid: string
  users: Record<string, ConversationUser>
  participants: Record<string, ConversationParticipant>
  lastMessage?: ConversationMessage
  lastMainMessageSid?: string
  limit?: number
  messages?: ConversationMessage[]
}

interface ConversationListResponse {
  lastMainConversationSid: string
  limit: number
  conversations: MessageConversation[]
}

interface ConversationTokenResponse {
  mainUserSid: string
  vendor: {
    name: string
    token: string
  }
}

interface ConversationSession {
  id: string
  token: string
  vendor: 'twilio'
}

interface CreateConversationResponse {
  targetUserMapping: Record<
    string,
    {
      mainConversationSid: string
      unreadMessageCount: number
    }
  >
  totalUnreadMessageCount: number
}

interface JoinConversationResponse {
  mainConversationSid: string
  vendor: {
    name: 'twilio'
    conversationSid: string
  }
}

// message flow types
export interface MessageContent {
  type: 'message'
  id: string
  /**
   * hash, 用來判斷是否為同一個訊息
   * 目前：
   * kkday msg -> 用SID做hash
   * twilio/pending message -> 用index做hash
   */
  hash: string
  title: string
  self: boolean
  messageType: ConversationMessage['messageType']
  body: ConversationMessage['body']
  createDate: ConversationMessage['createDate']
  pending: boolean
}

export interface AttributesContent {
  type: 'attributes'
  id: string
  hash: string
  content: ConversationMessage['attributes']
}

export interface MarkContent {
  type: 'mark'
  id: string
  hash: string
  unread?: boolean
  date?: string
}

export interface ConversationUserWithParticipant {
  mainUserSid: string
  role: 'supplier' | 'kkday_member'
  identity: string
  storeUuid: string
  lang: string
  attributes: {
    firstName?: string
    lastName?: string
    email?: string
    photoUrl?: string
  }
  unreadMessageCount: number
}

export type ConversationFlow = (MessageContent | AttributesContent | MarkContent)[]

// type guards
export const isMessageContent = (
  content: MessageContent | AttributesContent | MarkContent
): content is MessageContent => content.type === 'message'
export const isAttributesContent = (
  content: MessageContent | AttributesContent | MarkContent
): content is AttributesContent => content.type === 'attributes'
export const isValidAttributes = (content: any): content is ConversationMessage['attributes'] =>
  !!content?.productName

const isSameMessage = (messageA: ConversationMessage, messageB: ConversationMessage) => {
  if (
    // 時間不同
    moment(messageA.createDate).format('x') !== moment(messageB.createDate).format('x') ||
    // 類型不同
    typeof messageA.body !== typeof messageB.body
  ) {
    return false
  }

  if (typeof messageA.body === 'string') {
    return messageB.body === messageA.body
  } else {
    const msgBodyA = messageA.body[0]
    const msgBodyB = messageB.body[0] as typeof msgBodyA
    return (
      // 檔案名相同
      msgBodyB.filename === msgBodyA.filename ||
      // 檔案名是sid開頭
      msgBodyA.filename?.startsWith?.(msgBodyB.sid) ||
      msgBodyB.filename?.startsWith?.(msgBodyA.sid)
    )
  }
}

@model('admin/Conversation')
export class Conversation extends Model({
  id: prop<string>(),
  detail: prop<MessageConversation>(null),
  active: prop<boolean>(false),
  twilioLoaded: prop<boolean>(false),
  twilioMessages: prop<ConversationMessage[]>(() => []),
  kkMessages: prop<Map<string, ConversationMessage>>(() => objectMap()),
  pendingMessages: prop<ConversationMessage[]>(() => [])
}) {
  core = Core.getInstance()

  connection: ConversationConnection

  nextAttributes: MessageAttributes

  rawTwilioMessages: Map<string, TwilioMessage> = new Map()
  lastIndex = 0

  twilioPaginator?: Paginator<TwilioMessage>

  attachingRetry: NodeJS.Timeout

  @modelFlow
  attach = _async(function* (this: Conversation) {
    try {
      console.debug('attach to conversation', this.id)
      const [joinConversationResult, client] = yield* _await(
        Promise.all([
          this.core.api.wdfcInstance.post<WDFCResponse<JoinConversationResponse>>(
            `kkday/conversation/${this.id}`
          ),
          this.connection.getClient()
        ])
      )
      this.loadDetail().catch(console.error)
      const conversation: TwilioConversation = yield* _await(
        client.getConversationBySid(joinConversationResult.data.data.vendor.conversationSid)
      )
      this.twilioConversation = conversation
      this.active = true
      const [participants] = yield* _await(Promise.all([conversation.getParticipants()]))
      this.twilioParticipants = participants

      this.loadTwilioMessages()
      console.debug('attached')
      this.twilioConversation.on('messageAdded', (message: TwilioMessage) => {
        console.debug('received message')

        this.formatTwilioMessage(message)
          .then((conversationMessage) => {
            this.addTwilioMessage(conversationMessage)
          })
          .catch(console.error)
      })

      this.markAsRead().catch(console.error)
    } catch (e) {
      console.error(e)
      this.attachingRetry = setTimeout(this.attach, 3000)
    }
  })

  @modelFlow
  loadTwilioMessages = _async(function* (this: Conversation) {
    if (this.twilioPaginator != null) {
      return
    }
    try {
      this.twilioPaginator = yield* _await(this.twilioConversation.getMessages())
      this.twilioMessages = yield* _await(
        Promise.all(this.twilioPaginator.items.map(this.formatTwilioMessage))
      )
    } catch (e) {
      console.error(e)
    }
    this.twilioLoaded = true
  })

  @modelAction
  detach() {
    console.debug('detach from conversation', this.id)
    this.twilioConversation?.removeAllListeners?.()
    clearTimeout(this.attachingRetry)
  }

  twilioConversation: TwilioConversation
  twilioParticipants: TwilipoParticipant[]
  orderNoLoader = new DataLoader<string, string>(async (orderMids) => {
    return await Promise.all(
      orderMids.map(async (orderMid) => {
        try {
          const result = await apiWithExtractor.get<QuickSearchResponse>(`order/search/1`, {
            text: orderMid,
            num: 1,
            tsDiff: -(new Date().getTimezoneOffset() * 60)
          })
          return result.list?.[0]?.orderNo
        } catch (e) {
          console.error(e)
          return null
        }
      })
    )
  })

  async sendMessage(value: string | FormData | SendMediaOptions, attributes?: Record<string, any>) {
    if (!isValidAttributes(attributes)) {
      // 使用上次對話的attributes, 延續對話內容
      attributes = this.lastAttribute
    }

    const pendingId = nanoid()
    this.addPendingMessage(pendingId, value)

    const newMessageBuilder = this.twilioConversation.prepareMessage()
    if (attributes) {
      // admin => twilio => kkday => rezio api這段都要是snake_case, 只有 rezio api => admin這段是camelCase
      // i.e. 進來資料統一轉成camelCase, 出去資料統一用snake_case
      attributes = _.mapKeys(attributes, (value, key) => _.snakeCase(key))
      newMessageBuilder.setAttributes(attributes)
    }
    if (typeof value === 'string') {
      newMessageBuilder.setBody(value)
    } else {
      newMessageBuilder.addMedia(value)
    }

    const result = await newMessageBuilder.build().send()
    this.setPendingMessageIndex(pendingId, result)
  }

  @modelAction
  addPendingMessage(id: string, value: string | FormData | SendMediaOptions) {
    this.lastIndex += 1
    this.pendingMessages.push({
      index: this.lastIndex,
      mainMessageSid: id,
      mainParticipantSid: this.me.participant.mainParticipantSid,
      mainUserSid: this.me.user.mainUserSid,
      createDate: moment().toISOString(),
      messageType:
        typeof value === 'string'
          ? 'text'
          : (value as SendMediaOptions).contentType.startsWith('image')
          ? 'image'
          : 'file',
      body: typeof value === 'string' ? value : null,
      attributes: null,
      pending: true
    })
  }

  @modelAction
  setPendingMessageIndex(id: string, index: number) {
    const pendingMessage = this.pendingMessages.find(({ mainMessageSid }) => id === mainMessageSid)
    pendingMessage.index = index
  }

  @computed
  get lastAttribute(): MessageAttributes {
    return _.findLast(this.mergedMessages, (message) => isValidAttributes(message.attributes))
      ?.attributes
  }

  @modelFlow
  loadDetail = _async(function* (this: Conversation) {
    try {
      const conversationDetailResult = yield* _await(
        this.core.api.wdfcInstance.get<WDFCResponse<MessageConversation>>(
          `kkday/conversation/${this.id}/detail`
        )
      )
      const detail = conversationDetailResult.data?.data
      if (!detail) {
        return
      }
      this.detail = detail
      detail.messages.forEach((message) => {
        const kkMessage = Conversation.formatKKMsgMessage(message)
        this.kkMessages.set(kkMessage.mainMessageSid, kkMessage)
      })
      this.hasMore = detail.messages.length === detail.limit
    } catch (e) {
      setTimeout(() => {
        if (this.active) {
          this.loadDetail().catch(console.error)
        }
      }, 3000)
      throw e
    }
  })

  hasMore: boolean

  @modelFlow
  loadHistory = _async(function* (this: Conversation) {
    const result = yield* _await(
      this.core.api.wdfcInstance.get<WDFCResponse<MessageConversation>>(
        `kkday/conversation/${this.id}/list?start_main_message_sid=${this.mergedMessages[0].mainMessageSid}`
      )
    )
    const history = result.data?.data
    if (!history) {
      return
    }
    history.messages.forEach((message) => {
      const kkMessage = Conversation.formatKKMsgMessage(message)
      this.kkMessages.set(kkMessage.mainMessageSid, kkMessage)
    })
    this.hasMore = history.messages.length === history.limit
  })

  async loadMore() {
    if (this.hasMore) {
      await this.loadHistory()
    }
  }

  static formatKKMsgMessage = (message: ConversationMessage): ConversationMessage => ({
    ...message,
    attributes: _.mapKeys(message.attributes, (value, key) => _.camelCase(key)) as any,
    createDate: moment.utc(message.createDate).toISOString()
  })

  formatTwilioMessage = async (message: TwilioMessage): Promise<ConversationMessage> => {
    const currentMainUserSid = this.twilioParticipants.find(
      (participant) => participant.sid === message.participantSid
    ).identity
    const participant = Object.values(this.detail.participants).find(
      (p) => p.mainUserSid === currentMainUserSid
    )

    this.rawTwilioMessages.set(message.sid, message)
    this.lastIndex = Math.max(this.lastIndex, message.index)
    const attributes = _.mapKeys(message.attributes as any, (value, key) => _.camelCase(key)) as any
    if (attributes.orderMid && !attributes.orderNo) {
      attributes.orderNo = await this.orderNoLoader.load(attributes.orderMid)
    }
    return {
      index: message.index,
      mainMessageSid: message.sid,
      mainParticipantSid: participant.mainParticipantSid,
      mainUserSid: currentMainUserSid,
      createDate: message.dateCreated.toISOString(),
      messageType:
        message.type === 'text'
          ? 'text'
          : message.attachedMedia[0].contentType.startsWith('image')
          ? 'image'
          : 'file',
      body:
        message.type === 'text'
          ? message.body
          : [
              {
                publishUrl: await message.attachedMedia[0].getContentTemporaryUrl(),
                contentType: message.attachedMedia[0].contentType,
                filename: message.attachedMedia[0].filename,
                sid: message.attachedMedia[0].sid
              }
            ],
      attributes
    }
  }

  @modelFlow
  markAsRead = _async(function* (this: Conversation) {
    if (this.me.participant.unreadMessageCount === 0) {
      return
    }
    yield* _await(
      this.core.api.wdfcInstance.post<WDFCResponse<MessageConversation>>(
        `kkday/conversation/${this.id}/readAllMessages`
      )
    )
    this.me.participant.unreadMessageCount = 0
  })

  @modelAction
  addTwilioMessage = (message: ConversationMessage) => {
    this.twilioMessages = [...this.twilioMessages, message]
  }

  @modelFlow
  revokePublishUrl = _async(function* (this: Conversation, message: MessageContent) {
    const rawMessage = this.rawTwilioMessages.get(message?.id)
    const twilioMessage = this.twilioMessages.find(
      ({ mainMessageSid }) => mainMessageSid === message.id
    )
    if (!twilioMessage || typeof twilioMessage?.body === 'string' || !rawMessage) {
      return null
    }

    const nextUrl = yield* _await(rawMessage.attachedMedia[0].getContentTemporaryUrl())
    twilioMessage.body[0].publishUrl = nextUrl
    return nextUrl
  })

  @computed
  get kkMessagesSortByDate() {
    return _.sortBy([...this.kkMessages.values()], 'createDate')
  }

  @computed
  get me() {
    if (!this.detail) {
      return null
    }
    const user = Object.values(this.detail.users).find((user) => user.role === 'supplier')
    const participant = Object.values(this.detail.participants).find(
      (participant) => participant.mainUserSid === user.mainUserSid
    )
    return { user, participant }
  }

  @computed
  get mergedMessages(): ConversationMessage[] {
    const apiMessages = this.kkMessagesSortByDate
    try {
      const twilioMessages = this.twilioMessages ?? []
      const cursor = twilioMessages.length > 0 ? twilioMessages[0] : null
      const index = cursor
        ? apiMessages.findLastIndex((message) => isSameMessage(message, cursor))
        : undefined
      const latestIndex =
        twilioMessages.length > 0 ? twilioMessages[twilioMessages.length - 1]?.index : -1
      return [
        ...apiMessages.slice(0, index),
        ...twilioMessages,
        ...this.pendingMessages.filter(({ index }) => index == null || index > latestIndex)
      ]
    } catch (e) {
      // 有問題的話至少要顯示歷史訊息
      console.error(e)
      return apiMessages
    }
  }

  isSameAttribute(a: MessageAttributes, b: MessageAttributes): boolean {
    if (!a || !b) {
      return false
    }
    if (a.orderMid || b.orderMid) {
      return a.orderMid === b.orderMid
    } else if (a.productMid || b.productMid) {
      return a.productMid === b.productMid
    }

    return false
  }

  @computed
  get messageFlow(): ConversationFlow {
    const t = Core.getInstance().t
    let lastAttr: MessageAttributes
    return this.mergedMessages.reduce<ConversationFlow>((flow, message, index, messages) => {
      const lastMessage = messages[index - 1]

      const user = this.detail.users[message.mainUserSid]
      const content: MessageContent = {
        type: 'message',
        hash: hash(message.index ?? message.mainMessageSid),
        id: message.mainMessageSid,
        title: user.attributes
          ? formatName(user.attributes.firstName, user.attributes.lastName)
          : t('CONVERSATION.PARTICIPANT_GUEST'),
        createDate: message.createDate,
        messageType: message.messageType,
        body: message.body,
        self: this.me.user.mainUserSid === message.mainUserSid,
        pending: !!message.pending
      }
      const attributes: AttributesContent =
        !this.isSameAttribute(lastAttr, message.attributes) && isValidAttributes(message.attributes)
          ? {
              type: 'attributes',
              id: `attr_${content.hash}`,
              hash: hash(`attr_${content.hash}`),
              content: message.attributes
            }
          : null
      if (attributes) {
        lastAttr = attributes.content
      }
      const createDate = moment(message.createDate).format('YYYY-MM-DD')
      const dateMark: MarkContent =
        createDate !== moment(lastMessage?.createDate).format('YYYY-MM-DD')
          ? {
              type: 'mark',
              id: `mark-${createDate}`,
              hash: hash(`mark-${createDate}`),
              date: createDate
            }
          : null
      // const unreadMark: MarkContent = this.me.participant.lastReadMainMessageSid === message.mainMessageSid ? { type: 'mark', id: `mark-${message.createDate}-unread`, date: message.createDate.substr(0, 10), unread: true } : null

      return [...flow, ..._.compact([attributes, dateMark, content])]
    }, [])
  }

  @computed
  get title() {
    if (!this.detail) {
      return ''
    }
    const t = Core.getInstance().t
    const targets = Object.values(this.detail.users).filter(
      (user) => user.mainUserSid !== this.me.user.mainUserSid
    )
    return targets
      .map(
        (target) =>
          `${
            target.attributes?.firstName || target.attributes?.lastName
              ? formatName(target.attributes.firstName, target.attributes.lastName)
              : t('CONVERSATION.PARTICIPANT_GUEST')
          } (${
            target.attributes?.email
              ? t('CONVERSATION.PARTICIPANT_TYPE_KKDAY_MEMBER', { email: target.attributes.email })
              : target.attributes?.firstName || target.attributes?.lastName
              ? '-'
              : target.identity
          })`
      )
      .join(',')
  }

  @computed
  get lastMessage() {
    return this.mergedMessages?.length > 0
      ? this.mergedMessages[this.mergedMessages.length - 1]
      : this.detail?.lastMessage
  }

  @computed
  get read() {
    const me = this.me
    if (!me) {
      return false
    }
    return (
      me.participant.unreadMessageCount === 0 ||
      this.lastMessage?.mainUserSid === me.user.mainUserSid
    )
  }
}

@model('admin/ConversationConnection')
export class ConversationConnection extends Model({
  storeId: prop<string>(),
  state: prop<ConnectionState>('disconnected'),
  conversations: tProp(types.array(types.model(Conversation)), () => [])
}) {
  async onInit() {
    try {
      this.session = await this.fetchSession()
      if (!this.session) {
        return
      }
      console.debug('creating client with', this.session?.token)
      this.twilioClient = new TwilioConversationsClient(this.session.token, { logLevel: 'silent' })
      console.debug('created, wating connected')
      this.twilioClient.on('connectionStateChanged', (state) => {
        this.setConnectionState(state)
        console.debug('connectionStateChanged', state)
      })

      this.dispatchers.push(() => clearTimeout(this.nextFetchUnreadTimeout))
    } catch (e) {
      if (e.message !== 'jwt expired') {
        throw e
      }
    }
  }

  @modelAction
  setConnectionState(state: ConnectionState) {
    this.state = state
  }

  detach() {
    console.debug('detach from connection')
    if (this.state === 'connected') {
      this.twilioClient.removeAllListeners()
      this.twilioClient.shutdown().catch(console.error)
    }
    this.dispatchers.map((dispatcher) => dispatcher())
  }

  async getClient(): Promise<TwilioConversationsClient> {
    if (this.state === 'connected') {
      return this.twilioClient
    }
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve(true)
      }, 1000)
    )
    return await this.getClient()
  }

  core: Core = Core.getInstance()

  session: ConversationSession

  twilioClient: TwilioConversationsClient

  dispatchers: (() => void)[] = []

  lastId: string

  hasMore: boolean

  async fetchSession(skipCache: boolean = false): Promise<ConversationSession> {
    let conversatioinSession: ConversationTokenResponse
    const key = `conversattion-session-${this.storeId}`
    try {
      if (skipCache) {
        throw new Error('skip conversation cache')
      }
      conversatioinSession = JSON.parse(await AsyncStorage.getItem(key))
      if (
        !conversatioinSession?.vendor?.token ||
        jwtDecode<any>(conversatioinSession.vendor.token)?.exp < Date.now() / 1000
      ) {
        throw new Error('jwt expired')
      }
    } catch (e) {
      console.error(e)
      conversatioinSession = (
        await this.core.api.wdfcInstance.get<WDFCResponse<ConversationTokenResponse>>(
          'kkday/conversation/token',
          { skipErrorHandle: true }
        )
      ).data.data
      if (conversatioinSession?.vendor?.token) {
        await AsyncStorage.setItem(key, JSON.stringify(conversatioinSession))
      }
    }

    if (!conversatioinSession?.vendor?.token) {
      return null
    }

    return {
      token: conversatioinSession.vendor.token,
      id: conversatioinSession.mainUserSid,
      vendor: 'twilio'
    }
  }

  async fetchConversationList(start?: string, limit: number = 10, skipErrorHandle = true) {
    const result = await this.core.api.wdfcInstance.get<WDFCResponse<ConversationListResponse>>(
      `kkday/conversation/list?${qs.stringify({ start_main_conversation_sid: start, limit })}`,
      { skipErrorHandle }
    )
    if (!result.data?.data?.conversations) {
      return
    }
    result.data?.data?.conversations.map((conversation) => {
      this.addConversation(conversation)
    })

    this.lastId = result.data.data.lastMainConversationSid
    this.hasMore = result.data.data.conversations.length >= limit
  }

  async loadMore() {
    if (this.hasMore) {
      await this.fetchConversationList(this.lastId)
    }
  }

  nextFetchUnreadTimeout = null

  async polling() {
    clearTimeout(this.nextFetchUnreadTimeout)

    await this.fetchConversationList(undefined, undefined, true)

    // 每3分鐘重拉
    this.nextFetchUnreadTimeout = setTimeout(
      () => {
        this.polling().catch(console.error)
      },
      3 * 60 * 1000
    )
  }

  lastNotificationTime: string

  // 有新的訊息通知就重拉
  handleNotifications = async (notifications: UserNotification[]) => {
    const last = notifications?.[0]
    if (!last || last.notification.createdAt === this.lastNotificationTime) {
      return
    }
    this.lastNotificationTime = last.notification.createdAt
    await this.polling()
  }

  @modelAction
  addConversation = (conversation: MessageConversation) => {
    if (!this.getConversation(conversation.mainConversationSid)) {
      const conversationInsance = new Conversation({
        id: conversation.mainConversationSid,
        detail: {
          ...conversation,
          lastMessage: Conversation.formatKKMsgMessage(conversation.lastMessage)
        }
      })
      conversationInsance.connection = this
      this.conversations.push(conversationInsance)
    }
  }

  @modelAction
  createConversation = (mainConversationSid: string) => {
    const current = this.getConversation(mainConversationSid)
    if (current) {
      return current
    }
    const conversationInsance = new Conversation({
      id: mainConversationSid
    })
    conversationInsance.connection = this
    this.conversations = [conversationInsance, ...this.conversations]
    return conversationInsance
  }

  @computed
  get list() {
    return _.sortBy(
      [...this.conversations],
      (c) => 0 - parseInt(moment(c.lastMessage?.createDate).format('x'), 10)
    )
  }

  @computed
  get hasUnread() {
    return _.some(this.list, (conversation) => !conversation.read)
  }

  getConversation = (id: string) => this.conversations.find((c) => c.id === id)

  createConversationByOrderNo = async (orderNo: string) => {
    const result = await this.core.api.wdfcInstance.post<WDFCResponse<CreateConversationResponse>>(
      'kkday/conversation',
      { orderNo }
    )
    const id = Object.values(result.data.data.targetUserMapping)[0].mainConversationSid
    this.createConversation(
      Object.values(result.data.data.targetUserMapping)[0].mainConversationSid
    )
    return id
  }
}

export const ConversationContext = createContext<ConversationConnection>(null)

export const useConversation = () => useContext(ConversationContext)

export const ConversationProvider = (props: PropsWithChildren) => {
  const { store } = useStores()
  const connectionRef = useRef<ConversationConnection>()
  const [connection, setConnection] = useState<ConversationConnection>()
  const { events } = useContext(NotificationContext)
  useEffect(() => {
    const notificationHandler = (notifications: UserNotification[]) => {
      connection
        ?.handleNotifications(
          notifications.filter((n) => n.notification.type === 'CONVERSATION_MESSAGE')
        )
        .catch(console.error)
    }
    events.on('notifications', notificationHandler)
    const dispacher = autorun(() => {
      const storeUuid = store.core?.session?.store?.storeUuid
      if (storeUuid) {
        if (connectionRef.current?.storeId === storeUuid) {
          return
        }
        if (connectionRef.current) {
          connectionRef.current.detach()
        }
        const nextStoreConnection = new ConversationConnection({ storeId: storeUuid })
        connectionRef.current = nextStoreConnection
        setConnection(nextStoreConnection)
      } else {
        setConnection(null)
      }
    })

    return () => {
      events.off('notifications', notificationHandler)
      dispacher()
    }
  }, [])

  return (
    <ConversationContext.Provider value={connection}>{props.children}</ConversationContext.Provider>
  )
}
