import { atom, WritableAtom, type Getter, type Setter } from 'jotai'

export const storeUuidAtom = atom('')

export function atomWithStoreUuid<T, F>(
  getterFn: (get: Getter) => T | Promise<T>,
  setterFn?: (get: Getter, set: Setter, newValue: unknown) => F
): F extends Promise<void> ? WritableAtom<T, any, Promise<void>> : WritableAtom<T, any, void> {
  return atom((get) => {
    get(storeUuidAtom)
    return getterFn(get)
  }, setterFn) as any
}
