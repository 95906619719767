import { ThemedButton, MaterialIcons } from '@rezio/components/index'
import { useStores } from '@rezio/core/hooks'
import { template, palette } from '@rezio/res/theme'
import _ from 'lodash'
import memoize from 'memoize-one'
import moment from 'moment'
import React, { Component, useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { Text, View, StyleSheet, FlatList } from 'react-native'

import { Dropdown } from './themedComponents'

export function DateSelector(props) {
  const { core, data } = useStores()
  const defaultDate =
    moment(props.date).format(core.datePattern) || moment().format(core.datePattern)
  const [date, setDate] = useState(defaultDate)
  const calendarRef = useRef(null)
  const dropdownRef = useRef(null)
  const weekDiff = _.get(data, 'store.regional.firstDayOfWeek')

  const handleChangeDate = useCallback(
    (date) => {
      dropdownRef.current.close()
      setDate(moment(date).format(core.datePattern))
      typeof props.onChange === 'function' && props.onChange(date)
    },
    [props.onChange]
  )

  useEffect(() => {
    if (props.date !== date) {
      setDate(moment(props.date).format(core.datePattern))
    }
  }, [props.date])

  return (
    <View style={[template.rowContainer, { marginRight: 8 }, props.style]}>
      <Dropdown
        ref={dropdownRef}
        fullWidth={false}
        hideOnBlur={false}
        style={{ paddingLeft: 8 }}
        menuStyle={{ maxHeight: 'inherit' }}
        label={
          <>
            <MaterialIcons
              name='calendar-today'
              size={20}
              style={{ color: palette.primary, paddingRight: 8 }}
            />
            <Text style={{ color: palette.primary }}>{date}</Text>
          </>
        }
      >
        <Calendar
          ref={calendarRef}
          date={date}
          weekDiff={weekDiff || 0}
          onChange={handleChangeDate}
        />
      </Dropdown>
    </View>
  )
}

const dateStyles = StyleSheet.create({
  container: {
    width: 50,
    height: 50,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  button: {
    justifyContent: 'space-around',
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: 'white'
  },
  selectedButton: { backgroundColor: palette.weekend }
})

function CalendarDay(props) {
  const { date, month, textStyle, disabled, outOfRange, selected } = props
  const day = useMemo(() => moment(`${month}-${_.padStart(date, 2, '0')}`).day(), [date, month])

  let color = palette.black
  switch (true) {
    case selected:
      color = palette.white
      break
    case outOfRange:
      color = palette.negative
      break
    case [0, 6].includes(day):
      color = palette.weekend
      break
  }
  const pressDate = useCallback(() => props.onSelect(date), [date])
  return (
    <View style={dateStyles.container}>
      <ThemedButton
        disabled={disabled}
        async={false}
        onPress={pressDate}
        style={[dateStyles.button, selected && dateStyles.selectedButton]}
      >
        <Text style={[{ color }, textStyle]}>{date}</Text>
      </ThemedButton>
    </View>
  )
}

const calendarStyles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: palette.border,
    width: 360
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 59,
    backgroundColor: palette.panelBackground
  },
  week: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    height: 50
  },
  month: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap'
  },
  arrow: {
    backgroundColor: 'transparent'
  }
})

export const Calendar = class Calendar extends Component {
  static defaultProps = {
    weekDiff: 0, // 0 = start from Sunday
    dayComponent: CalendarDay,
    selected: []
  }

  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      month: moment(props.date).format('YYYY-MM')
    }
  }

  _handleSelectDate = (date) => {
    const nextDate = `${this.state.month}-${_.padStart(date, 2, '0')}`
    if (this.props.onChange) {
      this.props.onChange(nextDate)
    }
  }

  today = () => {
    const now = moment()
    this.setState({
      month: now.format('YYYY-MM')
    })
    if (this.props.onChange) {
      this.props.onChange(now.format('YYYY-MM-DD'))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  _handleChangeMonth = (n) => {
    if (this.props.disableChangeMonth) {
      return
    }
    if (n > 0) {
      this.setState({
        month: moment(this.state.month).add(n, 'month').format('YYYY-MM'),
        flip: 'left'
      })
    } else if (n < 0) {
      this.setState({
        month: moment(this.state.month)
          .subtract(0 - n, 'month')
          .format('YYYY-MM'),
        flip: 'right'
      })
    }
  }

  prevMonth = () => {
    this._handleChangeMonth(-1)
  }

  nextMonth = () => {
    this._handleChangeMonth(1)
  }

  getDays = memoize((month, selected, orgWeekDiff) => {
    const weekDiff = (orgWeekDiff - 7) % 7
    const currentMonth = moment(month).date(1)
    const nextMonth = moment(month).date(1).add(1, 'month')
    const nextMonthDay = nextMonth.day() ? nextMonth.day() : 7
    const lastMonth = moment(month).date(1).subtract(1, 'month')
    const daysInLastMonth = lastMonth.daysInMonth()
    const fillDays =
      this.props.fullRow && ((currentMonth.day() - weekDiff) % 7) + currentMonth.daysInMonth() < 35
        ? 7
        : 0
    const days = [
      ..._.range(
        daysInLastMonth - ((currentMonth.day() - weekDiff) % 7) + 1,
        daysInLastMonth + 1
      ).map((date) => ({ date, month: this.state.month, type: 'last' })),
      ..._.range(1, currentMonth.daysInMonth() + 1).map((date) => ({
        date,
        month: this.state.month,
        type: 'current',
        selected:
          _.isArray(selected) &&
          selected.includes(`${this.state.month}-${_.padStart(date, 2, '0')}`)
      })),
      ..._.range(1, 8 - ((nextMonthDay - weekDiff) % 7) + fillDays).map((date) => ({
        date,
        month: this.state.month,
        type: 'next'
      }))
    ]
    return days
  }, _.isEqual)

  keyExtractor = (item) => `${item.month}-${item.type}-${item.date}`

  renderItem = ({ item }) => {
    const { disabled } = this.props
    return (
      <CalendarDay
        disabled={disabled}
        selected={item.selected}
        date={item.date}
        month={item.month}
        outOfRange={item.type !== 'current'}
        onSelect={
          item.type === 'current'
            ? this._handleSelectDate
            : item.type === 'last'
            ? this.prevMonth
            : this.nextMonth
        }
      />
    )
  }

  render() {
    const { weekDiff, headerFormat = 'MMM YYYY', selected = [], disabled } = this.props
    const currentMonth = moment(this.state.month).date(1)
    const weekdaysShort = moment
      .weekdaysShort()
      .slice(weekDiff)
      .concat(moment.weekdaysShort().slice(0, weekDiff))

    const days = this.getDays(this.state.month, [...selected], weekDiff)

    return (
      <View style={calendarStyles.root}>
        <View style={calendarStyles.header}>
          <View style={{ flex: 1 }}>
            {!this.props.disableChangeMonth && (
              <ThemedButton
                onPress={() => this._handleChangeMonth(-1)}
                style={calendarStyles.arrow}
              >
                <MaterialIcons name='arrow-back' size={14} />
              </ThemedButton>
            )}
          </View>
          <Text style={{ textAlign: 'center', flex: 1, fontWeight: 'bold' }}>
            {currentMonth.format(headerFormat)}
          </Text>
          <View style={{ flex: 1 }}>
            {!this.props.disableChangeMonth && (
              <ThemedButton onPress={() => this._handleChangeMonth(1)} style={calendarStyles.arrow}>
                <MaterialIcons name='arrow-forward' size={14} />
              </ThemedButton>
            )}
          </View>
        </View>
        <View style={calendarStyles.week}>
          {weekdaysShort.map((d) => (
            <Text
              key={d}
              style={Object.assign(
                {
                  flex: 1,
                  textAlign: 'center',
                  fontSize: 13,
                  color: d === 'Sun' || d === 'Sat' ? 'red' : palette.black
                },
                calendarStyles.dayTitle
              )}
            >
              {d}
            </Text>
          ))}
        </View>
        <FlatList
          data={days}
          keyExtractor={this.keyExtractor}
          numColumns={7}
          renderItem={this.renderItem}
          extraData={{ disabled }}
        />
      </View>
    )
  }
}
