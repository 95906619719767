import { AppConfigContext } from '@rezio/core/config'
import { useRouter, useLayout, usePermission, useStores } from '@rezio/core/hooks'
import {
  SUBSCRIPTION_STATUS,
  PLAN_FEATURES,
  ROLE_TITLE,
  PERMISSION_TITLE,
  PERMISSION_ACTION
} from '@rezio/core/permission'
import { NavigatorStatus, ChannelType } from '@rezio/utils/types'
import _ from 'lodash'
import { autorun, computed, reaction, values } from 'mobx'
import { useMemo, useContext, ContextType, useState, useEffect, useSyncExternalStore } from 'react'
import { Platform } from 'react-native'
import type { IconName } from '@rezio/components/rezioIcon'
import { ChannelStatus } from '@rezio/components/channel'
import { molecule } from 'bunshi'
import { atom as atomVanilla } from 'jotai/vanilla'
import { useMolecule } from 'bunshi/react'
import { unwrap } from 'jotai/utils'
import { useAtomValue } from 'jotai'
import { channel } from 'expo-updates'
import { useFlags } from '../flags'
import Core from '../core'
import { APIResponse } from '../api'
import { storeScope } from '../scopes'

/**
 * 網頁選單設定檔
 * desktop/mobile統一
 */
const menuConfig: SiteMapConfig = [
  {
    icon: 'dashboard',
    label: 'NAVIGATOR.CATEGORY_DASHBOARD',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_DASHBOARD',
        path: '/dashboard',
        mobile: 'tab'
      },
      {
        label: 'NAVIGATOR.PAGE_CONVERSATION',
        path: '/conversation',
        mobile: 'navbar-only',
        deny: composeRules([
          withPlanFeature([PLAN_FEATURES.MESSAGE_CENTER], NavigatorStatus.Hidden),
          withoutRoles([ROLE_TITLE.REDEEM_STUFF], NavigatorStatus.Hidden)
        ])
      },
      {
        label: 'NAVIGATOR.PAGE_TO_BE_PROCESSED',
        path: '/toBeProcessed',
        mobile: 'navbar-only' as MobilePosition,
        deny: withoutRoles([ROLE_TITLE.REDEEM_STUFF], NavigatorStatus.Hidden)
      },
      {
        label: 'NAVIGATOR.PAGE_CUSTOM_REPORT',
        path: '/customReport',
        mobile: 'navbar-only',
        // AppConfig要有CUSTOM_REPORT的設定
        deny: (_, config) =>
          config.store?.find((config) => config?.type === 'CUSTOM_REPORT')
            ? NavigatorStatus.Active
            : NavigatorStatus.Hidden
      }
    ],
    allow: withoutRoles([ROLE_TITLE.ORDER_ONLY], NavigatorStatus.Active)
  },
  {
    icon: 'rezio_kkday_2',
    label: 'NAVIGATOR.CATEGORY_KKDAY_MKP',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_MKP_STORE_PROFILE',
        path: '/channel/kkdaymkp/storeInfo'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_COMMENT',
        path: '/scmmkp/comment/index'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_EVALUATION',
        path: '/scmmkp/evaluation/index'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_REPORT_PAYMENT_MKP_LEGACY',
        path: '/scmmkp/report/statement'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_COMMISSION_REPORT',
        path: '/scmmkp//billing/billingGroup'
      }
    ],
    deny: withChannel(ChannelType.KKDAYMKP, NavigatorStatus.Hidden)
  },
  {
    icon: 'rezio_kkday_1',
    label: 'NAVIGATOR.CATEGORY_KKDAY',
    pages: [
      {
        label: 'NAVIGATOR.SCM_PAGE_DASHBOARD',
        path: '/scm/home/dashboard'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_MESSAGE_ALL',
        path: '/scm/home/index'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_VOUCHER_REDEEM',
        path: '/scm/writeoff/redeem_voucher'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_COMMENT',
        path: '/scm/comment/index'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_FREQUENT_MESSAGE',
        path: '/scm/message/frequentMessageList'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_ORDER',
        path: '/scm/datacenter/order'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_EVALUATION',
        path: '/scm/evaluation/index'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_REPORT_ORDER',
        path: '/scm/report/orderok'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_REPORT_PAYMENT',
        path: '/scm/report/statement'
      },
      {
        label: 'NAVIGATOR.SCM_PAGE_SETTING',
        path: '/scm/manage/company'
      }
    ],
    deny: withChannel(ChannelType.KKDAY, NavigatorStatus.Hidden)
  },
  {
    icon: 'folder-special',
    label: 'NAVIGATOR.CATEGORY_PRODUCT',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_PRODUCT_LIST',
        path: '/product'
      },
      {
        label: 'NAVIGATOR.PAGE_PRODUCT_ADD',
        path: '/product/add'
      }
    ]
  },
  {
    icon: 'local-activity',
    label: 'NAVIGATOR.CATEGORY_TICKET',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_TICKET_LIST',
        path: '/ticket'
      },
      {
        label: 'NAVIGATOR.PAGE_TICKET_INVENTORY',
        path: '/ticket/inventory'
      },
      {
        label: 'NAVIGATOR.PAGE_TICKET_REPORT',
        path: '/ticket/report'
      }
    ],
    deny: withPlanFeature([PLAN_FEATURES.TICKET], NavigatorStatus.Locked)
  },
  {
    icon: 'event',
    label: 'NAVIGATOR.CATEGORY_PUBLISH',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_PUBLISH_CALENDAR',
        path: '/publish/calendar',
        alias: '/publish/session',
        mobile: 'tab'
      },
      {
        label: 'NAVIGATOR.PAGE_PUBLISH_STOCK_CALENDAR',
        path: '/publish/stockCalendar',
        mobile: 'navbar-only'
      },
      {
        label: 'NAVIGATOR.PAGE_PUBLISH_RESOURCE_CALENDAR',
        path: '/publish/resourceCalendar',
        mobile: 'navbar-only',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_PUBLISH_PRICING',
        path: '/publish/pricingSetting',
        deny: (_, __, checkFlag) =>
          checkFlag('remove_store_pricing_policy') ? NavigatorStatus.Hidden : NavigatorStatus.Active
      },
      {
        label: 'NAVIGATOR.PAGE_PUBLISH_ACTION_HISTORY',
        path: '/publish/actionHistory'
      }
    ]
  },
  {
    icon: 'label',
    label: 'NAVIGATOR.CATEGORY_DISCOUNT',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_DISCOUNT_COUPON_CODE',
        path: '/discount/couponCode',
        mobile: 'navbar-only'
      },
      {
        label: 'NAVIGATOR.PAGE_DISCOUNT_DISCOUNT_REPORT',
        path: '/discount/discountReport'
      }
    ],
    deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
  },
  {
    icon: 'description',
    label: 'NAVIGATOR.CATEGORY_ORDERS',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_ORDER_QUICK_SEARCH',
        path: '/quicksearch',
        mobile: 'navbar-only'
      },
      {
        label: 'NAVIGATOR.PAGE_ORDER_CALENDAR',
        path: '/order/calendar',
        mobile: 'navbar-only'
      },
      {
        label: 'NAVIGATOR.PAGE_ORDER_LIST',
        path: '/order',
        mobile: 'tab'
      },
      {
        label: 'NAVIGATOR.PAGE_ORDER_TAG',
        path: '/order/orderTag'
      },
      {
        label: 'NAVIGATOR.PAGE_ORDER_SOURCE',
        path: '/order/source'
      },
      {
        label: 'NAVIGATOR.PAGE_ORDER_ADD',
        path: '/order/add',
        mobile: 'navbar-only',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      }
    ],
    allow: withoutRoles([ROLE_TITLE.ORDER_ONLY], NavigatorStatus.Active)
  },
  {
    icon: 'crop-free',
    label:
      Platform.OS === 'web' ? 'NAVIGATOR.CATEGORY_REDEEM_MANAGEMENT' : 'NAVIGATOR.CATEGORY_SCAN',
    pages: (permission, config) => {
      const hasLAN = !!config?.basic?.localUrl && Platform.OS !== 'web'
      const lanPageConfig: SiteMapPageConfig = {
        label: 'NAVIGATOR.PAGE_REDEEM_LAN',
        path: '/lan',
        mobile: 'tab'
      }
      return [
        ...(hasLAN ? [lanPageConfig] : []),
        {
          label: hasLAN ? 'NAVIGATOR.PAGE_REDEEM_CLOUD' : 'NAVIGATOR.PAGE_SCANNER',
          path: '/scanner',
          mobile: hasLAN ? 'navbar-only' : 'tab'
        },
        {
          label: 'NAVIGATOR.PAGE_REDEEM_RECORD',
          path: '/redeem/record',
          mobile: 'navbar-only',
          deny: composeRules([withoutRoles([ROLE_TITLE.REDEEM_STUFF], NavigatorStatus.Hidden)])
        },
        {
          label: 'NAVIGATOR.PAGE_REPORT_REDEEM',
          path: '/redeem/report',
          mobile: 'navbar-only'
        }
      ]
    },
    allow: withoutRoles([ROLE_TITLE.REDEEM_STUFF, ROLE_TITLE.ORDER_ONLY], NavigatorStatus.Active)
  },
  {
    icon: 'file-download',
    label: 'NAVIGATOR.CATEGORY_REPORT',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_REPORT_ORDER',
        path: '/report/salesReport'
      },
      {
        label: 'NAVIGATOR.PAGE_REPORT_TRAVELER',
        path: '/report/travelerReport'
      },
      {
        label: 'NAVIGATOR.PAGE_REPORT_ACCOUNT',
        path: '/report/accountStatement',
        deny: withPlanFeature([PLAN_FEATURES.ACCOUNT_STATEMENT], NavigatorStatus.Hidden)
      },
      {
        label: 'NAVIGATOR.PAGE_REPORT_PASSENGER_TRANSFER',
        path: '/report/passengerTransfer'
      }
    ],
    allow: withoutRoles([ROLE_TITLE.ORDER_ONLY], NavigatorStatus.Active)
  },
  {
    icon: 'store',
    label: 'NAVIGATOR.CATEGORY_SELLS',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_MY_SITE',
        path: '/mysite',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_THEME_DESIGN',
        path: '/mysite/theme',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_MYSITE_WIDGET',
        path: '/mysite/widget',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_VOUCHER_SETTING',
        path: '/mysite/voucherSetting',
        deny: withPlanFeature([PLAN_FEATURES.VOUCHER_CUSTOMER_REDEEM], NavigatorStatus.Locked)
      }
    ]
  },
  {
    icon: 'point-of-sale',
    label: 'NAVIGATOR.CATEGORY_DEVICE',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_DEVICE_LIST',
        path: '/device'
      },
      {
        label: 'NAVIGATOR.PAGE_DEVICE_SETTING',
        path: '/device/setting',
        deny: withPlanFeature([PLAN_FEATURES.CHANNEL_POS], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_DEVICE_ACCOUNT_LOG',
        path: '/device/settleAccountLog',
        deny: withPlanFeature([PLAN_FEATURES.CHANNEL_POS], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_DEVICE_OPERATION_RECORD',
        path: '/device/operationRecord',
        deny: withPlanFeature([PLAN_FEATURES.CHANNEL_POS], NavigatorStatus.Locked)
      }
    ],
    deny: withPlanFeature([PLAN_FEATURES.CHANNEL_POS, PLAN_FEATURES.GATE], NavigatorStatus.Hidden)
  },
  {
    icon: 'call-split',
    label: 'NAVIGATOR.CATEGORY_DISTRIBUTION',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_DISTRIBUTION_DASHBOARD',
        path: '/distributor'
      }
    ],
    deny: withPlanFeature([PLAN_FEATURES.DISTRIBUTION], NavigatorStatus.Locked)
  },
  {
    icon: 'device-hub',
    label: 'NAVIGATOR.CATEGORY_CHANNELS',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_CHANNEL_DASHBOARD',
        path: '/channel'
      },
      {
        label: 'NAVIGATOR.PAGE_ABNORMAL_ORDER',
        path: '/abnormalOrder',
        deny: withPermission(
          PERMISSION_TITLE.ABNORMAL_ORDER,
          PERMISSION_ACTION.GET,
          NavigatorStatus.Locked
        )
      }
    ],
    deny: withPlanFeature([PLAN_FEATURES.CHANNEL], NavigatorStatus.Locked)
  },
  {
    icon: 'settings',
    label: 'NAVIGATOR.CATEGORY_SETTING',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_SETTING_ACCOUNT',
        path: '/account/list',
        alias: '/account'
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_STORE',
        path: '/store/basic',
        alias: '/store'
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_REDEEM_PERMISSION',
        path: '/redeemPermission/forceRedeem',
        alias: '/redeemPermission'
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_PRICING',
        path: '/setting/pricing',
        deny: (_, __, checkFlag) =>
          checkFlag('remove_store_pricing_policy') ? NavigatorStatus.Hidden : NavigatorStatus.Active
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_BOOKING_INFO',
        path: '/setting/bookingInfo'
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_EXTRA',
        path: '/setting/extra',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_EXTRA], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_RESOURCE',
        path: '/setting/resource',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_PICKUP',
        path: '/setting/pickup'
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_DEPOSIT',
        path: '/setting/deposit',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_CANCEL',
        path: '/setting/cancel'
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_TAX_AND_FEES',
        path: '/setting/feendtax',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_VENDOR',
        path: '/setting/vendor',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_TRANSLATION_LIST',
        path: '/translation',
        deny: withPlanFeature([PLAN_FEATURES.REZIO_BASIC], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_VOUCHER_TEMPLATE',
        path: '/voucherTemplate',
        deny: withPlanFeature([PLAN_FEATURES.CUSTOM_VOUCHER_TEMPLATE], NavigatorStatus.Locked)
      },
      {
        label: 'NAVIGATOR.PAGE_SETTING_MEDIA_LIBRARY',
        path: '/setting/mediaLibrary'
      },
      {
        label: 'CHECK.TITLE_CONNCECTION_CHECK',
        path: '/check',
        mobile: 'navbar-only'
      },
      {
        label: 'Internal Configs (Beta)',
        path: '/internal',
        deny: (_, __, checkFlag) =>
          checkFlag('beta_flags') ? NavigatorStatus.Active : NavigatorStatus.Hidden
      }
    ]
  },
  {
    icon: 'verified-user',
    label: 'NAVIGATOR.CATEGORY_MYPLAN',
    pages: [
      {
        label: 'NAVIGATOR.PAGE_MYPLAN_TOKEN',
        path: '/myplan/token',
        deny: composeRules([
          withPlanFeature([PLAN_FEATURES.API_TOKEN], NavigatorStatus.Locked),
          withPermission(PERMISSION_TITLE.API_TOKEN, PERMISSION_ACTION.GET, NavigatorStatus.Hidden)
        ])
      },
      {
        label: 'NAVIGATOR.PAGE_MYPLAN_SUBSCRIPTION',
        path: '/myplan/subscription',
        deny: withRoles([ROLE_TITLE.STORE_OWNER, ROLE_TITLE.REZIO_EXPERT], NavigatorStatus.Hidden)
      }
    ],
    deny: withRoles(
      [ROLE_TITLE.STORE_OWNER, ROLE_TITLE.ADMIN, ROLE_TITLE.REZIO_EXPERT],
      NavigatorStatus.Hidden
    )
  }
]

type StatusRule = (
  permission: ReturnType<typeof usePermission>,
  config: ContextType<typeof AppConfigContext>,
  checkFlag: ReturnType<typeof useFlags>['checkFlag'],
  channels: ChannelOption[]
) => NavigatorStatus | false

type StatusRuleArgs = Parameters<StatusRule>

interface NavigatorStatusConfig {
  /** 擋的規則 */
  deny?: StatusRule
  /** 允許的規則，allow會蓋過deny */
  allow?: StatusRule
}

type SiteMapPageConfig = NavigatorStatusConfig & Omit<SiteMapPage, 'status'>

interface SiteMapCategoryConfig extends NavigatorStatusConfig {
  icon: IconName
  label: string
  pages: SiteMapPageConfig[] | ((...args: Parameters<StatusRule>) => SiteMapPageConfig[])
}

type SiteMapConfig = SiteMapCategoryConfig[]

type MobilePosition = 'tab' | 'navbar-only'

export interface SiteMapCategory {
  label: string
  status: NavigatorStatus
  icon: IconName
  pages: SiteMapPage[]
}

export interface SiteMapPage {
  label: string
  status: NavigatorStatus
  path: string
  alias?: string
  mobile?: MobilePosition
}

export interface SiteMap {
  status: NavigatorStatus
  categories: SiteMapCategory[]
  favorites: string[]
  pages: SiteMapPage[]
  currentPage: SiteMapPage
}

/**
 * 預設的Deny StatusRule
 * 2022/07/27: 核銷員和理單員沒有任何頁面的基本權限(i.e. 除非被allow允許，不然都會是disabled)
 */
const defaultDenyRule: StatusRule = ({ role: targetRole }) =>
  [ROLE_TITLE.ORDER_ONLY, ROLE_TITLE.REDEEM_STUFF].includes(targetRole)
    ? NavigatorStatus.Disabled
    : NavigatorStatus.Active

/**
 * withPlanFeature 搭配 PLAN_FEATURES
 * @param feature 所需Feature
 * @param fallbackStatus 不滿足的話給予的NavigatorStatus
 */
function withPlanFeature(feature: PLAN_FEATURES[], fallbackStatus: NavigatorStatus): StatusRule {
  return ({ hasPlanFeature }) =>
    feature.some((eachFeature) => hasPlanFeature(eachFeature)) ? false : fallbackStatus
}

/**
 * withPermission 搭配PERMISSION TITLE/ACTION
 * @param permission 檢查的PERMISSION_TITLE
 * @param action 所需的權限動作
 * @param fallbackStatus 不滿足的話給予的NavigatorStatus
 */
function withPermission(
  permission: PERMISSION_TITLE,
  action: PERMISSION_ACTION,
  fallbackStatus: NavigatorStatus
): StatusRule {
  return ({ hasRolePermission }) => (hasRolePermission(permission, action) ? false : fallbackStatus)
}

/**
 * withRoles 搭配ROLE_TITLE
 * @param roles 檢查的ROLE_TITLE
 * @param fallbackStatus 不滿足的話給予的NavigatorStatus
 */
function withRoles(roles: ROLE_TITLE[], fallbackStatus: NavigatorStatus): StatusRule {
  return ({ role: targetRole }) => (roles.includes(targetRole) ? false : fallbackStatus)
}

/**
 * withoutRoles 搭配ROLE_TITLE 與withRoles相反
 * @param roles 檢查的ROLE_TITLE
 * @param fallbackStatus *是這些ROLE的話*給予的NavigatorStatus
 */
function withoutRoles(roles: ROLE_TITLE[], fallbackStatus: NavigatorStatus): StatusRule {
  return ({ role: targetRole }) => (roles.includes(targetRole) ? fallbackStatus : false)
}

function withChannel(channel: ChannelType, fallbackStatus: NavigatorStatus): StatusRule {
  return (_auth, _config, _flags, channels) => {
    return channels.find((channelOption) => channelOption.key === channel) ? false : fallbackStatus
  }
}

/**
 * Hidden > Disabled > Locked (Active依Deny/Allow規則不同)
 * @param currentStatus
 * @param nextStatus
 * @returns NavigatorStatus
 */
function composeStatus(
  currentStatus: false | NavigatorStatus,
  nextStatus: false | NavigatorStatus
) {
  if (nextStatus === NavigatorStatus.Hidden || currentStatus === NavigatorStatus.Hidden) {
    currentStatus = NavigatorStatus.Hidden
  } else if (
    nextStatus === NavigatorStatus.Disabled ||
    currentStatus === NavigatorStatus.Disabled
  ) {
    currentStatus = NavigatorStatus.Disabled
  } else if (nextStatus === NavigatorStatus.Locked || currentStatus === NavigatorStatus.Locked) {
    currentStatus = NavigatorStatus.Locked
  }
  return currentStatus
}

/**
 * 合併不同的StatusRole規則
 */
function composeRules(functions: StatusRule[]): StatusRule {
  return (...args) => {
    return _.compact(functions)
      .map((func) => func(...args))
      .reduce(composeStatus, NavigatorStatus.Active)
  }
}

// function isCategory (node): node is SiteMapCategoryConfig {
//   return !!node.pages
// }

const defaultFavorites = [
  'DASHBOARD',
  'PRODUCT_LIST',
  'TICKET_LIST',
  'PUBLISH_CALENDAR',
  'ORDER_CALENDAR',
  'ORDER_LIST'
]

interface ChannelOption {
  key: ChannelType
  title: string
  uuid: string
}

const channelMolecule = molecule((mol, scope) => {
  scope(storeScope)
  const fetchChannelAtom = atomVanilla(async () => {
    const api = Core.getInstance().api
    const { data: channels } = await api.get<APIResponse<ChannelOption[]>>(
      'channel/listAsOption'
    )[0]
    return channels.map((channel) => ({
      ...channel,
      key: channel.key.toUpperCase() as ChannelType
    }))
  })

  const unwrapedChannelAtom = unwrap(fetchChannelAtom)
  const channelsAtom = atomVanilla((get) => {
    return get(unwrapedChannelAtom) || []
  })
  return channelsAtom
})

function useChannels() {
  const channelsAtom = useMolecule(channelMolecule)
  const channels = useAtomValue(channelsAtom)
  return channels
}

/**
 * 取得完整SiteMap/選單頁面資料
 * @param skipLayout optional 無視當下Mobile/Desktop 回傳該用戶能存取的所有的Pages
 * @returns SiteMap
 */
export function useSitemap(skipLayout?: boolean): SiteMap {
  const config = useContext(AppConfigContext)
  const { layout } = useLayout()
  const permission = usePermission('basic')
  const { checkFlag } = useFlags()
  const router = useRouter()
  const { store } = useStores()
  const channels = useChannels()
  const args: StatusRuleArgs = useMemo(
    () => [permission, config, checkFlag, channels],
    [permission, config, checkFlag, channels]
  )

  const navigatorStatus = [SUBSCRIPTION_STATUS.BANNED, SUBSCRIPTION_STATUS.PAUSE].includes(
    permission.subscriptionStatus
  )
    ? NavigatorStatus.Disabled
    : NavigatorStatus.Active

  const categories = useMemo((): SiteMap['categories'] => {
    if (!permission.isLogin) {
      return []
    }

    function parseStatus(
      categoryOrPage: SiteMapCategoryConfig | SiteMapPageConfig,
      categoryStatus?: NavigatorStatus
    ) {
      if (navigatorStatus !== NavigatorStatus.Active) {
        return navigatorStatus
      }
      let status =
        (categoryStatus
          ? categoryStatus !== NavigatorStatus.Active
            ? composeStatus(categoryStatus, defaultDenyRule(...args))
            : categoryStatus
          : defaultDenyRule(...args)) || NavigatorStatus.Active

      if (categoryOrPage.deny) {
        status = composeStatus(status, categoryOrPage.deny(...args)) || status
      }
      // 允許會覆蓋過去
      if (categoryOrPage.allow) {
        status = categoryOrPage.allow(...args) || status
      }

      return status
    }

    function resolveSitemapCategory(categoryConfig: SiteMapCategoryConfig) {
      const pageStatus = parseStatus(categoryConfig)

      if (pageStatus === NavigatorStatus.Hidden) return null

      const pages = _.compact(
        (typeof categoryConfig.pages === 'function'
          ? categoryConfig.pages(...args)
          : categoryConfig.pages
        )
          .filter((page) => skipLayout || layout === 'desktop' || !!page.mobile)
          .map((page) => resolveSitemapPage(page, pageStatus))
      )

      if (pages.length === 0) return null

      const category: SiteMapCategory = {
        label: categoryConfig.label,
        status: pageStatus,
        icon: categoryConfig.icon,
        pages
      }
      return category
    }

    function resolveSitemapPage(pageConfig: SiteMapPageConfig, categoryStatus?: NavigatorStatus) {
      const pageStatus = parseStatus(pageConfig, categoryStatus)

      if (pageStatus === NavigatorStatus.Hidden) return null

      const page: SiteMapPage = {
        label: pageConfig.label,
        status: pageStatus,
        path: pageConfig.path,
        alias: pageConfig.alias,
        ...(pageConfig.mobile ? { mobile: pageConfig.mobile } : {})
      }
      return page
    }

    return _.compact(menuConfig.map((category) => resolveSitemapCategory(category)))
  }, [permission.isLogin, navigatorStatus, args, skipLayout, layout])

  const pages = useMemo(() => _.flatten(_.map(categories, ({ pages }) => pages)), [categories])

  const currentPage = useMemo(
    () =>
      _.reduce(
        pages,
        (currentPage, page) => {
          if (!router.pathname) {
            return currentPage
          }
          let result = router.pathname.match(page.path)
          if (
            result &&
            (currentPage.path.length < result[0].length ||
              currentPage.path.length === result[0].length)
          ) {
            currentPage = page
          }

          if (page.alias) {
            result = router.pathname.match(page.alias)
            if (
              result &&
              (currentPage.path.length < result[0].length ||
                currentPage.path.length === result[0].length)
            ) {
              currentPage = page
            }
          }

          return currentPage
        },
        { path: '', label: undefined, status: NavigatorStatus.Active }
      ),
    [pages, router.pathname]
  )

  const [favorites, setFavorites] = useState<string[]>([])

  useEffect(() => {
    const disposer = autorun(() => {
      try {
        if (store.core.session) {
          const menu = values(computed(() => store.core.session.menu).get())
          setFavorites(menu[0] === 'UNSET' ? defaultFavorites : (menu as any))
        }
      } catch (e) {
        console.error(e)
      }
    })
    return () => disposer()
  }, [store.core.session])

  const siteMap = useMemo(() => {
    return {
      status: navigatorStatus,
      categories,
      pages,
      favorites: _.compact(
        favorites.filter((favoriteKey) =>
          pages.find(
            (page) =>
              page.label === `NAVIGATOR.PAGE_${favoriteKey}` &&
              page.status === NavigatorStatus.Active
          )
        )
      ),
      currentPage
    }
  }, [navigatorStatus, categories, pages, favorites, currentPage])

  return siteMap
}

export function translationKeyToPageKey(translationKey: string) {
  return translationKey.replace('NAVIGATOR.PAGE_', '')
}
