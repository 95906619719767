import { HoverableOpacity } from '@rezio/components/hoverableOpacity'
import { useLayout } from '@rezio/core/hooks'
import { palette } from '@rezio/res/theme'
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import {
  Text,
  TouchableOpacity,
  Modal,
  View,
  Dimensions,
  StyleSheet,
  StyleProp,
  ViewStyle
} from 'react-native'
import { BlackPortal } from 'react-native-portal'

import { TooltipProps } from './tooltip.type'

export const Tooltip: React.FC<TooltipProps> = ({
  popoverStyle = {},
  backgroundColor = palette.darkGrayBg,
  popover,
  ...props
}) => {
  const { isMobile } = useLayout()
  const isMounted = useRef(false)
  const elementRef = useRef<View>(null)
  const [visible, setVisible] = useState<boolean>(false)
  const [dimensions, setDimensions] = useState({
    offsetX: 0,
    offsetY: 0,
    elementWidth: 0,
    elementHeight: 0
  })

  const tooltipStyle: StyleProp<ViewStyle> = useMemo(() => {
    const { offsetX, offsetY, elementWidth, elementHeight } = dimensions
    const { width: dimensionWidth, height: dimensionHeight } = Dimensions.get('window')
    const screenPadding = 15
    const centerCoordinate = [offsetX - elementWidth / 2, offsetY + elementHeight / 2]
    const atLeftHalf = dimensionWidth / 2 >= centerCoordinate[0]
    const atUpperHalf = dimensionHeight / 2 >= centerCoordinate[1]
    const newX = atLeftHalf ? offsetX : dimensionWidth - offsetX - elementWidth
    const newY = (atUpperHalf ? offsetY + elementHeight : dimensionHeight - offsetY) + 10
    const maxWidth = Math.min(dimensionWidth - newX - screenPadding, 250)
    const maxHeight = dimensionHeight - newY - screenPadding

    return StyleSheet.flatten([
      {
        position: 'absolute',
        [atLeftHalf ? 'left' : 'right']: newX,
        [atUpperHalf ? 'top' : 'bottom']: newY,
        maxWidth,
        maxHeight,
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        borderRadius: 4,
        padding: 10
      },
      popoverStyle
    ])
  }, [backgroundColor, popoverStyle, dimensions])

  const getElementPosition = useCallback(() => {
    elementRef?.current?.measure(
      (_frameOffsetX, _frameOffsetY, _width, _height, pageOffsetX, pageOffsetY) => {
        isMounted.current &&
          setDimensions({
            offsetX: pageOffsetX,
            offsetY: pageOffsetY,
            elementWidth: _width,
            elementHeight: _height
          })
      }
    )
  }, [])

  useEffect(() => {
    isMounted.current = true
    // Wait till element's position is calculated
    requestAnimationFrame(getElementPosition)
    const subscription = Dimensions.addEventListener('change', () => {
      getElementPosition()
    })

    return () => {
      isMounted.current = false
      subscription.remove()
    }
  }, [getElementPosition])

  const handleOnPress = useCallback(() => {
    getElementPosition()
    isMounted.current && setVisible((prev) => !prev)
  }, [])

  const popoverContent = useMemo(() => {
    return typeof popover === 'string' ? (
      <Text style={{ color: palette.white }}>{popover}</Text>
    ) : (
      popover
    )
  }, [popover])

  return (
    // 沒有 collapsable={false} android 會無法 measure offset, width and height
    <View collapsable={false} ref={elementRef}>
      <HoverableOpacity
        onPress={handleOnPress}
        onMouseEnter={() => !isMobile && handleOnPress()}
        onMouseLeave={() => !isMobile && setVisible(false)}
      >
        {props.children}
      </HoverableOpacity>
      {isMobile ? (
        <Modal
          transparent
          visible={visible}
          animationType='fade'
          onRequestClose={() => setVisible(false)}
        >
          <TouchableOpacity
            style={{
              backgroundColor: 'transparent',
              flex: 1
            }}
            onPress={handleOnPress}
            activeOpacity={1}
          >
            <View style={tooltipStyle}>{popoverContent}</View>
          </TouchableOpacity>
        </Modal>
      ) : (
        // @ts-expect-error // * related to react 18 FC type no longer contains implicit children
        <BlackPortal name='addition'>
          {visible && <View style={tooltipStyle}>{popoverContent}</View>}
        </BlackPortal>
      )}
    </View>
  )
}
