import Core from '@rezio/core/core'
import { ProductCategories } from '@rezio/utils/types'

interface GetLatestSession {
  ({ productId, salesOptionId }: { productId: string; salesOptionId: string }): Promise<{
    sessionUuid: string
    sessionSettingUuid: string
    endDate: string
  } | null>
}

const getLatestSession: GetLatestSession = async ({ productId, salesOptionId }) => {
  const core = Core.getInstance()
  const { data: sessions } = await core.api.get<{
    data: {
      sessionUuid: string
      sessionSettingUuid: string
      endDate: string
    }[]
  }>(`/product/${productId}/salesOption/${salesOptionId}/sessionSetting`)[0]

  const allSortedSessionsWithDESC = [...sessions].sort(
    (sessionA, sessionB) =>
      new Date(sessionB.endDate).getTime() - new Date(sessionA.endDate).getTime()
  )

  return [...allSortedSessionsWithDESC].shift() || null
}

const isNonSessionProduct = async (id: string) => {
  const core = Core.getInstance()
  const { data: product } = await core.api.get<{
    data: {
      categoryUuid: string
    }
  }>(`product/${id}`)[0]
  return product.categoryUuid === ProductCategories.NonSessionProduct
}

export { getLatestSession, isNonSessionProduct }
