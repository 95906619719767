import { StaticImage } from '@rezio/components'
import React from 'react'
import { View, Text, ScrollView } from 'react-native'

const MaintenancePage = () => {
  const maintainContent = [
    {
      start:
        '為了提供更優質的服務，並配合通路-KKday 進行系統維護工作。於下列時段進行系統升級維護，暫停所有服務，詳細說明如下：',
      date: '日期：2024年09月18日（星期三）',
      time: '時間：上午 02:00 至 06:00 (GMT+8)',
      end: '造成不便，敬請見諒。'
    },
    {
      start:
        'We will be conducting system maintenance in collaboration with our KKday channel to enhance service quality. All functions will be temporarily suspended during the following period:',
      date: 'Date: Wednesday September 18, 2024',
      time: 'Time: 02:00 - 06:00 AM (GMT+8)',
      end: 'We apologize for any inconvenience this may cause. Thank you for your understanding.'
    },
    {
      start: `平素よりrezioをご利用いただき、誠にありがとうございます。\nKKday / KKdayモールのシステムアップデートに伴い、rezioでは以下記載の日時にて、メンテナンスを実施します。メンテナンス期間中は、管理画面 / 管理者用モバイルアプリ / B2B販売専用予約サイト等の全ての操作が一時的にご利用いただけません。`,
      date: '実施日：2024年9月18日（水）',
      time: '実施時間：03:00〜07:00  (日本時間)',
      end: 'ご迷惑をおかけいたしますが、何卒ご理解賜りますようお願い申し上げます。'
    },
    {
      start:
        'KKday / KKday Marketplace 시스템 업데이트로 인해, rezio의 모든 서비스 (웹사이트 및 앱)이 일시중단 예정 입니다.',
      date: '작업 일시: 2024년 9월 18일 (수요일)',
      time: '작업 시간: 03:00 ~ 07:00 (한국 시간)',
      end: '불편을 끼쳐 드린 점 양해해 주시기 바랍니다.'
    }
  ]
  return (
    <View className='h-full overflow-auto'>
      <ScrollView>
        <View className='m-6 flex items-center justify-center'>
          <StaticImage
            className=''
            style={[{ width: 308, height: 308 }]}
            source={require('@rezio/assets/maintenance.png')}
          />
          <Text className='mb-4 text-center text-primary text-h2'>系統升級公告</Text>
          <Text className='mb-4 text-center text-primary text-h2'>System Upgrade In Progress</Text>
          <Text className='mb-4 text-center text-primary text-h2'>
            システムアップデートに伴うサービス一時停止のお知らせ
          </Text>
          <Text className='text-center text-primary text-h2'>시스템 업데이트 안내</Text>
          <View className='mt-9'>
            {maintainContent.map(({ start, date, time, end }) => (
              <View className='mb-9'>
                <Text className='text-h3'>
                  {start}
                  {'\n '}
                </Text>
                <Text className='leading-[30px] text-h2'>
                  {date}
                  {'\n '}
                </Text>
                <Text className='leading-[30px] text-h2'>
                  {time}
                  {'\n '}
                </Text>
                <Text className='text-h3'>{end}</Text>
              </View>
            ))}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default MaintenancePage
