import { MaterialIcons, ThemedTextInput } from '@rezio/components'
import { Modal } from '@rezio/components/modal'
import { palette } from '@rezio/res/theme'
import { getFuzzySearchItems } from '@rezio/utils/format'
import {
  Platform,
  View,
  Text,
  TouchableOpacity,
  ScrollView as NativeScrollView
} from 'react-native'
import { ScrollView as GestureScrollView } from 'react-native-gesture-handler'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import _ from 'lodash'
import type { ModalProps } from 'react-native-modal'

const ScrollView = Platform.OS === 'web' ? NativeScrollView : GestureScrollView

type TStore = {
  id: number // 商店編號(流水號)
  uuid: string
  storeUuid: string
  storeTitle: string
  [key: string]: string | number | boolean
}
interface SelectStoreModalProps extends Omit<ModalProps, 'children'> {
  isVisible: boolean
  stores: TStore[]
  onClose: () => void
  onChangeStore: (storeUuid: string) => void | Promise<void>
}
export default function SelectStoreModal(props: SelectStoreModalProps) {
  const { t } = useTranslation()
  const [storeName, setStoreName] = useState('')

  const { isVisible, stores, onClose, onChangeStore, ...rest } = props

  return (
    <Modal isVisible={isVisible} {...rest}>
      <View className='max-h-[90%] w-[350px] self-center rounded-[5px] border-1 border-solid border-gray-400 bg-white p-6'>
        <TouchableOpacity onPress={onClose} className='self-end'>
          <MaterialIcons color={palette.icon} size={20} name='close' />
        </TouchableOpacity>
        <Text className='my-4 self-center text-xl text-primary-600'>{t('LOGIN.SELECT_STORE')}</Text>
        {_.size(stores) > 3 && (
          <ThemedTextInput
            placeholder={t('COMMON.SEARCH_PLACEHOLDER')}
            iconName='search'
            containerStyle={{ marginBottom: 10 }}
            onChangeText={(storeName: string) => setStoreName(storeName)}
          />
        )}
        <ScrollView style={{ maxHeight: 400, paddingVertical: 3 }}>
          {_.map(
            getFuzzySearchItems(
              stores.map((item) => ({ ...item, title: `${item.id}${item.storeTitle}` })),
              storeName
            ),
            (item) => {
              return (
                <TouchableOpacity
                  className='mb-4 rounded-[5px] border-1 border-solid border-gray-400 px-[15px] py-4'
                  key={item.uuid}
                  onPress={() => onChangeStore(item.storeUuid)}
                >
                  <Text className='text-lg text-black'>{item.storeTitle}</Text>
                </TouchableOpacity>
              )
            }
          )}
        </ScrollView>
      </View>
    </Modal>
  )
}
