import _ from 'lodash'

export { Validators } from 'react-reactive-form'
export const validateChildren = (control, path: string, validators: Function | Function[]) => {
  if (typeof validators === 'function') {
    validators = [validators]
  }
  const target = control.get(path)
  const errors = validators.reduce((err, validator) => {
    return Object.assign(err, validator(target) || {})
  }, {})
  if (_.isEmpty(errors)) {
    return null
  }
  target.errors = Object.assign({}, errors, target.errors || {})
  target.status = target._calculateStatus()
  return { childrenError: true }
}

export const setErrors = (control, childrenErrors) => {
  return _.map(childrenErrors, (errors, key) => {
    const child = control.get(key)
    const resErrors = Object.assign({}, errors || {}, child._runValidator() || {})
    child.errors = _.isEmpty(resErrors) ? null : resErrors
    child.status = child._calculateStatus()
    return child.status
  }).find((status) => status === 'INVALID')
    ? {
        childrenError: true
      }
    : null
}

/**
 * 驗證 url 格式
 */
export const validateUrlRegexp = new RegExp(
  /^https?:\/\/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%._+~#?&//=]*)$/
)
