import { useStores } from '@rezio/core/hooks'
import { palette } from '@rezio/res/theme'
import React from 'react'
import { Text } from 'react-native'

export enum ReceiptStatus {
  Invalid = 'INVALID', // 未開立
  Pending = 'PENDING', // 待開立
  Success = 'SUCCESS', // 已開立
  CancelInProcessing = 'CANCEL_IN_PROCESSING', // 作廢處理中
  Cancelled = 'CANCELLED' // 已作廢
}

export enum ReceiptCategory {
  B2B = 'B2B',
  B2C = 'B2C'
}

export enum ReceiptCarrierType {
  Donated,
  EzPay,
  MobileBarcode,
  PersonCertificate,
  Print // 紙本
}

export enum InvoiceDonateType {
  NotDonated = 'NOT_DONATE',
  IsDonated = 'IS_DONATED'
}

export enum ReceiptType {
  None,
  Invoice,
  Receipt
}

export enum ReceiptTypeTitle {
  InvoiceTitle = 'INVOICE',
  ReceiptTitle = 'RECEIPT'
}

export enum ReceiptCreateType {
  Auto = 1,
  Manual
}

export enum ReceiptSystemStatus {
  Unconnected,
  Connected
}

export function formatCarrierType(carrierType: ReceiptCarrierType) {
  switch (carrierType) {
    case ReceiptCarrierType.Donated:
      return 'DONATED'
    case ReceiptCarrierType.EzPay:
      return 'EZPAY'
    case ReceiptCarrierType.MobileBarcode:
      return 'MOBILE_BARCODE'
    case ReceiptCarrierType.PersonCertificate:
      return 'PERSON_CERTIFICATE'
  }
}

export function FormatReceiptStatus(receiptStatus: ReceiptStatus | number) {
  let statusCode
  let statusTitle
  let statusText
  const { t } = useStores()
  switch (receiptStatus) {
    case -1:
    case ReceiptStatus.Invalid:
      statusCode = -1
      statusTitle = ReceiptStatus.Invalid
      statusText = (
        <Text style={{ color: palette.black }}>{t(`RECEIPT.STATUS_${ReceiptStatus.Invalid}`)}</Text>
      )
      break
    case 1:
    case ReceiptStatus.Success:
      statusCode = 1
      statusTitle = ReceiptStatus.Success
      statusText = (
        <Text style={{ color: palette.orderStatusConfirmed }}>
          {t(`RECEIPT.STATUS_${ReceiptStatus.Success}`)}
        </Text>
      )
      break
    case 2:
    case ReceiptStatus.Cancelled:
      statusCode = 2
      statusTitle = ReceiptStatus.Cancelled
      statusText = (
        <Text style={{ color: palette.black }}>
          {t(`RECEIPT.STATUS_${ReceiptStatus.Cancelled}`)}
        </Text>
      )
      break
    case 3:
    case ReceiptStatus.CancelInProcessing:
      statusCode = 3
      statusTitle = ReceiptStatus.CancelInProcessing
      statusText = (
        <Text style={{ color: palette.orderStatusCancelProcessing }}>
          {t(`RECEIPT.STATUS_${ReceiptStatus.CancelInProcessing}`)}
        </Text>
      )
      break
    case 0:
    case ReceiptStatus.Pending:
      statusCode = 0
      statusTitle = ReceiptStatus.Pending
      statusText = (
        <Text style={{ color: palette.orderStatusPending }}>
          {t(`RECEIPT.STATUS_${ReceiptStatus.Pending}`)}
        </Text>
      )
      break
  }

  return {
    statusCode,
    statusTitle,
    statusText
  }
}
