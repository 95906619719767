// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Core from '@rezio/core/core'
import { model, Model, modelAction, modelFlow, prop, _async, _await } from 'mobx-keystone'

export interface SourceItem {
  uuid: string
  label: string
  display: boolean
  deletable: boolean
  storeUuid: string
  updatedAt: string
  createdAt: string
}

interface Filter {
  text: string
}

interface SourceData {
  deletable: boolean
  display: boolean
  label: string
  uuid: string
}

interface RequestData {
  display: boolean
  label: string
}

export interface ActionHistory {
  uuid: string
  accountFirstName: string
  accountLastName: string
  actionCategory: string
  actionPayloadOld: string
  actionPayloadNew: string
  createdAt: string
}

@model('admin/OrderSource')
class OrderSource extends Model({
  loading: prop<boolean>(false),
  list: prop<SourceItem[]>(() => []),
  totalCount: prop<number>(0),
  itemPerPage: prop<number>(20),
  currentPage: prop<number>(1),
  limit: prop<number>(200), // NOTE: 目前固定上限200筆
  filter: prop<Filter>(() => ({ text: '' })),
  sourceData: prop<SourceData>(null),
  actionHistoryList: prop<ActionHistory[]>(() => [])
}) {
  @modelAction
  setPage = (page: number) => {
    this.currentPage = page
  }

  @modelAction
  setFilter = (filter: Filter) => {
    this.filter = filter
    this.currentPage = 1
  }

  @modelFlow
  fetchAllOrderSourceList = _async(function* (this: OrderSource) {
    try {
      const [request] = Core.getInstance().api.get(`customSource/${1}?num=${this.limit}`)
      const { data } = yield* _await(request)
      return data.list
    } catch (err) {
      console.error(err)
    }
  })

  @modelFlow
  fetchSourceList = _async(function* (this: OrderSource, num: number = this.itemPerPage) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.get(
        `customSource/${this.currentPage}?num=${num}&text=${this.filter.text}`
      )
      const { data } = yield* _await(request)
      this.list = data.list
      this.totalCount = data.totalCount
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  switchOrderDisplay = _async(function* (this: OrderSource, uuid: string, boolean: boolean) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.put(`customSource/${uuid}/switch`, {
        display: boolean
      })
      yield* _await(request)
      yield* _await(this.fetchSourceList().catch(console.error))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  createOrderSource = _async(function* (this: OrderSource, label: string) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.post('customSource', { label })
      yield* _await(request)
      yield* _await(this.fetchSourceList().catch(console.error))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  fetchOrderSourceDataAndHistory = _async(function* (this: OrderSource, uuid: string) {
    try {
      this.loading = true
      const [dataRequest] = Core.getInstance().api.get(`customSource/${uuid}`)
      const { data } = yield* _await(dataRequest)
      this.sourceData = {
        deletable: data.deletable,
        display: data.display,
        label: data.label,
        uuid: data.uuid
      }
      const [historyRequest] = Core.getInstance().api.get(`customSource/${uuid}/actionHistory`)
      const { data: historyData } = yield* _await(historyRequest)
      this.actionHistoryList = historyData.map(
        (payload): ActionHistory => ({
          uuid: payload.uuid,
          accountFirstName: payload.accountFirstName,
          accountLastName: payload.accountLastName,
          actionCategory: payload.actionCategory,
          actionPayloadOld:
            payload.actionPayload?.oldLabel || `${payload.actionPayload?.oldDisplay}`,
          actionPayloadNew:
            payload.actionPayload?.newLabel || `${payload.actionPayload?.newDisplay}`,
          createdAt: payload.createdAt
        })
      )
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  deleteOrderSource = _async(function* (this: OrderSource, uuid: string, callback?: () => void) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.delete(`customSource/${uuid}`)
      yield* _await(request)
      if (typeof callback === 'function') {
        callback()
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })

  @modelFlow
  updateOrderSource = _async(function* (this: OrderSource, uuid: string, data: RequestData) {
    try {
      this.loading = true
      const [request] = Core.getInstance().api.put(`customSource/${uuid}`, data)
      yield* _await(request)
      yield* _await(this.fetchOrderSourceDataAndHistory(uuid).catch(console.error))
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  })
}

export default OrderSource
